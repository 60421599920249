import { Button, Drawer, Flex, Space } from "antd";
import React, { useState } from "react";
import { ClearOutlined, FilterOutlined } from "@ant-design/icons";

import GCAdminSelectFilter, {
  GCAdminSelectFilterProps,
} from "./GCAdminSelectFilter";
import GCAdminFilterDates, {
  GCAdminFilterDatesProps,
} from "./GCAdminFilterDates";
import GCAdminRadioFilter, {
  GCAdminRadioFilterProps,
} from "./GCAdminRadioFilter";

export interface GCAdminHeaderFilterButtonProps {
  showDone?: boolean;
  filters: {
    dates?: GCAdminFilterDatesProps;
    selectFilters?: GCAdminSelectFilterProps[];
    radioFilters?: GCAdminRadioFilterProps[];
  };
}

const GCAdminHeaderFilterButton: React.FC<GCAdminHeaderFilterButtonProps> = (
  props,
) => {
  // Drawer Controls
  const [drawerVisible, setDrawerVisible] = useState(false);
  const openFilterDrawer = () => {
    setDrawerVisible(true);
  };
  const closeFilterDrawer = () => {
    setDrawerVisible(false);
  };

  const onClearFilters = () => {
    props.filters.dates?.onSelectedDatesChange(null);
    if (props.filters.selectFilters)
      for (const filter of props.filters.selectFilters) {
        filter.setSelectedItems(new Set<string>());
      }
  };

  const anyDates = !!props.filters?.dates?.selectedDates;

  const anyFiltersSet =
    anyDates ||
    !props.filters.selectFilters ||
    !!props.filters.selectFilters.find((filter) => filter.selectedItems.size);

  return (
    <>
      <Button
        icon={<FilterOutlined />}
        type={"primary"}
        onClick={openFilterDrawer}
      >
        Filter
      </Button>
      <Drawer
        // mask={false}
        title={"Filters"}
        open={drawerVisible}
        onClose={closeFilterDrawer}
        footer={
          props.showDone ? (
            <Button onClick={closeFilterDrawer} type="primary">
              Done
            </Button>
          ) : null
        }
        extra={
          <Space>
            <Button
              disabled={!anyFiltersSet}
              icon={<ClearOutlined />}
              onClick={onClearFilters}
            >
              Clear Filters
            </Button>
          </Space>
        }
      >
        <Flex vertical gap={"middle"}>
          {props.filters.dates && (
            <GCAdminFilterDates {...props.filters.dates} />
          )}
          {!!props.filters.radioFilters &&
            props.filters.radioFilters.map((filter) => (
              <GCAdminRadioFilter {...filter} key={filter.title} />
            ))}
          {!!props.filters.selectFilters &&
            props.filters.selectFilters.map((filter) => (
              <GCAdminSelectFilter {...filter} key={filter.title} />
            ))}
        </Flex>
      </Drawer>
    </>
  );
};

export default GCAdminHeaderFilterButton;
