import { MaterialSymbol } from "react-material-symbols";
import React, { useState } from "react";
import { GCInspectionsQuery$data } from "src/common/types/generated/relay/GCInspectionsQuery.graphql";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import InspectionSummaryCreator from "src/domain-features/siteinspection/components/InspectionSummaryCreator";
import InspectionResultSummaryTable from "src/domain-features/siteinspection/components/tables/InspectionResultSummaryTable";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";

const GCCompletedInspections: React.FC<
  ProjectIdProps & { data: GCInspectionsQuery$data }
> = ({ projectId, data }) => {
  const { userData } = useUserData();
  const inspections = data.inspection_instance_connection.edges.map((ins) => ({
    text: ins.node.name.en,
    value: ins.node.pk,
  }));
  const qrs = data.inspection_qr_code_connection.edges.map((ins) => ({
    text: ins.node.name,
    value: ins.node.pk,
  }));
  const companies = data.inspection_result_connection.edges.map((ins) => ({
    name: ins.node.company_name!,
    inspInstanceId: ins.node.inspection_instance_id,
  }));
  const showLocation =
    !!userData.employee?.general_contractor.show_locations_during_inspection;
  const [open, setOpen] = useState(false);
  return (
    <div>
      <InspectionSummaryCreator
        {...{ projectId, open, onClose: () => setOpen(false), showLocation }}
      />
      <InspectionResultSummaryTable
        where={{
          project_id: { _eq: projectId },
          inspection_results: {},
        }}
        topBarButtons={[
          {
            label: "",
            icon: <MaterialSymbol icon="download" />,
            onClick: () => setOpen(true),
          },
        ]}
        showLocation={showLocation}
        excludedKeys={showLocation ? [] : ["location"]}
        qrs={qrs}
        inspections={inspections}
        projectId={projectId}
        subs={data.project_subcontractor_connection.edges}
        manuallyEnteredCompanies={companies}
      />
    </div>
  );
};
export default GCCompletedInspections;
