import NestedRoute from "src/common/types/manual/NestedRoute";
import GCAddNewProject from "./newProject/GCAddNewProject";
import siteInspectionEntryRoutes from "../../../../../../domain-features/siteinspection/siteInspectionEntryRoutes";
import observationEntryRoutes from "../../../../../../domain-features/observation/observationEntryRoutes";
import siteSafetyRoutes from "../../../../../../domain-features/sitesafety/siteSafetyRoutes";
import GCProjectWorkers from "./workers/GCProjectWorkers";
import GCProjectWorkersTable from "./workers/table/GCProjectWorkersTable";
import GCProjectWorkersOverview from "./workers/overview/GCProjectWorkersOverview";
import GCprojectWorker from "./workers/worker/GCProjectWorkersWorker";
import { Navigate, Outlet } from "react-router-dom";
import incidentEntryRoutes from "../../../../../../domain-features/incident-management/incidentEntryRoutes";
import GCTasks from "../../jha/GCProjectJhas";
import GCSds from "../../sds/GCSds";
import GCProjectSubcontractors from "./subcontractors/GCProjectSubcontractors";
import GCSubcontractors from "./subcontractors/GCSubcontractors";
import GCProjectEmployeeVerification from "../../../../../../domain-features/siteorientation/entryRoutes/gcDashboard/routes/employeeVerification/GCProjectEmployeeVerification";
import GCSubcontractor from "./subcontractors/GCSubcontractor";
import GCProjectCrew from "../../../../../../domain-features/worker-crew/entry-routes/gc/GCProjectCrew";
import GCAlerts from "./alerts/GCProjectSiteAlerts";
import gcProjectToolboxTalksRoute from "../../../../../../domain-features/sitesafety/entry-routes/gc-project-toolbox-talks/gcToolboxTalksRoute";
import siteOrientationRoutes from "../../../../../../domain-features/siteorientation/siteOrientationEntryRoutes";
import GCProjectCalendarSitedeliverySubBlocks from "../../../../../../domain-features/site-delivery/entry-routes/calendar/sitedelivery/sub-blocks/GCProjectCalendarSitedeliverySubBlocks";
import ReportManagementRedirect from "../../../../../../domain-features/sitesafety/entry-routes/report-settings/gcProjectCalendarReports/ReportManagementRedirect";
import GCProjectTeam from "./team/GCProjectTeam";
import GCBilling from "../../billing/GCBilling";
import React from "react";
import GCProjectSitedelivery from "../../../../../../domain-features/site-delivery/entry-routes/calendar/sitedelivery/GCProjectCalendarSitedelivery";
import GCProjectCalendarSitedeliveryDeliveries from "../../../../../../domain-features/site-delivery/entry-routes/calendar/sitedelivery/deliveries/GCProjectCalendarSitedeliveryDeliveries";
import GCProjectBreadcrumb from "./GCProjectBreadcrumb";
import GCProjectSettings from "./settings/GCProjectSettings";
import getRoutesByRoutedMenu from "../../../../../../utility-features/navigation/routed-menu/getRoutesByRoutedMenu";
import gcProjectSettingsRoutedMenu from "./settings/gcProjectSettingsRoutedMenu";
import preTaskPlansRoute from "../../../../../../domain-features/sitesafety/entry-routes/preTaskPlans/preTaskPlansRoute";
import dailyRoute from "../../../../../../domain-features/sitesafety/entry-routes/daily-reports/dailyRoute";
import permitsRoute from "../../../../../../domain-features/sitesafety/entry-routes/permits/permitsRoute";
import toolboxTalksRoute from "../../../../../../domain-features/sitesafety/entry-routes/toolboxtalks/toolboxTalksRoute";
import visitors from "../../../../../../domain-features/sitesafety/entry-routes/visitor/visitorsRoute";
import gcProjectReportsRoute from "../../../../../../domain-features/sitesafety/entry-routes/reports/gcProjectReportsRoute";

const gcProjectsRoute: NestedRoute = {
  element: <Outlet />,
  children: [
    {
      path: "new-project",
      element: <GCAddNewProject />,
      handle: {
        breadcrumb: "New Project",
      },
    },
    {
      path: ":projectId",
      element: <Outlet />,
      handle: {
        breadcrumb: <GCProjectBreadcrumb />,
      },
      children: [
        {
          path: "inspections",
          ...siteInspectionEntryRoutes.gcInpsection,
        },
        {
          path: "observations",
          ...observationEntryRoutes.gcObservationRoute,
        },
        {
          path: "dashboard",
          ...siteSafetyRoutes.gcProjectDashboard,
        },
        {
          path: "workers", // projectWorkers
          element: <GCProjectWorkers />,
          handle: {
            breadcrumb: "Workers",
          },
          children: [
            {
              path: "table",
              element: <GCProjectWorkersTable />,
            },
            {
              path: "overview",
              element: <GCProjectWorkersOverview />,
            },
            {
              path: "worker/:projectWorkerId",
              element: <GCprojectWorker />,
            },
            {
              path: "",
              element: <Navigate to={`overview`} replace />,
            },
          ],
        },
        {
          path: "incidents",
          ...incidentEntryRoutes.gcIncidentRoute,
        },

        {
          path: "jha",
          handle: {
            breadcrumb: "Job Hazard Analysis",
          },
          element: <GCTasks />,
        },

        {
          path: "sds",
          element: <Outlet />,
          handle: {
            breadcrumb: "Safety Data Sheets",
          },
          children: [
            {
              path: ":subview",
              element: <GCSds />,
            },
            {
              path: "",
              element: <Navigate replace to={"project"} />,
            },
          ],
        },

        {
          path: "subcontractors",

          element: <GCProjectSubcontractors />,
          handle: {
            breadcrumb: "Subcontractors",
          },
          children: [
            {
              path: "table",
              element: <GCSubcontractors />,
            },
            {
              path: "employeeverification",
              element: <GCProjectEmployeeVerification />,
            },
            {
              path: ":subcontractorId",
              element: <Outlet />,
              children: [
                {
                  path: "task/:taskId",
                  element: <GCSubcontractor />,
                },
                {
                  path: "",
                  element: <Navigate replace to="sub-info" />,
                },
                {
                  path: ":subview",
                  element: <GCSubcontractor />,
                },
                {
                  path: "crew/:crewId",
                  element: <GCProjectCrew />,
                },
              ],
            },
            {
              path: "",
              element: <Navigate to="table" replace />,
            },
          ],
        },
        {
          path: "alerts",
          element: <Outlet />,
          handle: {
            breadcrumb: "Alerts",
          },
          children: [
            {
              path: ":subview",
              element: <GCAlerts />,
            },
            {
              path: "",
              element: <Navigate to={"alerts"} />,
            },
          ],
        },
        {
          path: "reports",
          ...gcProjectReportsRoute,
        },
        {
          path: "toolbox-talks",
          ...gcProjectToolboxTalksRoute,
        },

        {
          path: "pre-task-plans",
          ...preTaskPlansRoute,
        },
        {
          path: "daily-reports",
          ...dailyRoute,
        },

        {
          path: "permits",
          ...permitsRoute,
        },
        {
          path: "toolbox-talks",
          ...toolboxTalksRoute,
        },

        {
          path: "visitors",
          ...visitors,
        },
        {
          path: "orientation",
          ...siteOrientationRoutes.gcDashboard,
        },
        {
          path: "deliveries",
          element: <GCProjectSitedelivery />,
          handle: {
            breadcrumb: "Deliveries",
          },
          children: [
            {
              path: "calendar",
              element: <GCProjectCalendarSitedeliveryDeliveries />,
            },
            {
              path: "sub-blocks",
              element: <GCProjectCalendarSitedeliverySubBlocks />,
            },
            {
              // TODO change to kebab case
              // TODO maybe not needed?
              path: "reportManagement",
              element: <ReportManagementRedirect />,
            },
            {
              path: "",
              element: <Navigate to="calendar" replace />,
            },
          ],
        },
        {
          path: "team",
          element: <GCProjectTeam />,
          handle: {
            breadcrumb: "Team",
          },
        },
        {
          path: "billing",
          element: <GCBilling />,
          handle: {
            breadcrumb: "Billing",
          },
        },
        {
          path: "settings",
          element: <GCProjectSettings />,
          handle: {
            breadcrumb: "Settings",
          },
          children: getRoutesByRoutedMenu(gcProjectSettingsRoutedMenu),
        },

        {
          path: "",
          element: <Navigate replace to={"dashboard"} />,
        },
      ],
    },
  ],
};

export default gcProjectsRoute;
