import React from "react";

export type TagStatusType =
  | "negative"
  | "positive"
  | "inactive"
  | "pending"
  | "incomplete"
  | "corrected"
  | "warn";

export interface TagProps {
  children: string;
  status?: TagStatusType;
}

const Tag: React.FunctionComponent<TagProps> = (props) => {
  let bgClassName = "bg-suplementary-1";
  let textClassName = "text-static";

  switch (props.status) {
    case "positive":
      bgClassName = "bg-semantic-positive-light";
      textClassName = "text-semantic-positive-dark";
      break;
    case "inactive":
      bgClassName = "bg-semantic-inactive-light";
      textClassName = "text-semantic-inactive-dark";
      break;
    case "negative":
      bgClassName = "bg-semantic-negative-light";
      textClassName = "text-semantic-negative-dark";
      break;
    case "pending":
      bgClassName = "bg-semantic-pending-light";
      textClassName = "text-semantic-pending-dark";
      break;
    case "warn":
      bgClassName = "bg-warn";
      textClassName = "text-semantic-pending-dark";
      break;
    case "incomplete":
      bgClassName = "bg-grey";
      textClassName = "text-white";
      break;
    case "corrected":
      bgClassName = "bg-purple";
      textClassName = "text-white";
      break;
  }

  return (
    <div className="inline-block">
      <div
        className={`flex flex-row items-center px-0.5 h-1.5 select-none rounded-1 margin-gap-x-0.25 group border border-transparent transitoin-all ${bgClassName}`}
      >
        <span className={`text-0.75 ${textClassName}`}>{props.children}</span>
      </div>
    </div>
  );
};

export default Tag;
