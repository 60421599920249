import { Card, Form, Radio } from "antd";

const RadioButtonsDemo: React.FC = () => {
  return (
    <div className={`flex flex-col gap-1`}>
      <Card>
        <Form.Item name="manhoursType" style={{ display: "inline-block" }}>
          <Radio.Group
            optionType="button"
            options={[
              { value: "quick_entry", label: "Simple" },
              { value: "breakdown", label: "Breakdown" },
            ]}
          />
          <Radio.Group
            options={[
              { value: "quick_entry", label: "Simple" },
              { value: "breakdown", label: "Breakdown" },
            ]}
          />
        </Form.Item>
      </Card>
    </div>
  );
};

export default RadioButtonsDemo;
