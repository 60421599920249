/**
 * @generated SignedSource<<0975f73c7049483fc7a23a24177bd1c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { inspection_instance_qr_code_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddInspectionsToQRModal_InsertQR_Mutation$variables = {
  objects: ReadonlyArray<inspection_instance_qr_code_insert_input>;
};
export type AddInspectionsToQRModal_InsertQR_Mutation$data = {
  readonly insert_inspection_instance_qr_code: {
    readonly returning: ReadonlyArray<{
      readonly inspection_qr_code: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
      readonly inspection_qr_code_id: string;
    }>;
  } | null | undefined;
};
export type AddInspectionsToQRModal_InsertQR_Mutation = {
  response: AddInspectionsToQRModal_InsertQR_Mutation$data;
  variables: AddInspectionsToQRModal_InsertQR_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "inspection_instance_qr_code_inspection_qr_code_id_inspection_in",
      "update_columns": ([]/*: any*/)
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "inspection_qr_code_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddInspectionsToQRModal_InsertQR_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "inspection_instance_qr_code_mutation_response",
        "kind": "LinkedField",
        "name": "insert_inspection_instance_qr_code",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "inspection_instance_qr_code",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "inspection_qr_code",
                "kind": "LinkedField",
                "name": "inspection_qr_code",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddInspectionsToQRModal_InsertQR_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "inspection_instance_qr_code_mutation_response",
        "kind": "LinkedField",
        "name": "insert_inspection_instance_qr_code",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "inspection_instance_qr_code",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "inspection_qr_code",
                "kind": "LinkedField",
                "name": "inspection_qr_code",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac04f6dd400aadf29003ce40583a6372",
    "id": null,
    "metadata": {},
    "name": "AddInspectionsToQRModal_InsertQR_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddInspectionsToQRModal_InsertQR_Mutation(\n  $objects: [inspection_instance_qr_code_insert_input!]!\n) {\n  insert_inspection_instance_qr_code(objects: $objects, on_conflict: {constraint: inspection_instance_qr_code_inspection_qr_code_id_inspection_in, update_columns: []}) {\n    returning {\n      inspection_qr_code_id\n      inspection_qr_code {\n        name\n        id\n        pk: id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1c363c6fb435b83ced6df11984871c6";

export default node;
