import { MaterialSymbol } from "react-material-symbols";
import { Button, Descriptions, message } from "antd";
import dayjs from "dayjs";
import React, { FC, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SendEmailModal, {
  SendEmailModalRef,
} from "src/common/components/dialogs/SendEmailModal";
import Image from "src/common/components/general/images/Image";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import BSpace from "src/common/components/layouts/BSpace";
import StyledContent from "src/common/components/layouts/StyledContent";
import AnonProjectWorkerTable from "src/common/components/tables/AnonProjectWorkerTable";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import {
  Toolbox_Talk,
  useEmailToolboxTalkPdfMutation,
  useGenerateToolboxTalkPdfMutation,
  useGetToolboxTalkByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import RouteIdProps from "src/common/types/manual/RouteIdProps";

const { Item } = Descriptions;

interface Props {
  toolboxTalk: Toolbox_Talk;
}

// TODO
const BadNameComponent: FC<Props & RouteIdProps> = ({
  toolboxTalk,
  routeId,
}) => {
  const emailModal = useRef<SendEmailModalRef>(null);
  const [emailToolboxTalk] = useEmailToolboxTalkPdfMutation();
  const [generateToolboxTalkPdf, { loading }] =
    useGenerateToolboxTalkPdfMutation();
  const navigate = useNavigate();

  return (
    <>
      <SendEmailModal
        ref={emailModal}
        title={"Email toolbox talk PDF"}
        onSubmit={({ email }) => {
          emailModal.current?.close();

          emailToolboxTalk({
            variables: {
              input: {
                emailRecipients: [email],
                toolboxTalkId: toolboxTalk.id,
              },
            },
          });

          message.success("Emailed toolbox talk!");
        }}
      />
      <div className={`flex flex-col gap-1`}>
        <StyledContent padding backgroundColor="white">
          <BSpace>
            <Descriptions title="Toolbox Talk" bordered size="middle">
              <Item label="Date">
                {dayjs(toolboxTalk.created_at).format("lll")}
              </Item>
              <Item label="Project">{toolboxTalk.project.name}</Item>
              <Item label="Subcontractor">
                {toolboxTalk.subcontractor.name}
              </Item>
              <Item label="Crew">{toolboxTalk.project_crew?.name ?? ""}</Item>
              <Item label="Submitter">
                {toolboxTalk?.submitter_project_worker?.user?.name},{" "}
                {
                  toolboxTalk?.submitter_project_worker?.title?.translation
                    .clientText
                }
              </Item>
              <Item label="Led By">
                {toolboxTalk.led_by_project_worker.user?.name},{" "}
                {
                  toolboxTalk.led_by_project_worker.title?.translation
                    .clientText
                }
              </Item>
              <Item label="Topic">{toolboxTalk.description.clientText}</Item>
            </Descriptions>
            <div className="flex gap-1">
              <Button onClick={() => emailModal.current?.open()}>
                Email report
              </Button>
              <Button
                loading={loading}
                icon={<MaterialSymbol icon="download" />}
                onClick={async () => {
                  await generateToolboxTalkPdf({
                    variables: { input: { toolboxTalkId: toolboxTalk.id } },
                  }).then((d) => {
                    if (d.data?.generateToolboxTalkPdf) {
                      downloadFromUrl(d.data.generateToolboxTalkPdf);
                    }
                  });
                  message.success("Success");
                }}
              />
            </div>
          </BSpace>
          {toolboxTalk.content && (
            <div className="my-1 text-1">{toolboxTalk.content.en}</div>
          )}
          <div className="flex flex-col gap-1">
            <div className="font-accent">
              Pictures ({toolboxTalk.images.length}):
            </div>
            <div className="flex flex-row flex-wrap">
              {toolboxTalk.images.map((o, i) => (
                <div className="font-accent max-w-32 min-w-24" key={i}>
                  <Image src={o.url} alt={"Image " + (i + 1)} />
                </div>
              ))}
            </div>
          </div>
          <AnonProjectWorkerTable
            loadAll
            excludedKeys={["subcontractor", "project", "crew", "corporateDate"]}
            where={{
              toolbox_talks: {
                toolbox_talk_id: {
                  _eq: toolboxTalk.id,
                },
              },
            }}
            onRowClick={({ pk }) => {
              navigate(
                `/gce/hierarchy/project/${routeId.id}/workers/worker/${pk}`,
              );
            }}
          />
        </StyledContent>
      </div>
    </>
  );
};

interface GCProjectReportsTBTsDetailProps {
  toolboxTalkId: string;
  projectId: string;
}

const GCProjectReportsTBTsDetail: React.FunctionComponent<
  GCProjectReportsTBTsDetailProps
> = (props) => {
  const { data, loading } = useGetToolboxTalkByPkQuery({
    variables: { toolboxTalkId: props.toolboxTalkId },
  });
  if (loading) {
    return <LoadingContent />;
  }
  if (!data?.toolbox_talk_by_pk) {
    throw new Error("Toolbox talk not found");
  }

  return (
    <BadNameComponent
      routeId={{ type: "gce", id: props.projectId }}
      toolboxTalk={data.toolbox_talk_by_pk as any}
    />
  );
};

export default GCProjectReportsTBTsDetail;
