/**
 * @generated SignedSource<<591be50d2bdfc2bc49b3d95507e191ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_drug_test_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type DrugtestCardQuery$variables = {
  drugTestWhere: worker_drug_test_bool_exp;
};
export type DrugtestCardQuery$data = {
  readonly worker_drug_test_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly drug_test_date: string;
        readonly entered_through_user: {
          readonly employee: {
            readonly employee_title: {
              readonly name: {
                readonly en: string;
              };
            } | null | undefined;
            readonly general_contractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly id: string;
          readonly name: string;
          readonly pk: string;
          readonly role: string;
          readonly subcontractor_employee: {
            readonly employee_title: {
              readonly name: {
                readonly en: string;
              };
            } | null | undefined;
            readonly subcontractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly worker: {
            readonly current_worker_role: string;
            readonly subcontractor: {
              readonly name: string;
            } | null | undefined;
            readonly worker_title: {
              readonly translation: {
                readonly en: string;
              };
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly name: string | null | undefined;
        readonly pk: string;
        readonly project: {
          readonly name: string;
        } | null | undefined;
        readonly project_id: string | null | undefined;
        readonly status: string | null | undefined;
        readonly worker_id: string;
      };
    }>;
  };
};
export type DrugtestCardQuery = {
  response: DrugtestCardQuery$data;
  variables: DrugtestCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "drugTestWhere"
  }
],
v1 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "drugTestWhere"
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drug_test_date",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v9 = [
  (v3/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v13 = [
  (v12/*: any*/)
],
v14 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": (v13/*: any*/),
    "storageKey": null
  }
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_worker_role",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "drug_test_date": "desc"
    }
  },
  (v1/*: any*/)
],
v21 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v22 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v23 = [
  (v3/*: any*/),
  (v10/*: any*/)
],
v24 = [
  (v12/*: any*/),
  (v10/*: any*/)
],
v25 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": (v24/*: any*/),
    "storageKey": null
  },
  (v10/*: any*/)
],
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v23/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DrugtestCardQuery",
    "selections": [
      {
        "alias": "worker_drug_test_connection",
        "args": [
          (v1/*: any*/)
        ],
        "concreteType": "worker_drug_testConnection",
        "kind": "LinkedField",
        "name": "__DrugtestCard_worker_drug_test_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_drug_testEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_drug_test",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "entered_through_user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v10/*: any*/),
                      (v2/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "employee_title",
                            "kind": "LinkedField",
                            "name": "employee_title",
                            "plural": false,
                            "selections": (v14/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor_employee",
                        "kind": "LinkedField",
                        "name": "subcontractor_employee",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "subcontractor_employee_title",
                            "kind": "LinkedField",
                            "name": "employee_title",
                            "plural": false,
                            "selections": (v14/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker",
                        "kind": "LinkedField",
                        "name": "worker",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "worker_title",
                            "kind": "LinkedField",
                            "name": "worker_title",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "text_translation",
                                "kind": "LinkedField",
                                "name": "translation",
                                "plural": false,
                                "selections": (v13/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DrugtestCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v20/*: any*/),
        "concreteType": "worker_drug_testConnection",
        "kind": "LinkedField",
        "name": "worker_drug_test_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_drug_testEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_drug_test",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v23/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "entered_through_user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v10/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": (v23/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "employee_title",
                            "kind": "LinkedField",
                            "name": "employee_title",
                            "plural": false,
                            "selections": (v25/*: any*/),
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor_employee",
                        "kind": "LinkedField",
                        "name": "subcontractor_employee",
                        "plural": false,
                        "selections": [
                          (v26/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "subcontractor_employee_title",
                            "kind": "LinkedField",
                            "name": "employee_title",
                            "plural": false,
                            "selections": (v25/*: any*/),
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker",
                        "kind": "LinkedField",
                        "name": "worker",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "worker_title",
                            "kind": "LinkedField",
                            "name": "worker_title",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "text_translation",
                                "kind": "LinkedField",
                                "name": "translation",
                                "plural": false,
                                "selections": (v24/*: any*/),
                                "storageKey": null
                              },
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v26/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              },
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v20/*: any*/),
        "filters": [
          "where"
        ],
        "handle": "connection",
        "key": "DrugtestCard_worker_drug_test_connection",
        "kind": "LinkedHandle",
        "name": "worker_drug_test_connection"
      }
    ]
  },
  "params": {
    "cacheID": "5f4f9698f0692f3d16968f0ad43464b2",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "worker_drug_test_connection"
          ]
        }
      ]
    },
    "name": "DrugtestCardQuery",
    "operationKind": "query",
    "text": "query DrugtestCardQuery(\n  $drugTestWhere: worker_drug_test_bool_exp!\n) {\n  worker_drug_test_connection(where: $drugTestWhere, first: 10000, order_by: {drug_test_date: desc}) {\n    edges {\n      node {\n        pk: id\n        name\n        status\n        created_at\n        drug_test_date\n        worker_id\n        project_id\n        project {\n          name\n          id\n        }\n        entered_through_user {\n          name\n          id\n          pk: id\n          role\n          employee {\n            general_contractor {\n              name\n              id\n            }\n            employee_title {\n              name {\n                en\n                id\n              }\n              id\n            }\n            id\n          }\n          subcontractor_employee {\n            subcontractor {\n              name\n              id\n            }\n            employee_title {\n              name {\n                en\n                id\n              }\n              id\n            }\n            id\n          }\n          worker {\n            current_worker_role\n            worker_title {\n              translation {\n                en\n                id\n              }\n              id\n            }\n            subcontractor {\n              name\n              id\n            }\n            id\n          }\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "43efbfa2116fe4d8036b84b6e937e824";

export default node;
