import { Modal } from "antd";
import Popover, { PopoverProps } from "antd/lib/popover";
import clsx from "clsx";
import React from "react";
import remToPx from "src/common/functions/remToPx";
import { useBoolean } from "usehooks-ts";

export interface LabelIconProps {
  icon: React.ReactNode;
  label: string;
  popover?: Pick<PopoverProps, "content" | "placement">;
  modal?: {
    content: React.ReactNode;
    title?: string;
  };
}

const LabelIcon: React.FC<LabelIconProps> = ({
  icon,
  label,
  popover,
  modal,
}) => {
  const {
    value: modalVisible,
    setTrue: showModal,
    setFalse: hideModal,
  } = useBoolean();
  const clickable = !!modal;
  const interactive = clickable || !!popover;
  const element = (
    <div
      className={clsx(
        `flex flex-row items-center gap-0.5`,
        clickable && "cursor-pointer",
        interactive && "group select-none",
      )}
      onClick={(e) => {
        if (clickable) {
          e.stopPropagation();
          showModal();
        }
      }}
    >
      {icon}
      <div
        className={`${
          !!clickable
            ? "group-hover:text-interactive-primary transition-all "
            : ""
        }`}
      >
        {label}
      </div>
    </div>
  );

  return (
    <div className="flex">
      {popover ? (
        <Popover placement={popover.placement} content={popover.content}>
          <div onClick={(e) => e.stopPropagation()}>{element}</div>
        </Popover>
      ) : (
        element
      )}
      {!!modal && (
        <Modal
          onCancel={(e) => {
            e.stopPropagation();
            hideModal();
          }}
          centered
          title={modal.title}
          width={remToPx(16)}
          open={modalVisible}
          footer={null}
        >
          <div className="max-h-full overflow-auto">{modal.content}</div>
        </Modal>
      )}
    </div>
  );
};

export default LabelIcon;
