import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { agcCompanyId } from "src/common/hooks/useCheckAGCUser";
import { OrientationPosterUpload_Mutation } from "src/common/types/generated/relay/OrientationPosterUpload_Mutation.graphql";
import { OrientationPosterUploadQuery } from "src/common/types/generated/relay/OrientationPosterUploadQuery.graphql";
import UploadButton from "src/common/components/general/upload/UploadButton";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Image } from "antd";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";

const query = graphql`
  query OrientationPosterUploadQuery($gcId: uuid!) {
    general_contractor_connection(where: { id: { _neq: $gcId } }) {
      edges {
        node {
          orientation_qr_code_poster_background_url
          in_person_registration_poster_background_url
        }
      }
    }
  }
`;
const OrientationPosterUpload: React.FC = () => {
  const [updateOrientationPoster] =
    useAsyncMutation<OrientationPosterUpload_Mutation>(
      graphql`
        mutation OrientationPosterUpload_Mutation(
          $where: general_contractor_bool_exp!
          $_set: general_contractor_set_input!
        ) {
          update_general_contractor(where: $where, _set: $_set) {
            affected_rows
          }
        }
      `,
    );

  const data = useLazyLoadQuery<OrientationPosterUploadQuery>(query, {
    gcId: agcCompanyId,
  });

  const [posterBackgroundImageUrl, setPosterBackgroundImageUrl] = useState(
    data.general_contractor_connection.edges[0].node
      .orientation_qr_code_poster_background_url ?? "",
  );
  const [
    inPersonPosterBackgroundImageUrl,
    setInPersonPosterBackgroundImageUrl,
  ] = useState(
    data.general_contractor_connection.edges[0].node
      .in_person_registration_poster_background_url ?? "",
  );
  return (
    <div>
      <h3 className="text-2 mb-2 ml-1">
        Online Orientation Poster Background Image
      </h3>
      <div className="flex flex-row items-end">
        <div className="w-3/12">
          <Image src={posterBackgroundImageUrl} />
        </div>
        <div className="ml-2">
          <UploadButton
            accept="image/*"
            children="Upload"
            onFileUpload={async (imageUrl) => {
              setPosterBackgroundImageUrl(imageUrl);
              // const user = await auth.currentUser?.uid();
              await updateOrientationPoster({
                variables: {
                  where: {
                    id: {
                      _neq: agcCompanyId,
                    },
                  },
                  _set: {
                    orientation_qr_code_poster_background_url: imageUrl,
                  },
                },
              });
            }}
            // loading={props.loading}
          />
        </div>
      </div>
      <h3 className="text-2 mb-2 ml-1 mt-3">
        In-Person Orientation Poster Background Image
      </h3>
      <div className="flex flex-row items-end">
        <div className="w-3/12">
          <Image src={inPersonPosterBackgroundImageUrl} />
        </div>
        <div className="ml-2">
          <UploadButton
            accept="image/*"
            children="Upload"
            onFileUpload={async (imageUrl) => {
              setInPersonPosterBackgroundImageUrl(imageUrl);
              // const user = await auth.currentUser?.uid();
              await updateOrientationPoster({
                variables: {
                  where: {
                    id: {
                      _neq: agcCompanyId,
                    },
                  },
                  _set: {
                    in_person_registration_poster_background_url: imageUrl,
                  },
                },
              });
            }}
            // loading={props.loading}
          />
        </div>
      </div>
    </div>
  );
};

export default withCustomSuspense(OrientationPosterUpload);
