import { Button, Tooltip } from "antd";
import React, { PropsWithChildren } from "react";
import { MaterialSymbol } from "react-material-symbols";
import useUploadInput from "./useUploadInput";

interface FileUploaderProps extends PropsWithChildren {
  onFileUpload?: (url: string) => Promise<void>;
  accept?: string;
  loading?: boolean;
}

const UploadButton: React.FC<FileUploaderProps> = ({
  onFileUpload,
  loading,
  accept,
  children,
}) => {
  const { uploading, openUploadWindow, hiddenInputElement } = useUploadInput({
    accept,
    onFileUpload,
  });

  const buttonElement = (
    <Button
      loading={loading || uploading}
      onClick={openUploadWindow}
      icon={<MaterialSymbol icon={"upload"} />}
    >
      {children}
    </Button>
  );
  return (
    <>
      {hiddenInputElement}
      {!children ? (
        <Tooltip title={"Upload"}>{buttonElement}</Tooltip>
      ) : (
        buttonElement
      )}
    </>
  );
};

export default UploadButton;
