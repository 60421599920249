import { Form, Input, message, Modal, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import crewName from "src/common/functions/crewName";
import { CrewProjectWorkerOptions_project_worker_connection_frag$data } from "src/common/types/generated/relay/CrewProjectWorkerOptions_project_worker_connection_frag.graphql";
import useUpsertCrewData from "src/domain-features/worker-crew/utils/useUpsertCrewData";
import * as uuid from "uuid";

interface CreateNewCrewModalProps {
  visible: boolean;
  onCancel: () => void;
  projectId: string;
  subcontractorId: string;
  workersOnProject: CrewProjectWorkerOptions_project_worker_connection_frag$data;
  onFinish: () => void;
}

type WorkerOptionsType =
  CrewProjectWorkerOptions_project_worker_connection_frag$data["project_worker_connection"]["edges"][number];
const CreateNewCrewModal: React.FC<CreateNewCrewModalProps> = ({
  visible,
  onCancel,
  subcontractorId,
  projectId,
  onFinish,
  workersOnProject,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [upsertProjectCrew] = useUpsertCrewData();
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const [selectedWorkerIds, setSelectedWorkerIds] = useState<string[]>([]);
  useEffect(() => {
    setSelectedWorkerIds([]);
  }, [visible]);
  const workersNotOnCrew: WorkerOptionsType[] = useMemo(() => {
    return workersOnProject.project_worker_connection.edges.filter((pw) =>
      selectedWorkerIds.every((pwId) => pwId !== pw.node.pk),
    );
  }, [selectedWorkerIds]);

  const workersOnCrew: WorkerOptionsType[] = useMemo(() => {
    return workersOnProject.project_worker_connection.edges.filter((pw) =>
      selectedWorkerIds.some((pwId) => pwId === pw.node.pk),
    );
  }, [selectedWorkerIds]);

  return (
    <Modal
      open={visible}
      title="Create New Crew"
      okText="Create"
      destroyOnClose
      cancelText="Cancel"
      confirmLoading={loading}
      onCancel={handleCancel}
      onOk={async () => {
        const values = await form.validateFields().catch(e => null);
        if (!values)
            return;
        setLoading(true);
        try {
              const leadProjectWorkerId: string | undefined =
                values.leadProjectWorkerId;
              const toAddProjectWorkers: Array<string> =
                values.projectWorkerIds || [];
              if (leadProjectWorkerId) {
                toAddProjectWorkers.push(leadProjectWorkerId);
              }
              const newCrewName = values.name;
              const newCrewId = uuid.v4();
              console.log(leadProjectWorkerId, newCrewName, newCrewId);

              await upsertProjectCrew({
                variables: {
                  prevLeadsProjectCrewWhere: {
                    lead_foreman_project_worker_id: {
                      _in: toAddProjectWorkers,
                    },
                  },
                  setProjectWorkers: { project_crew_id: newCrewId },
                  projectWorkerWhere: {
                    id: {
                      _in: [
                        ...(leadProjectWorkerId ? [leadProjectWorkerId] : []),
                        ...(values.projectWorkerIds || []),
                      ],
                    },
                  },
                  newCrewObject: {
                    id: newCrewId,
                    name: newCrewName,
                    lead_foreman_project_worker_id: leadProjectWorkerId,
                    project_id: projectId,
                    subcontractor_id: subcontractorId,
                  },
                },
                updater: (store) => {
                  // const insertedCrew = store.getRootField(
                  //   "insert_project_crew_one"
                  // );
                  // const crewTableConn = ConnectionHandler.getConnection(
                  //   store.getRoot(),
                  //   "CrewTableQuery_project_crew_connection"
                  // );
                  // if (crewTableConn) {
                  //   const edges = crewTableConn.getLinkedRecords("edges");
                  //   if (edges) {
                  //     const edge = ConnectionHandler.createEdge(
                  //       store,
                  //       crewTableConn,
                  //       insertedCrew,
                  //       "edge"
                  //     );
                  //     ConnectionHandler.insertEdgeBefore(crewTableConn, edge);
                  //   }
                  // }
                },
              });
              onFinish();
              message.success("Crew Created");
        } finally {
          setLoading(false);
        }            
        form.resetFields();
        onCancel();
      }}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="name"
          label="Crew name"
          rules={[{ required: true, message: "Enter the crew name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="projectWorkerIds" label="Add Workers">
          <Select
            className="w-full"
            mode="multiple"
            showSearch
            onChange={(val) => {
              if (Array.isArray(val)) {
                if (val.length > 0) {
                  setSelectedWorkerIds([...val.map((v) => v.toString())]);
                }
              }
            }}
            filterOption={(input, option) => {
              if (!option?.children || !option.children[0]) return false;
              return (
                option.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
                0
              );
            }}
          >
            {workersOnProject.project_worker_connection.edges.map((w) => (
              <Select.Option key={w.node.pk} value={w.node.pk}>
                {w.node.user?.name}
                {w.node.hard_hat_number ? ", #" + w.node.hard_hat_number : ""}
                {w.node.title ? ", " + w.node.title.translation.en : ""}
                {(w.node.leading_project_crews_aggregate.aggregate?.count ||
                  0) > 0 ? (
                  <>
                    ,{" "}
                    <span className="text-interactive-primary">Crew Lead</span>
                  </>
                ) : (
                  ""
                )}

                <span className="italic float-right">
                  &nbsp;&nbsp; {crewName(w.node.project_crew)}
                </span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="leadProjectWorkerId"
          label="Crew Lead"
          rules={[{ required: false, message: "Lead Foreman" }]}
        >
          <Select
            className="w-full"
            showSearch
            filterOption={(input, option) => {
              if (!option?.children || !option.children[0]) return false;
              return (
                option.children[0].toLowerCase().indexOf(input.toLowerCase()) >=
                0
              );
            }}
          >
            {workersOnCrew.length > 0 ? (
              <>
                <Select.OptGroup key={1} label={"Workers on Crew"}>
                  {workersOnCrew.map((w) => (
                    <Select.Option key={w.node.pk} value={w.node.pk}>
                      {w.node.user?.name}
                      {w.node.hard_hat_number
                        ? ", #" + w.node.hard_hat_number
                        : ""}
                      {w.node.title ? ", " + w.node.title.translation.en : ""}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
                <Select.OptGroup key={2} label={"Others"}>
                  {workersNotOnCrew.map((w) => (
                    <Select.Option key={w.node.pk} value={w.node.pk}>
                      {w.node.user?.name}
                      {w.node.hard_hat_number
                        ? ", #" + w.node.hard_hat_number
                        : ""}
                      {w.node.title ? ", " + w.node.title.translation.en : ""}
                      {(w.node.leading_project_crews_aggregate.aggregate
                        ?.count || 0) > 0 ? (
                        <>
                          ,{" "}
                          <span className="text-interactive-primary">
                            Crew Lead
                          </span>
                        </>
                      ) : (
                        ""
                      )}

                      <span className="italic float-right">
                        &nbsp;&nbsp; {crewName(w.node.project_crew)}
                      </span>
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              </>
            ) : (
              workersNotOnCrew.map((w) => (
                <Select.Option key={w.node.pk} value={w.node.pk}>
                  {w.node.user?.name}
                  {w.node.hard_hat_number ? ", #" + w.node.hard_hat_number : ""}
                  {w.node.title ? ", " + w.node.title.translation.en : ""}
                  {(w.node.leading_project_crews_aggregate.aggregate?.count ||
                    0) > 0 ? (
                    <>
                      ,{" "}
                      <span className="text-interactive-primary">
                        Crew Lead
                      </span>
                    </>
                  ) : (
                    ""
                  )}

                  <span className="italic float-right">
                    &nbsp;&nbsp; {crewName(w.node.project_crew)}
                  </span>
                </Select.Option>
              ))
            )}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNewCrewModal;
