import MonthDateRange from "src/common/components/general/MonthDateRange";
import {
  General_Contractor_Employee_Bool_Exp,
  Project_Bool_Exp,
  useGenerateGcObsLeaderboardPdfMutation,
  GetObsLeaderboardDataQuery,
  GetObsLeaderboardDataQueryVariables,
  useGetObsLeaderboardDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useMemo, useState } from "react";
import { Button, Segmented, Tooltip } from "antd";
import {
  DownloadOutlined,
  PieChartOutlined,
  TableOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import GCAdminHeaderFilterButton from "../filter-inputs/GCAdminHeaderFilterButton";
import GCAdminHeaderFilterTags from "../filter-inputs/GCAdminHeaderFilterTags";
import { GCAdminSelectFilterProps } from "../filter-inputs/GCAdminSelectFilter";
import LeaderboardCharts from "./leaderboard/LeaderboardCharts";
import LeaderboardTable from "./leaderboard/LeaderboardTable";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";

export interface GCObsLeaderboardProps {
  gcId: string;
  gcEmployeeWhere: General_Contractor_Employee_Bool_Exp;
  projWhere: Project_Bool_Exp;
  organization_level: "gc" | "business_unit" | "division" | "office";
  organization_unit_id: string;
}

type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

const GCObsLeaderboard: React.FC<GCObsLeaderboardProps> = ({
  gcId,
  organization_level,
  organization_unit_id,
  gcEmployeeWhere,
  projWhere,
}) => {
  const [startMonth, setStartMonth] = useState<dayjs.Dayjs | null>(dayjs());
  const [endMonth, setEndMonth] = useState<dayjs.Dayjs | null>(dayjs());
  const [generateObsleaderboardPdf, { loading: downloading }] =
    useGenerateGcObsLeaderboardPdfMutation();
  const [viewType, setViewType] = useState("table");
  const start = (startMonth || dayjs()).startOf("month");
  const end = (endMonth || dayjs()).add(1, "month").startOf("month");
  const variables: GetObsLeaderboardDataQueryVariables = {
    projWhere,
    gcEmployeeWhere,
    timestampComparisonExp: {
      _lt: end.toISOString(),
      _gte: start.toISOString(),
    },
    obsReqWhere: {
      general_contractor_id: { _eq: gcId },
      archived_at: { _is_null: true },
    },
  };
  const monthsCount = dayjs(end).diff(dayjs(start), "month");
  const {
    data,
    error,
    loading: dataLoading,
  } = useGetObsLeaderboardDataQuery({
    variables: variables,
  });

  if (error) throw error;
  const [projectIdsFilter, handleProjectChange, setProjectIdsFilter] =
    useSelectedIndicies<string>();
  const [titleIdsFilter, handleTitleChange, setTitleIdsFilter] =
    useSelectedIndicies<string>();
  const [groupIdsFilter, handleGroupChange, setGroupIdsFilter] =
    useSelectedIndicies<string>();
  const selectFilters: GCAdminSelectFilterProps[] = useMemo(
    () =>
      !data
        ? []
        : [
            {
              options: data.project.map((p) => ({
                value: p.id,
                label: p.name,
              })),
              title: "Projects",
              placeholder: "All Projects Included",
              selectedItems: projectIdsFilter,
              handleItemChange: handleProjectChange,
              setSelectedItems: setProjectIdsFilter,
            },
            {
              options: data.observation_requirement.map((req) => ({
                value: req.id,
                label: req.group_name,
              })),
              title: "Groups",
              placeholder: "All Groups Included",
              selectedItems: groupIdsFilter,
              handleItemChange: handleGroupChange,
              setSelectedItems: setGroupIdsFilter,
            },
            {
              options: data.employee_title.map((title) => ({
                value: title.id,
                label: title.name.en,
              })),
              title: "Titles",
              placeholder: "All Titles Included",
              selectedItems: titleIdsFilter,
              handleItemChange: handleTitleChange,
              setSelectedItems: setTitleIdsFilter,
            },
          ],
    [
      data?.observation_requirement,
      data?.project,
      data?.employee_title,
      groupIdsFilter,
      titleIdsFilter,
      projectIdsFilter,
    ],
  );
  const titleReqMap = useMemo(
    () =>
      new Map<
        string,
        GetObsLeaderboardDataQuery["observation_requirement"][number]
      >(
        (data?.observation_requirement || []).flatMap((req) =>
          req.employee_titles.map(({ employee_title_id }) => [
            employee_title_id,
            req,
          ]),
        ),
      ),
    [data?.observation_requirement],
  );
  const props = {
    users: data?.user || [],
    monthsCount,
    titleReqMap,
    projectIdsFilter,
    titleIdsFilter,
    groupIdsFilter,
  };

  return (
    <div>
      <Segmented
        value={viewType}
        options={[
          {
            label: (
              <>
                <TableOutlined /> Table
              </>
            ),
            value: "table",
          },
          {
            label: (
              <>
                <PieChartOutlined /> Charts
              </>
            ),
            value: "charts",
          },
        ]}
        onChange={(value) => setViewType(value)}
      />
      <br />
      <br />
      <MonthDateRange
        endMonth={endMonth}
        startMonth={startMonth}
        onEndMonthChange={setEndMonth}
        onStartMonthChagne={setStartMonth}
      />
      <Tooltip title={`Download PDF`}>
        <Button
          className="ml-1"
          icon={<DownloadOutlined />}
          onClick={async () => {
            const { data } = await generateObsleaderboardPdf({
              variables: {
                input: {
                  gcId,
                  organization_level,
                  organization_unit_id,
                  end_time: end.toISOString(),
                  start_time: start.toISOString(),
                  user_timezone: dayjs.tz.guess(),
                  projectIdsFilter: [...projectIdsFilter],
                  titleIdsFilter: [...titleIdsFilter],
                  groupIdsFilter: [...groupIdsFilter],
                },
              },
            });
            if (data) {
              downloadFromUrl(data.generateGcObsLeaderboardPdf);
            }
          }}
          loading={downloading}
        />
      </Tooltip>
      <div className="flex flex-col gap-0.5 mt-0.5">
        <div>
          <GCAdminHeaderFilterButton filters={{ selectFilters }} showDone />
        </div>
        <GCAdminHeaderFilterTags filters={{ selectFilters }} />
      </div>
      <br />
      <br />
      {dataLoading ? (
        <LargeTableSkeleton />
      ) : viewType === "table" ? (
        <LeaderboardTable {...props} />
      ) : (
        <LeaderboardCharts {...props} />
      )}
    </div>
  );
};
export default GCObsLeaderboard;
