import React, { useState } from "react";
import { Modal, Form, Input, Space, Checkbox } from "antd";

interface Values {
  description: string;
  selectedOptions: Array<string>;
}

interface Props {
  visible: boolean;
  onCreate: (values: Values) => void;
  onCancel: () => void;
}

const options = [
  {
    name: "yesEnabled",
    title: "Yes",
  },
  {
    name: "noEnabled",
    title: "No",
  },
  {
    name: "naEnabled",
    title: "N/A",
  },
];

const AddPermitChecklistItemModal: React.FC<Props> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [textEnabled, setTextEnabled] = useState(false);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
    setTextEnabled(false);
  };

  return (
    <Modal
      open={visible}
      title="Add permit checklist item"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={async () => {
        const values = await form.validateFields().catch((v) => null);
        if (!values) return;
        onCreate(values);
        form.resetFields();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Enter a description" }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
        <p>
          Phrase all questions to be answered with either a Yes or N/A to be
          approved.
        </p>
        <p>
          A permit cannot be submitted or closed unless each item is answered
          with either a Yes or N/A.
        </p>
        <Form.Item name="selectedOptions">
          <Checkbox.Group>
            {options.map((o) => (
              <Checkbox value={o.name} key={o.name}>
                {o.title}
              </Checkbox>
            ))}
            <Checkbox
              value="textEnabled"
              onChange={(e) => {
                if (e.target.checked) {
                  setTextEnabled(true);
                }
              }}
            >
              Text input
            </Checkbox>
            {textEnabled && (
              <Checkbox value="textRequired">Text required</Checkbox>
            )}
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPermitChecklistItemModal;
