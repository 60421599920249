import withAppPageLayout from "../../../../../common/components/layouts/main/content/withAppPageLayout";
import { Button, Card, Input, Menu, Typography } from "antd";
import React from "react";
import { MaterialSymbol, MaterialSymbolProps } from "react-material-symbols";
import ProfileCard from "../../../../../common/components/ProfileCard";

const IconsDemo: React.FC = () => {
  const iconProps: MaterialSymbolProps = {
    icon: "circle",
  };

  return (
    <div className={`flex flex-col gap-1`}>
      <Card>
        <div className={`grid grid-cols-3 gap-1`}>
          <Card title={"Menu"}>
            <Menu
              selectedKeys={["user"]}
              items={[
                {
                  icon: <MaterialSymbol {...iconProps} />,
                  label: "User",
                  key: "user",
                },
                {
                  icon: <MaterialSymbol {...iconProps} />,
                  label: "User",
                  key: "user1",
                },
              ]}
            />
          </Card>
          <Card title={`div.flex`}>
            <div className={`flex flex-row items-center gap-1`}>
              <MaterialSymbol {...iconProps} />
              <Typography.Text>User</Typography.Text>
            </div>
          </Card>
          <Card title={"Button of type text"}>
            <Button icon={<MaterialSymbol {...iconProps} />} type={"text"}>
              User
            </Button>
          </Card>
          <Card title={"Regular Button"}>
            <Button icon={<MaterialSymbol {...iconProps} />}>User</Button>
          </Card>
          <Card title={"Input"}>
            <Input prefix={<MaterialSymbol {...iconProps} />} value={"User"} />
          </Card>
          <Card title={"Typography"}>
            <Typography>
              <Typography.Paragraph>
                <MaterialSymbol {...iconProps} />
                <Typography.Text>
                  pP japwofeij poasiejf poiasj efopiasefpoi jasepfioj asepoif
                  asopije fopisaej foipasj efopijaseopfij asopijfe oipasjefo
                  ijsaeofij{" "}
                </Typography.Text>
              </Typography.Paragraph>
              <Typography.Paragraph>
                <MaterialSymbol {...iconProps} />
                <Typography.Text>Pp</Typography.Text>
              </Typography.Paragraph>
            </Typography>
          </Card>
        </div>
      </Card>

      <Card>
        <ProfileCard
          fullName={"Full Name"}
          company={"Company"}
          avatar={{
            src: "https://i.pravatar.cc/300",
          }}
          bio={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
          email={`email@via.placeholder.com`}
          phoneNumber={`+1 234 567 890`}
          position={"Position"}
          hardHatNumber={`123456`}
          userName={"userName"}
        />
      </Card>
    </div>
  );
};

export default withAppPageLayout(IconsDemo, {
  pageTitle: "Icons Demo",
});
