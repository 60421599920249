import React, { useRef } from "react";
import VisitorSpreadsheetModal, {
  VisitorSpreadsheetModalRef,
} from "src/domain-features/sitesafety/entry-routes/visitor/components/VisitorSpreadsheetModal";
import { useGenerateVisitorSpreadsheetMutation } from "src/common/types/generated/apollo/graphQLTypes";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import VisitorsTable from "src/domain-features/sitesafety/entry-routes/visitor/components/VisitorsTable";
import { useNavigate } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay/hooks";
import downloadFromUrl from "src/common/functions/downloadFromUrl";

import useAuthUser from "src/common/hooks/useAuthUser";
import getGcProjectSettingsPath from "../../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";
import withProjectIdUrlParam from "../../../../../common/hoc/withProjectIdUrlParam";
import { graphql } from "babel-plugin-relay/macro";
import { GCProjectVisitorsQuery } from "src/common/types/generated/relay/GCProjectVisitorsQuery.graphql";
import AppPageLayout from "../../../../../common/components/layouts/main/content/AppPageLayout";
import { SettingOutlined } from "@ant-design/icons";
import { MaterialSymbol } from "react-material-symbols";

export const gcProjectVisitorsQuery = graphql`
  query GCProjectVisitorsQuery($projectId: uuid!, $empId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          is_visitor_log_and_tracking_active
          visitor_sign_waiver
          visitor_waiver_text
        }
      }
    }
    general_contractor_connection(
      where: { employees: { uid: { _eq: $empId } } }
    ) {
      edges {
        node {
          visitor_waiver_text
        }
      }
    }
  }
`;

const GCProjectReportVisitors: React.FunctionComponent<ProjectIdProps> = ({
  projectId,
}) => {
  const emailOpen = useRef<VisitorSpreadsheetModalRef>(null);
  const [visitorSpreadsheet] = useGenerateVisitorSpreadsheetMutation();
  const navigate = useNavigate();

  const authUser = useAuthUser();
  const handelDownloadButtonClick = () => {
    emailOpen.current?.open();
  };

  const data = useLazyLoadQuery<GCProjectVisitorsQuery>(
    gcProjectVisitorsQuery,
    { projectId: projectId, empId: authUser.uid },
    { fetchPolicy: "store-or-network" },
  );

  return (
    <>
      {data.project_connection.edges[0].node
        .is_visitor_log_and_tracking_active ? (
        <>
          <VisitorSpreadsheetModal
            ref={emailOpen}
            onCancel={() => emailOpen.current?.close()}
            onCreate={async (values) => {
              emailOpen.current?.close();

              const { data: visitorData } = await visitorSpreadsheet({
                variables: {
                  input: {
                    projectId,
                    startAt: values.startDate.startOf("day").toString(),
                    endAt: values.endDate.endOf("day").toString(),
                  },
                },
              });

              if (visitorData?.generateVisitorSpreadsheet) {
                downloadFromUrl(visitorData.generateVisitorSpreadsheet);
              }
            }}
          />

          <VisitorsTable
            topBarButtons={[
              {
                secondary: true,
                onClick: handelDownloadButtonClick,
                icon: <MaterialSymbol icon="download" />,
                label: "Download data in excel",
              },
            ]}
            showDateRangeFilter={true}
            projectId={projectId}
            onRowClick={(pk) => {
              // props.openRow({ reportId: pk });
            }}
            where={{
              project_id: {
                _eq: projectId,
              },
            }}
          />
        </>
      ) : (
        <div className="flex justify-center mt-3">
          <h1>
            Turn on the visitor log and tracking from the project settings
          </h1>
        </div>
      )}
    </>
  );
};

const GCProjectVisitorsPage: React.FC<ProjectIdProps> = (props) => {
  const navigate = useNavigate();
  const goToSettings = () => {
    navigate(getGcProjectSettingsPath(props.projectId, "visitors"));
  };
  return (
    <AppPageLayout
      pageTitle={"Visitors"}
      pageActions={[
        {
          children: "Settings",
          onClick: goToSettings,
          icon: <SettingOutlined />,
        },
      ]}
    >
      <GCProjectReportVisitors projectId={props.projectId} />
    </AppPageLayout>
  );
};

export default withProjectIdUrlParam(GCProjectVisitorsPage);
