import { message, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { FC, useState } from "react";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import PdfViewer from "src/common/components/pdf/PdfViewer";
import { auth } from "src/common/functions/firebase";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  AddSdsFromCompanyLibraryModalMutation,
  AddSdsFromCompanyLibraryModalMutation$data,
} from "src/common/types/generated/relay/AddSdsFromCompanyLibraryModalMutation.graphql";
import type {
  safety_data_sheet_bool_exp,
  safety_data_sheet_insert_input,
} from "src/common/types/generated/relay/types";
import SdsTable from "src/domain-features/sitesafety/safety-data-sheets/components/SdsTable";
import * as uuid from "uuid";
import { SDS } from "./SdsTable";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

const insertSdsMutation = graphql`
  mutation AddSdsFromCompanyLibraryModalMutation(
    $objects: [safety_data_sheet_insert_input!]!
  ) {
    insert_safety_data_sheet(objects: $objects) {
      returning {
        ...SdsFrag @relay(mask: false)
      }
    }
  }
`;

interface AddSdsFromCompanyLibraryModalProps {
  refresh: () => void;
  visible: boolean;
  closeModal: () => void;
  subcontractorId?: string;
  projectId?: string;
  title: string;
  projectSubcontractorData?: Array<{ id: string; name: string }>;
  tableWhere: safety_data_sheet_bool_exp;
  showProjectColumn?: boolean;
}

const AddSdsFromCompanyLibraryModal: FC<AddSdsFromCompanyLibraryModalProps> = ({
  refresh,
  subcontractorId,
  closeModal,
  projectId,
  visible,
  title,
  projectSubcontractorData,
  tableWhere,
  showProjectColumn,
}) => {
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);
  const [subcontractor, setSubcontractor] = useState<undefined | string>(
    undefined,
  );
  const [curRows, setCurRows] = useState<Array<SDS>>([]);
  const [insertSds, loading] =
    useAsyncMutation<AddSdsFromCompanyLibraryModalMutation>(insertSdsMutation);

  const handleInsertSafetyDataSheetsUpdater = (
    store: RecordSourceSelectorProxy<AddSdsFromCompanyLibraryModalMutation$data>,
  ) => {
    const insertSafetyDataSheets = store.getRootField(
      "insert_safety_data_sheet",
    );
    const conn1 = ConnectionHandler.getConnection(
      store.getRoot(),
      "SCSds_safety_data_sheet_connection",
    );
    const conn2 = ConnectionHandler.getConnection(
      store.getRoot(),
      "ProjectSubcontractorSdsTable_safety_data_sheet_connection",
    );
    const conn3 = ConnectionHandler.getConnection(
      store.getRoot(),
      "SubProjectMobilizationView_safety_data_sheet_connection",
    );

    if (conn1 && insertSafetyDataSheets) {
      insertSafetyDataSheets.getLinkedRecords("returning")?.forEach((c) => {
        const edge = store.create(uuid.v4(), "edge");
        edge.setLinkedRecord(insertSafetyDataSheets, "node");
        ConnectionHandler.insertEdgeAfter(conn1, edge);
      });
    }
    if (conn2 && insertSafetyDataSheets) {
      insertSafetyDataSheets.getLinkedRecords("returning")?.forEach((c) => {
        const edge = store.create(uuid.v4(), "edge");
        edge.setLinkedRecord(insertSafetyDataSheets, "node");
        ConnectionHandler.insertEdgeAfter(conn2, edge);
      });
    }
    if (conn3 && insertSafetyDataSheets) {
      insertSafetyDataSheets.getLinkedRecords("returning")?.forEach((c) => {
        const edge = store.create(uuid.v4(), "edge");
        edge.setLinkedRecord(insertSafetyDataSheets, "node");
        ConnectionHandler.insertEdgeAfter(conn3, edge);
      });
    }
  };

  const onOkEvent = async (sdsToAdd: Array<SDS>) => {
      await insertSds({
        variables: {
          objects: sdsToAdd.map((sds) => {
            const sdsInsertInput: safety_data_sheet_insert_input = {
              project_id: projectId,
              created_by_uid: auth.currentUser?.uid,
              subcontractor_id: subcontractorId
                ? subcontractorId
                : subcontractor,
              product_name: sds.product_name,
              company_name: sds.company_name,
              reference_number: sds.reference_number,
              issue_date: sds.issue_date,
              file_type: sds.file_type,
              url: sds.url,
              company_safety_data_sheet_id: sds.pk,
            };
            return sdsInsertInput;
          }),
        },
        updater: handleInsertSafetyDataSheetsUpdater,
      });
    message.success(`${sdsToAdd.length} SDS added`);
    refresh();
    closeModal();
  };

  return (
    <>
      <PdfViewer
        visible={!!pdfUrl}
        onClose={() => setPdfUrl(undefined)}
        pdfUrl={pdfUrl}
      />
      <Modal
        open={visible}
        destroyOnClose
        confirmLoading={loading}
        onCancel={closeModal}
        onOk={() => {
          onOkEvent(curRows);
        }}
        title={title}
        className="min-w-40 max-w-40"
      >
        {projectSubcontractorData && (
          <div>
            <div>Add SDS to a Trade Partner</div>
            <Select
              placeholder={"Select Subcontractor to add SDS to"}
              className="mt-0.5 mb-1.5 min-w-full"
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              value={subcontractor}
              onChange={(e) => setSubcontractor(e)}
            >
              {projectSubcontractorData?.map((sub) => (
                <Select.Option key={sub.id} value={sub.id} label={sub.name}>
                  {" "}
                  {sub.name}
                </Select.Option>
              ))}
            </Select>
            <div className="text-0.75 text-lightgrey mt-0.25">
              Add these SDS to a Trade Partner or simply add them to the
              project. Everyone on the project has access to ALL SDS by scanning
              your Project QR Code. Adding to a company makes it easier to
              search and filter.
            </div>
          </div>
        )}

        <div className="mt-1 mb-0.5"> Select SDS to Add</div>

        <SdsTable
          onSdsRowClick={(sds) => setPdfUrl(sds.url)}
          excludedKeys={
            showProjectColumn
              ? [
                  "action",
                  "addToProjects",
                  "addedBy",
                  "addedDate",
                  "revNumber",
                  "delete",
                  "subcontractorName",
                  "sdsDate",
                ]
              : [
                  "action",
                  "addToProjects",
                  "addedBy",
                  "addedDate",
                  "revNumber",
                  "delete",
                  "subcontractorName",
                  "sdsDate",
                  "projectName",
                ]
          }
          title={""}
          where={tableWhere}
          loadAll
          loading
          hideFilter={true}
          hideCount
          updateSelectedSds={(sds: SDS, type: "add" | "remove") => {
            type === "add"
              ? setCurRows([...curRows, sds])
              : setCurRows(curRows.filter((row) => row.pk !== sds.pk));
          }}
        />
      </Modal>
    </>
  );
};

export default AddSdsFromCompanyLibraryModal;
