/**
 * @generated SignedSource<<e3b73aa0a9e856bfb1bf6c9e30673d85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { certificates_to_verify_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertCertificatesToVerifyMutation$variables = {
  objects: ReadonlyArray<certificates_to_verify_insert_input>;
};
export type InsertCertificatesToVerifyMutation$data = {
  readonly insert_certificates_to_verify: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type InsertCertificatesToVerifyMutation = {
  response: InsertCertificatesToVerifyMutation$data;
  variables: InsertCertificatesToVerifyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InsertCertificatesToVerifyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "certificates_to_verify_mutation_response",
        "kind": "LinkedField",
        "name": "insert_certificates_to_verify",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "certificates_to_verify",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InsertCertificatesToVerifyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "certificates_to_verify_mutation_response",
        "kind": "LinkedField",
        "name": "insert_certificates_to_verify",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "certificates_to_verify",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f50fe5ef8a251650f0e5017bf99cf221",
    "id": null,
    "metadata": {},
    "name": "InsertCertificatesToVerifyMutation",
    "operationKind": "mutation",
    "text": "mutation InsertCertificatesToVerifyMutation(\n  $objects: [certificates_to_verify_insert_input!]!\n) {\n  insert_certificates_to_verify(objects: $objects) {\n    affected_rows\n    returning {\n      id\n      pk: id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "834f45eaccf15fa0226e8e6cb2c7b4ff";

export default node;
