import { MaterialSymbol } from "react-material-symbols";
import { Image } from "antd";
import { ImageProps } from "antd/lib/image";
import clsx from "clsx";
import React, { FC, useEffect, useRef, useState } from "react";
import useMeasure from "react-use-measure";
import Button from "src/common/components/general/button/Button";

interface ResizeImageProps
  extends Omit<ImageProps, "className" | "wrapperClassName" | "width"> {
  onResize?: () => void;
}

const ResizeImage: FC<ResizeImageProps> = (props) => {
  const [imageError, setImageError] = useState(false);
  const [imageRef, imageBound] = useMeasure();
  const [wrapperRef, wrapperBound] = useMeasure();
  const [imageWidthPx, setImageWidthPx] = useState<number>();
  useEffect(() => {
    if (wrapperBound.height !== imageBound.height) {
      setImageWidthPx(
        (imageBound.width * wrapperBound.height) / imageBound.height,
      );
    }
  }, [imageBound.height, wrapperBound.height]);
  useEffect(() => {
    props.onResize?.();
  }, [imageWidthPx]);

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        `flex items-center justify-center h-full`,
        !imageWidthPx && "invisible",
      )}
    >
      <div ref={imageRef}>
        {imageError ? (
          <iframe width={960} height={600} src={props.src} title=""></iframe>
        ) : (
          <Image
            {...{
              className: `object-cover`,
              wrapperClassName: `block`,
              width: imageWidthPx ? `${imageWidthPx}px` : `auto`,
              ...props,
              onError: (e) => {
                console.log("ERROR IMAGE LOAD", e);
                setImageError(true);
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

interface Props {
  images: Array<{
    url: string;
  }>;
  profilePhoto?: boolean;
}

const sideScroll = (element: HTMLDivElement, direction: "right" | "left") => {
  const step = element.offsetWidth;
  element?.scroll({
    left: element.scrollLeft + step * (direction === "right" ? -1 : 1),
    behavior: "smooth",
  });
};

const ImageCarousel: FC<Props> = ({ images, profilePhoto }) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const imagesContainerRef = useRef<HTMLDivElement>(null);
  const [buttonLeftShown, setButtonLeftShown] = useState(false);
  const [buttonRightShown, setButtonRightShown] = useState(false);

  const calculateButtonsVisibility = () => {
    setButtonLeftShown(
      !!scrollableContainerRef.current &&
        scrollableContainerRef.current.scrollLeft > 0,
    );
    setButtonRightShown(
      !!scrollableContainerRef.current &&
        !!imagesContainerRef.current &&
        Math.ceil(scrollableContainerRef.current.scrollLeft) +
          scrollableContainerRef.current.offsetWidth <
          imagesContainerRef.current.offsetWidth,
    );
  };

  useEffect(() => {
    console.log("IMAGE CAROUSEL CALLED");
    const scrollRef = scrollableContainerRef.current;
    window.addEventListener("resize", calculateButtonsVisibility);
    scrollRef?.addEventListener("scroll", calculateButtonsVisibility);
    return () => {
      scrollRef?.removeEventListener("scroll", calculateButtonsVisibility);
      window.removeEventListener("resize", calculateButtonsVisibility);
    };
  }, []);

  const onRightButtonClick = () => {
    if (scrollableContainerRef.current) {
      sideScroll(scrollableContainerRef.current, "right");
    }
  };
  const onLeftButtonClick = () => {
    if (scrollableContainerRef.current) {
      sideScroll(scrollableContainerRef.current, "left");
    }
  };

  return (
    <div className={`relative w-full px-1.5 rounded`}>
      <div
        className={clsx(
          `absolute bg-white rounded-full p-0.5 left-0 top-1/2 transform -translate-y-1/2 rouded-2 z-10 transition-opacity`,
          buttonLeftShown ? "opacity-100" : "opacity-0",
        )}
      >
        <Button
          onClick={onRightButtonClick}
          icon={<MaterialSymbol icon="arrow_left" />}
          secondary
        ></Button>
      </div>

      <div
        className={clsx(
          profilePhoto ? `h-8` : `h-32`,
          `w-full max-h-screen overflow-x-scroll rounded-1`,
        )}
        ref={scrollableContainerRef}
      >
        <div
          ref={imagesContainerRef}
          className={clsx(
            profilePhoto ? `w-10` : `w-max`,
            `flex mx-auto h-full flex-row items-center justify-center gap-1`,
          )}
        >
          {images.map((image, i) => (
            <ResizeImage
              onResize={calculateButtonsVisibility}
              key={image.url}
              src={image.url}
              alt={image.url}
            />
          ))}
        </div>
      </div>
      <div
        className={clsx(
          `absolute bg-white rounded-full right-0 top-1/2 transform -translate-y-1/2 z-10 p-0.5 transition-opacity`,
          buttonRightShown ? "opacity-100" : "opacity-0",
        )}
      >
        <Button
          onClick={onLeftButtonClick}
          icon={<MaterialSymbol icon="arrow_right" />}
          secondary
        ></Button>
      </div>
    </div>
  );
};

export default ImageCarousel;
