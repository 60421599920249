import React, { useState } from "react";
import { Button, Select, Typography } from "antd";

import {
  GetIncidentByPkDocument,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  useDeleteInjuryDetailBodyPartAffectedMutation,
  useUpdateBodyPartByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import useUpdateInjuryBodyParts from "../utils/useUpdateInjuryBodyParts";
import createIncidentPatch from "../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

interface Props {
  options: Array<{ value: string; label: string }>;
  injuredUserId: string;
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
}

const InjuryDetailBodyParts: React.FC<Props> = ({
  options,
  incident,
  injuredUserId,
}) => {
  const [updateBodyPart] = useUpdateBodyPartByPkMutation();
  const [deleteBodyPart] = useDeleteInjuryDetailBodyPartAffectedMutation();
  const [insertBodyPart] = useUpdateInjuryBodyParts();
  const authUser = useAuthUser();

  const injuryDetail = incident.injured_users.find(
    (injuredUser) => injuredUser.id === injuredUserId,
  )?.injury_detail;
  if (!injuryDetail) throw new Error(" Injury Detail Not Found ");

  const [side, setSide] = useState<string | undefined>(undefined);
  const [value, setValue] = useState<string | undefined>(undefined);

  const bodyParts = injuryDetail.body_parts_affected.map((bodyPart) => ({
    value: bodyPart.value,
    side: bodyPart.side,
    id: bodyPart.id,
  }));

  const handleBodyPartUpdate = async (
    id: string,
    value: string,
    side?: string,
  ) => {
    const updatedIncident = {
      ...incident,
      injured_users: incident.injured_users.map((injuredUser) => {
        if (injuredUser.id === injuredUserId && injuredUser.injury_detail) {
          const bodyPartsAffected =
            injuredUser.injury_detail.body_parts_affected;
          return {
            ...injuredUser,
            injury_detail: {
              ...injuredUser.injury_detail,
              body_parts_affected: bodyPartsAffected.map((bodyPart) => {
                if (bodyPart.id === id) {
                  return { ...bodyPart, side: side, value: value };
                }
                return bodyPart;
              }),
            },
          };
        }
        return injuredUser;
      }),
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateBodyPart({
      variables: {
        id: id,
        _set: { value: value, side: side },
        editObjects: {
          patch: patch,
          comment: "Injury Detail Body Part Updated",
          edit_type: "body-part-update",
          incident_id: incident.id,
          edited_by_uid: authUser.uid,
        },
      },
      optimisticResponse: {
        update_injury_detail_body_part_affected_by_pk: {
          side: side,
          value: value,
          injury_detail_id: injuryDetail.id,
          id: id,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const handleBodyPartDeletion = async (id: string) => {
    const updatedIncident = {
      ...incident,
      injured_users: incident.injured_users.map((injuredUser) => {
        if (injuredUser.id === injuredUserId && injuredUser.injury_detail) {
          return {
            ...injuredUser,
            injury_detail: {
              ...injuredUser.injury_detail,
              body_parts_affected:
                injuredUser.injury_detail.body_parts_affected.filter(
                  (bodyPart) => bodyPart.id !== id,
                ),
            },
          };
        } else return injuredUser;
      }),
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = `Injury Detail Body Part deleted`;

    await deleteBodyPart({
      variables: {
        deleteWhere: { id: { _eq: id } },
        editObjects: {
          patch: patch,
          comment: comment,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "body-part-delete",
        },
      },
      update(cache) {
        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          query: GetIncidentByPkDocument,
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
        });
      },
    });
  };

  const addBodyParts = async (toBeInsertedBodyParts: {
    value: string;
    side?: string;
  }) => {
    await insertBodyPart(toBeInsertedBodyParts, incident, injuredUserId);
  };

  return (
    <div className="flex flex-col">
      <Typography.Paragraph>Specific Body Part Injured</Typography.Paragraph>
      <div>
        {bodyParts.map((bodyPart, index) => (
          <div key={index} className="flex flex-col gap-1">
            <Select
              value={bodyPart.value}
              onChange={(value) =>
                handleBodyPartUpdate(
                  bodyPart.id,
                  value,
                  bodyPart.side ?? undefined,
                )
              }
              options={options}
              placeholder="Select body part"
            />
            <Select
              value={bodyPart.side}
              onChange={(value) =>
                handleBodyPartUpdate(bodyPart.id, bodyPart.value, value)
              }
              options={[
                { label: "Left", value: "left" },
                { label: "Right", value: "right" },
              ]}
              placeholder="Select side"
            />

            <Button
              title="Delete this body part"
              className="w-5 mb-1"
              type="primary"
              danger={true}
              onClick={() => handleBodyPartDeletion(bodyPart.id)}
            >
              Delete
            </Button>
          </div>
        ))}

        <div className="flex flex-col gap-1">
          <Select
            onChange={(val) => setValue(val)}
            value={value}
            options={options}
            placeholder="Add New Body Part"
          />
          <Select
            onChange={(val) => setSide(val)}
            value={side}
            options={[
              { label: "Left", value: "left" },
              { label: "Right", value: "right" },
            ]}
            placeholder="Select side"
          />

          <Button
            type="primary"
            className="w-5 mb-1"
            disabled={!value}
            onClick={() => {
              if (value) {
                addBodyParts({ value: value, side: side });
                setValue(undefined);
                setSide(undefined);
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InjuryDetailBodyParts;
