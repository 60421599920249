import { Menu } from "antd";
import useGcEmployee from "../../../hierarchy/organization-unit/utils/useGcEmployee";
import useRoutedMenuProps from "../../../../../../../utility-features/navigation/routed-menu/useRoutedMenuProps";
import { RoutedMenuItem } from "../../../../../../../utility-features/navigation/routed-menu/routedMenuTypes";
import { MaterialSymbol } from "react-material-symbols";
import React, { useMemo } from "react";

const BASE_PATH = "/gce/hierarchy/corporate-office";

interface GCNavigationHierarchyMenuCorporateOfficeProps {}

const GCNavigationHierarchyMenuCorporateOffice: React.FC<
  GCNavigationHierarchyMenuCorporateOfficeProps
> = () => {
  const { general_contractor: gc } = useGcEmployee();

  const featureMenuItems: RoutedMenuItem[] = useMemo(() => [
    // TODO Add dashboard
    // {
    //   key: "dashboard",
    //   label: "Dashboard",
    //   icon: <MaterialSymbol icon={"dashboard"} />,
    // },
    {
      label: "Child Units",
      path: "child-units",
      icon: <MaterialSymbol icon={"home_pin"} />,
    },
    {
      label: "Projects",
      path: "projects",
      icon: <MaterialSymbol icon={"cases"} />,
    },
    {
      path: "workers",
      label: "Workers",
      icon: <MaterialSymbol icon={"construction"} />,
    },
    {
      path: "employees",
      label: "Employees",
      icon: <MaterialSymbol icon={"badge"} />,
    },
    ...(gc.observation_enabled
      ? [
          {
            label: "Observations",
            path: "observations",
            icon: <MaterialSymbol icon={"visibility"} />,
          },
        ]
      : []),
    {
      path: "incidents",
      label: "Incidents",
      icon: <MaterialSymbol icon={"report"} />,
    },
    {
      path: "permits",
      label: "Permits",
      icon: <MaterialSymbol icon={"task"} />,
    },
    {
      path: "orientations",
      label: "Orientations",
      icon: <MaterialSymbol icon={"school"} />,
    },
    {
      path: "admins",
      label: "Admins",
      icon: <MaterialSymbol icon={"supervisor_account"} />,
    },
    {
      path: "hierarchy",
      label: "Organization Hierarchy",
      icon: <MaterialSymbol icon={"account_tree"} />,
    },
    {
      path: "settings",
      label: "Settings",
      icon: <MaterialSymbol icon={"settings"} />,
    },
  ], [gc.observation_enabled]);

  const menuProps = useRoutedMenuProps(
    featureMenuItems,
    BASE_PATH,
    "Corporate Office",
  );

  return <Menu {...menuProps} />;
};

export default GCNavigationHierarchyMenuCorporateOffice;
