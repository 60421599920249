/**
 * @generated SignedSource<<a39f03421a82ac06f806480a65ab411b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_crew_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CrewTableTotalRefetchableQuery$variables = {
  where: project_crew_bool_exp;
};
export type CrewTableTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CrewTable_total">;
};
export type CrewTableTotalRefetchableQuery = {
  response: CrewTableTotalRefetchableQuery$data;
  variables: CrewTableTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CrewTableTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "CrewTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CrewTableTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allProjectCrewsConnection",
        "args": (v1/*: any*/),
        "concreteType": "project_crewConnection",
        "kind": "LinkedField",
        "name": "project_crew_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_crewEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_crew",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "457e58331b7a0d6d6942f924914e7f58",
    "id": null,
    "metadata": {},
    "name": "CrewTableTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query CrewTableTotalRefetchableQuery(\n  $where: project_crew_bool_exp!\n) {\n  ...CrewTable_total_3FC4Qo\n}\n\nfragment CrewTable_total_3FC4Qo on query_root {\n  allProjectCrewsConnection: project_crew_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a248d206372578b691e2b57172afe6ca";

export default node;
