import { Button } from "antd";
import React from "react";
import useSiteVisitor from "../utils/siteVisitorHook";

export interface VisitorTnCProps {
  gcName: string;
  onBack?: () => void;
}

const VisitorTnC: React.FC<VisitorTnCProps> = (props) => {
  const visitorContext = useSiteVisitor();
  return (
    <div className="flex flex-col items-center h-full mt-2">
      <div className="w-full text-center">
        <h2>{props.gcName + " Visitor Waiver"}</h2>
      </div>
      <div
        className="my-2 flex-grow w-full overflow-y-auto"
        style={{ maxHeight: "calc(100vh - 14rem)" }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: visitorContext.waiverText ?? "" }}
        ></div>
      </div>
      <div className="w-full px-2 sticky bottom-0">
        <Button onClick={props.onBack} block>
          Back
        </Button>
      </div>
    </div>
  );
};

export default VisitorTnC;
