import { MaterialSymbol } from "react-material-symbols";
import React, { useRef, useState } from "react";
import Button from "./button/Button";

interface SubmitHintProps {
  visible: boolean;
}

const SubmitHint: React.FunctionComponent<SubmitHintProps> = ({
  visible = false,
}) => {
  return (
    <div
      className={`flex flex-col items-end transition-opacity ${
        !visible ? "opacity-0" : ""
      }`}
    >
      <div className="h-0.25 rounded-t-0.25 bg-interactive-primary w-1.5"></div>
      <div className="flex bg-interactive-primary px-0.25 rounded-tl-0.25 rounded-b-0.25">
        <span className="text-white text-0.75"> search</span>
      </div>
    </div>
  );
};

interface TextInputProps {
  onSubmit?: (input: string) => boolean; // returns true if the input should be cleared
  inputOptions?: string[];
  placeholder?: string;
  darker?: boolean;
  allowClear?: boolean;
}

const TextInput: React.FunctionComponent<TextInputProps> = ({
  onSubmit,
  inputOptions,
  placeholder,
  darker,
  allowClear,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string>("");
  const [hintText, setHintText] = useState<string>("");
  const [showSubmitHint, setShowSubmitHint] = useState<boolean>(false);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(() => {
      let newValue = event.target.value + "";
      if (inputOptions?.length) {
        setHintText(() => {
          let newHintText =
            inputOptions &&
            newValue &&
            inputOptions.find(
              (text) =>
                text.substring(0, newValue.length).toLowerCase() ===
                newValue.toLowerCase(),
            );
          if (newHintText) {
            setShowSubmitHint(true);
            return newHintText;
          } else {
            setShowSubmitHint(false);
            return "";
          }
        });
      }
      return newValue;
    });
  };

  const handleClear = () => {
    setValue("");
    if (onSubmit) {
      onSubmit("");
    }
  };

  return (
    <div
      className={`flex flex-row items-center rounded-2 cursor-text ${
        darker ? "bg-suplementary-2" : "bg-suplementary-1"
      }`}
      onClick={handleClick}
    >
      <div
        className={`flex flex-1 flex-row pl-1.5 py-0.75 ${
          allowClear ? "pr-0" : "pr-1.5"
        }`}
      >
        <div className="flex flex-row items-center gap-0.5 flex-1">
          <MaterialSymbol icon="search" />
          <div className="grid flex-1">
            <input
              type="search"
              className="relative flex-1 w-full col-start-1 col-end-2 row-start-1 row-end-2"
              ref={inputRef}
              {...{ value }}
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={() => {
                setShowSubmitHint(false);
              }}
              onFocus={() => {
                setShowSubmitHint(true);
              }}
              onKeyPress={
                onSubmit
                  ? (e) => {
                      if (e.key === "Enter") {
                        if (onSubmit(hintText ? hintText : value)) {
                          setValue("");
                          setHintText("");
                          if (inputOptions?.length) {
                            setShowSubmitHint(false);
                          }
                        }
                      }
                    }
                  : undefined
              }
            />
            {hintText && (
              <div className="col-start-1 col-end-2 row-start-1 row-end-2 overflow-hidden">
                <span className="text-static-secondary whitespace-nowrap">
                  {value + hintText.substring(value.length, hintText.length)}
                </span>
              </div>
            )}
          </div>
          {!!onSubmit && <SubmitHint visible={showSubmitHint} />}
        </div>
      </div>
      {!!allowClear && (
        <div className="px-0.5">
          {!!value.length && (
            <Button
              icon={<MaterialSymbol icon="close" />}
              secondary
              small
              onClick={handleClear}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TextInput;
