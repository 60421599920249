import React from "react";
import CustomSelect, {
  CustomSelectProps,
} from "src/common/components/general/CustomSelect";

interface WorkerProfileSubcontractorProps extends CustomSelectProps {}

const WorkerProfileSubcontractor: React.FunctionComponent<
  WorkerProfileSubcontractorProps
> = (props) => {
  return <CustomSelect {...props} />;
};

export default WorkerProfileSubcontractor;
