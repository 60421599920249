import { MaterialSymbol } from "react-material-symbols";
import React from "react";
import Icon from "src/common/components/general/Icon";
import dayjs from "dayjs";

export interface BlockOutDeliveryTimesTableColumnTimeBlockProps {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  onRemove?: () => void;
}

const BlockOutDeliveryTimesTableColumnTimeBlock: React.FunctionComponent<
  BlockOutDeliveryTimesTableColumnTimeBlockProps
> = (props) => {
  const interactive = !!props.onRemove;

  return (
    <div
      className={`flex flex-row items-center justify-center p-0.25 rounded-0.25 border  relative select-none ${
        interactive
          ? "cursor-pointer group hover:border-interactive-primary transition-all border-interactive-secondary"
          : "border-suplementary-1"
      }`}
    >
      <span
        className={`transition-opacity opacity-100 group-hover:opacity-20 text-center`}
      >
        {`${props.start.format("h:mm a")} → ${props.end.format("h:mm a")}`}{" "}
      </span>
      <div
        onClick={props.onRemove}
        className="absolute inset-0 flex items-center justify-center transition-opacity opacity-0 group-hover:opacity-100"
      >
        <Icon color="interactive" icon={<MaterialSymbol icon="close" />} />
      </div>
    </div>
  );
};

export default BlockOutDeliveryTimesTableColumnTimeBlock;
