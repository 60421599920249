import React, { PropsWithChildren } from "react";
import Collapse, {
  CollapseProps,
} from "../../../../../common/components/general/Collapse";
import clsx from "clsx";
import { MaterialSymbol } from "react-material-symbols";

export interface SiteOrientationInPersonCollapseProps
  extends Pick<CollapseProps, "hide" | "expand" | "expanded"> {
  headText: React.ReactNode;
}

const SiteOrientationInPersonCollapse: React.FC<
  PropsWithChildren<SiteOrientationInPersonCollapseProps>
> = (props) => {
  return (
    <Collapse
      {...{
        ...props,
        head: (
          <div
            className={clsx(
              `flex flex-row items-center gap-0.5 top-0 px py-0.5 bg-white`,
              !props.children && "opacity-20",
            )}
          >
            <MaterialSymbol
              icon="keyboard_arrow_down"
              className={`transition-transform`}
              style={{
                transform: props.expanded ? "rotate(0deg)" : "rotate(-90deg)",
              }}
            />
            {typeof props.headText === "string" ? (
              <p>{props.headText}</p>
            ) : (
              props.headText
            )}
          </div>
        ),
      }}
    >
      {props.children}
    </Collapse>
  );
};

export default SiteOrientationInPersonCollapse;
