import { FC, useMemo } from "react";
import { Button, Drawer, Menu, message, Popover, Select, Spin } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  GetIncidentByPkDocument,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  useEmailIncidentPdfMutation,
  useGenerateIncidentPdfMutation,
  useGetMonetarySeverityLevelsQuery,
  useUpdateIncidentMutation,
  useUpdateIncidentUserMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import getMenuItemLabel from "../../utils/getMenuItemLabel";
import getMenuItemIcon from "../../utils/getMenuItemIcon";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { useSuspenseQuery } from "@apollo/client";
import { InfoCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Icon from "src/common/components/general/Icon";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentComponentRenderer from "../../components/IncidentComponentRenderer";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import getGcProjectPath from "../../../../root/routes/views/general-contractor/hierarchy/projects/getGcProjectPath";
import getGcCorporateOfficePath from "../../../../root/routes/views/general-contractor/hierarchy/corporate-office/utils/getGcCorporateOfficePath";
import { MaterialSymbol } from "react-material-symbols";

const GcIncidentManagement: FC = () => {
  const { projectId, incidentId } = useParams();
  if (!incidentId) throw new Error("incidentId not found");
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const subview = searchParams.get("subview");
  const incidentUserId = searchParams.get("id");

  const [emailIncidentPdf, { loading: emailing }] =
    useEmailIncidentPdfMutation();
  const [generateIncidentPdf, { loading: generating }] =
    useGenerateIncidentPdfMutation();
  const [updateIncident] = useUpdateIncidentMutation();
  const [updateIncidentUser] = useUpdateIncidentUserMutation();

  const { loading: monetaryLoading, error: monetaryError } =
    useGetMonetarySeverityLevelsQuery(); // used in child component queries
  const { data: incidentData, refetch } = useSuspenseQuery<
    GetIncidentByPkQuery,
    GetIncidentByPkQueryVariables
  >(GetIncidentByPkDocument, {
    variables: {
      incidentId: incidentId,
    },
  });

  const incident = incidentData.incident_by_pk;

  if (!incident) throw new Error("Incident not found for given incident id");

  const allIncidentTypes = [
    "environmental",
    "equipment",
    "near_miss",
    "property_damage",
    "vehicle",
    "utility",
    "theft",
  ];
  // Has not included injury_illness to these types as it is located at a fixed position in the Menu Item.

  const { selectedIncidentTypes, nonSelectedIncidentTypes } = useMemo(() => {
    const selectedIncidentTypes: Array<string> = [];
    const nonSelectedIncidentTypes: Array<string> = [];
    for (const type of allIncidentTypes) {
      if (
        incident.incident_types.findIndex(
          (incidentType) => incidentType.type_value === type,
        ) !== -1
      ) {
        selectedIncidentTypes.push(type);
      } else {
        nonSelectedIncidentTypes.push(type);
      }
    }
    return { selectedIncidentTypes, nonSelectedIncidentTypes };
  }, [incident.incident_types]);

  const deleteIncidentUser = async (
    id: string,
    type: "witness" | "injured_user",
  ) => {
    const incidentUsers =
      type === "witness" ? incident.witnesses : incident.injured_users;

    const userName = incidentUsers.find(
      (incidentUser) => incidentUser.id === id,
    )?.user.name;
    const filteredIncidetUsers = incidentUsers.filter(
      (incidentUser) => incidentUser.id !== id,
    );

    const updatedIncident =
      type === "injured_user"
        ? {
            ...incident,
            injured_users: filteredIncidetUsers,
          }
        : { ...incident, witnesses: filteredIncidetUsers };

    const patch = createIncidentPatch(updatedIncident, incident);

    const comment = `Deleted the ${
      type === "injured_user" ? "injured user" : "witness"
    } ${userName}`;

    await updateIncidentUser({
      variables: {
        id: id,
        set: { deleted_at: dayjs().format() },
        objects: {
          patch: patch,
          comment: comment,
          edit_type: "deleted-incident-user",
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
        },
      },
      update(cache, result) {
        const incidentUserId = result.data?.update_incident_user_by_pk?.id;
        if (!incidentUserId) return;

        const incidentUsers =
          type === "witness" ? incident.witnesses : incident.injured_users;

        const filteredIncidetUsers = incidentUsers.filter(
          (incidentUser) => incidentUser.id !== incidentUserId,
        );
        const updatedIncident =
          type === "injured_user"
            ? {
                ...incident,
                injured_users: filteredIncidetUsers,
              }
            : { ...incident, witnesses: filteredIncidetUsers };

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          query: GetIncidentByPkDocument,
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
        });
      },
    });

    const incidentUserId = searchParams.get("id");

    if (incidentUserId === id) {
      setSearchParams({ subview: "incident_detail" });
    }
  };

  const selectedItem = incidentUserId
    ? `${subview}&&${incidentUserId}`
    : subview;

  const incidentUsersData: Array<{
    key: string;
    label: JSX.Element;
  }> = [];

  const witnessUsers: Array<{
    key: string;
    label: JSX.Element;
  }> = [];

  if (!!incident?.injured_users && Array.isArray(incident.injured_users)) {
    for (const user of incident.injured_users) {
      incidentUsersData.push({
        key: `injured_user&&${user.id}`,
        label: (
          <div className="flex flex-row">
            <div className="min-w-10 max-w-10"> {user.user.name} </div>
            <div
              className="mt-0.5 ml-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <BPopconfirm
                title="Are you sure you want to remove this person?"
                onConfirm={() => deleteIncidentUser(user.id, "injured_user")}
                okText="Yes"
                cancelText="No"
              >
                <MaterialSymbol icon="delete" />
              </BPopconfirm>
            </div>
          </div>
        ),
      });
    }
  }

  if (!!incident.witnesses && Array.isArray(incident.witnesses)) {
    for (const user of incident.witnesses) {
      witnessUsers.push({
        key: `witness&&${user.id}`,
        label: (
          <div className="flex flex-row">
            <div className="min-w-10 max-w-10"> {user.user.name} </div>
            <div
              className="mt-0.5 ml-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <BPopconfirm
                title="Are you sure you want to remove this person?"
                onConfirm={() => deleteIncidentUser(user.id, "witness")}
                okText="Yes"
                cancelText="No"
              >
                <Icon
                  icon={<MaterialSymbol icon="delete" />}
                  color="negative"
                />
              </BPopconfirm>
            </div>
          </div>
        ),
      });
    }
  }

  incidentUsersData.push({
    key: "injured_user",
    label: <span>+ Add Person</span>,
  });
  witnessUsers.push({ key: "witness", label: <span> + Add Person </span> });

  const menuItems = [
    {
      label: getMenuItemLabel(incident, "project"),
      key: "project_info",
      icon: getMenuItemIcon("project"),
    },
    {
      label: getMenuItemLabel(incident, "incident_detail"),
      key: "incident_detail",
      icon: getMenuItemIcon("incident_detail"),
    },
    {
      label: getMenuItemLabel(incident, "injury_illness"),
      key: "injury_detail",
      icon: getMenuItemIcon("injury_illness"),
      children: incidentUsersData,
    },
    {
      label: getMenuItemLabel(incident, "root_cause"),
      key: "root_cause",
      icon: getMenuItemIcon("root_cause"),
    },
    {
      label: getMenuItemLabel(incident, "witness"),
      key: "witness_detail",
      icon: getMenuItemIcon("witness"),
      children: witnessUsers,
    },
  ];

  for (const type of selectedIncidentTypes) {
    menuItems.push({
      label: getMenuItemLabel(incident, type),
      key: type,
      icon: getMenuItemIcon(type),
    });
  }

  for (const type of nonSelectedIncidentTypes) {
    menuItems.push({
      label: getMenuItemLabel(incident, type),
      key: type,
      icon: getMenuItemIcon(type, "grey"),
    });
  }

  menuItems.push({
    label: getMenuItemLabel(incident, "notes"),
    key: "notes",
    icon: getMenuItemIcon("notes"),
  });

  menuItems.push({
    label: getMenuItemLabel(incident, "logs"),
    key: "logs",
    icon: getMenuItemIcon("logs"),
  });

  const updateIncidentStatus = async (status: string) => {
    const updatedIncident = { ...incident, status: status };
    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = `Updated "Incident status" to ${status}`;
    await updateIncident({
      variables: {
        incidentId: incidentId,
        _set: { status: status },
        objects: {
          patch: patch,
          comment: comment,
          edited_by_uid: authUser.uid,
          incident_id: incidentId,
          edit_type: "updated-incident-status",
        },
      },
      optimisticResponse: {
        update_incident_by_pk: {
          ...incident,
          status: status,
          id: incidentId,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  // if (loadingOptions) return <Spin />;
  // if (optionsError) throw optionsError;
  if (monetaryLoading) return <Spin />;
  if (monetaryError) throw monetaryError;
  return (
    <Drawer
      open={true}
      onClose={() => {
        navigate(
          projectId
            ? getGcProjectPath(projectId, "incidents")
            : getGcCorporateOfficePath("incidents"),
        );
      }}
      width={"80%"}
      title={"Incident"}
      extra={
        <div className="flex flex-row items-center gap-1">
          <Button
            loading={emailing}
            icon={<MaterialSymbol icon="send" />}
            onClick={async () => {
              await emailIncidentPdf({
                variables: {
                  input: { incidentId, projectId: incident.project_id },
                },
              });
              message.success("Email sent");
            }}
          >
            Report Incident
          </Button>

          <Button
            loading={generating}
            icon={<MaterialSymbol icon="download" />}
            onClick={async () => {
              const { data } = await generateIncidentPdf({
                variables: { input: { incidentId } },
              });
              if (data?.generateIncidentPdf) {
                downloadFromUrl(data.generateIncidentPdf);
              }
              message.success("Download complete");
            }}
          >
            Download PDF
          </Button>

          <Select
            className={`w-8`}
            value={incident.status}
            onChange={(option) => {
              updateIncidentStatus(option);
            }}
            options={[
              {
                label: "Incident Status",
                options: [
                  { value: "open", label: "Open" },
                  { value: "closed", label: "Closed" },
                  { value: "pending", label: "In-Progress" },
                ],
              },
            ]}
          />

          <Popover
            trigger={"hover"}
            placement="bottomLeft"
            title={"Incident Status Definitions"}
            content={
              <div className={"flex flex-col w-24 space-y-1"}>
                <div>
                  <strong>Open:</strong> Additional details or information
                  required
                </div>
                <div>
                  <strong>In-progress:</strong> All general details or
                  information have been provided. In-Progress incidents are
                  following cases that have not been closed, like a worker that
                  has not returned to work
                </div>
                <div>
                  <strong>Closed:</strong> All information is collected and the
                  incident is considered 100% complete
                </div>
              </div>
            }
          >
            <InfoCircleOutlined />
          </Popover>
        </div>
      }
    >
      <div className="flex flex-col w-full gap-1">
        <div className={`flex relative flex-row gap-1 w-full`}>
          <div className={``}>
            <Menu
              mode="inline"
              className={`sticky top-0`}
              selectedKeys={[selectedItem ?? ""]}
              items={menuItems}
              onClick={(e) => {
                if (e.key === "table") {
                  navigate(
                    projectId
                      ? getGcProjectPath(projectId, "incidents")
                      : getGcCorporateOfficePath("incidents"),
                  );
                } else {
                  const [type, id] = e.key.split("&&");
                  setSearchParams(
                    id ? { subview: type, id: id } : { subview: type },
                  );
                }
              }}
            />
          </div>

          <div className={`flex-1`}>
            <IncidentComponentRenderer
              selectedIncidentUserKey={incidentUserId}
              subview={subview}
              typeProps={{ incident, refetch }}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default withCustomSuspense(GcIncidentManagement);
