import { Button as AntDButton, Card, Divider, Space, Typography } from "antd";
import { default as CustomButton } from "../../../../../common/components/general/button/Button";
import { useContext, useState } from "react";
import SwitchWithText from "../../../../../common/components/SwitchWithText";
import { AppNavigationLayoutContext } from "../../../../../common/components/layouts/main/utils/AppNavigationLayoutContext";
import remToPx from "../../../../../common/functions/remToPx";
import { MaterialSymbol } from "react-material-symbols";

const ButtonsDemo: React.FC = () => {
  const appNavigationContext = useContext(AppNavigationLayoutContext);
  const headerHeight = appNavigationContext?.headerHeightPx;

  const [withIcon, setWithIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSecondary, setIsSecondary] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [withChildren, setWithChildren] = useState(false);
  const [withHint, setWithHint] = useState(false);
  const [withLbel, setWithLabel] = useState(false);
  const [isFake, setIsFake] = useState(false);
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [isGreen, setIsGreen] = useState(false);
  const [isGrey, setIsGrey] = useState(false);
  const [isInteractiveOnHover, setIsInteractiveOnHover] = useState(false);
  const [withSecondaryIcon, setWithSecondaryIcon] = useState(false);
  const [textColor, setTextColor] = useState<
    "red" | "green" | "grey" | undefined
  >(undefined);
  const [withWrapText, setWithWrapText] = useState(false);

  const onClick = () => {
    alert("click");
  };

  return (
    <div className={`flex flex-col gap-1`}>
      <div className={`grid grid-cols-2 gap-1`}>
        <Card>
          <Space
            direction={"vertical"}
            size={0}
            split={<Divider />}
            className={`w-full`}
          >
            <SwitchWithText
              checked={withIcon}
              text={<Typography.Text code>withIcon</Typography.Text>}
              onChange={setWithIcon}
            />
            <SwitchWithText
              checked={loading}
              text={<Typography.Text code>loading</Typography.Text>}
              onChange={setLoading}
            />
            <SwitchWithText
              checked={isSecondary}
              text={<Typography.Text code>isSecondary</Typography.Text>}
              onChange={setIsSecondary}
            />
            <SwitchWithText
              checked={isDelete}
              text={<Typography.Text code>isDelete</Typography.Text>}
              onChange={setIsDelete}
            />
            <SwitchWithText
              checked={withChildren}
              text={<Typography.Text code>withChildren</Typography.Text>}
              onChange={setWithChildren}
            />
            <SwitchWithText
              checked={withHint}
              text={<Typography.Text code>withHint</Typography.Text>}
              onChange={setWithHint}
            />
            <SwitchWithText
              checked={withLbel}
              text={<Typography.Text code>withLabel</Typography.Text>}
              onChange={setWithLabel}
            />
            <SwitchWithText
              checked={isFake}
              text={<Typography.Text code>isFake</Typography.Text>}
              onChange={setIsFake}
            />
            <SwitchWithText
              checked={isFullWidth}
              text={<Typography.Text code>isFullWidth</Typography.Text>}
              onChange={setIsFullWidth}
            />
            <SwitchWithText
              checked={isGreen}
              text={<Typography.Text code>isGreen</Typography.Text>}
              onChange={setIsGreen}
            />
            <SwitchWithText
              checked={isGrey}
              text={<Typography.Text code>isGrey</Typography.Text>}
              onChange={setIsGrey}
            />
            <SwitchWithText
              checked={isInteractiveOnHover}
              text={
                <Typography.Text code>isInteractiveOnHover</Typography.Text>
              }
              onChange={setIsInteractiveOnHover}
            />
            <SwitchWithText
              checked={withSecondaryIcon}
              text={<Typography.Text code>withSecondaryIcon</Typography.Text>}
              onChange={setWithSecondaryIcon}
            />
            <SwitchWithText
              checked={withWrapText}
              text={<Typography.Text code>withWrapText</Typography.Text>}
              onChange={setWithWrapText}
            />
          </Space>
        </Card>
        <div>
          <Card
            className={`sticky`}
            style={{
              top: headerHeight ? `${headerHeight + remToPx(1)}px` : undefined,
            }}
          >
            <div className={`flex flex-col gap-1`}>
              <CustomButton
                icon={withIcon ? <MaterialSymbol icon={"person"} /> : undefined}
                onClick={onClick}
                loading={loading}
                secondary={isSecondary}
                delete={isDelete}
                children={withChildren ? "Children" : undefined}
                hint={withHint ? "Hint text" : undefined}
                label={withLbel ? "Label" : undefined}
                fake={isFake}
                fullWidth={isFullWidth}
                green={isGreen}
                grey={isGrey}
                interactiveOnHover={isInteractiveOnHover}
                secondaryIcon={
                  withSecondaryIcon ? (
                    <MaterialSymbol icon="person_add" />
                  ) : undefined
                }
                textColor={textColor}
                wrapText={withWrapText}
              />
              <AntDButton />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ButtonsDemo;
