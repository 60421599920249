import { MaterialSymbol } from "react-material-symbols";
import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "src/common/components/general/button/Button";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import TaskInfo from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/TaskInfo";
import { NULL_ID } from "src/common/functions/nullId";

const SCTask: FC = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();

  if (!taskId) return <>Task Not Found</>;
  return (
    <BasicWrapper scrollable>
      <div className="mb-1">
        <CustomButton
          onClick={() => {
            navigate(`/sub/tasks`);
          }}
          icon={<MaterialSymbol icon="arrow_left" />}
          label="Back"
        />
      </div>

      <TaskInfo
        taskId={taskId}
        subcontractorId={NULL_ID}
        type="sub_company_jha"
      />
    </BasicWrapper>
  );
};

export default SCTask;
