import { MaterialSymbol } from "react-material-symbols";
import React from "react";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import Button from "src/common/components/general/button/Button";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import TaskInfo from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/TaskInfo";

interface SCProjectTaskProps {}

const SCProjectTask: React.FC<SCProjectTaskProps> = () => {
  const subcontractorId = useSubcontractorId();
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get("id");
  const navigate = useNavigate();
  return projectId && taskId ? (
    <BasicWrapper scrollable>
      <div className="flex flex-col">
        <div>
          <Button
            icon={<MaterialSymbol icon="arrow_left" />}
            label="Back"
            onClick={() => navigate(`/sub/projects/${projectId}/jhas`)}
          />
        </div>
        <TaskInfo
          {...{
            projectId,
            subcontractorId,
            taskId,
            type: "sub_project_jha",
          }}
        />
      </div>
    </BasicWrapper>
  ) : (
    <Navigate to={`sub`} replace />
  );
};

export default SCProjectTask;
