import React, { forwardRef, useState } from "react";
import { safety_data_sheet_bool_exp, SdsTableQuery } from "src/common/types/generated/relay/SdsTableQuery.graphql";
import { SdsTable_sds$data } from "src/common/types/generated/relay/SdsTable_sds.graphql";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "../../../../common/components/tables/basic/DataScrollTable";
import getDateStr from "src/common/functions/dates/getDateStr";
import dayjs from "dayjs";
import { graphql } from "babel-plugin-relay/macro";
import AddSdsToProjectModal from "./action-buttons/AddSdsToProjectModal";
import { Button, Checkbox } from "antd";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SdsTableMutation } from "src/common/types/generated/relay/SdsTableMutation.graphql";
import { MaterialSymbol } from "react-material-symbols";

export type ColumnKeys =
  | "productName"
  | "companyName"
  | "subcontractorName"
  | "addedDate"
  | "addedBy"
  | "action"
  | "revNumber"
  | "sdsDate"
  | "delete"
  | "addToProjects"
  | "select"
  | "projectName";

const deleteSdsMutation = graphql`
    mutation SdsTableMutation(
        $where: safety_data_sheet_bool_exp!
        $_set: safety_data_sheet_set_input!
    ) {
        update_safety_data_sheet(where: $where, _set: $_set) {
            affected_rows
        }
    }
`;
const CONNECTION_NAME = "safety_data_sheet_connection";
type DConnection = SdsTable_sds$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  SdsTableQuery,
  {}
> & {
  showDeleteButton?: boolean;
  onSdsRowClick: (sds: SDS) => void;
  projectSubcontractors?: Array<{ id: string; name: string }>;
  title: string;
  explainerText?: string;
  projectId?: string;
  customFilters?: CustomFilterType[];
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    SdsTableQuery
  >["columns"];
  updateSelectedSds?: (sdsId: SDS, type: "add" | "remove") => void;
  loading: boolean;
  hideCount?: boolean;
  hideFilter?: boolean;
  childSdsWhere?: safety_data_sheet_bool_exp;
};
export type SDS =
  SdsTable_sds$data["safety_data_sheet_connection"]["edges"][0]["node"];
const SdsTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      onSdsRowClick,
      where,
      topBarButtons = [],
      title,
      explainerText,
      extraColumns,
      customFilters,
      loading,
      updateSelectedSds,
      childSdsWhere,
      showDeleteButton,
      ...props
    },
    ref,
  ) => {
    console.log("CHILD SDS WHERE", childSdsWhere);
    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
    const [sds, setSds] = useState<SDS | undefined>(undefined);
    const [deleteSds] = useAsyncMutation<SdsTableMutation>(deleteSdsMutation);

    const deleteSDSButton = showDeleteButton && selectedKeys.length > 0 && {
        icon: <MaterialSymbol icon="delete" />,
        secondary: true,
        onClick: async () => {
          await deleteSds({
            variables: {
              where: {
                id: { _in: selectedKeys },
              },
              _set: { deleted_at: dayjs().format() },
            },
          });
          // refetch after delete finished
          if (ref && typeof ref !== "function") ref.current?.refetch();
          setSelectedKeys([]);
        },
      };
    

    return (
      <>
        {props.projectSubcontractors && sds && props.projectId && (
          <AddSdsToProjectModal
            handleCancel={() => setSds(undefined)}
            projectSubcontractors={props.projectSubcontractors}
            sds={sds}
            visible={!!sds}
            projectId={props.projectId}
          />
        )}
        <DataScrollTable<DConnection, ColumnKeys, SdsTableQuery>
          {...props}
          newCustomTableLook
          ref={ref}
          title={title}
          showHeader={false}
          explainerText={explainerText}
          loading={loading}
          onRowClick={(sds) => onSdsRowClick && onSdsRowClick(sds)}
          defaultCustomInitialSort={true}
          where={where}
          extraQueryVariables={{
            childSdsWhere: childSdsWhere ?? { id: { _is_null: true } },
            includeChildSds: !!childSdsWhere,
          }}
          topBarButtons={[...topBarButtons, deleteSDSButton].filter(v => !!v)}
          customFilters={customFilters}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allSdsConnection"}
          columns={[
            {
              onCell: (e) => ({
                onClick: (e) => {
                  e.stopPropagation();
                },
              }),
              title: "",
              size: "icon",
              align: "right",
              dataIndex: [],
              key: "select",
              render: (_, row) => {
                return (
                  <div className="p-1" onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      key={row.id}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        e.stopPropagation();
                        const checked =  e.target.checked;
                        updateSelectedSds?.(row, checked? "add" : "remove");
                        
                        if (checked) {
                          setSelectedKeys([...selectedKeys, row.pk]);
                        } else {
                          setSelectedKeys(
                            selectedKeys.filter((key) => key !== row.pk),
                          );
                        }
                      }}
                    ></Checkbox>
                  </div>
                );
              },
            },
            {
              title: "Project",
              dataIndex: ["project", "name"],
              searchDataIndex: ["project", "name"],
              key: "projectName",
              size: "sm",
            },
            {
              title: "Product Name",
              dataIndex: ["product_name"],
              key: "productName",
              defaultSortOrder: "asc",
              searchDataIndex: ["product_name"],
              sortable: true,
              size: "ml",
              clientCompareFn: (a, b) =>
                a.product_name.localeCompare(b.product_name),
            },
            {
              title: "Manufacturer Name",
              dataIndex: ["company_name"],
              key: "companyName",
              defaultSortOrder: "asc",
              searchDataIndex: ["company_name"],
              sortable: true,
              size: "ml",
            },
            {
              title: "Company",
              key: "subcontractorName",
              dataIndex: ["subcontractor", "name"],
              defaultSortOrder: "asc",
              searchDataIndex: ["subcontractor", "name"],
              sortable: true,
              size: "ml",
              render: (_, row) => {
                return row.subcontractor?.name ?? "";
              },
            },
            {
              title: "Rev #",
              dataIndex: ["reference_number"],
              key: "revNumber",
              defaultSortOrder: "asc",
              searchDataIndex: ["reference_number"],
              sortable: true,
              size: "sm",
              render: (num) => <>{num && <div>{num}</div>}</>,
            },
            {
              title: "SDS Date",
              dataIndex: ["issue_date"],
              key: "sdsDate",
              defaultSortOrder: "asc",
              sortable: true,
              size: "ml",
              render: (date, sds) => (
                <>
                  {date && (
                    <div>{getDateStr(dayjs(date), { format: "full" })}</div>
                  )}
                </>
              ),
            },
            {
              title: "Added By",
              dataIndex: ["created_by_user", "name"],
              key: "addedBy",
              searchDataIndex: ["created_by_user", "name"],
              size: "md",
              sortable: true,
            },
            {
              title: "Added Date",
              dataIndex: ["created_at"],
              key: "addedDate",
              size: "ml",
              sortable: true,
              render: (date, sds) => (
                <div>{getDateStr(dayjs(date), { format: "full" })}</div>
              ),
            },
            {
              title: "",
              dataIndex: [""],
              key: "addToProjects",
              size: "sm",
              render: (_, row) => {
                return (
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSds(row);
                    }}
                  >
                    Add To Project
                  </Button>
                );
              },
            },
            ...(extraColumns || []),
          ]}
          queryNode={graphql`
            query SdsTableQuery(
              $first: Int!
              $after: String
              $where: safety_data_sheet_bool_exp!
              $childSdsWhere: safety_data_sheet_bool_exp!
              $includeChildSds: Boolean!
              $order_by: [safety_data_sheet_order_by!]!
            ) {
              ...SdsTable_sds
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                )
              ...SdsTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment SdsTable_total on query_root
            @argumentDefinitions(where: { type: "safety_data_sheet_bool_exp!" })
            @refetchable(queryName: "SdsTableTotalRefetchableQuery") {
              allSdsConnection: safety_data_sheet_connection(where: $where) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment SdsTable_sds on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "safety_data_sheet_bool_exp!" }
              order_by: { type: "[safety_data_sheet_order_by!]!" }
            )
            @refetchable(queryName: "SdsTableRefetchableQuery") {
              safety_data_sheet_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "SdsTable_safety_data_sheet_connection"
                  filters: ["where", "order_by"]
                ) {
                edges {
                  node {
                    ...SdsFrag @relay(mask: false)
                    child_sds(where: $childSdsWhere)
                      @include(if: $includeChildSds) {
                      project_id
                      subcontractor_id
                    }
                  }
                }
              }
            }
          `}
        />
      </>
    );
  },
);

export default SdsTable;
