import { RoutedMenuItem } from "src/utility-features/navigation/routed-menu/routedMenuTypes";
import ChartsDemo from "./charts-demo/ChartsDemo";
import TabsDemo from "./tabs-demo/TabsDemo";
import VideoPlayerDemo from "./video-player-demo/VideoPlayerDemo";
import AudioPlayerDemo from "./audio-player/AudioPlayerDemo";
import SwitchDemo from "./switch/SwitchDemo";
import DemoDraft from "./draft/DemoDraft";
import OrientationPlayerDemo from "./orientation-player/OrientationPlayerDemo";
import tabsDemoNavigationItems from "./tabs-demo/tabsDemoNavigationItems";
import IconsDemo from "./icons/IconsDemo";
import ImagesDemo from "./images/ImagesDemo";
import ButtonsDemo from "./buttons/ButtonsDemo";
import RadioButtonsDemo from "./radio-buttons/RadioButtonsDemo";
import PeopleListDemo from "./people-list/PeopleListDemo";

const developmentPlaygroundNavigationItems: RoutedMenuItem[] = [
  {
    path: "charts",
    label: `Charts`,
    element: <ChartsDemo />,
  },
  {
    path: "tabs",
    element: <TabsDemo />,
    label: "Tabs",
    children: tabsDemoNavigationItems,
  },
  {
    path: "video-player",
    label: `Video Player`,
    element: <VideoPlayerDemo />,
  },
  {
    path: "orientation",
    label: "Orientation Player",
    element: <OrientationPlayerDemo />,
  },
  {
    path: "audio-player",
    label: "Audio Player",
    element: <AudioPlayerDemo />,
  },
  {
    path: "switch",
    label: "Switch",
    element: <SwitchDemo />,
  },
  {
    path: "icons",
    label: "Icons",
    element: <IconsDemo />,
  },
  {
    path: "draft",
    element: <DemoDraft />,
    label: " Draft",
  },
  {
    path: "images",
    label: "Images",
    element: <ImagesDemo />,
  },
  {
    path: "buttons",
    label: "Buttons",
    element: <ButtonsDemo />,
  },
  {
    path: "radio-buttons",
    label: "Radio Buttons",
    element: <RadioButtonsDemo />,
  },
  {
    path: "people-list",
    label: "People List",
    element: <PeopleListDemo />,
  },
];

export default developmentPlaygroundNavigationItems;
