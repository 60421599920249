import { message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import { useParams } from "react-router-dom";
import apolloClient from "src/common/api/apollo/apolloClient";
import { CreateGCEmployeeFormValues } from "src/common/components/dialogs/CreateGCEmployeeModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  GetNameUsernameDocument,
  GetNameUsernameQuery,
  GetNameUsernameQueryVariables,
  useCreateGeneralContractorEmployeeMutation,
  useUpdateUserHierarchyTeamsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { GCProjectTeamQuery } from "src/common/types/generated/relay/GCProjectTeamQuery.graphql";
import { GCProjectTeam_updateProjectEmployee_Mutation } from "src/common/types/generated/relay/GCProjectTeam_updateProjectEmployee_Mutation.graphql";
import { GCProjectTeam_deleteProjectEmployee_Mutation } from "src/common/types/generated/relay/GCProjectTeam_deleteProjectEmployee_Mutation.graphql";
import GCTeam, { GCProjectTeamUIProps } from "./GCTeam";
import sendInvite from "src/utility-features/invitations/sendInvite";
import { getInvitedUserData } from "src/common/functions/invitedUserEmailData";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";

const query = graphql`
  query GCProjectTeamQuery(
    $projectEmpoyeeWhere: project_employee_bool_exp!
    $projectId: uuid!
  ) {
    general_contractor_connection(
      where: { projects: { id: { _eq: $projectId } } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
    project_employee_connection(
      where: $projectEmpoyeeWhere
      order_by: { employee: { user: { name: asc } } }
      first: 10000
    )
      @connection(
        key: "GCProjectTeam_project_employee_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          status
          project {
            id
            name
          }
          employee {
            employee_title {
              id
              name {
                en
              }
            }
            is_corporate_admin
            user {
              id
              pk: id @__clientField(handle: "pk")
              name
              email
              phone_number
              created_password
              terminated_at
              profile_picture {
                id
                url
              }
            }
          }
        }
      }
    }
    project_subcontractor_connection(
      where: { project_id: { _eq: $projectId } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          subcontractor {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }
  }
`;
const updateProjectEmployeeMutation = graphql`
  mutation GCProjectTeam_updateProjectEmployee_Mutation(
    $id: uuid!
    $status: String!
  ) {
    update_project_employee_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;
const deleteProjectEmployeeMutation = graphql`
  mutation GCProjectTeam_deleteProjectEmployee_Mutation(
    $userId: uuid!
    $projectId: uuid!
  ) {
    delete_project_employee(
      where: { employee_id: { _eq: $userId }, project_id: { _eq: $projectId } }
    ) {
      affected_rows
    }
  }
`;

const GCProjectTeam: React.FC = () => {
  const [createEmployee] = useCreateGeneralContractorEmployeeMutation();
  const [updateUserHierarchyTeams] = useUpdateUserHierarchyTeamsMutation();
  const [fetchKey, setFetchKey] = useState(0);
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("Project id not found");
  }
  const { userData } = useUserData();
  const environment = useRelayEnvironment();
  const [updateProjectEmployee] =
    useAsyncMutation<GCProjectTeam_updateProjectEmployee_Mutation>(
      updateProjectEmployeeMutation,
    );
  const [deleteProjectEmployee] =
    useAsyncMutation<GCProjectTeam_deleteProjectEmployee_Mutation>(
      deleteProjectEmployeeMutation,
    );

  const data = useLazyLoadQuery<GCProjectTeamQuery>(
    query,
    {
      projectEmpoyeeWhere: {
        project_id: { _eq: projectId },
        _or: [
          { direct_project_assign: { _is_null: true } },
          { direct_project_assign: { _eq: true } },
        ],
        status: { _in: ["Active", "Inactive"] },
      },
      projectId: projectId,
    },
    {
      fetchKey,
      fetchPolicy: "network-only",
    },
  );

  const refetch = () => {
    setFetchKey((prev) => prev + 1);
  };

  const gcId = data.general_contractor_connection.edges[0].node.pk;
  const gcTeamProps: GCProjectTeamUIProps = {
    projectsToInvite: [projectId],
    onInviteSuccess: (userIds) => {},

    directProjectTeam: true,
    excludeEmployeeWhere: {
      employee_projects: {
        project_id: { _eq: projectId },
        direct_project_assign: { _eq: true },
      },
    },
    employees: data.project_employee_connection.edges.map((gc) => ({
      loading: false,
      userId: gc.node.employee.user.pk,
      generalContractorId: gcId,
      isCorporateAdmin: gc.node.employee.is_corporate_admin,
      relayDataId: gc.node.id,
      title: gc.node.employee.employee_title?.name.en,
      email: gc.node.employee.user.email ?? undefined,
      phoneNumber: gc.node.employee.user.phone_number ?? undefined,
      type: "project",
      onRemoveFromTeam: userData.employee?.is_corporate_admin
        ? async (userId) => {
            await deleteProjectEmployee({ variables: { userId, projectId } });
            refetch();
          }
        : undefined,
      terminatedAt: gc.node.employee.user.terminated_at ?? undefined,
      isLocalAdmin: false,
      activeOnProject: gc.node.status === "Active",
      onActiveChange: async (newValue: boolean) => {
        if (newValue) {
          await updateProjectEmployee({
            variables: {
              id: gc.node.pk,
              status: "Active",
            },
            optimisticResponse: {
              update_project_employee_by_pk: {
                id: gc.node.id,
                status: "Active",
              },
            },
          });
        } else {
          await updateProjectEmployee({
            variables: {
              id: gc.node.pk,
              status: "Inactive",
            },
            optimisticResponse: {
              update_project_employee_by_pk: {
                id: gc.node.id,
                status: "Inactive",
              },
            },
          });
        }
      },

      reSendInvite: async () => {
        const toEmail = gc.node.employee.user.email ?? undefined;
        const toName = gc.node.employee.user.name;
        const projectName = gc.node.project.name;
        if (toEmail) {
          await sendInvite({
            sendTo: toEmail,
            joinProjectId: projectId,
            sendToName: toName,
            claimingAccount: true,
            inviteType: "reSendGCInvite",
          });
          message.success("Invite sent");
        }
      },
      name: gc.node.employee.user.name,
      avatarUrl: gc.node.employee.user.profile_picture?.url,
      invitationStatus: gc.node.employee.user.created_password
        ? "accepted"
        : "pending",
    })),

    onCreateGCEmployee: async (values: CreateGCEmployeeFormValues) => {
      // TODO: move it to hook and use from both (GCHierarchyTeam and here)
      const invitedUserData = await getInvitedUserData(
        values.email,
        environment,
      );
      const user_edge = invitedUserData.user_connection.edges[0];
      if (user_edge) {
        const has_password = user_edge.node.created_password;
        await sendInvite({
          sendTo: values.email,
          toUid: user_edge.node.pk,
          joinProjectId: projectId,
          claimingAccount: true,
          inviteType: has_password ? "loginInvite" : "reSendGCInvite",
        });
        message.success("Invite sent");
        // why do we display two messages?
        notification.info({
          message: "User Already Exists ",
          description:
            "This user already exists in your organization. They have been added to your project. If they are having trouble logging in, they can select Forgot Password on the login screen",
          duration: null,
        });
        return;
      }
      const { data } = await apolloClient.query<
        GetNameUsernameQuery,
        GetNameUsernameQueryVariables
      >({
        query: GetNameUsernameDocument,
        variables: {
          name: values.name,
        },
      });
      if (!data.getNewUsername)
        throw new Error("getNewUsername retunred empty result");
      await createEmployee({
        variables: {
          name: values.name,
          email: values.email,
          phoneNumber: values.phone,
          generalContractorId: gcId,
          titleId: values.titleId,
          username: data.getNewUsername,
          projectIds: [projectId],
          directProjectAssign: true, // phoneNumber: un
        },
      });
      refetch();
    },
  };

  return <GCTeam {...gcTeamProps} />;
};

export default withAppPageLayout(GCProjectTeam, { pageTitle: "Team" });
