import { Image as ImageAntD } from "antd";
import React from "react";
import clsx from "clsx";

export interface ImageProps {
  src?: string;
  height?: number;
  width?: number;
  alt?: string;
}

const Image: React.FunctionComponent<ImageProps> = ({
  src,
  height,
  width,
  alt,
}) => {
  return (
    <div
      className={clsx(
        "bg-transparent h-full w-full flex items-center justify-center transition-all overflow-hidden",
      )}
    >
      <ImageAntD
        width={width ?? `100%`}
        height={height ?? `100%`}
        style={{ objectFit: "cover" }}
        onClick={(e) => e && e.stopPropagation()}
        src={src}
        alt={alt}
      />
    </div>
  );
};

export default Image;
