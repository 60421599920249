import { Modal, Tag } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";

import React, { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import getConnectionNodes from "src/common/functions/getConnectionNodes";
import { GCProjectReportsDailyDayBodyQuery } from "src/common/types/generated/relay/GCProjectReportsDailyDayBodyQuery.graphql";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import GCProjectReportsDailyDetail from "../report/GCProjectReportsDailyEntry";
import GCProjectReportsSafetyReportEntry from "../report/GCProjectReportsSafetyReportEntry";
import GCProjectReportsDailySummaryPhotos, {
  GCDailyReportImageType,
} from "../summary/GCProjectReportsDailySummaryPhotos";
import GCDailyReportCards, {
  GCDailyReportCardType,
  GCProjectReportsDailyDayCardsProps,
} from "./GCProjectReportsDailyDayCards";
import GCProjectReportsDailySummaryComments, {
  GCDailyReportCommentsType,
} from "../summary/GCProjectReportsDailySummaryComments";
import ErrorMessage from "../../../../../../utility-features/error-handling/components/ErrorMessage";
import { MaterialSymbol } from "react-material-symbols";

const query = graphql`
  query GCProjectReportsDailyDayBodyQuery(
    $projectId: uuid!
    $date: date!
    $startTime: timestamptz!
    $endTime: timestamptz!
  ) {
    daily_note_connection(
      where: { project_id: { _eq: $projectId }, date: { _eq: $date } }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          text {
            en
            pk: id @__clientField(handle: "pk")
          }
          daily_note_subs {
            pk: id @__clientField(handle: "pk")
            subcontractor {
              name
              pk: id @__clientField(handle: "pk")
            }
            general_contractor {
              name
              pk: id @__clientField(handle: "pk")
            }
          }
          daily_note_tags {
            pk: id @__clientField(handle: "pk")
            tag {
              id
              pk: id @__clientField(handle: "pk")
              name {
                pk: id @__clientField(handle: "pk")
                en
              }
              color_hex
            }
          }
          created_by_employee {
            user {
              pk: id @__clientField(handle: "pk")
              name
            }
          }
          created_at
        }
      }
    }
    daily_schedule_event_connection(
      where: { project_id: { _eq: $projectId }, date: { _eq: $date } }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          created_at
          type
          delay_type_text {
            pk: id @__clientField(handle: "pk")
            en
          }
          description {
            pk: id @__clientField(handle: "pk")
            en
          }
          daily_schedule_event_subs {
            pk: id @__clientField(handle: "pk")
            subcontractor {
              pk: id @__clientField(handle: "pk")
              name
            }
            general_contractor {
              name
              pk: id @__clientField(handle: "pk")
            }
          }
          created_by_employee {
            user {
              name
            }
          }
        }
      }
    }
    daily_weather_observation_connection(
      where: { project_id: { _eq: $projectId }, date: { _eq: $date } }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          created_at
          created_by_project_user {
            employee {
              user {
                pk: id @__clientField(handle: "pk")
                name
              }
            }
            project_worker {
              user {
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
          description {
            en
          }
          daily_weather_observation_subs {
            subcontractor {
              name
            }
            general_contractor {
              name
            }
          }
          time_observed
          ground {
            name {
              en
            }
          }
          sky {
            name {
              en
            }
          }
          temp
          wind {
            name {
              en
            }
          }
          precipitation
        }
      }
    }
    daily_work_log_connection(
      where: {
        project_id: { _eq: $projectId }
        date: { _eq: $date }
        is_deleted: { _eq: false }
      }
      order_by: { subcontractor: { name: asc } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          submitted_at
          created_at
          worker_count
          worker_hours
          subcontractor {
            name
            pk: id @__clientField(handle: "pk")
          }
          general_contractor {
            name
            pk: id @__clientField(handle: "pk")
          }
          description {
            ...TextTranslationFrag @relay(mask: false)
          }
          daily_work_log_comments(order_by: { created_at: asc }) {
            pk: id @__clientField(handle: "pk")
            text {
              en
            }
          }
          images(order_by: { sort_index: asc }) {
            ...ImageFrag @relay(mask: false)
          }
          daily_work_log_tags {
            id
            pk: id @__clientField(handle: "pk")
            tag {
              ...TagFrag @relay(mask: false)
            }
          }
          daily_work_log_comments(order_by: { created_at: asc }) {
            id
            pk: id @__clientField(handle: "pk")
            created_by_employee {
              user {
                pk: id @__clientField(handle: "pk")
                name
              }
            }
            created_at
            text {
              ...TextTranslationFrag @relay(mask: false)
            }
          }
          daily_work_log_workers {
            hours
            project_worker {
              user {
                name
              }
            }
          }
          created_by_project_user {
            project_worker {
              pk: id @__clientField(handle: "pk")
              subcontractor {
                name
              }
              user {
                name
              }
            }
            employee {
              user {
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
        }
      }
    }
    daily_safety_report_connection(
      first: 10000
      where: { project_id: { _eq: $projectId }, date: { _eq: $date } }
      order_by: { subcontractor: { name: asc } }
    )
      @connection(
        key: "GCProjectReportsDailyDayBody_daily_safety_report_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          created_at
          created_by_user {
            id
            name
            pk: id @__clientField(handle: "pk")
          }
          subcontractor {
            name
            pk: id @__clientField(handle: "pk")
          }
          images(order_by: { sort_index: asc }) {
            ...ImageFrag @relay(mask: false)
          }
          daily_safety_report_tags {
            id
            pk: id @__clientField(handle: "pk")
            tag {
              ...TagFrag @relay(mask: false)
            }
          }
          daily_safety_report_comments {
            id
            pk: id @__clientField(handle: "pk")
            text_translation {
              en
            }
          }
        }
      }
    }
    project_visitor_certification_connection(
      where: {
        created_at: { _lte: $endTime, _gte: $startTime }
        project_id: { _eq: $projectId }
      }
    ) {
      edges {
        node {
          created_at
          name
          company_name
          hours_planning_to_stay
          project {
            timezone
          }
          general_contractor {
            id
            name
          }
          subcontractor {
            id
            name
          }
          oac_company {
            id
            name
          }
          visitor_company {
            id
            name
          }
        }
      }
    }
  }
`;

interface DateProps {
  date: dayjs.Dayjs;
}

interface TagProps {
  selectedTagIds: string[];
  selectedSubcontractorIds: string[];
  searchQuery: string;
}

const GCProjectReportsDailyDayBody: FC<
  ProjectIdProps & DateProps & TagProps
> = ({
  projectId,
  date,
  selectedTagIds,
  selectedSubcontractorIds,
  searchQuery,
}) => {
  const [modalReportId, setModalReportId] = useState<string | null>(null);
  const [safetyReportModalReportId, setSafetyReportModalReportId] = useState<
    string | null
  >(null);
  const data = useLazyLoadQuery<GCProjectReportsDailyDayBodyQuery>(query, {
    projectId,
    date: date.format(),
    startTime: date.startOf("day").format(),
    endTime: date.endOf("day").format(),
  });
  const safetyLogs = getConnectionNodes(data.daily_safety_report_connection);
  const safetyLogsData = safetyLogs
    .filter((log) => {
      let searchString = `${log.subcontractor?.name ?? ""} ${
        log.created_by_user.name
      }`;
      searchString = `${searchString} ${log.daily_safety_report_comments.reduce(
        (tempString: string, s: any) =>
          `${tempString} ${s.text_translation.en}`,
        "",
      )}`;
      searchString = `${searchString} ${log.daily_safety_report_tags.reduce(
        (tempString: string, s: any) => `${tempString} ${s.tag.name.en}`,
        "",
      )}`;
      return (
        (selectedTagIds.length === 0 ||
          log.daily_safety_report_tags.some((log) =>
            selectedTagIds.includes(log.tag.pk),
          )) &&
        (selectedSubcontractorIds.length === 0 ||
          (log.subcontractor &&
            selectedSubcontractorIds.includes(log.subcontractor?.pk ?? ""))) &&
        searchString.toUpperCase().includes(searchQuery.toUpperCase())
      );
    })
    .map((l) => ({
      pk: l.pk,
      subcontractor: l.subcontractor,
      images: l.images,
      comments: l.daily_safety_report_comments.map((comment) => {
        return comment.text_translation.en + "\n";
      }),
      creator:
        (l.created_by_user.name ?? "") +
        ", " +
        dayjs(l.created_at)
          .tz("America/New_York")
          .format("MMMM Do YYYY, h:mm:ss A z"),
      tags: l.daily_safety_report_tags.map(function (l1) {
        return (
          <Tag color={l1.tag.color_hex} key={l1.tag.pk}>
            {l1.tag.name.en}
          </Tag>
        );
      }),
    }));
  const logs = getConnectionNodes(data.daily_work_log_connection);
  const logsData = logs
    .filter((log) => {
      let searchString = `${
        log.subcontractor?.name ?? log.general_contractor?.name ?? ""
      } ${log.created_by_project_user.employee?.user.name ?? ""} ${
        log.description.en
      }`;
      searchString = `${searchString} ${log.daily_work_log_comments.reduce(
        (tempString: string, s: any) => `${tempString} ${s.text.en}`,
        "",
      )}`;
      searchString = `${searchString} ${log.daily_work_log_tags.reduce(
        (tempString: string, s: any) => `${tempString} ${s.tag.name.en}`,
        "",
      )}`;
      return (
        (!!log.submitted_at || !!log.created_by_project_user.employee) &&
        (selectedTagIds.length === 0 ||
          log.daily_work_log_tags.some((log) =>
            selectedTagIds.includes(log.tag.pk),
          )) &&
        (selectedSubcontractorIds.length === 0 ||
          (log.subcontractor &&
            selectedSubcontractorIds.includes(log.subcontractor?.pk ?? ""))) &&
        searchString.toUpperCase().includes(searchQuery.toUpperCase())
      );
    })
    .map((l) => ({
      pk: l.pk,
      subcontractor: l.subcontractor,
      general_contractor: l.general_contractor,
      workersCount: l.worker_count ?? l.daily_work_log_workers.length,
      addedComments: l.daily_work_log_comments,
      manHours:
        typeof l.worker_hours === "number"
          ? l.worker_hours * l.worker_count! +
            (l.created_by_project_user.project_worker ? " (Adjusted)" : "")
          : l.daily_work_log_workers.reduce((sum, w) => sum + w.hours, 0),
      description: l.description,
      images: l.images,
      creator:
        (l.created_by_project_user.employee ??
          l.created_by_project_user.project_worker)!.user!.name +
        ", " +
        dayjs((l.submitted_at ?? l.created_at) as string)
          .tz("America/New_York")
          .format("MMMM Do YYYY, h:mm:ss A z"),
      tags: l.daily_work_log_tags.map(function (l1) {
        return (
          <Tag color={l1.tag.color_hex} key={l1.tag.pk}>
            {l1.tag.name.en}
          </Tag>
        );
      }),
    }));
  const visitorLogs = getConnectionNodes(
    data.project_visitor_certification_connection,
  );

  const notes = getConnectionNodes(data.daily_note_connection);
  const notesData = notes
    .filter((note) => {
      let searchString = `${note.daily_note_subs.reduce(
        (tempString: string, s: any) =>
          `${tempString} ${
            s.subcontractor?.name ?? s.general_contractor?.name
          }`,
        "",
      )}  ${note.text.en} ${note.created_by_employee.user.name}`;
      searchString = `${searchString}
        ${note.daily_note_tags.reduce(
          (tempString: string, s: any) => `${tempString} ${s.tag.name.en}`,
          "",
        )}`;
      return (
        (selectedTagIds.length == 0 ||
          note.daily_note_tags.some((log) =>
            selectedTagIds.includes(log.tag.pk),
          )) &&
        (selectedSubcontractorIds.length == 0 ||
          note.daily_note_subs.some((log) =>
            selectedSubcontractorIds.includes(log.subcontractor?.pk ?? ""),
          )) &&
        searchString.toUpperCase().includes(searchQuery.toUpperCase())
      );
    })
    .map((l) => ({
      pk: l.pk,
      description: l.text,
      subcontractor: l.daily_note_subs.map(function (l1) {
        return l1.subcontractor?.name ?? l1.general_contractor?.name + "\n";
      }),
      tags: l.daily_note_tags.map((l1) => {
        return <Tag color={l1.tag.color_hex}>{l1.tag.name.en}</Tag>;
      }),
      creator:
        l.created_by_employee.user.name +
        ", " +
        dayjs(l.created_at)
          .tz("America/New_York")
          .format("MMMM Do YYYY, h:mm:ss A z"),
    }));
  const schedule = getConnectionNodes(data.daily_schedule_event_connection);
  const scheduleData = schedule.map((l) => ({
    pk: l.pk,
    type: l.type == "delay" ? "Delay - " + l.delay_type_text.en : "Milestone",
    description: l.description,
    subcontractor: l.daily_schedule_event_subs.map(function (l1) {
      return l1.subcontractor?.name ?? l1.general_contractor?.name + "\n";
    }),
    creator:
      l.created_by_employee.user.name +
      ", " +
      dayjs(l.created_at as string)
        .tz("America/New_York")
        .format("MMMM Do YYYY, h:mm:ss A z"),
  }));
  const weather = getConnectionNodes(data.daily_weather_observation_connection);
  const weatherData = weather.map((l) => ({
    pk: l.pk,
    description: l.description,
    time: dayjs(l.time_observed as string).format("h:mm A"),
    affectedSubcontractor: l.daily_weather_observation_subs.map(function (l1) {
      return (
        (l1.subcontractor?.name ?? l1.general_contractor?.name ?? "") + "\n"
      );
    }),
    creator:
      (l.created_by_project_user.employee ??
        l.created_by_project_user.project_worker)!.user!.name +
      ", " +
      dayjs(l.created_at as string)
        .tz("America/New_York")
        .format("MMMM Do YYYY, h:mm:ss A z"),
    weatherSituation: [
      !!l.ground?.name.en ? l.ground.name.en + " " : "",
      !!l.sky?.name.en ? l.sky.name.en + " " : "",
      !!l.wind?.name.en ? l.wind.name.en + " " : "",
    ],
  }));

  const cardArrays: GCProjectReportsDailyDayCardsProps[] = [
    {
      title: `Safety Logs`,
      cards: safetyLogsData.map((safetyLog) => {
        const card: GCDailyReportCardType = {
          rows: [],
          onClick: () => {
            setSafetyReportModalReportId(safetyLog.pk);
          },
        };
        if (safetyLog.comments) {
          card.rows.push({
            icon: <MaterialSymbol icon={"chat"} />,
            label: safetyLog.comments.join(","),
          });
        }

        if (safetyLog.subcontractor) {
          card.rows.push({
            icon: <MaterialSymbol icon={"work"} />,
            label: safetyLog.subcontractor.name,
          });
        }

        if (safetyLog.images && safetyLog.images.length > 0) {
          const safetyLogPhotos = safetyLog.images.map((item) => {
            const safetyLogPhoto: GCDailyReportImageType = {
              image: {
                src: item.url,
              },
              author: safetyLog.creator,
              subcontractorTitle: safetyLog.subcontractor?.name ?? "",
            };
            return safetyLogPhoto;
          });
          card.rows.push({
            icon: <MaterialSymbol icon={"photo"} />,
            label:
              safetyLog.images.length > 1
                ? `${safetyLog.images.length} images`
                : `${safetyLog.images.length} image`,
            modal: !!safetyLog.images.length
              ? {
                  title: "Photos",
                  content: (
                    <GCProjectReportsDailySummaryPhotos
                      {...{ items: safetyLogPhotos }}
                    ></GCProjectReportsDailySummaryPhotos>
                  ),
                }
              : undefined,
          });
        }

        if (safetyLog.creator) {
          card.rows.push({
            icon: <MaterialSymbol icon={"edit"} />,
            label: safetyLog.creator,
          });
        }

        if (safetyLog.tags.length) {
          card.footer = (
            <div className={`-m-25`}>
              {safetyLog.tags.map((tag) => (
                <div className="m-0.25 inline-block">{tag}</div>
              ))}
            </div>
          );
        }

        return card;
      }),
    },
    {
      title: `Work Logs`,
      cards: logsData.map((workLog) => {
        const card: GCDailyReportCardType = {
          rows: [],
          onClick: () => {
            setModalReportId(workLog.pk);
          },
        };
        if (
          (workLog.subcontractor && workLog.subcontractor.name) ||
          (workLog.general_contractor && workLog.general_contractor.name)
        ) {
          card.rows.push({
            icon: <MaterialSymbol icon={"work"} />,
            label: `${
              workLog.subcontractor?.name ??
              workLog.general_contractor?.name ??
              ""
            }`,
          });
        }
        card.rows.push({
          icon: <MaterialSymbol icon={"group"} />,
          label: `${workLog.workersCount} worker${
            workLog.workersCount !== 1 ? "s" : ""
          }`,
        });
        card.rows.push({
          icon: <MaterialSymbol icon={"schedule"} />,
          label: `${workLog.manHours} man hour${
            // eslint-disable-next-line eqeqeq
            workLog.manHours != 1 ? "s" : ""
          }`,
        });
        if (workLog.images && workLog.images.length > 0) {
          const dailySummaryPhotos = workLog.images.map((item) => {
            const dailySummaryPhoto: GCDailyReportImageType = {
              image: {
                src: item.url,
              },
              author: workLog.creator,
              subcontractorTitle:
                workLog.subcontractor?.name ??
                workLog.general_contractor?.name ??
                "",
            };
            return dailySummaryPhoto;
          });
          card.rows.push({
            icon: <MaterialSymbol icon={"photo"} />,
            label:
              workLog.images.length > 1
                ? `${workLog.images.length} images`
                : `${workLog.images.length} image`,
            modal: !!workLog.images.length
              ? {
                  title: "Photos",
                  content: (
                    <GCProjectReportsDailySummaryPhotos
                      {...{ items: dailySummaryPhotos }}
                    ></GCProjectReportsDailySummaryPhotos>
                  ),
                }
              : undefined,
          });
        }
        if (workLog.creator) {
          card.rows.push({
            icon: <MaterialSymbol icon={"edit"} />,
            label: `Created by ${workLog.creator}`,
          });
        }
        if (workLog.description.en) {
          card.rows.push({
            icon: <MaterialSymbol icon={"description"} />,
            label: `Comments: ${workLog.description.en}`,
          });
        }
        if (workLog.addedComments && workLog.addedComments.length > 0) {
          const dailySummaryComments =
            workLog.addedComments.map<GCDailyReportCommentsType>((item) => ({
              id: item.pk,
              comment: item.text.en,
              author: item.created_by_employee?.user.name ?? "",
              time: dayjs(item.created_at),
            }));
          card.rows.push({
            icon: <MaterialSymbol icon={"description"} />,
            label:
              workLog.addedComments.length > 1
                ? `${workLog.addedComments.length} Added Comments`
                : `Added Comment: ${workLog.addedComments[0].text.en}`,
            modal: !!workLog.addedComments.length
              ? {
                  title: "Added Comments",
                  content: (
                    <GCProjectReportsDailySummaryComments
                      {...{ items: dailySummaryComments }}
                    ></GCProjectReportsDailySummaryComments>
                  ),
                }
              : undefined,
          });
        }
        if (workLog.tags.length) {
          card.footer = (
            <div className={`-m-25`}>
              {workLog.tags.map((tag) => (
                <div className="m-0.25 inline-block">{tag}</div>
              ))}
            </div>
          );
        }

        return card;
      }),
    },
    {
      title: `Notes`,
      cards: notesData.map((note) => {
        const card: GCDailyReportCardType = {
          rows: [],
        };

        if (note.subcontractor) {
          card.rows.push({
            icon: <MaterialSymbol icon={"work"} />,
            label: note.subcontractor.join(", "),
          });
        }
        if (note.description.en) {
          card.rows.push({
            icon: <MaterialSymbol icon={"description"} />,
            label: note.description.en,
          });
        }
        if (note.creator) {
          card.rows.push({
            icon: <MaterialSymbol icon={"edit"} />,
            label: `Created by ${note.creator}`,
          });
        }

        if (note.tags.length) {
          card.footer = (
            <div className={`-m-25`}>
              {note.tags.map((tag) => (
                <div className="m-0.25">{tag}</div>
              ))}
            </div>
          );
        }
        return card;
      }),
    },
    {
      title: `Schedule`,
      cards: scheduleData.map((schedule) => {
        const card: GCDailyReportCardType = {
          rows: [],
        };

        if (schedule.type) {
          card.rows.push({
            icon: <MaterialSymbol icon={"event"} />,
            label: schedule.type,
          });
        }
        if (schedule.subcontractor) {
          card.rows.push({
            icon: <MaterialSymbol icon={"work"} />,
            label: schedule.subcontractor.join(", "),
          });
        }
        if (schedule.description.en) {
          card.rows.push({
            icon: <MaterialSymbol icon={"description"} />,
            label: schedule.description.en,
          });
        }
        if (schedule.creator) {
          card.rows.push({
            icon: <MaterialSymbol icon={"edit"} />,
            label: `Created by ${schedule.creator}`,
          });
        }

        return card;
      }),
    },
    {
      title: `Weather Observations`,
      cards: weatherData.map((weather) => {
        const card: GCDailyReportCardType = {
          rows: [],
        };

        if (weather.weatherSituation) {
          card.rows.push({
            icon: <MaterialSymbol icon={"cloud"} />,
            label: `${weather.weatherSituation}`,
          });
        }

        if (weather.affectedSubcontractor) {
          card.rows.push({
            icon: <MaterialSymbol icon={"work"} />,
            label: `${weather.affectedSubcontractor.join(", ")} ${
              weather.affectedSubcontractor.length > 1 ? "were" : "was"
            } affected`,
          });
        }

        if (weather.description.en) {
          card.rows.push({
            icon: <MaterialSymbol icon={"description"} />,
            label: weather.description.en,
          });
        }

        if (weather.time) {
          card.rows.push({
            icon: <MaterialSymbol icon={"schedule"} />,
            label: `Observed at ${weather.time}`,
          });
        }

        if (weather.creator) {
          card.rows.push({
            icon: <MaterialSymbol icon={"edit"} />,
            label: `Created by ${weather.creator}`,
          });
        }

        return card;
      }),
    },
    {
      title: `Visitor Log`,
      cards: visitorLogs.map((logs) => {
        const card: GCDailyReportCardType = {
          rows: [],
        };
        let company = logs.visitor_company?.name;
        if (logs.subcontractor) company = logs.subcontractor.name;
        if (logs.general_contractor) company = logs.general_contractor.name;
        if (logs.oac_company) company = logs.oac_company.name;
        card.rows.push({
          icon: <MaterialSymbol icon={"work"} />,
          label: company ?? "",
        });

        card.rows.push({
          icon: <MaterialSymbol icon={"person"} />,
          label: logs.name,
        });

        card.rows.push({
          icon: <MaterialSymbol icon={"calendar_today"} />,
          label: dayjs(logs.created_at)
            .tz("America/New_York")
            .format("MMMM Do YYYY, h:mm:ss A z"),
        });

        card.rows.push({
          icon: <MaterialSymbol icon={"schedule"} />,
          label: logs.hours_planning_to_stay.toString() + " Hours",
        });

        return card;
      }),
    },
  ];

  const cardsTotal = cardArrays
    .map((cardsObj) => cardsObj.cards.length)
    .reduce((a, b) => a + b);
  return (
    <>
      <Modal
        onCancel={() => {
          setSafetyReportModalReportId(null);
        }}
        open={!!safetyReportModalReportId}
        // width={`1000`}
        width={`66vw`}
        footer={null}
      >
        {!!safetyReportModalReportId && (
          <CustomSuspense>
            <GCProjectReportsSafetyReportEntry
              reportId={safetyReportModalReportId}
            />
          </CustomSuspense>
        )}
      </Modal>
      <Modal
        onCancel={() => {
          setModalReportId(null);
        }}
        open={!!modalReportId}
        // width={`1000`}
        width={`66vw`}
        footer={null}
      >
        {!!modalReportId && (
          <CustomSuspense>
            <GCProjectReportsDailyDetail reportId={modalReportId} />
          </CustomSuspense>
        )}
      </Modal>
      <div className="flex flex-col gap-1">
        {cardArrays.map((cardProps) => (
          <GCDailyReportCards key={cardProps.title} {...cardProps} />
        ))}
        {cardsTotal === 0 && <ErrorMessage message={`No entries found`} />}
      </div>
    </>
  );
};

export default withCustomSuspense(GCProjectReportsDailyDayBody);
