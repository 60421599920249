import React, { FC, useState } from "react";
import BackButton from "src/common/components/general/button/BackButton";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import SdsTable from "src/domain-features/sitesafety/safety-data-sheets/components/SdsTable";
import CardWrapper from "../../../components/CardWrapper";
import PdfViewer from "src/common/components/pdf/PdfViewer";
import CustomButton from "src/common/components/general/button/Button";
import { safety_data_sheet_bool_exp } from "src/common/types/generated/relay/SiteOrientationSlidesViewerQuery.graphql";

const QrProjectSds: FC<ProjectIdProps> = ({ projectId }) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfModal, setPdfModal] = useState(false);

  const [tableWhere, setTableWhere] = useState<safety_data_sheet_bool_exp>({
    project_id: { _eq: projectId },
    deleted_at: { _is_null: true },
  });
  const [allSdsVisible, setAllSdsVisible] = useState(false);
  return (
    <MobileViewWrapper footer={<BackButton url={`/qr/p/${projectId}`} />}>
      <PdfViewer
        visible={pdfModal}
        onClose={() => {
          setPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />
      <CardWrapper>
        <SdsTable
          loadAll
          excludedKeys={[
            "projectName",
            "addedBy",
            "addedDate",
            "addToProjects",
            "select",
          ]}
          title={allSdsVisible ? "SDS Database" : "Project SDS"}
          headerComponent={
            <CustomButton
              secondary
              large
              label={allSdsVisible ? "Project SDS" : "SDS Database"}
              onClick={() => {
                allSdsVisible
                  ? setTableWhere({
                      project_id: { _eq: projectId },
                      deleted_at: { _is_null: true },
                    })
                  : setTableWhere({
                      deleted_at: { _is_null: true },
                      company_safety_data_sheet_id: { _is_null: true },
                    });
                allSdsVisible
                  ? setAllSdsVisible(false)
                  : setAllSdsVisible(true);
              }}
            />
          }
          loading={false}
          onSdsRowClick={(sds) => {
            setPdfUrl(sds.url);
            setPdfModal(true);
          }}
          where={tableWhere}
        />
      </CardWrapper>
    </MobileViewWrapper>
  );
};

export default withCustomSuspense(QrProjectSds);
