import { MaterialSymbol } from "react-material-symbols";

import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "src/common/components/general/button/Button";
import { useInsertSubBlockMutation } from "src/common/types/generated/apollo/graphQLTypes";
import SitedeliveryTimeBlockModal, {
  SitedeliveryTimeBlockModalValues,
} from "./SitedeliveryTimeBlockModal";
import useAuthUser from "src/common/hooks/useAuthUser";

interface SitedeliveryAddTimeBlockModalProps {
  timezone?: string;
}

const SitedeliveryAddTimeBlockModal: React.FC<
  SitedeliveryAddTimeBlockModalProps
> = (props) => {
  const [visible, setVisible] = useState(false);

  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("project id param is missing");
  }
  const authUser = useAuthUser();
  const [insertSubBlock, { loading }] = useInsertSubBlockMutation();

  const onSubmit = async (values: SitedeliveryTimeBlockModalValues) => {
    await insertSubBlock({
      variables: {
        object: {
          start_time: dayjs(values.startTime)
            .tz(props.timezone, true)
            .format("HH:mm"),
          end_time: dayjs(values.endTime)
            .tz(props.timezone, true)
            .format("HH:mm"),
          project_id: projectId,
          weekday: values.weekday,
          created_by_user_id: authUser.uid,
          calendars: {
            data: values.calendarIds.map((c) => ({
              calendar_id: c,
            })),
          },

          subcontractors: {
            data: values.subcontractorIds.map((s) => ({
              subcontractor_id: s,
            })),
          },
        },
        deleteFilterCalendarWhere: {
          calendar_id: { _in: values.calendarIds },
          filter_type: { _eq: "web-hidden-calendar" },
          user_id: { _eq: authUser.uid },
          project_id: { _eq: projectId },
        },
      },
    });
  };

  return (
    <>
      <Button
        icon={<MaterialSymbol icon="add" />}
        onClick={() => {
          setVisible(true);
        }}
        label={`Add Sub Block`}
      />
      {visible && (
        <SitedeliveryTimeBlockModal
          modalClose={() => {
            setVisible(false);
          }}
          modalVisible={visible}
          onSubmit={onSubmit}
          loading={loading}
        />
      )}
    </>
  );
};

export default SitedeliveryAddTimeBlockModal;
