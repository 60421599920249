import { useLocation, useNavigate } from "react-router-dom";

import { ItemType } from "antd/lib/menu/interface";
import { MenuProps } from "antd";
import { useEffect, useMemo, useState } from "react";

const getMatchingMenuKeys: (
  locationPathName: string,
  basePath: string,
  menuItems: ItemType[],
) => string[] = (locationPathName, basePath, menuItems) => {
  const matchingMenuItem = menuItems.find((item) =>
    item ? locationPathName.startsWith(`${basePath}/${item.key}`) : false,
  );

  if (matchingMenuItem?.key) {
    const mainKey: string = matchingMenuItem.key + "";

    const resultKeys: string[] = [mainKey];

    if (
      matchingMenuItem &&
      "children" in matchingMenuItem &&
      matchingMenuItem.children?.length
    ) {
      const childKeys = getMatchingMenuKeys(
        locationPathName,
        `${basePath}/${mainKey}`,
        matchingMenuItem.children,
      );

      resultKeys.push(...childKeys);
    }

    return resultKeys;
  }
  return [];
};

const useSelectedMenuKeys: (
  menuItems: ItemType[],
  basePath: string,
) => {
  selectedKeys: MenuProps["selectedKeys"];
  onSelect: MenuProps["onSelect"];
} = (menuItems, basePath) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onSelect: MenuProps["onSelect"] = (selectInfo) => {
    const { keyPath } = selectInfo;
    if (keyPath.length > 0) {
      const keyPathStr = [...keyPath].reverse().join("/");
      navigate(`${basePath}/${keyPathStr}`);
    }
  };

  const selectedKeys = useMemo(() => 
    getMatchingMenuKeys(location.pathname, basePath, menuItems)
  , [basePath, location.pathname, menuItems]);

  return {
    selectedKeys,
    onSelect,
  };
};

export default useSelectedMenuKeys;
