import React from "react";
import { Select } from "antd";

import Popover from "src/common/components/general/Popover";

type OptionType = {
  label: string;
  value: string;
  key?: string;
};

export interface CustomSelectProps {
  options: OptionType[];
  icon: React.ReactNode;
  selectedOptionId?: string;
  onOptionSubmit: (optionId: string) => void;
  editMode: boolean;
  placeholderText?: string;
}

const CustomSelect: React.FunctionComponent<CustomSelectProps> = (props) => {
  const selectedItem: OptionType | null = props.selectedOptionId
    ? props.options.find(({ value }) => value === props.selectedOptionId) ??
      null
    : null;

  const handleSubmit = (newValue: string) => {
    props.onOptionSubmit(newValue);
  };

  return props.editMode ? (
    <Select
      options={props.options}
      value={props.selectedOptionId}
      onChange={(e) => handleSubmit(e)}
    />
  ) : (
    <Popover
      content={
        <div className="flex flex-col gpa-0.5 max-h-20 overflow-y-auto">
          {props.options.map((option) =>
            option.value === props.selectedOptionId ? null : (
              <span
                key={option.key}
                className={`select-none hover:text-interactive-primary py-0.25 px-0.75 hover:bg-white transition-all`}
                onClick={() => {
                  handleSubmit(option.value);
                }}
              >
                {option.label}
              </span>
            ),
          )}
        </div>
      }
      showOnClick
    >
      <div className="inline-flex cursor-pointer relative flex-row gap-0.5 items-center group">
        {props.icon}
        <div className="grid grid-cols-1 grid-rows-1">
          {props.options.map((option) => (
            <span
              key={option.key}
              className={`leading-fit col-start-1 col-end-1 row-start-1 row-end-1 transition-opacity ${
                option.value === props.selectedOptionId
                  ? "opaicty-100"
                  : "opacity-0"
              }`}
            >
              {option.label}
            </span>
          ))}
          <span
            className={`leading-fit col-start-1 col-end-1 row-start-1 row-end-1 transition-all ${
              selectedItem
                ? "text-static-primary group-hover:text-interactive-primary"
                : "text-static-secondary"
            }`}
          >
            {selectedItem?.label ?? props.placeholderText ?? "Select an option"}
          </span>
        </div>
      </div>
    </Popover>
  );
};

export default CustomSelect;
