/**
 * @generated SignedSource<<b49ba3c49f18e94f89ef202464deceb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { orientation_slide_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectOrientationDetail_InsertSlide_Mutation$variables = {
  slide: orientation_slide_insert_input;
};
export type GCProjectOrientationDetail_InsertSlide_Mutation$data = {
  readonly insert_orientation_slide_one: {
    readonly answer_items: string | null | undefined;
    readonly answer_items_es: string | null | undefined;
    readonly answer_items_fr: string | null | undefined;
    readonly answer_items_it: string | null | undefined;
    readonly answer_items_pt: string | null | undefined;
    readonly answer_items_zh_hans: string | null | undefined;
    readonly answer_required: boolean;
    readonly check_correct_answer: boolean;
    readonly content_type: string | null | undefined;
    readonly correct_answer_note: string | null | undefined;
    readonly correct_answer_note_es: string | null | undefined;
    readonly correct_answer_note_fr: string | null | undefined;
    readonly correct_answer_note_it: string | null | undefined;
    readonly correct_answer_note_pt: string | null | undefined;
    readonly correct_answer_note_zh_hans: string | null | undefined;
    readonly correct_answers: string | null | undefined;
    readonly id: string;
    readonly image_url: string | null | undefined;
    readonly image_url_es: string | null | undefined;
    readonly image_url_fr: string | null | undefined;
    readonly image_url_it: string | null | undefined;
    readonly image_url_pt: string | null | undefined;
    readonly image_url_zh_hans: string | null | undefined;
    readonly order: number;
    readonly orientation_id: string;
    readonly pk: string;
    readonly question_ui_element: string | null | undefined;
    readonly slide_id: string;
    readonly text: string | null | undefined;
    readonly text_es: string | null | undefined;
    readonly text_fr: string | null | undefined;
    readonly text_it: string | null | undefined;
    readonly text_pt: string | null | undefined;
    readonly text_to_speak: string | null | undefined;
    readonly text_to_speak_es: string | null | undefined;
    readonly text_to_speak_fr: string | null | undefined;
    readonly text_to_speak_it: string | null | undefined;
    readonly text_to_speak_pt: string | null | undefined;
    readonly text_to_speak_zh_hans: string | null | undefined;
    readonly text_zh_hans: string | null | undefined;
    readonly title: string;
    readonly title_es: string | null | undefined;
    readonly title_fr: string | null | undefined;
    readonly title_it: string | null | undefined;
    readonly title_pt: string | null | undefined;
    readonly title_zh_hans: string | null | undefined;
    readonly video_url: string | null | undefined;
    readonly video_url_es: string | null | undefined;
    readonly video_url_fr: string | null | undefined;
    readonly video_url_it: string | null | undefined;
    readonly video_url_pt: string | null | undefined;
    readonly video_url_zh_hans: string | null | undefined;
    readonly voice_gender: string | null | undefined;
    readonly voice_name: string | null | undefined;
    readonly voice_name_es: string | null | undefined;
    readonly voice_name_fr: string | null | undefined;
    readonly voice_name_it: string | null | undefined;
    readonly voice_name_pt: string | null | undefined;
    readonly voice_name_zh_hans: string | null | undefined;
    readonly voice_url: string | null | undefined;
    readonly voice_url_es: string | null | undefined;
    readonly voice_url_fr: string | null | undefined;
    readonly voice_url_it: string | null | undefined;
    readonly voice_url_pt: string | null | undefined;
    readonly voice_url_zh_hans: string | null | undefined;
    readonly watch_full_video: boolean;
  } | null | undefined;
};
export type GCProjectOrientationDetail_InsertSlide_Mutation = {
  response: GCProjectOrientationDetail_InsertSlide_Mutation$data;
  variables: GCProjectOrientationDetail_InsertSlide_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slide"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "slide"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slide_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content_type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "watch_full_video",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "question_ui_element",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "check_correct_answer",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_required",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answers",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_gender",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_es",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_es",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_es",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_es",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_es",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_es",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_es",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_es",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_es",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_pt",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_pt",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_pt",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_pt",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_pt",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_pt",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_pt",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_pt",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_pt",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_it",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_it",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_it",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_it",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_it",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_it",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_it",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_it",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_it",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_fr",
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_fr",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_fr",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_fr",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_fr",
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_fr",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_fr",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_fr",
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_fr",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_zh_hans",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_zh_hans",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image_url_zh_hans",
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "video_url_zh_hans",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_url_zh_hans",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer_items_zh_hans",
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text_to_speak_zh_hans",
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correct_answer_note_zh_hans",
  "storageKey": null
},
v66 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voice_name_zh_hans",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectOrientationDetail_InsertSlide_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "orientation_slide",
        "kind": "LinkedField",
        "name": "insert_orientation_slide_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          (v58/*: any*/),
          (v59/*: any*/),
          (v60/*: any*/),
          (v61/*: any*/),
          (v62/*: any*/),
          (v63/*: any*/),
          (v64/*: any*/),
          (v65/*: any*/),
          (v66/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectOrientationDetail_InsertSlide_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "orientation_slide",
        "kind": "LinkedField",
        "name": "insert_orientation_slide_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          (v58/*: any*/),
          (v59/*: any*/),
          (v60/*: any*/),
          (v61/*: any*/),
          (v62/*: any*/),
          (v63/*: any*/),
          (v64/*: any*/),
          (v65/*: any*/),
          (v66/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c8e8042392b9bee3c96beb61f4596148",
    "id": null,
    "metadata": {},
    "name": "GCProjectOrientationDetail_InsertSlide_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectOrientationDetail_InsertSlide_Mutation(\n  $slide: orientation_slide_insert_input!\n) {\n  insert_orientation_slide_one(object: $slide) {\n    id\n    pk: id\n    slide_id\n    orientation_id\n    order\n    content_type\n    watch_full_video\n    question_ui_element\n    check_correct_answer\n    answer_required\n    correct_answers\n    voice_gender\n    title\n    text\n    image_url\n    video_url\n    voice_url\n    answer_items\n    text_to_speak\n    correct_answer_note\n    voice_name\n    title_es\n    text_es\n    image_url_es\n    video_url_es\n    voice_url_es\n    answer_items_es\n    text_to_speak_es\n    correct_answer_note_es\n    voice_name_es\n    title_pt\n    text_pt\n    image_url_pt\n    video_url_pt\n    voice_url_pt\n    answer_items_pt\n    text_to_speak_pt\n    correct_answer_note_pt\n    voice_name_pt\n    title_it\n    text_it\n    image_url_it\n    video_url_it\n    voice_url_it\n    answer_items_it\n    text_to_speak_it\n    correct_answer_note_it\n    voice_name_it\n    title_fr\n    text_fr\n    image_url_fr\n    video_url_fr\n    voice_url_fr\n    answer_items_fr\n    text_to_speak_fr\n    correct_answer_note_fr\n    voice_name_fr\n    title_zh_hans\n    text_zh_hans\n    image_url_zh_hans\n    video_url_zh_hans\n    voice_url_zh_hans\n    answer_items_zh_hans\n    text_to_speak_zh_hans\n    correct_answer_note_zh_hans\n    voice_name_zh_hans\n  }\n}\n"
  }
};
})();

(node as any).hash = "7cca7e7917600084963bcb75775b3d22";

export default node;
