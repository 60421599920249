import React, { useState } from "react";
import Button from "./general/button/Button";
import Icon from "./general/Icon";
import { MaterialSymbol } from "react-material-symbols";

export interface FormStepBlockProps {
  title: string;
  description: string;
  willBeChangedLater?: string | React.ReactElement;
  onNext: () => Promise<any>;
  onSkip?: () => void;
  nextButtonTitle?: string;
  dataIsValid: boolean;
  open: boolean;
  filled: boolean;
  children?: React.ReactNode;
}

const FormStepBlock: React.FC<FormStepBlockProps> = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row items-center gap-1">
        <Icon
          icon={
            props.filled ? (
              <MaterialSymbol icon="check_circle" />
            ) : (
              <MaterialSymbol icon="circle" />
            )
          }
          color={`static`}
        />
        <p className="text-1.5">{props.title}</p>
        <div className="flex-1 h-px bg-suplementary-3"></div>
      </div>
      <div
        className={`w-full overflow-hidden  ${
          props.open ? "h-auto -mt-0" : "h-0 -mt-1"
        }`}
      >
        <div className="flex flex-col gap-1">
          <div className="flex flex-col gap-0.5">
            <p className="font-accent text-1">{`${props.description}`}</p>
            {props.willBeChangedLater && (
              <p className="text-0.75">{props.willBeChangedLater}</p>
            )}
          </div>
          <div className="">{props.children}</div>

          <div className="flex flex-row gap-1">
            {props.dataIsValid && (
              <Button
                loading={loading}
                onClick={() => {
                  if (props.onNext) {
                    setLoading(true);
                    props.onNext().finally(() => setLoading(false));
                  }
                }}
                icon={<MaterialSymbol icon="arrow_downward" />}
                label={props.nextButtonTitle ?? `Next`}
              />
            )}
            {!!props.onSkip && (
              <Button secondary onClick={props.onSkip} label={`Skip`} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStepBlock;
