import { MaterialSymbol } from "react-material-symbols";
import {
  Button as AntdButton,
  Card,
  Descriptions,
  Image,
  message,
  notification,
} from "antd";
import Meta from "antd/lib/card/Meta";
import Item from "antd/lib/descriptions/Item";
import Modal from "antd/lib/modal/Modal";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import Button from "src/common/components/general/button/Button";
import StyledContent from "src/common/components/layouts/StyledContent";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { auth } from "src/common/functions/firebase";
import {
  useEmailUserAfterJhaCreationCompletionMutation,
  useGenerateImagesPdfForJhaRequestMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { VerifyAndAddJHAsQuery } from "src/common/types/generated/relay/VerifyAndAddJHAsQuery.graphql";
import {
  task_insert_input,
  VerifyAndAddJHAs_Insert_Task_Mutation,
} from "src/common/types/generated/relay/VerifyAndAddJHAs_Insert_Task_Mutation.graphql";
import { VerifyAndAddJHAs_Reject_Mutation } from "src/common/types/generated/relay/VerifyAndAddJHAs_Reject_Mutation.graphql";
import * as uuid from "uuid";
import AddJHAExcelUploadForm from "./components/AddJHAExcelUploadModal";
import ViewAndEditTaskType from "src/common/types/manual/ViewAndEditTaskType";
import ViewAndEditTask from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/ViewAndEditTask";

const query = graphql`
  query VerifyAndAddJHAsQuery($requestId: uuid!) {
    ppe_type_connection(order_by: { name_text: asc }) {
      edges {
        node {
          name_text
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    ppe_category_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          id
          name {
            en
          }
          ppe_types(order_by: { name: { en: asc } }) {
            id
            pk: id @__clientField(handle: "pk")
            name {
              en
            }
          }
        }
      }
    }
    permit_type_connection(order_by: { name_text: asc }) {
      edges {
        node {
          name_text
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    ecm_type_connection {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name_text
        }
      }
    }
    previousImageTask: task_connection(
      where: {
        creation_request_id: { _eq: $requestId }
        report_id: { _is_null: true }
        task_creation_request: { type: { _eq: "images_for_one" } }
        show_type: { _eq: "images" }
      }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          description {
            en
          }
        }
      }
    }
    add_task_request_connection(where: { id: { _eq: $requestId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          project_id
          subcontractor_id
          rejected_at
          type
          number_of_task_requested
          requested_by_uid
          requested_by_user {
            name
            email
          }
          requested_at
          project {
            name
            general_contractor_id
            general_contractor {
              name
            }
          }
          task_add_to_projects {
            project_id
          }
          subcontractor {
            name
          }
          attached_files(order_by: { name: asc }) {
            id
            pk: id @__clientField(handle: "pk")
            url
            name
            jha_creation_request_verified_at
          }
        }
      }
    }
  }
`;

interface JHAListItem {
  id: string;
  name: string;
  taskInsertInput: task_insert_input;
  valuesPPE: Array<{ id: string; name: string }>;
  valuesPermit: Array<{ id: string; name: string }>;
}

const VerifyAndAddJHAs: FC<{ requestId: string }> = ({ requestId }) => {
  const jhaData = useLazyLoadQuery<VerifyAndAddJHAsQuery>(query, {
    requestId,
  });
  const jhaRequest = jhaData.add_task_request_connection.edges[0].node;
  const projectId = jhaRequest.project_id;
  const subcontractorId = jhaRequest.subcontractor_id;
  const status = !!jhaRequest.rejected_at
    ? "rejected"
    : !!jhaRequest.attached_files.at(0)?.jha_creation_request_verified_at &&
      !jhaRequest.rejected_at
    ? "processed"
    : "pending";

  const addToProjectId = jhaRequest.task_add_to_projects.length
    ? jhaRequest.task_add_to_projects[0].project_id
    : undefined;

  const [verifyAndInsertTask, isInserting] =
    useAsyncMutation<VerifyAndAddJHAs_Insert_Task_Mutation>(graphql`
      mutation VerifyAndAddJHAs_Insert_Task_Mutation(
        $where: document_bool_exp!
        $objects: [task_insert_input!]!
        $_set: document_set_input!
        $deleteTaskWhere: task_bool_exp!
        $requestId: uuid!
      ) {
        update_document(where: $where, _set: $_set) {
          affected_rows
        }
        insert_task(objects: $objects) {
          affected_rows
        }
        delete_task(where: $deleteTaskWhere) {
          affected_rows
        }
        update_add_task_request(
          where: { id: { _eq: $requestId } }
          _set: { rejected_at: null, rejected_by_uid: null }
        ) {
          affected_rows
        }
      }
    `);
  const [downloadAllImagesAsPdf, { loading: downloadLoading }] =
    useGenerateImagesPdfForJhaRequestMutation();
  const [emailUserAfterJHACreationCompletion] =
    useEmailUserAfterJhaCreationCompletionMutation();
  const [rejectJHARequest, rejecting] =
    useAsyncMutation<VerifyAndAddJHAs_Reject_Mutation>(graphql`
      mutation VerifyAndAddJHAs_Reject_Mutation(
        $requestId: uuid!
        $_set: add_task_request_set_input!
      ) {
        update_add_task_request_by_pk(
          pk_columns: { id: $requestId }
          _set: $_set
        ) {
          id
        }
      }
    `);
  const currentImageJHA = jhaData.previousImageTask.edges[0]?.node;
  const queryData = jhaData.add_task_request_connection.edges[0].node;
  const files = queryData.attached_files;
  const ppeData = jhaData.ppe_type_connection.edges;
  const permitData = jhaData.permit_type_connection.edges;
  const ecmData = jhaData.ecm_type_connection.edges;
  const [visibleFileIndex, setVisibleFileIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [jhaList, setJHAList] = useState<Array<JHAListItem>>([]);
  const [modalJHA, setModalJHA] = useState<JHAListItem | null>(null);
  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const isTurnerProject =
    queryData.project?.general_contractor_id === turnerGCId;
  const isImagesForOne = queryData.type === "images_for_one";
  const totalFiles = files.length;
  const onSuccess = () => {
    setJHAList([]);
    setModalJHA(null);

    if (visibleFileIndex + 1 >= totalFiles || isImagesForOne)
      navigate("/sfe/verify/jha");
    else setVisibleFileIndex((index) => index + 1);
  };

  const file = files[visibleFileIndex];

  const addJhaAndComplete = async (
    fileIds: string[],
    linkToDoc: boolean,
    addToProjectId?: string,
  ) => {
    setLoading(true);
    try {
      const objects: task_insert_input[] = jhaList.map((jha) => ({
        ...jha.taskInsertInput,
        id: jha.id,
        document_id: linkToDoc ? fileIds[visibleFileIndex] : null,
        task_ppe_types: {
          data: jha.valuesPPE.map((ppe) => ({
            ppe_type_id: ppe.id,
          })),
        },
        task_permit_types: {
          data: jha.valuesPermit.map((permit) => ({
            permit_type_id: permit.id,
          })),
        },
        is_pending_acceptance: true,
        project_id: projectId,
        subcontractor_id: subcontractorId,
      }));

      if (addToProjectId) {
        objects.push(
          ...objects.map((jha) => ({
            ...jha,
            id: uuid.v4(),
            project_id: addToProjectId,
            company_task_id: jha.id,
          })),
        );
      }
      const variables = {
        variables: {
          where: { id: { _in: fileIds } },
          _set: {
            jha_creation_request_verified_at: dayjs().format(),
            jha_creation_request_verified_by_uid: auth.currentUser?.uid,
          },
          objects,
          deleteTaskWhere: {
            creation_request_id: { _eq: requestId },
            request_state: { _eq: "processing" },
          },
          requestId: requestId,
        },
      };

      await verifyAndInsertTask({ ...variables });
      await emailUserAfterJHACreationCompletion({
        variables: {
          input: linkToDoc
            ? { fileId: fileIds[visibleFileIndex] }
            : { requestId: requestId },
        },
      }).catch((e) =>
        notification.error({
          message: "Couldn't send Email.",
          description: e.message,
        }),
      );
      message.success(
        "JHAs Successfully added to the Requested User's Library",
      );
    } finally {
      setLoading(false);
    }
    onSuccess();
  };

  const getUploadTask = (jha: JHAListItem): ViewAndEditTaskType => {
    const task = jha.taskInsertInput;
    return {
      description: task.description?.data.en ?? "",
      descriptionId: uuid.v4(), // DO NOT GENERATE ID on render...  they will change each rerender...
      id: jha.id,
      taskPermits: jha.valuesPermit.map((permit) => ({
        id: permit.id,
        description: permit.name,
      })),
      taskPpes: jha.valuesPPE.map((ppe) => ({
        id: ppe.id,
        description: ppe.name,
      })),
      steps:
        jha.taskInsertInput.task_steps?.data.map((step) => ({
          description: step.description?.data.en ?? "",
          id: uuid.v4(),
          hazards: step.task_step_hazards
            ? step.task_step_hazards.data.map((haz) => ({
                id: uuid.v4(),
                description: haz.description?.data.en ?? "",
                control: haz.control?.data.en ?? "",
                ecms: haz.task_hazard_ecm_types
                  ? haz.task_hazard_ecm_types.data.map((ecm) => ({
                      id: uuid.v4(),
                      description: ecm.ecm_type?.data.name?.data.en ?? "",
                    }))
                  : [],
              }))
            : [],
        })) ?? [],

      ppeCategories: jhaData.ppe_category_connection.edges.map(
        (ppeCategory) => ({
          description: ppeCategory.node.name.en,
          id: ppeCategory.node.id,
          ppes: ppeCategory.node.ppe_types.map((ppe) => ({
            id: ppe.pk,
            description: ppe.name.en,
          })),
        }),
      ),
      permits: jhaData.permit_type_connection.edges.map((permit) => ({
        id: permit.node.pk,
        description: permit.node.name_text,
      })),
      ecms: jhaData.ecm_type_connection.edges.map((ecm) => ({
        id: ecm.node.pk,
        description: ecm.node.name_text,
      })),
    };
  };

  return (
    <StyledContent style={{ textAlign: "center", borderRadius: "20px" }}>
      {!!modalJHA && (
        <Modal
          open={!!modalJHA}
          title={`JHA:  ${
            modalJHA.taskInsertInput.description?.data.en ?? ""
          } `}
          onCancel={() => setModalJHA(null)}
          onOk={() => setModalJHA(null)}
          okText="Ok"
          width={1000}
          footer={[
            <AntdButton
              key="submit"
              type="primary"
              onClick={() => setModalJHA(null)}
            >
              OK
            </AntdButton>,
          ]}
        >
          <ViewAndEditTask
            data={getUploadTask(modalJHA)}
            isNotTurnersProject={!isTurnerProject}
            type="sfe_uploaded_jha"
            onSfeTaskSave={(task) => {
              setJHAList((prev) => {
                const index = prev.findIndex((jha) => jha.id === task.id);
                if (index < 0) throw new Error("jha not found");
                const prevVal = prev[index];
                const newVal: typeof prevVal = {
                  ...prevVal,
                  name: task.description,
                  taskInsertInput: {
                    ...prevVal.taskInsertInput,
                    description: {
                      data: {
                        en: task.description,
                        original: task.description,
                      },
                    },
                    task_steps: {
                      data: task.steps.map((step, i) => ({
                        id: step.id,
                        sort_index: (i + 1) * 10000,
                        description: {
                          data: {
                            en: step.description,
                            original: step.description,
                          },
                        },
                        task_step_hazards: {
                          data: step.hazards.map((haz, j) => ({
                            description: {
                              data: {
                                en: haz.description,
                                original: haz.description,
                              },
                            },
                            control: {
                              data: {
                                en: haz.control,
                                original: haz.control,
                              },
                            },

                            sort_index: (j + 1) * 10000,

                            task_hazard_ecm_types: {
                              data: haz.ecms
                                ? haz.ecms.map((ecm) => ({
                                    ecm_type_id: ecm.id,
                                  }))
                                : [],
                            },
                          })),
                        },
                      })),
                    },
                  },

                  valuesPPE: task.ppes.map((ppe) => ({
                    id: ppe.id,
                    name: ppe.description,
                  })),

                  valuesPermit: task.permits.map((permit) => ({
                    id: permit.id,
                    name: permit.description,
                  })),
                };
                const res = [...prev];
                res[index] = newVal;
                return res;
              });
            }}
          />
        </Modal>
      )}
      {!isImagesForOne && (
        <div className="text-left font-accent">
          File {visibleFileIndex + 1} of {files.length}
        </div>
      )}
      <div>
        <Descriptions size="middle" bordered>
          <Item label="Request Id">{queryData.pk}</Item>
          {queryData.project && (
            <>
              <Item label="Project Name">{queryData.project?.name}</Item>
              <Item label="General Contractor">
                {queryData.project?.general_contractor.name}
              </Item>
            </>
          )}

          <Item label="Subcontractor">{queryData.subcontractor?.name}</Item>
          <br />
          <Item label="Date Requested">
            {!!queryData.requested_at
              ? dayjs(queryData.requested_at).format("LLL")
              : ""}
          </Item>
          <Item label="# of JHAs">{queryData.number_of_task_requested}</Item>
          <Item label="Requested By">{queryData.requested_by_user.name}</Item>
          <br />
          {currentImageJHA && (
            <Item label="JHA Title">{currentImageJHA.description.en}</Item>
          )}
        </Descriptions>
      </div>

      <div className="mt-2">
        {isImagesForOne ? (
          <div className="flex flex-col items-center justify-center">
            <Button
              onClick={async () => {
                const { data } = await downloadAllImagesAsPdf({
                  variables: { input: { requestId } },
                });
                if (data?.generateImagesPdfForJHARequest) {
                  downloadFromUrl(data.generateImagesPdfForJHARequest);
                }
              }}
              loading={downloadLoading}
              label="Download all images as PDF"
              icon={<MaterialSymbol icon="download" />}
            ></Button>

            {status !== "processed" && (
              <>
                <div className="flex flex-row mt-1">
                  {files.map((file, index) => (
                    <div className="w-6/12 font-accent" key={file.pk}>
                      <Card
                        hoverable
                        className="w-11/12 ml-1"
                        cover={
                          <div>
                            <Image src={file.url} alt="Front Image" />
                          </div>
                        }
                      >
                        <Meta
                          title={"Page " + (index + 1)}
                          description={file.pk}
                        />
                      </Card>
                    </div>
                  ))}
                </div>
                {jhaList.length > 0 ? (
                  jhaList.map((jha) => (
                    <div key={jha.id} className="mb-2">
                      <div className="flex gap-1">
                        <Button
                          loading={loading}
                          onClick={() => setModalJHA(jha)}
                          label={jha.name}
                        />
                        <Button
                          label=""
                          secondary
                          onClick={() => setJHAList([])}
                          icon={<MaterialSymbol icon="close" />}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <AddJHAExcelUploadForm
                    single
                    setJHAList={setJHAList}
                    {...{
                      loading,
                      isTurnerProject,
                      ppeData,
                      permitData,
                      requestId,
                    }}
                  />
                )}
                <Button
                  loading={loading}
                  label="Add the JHA and Complete"
                  onClick={() =>
                    addJhaAndComplete(
                      files.map((f) => f.pk),
                      false,
                    )
                  }
                />
                <div className="mt-2">
                  <Button
                    loading={loading}
                    secondary
                    label="Complete without adding"
                    onClick={() => onSuccess()}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <p className="mt-1 font-accent">File Id: {file.pk}</p>
            <div className="flex flex-row items-center justify-center mt-2">
              <div className="flex flex-col items-center justify-center w-6/12">
                <Button
                  loading={loading}
                  onClick={() => file.url && downloadFromUrl(file.url, true)}
                  label={`Download File: ${
                    file.name ?? `${visibleFileIndex + 1}th File`
                  }`}
                />

                {status !== "processed" && (
                  <>
                    <AddJHAExcelUploadForm
                      {...{
                        setJHAList,
                        loading,
                        isTurnerProject,
                        ppeData,
                        permitData,
                        requestId,
                        fileId: file.pk,
                      }}
                    />
                    <Button
                      loading={loading}
                      label={`Add the JHAs and ${
                        visibleFileIndex < totalFiles - 1
                          ? "go to next"
                          : "Complete"
                      }`}
                      onClick={() =>
                        addJhaAndComplete([file.pk], true, addToProjectId)
                      }
                    />
                    <div className="mt-2">
                      <Button
                        loading={loading}
                        secondary
                        label={`${
                          visibleFileIndex < totalFiles - 1
                            ? "Go to next"
                            : "Complete"
                        } without adding`}
                        onClick={() => onSuccess()}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="w-6/12 font-accent">
                Uploaded JHA List For this File
                <br />
                <br />
                {jhaList.map((jha) => (
                  <div key={jha.id}>
                    <AntdButton
                      className="w-11/12 mt-1 truncate rounded-2 font-accent"
                      type="primary"
                      loading={loading}
                      onClick={() => setModalJHA(jha)}
                    >
                      {jha.name}
                    </AntdButton>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {status === "pending" && (
          <div className="flex justify-end">
            <Button
              label="Reject"
              loading={rejecting}
              onClick={async () => {
                await rejectJHARequest({
                  variables: {
                    requestId,
                    _set: {
                      rejected_at: dayjs().toISOString(),
                      rejected_by_uid: auth.currentUser?.uid,
                    },
                  },
                });
                navigate(`/sfe/verify/jha`);
              }}
            ></Button>
          </div>
        )}
      </div>
    </StyledContent>
  );
};
export default VerifyAndAddJHAs;
