import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, useMemo } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import CustomTable from "src/common/components/tables/basic/CustomTable";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { GCProjectSiteAlertsQuery } from "src/common/types/generated/relay/GCProjectSiteAlertsQuery.graphql";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import AppPageLayout from "../../../../../../../common/components/layouts/main/content/AppPageLayout";
import GCProjectSiteAlertExplanation from "./components/GCProjectSiteAlertExplanation";
import withUrlParams from "../../../../../../../common/hoc/withUrlParams";

const query = graphql`
  query GCProjectSiteAlertsQuery(
    $projectId: uuid!
    $order_by: [project_site_notification_order_by!] = {}
    $where: project_site_notification_bool_exp = {}
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          timezone
          billing_settings {
            text_messaging_enabled
            use_project_level_price
          }
          project_twilio_numbers(
            where: {
              use_type: { _eq: "site-alert" }
              verification_status: { _eq: "twilio_approved" }
            }
          ) {
            phone_number
          }
        }
      }
    }
    project_site_notification_connection(order_by: $order_by, where: $where) {
      edges {
        node {
          id
          project {
            id
            name
          }
          is_twilio_reply
          sms_send_count
          images {
            id
          }
          send_sms
          send_notification
          message {
            ...TextTranslationFrag @relay(mask: false)
          }
          message_text
          created_at
        }
      }
    }
  }
`;

const GCProjectSiteAlerts: FC<ProjectIdProps & { subview: string }> = ({
  projectId,
  subview,
}) => {
  const navigate = useNavigate();
  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };

  const data = useLazyLoadQuery<GCProjectSiteAlertsQuery>(
    query,
    {
      projectId,
      where: { project_id: { _eq: projectId } },
      order_by: [{ created_at: Order_By.Desc }],
    },
    {},
  );
  const project = data.project_connection.edges[0].node;

  type MonthylySMSBillType = {
    month: string;
    outBoundSMS: number;
    inBoundSMS: number;
    outBoundMMS: number;
    inBoundMMS: number;
  };
  const monthlySMSMap: Map<string, MonthylySMSBillType> = new Map();

  // const [upsertProjectBillingSettings, { loading: updatingBillingSettings }] =
  //   useUpsertProjectBillingSettingsMutation();
  data.project_site_notification_connection.edges.forEach((pn) => {
    const month = dayjs(pn.node.created_at).format("MMMM, YYYY");
    const monthMap = monthlySMSMap.get(month) || {
      outBoundSMS: 0,
      inBoundSMS: 0,
      outBoundMMS: 0,
      inBoundMMS: 0,
      month,
    };

    if (pn.node.is_twilio_reply && pn.node.images.length > 0) {
      monthMap.inBoundMMS += 1; // only reciving as mms and then sending a notification to gc users
    } else if (pn.node.is_twilio_reply) {
      monthMap.inBoundSMS += 1; // only receiving as sms and then sending notification to gcs
    } else if (pn.node.images.length > 0) {
      monthMap.outBoundMMS += pn.node.sms_send_count;
    } else {
      monthMap.outBoundSMS += pn.node.sms_send_count;
    }
    monthlySMSMap.set(month, monthMap);
  });
  const monthlyBillList: Array<MonthylySMSBillType> = [];
  monthlySMSMap.forEach((val) => monthlyBillList.push(val));
  const columns = useMemo(
    () => [
      {
        title: "Date",
        dataIndex: "date",
        size: "ml" as const,
        contentType: {
          type: "date" as const,
        },
      },
      {
        title: "Alerts",
        dataIndex: "msg",
        size: "lg" as const,
        contentType: {
          type: "note" as const,
        },
      },
      {
        title: "Text Messages",
        dataIndex: "totalMsgsSent",
        size: "md" as const,
      },
      {
        title: "Photo(s)",
        dataIndex: "imagesCount",
        size: "sm" as const,
      },
      {
        title: "Send Method",
        dataIndex: "type",
        size: "md" as const,
      },
    ],
    [],
  );
  const siteAlertsDataSource = useMemo(
    () =>
      data.project_site_notification_connection.edges.map((x) => {
        return {
          pk: x.node.id,
          msg: x.node.message_text,
          date: new Date(x.node.created_at),
          totalMsgsSent: x.node.is_twilio_reply
            ? 1
            : x.node.sms_send_count || "",
          imagesCount: x.node.images.length || "",
          type:
            x.node.send_notification && x.node.send_sms
              ? "Chat & Text"
              : x.node.send_sms
              ? "Text Only"
              : "Chat Only",
        };
      }),
    [data.project_site_notification_connection],
  );
  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "alerts",
      label: "Alerts",
      content: (
        <CustomTable
          columns={columns}
          title={`Alerts (${data.project_site_notification_connection.edges.length})`}
          dataSource={siteAlertsDataSource}
        />
      ),
    },
    {
      id: "sms-count",
      label: "SMS Monthly Count",
      content:
        project.project_twilio_numbers.length > 0 ? (
          <CustomTable
            title="SMS Monthly Count"
            dataSource={monthlyBillList.filter(
              (p) =>
                p.inBoundMMS || p.inBoundSMS || p.outBoundMMS || p.outBoundSMS,
            )}
            columns={[
              { dataIndex: ["month"], title: "Month" },
              {
                dataIndex: ["inBoundSMS"],
                title: "Inbound (Sent Images:  1 MMS = 3 SMS)",
                render: (a, r) => {
                  return r.inBoundMMS * 3 + r.inBoundSMS;
                },
              },
              {
                dataIndex: ["outBoundSMS"],
                title: "Outbound (Sent Images:  1 MMS = 3 SMS)",
                render: (a, r) => {
                  return r.outBoundMMS * 3 + r.outBoundSMS;
                },
              },
            ]}
          />
        ) : (
          <>No Assigned Phone Number found for the project</>
        ),
    },
  ];
  return (
    <AppPageLayout
      pageTitle={`Alerts`}
      pageExplainer={
        <GCProjectSiteAlertExplanation
          isSmsEnabled={!!project.billing_settings?.text_messaging_enabled}
          phoneNumber={project.project_twilio_numbers[0]?.phone_number}
        />
      }
    >
      <CustomTabs
        onTabSelect={handleReportsTabClick}
        tabs={tabs}
        selectedTabId={subview}
      />
    </AppPageLayout>
  );
};

export default withUrlParams(GCProjectSiteAlerts, ["projectId", "subview"]);
