import { useGetProjectDisabledFeaturesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { Menu } from "antd";
import GCNavigationHierarchyMenuLoadingFallback from "../GCNavigationHierarchyMenuLoadingFallback";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import useAuthUser from "../../../../../../../common/hooks/useAuthUser";
import { gcProjectVisitorsQuery } from "../../../../../../../domain-features/sitesafety/entry-routes/visitor/components/GCProjectVisitors";
import { GCProjectVisitorsQuery } from "src/common/types/generated/relay/GCProjectVisitorsQuery.graphql";
import useRoutedMenuProps from "../../../../../../../utility-features/navigation/routed-menu/useRoutedMenuProps";
import { RoutedMenuItem } from "../../../../../../../utility-features/navigation/routed-menu/routedMenuTypes";
import { MaterialSymbol } from "react-material-symbols";

interface GCNavigationHierarchyMenuProjectProps {
  projectId: string;
}

const GCNavigationHierarchyMenuProject: React.FC<
  GCNavigationHierarchyMenuProjectProps
> = (props) => {
  const authUser = useAuthUser();

  // TODO check visitor feature with useGetProjectDisabledFeaturesQuery instead
  const projectVisitorsData = useLazyLoadQuery<GCProjectVisitorsQuery>(
    gcProjectVisitorsQuery,
    { projectId: props.projectId, empId: authUser.uid },
    //refreshedQueryOptions ?? {}
  );

  const { data: projectDisabledFeaturesData, loading } =
    useGetProjectDisabledFeaturesQuery({
      variables: { projectId: props.projectId },
    });

  const setting = projectDisabledFeaturesData?.project_setting_by_pk;

  const basePath = `/gce/hierarchy/project/${props.projectId}`;

  const featureMenuItems: RoutedMenuItem[] = [
    {
      label: "Dashboard",
      path: "dashboard",
      icon: <MaterialSymbol icon="dashboard" />,
    },
    {
      label: "Reports",
      path: "reports",
      icon: <MaterialSymbol icon="summarize" />,
    },
    {
      label: "Subcontractors",
      path: "subcontractors",
      icon: <MaterialSymbol icon="handshake" />,
    },
    {
      label: "Workers",
      path: "workers",
      icon: <MaterialSymbol icon="construction" />,
    },
    {
      label: "Team",
      path: "team",
      icon: <MaterialSymbol icon="group" />,
    },
    {
      label: "Orientation",
      path: "orientation",
      icon: <MaterialSymbol icon="school" />,
    },
    {
      label: "Alerts",
      path: "alerts",
      icon: <MaterialSymbol icon="notifications_active" />,
    },
    {
      path: "daily-reports",
      label: "Daily Reports",
      icon: <MaterialSymbol icon="event_note" />,
    },
    {
      label: "Deliveries",
      path: "deliveries",
      icon: <MaterialSymbol icon="local_shipping" />,
    },
    ...(projectVisitorsData.project_connection.edges &&
    projectVisitorsData.project_connection.edges[0].node
      .is_visitor_log_and_tracking_active
      ? [
          {
            path: "visitors",
            label: "Visitors",
            icon: <MaterialSymbol icon="location_away" />,
          },
        ]
      : []),
    {
      label: "Job Hazard Analysis",
      path: "jha",
      icon: <MaterialSymbol icon="warning" />,
    },
    {
      path: "pre-task-plans",
      label: "Pre-Task Plans",
      icon: <MaterialSymbol icon="assignment" />,
    },
    {
      path: "permits",
      label: "Permits",
      icon: <MaterialSymbol icon="task" />,
    },
    {
      path: "toolbox-talks",
      label: "Toolbox Talks",
      icon: <MaterialSymbol icon="forum" />,
    },
    ...(setting?.site_inspection_enabled
      ? [
          {
            label: "Inspections",
            path: "inspections",
            icon: <MaterialSymbol icon="checklist" />,
          },
        ]
      : []),
    ...(setting?.observation_enabled
      ? [
          {
            label: "Observations",
            path: "observations",
            icon: <MaterialSymbol icon="visibility" />,
          },
        ]
      : []),
    ...(setting?.incident_enabled
      ? [
          {
            label: "Incidents",
            path: "incidents",
            icon: <MaterialSymbol icon="report" />,
          },
        ]
      : []),
    {
      label: "SDS",
      path: "sds",
      icon: <MaterialSymbol icon="health_and_safety" />,
    },
    {
      label: "Settings",
      path: "settings",
      icon: <MaterialSymbol icon="settings" />,
    },
    {
      label: "Billing",
      path: "billing",
      icon: <MaterialSymbol icon="payments" />,
    },
  ];

  const menuProps = useRoutedMenuProps(featureMenuItems, basePath, "Projects");

  if (loading) {
    return <GCNavigationHierarchyMenuLoadingFallback />;
  }
  return <Menu {...menuProps} />;
};

export default GCNavigationHierarchyMenuProject;
