import { message } from "antd";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import insertImages from "src/common/api/relay/mutations/InsertImages";
import UploadButton from "src/common/components/general/upload/UploadButton";
import { RoleConvert } from "src/common/components/tables/TaskEditsTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { getCertificateExpiryValue } from "src/common/functions/getCertificateExpiryValue";
import { CertificationCardQuery$data } from "src/common/types/generated/relay/CertificationCardQuery.graphql";
import { InsertImagesMutation } from "src/common/types/generated/relay/InsertImagesMutation.graphql";
import { Certificate } from "src/root/routes/views/subcontractor/people/workers/SCWorkersUI";
import ImageAutomaticSize from "../general/images/ImageAutomaticSize";
import remToPx from "src/common/functions/remToPx";
import { MaterialSymbol } from "react-material-symbols";

export type CertificationProps =
  CertificationCardQuery$data["worker_certification_connection"]["edges"][number]["node"];

const CertificationCardLayout: FC<{
  cert: CertificationProps;
  refetch?: () => void;
}> = ({ cert, refetch }) => {
  const isVerified = !!cert.verification?.created_at;
  const addedBy = cert.verification?.uploaded_by_user;
  const [title, company] = RoleConvert(addedBy);

  const certificates: Certificate[] = [
    {
      name: cert.certification.name,
      expiryDate: cert.expires_on,
    },
  ];
  const [backImage, setBackImage] = useState<string | undefined>(undefined);
  const clockColor = getCertificateExpiryValue(certificates);
  const [insertCertImages, isInserting] =
    useAsyncMutation<InsertImagesMutation>(insertImages);
  let textColor = "";
  if (clockColor === "low-severity") textColor = "text-orange-light";
  else if (clockColor === "high-severity") textColor = "text-orange-dark";
  else if (clockColor === "negative") textColor = "text-semantic-negative";

  return (
    <>
      {cert.issued_on && (
        <div>Issued at: {dayjs(cert.issued_on).format("LL")}</div>
      )}
      {cert.expires_on && (
        <div className="flex flex-row gap-0.5">
          <div>Expires on:</div>
          <div className={textColor}>{dayjs(cert.expires_on).format(`LL`)}</div>
          <MaterialSymbol icon={"schedule"} />
        </div>
      )}
      <div>
        Date added to SiteForm:{" "}
        {dayjs(cert.verification?.created_at ?? cert.created_at).format("LL")}
      </div>
      {cert.verification?.project?.name && (
        <div>
          Project: {cert.verification.project.name},{" "}
          {cert.verification.project.general_contractor.name}
        </div>
      )}
      <div>
        Added By:{" "}
        {addedBy?.name
          ? addedBy.name +
            " " +
            (addedBy?.role === "worker"
              ? addedBy.worker?.worker_title?.translation.en
              : title) +
            ", " +
            company
          : ""}
      </div>
      {isVerified && (
        <div>
          SFe:&nbsp;
          {cert.verification?.verified_by_uid?.split("-")?.[0]} /
          {dayjs(cert.verification?.verified_at).format("LL")}
        </div>
      )}
      {cert.images.length !== 0 && (
        <div>
          <div className="inline-block w-6/12">
            {cert.images?.[0]?.url && (
              <span>
                <ImageAutomaticSize
                  perfectImageSidePx={remToPx(6)}
                  imageProps={{
                    src: cert.images?.[0]?.url,
                    onClick: (e) => e.stopPropagation(),
                  }}
                />
                <br />
                <span className="ml-1">Front</span>
              </span>
            )}
          </div>
          <div className="inline-block w-6/12">
            {cert.images?.[1]?.url || backImage ? (
              <span>
                <ImageAutomaticSize
                  perfectImageSidePx={remToPx(6)}
                  imageProps={{
                    src: cert.images?.[1]?.url || backImage,
                    onClick: (e) => e.stopPropagation(),
                  }}
                />
                <br />
                <span className="ml-1">Back</span>
              </span>
            ) : (
              <span className="justify-center">
                <div className="flex mb-3 ml-4 align-middle">
                  <UploadButton
                    accept="image/*"
                    onFileUpload={async (url) => {
                      await insertCertImages({
                        variables: {
                          objects: [
                            {
                              parent_id: cert.pk,
                              description: "Back Image",
                              url: url,
                            },
                          ],
                        },
                      });
                      setBackImage(url);
                      message.success("Added Back Image");
                      refetch && refetch();
                    }}
                  />
                </div>
                <span className="ml-1">Back</span>
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default CertificationCardLayout;
