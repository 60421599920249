import { MaterialSymbol } from "react-material-symbols";
import { notification } from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { roleVar } from "src/common/api/apollo/cache";
import ProfileCard from "src/common/components/ProfileCard";
import LoadingContent from "src/common/components/general/loading-fallback/LoadingContent";
import { auth } from "src/common/functions/firebase";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  useForgotPasswordMutation,
  useGetEmployeeUserByPkQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import AppPageLayout from "../../../../../common/components/layouts/main/content/AppPageLayout";
import { ButtonProps } from "antd/es/button";
import withCustomSuspense from "../../../../../common/components/general/withCustomSuspense";

interface GCProfileProps {
  additionalButtons?: ButtonProps[];
}

const GCProfile: React.FunctionComponent<GCProfileProps> = (props) => {
  const authUser = useAuthUser();
  const { data, loading } = useGetEmployeeUserByPkQuery({
    variables: { uid: authUser.uid },
  });
  const [forgotPassword] = useForgotPasswordMutation();
  const navigate = useNavigate();

  if (loading) {
    return <LoadingContent />;
  }

  if (data?.user_by_pk?.role !== "employee") {
    throw new Error("No Valid Login Found");
  }
  const fullName = data.user_by_pk.name;
  const userName = data.user_by_pk.username;
  const company = data.user_by_pk?.employee?.general_contractor.name;
  const position = data.user_by_pk?.employee?.employee_title?.name.clientText;
  const phoneNumber = data.user_by_pk.phone_number ?? undefined;
  const email = data.user_by_pk.email ?? undefined;

  return (
    <AppPageLayout
      pageTitle={"Profile"}
      pageActions={[
        {
          icon: <MaterialSymbol icon="logout" />,
          onClick: async () => {
            roleVar("none");
            await auth.signOut();
            navigate("/");
          },
          children: "Log Out",
        },

        {
          onClick: async () => {
            if (data.user_by_pk?.email) {
              forgotPassword({
                variables: {
                  input: { loginIdentifier: data.user_by_pk!.email },
                },
              });
            }
            notification.open({
              message: "We emailed you a password reset link!",
            });
          },
          icon: <MaterialSymbol icon="key" />,
          children: "Update Password",
        },
      ]}
    >
      <ProfileCard
        {...{
          company,
          fullName,
          position,
          userName: userName ?? undefined,
          email,
          phoneNumber,
        }}
      />
    </AppPageLayout>
  );
};

export default withCustomSuspense(GCProfile);
