/**
 * @generated SignedSource<<40d63dda372325fbddf0c3e3105a59fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_drug_test_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkerDrugTestTableDeleteDrugTestMutation$variables = {
  where: worker_drug_test_bool_exp;
};
export type WorkerDrugTestTableDeleteDrugTestMutation$data = {
  readonly delete_worker_drug_test: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type WorkerDrugTestTableDeleteDrugTestMutation = {
  response: WorkerDrugTestTableDeleteDrugTestMutation$data;
  variables: WorkerDrugTestTableDeleteDrugTestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "worker_drug_test_mutation_response",
    "kind": "LinkedField",
    "name": "delete_worker_drug_test",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "worker_drug_test",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerDrugTestTableDeleteDrugTestMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkerDrugTestTableDeleteDrugTestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b9db10779156b47262c4a2f1e7db184f",
    "id": null,
    "metadata": {},
    "name": "WorkerDrugTestTableDeleteDrugTestMutation",
    "operationKind": "mutation",
    "text": "mutation WorkerDrugTestTableDeleteDrugTestMutation(\n  $where: worker_drug_test_bool_exp!\n) {\n  delete_worker_drug_test(where: $where) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "523ab859da013798ed8bb4b6f1fe0204";

export default node;
