import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { MaterialSymbol } from "react-material-symbols";

export interface BackButtonProps {
  url?: string;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Button
      {...{
        onClick: () => {
          if (props.url) {
            navigate(props.url);
          } else {
            navigate(-1);
          }
        },
        icon: <MaterialSymbol icon="undo" />,
        ...props,
      }}
    >
      Back
    </Button>
  );
};

export default BackButton;
