import React from "react";
import CustomSelect, {
  CustomSelectProps,
} from "src/common/components/general/CustomSelect";

interface WorkerProfileRoleProps extends CustomSelectProps {}

const WorkerProfileRole: React.FunctionComponent<WorkerProfileRoleProps> = (
  props,
) => {
  return <CustomSelect {...{ ...props }} />;
};

export default WorkerProfileRole;
