import { MaterialSymbol } from "react-material-symbols";
import dayjs from "dayjs";
import React from "react";
import LabelIcon from "src/common/components/general/LabelIcon";
import Separator from "src/common/components/general/Separator";
import getDateStr from "src/common/functions/dates/getDateStr";

// TODO: add record id
export type GCDailyReportDeliveryType = {
  title: string;
  deliveryTime: dayjs.Dayjs;
  author: string;
  storageLocation: string;
  detail: string;
};

const GCProjectReportDailySummaryDeliveriesItem: React.FC<
  GCDailyReportDeliveryType
> = (props) => {
  return (
    <div key={props.deliveryTime.format()} className="flex flex-col gap-0.5">
      <div className="flex flex-row items-center gap-0.5">
        <div className="font-accent">{props.title}</div>
      </div>
      {!!props.detail && (
        <LabelIcon
          label={props.detail}
          icon={<MaterialSymbol icon="package_2" />}
        />
      )}
      {!!props.author && (
        <LabelIcon
          label={props.author}
          icon={<MaterialSymbol icon={"edit"} />}
        />
      )}
      {!!props.storageLocation && (
        <LabelIcon
          label={props.storageLocation}
          icon={<MaterialSymbol icon="forklift" />}
        />
      )}
      <LabelIcon
        label={getDateStr(props.deliveryTime, {
          withTime: true,
          format: "numeric",
        })}
        icon={<MaterialSymbol icon="calendar_clock" />}
      />
    </div>
  );
};

interface GCProjectReportsDailySummaryDeliveriesProps {
  deliveries: GCDailyReportDeliveryType[];
}

const GCProjectReportsDailySummaryDeliveries: React.FC<
  GCProjectReportsDailySummaryDeliveriesProps
> = (props) => {
  return !!props.deliveries.length ? (
    <div className="flex flex-col gap-1">
      {props.deliveries.reduce<React.ReactNode>(
        /** use map with ID instead of reduce */ (acc, delivery, i) => (
          <>
            {acc}
            <Separator />
            <GCProjectReportDailySummaryDeliveriesItem {...delivery} />
          </>
        ),
        <GCProjectReportDailySummaryDeliveriesItem {...props.deliveries[0]} />,
      )}
    </div>
  ) : null;
};

export default GCProjectReportsDailySummaryDeliveries;
