import React from "react";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import { Button } from "antd";
import ButtonHuge from "../../general/button/ButtonHuge";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useCurrentLangStrings } from "../../../../utility-features/i18n/context/languageHooks";
import { MaterialSymbol } from "react-material-symbols";

export interface SiteFeatureStepLayoutProps {
  onNextButtonClick?: () => void;
  nextButtonText?: string;
  backButtonText?: string;
  nextButtonDisabled?: boolean;
  onBackButtonClick?: () => void;
  backButtonDisabled?: boolean;
  refresh?: boolean;
  children: React.ReactNode;
  loading?: boolean;
}

const SiteFeatureStepLayout: React.FC<SiteFeatureStepLayoutProps> = (props) => {
  const nextButtonHidden = !props.onNextButtonClick;
  const backButtonHidden = !props.onBackButtonClick;
  const langStrings = useCurrentLangStrings();
  console.log(`SiteFeatureStepLayout rendered`);
  return (
    <div className={`flex flex-col gap-px h-full w-full`}>
      <div className={`w-full flex-1 relative z-10`}>
        <div className={`w-full h-full overflow-y-auto absolute inset-0`}>
          <CustomSuspense>{props.children}</CustomSuspense>
        </div>
      </div>
      {(!nextButtonHidden || !backButtonHidden) && (
        <div className={`w-full flex flex-col gap-0.5`}>
          {!nextButtonHidden && (
            <ButtonHuge
              onClick={() =>
                props.onNextButtonClick && props.onNextButtonClick()
              } // do not write as onClick={props.onNextButtonClick} as that provides optional argument eventHandler which sometimes we do not want so better to exclude
              disabled={props.nextButtonDisabled}
              loading={props.loading}
              type={"primary"}
            >
              {props.nextButtonText ?? langStrings.strings.next}
            </ButtonHuge>
          )}
          {!backButtonHidden && (
            <Button
              onClick={props.onBackButtonClick}
              disabled={props.backButtonDisabled || props.loading}
              block
              icon={
                props.refresh ? (
                  <MaterialSymbol icon={"refresh"} />
                ) : (
                  <ArrowLeftOutlined />
                )
              }
            >
              {props.backButtonText ?? langStrings.strings.back}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default SiteFeatureStepLayout;
