import { MaterialSymbol } from "react-material-symbols";
import Link from "antd/lib/typography/Link";
import React, { FC } from "react";
import Icon from "src/common/components/general/Icon";
import { Tag } from "antd";
import FilterControllerInput from "../FilterControllerInput";
import { CheckboxCustomFilter, StateFunctions } from "./CustomFilterController";

export type CustomCheckListProps = {
  title: string;
  options: CheckboxCustomFilter["options"];
  initialClose?: boolean;
} & StateFunctions;
const CustomCheckList: FC<CustomCheckListProps> = ({
  options,
  appliedFiltersfilterMap,
  setAppliedFilter,
  setRemovalFilters,
  title,
  initialClose,
}) => {
  return (
    <FilterControllerInput
      {...{
        title,
        initialClose,
        component: (
          <>
            <div className="block -m-0.25 w-full">
              {options.map(({ option, onCancel }, id) => (
                <div key={id}>
                  {appliedFiltersfilterMap[title + "%" + option + "%" + id] && (
                    <div className="m-0.5" key={id}>
                      <Tag
                        onClose={() => {
                          onCancel();
                          setAppliedFilter((prev) => ({
                            ...prev,
                            [title + "%" + option + "%" + id]: false,
                          }));
                        }}
                        key={id}
                        closable={true}
                      >
                        {option}
                      </Tag>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {options.map(({ option, onApply, onCancel }, id) => (
              <div key={id}>
                {appliedFiltersfilterMap[title + "%" + option + "%" + id] !==
                  true && (
                  <Link
                    className="flex mt-0.75 "
                    onClick={() => {
                      onApply();
                      setAppliedFilter((prev) => ({
                        ...prev,
                        [title + "%" + option + "%" + id]: true,
                      }));
                      setRemovalFilters((prev) => ({
                        ...prev,
                        [title + "%" + option + "%" + id]: () => {
                          onCancel();
                          setAppliedFilter((prev) => ({
                            ...prev,
                            [title + "%" + option + "%" + id]: false,
                          }));
                        },
                      }));
                    }}
                  >
                    <Icon icon={<MaterialSymbol icon="add" />} color="interactive" />
                    <span className="ml-0.5 text-static-primary">{option}</span>
                  </Link>
                )}
              </div>
            ))}
          </>
        ),
      }}
    />
  );
};
export default CustomCheckList;
