import { Button, Input, message, Modal, Row } from "antd";
import { nanoid } from "nanoid";
import React, { useState, useEffect } from "react";
import workerAndUserUpdateMutation from "src/common/api/relay/mutations/UpdateWorkerAndUser";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useCreateInviteMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { UpdateWorkerAndUserMutation } from "src/common/types/generated/relay/UpdateWorkerAndUserMutation.graphql";
import gqlErrorString from "src/common/functions/gqlErrorString";

export interface InviteWorkerModalProps {
  onFinish: (inviteId: string) => void;
  onClose: () => void;
  visible: boolean;
  projectId: string;
  workerId: string;
  email?: string;
  phone?: string;
  dataId?: string;
}

const InviteWorkerModal: React.FC<InviteWorkerModalProps> = ({
  workerId,
  onClose,
  onFinish,
  visible,
  projectId,
  email,
  phone,
  dataId,
}) => {
  const [emailValue, setEmailValue] = useState<string | undefined>(email);
  const [phoneValue, setPhoneValue] = useState<string | undefined>(phone);
  const [loading, setLoading] = useState(false);
  const [createInvite] = useCreateInviteMutation();
  const [userUpdate] = useAsyncMutation<UpdateWorkerAndUserMutation>(
    workerAndUserUpdateMutation,
  );
  // const relayEnv = useRelayEnvironment();
  const onEmailSendClick = async () => {
    if (!emailValue) {
      message.error("Email Not Found");
      return;
    }
    setLoading(true);
    try {
      if (emailValue.toLowerCase().trim() !== email) {
        await userUpdate({
          variables: {
            workerId,
            user_set: { email: emailValue },
            worker_set: {},
          },
        })
      }
      const inviteId = nanoid(12);
      await createInvite({
        variables: {
          objects: [
            {
              inviteId,
              serverContact: true,
              claimingAccount: true,
              joinProjectId: projectId,
              toEmail: emailValue,
              toUid: workerId,
            },
          ],
        },
      });
      onFinish(inviteId);
      message.success("Invite Sent");
    } catch (e) {
      if (String(e).includes("user_email_key")) {
        message.error("Email registered with other user");
      } else {
        throw e;
      }
    } finally {
      setLoading(false);
    }
  };

  const onPhoneSendClick = async () => {
    if (!phoneValue) {
      message.error("Phone Number Not Found");
      return;
    }
    setLoading(true);
    try {
      if (phoneValue.toLowerCase().trim() !== phone) {
        await userUpdate({
          variables: {
            workerId,
            user_set: { phone_number: phoneValue },
            worker_set: {},
          },
        });
      }
      const inviteId = nanoid(12);

      await createInvite({
        variables: {
          objects: [
            {
              inviteId,
              toUid: workerId,
              serverContact: true,
              claimingAccount: true,
              joinProjectId: projectId,
              toPhoneNumber: phoneValue,
            },
          ],
        },
      });
      onFinish(inviteId);
      message.success("Invite Sent");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      title={`Invite this worker to the SiteForm mobile app`}
      okButtonProps={{ style: { display: "none" } }}
      confirmLoading={loading}
      onCancel={onClose}
    >
      <div className="flex flex-col items-center gap-1">
        <p>
          They can access and submit Pre-Task Plans, Daily Reports, Toolbox
          Talks, and other SiteForm awesomeness.
        </p>
        <div className="flex justify-between w-full items-center	">
          <Input
            placeholder="User's email address"
            value={emailValue}
            onChange={(e) => {
              setEmailValue(e.target.value);
            }}
          />
          <Button
            size="small"
            type="primary"
            className="ml-1"
            // className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
            onClick={onEmailSendClick}
          >
            ✉️ Email Invite
          </Button>
        </div>
        <div>OR</div>
        <div className="flex justify-between w-full items-center">
          <Input
            placeholder="User's phone number"
            value={phoneValue}
            onChange={(e) => {
              setPhoneValue(e.target.value);
            }}
          />
          <Button
            size="small"
            type="primary"
            className="ml-1 pr-1"
            // className="text-black hover:text-gray-700 focus:outline-none transition-colors duration-300"
            onClick={onPhoneSendClick}
          >
            💬 Text Invite{"  "}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default InviteWorkerModal;
