import React, { FC } from "react";
import Button from "src/common/components/general/button/Button";
import { MaterialSymbol } from "react-material-symbols";

interface PosterDownloadProps {
  header: string;
  description: string;
  onDownload: () => Promise<void>;
  loading: boolean;
  imageUrl: string;
}

const PosterDownload: FC<PosterDownloadProps> = ({
  description,
  header,
  loading,
  onDownload,
  imageUrl,
}) => {
  return (
    <div className="orientationQRCodePoster ml-2 w-1/6 flex items-center flex-col border-0.25 rounded-1 border-interactive-secondary p-1">
      <p className="text-1.25 text-center">{header}</p>
      <div className="flex justify-center my-1">
        <img className="w-1/2" src={imageUrl}></img>
      </div>
      <p className="text-grey	text-center">{description}</p>
      <div className="w-1/2 mt-1">
        <Button
          loading={loading}
          large
          fullWidth
          onClick={async () => {
            await onDownload();
          }}
          icon={<MaterialSymbol icon="download" />}
        ></Button>
      </div>
    </div>
  );
};
export default PosterDownload;
