import React, { useEffect, useRef, useState } from "react";
import SiteOrientationInPersonManagerLayout from "../../components/SiteOrientationInPersonManagerLayout";
import { SiteOrientationInPersonManagerStartingView_MarkSelectedMutation } from "src/common/types/generated/relay/SiteOrientationInPersonManagerStartingView_MarkSelectedMutation.graphql";
import type { user_orientation_insert_input } from "src/common/types/generated/relay/types";
import SiteOrientationInPersonManagerViewUsersSelect from "./components/SiteOrientationInPersonManagerViewUsersSelect";
import { Button, Input } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation, useRelayEnvironment } from "react-relay/hooks";
import { getNextHardHatNumber } from "src/common/functions/hardHatHelpers";
import dayjs from "dayjs";
import {
  OacUserType,
  WorkerType,
} from "../../../../../../utils/siteOrientationTypes";
import useGetSiteOrientationInPersonWorkerList from "src/domain-features/siteorientation/utils/useGetSiteOrientationInPersonWorkerList";
import getCorporateCompletionMap from "../../utils/getCorporateCompletionMap";
import { MaterialSymbol } from "react-material-symbols";

const MarkSelectedWorkers = graphql`
  mutation SiteOrientationInPersonManagerStartingView_MarkSelectedMutation(
    $projectIds: [uuid!]!
    $mainProjectId: uuid!
    $workerIds: [uuid!]
    $records: [user_orientation_insert_input!]!
    $nextHH: Int
  ) {
    insert_user_orientation(
      objects: $records
      on_conflict: {
        constraint: user_orientation_user_id_project_id_key
        update_columns: [selected]
      }
    ) {
      affected_rows
    }
    update_project_worker(
      where: {
        project_id: { _in: $projectIds }
        worker_id: { _in: $workerIds }
      }
      _set: { archived_at: null, archived_by_uid: null, deleted_at: null }
    ) {
      affected_rows
    }
    update_user_orientation(
      where: { project_id: { _in: $projectIds }, user_id: { _nin: $workerIds } }
      _set: { selected: false }
    ) {
      affected_rows
    }
    update_project_by_pk(
      pk_columns: { id: $mainProjectId }
      _set: { next_hard_hat_number: $nextHH }
    ) {
      pk: id @__clientField(handle: "pk")
      next_hard_hat_number
    }
  }
`;

export interface SiteOrientationInPersonManagerStartingViewProps {}

const SiteOrientationInPersonManagerStartingView: React.FC<
  SiteOrientationInPersonManagerStartingViewProps
> = ({}) => {
  const projectId = useParams().projectId as string;
  const data = useGetSiteOrientationInPersonWorkerList({
    projectId,
    projectEmployeeWhere: {
      project: { orientation_project_id: { _eq: projectId } },
      user_orientation: {
        in_person_orientated_at: { _is_null: true },
        completed_at: { _is_null: true },
      },
    },
    projectWorkerWhere: {
      project: { orientation_project_id: { _eq: projectId } },
      user: { role: { _eq: "worker" } },
      subcontractor_worker: {},
      deleted_at: { _is_null: true },
      _or: [
        { _not: { user_orientation: {} } },
        {
          user_orientation: {
            in_person_orientated_at: { _is_null: true },
            completed_at: { _is_null: true },
          },
        },
      ],
    },
  });

  const [markSelectedWorkers, updating] =
    useMutation<SiteOrientationInPersonManagerStartingView_MarkSelectedMutation>(
      MarkSelectedWorkers,
    );
  const hardHatRef = useRef<React.ElementRef<typeof Input>>(null);

  const environment = useRelayEnvironment();
  const [searchParams] = useSearchParams();
  const completionType = searchParams.get("completionType");

  const project = data.project_connection.edges[0]?.node;
  if (!project) {
    throw new Error("Project does not exist");
  }

  useEffect(() => {
    if (!project.next_hard_hat_number) {
      getNextHardHatNumber(environment, projectId).then(
        (v) => {
          if (hardHatRef.current?.input?.value)
            hardHatRef.current.input.value = (v || 1) + "";
        },
        (e) => console.error("Next HH failed", e),
      );
    }
  }, [project]);

  const orientations = data.orientation_connection.edges || [];
  const archivedWorkers: {
    [userId: string]: WorkerType & { initialSelected?: boolean };
  } = {};
  const activeWorkers: {
    [userId: string]: WorkerType & { initialSelected?: boolean };
  } = {};
  const projectHasUniversal =
    data.universalOrientations.edges.length > 0 &&
    project.agc_universal_orientation;
  const corporateCompletionMap = getCorporateCompletionMap(
    data,
    completionType,
  );
  (data.project_worker_connection.edges || []).forEach((v) => {
    if (
      !project.in_person_orientation &&
      orientations.length > 0 &&
      v.node.user_orientation?.orientated_at
    ) {
      ///
    } else {
      if (v.node.archived_at) {
        if (archivedWorkers[v.node.worker_id]) {
          if (archivedWorkers[v.node.worker_id].projectsToRegister)
            archivedWorkers[v.node.worker_id].projectsToRegister![
              v.node.project_id
            ] = true;
          else
            archivedWorkers[v.node.worker_id].projectsToRegister = {
              [v.node.project_id]: true,
            };
        } else {
          archivedWorkers[v.node.worker_id] = {
            userType: "worker",
            name: { old: v.node.user!.name },
            id: v.node.worker_id,
            title: v.node.worker.worker_title
              ? {
                  name: v.node.worker.worker_title.translation.en,
                  id: v.node.worker.worker_title_id!,
                }
              : undefined,
            company: {
              // we're only calling workers who are under a subcontractor for this project. so this relation will be present
              title: v.node.worker.subcontractor?.name ?? "",
              id: v.node.worker.subcontractor_id ?? "",
              companyWorkerTitles: [],
            },
            corporateOrientationPassed:
              !projectHasUniversal &&
              !!(
                (data.corporateOrientation.edges.length > 0 &&
                  corporateCompletionMap[v.node.worker_id]) ||
                (v.node.user_orientation?.orientation_provided_by_user_id &&
                  v.node.user_orientation?.orientated_at)
              ),
            universalCompleted: !!(projectHasUniversal
              ? v.node.user!.universal_orientations.length &&
                dayjs(
                  v.node.user!.universal_orientations[0]
                    .universal_orientated_at,
                ).isBefore(dayjs().subtract(1, "hour"))
              : false),
            projectsToRegister: { [v.node.project_id]: true },
          };
        }
      } else {
        if (activeWorkers[v.node.worker_id]) {
          if (activeWorkers[v.node.worker_id].projectsToRegister)
            activeWorkers[v.node.worker_id].projectsToRegister![
              v.node.project_id
            ] = true;
          else
            activeWorkers[v.node.worker_id].projectsToRegister = {
              [v.node.project_id]: true,
            };
        } else {
          activeWorkers[v.node.worker_id] = {
            userType: "worker",
            initialSelected: v.node.user_orientation?.selected,
            name: { old: v.node.user!.name },
            id: v.node.worker_id,
            title: v.node.worker.worker_title
              ? {
                  name: v.node.worker.worker_title.translation.en,
                  id: v.node.worker.worker_title_id!,
                }
              : undefined,
            company: {
              // we're only calling workers who are under a subcontractor for this project. so this relation will be present
              title: v.node.worker.subcontractor?.name ?? "",
              id: v.node.worker.subcontractor_id ?? "",
              companyWorkerTitles: [],
            },
            corporateOrientationPassed:
              !projectHasUniversal &&
              !!(
                (data.corporateOrientation.edges.length > 0 &&
                  corporateCompletionMap[v.node.worker_id]) ||
                (v.node.user_orientation?.orientation_provided_by_user_id &&
                  v.node.user_orientation?.orientated_at)
              ),
            universalCompleted: !!(projectHasUniversal
              ? v.node.user!.universal_orientations.length &&
                dayjs(
                  v.node.user!.universal_orientations[0]
                    .universal_orientated_at,
                ).isBefore(dayjs().subtract(1, "hour"))
              : false),

            projectsToRegister: { [v.node.project_id]: true },
          };
        }
      }
    }
  });
  const gcUsers: {
    [userId: string]: OacUserType & { initialSelected?: boolean };
  } = {};
  data.project_employee_connection.edges.forEach((pe) => {
    gcUsers[pe.node.employee_id] = {
      userType: "oacUser",
      initialSelected: pe.node.user_orientation?.selected,
      ...(pe.node.employee.oac_company
        ? {
            company: {
              title: pe.node.employee.oac_company.name,
              id: pe.node.employee.oac_company_id!,
              companyWorkerTitles: [],
            },
            title: pe.node.employee.oac_title
              ? {
                  name: pe.node.employee.oac_title.name,
                  id: pe.node.employee.oac_title_id!,
                }
              : undefined,
          }
        : {
            company: {
              title: pe.node.employee.general_contractor.name,
              id: pe.node.employee.general_contractor_id,
              companyWorkerTitles: [],
            },
            title: pe.node.employee.employee_title
              ? {
                  name: pe.node.employee.employee_title.name.en,
                  id: pe.node.employee.title_id!,
                }
              : undefined,
          }),
      id: pe.node.employee_id,
      name: { old: pe.node.employee.user.name },
      corporateOrientationPassed:
        !projectHasUniversal &&
        !!(
          (data.corporateOrientation.edges.length > 0 &&
            corporateCompletionMap[pe.node.employee_id]) ||
          (pe.node.user_orientation?.orientation_provided_by_user_id &&
            pe.node.user_orientation?.orientated_at)
        ),
      universalCompleted: !!(projectHasUniversal
        ? pe.node.employee.user.universal_orientations.length &&
          dayjs(
            pe.node.employee.user.universal_orientations[0]
              .universal_orientated_at,
          ).isBefore(dayjs().subtract(1, "hour"))
        : false),
    };
  });

  const [selectedActives, setSelectedActives] = useState<{
    [workerId: string]: boolean;
  }>(
    Object.fromEntries([
      ...Object.values(activeWorkers)
        .filter((w) => w.initialSelected)
        .map((w) => [w.id, true]),
    ]),
  );
  const [selectedArchived, setSelectedArchived] = useState<{
    [workerId: string]: boolean;
  }>({});
  const [selectedOAC, setSelectedOAC] = useState<{
    [workerId: string]: boolean;
  }>(
    Object.fromEntries([
      ...Object.values(gcUsers)
        .filter((w) => w.initialSelected)
        .map((w) => [w.id, true]),
    ]),
  );
  const navigate = useNavigate();

  const onStartClick = async () => {
    const allLinkedProjects =
      data.project_connection.edges[0].node.linked_orientation_projects.map(
        (p) => p.pk,
      );
    const records: Array<user_orientation_insert_input> = [];
    Object.entries(selectedOAC).forEach(([id, selected]) => {
      allLinkedProjects.forEach((p) =>
        records.push({ user_id: id, project_id: p, selected: true }),
      );
    });
    Object.entries(selectedArchived).forEach(([id, selected]) => {
      Object.keys(archivedWorkers[id].projectsToRegister ?? {}).forEach((p) =>
        records.push({ user_id: id, project_id: p, selected: true }),
      );
    });
    Object.entries(selectedActives).forEach(([id, selected]) => {
      Object.keys(activeWorkers[id].projectsToRegister ?? []).forEach((p) =>
        records.push({ user_id: id, project_id: p, selected: true }),
      );
    });
    const allSelected = [
      ...Object.keys(selectedActives),
      ...Object.keys(selectedArchived),
      ...Object.keys(selectedOAC),
    ];
    markSelectedWorkers({
      variables: {
        projectIds: allLinkedProjects,
        mainProjectId: projectId,
        nextHH: parseInt(hardHatRef.current?.input?.value || "") || null,
        workerIds: allSelected,
        records,
      },
      onError: (e) => {
        console.log("failed to mark workers", e);
      },
      onCompleted: () => {
        sessionStorage.setItem("selectedWorkers", JSON.stringify(allSelected));
      },
    });
    navigate(
      `/gce/orientation/${projectId}/worker${
        completionType ? "?completionType=inpersonComplete" : ""
      }`,
    );
  };

  return (
    <SiteOrientationInPersonManagerLayout
      projectInfo={{
        name: project.name,
        gcNames: project.general_contractors
          .map((p) => p.general_contractor.name)
          .join(", "),
        address: [
          project.address.line_1,
          project.address.line_2,
          project.address.city,
          project.address.state_code,
        ]
          .filter((str) => !!str?.length)
          .join(", "),
      }}
      hints={[
        {
          title: "Ask who is in the room",
          icon: <MaterialSymbol icon={"person"} />,
        },
        {
          title: "Select present workers in the list on the right",
          icon: <MaterialSymbol icon={"check_box"} />,
          body: "If a worker is not listed they will register on the next step",
        },
        {
          title: "Select Start and give the device to a worker",
          icon: <MaterialSymbol icon={"check_box"} />,
        },
      ]}
      footer={
        <Button type={`primary`} block onClick={onStartClick}>
          Start
        </Button>
      }
    >
      <SiteOrientationInPersonManagerViewUsersSelect
        activeWorkers={{
          users: activeWorkers,
          selectedUserIds: selectedActives,
          onToggleSelect: (userId) => {
            const new_selected = { ...selectedActives };
            if (selectedActives[userId]) {
              delete new_selected[userId];
            } else {
              new_selected[userId] = true;
            }
            setSelectedActives(new_selected);
          },
        }}
        archivedWorkers={{
          users: archivedWorkers,
          selectedUserIds: selectedArchived,
          onToggleSelect: (userId) => {
            const new_selected = { ...selectedArchived };
            if (selectedArchived[userId]) {
              delete new_selected[userId];
            } else {
              new_selected[userId] = true;
            }
            setSelectedArchived(new_selected);
          },
        }}
        oacUsers={{
          users: gcUsers,
          selectedUserIds: selectedOAC,
          onToggleSelect: (userId) => {
            const new_selected = { ...selectedOAC };
            if (selectedOAC[userId]) {
              delete new_selected[userId];
            } else {
              new_selected[userId] = true;
            }
            setSelectedOAC(new_selected);
          },
        }}
      />
    </SiteOrientationInPersonManagerLayout>
  );
};

export default SiteOrientationInPersonManagerStartingView;
