import { MaterialSymbol } from "react-material-symbols";
import React, { FC } from "react";
import LabelIcon from "src/common/components/general/LabelIcon";
import Separator from "src/common/components/general/Separator";
import dayjs from "dayjs";

export type GCDailyReportCommentsType = {
  id: string;
  comment: string;
  author: string;
  time?: dayjs.Dayjs;
};

const GCProjectReportsDailySummaryCommentsItem: FC<
  GCDailyReportCommentsType
> = (props) => {
  return (
    <>
      <div className="flex flex-col gap-0.5">
        {!!props.comment && (
          <LabelIcon
            icon={<MaterialSymbol icon={"sms"} />}
            label={props.comment}
          />
        )}
        <div className="flex my-auto flex-col gap-0.25">
          {!!props.author && (
            <LabelIcon
              icon={<MaterialSymbol icon={"edit"} />}
              label={props.author}
            />
          )}
          {!!props.time && (
            <LabelIcon
              icon={<MaterialSymbol icon={"calendar_today"} />}
              label={props.time
                .tz("America/New_York")
                .format("MMMM Do YYYY, h:mm:ss A z")}
            />
          )}
        </div>
      </div>
    </>
  );
};

interface GCProjectReportsDailySummaryCommentsProps {
  items: GCDailyReportCommentsType[];
}

const GCProjectReportsDailySummaryComments: React.FC<
  GCProjectReportsDailySummaryCommentsProps
> = (props) => {
  return !!props.items.length ? (
    <div className="flex w-full flex-col  gap-0.5">
      {props.items.map((item) => (
        <React.Fragment key={item.id}>
          <GCProjectReportsDailySummaryCommentsItem {...item} />
          <Separator />
        </React.Fragment>
      ))}
    </div>
  ) : null;
};

export default GCProjectReportsDailySummaryComments;
