import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import FModal, { FModalRef } from "../dialogs/FModal";
import { ConvertWorkerDataType } from "./performWorkerTypes";
import CombineWorkerModal from "./CombineWorkerModal";
import ConvertWorkerToEmployee from "./ConvertWorkerToEmployeeModal";
import ConvertWorkerToSubAdmin from "./ConvertWorkerToSubAdminModal";
import {
  GetWorkersToCombineDocument,
  GetWorkersToCombineQuery,
  GetWorkersToCombineQueryVariables,
  Project_Worker_Bool_Exp,
  useGetWorkersToCombineLazyQuery,
} from "src/common/types/generated/apollo/graphQLTypes";

import { Button } from "antd";
import { commitLocalUpdate, ConnectionHandler } from "relay-runtime";
import { useApolloClient } from "@apollo/client";
import { produce } from "immer";
import { useRelayEnvironment } from "react-relay";
import { MaterialSymbol } from "react-material-symbols";

interface PerformWorkerFunctionsButtonProps {
  isGC?: boolean;
  refetch: () => void;
}

export interface PerformWorkerFunctionsButtonRef {
  refetchData: () => void;
}

interface Values {
  function: "merge" | "workerToGC" | "workerToSubAdmin";
}

type Props = PerformWorkerFunctionsButtonProps &
  (
    | {
        type: "noData";
        projWorkerWhere: Project_Worker_Bool_Exp;
      }
    | {
        type: "withData";
        data: ConvertWorkerDataType[];
        relayQueryConnName?: string;
      }
  );
const PerformWorkerFunctionsButton = forwardRef<
  PerformWorkerFunctionsButtonRef,
  Props
>(({ refetch, isGC, ...props }, ref) => {
  const modalRef = useRef<FModalRef<Values>>(null);
  const [showFunctions, setShowFunctions] = useState(false);
  const onCancel = () => {
    modalRef.current && modalRef.current.form.resetFields();
    setShowFunctions(false);
  };
  const relayEnv = useRelayEnvironment();
  const [
    getWorkersToCombine,
    { data: workersToCombine, loading: workersLoading },
  ] = useGetWorkersToCombineLazyQuery();
  useEffect(() => {
    if (props.type === "noData") {
      getWorkersToCombine({
        variables: { projWorkerWhere: props.projWorkerWhere },
      });
    }
  }, [props.type]);
  useImperativeHandle<
    PerformWorkerFunctionsButtonRef,
    PerformWorkerFunctionsButtonRef
  >(ref, () => ({
    refetchData: () => refetch(),
  }));
  const queryData = (workersToCombine?.project_worker || []).map(
    (pw): ConvertWorkerDataType => ({
      name: pw.user!.name,
      id: pw.user!.id,
      hh: pw.hard_hat_number,
      company: pw.subcontractor.name,
      trade: pw.title?.translation?.en,
      email: pw.user!.email,
      createdPassword: pw.user!.created_password,
      subcontractorId: pw.subcontractor.id,
      subcontractorName: pw.subcontractor.name,
      phoneNumber: pw.user?.phone_number || undefined,
    }),
  );
  console.log(queryData);
  const [selectedFunction, setSelectedFunction] = useState<
    "merge" | "workerToGC" | "workerToSubAdmin" | undefined
  >(undefined);
  const client = useApolloClient();
  const removeWorkerFromCache = (workerId: string) => {
    if (props.type === "withData" && props.relayQueryConnName) {
      const connName = props.relayQueryConnName;
      if (connName)
        commitLocalUpdate(relayEnv, (store) => {
          const conn2 = ConnectionHandler.getConnection(
            store.getRoot(),
            connName,
          );
          if (conn2) {
            const worker = queryData.find((p) => p.id === workerId);
            if (worker?.dataId)
              ConnectionHandler.deleteNode(conn2, worker.dataId);
          }
        });
    } else if (props.type === "noData" && workersToCombine)
      client.cache.writeQuery<
        GetWorkersToCombineQuery,
        GetWorkersToCombineQueryVariables
      >({
        query: GetWorkersToCombineDocument,
        variables: { projWorkerWhere: props.projWorkerWhere },
        data: produce(workersToCombine, (draft) => {
          draft.project_worker = draft.project_worker.filter(
            (pw) => pw.user?.id !== workerId,
          );
        }),
      });
  };
  return (
    <>
      <FModal
        okText={"Next"}
        ref={modalRef}
        width={650}
        title="What function do you want to perform?"
        open={showFunctions}
        onCancel={onCancel}
        onOk={async () => {
          await modalRef.current?.form.validateFields().then((val) => {
            console.log(val.function, "   val  ");
            setSelectedFunction(val.function);
            // onCancel();
          });
        }}
      >
        <FModal.RadioGroup label="" name="function">
          <div className="flex flex-row">
            <FModal.Radio props={{ value: "merge" }} className="flex">
              Combine two workers
            </FModal.Radio>
            <MaterialSymbol icon={"person"} />
            <MaterialSymbol icon={"person"} />
            <MaterialSymbol icon={"arrow_forward"} />
            <MaterialSymbol icon={"person"} />
          </div>
          {isGC && (
            <>
              <div className="flex flex-row">
                <FModal.Radio props={{ value: "workerToGC" }} className="flex">
                  Worker to GC Team Member
                </FModal.Radio>
                <MaterialSymbol icon={"person"} />
                <MaterialSymbol icon={"arrow_forward"} />
                <MaterialSymbol icon={"account_circle"} />
              </div>
              <div
                style={{
                  marginTop: "-20px",
                  fontSize: "12px",
                  marginBottom: 25,
                  marginLeft: "20px",
                }}
              >
                Convert a worker to a member of your Operations Team (but not a
                foreman that submits reports)
              </div>
            </>
          )}
          {isGC && (
            <>
              <div className="flex flex-row">
                <FModal.Radio
                  props={{ value: "workerToSubAdmin" }}
                  className="flex"
                >
                  Worker to Subcontractor Administrator
                </FModal.Radio>
                <MaterialSymbol icon={"person"} />
                <MaterialSymbol icon={"arrow_forward"} />
                <MaterialSymbol icon={"person_check"} />
              </div>
              <div
                style={{
                  marginTop: "-20px",
                  fontSize: "12px",
                  marginLeft: " 20px",
                }}
              >
                Convert a worker to an administrator of their Sub (this will
                give access to their company’s profile)
              </div>
            </>
          )}
        </FModal.RadioGroup>
      </FModal>
      <CombineWorkerModal
        refetch={refetch}
        type="withData"
        removeWorkerFromCache={removeWorkerFromCache}
        onClose={() => {
          setSelectedFunction(undefined);
          // onCancel();
        }}
        visible={selectedFunction === "merge"}
        data={props.type === "withData" ? props.data : queryData}
      />
      {isGC && selectedFunction === "workerToGC" && (
        <ConvertWorkerToEmployee
          refetch={refetch}
          type="selectWorker"
          removeWorkerFromCache={removeWorkerFromCache}
          onClose={() => {
            setSelectedFunction(undefined);
          }}
          onCancel={() => {
            onCancel();
          }}
          visible={true}
          data={props.type === "withData" ? props.data : queryData}
        />
      )}
      {isGC && selectedFunction === "workerToSubAdmin" && (
        <ConvertWorkerToSubAdmin
          refetch={refetch}
          type="selectWorker"
          removeWorkerFromCache={removeWorkerFromCache}
          onClose={() => {
            setSelectedFunction(undefined);
            // onCancel();
          }}
          onCancel={() => {
            onCancel();
          }}
          visible={true}
          data={props.type === "withData" ? props.data : queryData}
        />
      )}
      <div className="mr-1 ml-2">
        <Button
          onClick={() => setShowFunctions(true)}
          icon={<MaterialSymbol icon={"manage_accounts"} />}
          loading={workersLoading}
        />
      </div>
    </>
  );
});
export default PerformWorkerFunctionsButton;
