import React, { useMemo } from "react";
import Button from "src/common/components/general/button/Button";
import Icon from "src/common/components/general/Icon";
import getDateStr from "src/common/functions/dates/getDateStr";
import {
  InvoiceData,
  InvoiceLine,
} from "src/root/routes/views/general-contractor/billing/types";
import { Popover } from "antd";
import dayjs from "dayjs";
import { MaterialSymbol } from "react-material-symbols";

interface Invoice {
  invoice: InvoiceData;
  expanded: boolean;
  onClick: () => void;
}

interface LineGroup {
  date: string;
  lines: InvoiceLine[];
}

const Invoice: React.FC<Invoice> = (props) => {
  const { invoice } = props;

  const emailClick = () => {
    alert(`emailClick`);
    // TODO send invoice data to email that is to be entered
  };
  const groups = useMemo<LineGroup[]>(() => {
    const map = new Map<string, InvoiceLine[]>();
    if (invoice.lines) {
      for (const line of invoice.lines) {
        const key = line.date ?? "";
        const lines = map.get(key);
        if (lines) lines.push(line);
        else {
          map.set(key, [line]);
        }
      }
    }
    const res = [];
    for (const [key, value] of map.entries()) {
      res.push({
        date: key,
        lines: value,
      });
    }
    return res;
  }, [invoice.lines]);

  const { payment_url, invoice_pdf } = invoice;
  return (
    <div
      onClick={!props.expanded ? props.onClick : undefined}
      className={`w-full bg-white rounded-1 border transition-all ${
        props.expanded ? "p-2" : "p-1"
      } ${
        !props.expanded
          ? "border-interactive-secondary cursor-pointer hover:border-interactive-primary"
          : "border-suplementary-3"
      }`}
    >
      <div
        className={`flex flex-row items-center w-full transition-all ${
          props.expanded ? "gap-0.5" : "gap-1.5"
        }`}
      >
        <div className="flex flex-row items-center flex-1 gap-1">
          <div className={`flex flex-col flex-1 gap-0`}>
            <p className="text-1.5">Invoice</p>
            <p>
              {getDateStr(invoice.period_start)} -{" "}
              {getDateStr(invoice.period_end)}
            </p>
          </div>
          <div
            className={`flex flex-fow items-center gap-0.5 ${
              invoice.paid ? "opacity-30" : "opacity-100"
            }`}
          >
            {invoice.paid && (
              <Icon icon={<MaterialSymbol icon="check" />} color={`static`} />
            )}
            {!invoice.paid && payment_url && (
              <Button
                onClick={() => {
                  window.open(payment_url);
                  //window.open(invoice.payment_url, "_blank");
                }}
                label={`Pay`}
              />
            )}
            <p
              className={`text-1.5 text-static-primary`}
            >{`$${invoice.amount}`}</p>
            {invoice_pdf && (
              <a
                href={invoice_pdf}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Icon
                  icon={<MaterialSymbol icon="download" />}
                  color={`static`}
                />
              </a>
            )}
          </div>
        </div>
      </div>
      <div
        className={`w-full overflow-hidden transition-all`}
        style={{ height: props.expanded ? "auto" : 0 }}
      >
        <div className="flex flex-col gap-2 pt-1">
          <div className="p-1 bg-suplementary-1 rounded-1">
            <div
              style={{
                display: "table",
                width: "100%",
                maxWidth: "600px",
                margin: "auto",
              }}
            >
              {groups.map((group) => {
                return (
                  <>
                    {!!group.date && (
                      <div style={{ display: "table-row" }}>
                        <div
                          style={{
                            display: "table-cell",
                            paddingTop: "10px",
                          }}
                        >
                          {dayjs(group.date).format("MMMM YYYY")}
                        </div>
                      </div>
                    )}
                    {group.lines.map((line) => {
                      const description = line.description ?? "";
                      return (
                        <div style={{ display: "table-row" }}>
                          <div
                            style={{
                              display: "table-cell",
                              paddingLeft: "20px",
                            }}
                          >
                            {line.name}
                          </div>
                          <div
                            style={{
                              display: "table-cell",
                              textAlign: "right",
                            }}
                          >
                            {line.hint ? (
                              <Popover
                                content={
                                  <div>
                                    {line.hint.map((v) => (
                                      <div>{v}</div>
                                    ))}
                                  </div>
                                }
                              >
                                {description}
                              </Popover>
                            ) : (
                              description
                            )}
                          </div>
                          <div
                            style={{
                              display: "table-cell",
                              textAlign: "right",
                            }}
                          >
                            {line.amount} USD
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })}
            </div>
          </div>

          <div className="flex flex-row justify-center gap-1">
            {invoice_pdf && (
              <Button
                label="Download PDF"
                icon={<MaterialSymbol icon="download" />}
                onClick={() => {
                  window.open(invoice_pdf, "_blank");
                }}
                secondary
              />
            )}
            {/*<Button
              label="Send to E-mail"
              icon={"mail"}
              onClick={emailClick}
              secondary
            />*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
