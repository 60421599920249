/**
 * @generated SignedSource<<a7c6ac3a22bc8ff5e8e9d37d580e1739>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { safety_data_sheet_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddSdsToProjectModalMutation$variables = {
  objects: ReadonlyArray<safety_data_sheet_insert_input>;
};
export type AddSdsToProjectModalMutation$data = {
  readonly insert_safety_data_sheet: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type AddSdsToProjectModalMutation = {
  response: AddSdsToProjectModalMutation$data;
  variables: AddSdsToProjectModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "safety_data_sheet_mutation_response",
    "kind": "LinkedField",
    "name": "insert_safety_data_sheet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSdsToProjectModalMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSdsToProjectModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "846ebfe7e6b6d3f2359a6f0ac9aa2f2d",
    "id": null,
    "metadata": {},
    "name": "AddSdsToProjectModalMutation",
    "operationKind": "mutation",
    "text": "mutation AddSdsToProjectModalMutation(\n  $objects: [safety_data_sheet_insert_input!]!\n) {\n  insert_safety_data_sheet(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7f936aee8137a7685d38c1bb6e33726";

export default node;
