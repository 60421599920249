import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import UploadButton from "src/common/components/general/upload/UploadButton";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Image } from "antd";
import { VisitorPosterUpload_Mutation } from "src/common/types/generated/relay/VisitorPosterUpload_Mutation.graphql";
import { VisitorPosterUploadQuery } from "src/common/types/generated/relay/VisitorPosterUploadQuery.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";

const query = graphql`
  query VisitorPosterUploadQuery {
    general_contractor_connection {
      edges {
        node {
          visitor_log_poster_background_url
        }
      }
    }
  }
`;
const VisitorPosterUpload: React.FC = () => {
  const [updateVisitorPoster] = useAsyncMutation<VisitorPosterUpload_Mutation>(
    graphql`
      mutation VisitorPosterUpload_Mutation(
        $where: general_contractor_bool_exp!
        $_set: general_contractor_set_input!
      ) {
        update_general_contractor(where: $where, _set: $_set) {
          affected_rows
        }
      }
    `,
  );

  const data = useLazyLoadQuery<VisitorPosterUploadQuery>(query, {});

  const [posterBackgroundImageUrl, setPosterBackgroundImageUrl] = useState(
    data.general_contractor_connection.edges[0].node
      .visitor_log_poster_background_url ?? "",
  );
  return (
    <div>
      <h3 className="text-2 mb-2 ml-1">Visitors Poster Background Image</h3>
      <div className="flex flex-row items-end">
        <div className="w-3/12">
          <Image src={posterBackgroundImageUrl} />
        </div>
        <div className="ml-2">
          <UploadButton
            accept="image/*"
            children="Upload"
            onFileUpload={async (imageUrl) => {
              setPosterBackgroundImageUrl(imageUrl);
              await updateVisitorPoster({
                variables: {
                  where: {},
                  _set: {
                    visitor_log_poster_background_url: imageUrl,
                  },
                },
              });
            }}
            // loading={props.loading}
          />
        </div>
      </div>
    </div>
  );
};

export default VisitorPosterUpload;
