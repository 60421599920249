import { MaterialSymbol } from "react-material-symbols";
import React from "react";
import { Button, Popover, Typography } from "antd";

interface ContactLinkProps {
  value: string;
}

type ContactType = "email" | "phone" | undefined;

export function detectContactType(value: string): ContactType {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\+?\d{7,15}$/;

  if (emailRegex.test(value)) {
    return "email";
  }

  if (phoneRegex.test(value.replace(/[\s()-]/g, ""))) {
    return "phone";
  }

  return undefined;
}

const ContactLink: React.FunctionComponent<ContactLinkProps> = ({ value }) => {
  // TODO: check automatically if the value is email or phone number

  const { icon, href } = (() => {
    switch (detectContactType(value)) {
      case "phone":
        return {
          icon: <MaterialSymbol icon="smartphone" />,
          href: `tel:${value}`,
        };

      case "email":
        return {
          icon: <MaterialSymbol icon="mail" />,
          href: `mailto:${value}`,
        };

      default:
        return {
          icon: <MaterialSymbol icon="contact_page" />,
        };
    }
  })();

  return (
    <Popover
      content={<Typography.Paragraph copyable>{value}</Typography.Paragraph>}
    >
      <Button href={href} icon={icon} type={"text"} />
    </Popover>
  );
};

export default ContactLink;
