import React, { useState } from "react";
import { RangeType } from "../tables/basic/CustomTableDateRangePicker";
import CustomFilterController, {
  CustomFilterType,
} from "./CustomFilterComponents/CustomFilterController";
import CustomFilterTopSection from "./CustomFilterComponents/CustomFilterTopSection";
import FilterControllerInput, {
  FilterControllerInputProps,
} from "./FilterControllerInput";
import { Drawer, Flex, Tag, Typography } from "antd";

export type FilterSearchQueryType = {
  text: string;
  filterControllerId: string;
  inputId: string;
};

interface FilterControllerProps {
  inputs: ({ id: string } & FilterControllerInputProps)[];
  customFilters?: CustomFilterType[];
  dataIndexFilters?: {
    [key: string]: { val: string | boolean | string[]; onCancel: () => void };
  };
  searchFilters?: {
    placeholder?: string;
    onSubmit?: (searchQuery: FilterSearchQueryType) => void;
    inputOptions?: FilterSearchQueryType[];
  };
  tags?: {
    heading: string;
    appliedFilters: { label: string; id: string; onClick: () => void }[];
  }[];
  onClose?: () => void;
  open: boolean;
}

const CustomFilterControllerDrawer: React.FunctionComponent<
  FilterControllerProps
> = (props) => {
  const [appliedFiltersfilterMap, setAppliedFilter] = useState<{
    [key: string]:
      | boolean
      | string
      | Array<{ name: string; id: string }>
      | RangeType;
  }>({});
  const [removalFilters, setRemovalFilters] = useState<{
    [key: string]: (
      list?: Array<{ name: string; id: string }>,
      listItemToRemove?: string,
    ) => void;
  }>({});
  // const handleTextInputSubmit = (textInputValue: string) => {
  //   const foundFilter = props.searchFilters.inputOptions.find(
  //     ({ text }) => text === textInputValue
  //   );
  //   if (!!foundFilter) {
  //     props.searchFilters.onSubmit(foundFilter);
  //     return true;
  //   }
  //   return false;
  // };

  return (
    <Drawer title={`Filters`} open={props.open} onClose={props.onClose}>
      <div className="flex flex-col gap-1">
        <div className={`flex flex-col gap-1`}>
          <CustomFilterTopSection
            {...{
              dataIndexFilters: props.dataIndexFilters,
              removalFilters,
              setRemovalFilters,
              setAppliedFilter,
              appliedFiltersfilterMap,
            }}
          />

          {!!props.tags && !!props.tags.length && (
            <div className={`flex flex-col gap-1`}>
              {props.tags
                .filter(({ appliedFilters }) => !!appliedFilters.length)
                .map(({ heading, appliedFilters }, i) => (
                  <div key={heading + i}>
                    <Typography.Text>{heading}</Typography.Text>
                    <Flex wrap gap={"small"}>
                      {appliedFilters.map(({ label, id, onClick }) => (
                        <Tag onClose={onClick} key={id} closable={true}>
                          {label}
                        </Tag>
                      ))}
                    </Flex>
                  </div>
                ))}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-1">
          {props.customFilters && (
            <div className="relative flex-grow">
              <CustomFilterController
                customFilters={props.customFilters}
                {...{
                  appliedFiltersfilterMap,
                  setAppliedFilter,
                  setRemovalFilters,
                }}
              />
            </div>
          )}
          <div className="flex flex-col margin-gap-y-1.5  pt-1">
            {props.inputs.map((inputProps) => (
              <FilterControllerInput key={inputProps.id} {...inputProps} />
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default CustomFilterControllerDrawer;
