import { MaterialSymbol } from "react-material-symbols";

const getMenuItemIcon = (type: string, color?: "grey" | "black") => {
  switch (type) {
    case "table":
      return <MaterialSymbol icon="undo" />;
    case "project":
      return <MaterialSymbol icon={"info"} />;
    case "root_cause":
      return <MaterialSymbol icon={"analytics"} />;
    case "witness":
      return <MaterialSymbol icon="visibility" />;
    case "notes":
      return <MaterialSymbol icon={"note"} />;
    case "environmental":
      return <MaterialSymbol icon={"nature_people"} />;
    case "equipment":
      return <MaterialSymbol icon={"construction"} />;
    case "injury_illness":
      return <MaterialSymbol icon={"sick"} />;
    case "near_miss":
      return <MaterialSymbol icon={"mood_bad"} />;
    case "property_damage":
      return <MaterialSymbol icon="house" />;
    case "theft":
      return <MaterialSymbol icon={"sentiment_stressed"} />;
    case "utility":
      return <MaterialSymbol icon={"build"} />;
    case "vehicle":
      return <MaterialSymbol icon={"local_shipping"} />;
    case "incident_detail":
      return <MaterialSymbol icon="calendar_clock" />;
    case "logs":
      return <MaterialSymbol icon={`folder`} />;
    default:
      <></>;
  }
};

export default getMenuItemIcon;
