import React, { useMemo } from "react";
import {
  ChecklistItemAnswerType,
  SiteInspectionStepProps,
} from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import {
  GetInspectionDataOutput,
  InspectionChecklistItem,
} from "src/common/types/generated/apollo/graphQLTypes";
import { generalChecklistType } from "src/domain-features/siteinspection/utils/generalChecklistType";
import SiteInspectionWorkerChecklistItemListUI, {
  SiteInspectionWorkerStepChecklistItemListUIProps,
} from "./SiteInspectionWorkerStepChecklistitemListUI";
import getFullyCompletedChecklistitemAnswers from "src/domain-features/siteinspection/utils/getFullyCompletedChecklistitemAnswers";
import ButtonHuge from "src/common/components/general/button/ButtonHuge";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";
import {
  useCurrentDBLangSelector,
  useCurrentLangStrings,
  useCurrentLanguage,
} from "../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteInspectionWorkerStepChecklistItemListProps
  extends SiteInspectionStepProps {
  deficientTypeOptions: GetInspectionDataOutput["deficientAnswerSelectTypes"];
}

const SiteInspectionWorkerStepChecklistItemList: React.FC<
  SiteInspectionWorkerStepChecklistItemListProps
> = ({ onNext, onBack, deficientTypeOptions }) => {
  const workerContext = useSiteInspectionUser();

  const gs = useCurrentDBLangSelector();
  const language = useCurrentLanguage();
  const langStrings = useCurrentLangStrings();
  const checklistItemsToShow = useMemo(
    () =>
      workerContext.user?.inspection?.is_multi_inspection
        ? workerContext.user?.inspection?.checklistItems.filter(
            (item) => item.item_type === "checklist",
          )
        : workerContext.user?.inspection?.checklistItems || [],
    [
      workerContext.user?.inspection?.checklistItems,
      workerContext.user?.inspection?.is_multi_inspection,
    ],
  );
  const { checklistItemTypeMap, onlyChecklistTypeItemsMap } = useMemo(() => {
    const checklistItemTypeMap: {
      [type: string]: InspectionChecklistItem[];
    } = {};
    const onlyChecklistTypeItemsMap: typeof checklistItemTypeMap = {};
    checklistItemsToShow.forEach((item) => {
      const typeId = item.type?.name?.en ?? generalChecklistType;
      const list = [...(checklistItemTypeMap[typeId] ?? [])];
      list.push(item);
      checklistItemTypeMap[typeId] = list;

      if (item.item_type === "checklist") {
        const otherTypeItems = [...(onlyChecklistTypeItemsMap[typeId] ?? [])];
        otherTypeItems.push(item);
        onlyChecklistTypeItemsMap[typeId] = otherTypeItems;
      }
    });
    return { checklistItemTypeMap, onlyChecklistTypeItemsMap };
  }, [checklistItemsToShow]);
  const setChecklistItemAnswer: SiteInspectionWorkerStepChecklistItemListUIProps["setChecklistItemAnswer"] =
    (changes, { id, item_type }) =>
      workerContext.updateUser((u) =>
        u
          ? {
              ...u,
              checklistItemAnswers: u.checklistItemAnswers
                ? {
                    ...u.checklistItemAnswers,
                    [id]: u.checklistItemAnswers[id]
                      ? { ...u.checklistItemAnswers[id], ...changes }
                      : { id, item_type, ...changes },
                  }
                : {
                    [id]: { id, item_type, ...changes },
                  },
            }
          : {},
      );
  return (
    <SiteFeatureStepLayout onBackButtonClick={onBack}>
      {workerContext.user?.inspection?.is_multi_inspection ? (
        <div className="my-0.5 font-accent">
          {langStrings.strings.completeThisChecklistForSelecteditems}
        </div>
      ) : (
        ""
      )}
      <SiteInspectionWorkerChecklistItemListUI
        {...{
          checklistItemTypeMap: workerContext.user?.inspection
            ?.is_multi_inspection
            ? onlyChecklistTypeItemsMap
            : checklistItemTypeMap,
          deficientTypeOptions,
          setChecklistItemAnswer,
          showEntryInputs: true,
          hideIncorrect: !!workerContext.user?.inspection?.is_multi_inspection,
          checklistItemAnswers: workerContext.user?.checklistItemAnswers,
        }}
      />
      <ButtonHuge
        onClick={() => onNext()} // do not write as onClick={props.onNextButtonClick} as that provides optional argument eventHandler which sometimes we do not want so better to exclude
        disabled={
          getFullyCompletedChecklistitemAnswers(
            workerContext.user?.checklistItemAnswers ?? {},
          ).length !== checklistItemsToShow.length
        }
        type={"primary"}
      >
        {langStrings.strings.next}
      </ButtonHuge>
    </SiteFeatureStepLayout>
  );
};

export default SiteInspectionWorkerStepChecklistItemList;
