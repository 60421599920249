/**
 * @generated SignedSource<<32cfd4ad7a2393d28eabb611703f605b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { inspection_template_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpdateInspectionTemplateMutation$variables = {
  _set: inspection_template_set_input;
  id: string;
};
export type useUpdateInspectionTemplateMutation$data = {
  readonly update_inspection_template_by_pk: {
    readonly id: string;
    readonly pk: string;
  } | null | undefined;
};
export type useUpdateInspectionTemplateMutation = {
  response: useUpdateInspectionTemplateMutation$data;
  variables: useUpdateInspectionTemplateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateInspectionTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "inspection_template",
        "kind": "LinkedField",
        "name": "update_inspection_template_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useUpdateInspectionTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "inspection_template",
        "kind": "LinkedField",
        "name": "update_inspection_template_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a2feb696c1a3d01af600dbeb9507f7d9",
    "id": null,
    "metadata": {},
    "name": "useUpdateInspectionTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateInspectionTemplateMutation(\n  $id: uuid!\n  $_set: inspection_template_set_input!\n) {\n  update_inspection_template_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n    pk: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b17399165823a3a01bec360f702d08e3";

export default node;
