import { FC, useMemo } from "react";
import { Button, Card, message } from "antd";
import {
  Document_Insert_Input,
  Statement_Detail_Set_Input,
  useGenerateIncidentWitnessPdfMutation,
  useGetIncidentOptionsQuery,
  useInsertImageMutation,
  useUpdateStatementDetailMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentTextField from "../basic/TextField";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { useSearchParams } from "react-router-dom";
import IncidentDatepicker from "../basic/Datepicker";
import IncidentSelectField from "../basic/SelectField";
import IncidentUser from "../incident-users/IncidentUser";
import getNextPage from "../../utils/getNextPage";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../../utils/IncidentTypeProps";
import SignatureCard from "src/domain-features/incident-management/components/basic/SignatureCard";
import useIncidentUserMutations from "../../utils/useIncidentUserMutations";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../../utils/useDeleteIncidentDocument";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import dayjs from "dayjs";
import { MaterialSymbol } from "react-material-symbols";
import IncidentSubViewLayout from "../IncidentSubViewLayout";

const WitnessDetail: FC<IncidentTypeProps> = ({ incident, refetch }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const witnessUserId = searchParams.get("id");
  const authUser = useAuthUser();
  const { insertIncidentUser } = useIncidentUserMutations(
    "witness",
    incident,
    refetch,
  );
  const [insertImage] = useInsertImageMutation();
  const [updateStatementDetail, { loading: updateStatementLoading }] =
    useUpdateStatementDetailMutation();
  const [generateIncidentWitnessPdf, { loading: generating }] =
    useGenerateIncidentWitnessPdfMutation();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();

  const witnessData = useMemo(() => {
    const witnessData = incident.witnesses.find(
      (witness) => witness.id === witnessUserId,
    );
    return witnessData;
  }, [incident.witnesses, witnessUserId]);

  const uploadSign = async (
    imgUrl: string,
    type: "statement_provider_signature_id" | "statement_taken_by_signature_id",
  ) => {
    await insertImage({ variables: { object: { url: imgUrl } } }).then(
      (res) => {
        const imageId = res.data?.insert_image_one?.id;
        if (imageId) {
          updateStatement(
            type,
            imageId,
            `Signature added by ${
              type === "statement_provider_signature_id"
                ? `person providing witness statement`
                : `person taking witness statement`
            }`,
          );
        }
      },
    );
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incident,
      incidentUserId: witnessUserId ?? undefined,
      incidentFieldKey: "witness_detail",
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "witness_detail",
      incidentUserId: witnessUserId ?? undefined,
      incident: incident,
      objects: objects,
    });
  };

  const { data: incidentOptions } = useGetIncidentOptionsQuery({
    variables: { projectId: incident.project_id },
    fetchPolicy: "cache-first",
  });
  const projectEmployeeOptions = useMemo(() => {
    const employeeOptions = incidentOptions?.project_employee.map((pe) => ({
      label: pe.employee.user.name,
      value: pe.employee.user.id,
    }));
    return employeeOptions;
  }, [incidentOptions?.project_employee]);

  const statementTypes = [
    { label: "Injured Person", value: "injured" },
    { label: "Foreman/Supervisor", value: "foreman" },
    { label: "Employee", value: "employee" },
    { label: "Witness", value: "witness" },
  ];

  const updateStatement = async (
    key: keyof Statement_Detail_Set_Input,
    value: number | string | boolean | null,
    comment: string,
  ) => {
    if (witnessData?.statement_detail) {
      const updatedWitnesses = incident.witnesses.map((witness) => {
        if (witness.id === witnessUserId) {
          return { ...witness, [key]: value };
        } else {
          return witness;
        }
      });
      const updatedIncident = { ...incident, witnesses: updatedWitnesses };
      const patch = createIncidentPatch(updatedIncident, incident);
      await updateStatementDetail({
        variables: {
          id: witnessData.statement_detail.id,
          _set: { [key]: value },
          objects: {
            patch: patch,
            comment: comment,
            edited_by_uid: authUser.uid,
            edit_type: "witness-statement-edit",
            incident_id: incident.id,
          },
        },
        optimisticResponse: {
          update_statement_detail_by_pk: {
            ...witnessData.statement_detail,
            id: witnessData.statement_detail.id,
            [key]: value,
          },
          insert_incident_edit: {
            affected_rows: 1,
          },
        },
      });
    }
  };

  const witnessUserName =
    incident.witnesses.find((witness) => witness.id === witnessUserId)?.user
      .name ?? "";

  return (
    <IncidentSubViewLayout title="Witness Details">
      <div className="flex flex-col gap-1">
        <IncidentUser
          title="Witness"
          incident={incident}
          incidentUserId={witnessUserId ?? undefined}
          onUpdateUser={async (
            id: string,
            name: string,
            subcontractorId?: string,
          ) => {
            insertIncidentUser(id, name, subcontractorId);
          }}
          userType="witness"
        />

        {witnessData?.statement_detail && witnessData && witnessUserId && (
          <>
            <Card>
              <IncidentSelectField
                title="Select who is providing the Statement"
                onChange={async (option) => {
                  if (option === null) {
                    await updateStatement(
                      "type",
                      null,
                      `Removed "Select who is providing the Statement" from Insurance Detail for Injured User ${witnessUserName}`,
                    );
                  } else if (typeof option.value === "string")
                    await updateStatement(
                      "type",
                      option.value,
                      `Updated Insurance Detail for Injured User ${witnessUserName} - "Select who is providing the Statement" to ${option.label}`,
                    );
                }}
                options={statementTypes}
                value={witnessData.statement_detail.type ?? undefined}
                loading={updateStatementLoading}
              />
            </Card>
            <Card>
              <IncidentDatepicker
                label="Date of statement"
                onChange={async (date) => {
                  await updateStatement(
                    "statement_date",
                    date,
                    `Updated Insurance Detail for Injured User ${witnessUserName} - "Date of statement" to ${dayjs(
                      date,
                    ).format("DD MMM, YYYY")}`,
                  );
                }}
                value={witnessData.statement_detail.statement_date ?? undefined}
                disabled={updateStatementLoading}
              />
            </Card>
            <Card>
              <IncidentSelectField
                title="Employee Collecting the statement"
                onChange={async (option) => {
                  if (option === null) {
                    await updateStatement(
                      "employee_collecting_statement_user_id",
                      null,
                      `Removed "Employee Collecting the statement" from Insurance Detail for Injured User ${witnessUserName}`,
                    );
                  } else if (typeof option.value === "string")
                    await updateStatement(
                      "employee_collecting_statement_user_id",
                      option.value,
                      `Updated Insurance Detail for Injured User ${witnessUserName} - "Employee Collecting the statement" to ${option.label}`,
                    );
                }}
                value={
                  witnessData.statement_detail.employee_collecting_statement
                    ?.id ?? undefined
                }
                options={projectEmployeeOptions ?? []}
                loading={updateStatementLoading}
              />
            </Card>

            <Card>
              <IncidentTextField
                label={"In your own words, explain what happened"}
                text={witnessData?.statement_description.en}
                textId={witnessData?.statement_description.id}
                fieldTypeKey={"witness_detail"}
                field={"statement_description"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
              <div className="mt-1">
                Consider the following details as you provide your statement
                <div>
                  - What happened? Tell a story answering who, what, where,
                  when, how and why
                </div>
                <div> - Where were you when the incident took place?</div>
                <div>
                  - What activity was being performed prior to the event?
                </div>
                <div> - What do you believe happened?</div>
                <div> - Who else was there?</div>
              </div>
            </Card>

            <Card>
              <IncidentTextField
                label="Do you recall anything unusual or unexpected that happened?"
                text={witnessData.statement_detail.unusual_happenings.en}
                textId={witnessData.statement_detail.unusual_happenings.id}
                fieldTypeKey={"witness_detail"}
                field={"unusual_happenings"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
            </Card>

            <Card>
              <IncidentTextField
                label="How would you prevent this incident from happening in the future?"
                text={witnessData.statement_detail.future_prevention.en}
                textId={witnessData.statement_detail.future_prevention.id}
                fieldTypeKey={"witness_detail"}
                field={"future_prevention"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
            </Card>

            <Card>
              <IncidentSelectField
                title="If you were injured, have you ever injured this body part
                  before?"
                onChange={async (option) => {
                  if (option === null) {
                    await updateStatement(
                      "body_part_injured_before",
                      null,
                      `Removed "If you were injured, have you ever injured this body part
                  before?" from Insurance Detail for Injured User ${witnessUserName}`,
                    );
                  } else if (typeof option.value === "number")
                    await updateStatement(
                      "body_part_injured_before",
                      option.value === 1,
                      `Updated Insurance Detail for Injured User ${witnessUserName} - "If you were injured, have you ever injured this body part
                  before?" to ${option.label}`,
                    );
                }}
                value={
                  witnessData.statement_detail.body_part_injured_before ??
                  undefined
                }
                options={[
                  { value: 0, label: "No" },
                  { value: 1, label: "Yes" },
                ]}
                loading={updateStatementLoading}
              />

              {witnessData.statement_detail.body_part_injured_before && (
                <IncidentTextField
                  label="Explain"
                  text={witnessData.statement_detail.prior_injury_detail.en}
                  textId={witnessData.statement_detail.prior_injury_detail.id}
                  fieldTypeKey={"witness_detail"}
                  field={"prior_injury_detail"}
                  incidentUserId={witnessUserId}
                  incident={incident}
                />
              )}
            </Card>

            <SignatureCard
              label="Signature of person providing statement"
              signatureUrl={
                witnessData.statement_detail.statement_provider_signature?.url
              }
              insertSignImage={async (signImageId) =>
                await uploadSign(signImageId, "statement_provider_signature_id")
              }
            />

            <SignatureCard
              label="Signature of person taking statement"
              signatureUrl={
                witnessData.statement_detail.statement_taken_by_signature?.url
              }
              insertSignImage={async (signImageId) =>
                await uploadSign(signImageId, "statement_taken_by_signature_id")
              }
            />

            <Card>
              <IncidentDocViewAndUpload
                deleteDocument={deleteDocument}
                documents={witnessData.attached_files}
                groupId={witnessData.id}
                uploadDocument={uploadDocument}
                type="witness"
              />
            </Card>

            <div className="inline-flex">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  const index =
                    incident.witnesses.findIndex(
                      (witnessUser) => witnessUser.id === witnessUserId,
                    ) ?? 0;
                  if (index + 1 < incident.witnesses.length) {
                    const nextWitnessId = incident.witnesses[index + 1].id;
                    if (nextWitnessId) {
                      setSearchParams({
                        subview: "witness",
                        id: nextWitnessId,
                      });
                    }
                  } else {
                    const next = getNextPage(incident, "witness");
                    setSearchParams({ subview: next });
                  }
                }}
              >
                Save
              </Button>

              <div className="ml-2">
                <Button
                  type="primary"
                  loading={generating}
                  icon={<MaterialSymbol icon="download" />}
                  onClick={async () => {
                    const { data } = await generateIncidentWitnessPdf({
                      variables: {
                        input: {
                          incidentId: incident.id,
                          userId: witnessData.user.id,
                        },
                      },
                    });
                    if (data?.generateIncidentWitnessPdf) {
                      downloadFromUrl(data.generateIncidentWitnessPdf);
                    }
                    message.success("Download complete");
                  }}
                >
                  Download This Witness Statement
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </IncidentSubViewLayout>
  );
};

export default WitnessDetail;
