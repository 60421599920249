import React from "react";
import { Typography } from "antd";

interface GCProjectSiteAlertExplanationProps {
  isSmsEnabled?: boolean;
  phoneNumber?: string;
}

const GCProjectSiteAlertExplanation: React.FC<
  GCProjectSiteAlertExplanationProps
> = (props) => {
  const hasSmsNumber = !!props.phoneNumber;

  return (
    <Typography>
      <Typography.Paragraph>
        SiteAlert is a text message or push notification sent to any worker with
        a phone number or is logged into the SiteForm mobile app. Sending push
        notifications to app users is FREE. Because we use a 3rd party service
        to send/receive text messages, there is additional cost which is billed
        monthly and based on usage. <br />
        <br />
        Email{" "}
        <Typography.Link
          href="mailto:support@siteform.io"
          className="text-interactive-primary"
        >
          support@siteform.io
        </Typography.Link>
        for pricing, general questions, or to turn this feature ON/OFF.
      </Typography.Paragraph>
      {hasSmsNumber && (
        <Typography.Paragraph>
          <Typography.Text type={"secondary"}>
            SiteAlert with Text Message is:
          </Typography.Text>{" "}
          <Typography.Text>{props.isSmsEnabled ? "ON" : "OFF"}</Typography.Text>
          <br />
          <Typography.Text type={"secondary"}>
            Your project Phone Number:
          </Typography.Text>{" "}
          <Typography.Text>{props.phoneNumber}</Typography.Text>
        </Typography.Paragraph>
      )}
    </Typography>
  );
};

export default GCProjectSiteAlertExplanation;
