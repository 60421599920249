import React from "react";
import { NavLink } from "react-router-dom";
import NavigationMenuItem, {
  NavigationMenuItemProps,
} from "src/common/components/navigation-menu/NavigationMenuItem";
import Icon from "../general/Icon";
import { MaterialSymbol } from "react-material-symbols";

export type NavigationMenuItems = NavigationMenuItemProps[];
export type UtilityMenuItems = {
  component: React.ReactElement;
}[];

export interface NavigationMenuProps {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  navigationItems: NavigationMenuItems;
  utilityItems?: UtilityMenuItems;
  expanded?: boolean;
}

const NavigationMenu: React.FunctionComponent<NavigationMenuProps> = (
  props,
) => {
  return (
    <div
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      className={`relative flex flex-col items-center justify-center h-full overflow-hidden transition-all border-0 border-l-px  ${
        props.expanded
          ? "border-suplementary-3 w-16"
          : "border-transparent w-3 "
      }`}
    >
      <div
        className={`absolute inset-0 flex items-center justify-center w-1 h-1 m-auto transition-opacity  pointer-events-none ${
          props.expanded ? "opacity-0" : "opacity-100"
        }`}
      >
        <div className="flex flex-row gap-0.5 items-center transform -rotate-90  border-px border-suplementary-3 rounded-2 border-b-0 rounded-b-0 py-0.5 px-2">
          {props.navigationItems.map((navigationItem) => (
            <NavLink
              key={navigationItem.label + navigationItem.rootPath}
              to={navigationItem.rootPath}
              className={({ isActive }) =>
                `text-center flex flex-row uppercase text-static-secondary whitespace-nowrap items-center gap-0.5 ${
                  !isActive ? "hidden" : ""
                }`
              }
            >
              {navigationItem.icon && (
                <div className="transform rotate-90">
                  <Icon
                    icon={navigationItem.icon}
                    color={"static"}
                    size={"small"}
                  />
                </div>
              )}
              <span className={`w-full text-center`}>
                {navigationItem.label}
              </span>
            </NavLink>
          ))}
          <div className="transform rotate-90">
            <MaterialSymbol icon={"chevron_right"} />
          </div>
        </div>
      </div>
      <div className="w-16 max-h-full px-0.5 overflow-y-auto py-2 ">
        <div
          className={`flex flex-col gap-0.5 justify-center transition-all ${
            props.expanded
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          {props.utilityItems && (
            <div className="flex flex-col mb-1 gap-0.5">
              {props.utilityItems.map((utilityItem) => (
                <div
                  className="flex flex-col w-full"
                  key={utilityItem.component.key}
                >
                  {utilityItem.component}
                </div>
              ))}
            </div>
          )}
          {props.navigationItems.map((itemProps) => (
            <NavigationMenuItem
              key={itemProps.label + itemProps.rootPath}
              {...itemProps}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NavigationMenu;
