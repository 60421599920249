import SiteformBrandLogo from "../../../logo/SiteformBrandLogo";
import clsx from "clsx";
import React from "react";

// TODO implement animation using Framer Motion lib instead of css

interface AppLayoutSideMenuLogoAnimationProps {
  logoState: "full" | "circle";
}

const AppLayoutSideMenuLogoAnimation: React.FC<
  AppLayoutSideMenuLogoAnimationProps
> = (props) => {
  const commonLogoClassName = clsx(
    `absolute h-full w-max top-0 bottom-0 left-0 transition-all origin-left`,
    (() => {
      switch (props.logoState) {
        case "full":
          return "scale-[calc(2/3)]";
        case "circle":
          return "scale-1";
      }
    })(),
  );

  const fullLogoClassName = clsx(
    clsx(
      (() => {
        switch (props.logoState) {
          case "full":
            return `opacity-100`;
          case "circle":
            return `opacity-0`;
        }
      })(),
      commonLogoClassName,
    ),
  );

  return (
    <div className={`relative h-1.5 flex-1 overflow-hidden`}>
      <SiteformBrandLogo
        key={"full"}
        className={fullLogoClassName}
        type={"full"}
      />
      <SiteformBrandLogo
        className={clsx(commonLogoClassName)}
        key={"circle"}
        type={"circle"}
      />
    </div>
  );
};

export default AppLayoutSideMenuLogoAnimation;
