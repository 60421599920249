/**
 * @generated SignedSource<<dcd41d5fd31b37c357d4a2a18980301b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_certification_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyCertsMutation$variables = {
  certId: string;
  certificationId?: string | null | undefined;
  objects: ReadonlyArray<worker_certification_insert_input>;
  time: string;
  verifierId: string;
};
export type VerifyCertsMutation$data = {
  readonly insert_worker_certification: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
  readonly update_certificates_to_verify: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type VerifyCertsMutation = {
  response: VerifyCertsMutation$data;
  variables: VerifyCertsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certificationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "time"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "verifierId"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "certification_id",
          "variableName": "certificationId"
        },
        {
          "kind": "Variable",
          "name": "verified_at",
          "variableName": "time"
        },
        {
          "kind": "Variable",
          "name": "verified_by_uid",
          "variableName": "verifierId"
        }
      ],
      "kind": "ObjectValue",
      "name": "_set"
    },
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "certId"
            }
          ],
          "kind": "ObjectValue",
          "name": "id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "certificates_to_verify_mutation_response",
  "kind": "LinkedField",
  "name": "update_certificates_to_verify",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyCertsMutation",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "worker_certification_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_certification",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "VerifyCertsMutation",
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "worker_certification_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_certification",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5eba4d9a5002dc4cfaa9cb9cbc13495d",
    "id": null,
    "metadata": {},
    "name": "VerifyCertsMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyCertsMutation(\n  $certId: uuid!\n  $time: timestamptz!\n  $verifierId: uuid!\n  $certificationId: uuid\n  $objects: [worker_certification_insert_input!]!\n) {\n  update_certificates_to_verify(where: {id: {_eq: $certId}}, _set: {verified_at: $time, verified_by_uid: $verifierId, certification_id: $certificationId}) {\n    affected_rows\n  }\n  insert_worker_certification(objects: $objects) {\n    affected_rows\n    returning {\n      id\n      pk: id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4fe9b115ecd65c6ebef9b126921a43b1";

export default node;
