import { Button, Form, message, notification, Select } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import FormStepBlock from "src/common/components/FormStepBlock";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCAddNewProjectQuery$data } from "src/common/types/generated/relay/GCAddNewProjectQuery.graphql";
import { GCNewProjectStepCoWorkersMutation } from "src/common/types/generated/relay/GCNewProjectStepCoWorkersMutation.graphql";
import { GCSendInviteModalRef } from "src/utility-features/invitations/GCSendInviteModal";
import { GCNewProjectStepType } from "../gcNewProjectStepType";
import CreateGCEmployeeModal, {
  CreateGCEmployeeFormValues,
} from "src/common/components/dialogs/CreateGCEmployeeModal";
import apolloClient from "src/common/api/apollo/apolloClient";
import {
  GetNameUsernameDocument,
  GetNameUsernameQuery,
  GetNameUsernameQueryVariables,
  useCreateGeneralContractorEmployeeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { MaterialSymbol } from "react-material-symbols";

interface Props {
  //  title: string;
  //  onSubmit: (values: SelectGCUSerModalValues) => void;
  gcemployee: GCAddNewProjectQuery$data["general_contractor_employee_connection"];
  userId: string;
}

const GCUserSelectFormItem = withCustomSuspense<Props>(
  ({ gcemployee, userId }) => {
    const options = useMemo(
      () =>
        gcemployee.edges.map((e) => ({
          label: e.node.user.name,
          key: e.node.uid,
          value: e.node.uid,
          disabled: e.node.uid === userId,
        })),
      [gcemployee],
    );
    return (
      <Form.Item
        name="GCUsersIds"
        label="Co-workers"
        rules={[{ required: true, message: "Select GCUsers" }]}
      >
        <Select
          optionFilterProp="label"
          mode="multiple"
          style={{ width: "80%" }}
          options={options}
        />
      </Form.Item>
    );
  },
);

interface GCNewProjectStepCoWorkersProps extends GCNewProjectStepType {
  projectId: string;
  userId: string;
  gcId: string;
  gcemployee: GCAddNewProjectQuery$data["general_contractor_employee_connection"];
}

const GCNewProjectStepCoWorkers: React.FC<GCNewProjectStepCoWorkersProps> = (
  props,
) => {
  const [form] = Form.useForm();
  const [createEmployeeOpen, setCreateEmployeeOpen] = useState(false);
  const [createEmployee] = useCreateGeneralContractorEmployeeMutation();

  const sendInvite = useRef<GCSendInviteModalRef>(null);
  const [insertProjectEmployees, insertingEmployees] =
    useAsyncMutation<GCNewProjectStepCoWorkersMutation>(
      graphql`
        mutation GCNewProjectStepCoWorkersMutation(
          $objects: [project_employee_insert_input!]!
        ) {
          insert_project_employee(
            objects: $objects
            on_conflict: {
              constraint: project_employee_project_id_employee_id_key
              update_columns: [direct_project_assign]
            }
          ) {
            affected_rows
          }
        }
      `,
    );

  const onNext = async () =>
    form
      .validateFields()
      .then((values) => {
        const users = values.GCUsersIds as string[];
        return insertProjectEmployees({
          variables: {
            objects: users
              .filter((uid) => uid !== props.userId)
              .map((uid) => ({
                project_id: props.projectId,
                employee_id: uid,
                direct_project_assign: true,
              })),
          },
        });
      })
      .then(() => {
        message.success("Added your coworkers to the new project");
        props.goToNext();
      })
      .catch((error: any) => {
        notification.error({
          message: "Error: ",
          description:
            error instanceof Error ? error.message : JSON.stringify(error),
          duration: null,
        });
      });

  return (
    <FormStepBlock
      dataIsValid={true}
      description="Add your Team"
      onNext={onNext}
      //onSkip={props.goToNext}
      title="Co-workers' information"
      willBeChangedLater={
        <div>
          Add you team members that work for your company; PMs, PEs, Supers,
          etc.{" "}
          <span className="font-accent">
            Do not invite Foremen or Workers, or Subcontractor Employees
          </span>
        </div>
      }
      filled={true}
      open={props.open}
    >
      <CreateGCEmployeeModal
        visible={createEmployeeOpen}
        onCancel={() => {
          setCreateEmployeeOpen(false);
        }}
        title={"Invite Office or Field Staff to SiteForm"}
        warning={
          "DO NOT invite workers, crew leads, foremen or field labor here"
        }
        onCreate={async (values: CreateGCEmployeeFormValues) => {
          const { data } = await apolloClient.query<
            GetNameUsernameQuery,
            GetNameUsernameQueryVariables
          >({
            query: GetNameUsernameDocument,
            variables: {
              name: values.name,
            },
          });
          if (data) {
            await createEmployee({
              variables: {
                name: values.name,
                email: values.email,
                generalContractorId: props.gcId,
                titleId: values.titleId,
                username: data!.getNewUsername!,
                projectIds: [props.projectId],
                directProjectAssign: true,
                // phoneNumber: un
              },
            })
              .then(() => {
                message.success("Invite sent");
              })
              .catch((error) => {
                message.error("Error : " + error);
              });
          } else {
            console.log("NO DATA");
          }

          setCreateEmployeeOpen(false);
          // handleAddUser();
        }}
      />
      <div>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ GCUsersIds: [props.userId] }}
        >
          <GCUserSelectFormItem
            gcemployee={props.gcemployee}
            userId={props.userId}
          />
        </Form>
        {/* <GCSendInviteModal
          ref={sendInvite}
          inviteRole="gc-employee"
          projectId={props.projectId}
        /> */}
        <Button
          onClick={() => setCreateEmployeeOpen(true)}
          type="primary"
          icon={<MaterialSymbol icon="add" />}
          loading={insertingEmployees}
        >
          Invite New Co-workers
        </Button>
      </div>
    </FormStepBlock>
  );
};

export default GCNewProjectStepCoWorkers;
