import React from "react";
import SiteOrientationInPersonManagerLayout from "../../components/SiteOrientationInPersonManagerLayout";
import { Button } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SiteOrientationInPersonManagerViewWorkersResult from "./components/SiteOrientationInPersonManagerViewWorkersResult";
import useGetSiteOrientationInPersonWorkerList from "src/domain-features/siteorientation/utils/useGetSiteOrientationInPersonWorkerList";
import { MaterialSymbol } from "react-material-symbols";

export interface SiteOrientationInPersonManagerFinishedViewProps {}

const SiteOrientationInPersonManagerFinishedView: React.FC<
  SiteOrientationInPersonManagerFinishedViewProps
> = (props) => {
  const navigate = useNavigate();
  const projectId = useParams().projectId as string;
  const [searchParams] = useSearchParams();
  const completionType = searchParams.get("completionType");
  const onStartOverClick = () => {
    navigate(
      `/gce/orientation/${projectId}/manager/starting${
        completionType ? "?completionType=inpersonComplete" : ""
      }`,
    );
  };
  const data = useGetSiteOrientationInPersonWorkerList({
    projectId,
    projectEmployeeWhere: {
      project: { orientation_project_id: { _eq: projectId } },
      user_orientation: { selected: { _eq: true } },
    },
    projectWorkerWhere: {
      project: { orientation_project_id: { _eq: projectId } },
      subcontractor_worker: {},
      user_orientation: { selected: { _eq: true } },
    },
  });

  const projectData = data.project_connection.edges[0].node;
  return (
    <SiteOrientationInPersonManagerLayout
      footer={
        <Button onClick={onStartOverClick} block>
          Start Over
        </Button>
      }
      projectInfo={{
        name: projectData.name,
        gcNames: projectData.general_contractors
          .map((p) => p.general_contractor.name)
          .join(", "),
        address: [
          projectData.address.line_1,
          projectData.address.line_2,
          projectData.address.city,
          projectData.address.state_code,
        ]
          .filter((str) => !!str?.length)
          .join(", "),
      }}
      hints={[
        {
          title:
            "Workers highlighted in red need to complete the entire orientation",
          icon: <MaterialSymbol icon={"group"} />,
          body: "These workers have not seen your orientation or it has expired",
        },
      ]}
    >
      <SiteOrientationInPersonManagerViewWorkersResult
        data={data}
        projectId={projectId}
      />
    </SiteOrientationInPersonManagerLayout>
  );
};

export default SiteOrientationInPersonManagerFinishedView;
