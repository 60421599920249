import ProjectTable from "src/root/routes/views/general-contractor/hierarchy/components/ProjectTable";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import { useRef, useState } from "react";
import GCAddProject from "../../components/GCAddProject";
import { message } from "antd";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import withAppPageLayout from "../../../../../../../common/components/layouts/main/content/withAppPageLayout";
import { MaterialSymbol } from "react-material-symbols";

const GCCorporateOfficeProjects: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<DataScrollTableRef>(null);
  return (
    <>
      {open && (
        <GCAddProject
          onCancel={() => setOpen(false)}
          onCreate={() => {
            message.success("Project Created Successfully");
            setOpen(false);
          }}
        />
      )}
      <ProjectTable
        showLevels={["business-unit", "division", "office"]}
        ref={ref}
        topBarButtons={[
          {
            label: "Add New Project",
            icon: <MaterialSymbol icon="add" />,
            onClick: () => setOpen(true),
          },
        ]}
        excludedKeys={["gc"]}
        where={{
          general_contractors: {
            general_contractor_id: {
              _eq: employee.general_contractor.id,
            },
          },
        }}
      />
    </>
  );
};
export default withAppPageLayout(
  withCorporateAdminWrapper(GCCorporateOfficeProjects),
  { pageTitle: "Projects" },
);
