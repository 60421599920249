import React from "react";

const SiteformLogoFull: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...{
        xmlns: "http://www.w3.org/2000/svg",
        width: "100%",
        height: "100%",
        viewBox: "0 0 8532 1334",
        fill: "none",
        preserveAspectRatio: "xMidYMid meet",
        ...props,
      }}
    >
      <g clip-path="url(#clip0_6_37)">
        <path
          d="M637.333 1.33335C490.933 7.73335 355.467 60.5333 240 155.733C220.267 172.133 173.467 218.933 157.067 238.667C82 329.6 33.7333 432.133 12.8 544.8C-2.00005 624.267 -2.00005 709.067 12.8 788.533C33.7333 901.2 82 1003.73 157.067 1094.67C173.467 1114.4 220.267 1161.2 240 1177.6C324.667 1247.47 420.267 1294.53 523.467 1317.2C700.933 1356 886.4 1321.07 1037.2 1220.13C1097.47 1179.87 1154.27 1126.13 1199.07 1067.07C1300.4 933.067 1347.6 760.267 1328.67 592C1316.67 486.4 1279.33 384.667 1220.13 296.133C1179.87 235.867 1126.13 179.067 1067.07 134.267C973.867 63.7333 859.067 18 741.333 4.66668C724.533 2.80001 672.4 -0.133321 663.333 0.266679C661.2 0.400012 649.467 0.800012 637.333 1.33335ZM989.2 312.667L1068 356.667L1067.6 666.533L1067.33 976.4L988 1020.8C944.4 1045.2 908.267 1065.33 907.733 1065.33C907.067 1065.33 906.667 885.867 906.667 666.533C906.667 323.867 906.933 267.867 908.533 268.267C909.6 268.533 945.867 288.4 989.2 312.667ZM748 822V1066.67H667.333H586.667V866.267V665.867L665.733 621.6C709.067 597.333 745.467 577.333 746.4 577.333C747.6 577.333 748 627.2 748 822ZM428 975.467C428 1047.07 427.6 1065.47 426.4 1064.93C420.533 1062.53 267.733 976.133 267.733 975.2C267.733 974.267 424.933 885.467 427.067 885.333C427.6 885.333 428 925.867 428 975.467Z"
          fill="#0086FF"
        />
        <path
          d="M2126.67 1.33346C2027.87 6.26679 1941.6 32.1335 1873.33 77.0668C1850 92.4001 1834.93 104.533 1815.6 123.733C1759.33 179.333 1726 246.533 1713.2 330C1709.2 356.4 1708.13 413.6 1711.2 442.533C1726.13 583.2 1801.6 674.933 1951.2 734.667C1997.47 753.067 2026.8 761.2 2139.33 786.8C2171.6 794.133 2206.4 802.267 2216.67 804.8C2304.67 826.933 2350.4 848.933 2375.33 881.333C2402.27 916.267 2405.87 973.2 2384 1018C2369.33 1048.27 2344.8 1071.2 2309.2 1087.87C2272 1105.2 2233.73 1113.07 2179.2 1114.27C2120.27 1115.6 2074.53 1108.53 2030.67 1091.2C1970.67 1067.33 1924 1023.6 1902.27 970.667C1899.6 964.4 1896.8 956.4 1896 952.933C1895.07 949.467 1893.73 946.667 1892.8 946.667C1892 946.667 1838.53 963.333 1774 983.733C1658.8 1020.13 1656.67 1020.93 1657.07 1024.13C1658.67 1035.2 1676.93 1079.07 1689.07 1100.67C1707.33 1133.73 1726.53 1158.93 1754.53 1186.67C1790.4 1222.4 1824.13 1246.27 1872.67 1270C1942 1304 2018.4 1323.47 2110.67 1330.8C2135.73 1332.8 2203.47 1332.8 2227.33 1330.8C2362 1319.6 2463.6 1278 2537.33 1204.13C2557.6 1183.87 2567.47 1171.87 2583.33 1148C2614.4 1101.6 2633.73 1048.67 2642.27 986.667C2645.07 966 2645.47 908.267 2642.93 885.333C2632.13 789.067 2592.93 724.533 2514 672.933C2478.8 650 2431.47 628.533 2373.33 609.2C2324.4 592.933 2277.33 580.4 2200.53 563.333C2147.47 551.467 2130.8 547.333 2107.33 540.267C2003.87 508.933 1956.8 462.133 1952.4 386C1950.93 359.2 1956.27 332.267 1968 308.667C1995.6 252.4 2061.87 218.8 2145.07 218.667C2169.47 218.667 2186.13 220.267 2206.53 224.8C2277.33 240.533 2330 285.467 2350.13 347.333C2353.6 357.867 2355.07 360.667 2356.93 360.533C2359.47 360.4 2592.27 303.867 2593.2 303.067C2595.47 301.333 2579.2 257.067 2567.33 232.267C2538.4 172.4 2501.73 127.467 2450 88.6668C2366.8 26.1335 2251.33 -5.06654 2126.67 1.33346Z"
          fill="#0086FF"
        />
        <path
          d="M2847.87 1.33348C2829.07 3.60015 2808.93 11.0668 2793.6 21.6002C2772.27 36.1335 2754.13 60.9335 2746.27 86.0002C2724.4 155.867 2763.73 229.333 2832.67 247.467C2846.93 251.2 2876.93 250.933 2891.73 246.933C2936.13 234.8 2968.93 200.8 2980.27 155.067C2984.67 137.6 2984.27 110.8 2979.6 92.9335C2969.47 54.6668 2941.73 22.9335 2906.27 9.33348C2888.13 2.26682 2865.73 -0.79985 2847.87 1.33348Z"
          fill="#0086FF"
        />
        <path
          d="M4685.33 666.667V1333.33H4808.67H4932V1071.33V809.333H5156H5380V690V570.667L5156.4 570.4L4932.67 570L4932.27 404.933L4932 240H5182H5432V120V9.32217e-05H5058.67H4685.33V666.667Z"
          fill="#0086FF"
        />
        <path
          d="M3210.67 292V389.333H3144.67H3078.67V495.333V601.333H3144.67H3210.67V794.267C3210.67 903.2 3211.33 997.2 3212 1010C3221.6 1178.13 3303.07 1287.6 3443.33 1321.07C3474.4 1328.53 3494.27 1330.8 3534.13 1331.6C3580 1332.53 3606.4 1330 3634.4 1322L3641.33 1319.87V1213.73V1107.6L3635.07 1108.53C3617.6 1110.8 3584.27 1111.87 3566.8 1110.67C3494.67 1105.73 3460.67 1079.6 3448.8 1020C3443.47 993.2 3443.33 987.067 3443.33 791.333V602L3542.4 601.6L3641.33 601.333V495.333V389.333H3542H3442.67V292V194.667H3326.67H3210.67V292Z"
          fill="#0086FF"
        />
        <path
          d="M2746.67 861.333V1333.33H2861.33H2976V861.333V389.333H2861.33H2746.67V861.333Z"
          fill="#0086FF"
        />
        <path
          d="M4108 392.134C4026.67 396.534 3948.13 422.8 3880 468.267C3835.47 498 3792.27 541.2 3761.47 586.667C3689.87 692.134 3664 831.067 3690.67 965.334C3715.33 1088.93 3784.4 1190.27 3888.93 1256.4C3908.13 1268.53 3949.73 1288.93 3972.4 1297.33C4062.53 1330.8 4170 1340.13 4264.4 1322.67C4360.53 1305.07 4450 1257.6 4514 1190.53C4525.6 1178.4 4539.07 1162.53 4544.93 1153.87L4547.87 1149.87L4458.67 1097.07L4369.6 1044.4L4362.67 1052.27C4332.27 1086.8 4278.67 1115.73 4223.2 1127.6C4206.93 1131.07 4204 1131.2 4170.67 1131.33C4133.6 1131.33 4120.4 1130 4095.33 1123.87C4023.07 1106 3968.4 1063.47 3936.53 1000.27C3929.73 986.667 3920 961.6 3918 952.267L3917.07 948H4247.73H4578.27L4579.87 931.6C4582.4 907.067 4583.2 842.8 4581.47 818.667C4573.07 708.134 4538.67 617.067 4477.33 542.667C4463.73 526.134 4433.47 496.534 4417.33 484.134C4355.47 436 4281.33 405.734 4200.13 395.334C4184.93 393.334 4140.67 390.534 4131.33 390.934C4129.2 390.934 4118.67 391.6 4108 392.134ZM4177.47 592.8C4229.2 600 4269.73 619.867 4302.27 653.734C4330.53 683.334 4348.13 718.934 4360.53 770.934L4361.33 774.667H4139.2H3917.07L3918 769.6C3918.53 766.934 3921.2 757.467 3924.13 748.8C3952.4 662.667 4018.53 605.067 4103.2 592.667C4120.53 590.134 4159.33 590.267 4177.47 592.8Z"
          fill="#0086FF"
        />
        <path
          d="M5936.67 392.134C5858.8 396 5780.27 418.4 5715.33 455.334C5677.6 476.667 5645.73 500.934 5615.2 531.334C5537.73 608.534 5492.67 704 5481.2 815.334C5479.07 835.867 5479.07 886.8 5481.2 907.334C5492.4 1015.47 5538 1113.2 5612.27 1188.13C5691.87 1268.27 5793.33 1315.87 5912 1328.8C5938.4 1331.73 5991.47 1331.73 6018.67 1328.8C6120.8 1318 6209.73 1281.87 6285.2 1220.4C6303.6 1205.33 6336.27 1172.53 6351.2 1154C6409.07 1081.87 6444.27 993.867 6452.13 901.6C6454.13 877.467 6452.93 821.2 6450 800C6440.93 734.134 6420.53 675.2 6388.4 621.334C6314.13 497.067 6183.73 414.134 6033.33 395.467C6017.2 393.334 5970.93 390.534 5961.33 390.934C5958.8 390.934 5947.73 391.467 5936.67 392.134ZM6014 610C6111.2 627.334 6183.2 693.067 6208 787.2C6215.6 816.267 6216.67 824.4 6216.67 861.334C6216.67 898.267 6215.6 906.4 6208 935.467C6190.27 1002.67 6147.33 1057.07 6087.87 1088C6050.13 1107.47 6012.93 1116.13 5966 1116.13C5930.27 1116.13 5900.93 1111.2 5872.4 1100.53C5798 1072.8 5744.8 1012.4 5724.27 932.667C5717.6 906.934 5715.6 890.534 5715.6 861.334C5715.6 832.134 5717.6 815.734 5724.27 790C5751.73 682.934 5838.4 612.134 5948.67 606.8C5970.13 605.867 5998.13 607.2 6014 610Z"
          fill="#0086FF"
        />
        <path
          d="M7073.33 392C7000.93 396.667 6924.67 426.8 6865.33 474.134C6840.8 493.734 6812 525.467 6794.67 551.867L6787.33 563.067L6786.93 488.134L6786.67 413.334H6671.33H6556V872.667V1332H6671.33H6786.67V1122.53C6786.67 914 6787.07 893.2 6791.47 858C6805.33 745.734 6850 679.867 6931.73 651.467C6972.4 637.334 7030.67 632.8 7087.73 639.6L7094.67 640.4V515.467V390.667L7091.73 390.8C7090 390.934 7081.73 391.467 7073.33 392Z"
          fill="#0086FF"
        />
        <path
          d="M7648 392C7558.8 397.867 7487.2 434.667 7437.2 500.134L7428.67 511.334L7428.27 462.267L7428 413.334H7312.67H7197.33V872.667V1332H7312.67H7427.87L7428.4 1053.6L7428.8 775.334L7431.73 760C7435.6 739.6 7440.27 723.734 7446.67 709.067C7472.93 648 7525.87 613.334 7592.93 613.334C7637.2 613.334 7672.8 626.267 7698.8 652C7721.47 674.267 7735.87 704.667 7744.27 747.334L7747.2 762L7747.6 1046.93L7748 1332H7863.33H7978.67V1066.93C7978.67 897.867 7979.2 795.467 7980 784C7985.73 709.2 8019.2 653.6 8073.6 628C8122 605.334 8190.67 609.334 8231.6 637.067C8261.73 657.467 8281.73 689.334 8291.87 733.2C8299.47 765.734 8299.2 750.134 8299.6 1052.93L8300.13 1332H8415.47H8530.8L8530.27 1027.6C8529.73 698.134 8530 713.6 8522.67 672C8506.93 584 8466.53 508.934 8409.33 461.067C8376 433.2 8336.27 412.667 8294.8 402C8230.4 385.467 8154 388.4 8092.27 410C8030.67 431.467 7979.07 469.467 7938.27 523.6C7933.2 530.4 7928.8 536 7928.67 536C7928.53 536 7925.07 530.4 7920.93 523.6C7901.07 490.4 7876.13 463.334 7844.27 440.4C7820.27 423.067 7780.27 405.867 7747.87 398.8C7720 392.8 7678.93 390 7648 392Z"
          fill="#0086FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_6_37">
          <rect width="8532" height="1333.33" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SiteformLogoFull;
