import { Button, message, notification, Space } from "antd";
import { FC, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ConnectionHandler,
  ReadOnlyRecordProxy,
  RecordSourceSelectorProxy,
} from "relay-runtime";
import DataDetailDescriptions, {
  DataDetailItems,
} from "src/common/components/layouts/DataDetailDescriptions";
import StyledContent from "src/common/components/layouts/StyledContent";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import getAddressText from "src/common/functions/getAddressText";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import { ProjectSubcontractorEmployeeFrag$data } from "src/common/types/generated/relay/ProjectSubcontractorEmployeeFrag.graphql";
import AddSubcontractorProjectTeamModal, {
  insertProjectSubcontractorEmployeeMutation,
} from "src/domain-features/sitesafety/siteSafetyPlan/components/AddSubcontractorProjectTeamModal";
import ProjectSubcontractorEmployeeTable from "src/domain-features/sitesafety/siteSafetyPlan/components/ProjectSubcontractorEmployeeTable";
import sendInvite from "src/utility-features/invitations/sendInvite";
import { PlusOutlined } from "@ant-design/icons";
import {
  AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation,
  AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$data,
} from "src/common/types/generated/relay/AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation.graphql";
import * as uuid from "uuid";
import GetFullID from "src/common/functions/GetFullId";
import ProjectSubWorkerTitleViewAndEdit from "./components/ProjectSubWorkerTitleViewAndEdit";
import ProjectSubChildSubs from "./components/ProjectSubChildSubs";
import AddSubcontractorEmployeeModal from "src/common/components/dialogs/AddSubcontractorEmployeeModal";
import { useCreateSubcontractorEmployeeMutation } from "src/common/types/generated/apollo/graphQLTypes";

import ScrollTable from "src/common/components/tables/basic/ScrollTable";
import useGetSubadminObsRiskLevelColumns, {
  NotifyForObsDeleteUpdaterType,
  NotifyForObsInsertUpdaterType,
} from "../../../../projects/subcontractors/sub-info/utils/useGetSubadminObsRiskLevelColumns";
import { MaterialSymbol } from "react-material-symbols";

export const handleInsertProjSubEmpForGcSubcontractorPage = (
  store: RecordSourceSelectorProxy<AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$data>,
  subcontractorId: string,
  projectId: string,
) => {
  const insertProjectSubcontractorEmployees = store.getRootField(
    "insert_project_subcontractor_employee",
  );
  const projSUbEmpRecs =
    insertProjectSubcontractorEmployees.getLinkedRecords("returning") || [];
  const conn1 = ConnectionHandler.getConnection(
    store.getRoot(),
    "GCSubcontractorQuery_subcontractor_connection",
  );
  if (conn1) {
    console.log(conn1.getLinkedRecords("edges"), "edges");
    const edge = (conn1.getLinkedRecords("edges") || []).find((edge) => {
      console.log(edge.getLinkedRecord("node"), "node");
      return (
        edge.getLinkedRecord("node")?.getDataID() ===
        GetFullID("subcontractor", subcontractorId)
      );
    });
    console.log(edge, "EDGE");
    if (edge) {
      const node = edge.getLinkedRecord("node");
      if (!node) return;
      const subEmps =
        node.getLinkedRecords("subcontractor_employees", {
          order_by: { user: { name: "asc" } },
        }) || [];
      console.log(subEmps, "SUBEMPS");
      subEmps.forEach((subEmp) => {
        console.log(subEmp, "SUBEMP111");
        const projSubEmprecord = projSUbEmpRecs.find((r) => {
          console.log(
            r.getValue("subcontractor_employee_id"),
            subEmp.getValue("user_id"),
          );
          return (
            r.getValue("subcontractor_employee_id") ===
            subEmp.getValue("user_id")
          );
        });
        console.log(projSubEmprecord);
        if (projSubEmprecord)
          subEmp.setLinkedRecords(
            [projSubEmprecord],
            "project_subcontractor_employees",
            { where: { project_id: { _eq: projectId } } },
          );
      });
    }
  }
  const conn2 = ConnectionHandler.getConnection(
    store.getRoot(),
    "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection",
  );
  if (conn2) {
    projSUbEmpRecs.forEach((projsubEmp) => {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(projsubEmp, "node");
      ConnectionHandler.insertEdgeBefore(conn2, edge);
    });
  }
};
const getSubEmpRecs = (rootRec: ReadOnlyRecordProxy) => {
  const conn = ConnectionHandler.getConnection(
    rootRec,
    "GCSubcontractorQuery_subcontractor_connection",
  );
  if (!conn) return;
  const edges = conn.getLinkedRecords("edges") || [];
  console.log(edges);
  const node = edges[0]?.getLinkedRecord("node");
  // using id to query sub
  console.log(node);
  if (!node) return;
  return node.getLinkedRecords("subcontractor_employees", {
    order_by: { user: { name: "asc" } },
  });
};

const GCSubcontractorDetail: FC<{
  data: GCSubcontractorQuery$data;
  subcontractorId: string;
  projectId: string;
  gcId: string;
  refetchData: () => void;
}> = ({ data, subcontractorId, projectId, refetchData, gcId }) => {
  const navigate = useNavigate();
  const subcontractorData = data.subcontractor_connection.edges[0].node;

  const subcontractorItems: DataDetailItems = [
    {
      label: "Name",
      value: subcontractorData.name,
      span: 2,
    },
    {
      label: "Company Trade",
      value: subcontractorData.trade?.name ?? "",
    },
    {
      label: "Address",
      value: getAddressText(subcontractorData.address),
    },
  ];

  const projectSubcontractorEmployeeTableRef = useRef<DataScrollTableRef>(null);
  const [
    openAddSubcontractorProjectTeamModal,
    setOpenAddSubcontractorProjectTeamModal,
  ] = useState(false);
  const [addEmployeeOpen, setAddEmployeeOpen] = useState(false);
  const [createSubEmployee, { loading: creatingSubEmployee }] =
    useCreateSubcontractorEmployeeMutation();
  const user = data.user_connection.edges[0].node;
  const obsEnabled =
    data.project_setting_connection.edges[0]?.node.observation_enabled;
  if (!user) throw new Error("User not found");
  const employee = user.employee;
  if (!employee) throw new Error("User is not employee");

  const riskLevelColsToShow = useMemo(
    () =>
      obsEnabled
        ? employee.general_contractor.observation_notifications.map(
            ({ risk_level }) => risk_level,
          )
        : [],
    [employee.general_contractor.observation_notifications, obsEnabled],
  );
  const allSubadmins = useMemo(() => {
    return data.subcontractor_connection.edges[0].node.subcontractor_employees.map(
      (r) => ({
        ...r,
        notify_for_obs: r.user.notify_for_obs,
      }),
    );
  }, [data.subcontractor_connection.edges[0].node.subcontractor_employees]);
  const notifyForObsVars = {
    where: { gc_id: { _eq: gcId } },
  };
  const notifyForObsInsertUpdater: NotifyForObsInsertUpdaterType = (
    store,
    rowDataId,
  ) => {
    const inserted = store
      .getRootField("insert_obs_notify_user")
      .getLinkedRecords("returning")[0];
    if (!inserted) throw new Error("Server returned null response");
    const subadminRecs = getSubEmpRecs(store.getRoot()) || [];
    subadminRecs.forEach((subadminRec) => {
      if (subadminRec.getDataID() !== rowDataId) return;
      const user = subadminRec.getLinkedRecord("user");
      if (!user) return;
      const currList = user.getLinkedRecords(
        "notify_for_obs",
        notifyForObsVars,
      );
      if (!currList) return;
      user.setLinkedRecords(
        [inserted, ...(currList || [])],
        "notify_for_obs",
        notifyForObsVars,
      );
    });
  };

  const notifyForObsDeleteUpdater: NotifyForObsDeleteUpdaterType = (
    store,
    rowDataId,
  ) => {
    const deletedRec = store
      .getRootField("delete_obs_notify_user")
      .getLinkedRecords("returning")[0];
    if (!deletedRec) throw new Error("Server returned null response");
    const deletedRecDataId = deletedRec.getDataID();
    const subadminRecs = getSubEmpRecs(store.getRoot()) || [];
    subadminRecs.forEach((subadminRec) => {
      if (subadminRec.getDataID() !== rowDataId) return;
      const user = subadminRec.getLinkedRecord("user");
      if (!user) return;
      const currList = user.getLinkedRecords(
        "notify_for_obs",
        notifyForObsVars,
      );
      if (!currList) return;
      user.setLinkedRecords(
        (currList || []).filter((rec) => rec.getDataID() !== deletedRecDataId),
        "notify_for_obs",
        notifyForObsVars,
      );
    });
  };
  const getRiskLevelColumns = useGetSubadminObsRiskLevelColumns<
    (typeof allSubadmins)[number]
  >({
    riskLevelColsToShow,
    notifyForObsDeleteUpdater,
    notifyForObsInsertUpdater,
    getObsNotifyUserInsertObj: (row, risk_level_value) => ({
      id: uuid.v4(),
      risk_level_value,
      gc_id: employee.general_contractor.pk,
      user_id: row.user_id,
    }),
  });

  const resendInvite = async (
    u:
      | SubEmployeeType
      | ProjectSubcontractorEmployeeFrag$data["subcontractor_employee"],
  ) => {
    console.log(u);
    const sentFromName = user.name;
    const sentFromCompanyName = employee.general_contractor.name;
    const employee_project = employee.employee_projects.find(
      (p) => p.project.pk === projectId,
    );
    if (!employee_project)
      throw new Error("Employee is not assigned to project");
    const projectName = employee_project.project.name;
    const joinGeneralContractorId = employee.general_contractor.pk;
    if (u.user.email) {
      await sendInvite({
        sendTo: u.user.email,
        joinSubcontractorId: subcontractorId,
        sentFromCompanyName,
        sentFromName,
        projectName,
        joinGeneralContractorId,
        joinProjectId: projectId,
        joinRole: "sub-admin",
      });
      message.success("Invite sent");
    }
  };

  type SubEmployeeType =
    GCSubcontractorQuery$data["subcontractor_connection"]["edges"][0]["node"]["subcontractor_employees"][number];

  const [insertProjectSubcontractorEmployee, inserting] =
    useAsyncMutation<AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation>(
      insertProjectSubcontractorEmployeeMutation,
    );

  const SubEmpAddToProjectButton: React.FC<{ row: SubEmployeeType }> = ({
    row,
  }) => (
    <Button
      loading={inserting}
      icon={<PlusOutlined />}
      type="link"
      onClick={async () => {
        await insertProjectSubcontractorEmployee({
          variables: {
            objects: [
              {
                subcontractor_employee_id: row.user_id,
                project_id: projectId,
                subcontractor_id: subcontractorId,
              },
            ],
          },
          updater: (store) =>
            handleInsertProjSubEmpForGcSubcontractorPage(
              store,
              subcontractorId,
              projectId,
            ),
        });
      }}
    >
      Add to Project
    </Button>
  );

  return (
    <StyledContent padding backgroundColor="white">
      <div className="w-full">
        <AddSubcontractorEmployeeModal
          visible={addEmployeeOpen}
          confirmLoading={creatingSubEmployee}
          onCancel={() => setAddEmployeeOpen(false)}
          onCreate={async (values) => {
            await createSubEmployee({
              variables: {
                input: {
                  name: values.name,
                  email: values.email,
                  phone: values.phoneNumber,
                  projectId: projectId,
                  employeeTitleId: values.titleId,
                  subcontractorId: subcontractorId,
                  sendInvite: values.send_invite,
                },
              },
            });
            projectSubcontractorEmployeeTableRef.current?.refetch();
            refetchData();
            if (values.send_invite)
              notification.open({
                message: `${values.name}'s account was created!`,
                description:
                  "They will be receiving an email containing their username and password to login to their account.",
              });
            setAddEmployeeOpen(false);
          }}
        />
        <div className="flex w-full">
          <div className="w-1/3">
            <DataDetailDescriptions
              title="Subcontractor Info"
              items={subcontractorItems}
            />
          </div>
          <div className="w-1/3 mt-1.5">
            <ProjectSubWorkerTitleViewAndEdit
              {...{
                projectId,
                subcontractorId,
                currentWorkerTitles:
                  subcontractorData.subcontractor_project_worker_titles,
                workerTitleOptions: data.worker_title_connection.edges.map(
                  (wt) => ({
                    value: wt.node.pk,
                    label: wt.node.translation.en,
                    disabled:
                      (subcontractorData.subcontractor_project_worker_titles.find(
                        (p) => p.worker_title_id === wt.node.pk,
                      )?.project_workers_aggregate?.aggregate?.count ?? 0) > 0,
                  }),
                ),
              }}
            />
          </div>
          <div className="w-1/3 mt-1.5">
            <ProjectSubChildSubs
              {...{
                projectId,
                subcontractorId,
                currentChildSubs:
                  data.parent_child_project_subcontractor_connection.edges,
                subSelectOptions: data.childSubOptions.edges.map(
                  ({ node }) => ({
                    label: node.subcontractor.name,
                    value: node.subcontractor.pk,
                  }),
                ),
              }}
            />
          </div>
        </div>

        <Space>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/gce/hierarchy/project/${projectId}/reports/pretaskplans?subId=${subcontractorId}`,
              )
            }
          >
            Safety Reports
          </Button>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/gce/hierarchy/project/${projectId}/reports/daily?subId=${subcontractorId}`,
              )
            }
          >
            Daily Reports
          </Button>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/gce/hierarchy/project/${projectId}/reports/toolboxtalks?subId=${subcontractorId}`,
              )
            }
          >
            Toolbox Talks
          </Button>
        </Space>

        <AddSubcontractorProjectTeamModal
          modalClose={() => {
            setOpenAddSubcontractorProjectTeamModal(false);
          }}
          refetchTables={() => {
            projectSubcontractorEmployeeTableRef.current?.refetch();
            refetchData();
          }}
          modalVisible={openAddSubcontractorProjectTeamModal}
          subcontractorId={subcontractorId}
          projectId={projectId}
          onSubmit={() => {
            setOpenAddSubcontractorProjectTeamModal(false);
            // projectSubcontractorEmployeeTableRef.current?.refetch();
          }}
        />
        <div className="mt-2">
          <ProjectSubcontractorEmployeeTable
            riskLevelColsToShow={riskLevelColsToShow}
            ref={projectSubcontractorEmployeeTableRef}
            showFullName
            resendInvite={resendInvite}
            headerComponent={
              <>
                <br />
                Add your project’s Point of Contacts (POC)
              </>
            }
            where={{
              project_id: { _eq: projectId },
              subcontractor_employee: {
                subcontractor_id: { _eq: subcontractorId },
              },
            }}
            loading={false}
            topBarButtons={[
              {
                onClick: () => {
                  setOpenAddSubcontractorProjectTeamModal(true);
                },
                label: "+ Add",
              },
            ]}
          />
        </div>
        {/* TODO COnvert this to DataScrollTable */}
        <div className="mt-2">
          <ScrollTable<(typeof allSubadmins)[number]>
            title={"Employees and Staff"}
            dataSource={allSubadmins}
            titleIcon={{
              icon: <MaterialSymbol icon={"add"} />,
              color: "interactive",
              onClick: () => {
                setAddEmployeeOpen(true);
              },
            }}
            columns={[
              {
                dataIndex: ["user", "name"],
                title: "Name",
                key: "name",
                defaultSortOrder: "asc",
                sortable: true,
              },
              {
                dataIndex: ["employee_title", "name_text"],
                title: "Title",
                key: "title",
              },
              {
                dataIndex: ["user", "email"],
                title: "Email",
                size: "xl",
                key: "email",
              },
              {
                dataIndex: ["user", "phone_number"],
                title: "Phone #",
                key: "phone",
              },

              {
                title: "",
                dataIndex: ["user"],
                key: "action",
                render: (_, row) => {
                  return row.project_subcontractor_employees[0] ? null : (
                    <SubEmpAddToProjectButton row={row} />
                  );
                },
              },
              ...getRiskLevelColumns(),
            ]}
          />
        </div>
      </div>
    </StyledContent>
  );
};

export default GCSubcontractorDetail;
