import { UserContextDataType } from "src/utility-features/authorization/UserDataProvider";
import { GCOrganizationUnitType } from "../../../components/hiearchy-navigation/gcHierarchyTreeTypes";
import { MaterialSymbol } from "react-material-symbols";
import React from "react";
import { RoutedMenuItem } from "../../../../../../../utility-features/navigation/routed-menu/routedMenuTypes";

export const getOrganizationUnitNavigationItems = (
  gc: NonNullable<UserContextDataType["employee"]>["general_contractor"],
  orgUnitType?: GCOrganizationUnitType,
): RoutedMenuItem[] => [
  // TODO Add Dashboard page
  // {
  //   label: "Dashboard",
  //   key: "dashboard",
  // },

  // TODO unify organization unit children to a single menu item
  // NOTE we should probably display this for all non-project nodes
  ...(orgUnitType === "division" || orgUnitType === "business_unit"
    ? [
        {
          label: "Child Units",
          path: "child-units",
          icon: <MaterialSymbol icon={"home_pin"} />,
        },
      ]
    : []),
  ///
  {
    label: "Projects",
    path: "projects",
    icon: <MaterialSymbol icon={"cases"} />,
  },
  {
    label: "Workers",
    path: "workers",
    icon: <MaterialSymbol icon={"construction"} />,
  },
  {
    label: "Employees",
    path: "employees",
    icon: <MaterialSymbol icon={"badge"} />,
  },
  {
    label: "Admins",
    path: "admins",
    icon: <MaterialSymbol icon={"supervisor_account"} />,
  },
  ...(gc.observation_enabled
    ? [
        {
          label: "Observations",
          path: "observations",
          icon: <MaterialSymbol icon={"visibility"} />,
        },
      ]
    : []),
  ...(gc.incident_enabled
    ? [
        {
          label: "Incidents",
          path: "incidents",
          icon: <MaterialSymbol icon={"report"} />,
        },
      ]
    : []),

  {
    label: "Settings",
    path: "settings",
    icon: <MaterialSymbol icon={"settings"} />,
  },
];
