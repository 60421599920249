import React from "react";
import CheckItem from "src/common/components/CheckItem";
import { Tag } from "antd";

export type Items = { id: string; label: string; selected: boolean }[];

export interface CheckListProps {
  items: Items;
  onClick: (id: string) => void;
}

const Checklist: React.FunctionComponent<CheckListProps> = ({
  items,
  onClick,
}) => {
  const handleClick = (id: string) => {
    onClick(id);
  };
  const checkedItems: Items = [];
  const uncheckedItems: Items = [];

  items
    .sort((a, b) => a.label.localeCompare(b.label))
    .forEach((item) => {
      if (item.selected) {
        checkedItems.push(item);
      } else {
        uncheckedItems.push(item);
      }
    });

  return (
    <div className="flex flex-col gap-0.5">
      {checkedItems.length > 0 && (
        <div className="block -m-0.25 w-full">
          {checkedItems.map(({ label, id }) => (
            <div className="inline-block m-0.25" key={id}>
              <Tag
                onClose={() => {
                  handleClick(id);
                }}
                key={id}
                closable={true}
              >
                {label}
              </Tag>
            </div>
          ))}
        </div>
      )}
      {uncheckedItems.length > 0 && (
        <div className="gap-0.5 flex flex-col items-start">
          {uncheckedItems.map(({ label, id }) => (
            <CheckItem
              onClick={() => {
                handleClick(id);
              }}
              {...{ label }}
              key={id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Checklist;
