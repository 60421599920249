import { graphql } from "babel-plugin-relay/macro";
import React, { forwardRef, useState } from "react";
import { WorkAboveElevationTable_ElevationUpdate_Mutation } from "src/common/types/generated/relay/WorkAboveElevationTable_ElevationUpdate_Mutation.graphql";
import { WorkAboveElevationTableQuery } from "src/common/types/generated/relay/WorkAboveElevationTableQuery.graphql";
import { WorkAboveElevationTable_work_above_elevation$data } from "src/common/types/generated/relay/WorkAboveElevationTable_work_above_elevation.graphql";

import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import CreateWorkAboveElevation from "../modal/CreateWorkAboveElevation";
import CounterLabel from "src/common/components/general/CounterLabel";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { Checkbox } from "antd";
import dayjs from "dayjs";
import Tag from "src/common/components/general/Tag";
import EditWorkAboveElevationModal from "../modal/EditWorkAboveElevationModal";
import { MaterialSymbol } from "react-material-symbols";

export type ColumnKeys =
  | "name"
  | "type"
  | "floors"
  | "protection"
  | "created_at"
  | "completed"
  | "created_by";

const CONNECTION_NAME = "work_above_elevation_connection";
export type DConnection =
  WorkAboveElevationTable_work_above_elevation$data[typeof CONNECTION_NAME];
export type WorkAboveElevationType = DConnection["edges"][number]["node"];
type Props = Omit<
  DataScrollTableImplementorProps<
    DConnection,
    ColumnKeys,
    WorkAboveElevationTableQuery,
    { pk: string }
  >,
  "onRowClick"
> & {
  searchString?: string;
  projectId: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    WorkAboveElevationTableQuery
  >["columns"];

  headerComponent?: React.ReactElement;
  datePickerDataTitle?: string;
  datePickerDataIndex?: string[];
  defaultSortColumnKey?: ColumnKeys;
  typeOptions: Array<{
    name: { en: string };
    id: string;
  }>;
};

export const useUpdateElevation = () =>
  useAsyncMutation<WorkAboveElevationTable_ElevationUpdate_Mutation>(graphql`
    mutation WorkAboveElevationTable_ElevationUpdate_Mutation(
      $id: uuid!
      $_set: work_above_elevation_set_input!
    ) {
      update_work_above_elevation_by_pk(pk_columns: { id: $id }, _set: $_set) {
        ...WorkAboveElevationFrag @relay(mask: false)
      }
    }
  `);
const WorkAboveElevationTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Locations",
      searchString,
      datePickerDataTitle,

      datePickerDataIndex,
      headerComponent,
      extraColumns,
      where,
      defaultSortColumnKey,
      ...props
    },
    ref,
  ) => {
    const [createNew, setCreateNew] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedKey, setSelectedKey] = useState<WorkAboveElevationType>();
    const [updateElevation, updatingElevation] = useUpdateElevation();

    return (
      <>
        {selectedKey && (
          <EditWorkAboveElevationModal
            visible={isEditing}
            workAboveElevationDataKey={selectedKey}
            onCancel={() => {
              setIsEditing(false);
            }}
          />
        )}
        <CreateWorkAboveElevation
          visible={createNew}
          projectId={props.projectId}
          onClose={() => setCreateNew(false)}
          typeOptions={props.typeOptions}
        />
        <DataScrollTable<DConnection, ColumnKeys, WorkAboveElevationTableQuery>
          {...props}
          newCustomTableLook
          ref={ref}
          title={title}
          onRowClick={(data) => {
            setIsEditing(true);
            setSelectedKey(data);
          }}
          topBarButtons={[
            {
              icon: <MaterialSymbol icon="add" />,
              label: "Create New Location",
              onClick: () => setCreateNew(true),
            },
          ]}
          where={{ ...where }}
          connectionName={CONNECTION_NAME}

          totalCountConnectionName={"allElevationsConnection"}
          datePickerDataIndex={datePickerDataIndex}
          datePickerDataTitle={datePickerDataTitle}
          columns={[
            {
              title: "Location",
              key: "name",
              sortable: true,
              size: "lg",
              defaultSortOrder: "asc",
              searchDataIndex: ["name", "en"],
              dataIndex: ["name", "en"],
              contentType: { type: "row-title" },
            },
            {
              title: "Protection(s)",
              key: "protection",
              dataIndex: ["work_above_floors"],
              render: (_, row) => {
                const nettings = row.work_above_floors.filter(
                  (f) => f.has_netting,
                );
                return nettings.length ? (
                  nettings.length === 1 ? (
                    <Tag status="inactive">{nettings[0].name.en}</Tag>
                  ) : (
                    <CounterLabel
                      count={nettings.length}
                      label="Protections"
                      popover={{
                        placement: "bottom",
                        trigger: "hover",
                        content: (
                          <div>
                            {nettings.map((qr) => (
                              <div className="mt-0.5" key={qr.pk}>
                                {qr.name.en}
                              </div>
                            ))}
                          </div>
                        ),
                      }}
                    />
                  )
                ) : (
                  ""
                );
              },
            },
            {
              width: 150,
              title: "Type",
              key: "type",
              dataIndex: ["work_above_elevation_type", "name", "en"],
              searchDataIndex: ["work_above_elevation_type", "name", "en"],
              size: "sm",
              contentType: {
                type: "tag",
              },
              filters: {
                dataIndex: ["type_id"],
                options: props.typeOptions.map((r) => ({
                  text: r.name.en,
                  value: r.id,
                })),
              },
            },
            {
              title: "Floors",
              key: "floors",
              dataIndex: ["work_above_floors"],
              size: "sm",
              render: (_, row) => (
                <CounterLabel
                  count={row.work_above_floors.length}
                  label="Floor(s)"
                  popover={{
                    placement: "bottomRight",
                    content: (
                      <table className="h-20 overflow-scroll pr-1">
                        <tr>
                          <th>Floor Name</th>
                          <th className="mr-2">Has Protection</th>
                          <th> &nbsp;&nbsp;</th>
                          <th>Completed</th>
                        </tr>
                        {row.work_above_floors.map((floor) => (
                          <tr key={floor.id}>
                            <td>{floor.name.en}</td>
                            <td className="text-center mr-1">
                              {floor.has_netting ? "✓" : ""}
                            </td>
                            <td></td>
                            <td className="text-center">
                              {floor.completed_at ? "✓" : ""}
                            </td>
                          </tr>
                        ))}
                      </table>
                    ),
                  }}
                />
              ),
            },
            {
              sortable: true,
              title: "Creation Date",
              key: "created_at",
              size: "md",
              defaultSortOrder: "asc",
              dataIndex: ["created_at"],
              // dateRangeSearchIndex: ["created_at"],
              contentType: {
                type: "date",
                renderOptions: () => ({
                  format: "mmddyyyy",
                }),
              },
            },
            {
              title: "Created By",
              key: "created_by",
              size: "md",
              dataIndex: ["created_by_user", "name"],
              // dateRangeSearchIndex: ["created_at"],
              render: (name, row) => (
                <div>
                  {name}
                  {row.created_by_user.employee?.employee_title
                    ? `, ${row.created_by_user.employee.employee_title.name.en}`
                    : ""}
                </div>
              ),
            },
            {
              title: "Completed",
              key: "completed",
              size: "sm",
              dataIndex: ["completed_at"],
              // dateRangeSearchIndex: ["created_at"],
              render: (val, row) => (
                <div className="p-1" onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    checked={!!val}
                    onChange={(e) => {
                      updateElevation({
                        variables: {
                          _set: {
                            completed_at: e.target.checked
                              ? dayjs().toISOString()
                              : null,
                          },
                          id: row.pk,
                        },
                        optimisticResponse: {
                          update_work_above_elevation_by_pk: {
                            ...row,
                            completed_at: e.target.checked
                              ? dayjs().toISOString()
                              : null,
                          },
                        },
                      });
                    }}
                  />
                </div>
              ),
            },

            ...(extraColumns || []),
          ]}
          queryNode={graphql`
            query WorkAboveElevationTableQuery(
              $first: Int!
              $after: String
              $where: work_above_elevation_bool_exp!
              $order_by: [work_above_elevation_order_by!]!
            ) {
              ...WorkAboveElevationTable_work_above_elevation
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                )
              ...WorkAboveElevationTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment WorkAboveElevationTable_total on query_root
            @argumentDefinitions(
              where: { type: "work_above_elevation_bool_exp!" }
            )
            @refetchable(
              queryName: "WorkAboveElevationTableTotalRefetchableQuery"
            ) {
              allElevationsConnection: work_above_elevation_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment WorkAboveElevationTable_work_above_elevation on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "work_above_elevation_bool_exp!" }
              order_by: { type: "[work_above_elevation_order_by!]!" }
            )
            @refetchable(queryName: "WorkAboveElevationTableRefetchableQuery") {
              work_above_elevation_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "WorkAboveElevationTable_work_above_elevation_connection"
                  filters: []
                ) {
                edges {
                  node {
                    ...WorkAboveElevationFrag @relay(mask: false)
                    ...EditWorkAboveElevationModal_work_above_elevation_frag
                  }
                }
              }
            }
          `}
        />
      </>
    );
  },
);

export default WorkAboveElevationTable;
