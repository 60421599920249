import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import { LockOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import { useState, useRef, useEffect } from "react";
import { useSignInMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { auth } from "src/common/functions/firebase";
import { useNavigate } from "react-router-dom";
import { signInWithCustomToken } from "firebase/auth";

const logoutAndSignInWithNewRole = async (token: string) => {
  await auth.signOut();
  await signInWithCustomToken(auth, token);
};

const SwitchCsrSfeButton: React.FC<{
  email: string;
}> = ({ email }) => {
  const [currentRole, setCurrentRole] = useState<string>();
  useEffect(() => {
    auth.currentUser?.getIdTokenResult().then((d) => {
      setCurrentRole(
        typeof d.claims.role === "string" ? d.claims.role : undefined,
      );
    });
  }, []);
  const [switchSFeModal, SetSwitchSFeModal] = useState(false);
  const [signIn, { loading, error }] = useSignInMutation();
  const navigate = useNavigate();
  const fmodalRef = useRef<FModalRef<{ password: string }>>(null);
  const switchRole = async () => {
      const val = await fmodalRef.current?.form.validateFields().catch(e => null);
      if (!val)
        return;
      const password = val.password;
      if (!password) {
        message.error("Password not entered");
        return;
      }
      const { data } = await signIn({
        variables: { username: email, password: password },
      });
      const token = data?.signIn;
      if (!token) {
        message.error("Token Generation failed");
        return;
      }
      await logoutAndSignInWithNewRole(token);
      navigate(0);
  };

  return (
    <>
      <FModal
        title="Switch"
        ref={fmodalRef}
        open={switchSFeModal}
        onCancel={() => SetSwitchSFeModal(false)}
        onOk={switchRole}
        okText={"Switch Role"}
        confirmLoading={loading}
      >
        <FModal.Password
          name={"password"}
          required
          props={{
            prefix: <LockOutlined />,
            placeholder: "Password",
            onPressEnter: switchRole,
            visibilityToggle: true,
          }}
          requiredMessage="Password required to switch"
        />
      </FModal>
      <Button type="primary" onClick={async () => SetSwitchSFeModal(true)}>
        Switch to {currentRole === "customer_portal" ? "SFe" : "Customer"}{" "}
        Portal
      </Button>
    </>
  );
};
export default SwitchCsrSfeButton;
