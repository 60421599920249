/**
 * @generated SignedSource<<2219c35b32c0c978129eb6b588ce0cf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { crew_onsite_update_insert_input, crew_report_settings_insert_input, project_subcontractor_onsite_update_insert_input, project_subcontractor_report_settings_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCOnsiteSettingsOnDateInsertMutation$variables = {
  crewOnsiteUpdateObjects: ReadonlyArray<crew_onsite_update_insert_input>;
  crewReportSettingsObjects: ReadonlyArray<crew_report_settings_insert_input>;
  projSubOnsiteUpdateObjects: ReadonlyArray<project_subcontractor_onsite_update_insert_input>;
  projSubReportSettingsObjects: ReadonlyArray<project_subcontractor_report_settings_insert_input>;
};
export type GCOnsiteSettingsOnDateInsertMutation$data = {
  readonly insert_crew_onsite_update: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_crew_report_settings: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_project_subcontractor_onsite_update: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_project_subcontractor_report_settings: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type GCOnsiteSettingsOnDateInsertMutation = {
  response: GCOnsiteSettingsOnDateInsertMutation$data;
  variables: GCOnsiteSettingsOnDateInsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "crewOnsiteUpdateObjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "crewReportSettingsObjects"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projSubOnsiteUpdateObjects"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projSubReportSettingsObjects"
},
v4 = [
  "type"
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v6 = [
  "safety_reports_required",
  "toolbox_talks_required",
  "daily_reports_required"
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "projSubOnsiteUpdateObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "project_subcontractor_onsite_update_project_id_subcontractor_id",
          "update_columns": (v4/*: any*/)
        }
      }
    ],
    "concreteType": "project_subcontractor_onsite_update_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_onsite_update",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "crewOnsiteUpdateObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "crew_onsite_update_project_crew_id_onsite_date_key",
          "update_columns": (v4/*: any*/)
        }
      }
    ],
    "concreteType": "crew_onsite_update_mutation_response",
    "kind": "LinkedField",
    "name": "insert_crew_onsite_update",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "projSubReportSettingsObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "project_subcontractor_report_settings_project_id_subcontractor_",
          "update_columns": (v6/*: any*/)
        }
      }
    ],
    "concreteType": "project_subcontractor_report_settings_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_report_settings",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "crewReportSettingsObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "crew_report_settings_project_crew_id_change_date_key",
          "update_columns": (v6/*: any*/)
        }
      }
    ],
    "concreteType": "crew_report_settings_mutation_response",
    "kind": "LinkedField",
    "name": "insert_crew_report_settings",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCOnsiteSettingsOnDateInsertMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCOnsiteSettingsOnDateInsertMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "c8b1789236f68ac57cfa5142f046d9f4",
    "id": null,
    "metadata": {},
    "name": "GCOnsiteSettingsOnDateInsertMutation",
    "operationKind": "mutation",
    "text": "mutation GCOnsiteSettingsOnDateInsertMutation(\n  $projSubOnsiteUpdateObjects: [project_subcontractor_onsite_update_insert_input!]!\n  $projSubReportSettingsObjects: [project_subcontractor_report_settings_insert_input!]!\n  $crewOnsiteUpdateObjects: [crew_onsite_update_insert_input!]!\n  $crewReportSettingsObjects: [crew_report_settings_insert_input!]!\n) {\n  insert_project_subcontractor_onsite_update(objects: $projSubOnsiteUpdateObjects, on_conflict: {constraint: project_subcontractor_onsite_update_project_id_subcontractor_id, update_columns: [type]}) {\n    affected_rows\n  }\n  insert_crew_onsite_update(objects: $crewOnsiteUpdateObjects, on_conflict: {constraint: crew_onsite_update_project_crew_id_onsite_date_key, update_columns: [type]}) {\n    affected_rows\n  }\n  insert_project_subcontractor_report_settings(objects: $projSubReportSettingsObjects, on_conflict: {constraint: project_subcontractor_report_settings_project_id_subcontractor_, update_columns: [safety_reports_required, toolbox_talks_required, daily_reports_required]}) {\n    affected_rows\n  }\n  insert_crew_report_settings(objects: $crewReportSettingsObjects, on_conflict: {constraint: crew_report_settings_project_crew_id_change_date_key, update_columns: [safety_reports_required, toolbox_talks_required, daily_reports_required]}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "c352caa9028f57461f6beaf5296be32f";

export default node;
