import { ShareAltOutlined } from "@ant-design/icons";
import { Button, Popover, Switch } from "antd";
import { nanoid } from "nanoid";
import { useState } from "react";
import Icon from "src/common/components/general/Icon";
import ConvertWorkerToEmployeeModal from "src/common/components/modals/ConvertWorkerToEmployeeModal";
import ConvertWorkerToSubAdminModal from "src/common/components/modals/ConvertWorkerToSubAdminModal";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  GetCsrProjectWorkersQuery,
  useCreateInviteMutation,
  useForgotPasswordMutation,
  useInsertCsrEditMutation,
  useUpdateUserCsrMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { MaterialSymbol } from "react-material-symbols";

const CsrProjectWorkersActionPopover: React.FC<{
  pw: GetCsrProjectWorkersQuery["project_worker"][number];
  projectId: string;
  refetch: () => void;
  subTitles: Array<{ label: string; value: string }>;
}> = ({ pw, projectId, refetch, subTitles }) => {
  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const authUser = useAuthUser();
  const user = pw.user;
  if (!user) throw new Error("User not found");
  const email = user.email;
  const inviteSent = user.invites;
  const [updateUser] = useUpdateUserCsrMutation();
  const [forgotPassword, { data }] = useForgotPasswordMutation();
  const [createInvite] = useCreateInviteMutation();
  const [insertCsrEdit] = useInsertCsrEditMutation();
  const [selectedFunction, setSelectedFunction] = useState<
    "workerToGC" | "workerToSubAdmin" | undefined
  >(undefined);
  return (
    <div onClick={(e) => e.stopPropagation()}>
      {user.email && (
        <ConvertWorkerToEmployeeModal
          refetch={refetch}
          insertCsrEdit
          type="single"
          worker={{
            email: user.email,
            id: user.id,
            name: user.name,
            phone_number: user.phone_number,
          }}
          onClose={() => {
            setSelectedFunction(undefined);
          }}
          onCancel={() => {
            setSelectedFunction(undefined);
          }}
          visible={selectedFunction === "workerToGC"}
        />
      )}
      {user.email && pw.subcontractor.id === pw.worker.subcontractor_id && (
        <ConvertWorkerToSubAdminModal
          refetch={refetch}
          insertCsrEdit
          type="single"
          onClose={() => {
            setSelectedFunction(undefined);
          }}
          worker={{
            email: user.email,
            id: user.id,
            subName: pw.subcontractor.name,
            name: user.name,
            phone_number: user.phone_number,
          }}
          onCancel={() => {
            setSelectedFunction(undefined);
          }}
          visible={selectedFunction === "workerToSubAdmin"}
        />
      )}
      <Popover
        open={open}
        placement="bottom"
        onOpenChange={handleOpenChange}
        key={pw.id}
        trigger={"click"}
        content={
          <div className="flex flex-col gap-1.5 min-w-16  items-start justify-center">
            <div>
              Allow Mobile Login:{" "}
              {user.allow_mobile_login ? (
                "ON"
              ) : (
                <Switch
                  checked={user.allow_mobile_login}
                  onChange={(index) => {
                    const _set = { allow_mobile_login: true };
                    updateUser({
                      variables: {
                        userId: user.id,
                        _set,
                        csrEdits: [
                          {
                            change_log: [{ _set }],
                            csr_user_id: authUser.uid,
                            edit_text: `Allowed mobile login for ${user.name}`,
                            entity_id: user.id,
                            name: "user",
                            operation_name: "update",
                          },
                        ],
                      },
                      optimisticResponse: {
                        update_user_by_pk: { ...user, ..._set },
                        insert_csr_edit: { affected_rows: 1 },
                      },
                    });
                  }}
                />
              )}
            </div>
            <div>
              Invite:{" "}
              {email ? (
                !pw.user?.created_password ? (
                  inviteSent && inviteSent.length > 0 ? (
                    <div
                      className="flex gap-1"
                      style={{ alignItems: "center" }}
                    >
                      <Button
                        size="small"
                        onClick={(e) => e.stopPropagation()}
                        className="text-0.75 bg-white text-semantic-pending border-0.125 border-semantic-pending hover:text-semantic-pending hover:border-semantic-pending rounded-2 mb-0.25"
                      >
                        INVITED
                      </Button>
                      <Button
                        icon={<ShareAltOutlined />}
                        onClick={async (e) => {
                          e.stopPropagation();
                          const inviteId = nanoid(12);
                          const invite = {
                            inviteId,
                            serverContact: true,
                            claimingAccount: true,
                            joinProjectId: projectId,
                            toEmail: email,
                            toUid: user.id,
                          };
                          await createInvite({
                            variables: { objects: [invite] },
                          });
                          await insertCsrEdit({
                            variables: {
                              csrEdits: [
                                {
                                  change_log: [invite],
                                  csr_user_id: authUser.uid,
                                  edit_text: `Resend invite to ${user.name}`,
                                  entity_id: user.id,
                                  name: "createInvite",
                                  operation_name: "resolver_call",
                                },
                              ],
                            },
                          });
                        }}
                      >
                        Resend Account Invite
                      </Button>
                    </div>
                  ) : (
                    <Button
                      onClick={async (e) => {
                        e.stopPropagation();
                        const inviteId = nanoid(12);
                        const invite = {
                          inviteId,
                          serverContact: true,
                          claimingAccount: true,
                          joinProjectId: projectId,
                          toEmail: email,
                          toUid: user.id,
                        };
                        await createInvite({
                          variables: { objects: [invite] },
                        });
                        await insertCsrEdit({
                          variables: {
                            csrEdits: [
                              {
                                change_log: [invite],
                                csr_user_id: authUser.uid,
                                edit_text: `Sent Account Invite to ${user.name}`,
                                entity_id: user.id,
                                name: "createInvite",
                                operation_name: "resolver_call",
                              },
                            ],
                          },
                        });
                      }}
                    >
                      Send Account Invite
                    </Button>
                  )
                ) : (
                  <Button
                    onClick={async (e) => {
                      e.stopPropagation();
                      await forgotPassword({
                        variables: { input: { loginIdentifier: email } },
                      });
                      await insertCsrEdit({
                        variables: {
                          csrEdits: [
                            {
                              change_log: [{ loginIdentifier: email }],
                              csr_user_id: authUser.uid,
                              edit_text: `Sent Reset password link to ${user.email}`,
                              entity_id: user.id,
                              name: "forgotPassword",
                              operation_name: "resolver_call",
                            },
                          ],
                        },
                      });
                    }}
                  >
                    Send Reset password link
                  </Button>
                )
              ) : (
                "Add Email First"
              )}
            </div>
            <div>
              {pw.worker.subcontractor_id === pw.subcontractor.id ? (
                <Button
                  onClick={(e) => {
                    handleOpenChange(false);
                    e.stopPropagation();
                    setSelectedFunction("workerToSubAdmin");
                  }}
                >
                  Convert to SubAdmin
                </Button>
              ) : (
                "Worker Current Sub and this project's sub do not match"
              )}
            </div>
            <div>
              <Button
                onClick={(e) => {
                  handleOpenChange(false);
                  e.stopPropagation();
                  setSelectedFunction("workerToGC");
                }}
              >
                Convert to Employee
              </Button>
            </div>
          </div>
        }
      >
        <Button
          className="rounded-1 font-accent"
          onClick={(e) => e.stopPropagation()}
        >
          <Icon icon={<MaterialSymbol icon="more_vert" />} color="interactive" />
        </Button>
      </Popover>
    </div>
  );
};
export default CsrProjectWorkersActionPopover;
