import * as uuid from "uuid";
import { forwardRef } from "react";
import { Popconfirm, Popover, Button, Space, message } from "antd";
import { ProjectSubcontractorEmployeeTableQuery } from "src/common/types/generated/relay/ProjectSubcontractorEmployeeTableQuery.graphql";
import { ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee$data } from "src/common/types/generated/relay/ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee.graphql";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "../../../../common/components/tables/basic/DataScrollTable";
import { graphql } from "babel-plugin-relay/macro";
import useSelectedIndices from "src/common/hooks/useSelectedIndices";
import {
  ProjectSubcontractorEmployeeTable_updateProjectSubcontractorEmployeeMutation,
  ProjectSubcontractorEmployeeTable_updateProjectSubcontractorEmployeeMutation$data,
} from "src/common/types/generated/relay/ProjectSubcontractorEmployeeTable_updateProjectSubcontractorEmployeeMutation.graphql";
import noPropagation from "src/common/functions/noPropagation";
import { DeleteOutlined, SendOutlined } from "@ant-design/icons";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import useGetSubadminObsRiskLevelColumns, {
  NotifyForObsDeleteUpdaterType,
  NotifyForObsInsertUpdaterType,
} from "src/root/routes/views/general-contractor/projects/subcontractors/sub-info/utils/useGetSubadminObsRiskLevelColumns";
import {
  ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation,
  ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation$data,
} from "src/common/types/generated/relay/ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation.graphql";

export type ColumnKeys =
  | "name"
  | "email"
  | "phoneNumber"
  | "emergencyContact"
  | "subcontractorEmployeeTitle"
  | "status"
  | "unsafe-high"
  | "unsafe-low"
  | "unsafe-sifp"
  | "unsafe-medium"
  | "action";

const updateProjectSubcontractorEmployeeUpdater: (
  store: RecordSourceSelectorProxy<ProjectSubcontractorEmployeeTable_updateProjectSubcontractorEmployeeMutation$data>,
  id: string,
  markAsValid: boolean,
) => void = (store, id, markAsValid) => {
  const conn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection",
  );
  if (conn) {
    const edges = conn.getLinkedRecords("edges") || [];
    edges.forEach((edge) => {
      const node = edge.getLinkedRecord("node");
      if (!node) {
        return;
      }

      if (node.getValue("id") == id && markAsValid) {
        node.setValue(true, "emergency_contact");
      } else if (!markAsValid) {
        node.setValue(false, "emergency_contact");
      }
    });
    conn.setLinkedRecords(edges, "edges");
  }
};

const notifyForObsInsertUpdater: NotifyForObsInsertUpdaterType = (
  store,
  rowDataId,
) => {
  const inserted = store
    .getRootField("insert_obs_notify_user")
    .getLinkedRecords("returning")[0];
  if (!inserted) throw new Error("Server returned null response");
  const conn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection",
  );
  if (!conn) return;
  const edges = conn.getLinkedRecords("edges") || [];
  edges.forEach((edge) => {
    const node = edge.getLinkedRecord("node");
    if (!node || node.getDataID() !== rowDataId) return;

    const currList = node.getLinkedRecords("notify_for_obs");
    node.setLinkedRecords([inserted, ...(currList || [])], "notify_for_obs");
  });
};

const notifyForObsDeleteUpdater: NotifyForObsDeleteUpdaterType = (
  store,
  rowDataId,
) => {
  const deletedRec = store
    .getRootField("delete_obs_notify_user")
    .getLinkedRecords("returning")[0];
  if (!deletedRec) throw new Error("Server returned null response");
  const conn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection",
  );
  const deletedRecDataId = deletedRec.getDataID();
  if (!conn) return;
  const edges = conn.getLinkedRecords("edges") || [];
  edges.forEach((edge) => {
    const node = edge.getLinkedRecord("node");
    if (!node || node.getDataID() !== rowDataId) return;
    const currList = node.getLinkedRecords("notify_for_obs");
    node.setLinkedRecords(
      (currList || []).filter((rec) => rec.getDataID() !== deletedRecDataId),
      "notify_for_obs",
    );
  });
};

const deleteProjectSubcontractorEmployeeUpdater = (
  store: RecordSourceSelectorProxy<ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation$data>,
  id: string,
) => {
  const conn = ConnectionHandler.getConnection(
    store.getRoot(),
    "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection",
  );
  if (conn) {
    const edges = conn.getLinkedRecords("edges");
    if (edges) {
      const newEdges = edges.filter((edge) => {
        const node = edge.getLinkedRecord("node");
        return node?.getValue("id") !== id;
      });
      conn.setLinkedRecords(newEdges, "edges");
    }
    ConnectionHandler.deleteNode(conn, id);
  }
};

const CONNECTION_NAME = "project_subcontractor_employee_connection";
type DConnection =
  ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee$data[typeof CONNECTION_NAME];
type NodeType = DConnection["edges"][number]["node"];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  ProjectSubcontractorEmployeeTableQuery,
  {}
> & {
  onProjectSubcontractorEmployeeRowClick?: (
    projectSubcontractorEmployee: ProjectSubcontractorEmployee,
  ) => void;
  searchString?: string;
  showFullName?: boolean;
  resendInvite?: (subEmp: NodeType["subcontractor_employee"]) => Promise<void>;
  title?: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    ProjectSubcontractorEmployeeTableQuery
  >["columns"];
  loading: boolean;
  riskLevelColsToShow?: { value: string; name: string; color_hex: string }[];
};
export type ProjectSubcontractorEmployee =
  ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee$data["project_subcontractor_employee_connection"]["edges"][0]["node"];
const projectSubcontractorEmployeeTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      onProjectSubcontractorEmployeeRowClick,
      searchString,
      where,
      topBarButtons,
      title = "Project Representatives",
      headerComponent,
      extraColumns,
      loading,
      resendInvite,
      riskLevelColsToShow,
      ...props
    },
    ref,
  ) => {
    const [updateProjectSubcontractorEmployee, inserting] =
      useAsyncMutation<ProjectSubcontractorEmployeeTable_updateProjectSubcontractorEmployeeMutation>(
        graphql`
          mutation ProjectSubcontractorEmployeeTable_updateProjectSubcontractorEmployeeMutation(
            $_set: project_subcontractor_employee_set_input
            $where: project_subcontractor_employee_bool_exp!
          ) {
            update_project_subcontractor_employee(_set: $_set, where: $where) {
              affected_rows
            }
          }
        `,
      );
    const [deletingRecords, _, __, addDeletingRecord, removeDeletingRecord] =
      useSelectedIndices<string>();

    const [deleteProjectSubcontractorEmployee] =
      useAsyncMutation<ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation>(
        graphql`
          mutation ProjectSubcontractorEmployeeTable_deleteProjectSubcontractorEmployeeMutation(
            $where: project_subcontractor_employee_bool_exp!
          ) {
            delete_project_subcontractor_employee(where: $where) {
              affected_rows
              returning {
                id
                pk: id @__clientField(handle: "pk")
              }
            }
          }
        `,
      );
    const getRiskLevelColumns = useGetSubadminObsRiskLevelColumns<
      NodeType,
      ColumnKeys
    >({
      riskLevelColsToShow,
      notifyForObsDeleteUpdater,
      notifyForObsInsertUpdater,
      getObsNotifyUserInsertObj: (row, risk_level_value) => ({
        id: uuid.v4(),
        risk_level_value,
        project_id: row.project.pk,
        user_id: row.subcontractor_employee_id,
      }),
    });
    const getName = (fullName: string) => {
      const parts = fullName.trim().split(" ");
      if (parts.length <= 1 || parts[parts.length - 1].length === 0) {
        return fullName;
      }
      const lastNameInitial = parts[parts.length - 1][0].toUpperCase();
      parts[parts.length - 1] = lastNameInitial;
      return parts.join(" ");
    };
    const makeEmergencyContact = async (
      projectSubcontractorEmployee: ProjectSubcontractorEmployee,
    ) => {
      await updateProjectSubcontractorEmployee({
        variables: {
          _set: { emergency_contact: false },
          where: { emergency_contact: { _eq: true } },
        },
        updater: (store) => {
          updateProjectSubcontractorEmployeeUpdater(
            store,
            projectSubcontractorEmployee.id,
            false,
          );
        },
      });
      await updateProjectSubcontractorEmployee({
        variables: {
          _set: { emergency_contact: true },
          where: { id: { _eq: projectSubcontractorEmployee.pk } },
        },
        updater: (store) => {
          updateProjectSubcontractorEmployeeUpdater(
            store,
            projectSubcontractorEmployee.id,
            true,
          );
        },
      });

      message.success("Emergency Contact Updated");
    };
    return (
      <>
        <DataScrollTable<
          DConnection,
          ColumnKeys,
          ProjectSubcontractorEmployeeTableQuery
        >
          {...props}
          newCustomTableLook
          ref={ref}
          title={title}
          headerComponent={headerComponent}
          loading={loading}
          onRowClick={(projectSubcontractorEmployee) =>
            onProjectSubcontractorEmployeeRowClick &&
            onProjectSubcontractorEmployeeRowClick(projectSubcontractorEmployee)
          }
          where={where}
          topBarButtons={topBarButtons}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allProjectSubcontractorEmployeeConnection"}
          columns={[
            {
              title: "Name",
              dataIndex: ["subcontractor_employee", "user", "name"],
              key: "name",
              defaultSortOrder: "asc",
              searchDataIndex: ["subcontractor_employee", "user", "name"],
              sortable: true,
              size: "sm",
              render: (name, projectSubcontractorEmployee) => (
                <>
                  {projectSubcontractorEmployee.emergency_contact && (
                    <div className="text-0.75 p-0.25 bg-white text-semantic-negative border-0.125 border-semantic-negative rounded-2 mb-0.25">
                      Emergency Contact
                    </div>
                  )}
                  <div>{props.showFullName ? name : getName(name)}</div>
                </>
              ),
            },
            {
              title: "Phone #",
              dataIndex: ["subcontractor_employee", "user", "phone_number"],
              key: "phoneNumber",
              defaultSortOrder: "asc",
              searchDataIndex: [
                "subcontractor_employee",
                "user",
                "phone_number",
              ],
              sortable: true,
              size: "xsm",
              render: (phoneNumber, _) => (
                <>
                  <div>{phoneNumber ?? ""}</div>
                </>
              ),
            },
            {
              title: "Title",
              dataIndex: [
                "subcontractor_employee",
                "employee_title",
                "name_text",
              ],
              key: "subcontractorEmployeeTitle",
              searchDataIndex: [
                "subcontractor_employee.employee_title.name_text",
              ],
              size: "sm",
              sortable: true,
            },
            {
              title: "Email",
              dataIndex: ["subcontractor_employee", "user", "email"],
              key: "email",
              defaultSortOrder: "asc",
              searchDataIndex: ["subcontractor_employee", "user", "email"],
              sortable: true,
              size: "sm",
            },
            {
              title: "",
              dataIndex: [""],
              size: "md",
              key: "emergencyContact",
              render: (_, projectSubcontractorEmployee) => (
                <>
                  <Space>
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={noPropagation(() => {
                        makeEmergencyContact(projectSubcontractorEmployee);
                      })}
                      onCancel={noPropagation()}
                      okText="Yes"
                      cancelText="Cancel"
                    >
                      <Button type="link" onClick={noPropagation()}>
                        Make Emergency Contact
                      </Button>
                    </Popconfirm>
                  </Space>
                </>
              ),
            },
            {
              dataIndex: ["subcontractor_employee", "user", "created_password"],
              title: "Status",
              key: "status",
              size: "sm",
              render: (a, { subcontractor_employee: subEmp }) =>
                subEmp.user.created_password ? (
                  <div className="flex items-center justify-center text-center text-semantic-positive-green">
                    {"User"}
                  </div>
                ) : subEmp.user.invites.length ? (
                  <div className="text-center">
                    Invited{" "}
                    {!!resendInvite && (
                      <Popover content="Resend Invite">
                        <Button
                          icon={<SendOutlined />}
                          type="primary"
                          shape="circle"
                          onClick={() => resendInvite(subEmp)}
                        ></Button>
                      </Popover>
                    )}
                  </div>
                ) : (
                  <div>
                    Not Invited{" "}
                    {!!resendInvite && (
                      <Popover content="Send Invite">
                        <Button
                          icon={<SendOutlined />}
                          type="primary"
                          shape="circle"
                          onClick={() => resendInvite(subEmp)}
                        ></Button>
                      </Popover>
                    )}
                  </div>
                ),
            },
            {
              title: "Action",
              dataIndex: [""],
              key: "action",
              size: "sm",
              render: (_, record) => (
                <div className="flex items-center justify-center w-full">
                  <Popconfirm
                    title="Are you sure you want to delete this record?"
                    onConfirm={async () => {
                      addDeletingRecord(record.pk);
                      try {
                        await deleteProjectSubcontractorEmployee({
                          variables: {
                            where: { id: { _eq: record.pk } },
                          },
                          updater: (store) => {
                            deleteProjectSubcontractorEmployeeUpdater(
                              store,
                              record.id,
                            );
                          },
                        });
                        message.success("Record deleted successfully");
                      } finally {
                        removeDeletingRecord(record.pk);
                      }
                    }}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      danger
                      loading={deletingRecords.has(record.pk)}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Popconfirm>
                </div>
              ),
            },
            ...getRiskLevelColumns(),
            ...(extraColumns || []),
          ]}
          queryNode={graphql`
            query ProjectSubcontractorEmployeeTableQuery(
              $first: Int!
              $after: String
              $where: project_subcontractor_employee_bool_exp!
              $order_by: [project_subcontractor_employee_order_by!]!
            ) {
              ...ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                )
              ...ProjectSubcontractorEmployeeTable_total
                @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment ProjectSubcontractorEmployeeTable_total on query_root
            @argumentDefinitions(
              where: { type: "project_subcontractor_employee_bool_exp!" }
            )
            @refetchable(
              queryName: "ProjectSubcontractorEmployeeTotalRefetchableQuery"
            ) {
              allproject_subcontractor_employeeConnection: project_subcontractor_employee_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "project_subcontractor_employee_bool_exp!" }
              order_by: { type: "[project_subcontractor_employee_order_by!]!" }
            )
            @refetchable(
              queryName: "ProjectSubcontractorEmployeeTableRefetchableQuery"
            ) {
              project_subcontractor_employee_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection"
                  filters: []
                ) {
                edges {
                  node {
                    ...ProjectSubcontractorEmployeeFrag @relay(mask: false)
                  }
                }
              }
            }
          `}
        />
      </>
    );
  },
);

export default projectSubcontractorEmployeeTable;
