import { ItemType } from "antd/lib/menu/interface";
import { RoutedMenuItem } from "./routedMenuTypes";

const getMenuItemsByRoutedMenu: (
  navigationItems: RoutedMenuItem[],
) => ItemType[] = (navigationItems) => {
  const menuItems: ItemType[] = [];

  navigationItems.forEach((item) => {
    const { label, icon, path, children } = item;
    const menuChildren =
      item.showChildrenInMenu && children?.length
        ? getMenuItemsByRoutedMenu(children)
        : undefined;
    if (!!path && !!label) {
      menuItems.push({
        key: path,
        label,
        icon,
        children: menuChildren,
      });
    }
  });

  return menuItems;
};

export default getMenuItemsByRoutedMenu;
