import dayjs from "dayjs";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "src/common/components/general/button/BackButton";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import PreTaskPlansTable from "src/common/components/tables/PreTaskPlansTable";
import DateProps from "src/common/types/manual/DateProps";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import CardWrapper from "../../../components/CardWrapper";
import DatesNavigation from "../../../components/DatesNavigation";

const QrProjectPtp: FC<ProjectIdProps & DateProps> = ({ projectId, date }) => {
  const navigate = useNavigate();
  const onDateChange = (e: dayjs.Dayjs) => {
    navigate(`/qr/p/${projectId}/ptp/date/${dayjs(e).toISOString()}`);
  };
  return (
    <MobileViewWrapper
      footer={
        <>
          <DatesNavigation date={dayjs(date)} onChange={onDateChange} />
          <BackButton url={`/qr/p/${projectId}`} />
        </>
      }
    >
      <CardWrapper>
        <PreTaskPlansTable
          excludedKeys={["project", "date"]}
          onRowClick={(pk) => {
            navigate(`/qr/p/${projectId}/ptp/date/${date}/reports/${pk}`);
          }}
          topBarButtons={[
            {
              label: "Create PTP +",
              onClick: () => {
                navigate(`/ptp/${projectId}`);
              },
            },
          ]}
          where={{
            project_id: {
              _eq: projectId,
            },
            created_at: {
              _gte: dayjs(date).startOf("day").toISOString(),
              _lt: dayjs(date).endOf("day").toISOString(),
            },
          }}
        />
      </CardWrapper>
    </MobileViewWrapper>
  );
};
export default withCustomSuspense(QrProjectPtp);
