import React, { useRef, useState } from "react";
import uploadFile from "src/common/functions/upload-utility/uploadFile";

interface UseUploadInputProps {
  onFileUpload?: (url: string) => Promise<void>;
  accept?: string;
}

const useUploadInput: (props: UseUploadInputProps) => {
  uploading: boolean;
  hiddenInputElement: React.ReactNode;
  openUploadWindow: () => void;
} = ({ onFileUpload, accept }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // handle validations
    // console.log(e.target.files, fileInput.current, e.target);

    if (e.target.files && e.target.files.length) {
      setUploading(true);
      await uploadFile(e.target.files[0])
        .then(async (d) => {
          if (d?.url) {
            onFileUpload && (await onFileUpload(d?.url));
          } else {
            throw new Error("Failed to upload the file");
          }
        })
        .catch((e) => {
          throw new Error(e);
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  const hiddenInputElement = (
    <input
      ref={fileInput}
      className="hidden"
      type="file"
      accept={accept}
      onChange={handleFileInput}
    />
  );

  const openUploadWindow = () => {
    const fileInputElement = fileInput.current;
    if (!fileInputElement) {
      throw new Error("File input element is not rendered");
    }
    fileInputElement.click();
  };

  return {
    uploading,
    hiddenInputElement,
    openUploadWindow,
  };
};

export default useUploadInput;
