import AppPageHistoryNavigation from "./AppPageHistoryNavigation";
import AppPageBreadcrumbs from "./AppPageBreadcrumbs";
import { Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import ContactUsButton from "./ContactUsButton";
import { useNavigate } from "react-router-dom";
import { AppNavigationLayoutContext } from "../utils/AppNavigationLayoutContext";

interface AppNavigationLayoutHeaderProps {}

const PROFILE_PATH = `/gce/settings`;

const AppNavigationLayoutHeader: React.FC<
  AppNavigationLayoutHeaderProps
> = () => {
  const navigate = useNavigate();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const appNavigationLayoutContext = useContext(AppNavigationLayoutContext);
  const setHeaderHeightPx = appNavigationLayoutContext?.setHeaderHeightPx;
  React.useEffect(() => {
    if (setHeaderHeightPx) {
      setHeaderHeightPx(headerRef.current?.clientHeight ?? 0);
    }
  }, [setHeaderHeightPx]);

  return (
    <div
      className={`py-0.25 bg-glass sticky top-0 z-50 flex flex-row items-center justify-between gap-1 px-2`}
      ref={headerRef}
    >
      {/*Left side of Header*/}
      <div className={`flex flex-row items-center gap-1`}>
        <AppPageHistoryNavigation />
        <AppPageBreadcrumbs />
      </div>

      {/*Right side of Header*/}
      <div className={`flex flex-row items-center gap-0.5`}>
        <ContactUsButton />
        <Button
          onClick={() => {
            navigate(PROFILE_PATH);
          }}
          icon={<UserOutlined />}
        >
          Profile
        </Button>
      </div>
    </div>
  );
};

export default AppNavigationLayoutHeader;
