import NestedRoute from "src/common/types/manual/NestedRoute";
import { Navigate, Outlet } from "react-router-dom";
import GCOrgUnitEmployees from "./users/employees/GCOrgUnitEmployees";
import GCOrgUnitWorkers from "./worker/GCOrgUnitWorkers";
import GCOrgUnitProjects from "./projects/GCOrgUnitProjects";
import GCOrgUnitIncidents from "./incident/GCOrgUnitIncidents";
import GCOrgUnitObservations from "./observation/GCOrgUnitObservations";
import RequireRoleWrapper from "../../../../../../common/components/ReqiureRoleWrapper";
import GCOrgUnitSettings from "./settings/GCOrgUnitSettings";
import GCOrgUnitChildUnits from "./child-units/GCOrgUnitChildUnits";
import GCOrgUnitAdmins from "./users/admins/GCOrgUnitAdmins";
import OrganizationUnitBreadcrumb from "./OrganizationUnitBreadcrumb";
import getRoutesByRoutedMenu from "../../../../../../utility-features/navigation/routed-menu/getRoutesByRoutedMenu";
import gcOrgUnitSettingsRoutedMenu from "./settings/gcOrgUnitSettingsRoutedMenu";

const gcOrganizationUnitRoute: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="employee">
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    {
      // TODO deprecate `organizationUnitType` from url
      path: ":organizationUnitType",
      handle: {
        breadcrumb: <OrganizationUnitBreadcrumb />,
      },
      element: <Outlet />,
      children: [
        {
          path: ":organizationUnitId",
          element: <Outlet />,
          children: [
            // TODO Add Dashboard page
            // {
            //   path: "dashboard",
            //   element: <GCOrgUnitDashboard />,
            // },
            {
              path: "projects",
              element: <GCOrgUnitProjects />,
              handle: {
                breadcrumb: "Projects",
              },
            },
            {
              path: "employees",
              element: <GCOrgUnitEmployees />,
              handle: {
                breadcrumb: "Employees",
              },
            },
            {
              path: "admins",
              element: <GCOrgUnitAdmins />,
              handle: {
                breadcrumb: "Admins",
              },
            },

            {
              path: "workers",
              element: <GCOrgUnitWorkers />,
              handle: {
                breadcrumb: "Workers",
              },
            },
            {
              path: "settings",
              element: <GCOrgUnitSettings />,
              handle: {
                breadcrumb: "Settings",
              },
              children: getRoutesByRoutedMenu(gcOrgUnitSettingsRoutedMenu),
            },
            {
              path: "incidents",
              element: <GCOrgUnitIncidents />,
              handle: {
                breadcrumb: "Incidents",
              },
            },
            {
              path: "observations",
              element: <GCOrgUnitObservations />,
              handle: {
                breadcrumb: "Observations",
              },
            },
            // TODO allow child units only if available
            {
              path: "child-units",
              element: <GCOrgUnitChildUnits />,
              handle: {
                breadcrumb: "Child Units",
              },
              children: [
                {
                  path: ":subview",
                },
              ],
            },

            //
            {
              path: "",
              element: <Navigate to={`projects`} replace />,
            },
          ],
        },
      ],
    },
  ],
};

export default gcOrganizationUnitRoute;
