import { Button, Divider, Layout, Tooltip } from "antd";
import React from "react";
import { useBoolean } from "usehooks-ts";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import sendHotjarEvent from "../../../../../utility-features/event-tracking/hotjar/sendHotjarEvent";
import AppLayoutSideMenuLogoAnimation from "./AppLayoutSideMenuLogoAnimation";

export interface AppLayoutSideMenuProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
}

const MENU_WIDTH = `14rem`;
const menuOpenIcon = <MenuUnfoldOutlined />;
const menuCloseIcon = <MenuFoldOutlined />;

const openMenuText = "Open Menu";
const closeMenuText = "Close Menu";

const AppLayoutSideMenu: React.FC<AppLayoutSideMenuProps> = (props) => {
  const { value: menuOpen, toggle: toggleMenuOpen } = useBoolean(true);
  const onMenuToggleClick = () => {
    sendHotjarEvent("side_menu_collapse_toggled");
    toggleMenuOpen();
  };

  return (
    <div className={`sticky h-screen top-0`}>
      <div className={`overflow-y-auto h-full`}>
        <Layout.Sider
          collapsible
          collapsed={!menuOpen}
          trigger={null}
          theme={"light"}
          width={MENU_WIDTH}
          className={`h-full border-r border-r-suplementary-1`}
        >
          <div className={`h-full flex flex-col gap-0`}>
            {[
              <div
                key={`menu-toggle`}
                className={`w-full flex flex-row items-center justify-between pr-0.25 pl-0.5 py-0.25`}
              >
                <AppLayoutSideMenuLogoAnimation
                  logoState={menuOpen ? "full" : "circle"}
                />
                <Tooltip title={menuOpen ? closeMenuText : openMenuText}>
                  <Button
                    type={"text"}
                    onClick={onMenuToggleClick}
                    icon={menuOpen ? menuCloseIcon : menuOpenIcon}
                  />
                </Tooltip>
              </div>,
              ...(props.header
                ? [<div key={`header`}>{props.header}</div>]
                : []),
              ...(props.body
                ? [
                    <div key={`body`} className={`flex-1 overflow-y-auto`}>
                      {props.body}
                    </div>,
                  ]
                : []),
            ].reduce<React.ReactNode[]>((acc, item, index) => {
              if (index > 0) {
                acc.push(
                  <Divider key={`divider-${index}`} style={{ margin: "0" }} />,
                );
              }
              acc.push(item);
              return acc;
            }, [])}
          </div>
        </Layout.Sider>
      </div>
    </div>
  );
};

export default AppLayoutSideMenu;
