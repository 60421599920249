import React, { useContext } from "react";
import {
  ChecklistItemAnswerType,
  OptionResponseType,
} from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import SiteInspectionUserContext from "src/domain-features/siteinspection/utils/siteInspectionUserContext";
import {
  GetInspectionDataOutput,
  InspectionChecklistItem,
} from "src/common/types/generated/apollo/graphQLTypes";
import { generalChecklistType } from "src/domain-features/siteinspection/utils/generalChecklistType";
import SiteInspectionWorkerChecklistItemUI from "./SiteInspectionWorkerStepChecklistItemUI";
import capitalize from "src/common/functions/capitalize";
import {
  useCurrentDBLangSelector,
  useCurrentLanguage,
} from "../../../../../../utility-features/i18n/context/languageHooks";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";
import { Button } from "antd";

export interface SiteInspectionWorkerStepChecklistItemListUIProps {
  deficientTypeOptions: GetInspectionDataOutput["deficientAnswerSelectTypes"];
  checklistItemTypeMap: {
    [type: string]: InspectionChecklistItem[];
  };
  showEntryInputs?: boolean;
  hideIncorrect?: boolean;
  checklistItemAnswers?: { [id: string]: ChecklistItemAnswerType };
  setChecklistItemAnswer: (
    changes: Partial<ChecklistItemAnswerType>,
    item: InspectionChecklistItem,
  ) => void;
}

const SiteInspectionWorkerStepChecklistItemListUI: React.FC<
  SiteInspectionWorkerStepChecklistItemListUIProps
> = ({
  deficientTypeOptions,
  showEntryInputs,
  hideIncorrect,
  checklistItemAnswers,
  checklistItemTypeMap,
  setChecklistItemAnswer,
}) => {
  const workerContext = useSiteInspectionUser();

  const gs = useCurrentDBLangSelector();
  const language = useCurrentLanguage();
  const allowPhotoToChecklistItem =
    !!workerContext.user?.inspection?.allow_photo_to_checklist_item;
  return (
    <div>
      {Object.entries(checklistItemTypeMap).map(([type, items]) => {
        return (
          <div key={type}>
            <div className="text-1.25">
              {items[0].type?.name
                ? gs(items[0].type?.name)
                : capitalize(generalChecklistType)}
            </div>
            <div className="ml-0.5 mt-1">
              {items.map((item) => (
                <div key={item.id}>
                  <SiteInspectionWorkerChecklistItemUI
                    {...{
                      ...item,
                      deficientTypeOptions,
                      allowPhotoToChecklistItem,
                      initialValue: checklistItemAnswers?.[item.id],
                      language,
                      showEntryInputs,
                      hideIncorrect,
                      onSelectAnswerChange: (val) =>
                        setChecklistItemAnswer({ select_answer: val }, item),
                      onImagesChange: (newAllImages) =>
                        setChecklistItemAnswer({ images: newAllImages }, item),
                      onDeficientTypeSelect: (deficientTypeId, immediateFix) =>
                        setChecklistItemAnswer(
                          {
                            immediate_fix: immediateFix,
                            deficient_type_id: deficientTypeId,
                          },
                          item,
                        ),
                      onOptionResponse: (response) =>
                        setChecklistItemAnswer(
                          {
                            option_response: response,
                            correct_answer:
                              item.correct_answer === "no" ? "no" : "yes",
                            require_photo_on_deficient:
                              allowPhotoToChecklistItem && item.require_photo,
                          },
                          item,
                        ),
                      onTextChange: (text) =>
                        setChecklistItemAnswer({ text_response: text }, item),
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default SiteInspectionWorkerStepChecklistItemListUI;
