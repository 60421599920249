import { Popover } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { forwardRef, useState } from "react";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { InspectionResultTableQuery } from "src/common/types/generated/relay/InspectionResultTableQuery.graphql";
import CustomButton from "src/common/components/general/button/Button";
import InspectionReportPdfDownload from "../action-buttons/InspectionReportPdfDownload";
import { InspectionResultTable_inspection_results$data } from "src/common/types/generated/relay/InspectionResultTable_inspection_results.graphql";
import ShowInspectionResultStatus from "../ShowInspectionResultStatus";
import { MaterialSymbol } from "react-material-symbols";

type InspectionAnsType = InspectionResultType["inspection_answers"][number];

interface StatusItemType extends InspectionAnsType {
  correctedOn?: dayjs.Dayjs;
  correctedBy?: string;
}

type ColumnKeys =
  | "date"
  | "inspection_name"
  | "qr"
  | "model"
  | "location"
  | "created_by"
  | "company"
  | "deficient"
  | "actionButtons";
const CONNECTION_NAME = "inspection_result_connection";
export type InspectionResultType =
  InspectionResultTable_inspection_results$data["inspection_result_connection"]["edges"][number]["node"];
type DConnection =
  InspectionResultTable_inspection_results$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  InspectionResultTableQuery,
  string
> & {
  showDateRangeFilter?: boolean;
  inspections?: Array<{ text: string; value: string }>;
  qrs: Array<{ text: string; value: string }>;
  projectId: string;
  OnRowItemClick: (result: InspectionResultType) => void;
};
const InspectionResultTable = forwardRef<DataScrollTableRef, Props>(
  (
    { title = "Inspections", onRowClick, showDateRangeFilter, ...props },
    ref,
  ) => {
    const [isActionFieldsOpen, setIsActionFieldsOpen] = useState<
      string | undefined
    >(undefined);
    const getPopupContainer = (triggerNode: HTMLElement) => {
      return triggerNode.parentNode as HTMLElement;
    };
    return (
      <DataScrollTable<DConnection, ColumnKeys, InspectionResultTableQuery>
        {...props}
        newCustomTableLook
        ref={ref}
        title={title}
        datePickerDataIndex={showDateRangeFilter ? ["created_at"] : undefined}
        onRowClick={(result) => {
          props.OnRowItemClick && props.OnRowItemClick(result);
        }}
        extraSearchDataIndexes={[
          [{ index: "company_name" }],
          [{ index: "general_contractor" }, { index: "name" }],
          [{ index: "user_name" }],
          [
            { index: "user" },
            { index: "worker" },
            {
              index: "worker_projects",
              extraConstraint: { project_id: { _eq: props.projectId } },
            },
            { index: "hard_hat_number" },
          ],
        ]}
        extraQueryVariables={{ projectId: props.projectId }}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allInstancesConnection"}
        columns={[
          {
            title: "QR",
            key: "qr",
            size: "sm",
            sortable: true,
            dataIndex: ["inspection_qr_code", "name"],
            filters: {
              type: "checklist",
              initialClose: true,
              dataIndex: ["inspection_qr_code_id"],
              options: props.qrs,
            },
          },
          {
            title: "Status",
            key: "deficient",
            size: "sm",
            dataIndex: ["inspection_answers"],
            render: (_, row) => (
              <ShowInspectionResultStatus answers={row.inspection_answers} />
            ),
          },
          {
            title: "Job Location",
            key: "location",
            queryIncludeVarKey: "includeLocation",
            dataIndex: ["project_location", "name", "en"],
            render: (v: string) => v ?? "",
          },
          {
            title: "Inspection Name",
            key: "inspection_name",
            size: "md",
            dataIndex: ["inspection_name", "en"],
            searchDataIndex: ["inspection_name", "en"],
            contentType: {
              type: "row-title",
            },
            filters: {
              type: "checklist",
              initialClose: true,
              dataIndex: ["inspection_instance_id"],
              options: props.inspections,
            },
          },
          {
            title: "Model",
            key: "model",
            sortable: true,
            searchDataIndex: ["model_number"],
            size: "md",
            dataIndex: ["model_number"],
            render: (v) => v ?? "",
          },
          {
            title: "Date",
            key: "date",
            sortable: true,
            contentType: {
              type: "date",
              renderOptions: () => ({ format: "full", withTime: true }),
            },
            size: "md",
            defaultSortOrder: "desc",
            dataIndex: ["created_at"],
          },
          {
            title: "Company",
            key: "company",
            sortable: true,
            size: "md",
            defaultSortOrder: "asc",
            searchDataIndex: ["subcontractor", "name"],
            dataIndex: ["subcontractor", "name"],
            render: (val, row) =>
              val || row.company_name || row.general_contractor?.name || "",
          },
          {
            title: "Inspector",
            key: "created_by",
            sortable: true,
            size: "md",
            defaultSortOrder: "asc",
            dataIndex: ["user", "name"],
            searchDataIndex: ["user", "name"],
            render: (val, row) =>
              row.user
                ? `${row.user.name} ${
                    row.user.worker &&
                    (row.user.worker?.worker_projects || []).length &&
                    row.user.worker.worker_projects[0].hard_hat_number
                      ? `#${row.user.worker.worker_projects[0].hard_hat_number}`
                      : ""
                  }`
                : row.user_name || "",
          },
          {
            title: "",
            key: "actionButtons",
            size: "sm",
            defaultSortOrder: "asc",
            dataIndex: [],
            render: (val, row) => (
              <div className="flex">
                <span className="flex-1">
                  <Popover
                    getPopupContainer={getPopupContainer}
                    open={isActionFieldsOpen === row.pk}
                    placement={"topLeft"}
                    content={
                      <InspectionReportPdfDownload inspectionId={row.pk} />
                    }
                    trigger={["click"]}
                  >
                    <CustomButton
                      icon={<MaterialSymbol icon="more_vert" />}
                      secondary
                      onClick={() =>
                        isActionFieldsOpen === row.pk
                          ? setIsActionFieldsOpen(undefined)
                          : setIsActionFieldsOpen(row.pk)
                      }
                    />
                  </Popover>
                </span>
              </div>
            ),
          },
        ]}
        queryNode={graphql`
          query InspectionResultTableQuery(
            $first: Int!
            $after: String
            $projectId: uuid!
            $where: inspection_result_bool_exp!
            $includeLocation: Boolean!
            $order_by: [inspection_result_order_by!]!
          ) {
            ...InspectionResultTable_inspection_results
              @arguments(
                first: $first
                after: $after
                where: $where
                projectId: $projectId
                order_by: $order_by
                includeLocation: $includeLocation
              )
            ...InspectionResultTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment InspectionResultTable_total on query_root
          @argumentDefinitions(where: { type: "inspection_result_bool_exp!" })
          @refetchable(
            queryName: "InspectionResultTableTotalRefetchableQuery"
          ) {
            allInstancesConnection: inspection_result_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment InspectionResultTable_inspection_results on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            projectId: { type: "uuid!" }
            where: { type: "inspection_result_bool_exp!" }
            includeLocation: { type: "Boolean!" }
            order_by: { type: "[inspection_result_order_by!]!" }
          )
          @refetchable(queryName: "InspectionResultTableRefetchableQuery") {
            inspection_result_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "InspectionResultTable_inspection_result_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  id
                  model_number
                  created_at
                  user_id
                  user_name
                  project_location @include(if: $includeLocation) {
                    name {
                      en
                    }
                  }
                  user {
                    name
                    worker {
                      worker_projects(
                        where: {
                          project_id: { _eq: $projectId }
                          is_last: { _eq: true }
                        }
                      ) {
                        hard_hat_number
                      }
                    }
                  }
                  subcontractor_id
                  subcontractor {
                    name
                  }
                  general_contractor_id
                  general_contractor {
                    name
                  }
                  company_name
                  inspection_name {
                    en
                    id
                  }
                  inspection_qr_code {
                    name
                  }
                  inspection_images {
                    url
                    id
                  }
                  signature {
                    url
                  }
                  inspection_answers(
                    order_by: [
                      {
                        inspection_instance_checklist_item: {
                          inspection_checklist_item_type: {
                            text: { en: asc_nulls_first }
                          }
                        }
                      }
                      {
                        inspection_instance_checklist_item: { sort_index: asc }
                      }
                    ]
                  ) {
                    ...InspectionAnswerFrag @relay(mask: false)
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default InspectionResultTable;
