import React from "react";
import CustomSelect, {
  CustomSelectProps,
} from "src/common/components/general/CustomSelect";

interface WorkerProfileTitleProps extends CustomSelectProps {}

const WorkerProfileTitle: React.FunctionComponent<WorkerProfileTitleProps> = (
  props,
) => {
  return <CustomSelect {...props} />;
};

export default WorkerProfileTitle;
