import { Button, Checkbox, DatePicker, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import pluralize from "pluralize";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-relay/hooks";
import AddWorkerCertModal, {
  AddWorkerCertModalDataProps,
  AddWorkerCertModalRef,
} from "src/common/components/dialogs/AddWorkerCertModal";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import BSpace from "src/common/components/layouts/BSpace";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import {
  getCertificateExpiryValue,
  getCertificateExpiryValueFromDate,
} from "src/common/functions/getCertificateExpiryValue";
import { WorkerCertificationTableDeleteCertMutation } from "src/common/types/generated/relay/WorkerCertificationTableDeleteCertMutation.graphql";
import { WorkerCertificationTableInsertHistoryMutation } from "src/common/types/generated/relay/WorkerCertificationTableInsertHistoryMutation.graphql";
import { WorkerCertificationTableQuery } from "src/common/types/generated/relay/WorkerCertificationTableQuery.graphql";
import { WorkerCertificationTableUpdateCertMutation } from "src/common/types/generated/relay/WorkerCertificationTableUpdateCertMutation.graphql";
import { WorkerCertificationTable_worker_certifications$data } from "src/common/types/generated/relay/WorkerCertificationTable_worker_certifications.graphql";
import ImageCarousel from "../general/images/ImageCarousel";
import useUpdatePermission from "src/common/api/relay/mutationHooks/useUpdatePermission";
import { MaterialSymbol } from "react-material-symbols";

type ColumnKeys = "name" | "issuedDate" | "expiresDate" | "images" | "extra";
const CONNECTION_NAME = "worker_certification_connection";
type DConnection =
  WorkerCertificationTable_worker_certifications$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  WorkerCertificationTableQuery,
  string
> & {
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    WorkerCertificationTableQuery
  >["columns"];
  editable?: boolean;
  isMobileDevice?: boolean;
  isWorkerLogin?: boolean;
  insertModalProps?: AddWorkerCertModalDataProps;
};
export const deleteWorkerCertMutation = graphql`
  mutation WorkerCertificationTableDeleteCertMutation(
    $where: worker_certification_bool_exp!
  ) {
    delete_worker_certification(where: $where) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
      }
    }
  }
`;

export const updateCertMutation = graphql`
  mutation WorkerCertificationTableUpdateCertMutation(
    $id: uuid!
    $_set: worker_certification_set_input!
  ) {
    update_worker_certification_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
      expires_on
      issued_on
    }
  }
`;
export const insertWorkerCertificationHistoryMutation = graphql`
  mutation WorkerCertificationTableInsertHistoryMutation(
    $objects: [worker_certification_history_insert_input!]!
  ) {
    insert_worker_certification_history(objects: $objects) {
      affected_rows
    }
  }
`;

type Ref = DataScrollTableRef | null;
const WorkerCertificationTable = forwardRef<Ref, Props>(
  (
    {
      // title = " Verified Certifications",
      onRowClick,
      insertModalProps,
      editable,
      isMobileDevice,
      extraColumns,
      isWorkerLogin = false,
      ...props
    },
    ref,
  ) => {
    const [updatePermission] = useUpdatePermission();
    const table = useRef<DataScrollTableRef>(null);
    const addCertModal = useRef<AddWorkerCertModalRef>(null);
    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
    // TODO @deleteEdge https://github.com/facebook/relay/pull/3155
    const [deleteWorkerCerts, deletingCerts] =
      useMutation<WorkerCertificationTableDeleteCertMutation>(
        deleteWorkerCertMutation,
      );
    const [updateCert, updatingCert] =
      useAsyncMutation<WorkerCertificationTableUpdateCertMutation>(
        updateCertMutation,
      );
    const [insertHistory, insertingHistory] =
      useAsyncMutation<WorkerCertificationTableInsertHistoryMutation>(
        insertWorkerCertificationHistoryMutation,
      );
    useImperativeHandle<Ref, Ref>(ref, () => table.current);

    return (
      <BSpace>
        <BSpace hz>
          {!!insertModalProps && (
            <>
              <AddWorkerCertModal
                {...insertModalProps}
                ref={addCertModal}
                onInserted={() => {
                  table.current && table.current.refetch();
                }}
              />
              <Button
                type="primary"
                onClick={() => addCertModal.current?.open()}
                style={{ fontWeight: "bold", borderRadius: "20px" }}
              >
                Add Certification
              </Button>
            </>
          )}
          {selectedKeys.length > 0 && (
            <BPopconfirm
              onConfirm={() => {
                deleteWorkerCerts({
                  variables: {
                    where: {
                      id: {
                        _in: selectedKeys,
                      },
                    },
                  },
                  onCompleted: () => {
                    table.current?.refetch();
                    message.success(
                      `Deleted ${pluralize(
                        "certification",
                        selectedKeys.length,
                        true,
                      )}`,
                    );
                    setSelectedKeys([]);
                  },
                });
              }}
            >
              <Button
                danger
                loading={deletingCerts}
                style={{ fontWeight: "bold", borderRadius: "20px" }}
              >
                Delete selected ({selectedKeys.length})
              </Button>
            </BPopconfirm>
          )}
        </BSpace>
        <DataScrollTable<DConnection, ColumnKeys, WorkerCertificationTableQuery>
          {...props}
          ref={table}
          // title={title ?? ""}
          disableRowNumber
          onRowClick={(tbt) => {
            onRowClick && onRowClick(tbt.pk);
          }}
          extraQueryVariables={{ includeSettings: isWorkerLogin }}
          expandable={{
            rowExpandable: (cert) => cert.images.length > 0,
            expandedRowRender: (cert) => {
              const clockColor = getCertificateExpiryValue([
                {
                  name: cert.certification.name,
                  expiryDate: cert.expires_on,
                },
              ]);
              let textColor = "";
              if (clockColor === "low-severity")
                textColor = "text-orange-light";
              else if (clockColor === "high-severity")
                textColor = "text-orange-dark";
              else if (clockColor === "negative")
                textColor = "text-semantic-negative";
              return isMobileDevice ? (
                <div>
                  {cert.issued_on && (
                    <div>
                      Issued on:{dayjs(cert.issued_on).format("MMM D, YYYY")}
                    </div>
                  )}
                  {cert.expires_on && (
                    <div className="flex flex-row gap-0.5">
                      <div>Expires on:</div>
                      <div className={textColor}>
                        {dayjs(cert.expires_on).format("MMM D, YYYY")}
                      </div>
                      <MaterialSymbol icon={"schedule"} />
                    </div>
                  )}
                  {isWorkerLogin && (
                    <div>
                      Permissions:
                      <div className="flex flex-row justify-between">
                        <div> Manager</div>
                        <div>Employer</div>
                        <div>Supervisor</div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <Checkbox
                          checked={cert.privacy_setting.manager}
                          onChange={async (e) => {
                            await updatePermission(
                              e.target.checked,
                              { manager: e.target.checked },
                              cert.privacy_setting_id,
                              cert.privacy_setting,
                            );
                          }}
                        />
                        <Checkbox
                          checked={cert.privacy_setting.employer}
                          onChange={async (e) => {
                            await updatePermission(
                              e.target.checked,
                              { employer: e.target.checked },
                              cert.privacy_setting_id,
                              cert.privacy_setting,
                            );
                          }}
                        />
                        <Checkbox
                          checked={cert.privacy_setting.supervisor}
                          onChange={async (e) => {
                            await updatePermission(
                              e.target.checked,
                              { supervisor: e.target.checked },
                              cert.privacy_setting_id,
                              cert.privacy_setting,
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <ImageCarousel images={cert.images} />
              );
            },
          }}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allCertsConnection"}
          rowSelection={{
            selectedRowKeys: selectedKeys,
            onChange: (keys) => {
              setSelectedKeys(keys.map((k) => k.toString()));
            },
          }}
          columns={[
            {
              title: isMobileDevice ? "" : "Name",
              key: "name",
              sortable: true,
              size: "lg",
              defaultSortOrder: "asc",
              dataIndex: ["certification", "name"],
            },
            ...(isMobileDevice
              ? []
              : ([
                  {
                    title: "Issued on",
                    key: "issuedDate",
                    size: "md",
                    dataIndex: ["issued_on"],
                    sortable: true,
                    render: (dateString: string | undefined, record) => {
                      const date = dateString ? dayjs(dateString) : null;
                      return editable ? (
                        <div className="flex flex-row gap-0.5">
                          <DatePicker
                            value={date ? dayjs(date) : undefined}
                            format="ll"
                            disabledDate={(current) =>
                              current.isAfter(dayjs().add(1, "day"))
                            }
                            onChange={async (e) => {
                              if (!e) {
                                return;
                              }
                              await updateCert({
                                variables: {
                                  id: record.pk,
                                  _set: {
                                    issued_on: dayjs(e).toISOString(),
                                  },
                                },
                                optimisticResponse: {
                                  update_worker_certification_by_pk: {
                                    id: record.pk,
                                    issued_on: dayjs(e).toISOString(),
                                    expires_on: record.expires_on,
                                  },
                                },
                              })
                                .then(async (d) => {
                                  message.success("Date Updated");
                                  await insertHistory({
                                    variables: {
                                      objects: [
                                        {
                                          edit_type: "issued_on",
                                          edited_by_uid: auth.currentUser?.uid,
                                          previous_value: date
                                            ? dayjs(date).toISOString()
                                            : null,
                                          worker_certification_id: record.pk,
                                        },
                                      ],
                                    },
                                  });
                                })
                                .catch((error) => {
                                  throw error;
                                });
                            }}
                          />
                        </div>
                      ) : (
                        <>{date ? dayjs(date).format("MMM D, YYYY") : ""}</>
                      );
                    },
                  },
                  {
                    title: "Expires on",
                    key: "expiresDate",
                    size: "md",
                    dataIndex: ["expires_on"],
                    sortable: true,
                    render: (dateString: string | undefined, record) => {
                      const date = dateString ? dayjs(dateString) : null;
                      const textColor = getCertificateExpiryValueFromDate(
                        dateString ? new Date(dateString) : undefined,
                      );
                      let borderColor = undefined;
                      if (textColor === "text-orange-light") {
                        borderColor = "#F4BB44";
                      }
                      if (textColor === "text-orange-dark") {
                        borderColor = "#F28C28";
                      }
                      if (textColor === "text-semantic-negative") {
                        borderColor = "red";
                      }
                      return editable ? (
                        <div className="flex flex-row gap-0.5">
                          <DatePicker
                            value={date ? dayjs(date) : undefined}
                            format="ll"
                            style={{ borderColor: borderColor }}
                            onChange={async (e) => {
                              if (!e) {
                                return;
                              }
                              await updateCert({
                                variables: {
                                  id: record.pk,
                                  _set: {
                                    expires_on: dayjs(e).toISOString(),
                                  },
                                },
                                optimisticResponse: {
                                  update_worker_certification_by_pk: {
                                    id: record.pk,
                                    issued_on: record.issued_on,
                                    expires_on: dayjs(e).toISOString(),
                                  },
                                },
                              })
                              message.success("Date Updated");
                              await insertHistory({
                                variables: {
                                  objects: [
                                    {
                                      edit_type: "expires_on",
                                      edited_by_uid: auth.currentUser?.uid,
                                      previous_value: date
                                        ? dayjs(date).toISOString()
                                        : null,
                                      worker_certification_id: record.pk,
                                    },
                                  ],
                                },
                              });
                            }}
                          />
                        </div>
                      ) : (
                        <>{date ? dayjs(date).format("MMM D, YYYY") : ""}</>
                      );
                    },
                  },
                  {
                    title: "Images",
                    size: "md",
                    key: "images",
                    dataIndex: ["images"],
                    render: (_: any, cert) => `${cert.images.length}`,
                  },
                  ...((isWorkerLogin && !isMobileDevice
                    ? [
                        {
                          dataIndex: ["manager"],
                          title: "Manager",
                          size: "sm",
                          queryIncludeVarKey: "includeSettings",
                          key: "extra",
                          render: (_, a) => (
                            <Checkbox
                              checked={a.privacy_setting?.manager}
                              onChange={async (e) => {
                                await updatePermission(
                                  e.target.checked,
                                  { manager: e.target.checked },
                                  a.privacy_setting_id,
                                  a.privacy_setting,
                                );
                              }}
                            />
                          ),
                        },
                        {
                          dataIndex: [""],
                          title: "Employer",
                          size: "sm",
                          key: "extra",
                          render: (_, a) => (
                            <Checkbox
                              checked={a.privacy_setting?.employer}
                              onChange={async (e) => {
                                await updatePermission(
                                  e.target.checked,
                                  { employer: e.target.checked },
                                  a.privacy_setting_id,
                                  a.privacy_setting,
                                );
                              }}
                            />
                          ),
                        },
                        {
                          dataIndex: [""],
                          title: "Supervisor",
                          size: "sm",
                          key: "extra",
                          render: (_, a) => (
                            <Checkbox
                              checked={a.privacy_setting?.supervisor}
                              onChange={async (e) => {
                                await updatePermission(
                                  e.target.checked,
                                  { supervisor: e.target.checked },
                                  a.privacy_setting_id,
                                  a.privacy_setting,
                                );
                              }}
                            />
                          ),
                        },
                      ]
                    : []) as DataScrollTableProps<
                    DConnection,
                    ColumnKeys,
                    WorkerCertificationTableQuery
                  >["columns"]),
                  ...(extraColumns || []),
                ] as DataScrollTableProps<
                  DConnection,
                  ColumnKeys,
                  WorkerCertificationTableQuery
                >["columns"])),
          ]}
          queryNode={graphql`
            query WorkerCertificationTableQuery(
              $first: Int!
              $after: String
              $where: worker_certification_bool_exp!
              $includeSettings: Boolean!
              $order_by: [worker_certification_order_by!]!
            ) {
              ...WorkerCertificationTable_worker_certifications
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                  includeSettings: $includeSettings
                )
              ...WorkerCertificationTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment WorkerCertificationTable_total on query_root
            @argumentDefinitions(
              where: { type: "worker_certification_bool_exp!" }
            )
            @refetchable(
              queryName: "WorkerCertificationTableTotalRefetchableQuery"
            ) {
              allCertsConnection: worker_certification_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment WorkerCertificationTable_worker_certifications on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "worker_certification_bool_exp!" }
              includeSettings: { type: "Boolean!" }
              order_by: { type: "[worker_certification_order_by!]!" }
            )
            @refetchable(
              queryName: "WorkerCertificationTableRefetchableQuery"
            ) {
              worker_certification_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "WorkerCertificationTable_worker_certification_connection"
                  filters: ["where", "order_by"]
                ) {
                edges {
                  node {
                    pk: id @__clientField(handle: "pk")
                    created_at
                    expires_on
                    issued_on
                    privacy_setting_id
                    privacy_setting @include(if: $includeSettings) {
                      manager
                      id
                      employer
                      supervisor
                    }
                    certification {
                      name
                    }
                    images(order_by: { description: desc_nulls_last }) {
                      url
                    }
                  }
                }
              }
            }
          `}
        />
      </BSpace>
    );
  },
);

export default WorkerCertificationTable;
