import React, { forwardRef, useState } from "react";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { TasksTableQuery } from "src/common/types/generated/relay/TasksTableQuery.graphql";
import { TasksTable_task$data } from "src/common/types/generated/relay/TasksTable_task.graphql";
import dayjs from "dayjs";
import CustomButton from "src/common/components/general/button/Button";
import { graphql } from "babel-plugin-relay/macro";
import { Checkbox, Popover } from "antd";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import AddCompanyTaskToMultipleProjectsModal from "../modals/AddCompanyTaskToMultipleProjectsModal";
import { useRelayEnvironment } from "react-relay/hooks";
import { getJHAData } from "src/common/api/relay/queries/AddJHAToProjectOrCompany";
import { AddJHAToProjectOrCompanyQuery$data } from "src/common/types/generated/relay/AddJHAToProjectOrCompanyQuery.graphql";
import AcceptTask from "../action-buttons/AcceptTask";
import TaskRequestSubApproval from "../action-buttons/TaskRequestSubApproval";
import TaskViewFile from "../action-buttons/TaskViewFile";
import AddTaskToProjectOrCompany from "../action-buttons/AddTaskToProjectOrCompany";
import TaskPdfDownload from "../action-buttons/TaskPdfDownload";
import TaskExcelDownload from "../action-buttons/TaskExcelDownload";
import DeleteTask from "../action-buttons/DeleteTask";
import TaskResendForAcceptance from "../action-buttons/TaskResendForAcceptance";
import { getJHAApprovalData } from "src/common/api/relay/queries/GetJHAApprovalModalData";
import { GetJHAApprovalModalDataQuery$data } from "src/common/types/generated/relay/GetJHAApprovalModalDataQuery.graphql";
import ArchiveOrActivateTask from "../action-buttons/ArchiveOrActivateTask";
import SendTaskForApprovalModal from "../modals/SendTaskForApprovalModal";
import TaskFlagForAction from "../action-buttons/TaskFlagForAction";
import { MaterialSymbol } from "react-material-symbols";

export type ColumnKeys =
  | "status"
  | "select"
  | "subcontractor"
  | "jha"
  | "steps"
  | "hazards"
  | "ppe"
  | "permit"
  | "manager"
  | "internalReview"
  | "subcontractorApproval"
  | "workersSigned"
  | "workersUsed"
  | "lastUsed"
  | "firstUsed"
  | "lastEdit"
  | "created"
  | "actionButtons"
  | "addToMulipleProjects"
  | "addToProject"
  | "delete"
  | "safety"
  | "operations";

const CONNECTION_NAME = "task_connection";
export type DConnection = TasksTable_task$data[typeof CONNECTION_NAME];
export type ActionsAllowedType =
  | "archiveOrActivate"
  | "viewFile"
  | "resendForAcceptance"
  | "acceptJHA"
  | "addToProject"
  | "addToCompanyJHALibrary"
  | "delete"
  | "pdfDownload"
  | "requestTeamReview"
  | "excelDownload"
  | "requestSubcontractorApproval"
  | "archiveOrActivate"
  | "addToMultipleProjects"
  | "unflagJHA";
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  TasksTableQuery,
  {}
> & {
  projectOfSub?: Array<{ id: string; name: string }>;
  isNotTurnersProject: boolean;
  subcontractorId?: string;
  projectId?: string;
  customFilters?: CustomFilterType[];
  title: string;
  setSelectedRows?: React.Dispatch<React.SetStateAction<string[]>>;
  onRowItemClick: (row: TasksTableType) => void;

  actionsAllowed: Array<ActionsAllowedType>;
};

export type TasksTableType = DConnection["edges"][number]["node"];

const TasksTable = forwardRef<DataScrollTableRef, Props>(
  ({ projectId, where, title, customFilters, ...props }, ref) => {
    const environment = useRelayEnvironment();
    const [taskForApprovalModal, setTaskForApprovalModal] = useState<{
      type: "flag-jha-request" | "approval-request" | "review-request";
      taskId: string | undefined;
      modalData: GetJHAApprovalModalDataQuery$data | undefined;
    }>({
      type: "approval-request",
      modalData: undefined,
      taskId: undefined,
    });

    const [isMultiple, setIsMultiple] = useState(false);
    const [addTaskToProjects, setAddTaskToProjectsOpen] = useState<
      AddJHAToProjectOrCompanyQuery$data["task_connection"]["edges"] | undefined
    >(undefined);

    const [isActionFieldsOpen, setIsActionFieldsOpen] = useState(false);

    const getPopupContainer = (triggerNode: HTMLElement) => {
      return triggerNode.parentNode as HTMLElement;
    };

    return (
      <>
        {taskForApprovalModal.modalData && taskForApprovalModal.taskId && (
          <SendTaskForApprovalModal
            visible={!!taskForApprovalModal.modalData}
            data={taskForApprovalModal.modalData}
            type={taskForApprovalModal.type}
            onClose={() =>
              setTaskForApprovalModal((prev) => ({
                type: prev.type,
                modalData: undefined,
                taskId: undefined,
              }))
            }
            isNotTurnersProject={props.isNotTurnersProject}
            taskId={taskForApprovalModal.taskId}
          />
        )}

        {props.subcontractorId && props.projectOfSub && (
          <AddCompanyTaskToMultipleProjectsModal
            projectsOfSub={props.projectOfSub}
            onCancel={() => setAddTaskToProjectsOpen(undefined)}
            multiple={isMultiple}
            subcontractorId={props.subcontractorId}
            tasks={addTaskToProjects}
            visible={!!addTaskToProjects}
          />
        )}

        <DataScrollTable<DConnection, ColumnKeys, TasksTableQuery>
          {...props}

          loadAll
          scroll={{ x: true }}
          newCustomTableLook
          defaultCustomInitialSort={true}
          ref={ref}
          title={title}
          where={where}
          customFilters={customFilters}
          connectionName={CONNECTION_NAME}
          onRowClick={(row) => {
            if (isActionFieldsOpen) setIsActionFieldsOpen(false);
            else {
              props.onRowItemClick && props.onRowItemClick(row);
            }
          }}
          totalCountConnectionName={"allTasksTableConnection"}
          topBarButtons={props.topBarButtons}
          columns={[
            {
              onCell: (e) => ({
                onClick: (e) => {
                  e.stopPropagation();
                },
              }),
              title: "",
              size: "icon",
              align: "right",
              dataIndex: [],
              key: "select",
              render: (_, row) => {
                return (
                  <div className="p-1" onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      key={row.id}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        e.stopPropagation();
                        if (props.setSelectedRows) {
                          props.setSelectedRows((prevSelectedRows) => {
                            if (e.target.checked) {
                              return [...prevSelectedRows, row.pk];
                            } else {
                              return prevSelectedRows.filter(
                                (key) => key !== row.pk,
                              );
                            }
                          });
                        }
                      }}
                    />
                  </div>
                );
              },
            },
            {
              title: "Subcontractor",
              dataIndex: ["subcontractor", "name"],
              key: "subcontractor",
              size: "sm",
              sortable: true,
              searchDataIndex: ["subcontractor", "name"],

              clientCompareFn: (a, b) => {
                const subA =
                  a.subcontractor?.name ??
                  a.task_creation_request?.subcontractor.name ??
                  "";

                const subB =
                  b.subcontractor?.name ??
                  b.task_creation_request?.subcontractor.name ??
                  "";

                return subA.localeCompare(subB);
              },

              render: (_, row) => {
                return (
                  row.subcontractor?.name ??
                  row.task_creation_request?.subcontractor.name
                );
              },
            },
            {
              title: "JHA Title",
              dataIndex: ["description", "en"],
              key: "jha",
              searchDataIndex: ["description", "en"],
              queryIncludeVarKey: "includeLastUsed",
              sortable: true,
              render: (_, a) => {
                if (
                  a.last_used &&
                  a.last_used[0]?.created_at &&
                  dayjs(a.last_used[0]?.created_at).isAfter(
                    dayjs().subtract(14, "days").format("YYYY-MM-DD"),
                  )
                ) {
                  return (
                    <div className="flex flex-row w-3/4">
                      {a.description.en} {<div>🏢</div>}
                    </div>
                  );
                } else return a.description.en;
              },
            },
            {
              title: "Status",
              dataIndex: [""],
              key: "status",
              sortable: true,
              size: "xsm",

              clientCompareFn: (a, b) => {
                const order = [
                  "Requested",
                  "Processing",
                  "Pending Acceptance",
                  "Active",
                  "Archived",
                ];
                const statusA = a.archived_at
                  ? "Archived"
                  : a.flagged_at
                  ? "Flagged for Action"
                  : a.request_state === "requested"
                  ? "Requested"
                  : a.request_state === "processing"
                  ? "Processing"
                  : a.is_pending_acceptance
                  ? "Pending Acceptance"
                  : "Active";

                const statusB = b.archived_at
                  ? "Archived"
                  : b.flagged_at
                  ? "Flagged for Action"
                  : b.request_state === "requested"
                  ? "Requested"
                  : b.request_state === "processing"
                  ? "Processing"
                  : b.is_pending_acceptance
                  ? "Pending Acceptance"
                  : "Active";

                return order.indexOf(statusA) == order.indexOf(statusB)
                  ? a.description.en.localeCompare(b.description.en)
                  : order.indexOf(statusA) - order.indexOf(statusB);
              },
              render: (_, row) => {
                if (row.archived_at) {
                  return "Archived";
                } else if (row.flagged_at) {
                  return (
                    <div className="text-semantic-negative font-accent">
                      {" "}
                      Flagged for Action
                    </div>
                  );
                } else if (row.request_state === "requested")
                  return "Requested";
                else if (row.request_state === "processing")
                  return "Processing";
                else if (row.is_pending_acceptance) return "Pending Acceptance";
                else return "Active";
              },
            },
            {
              title: "Steps",
              dataIndex: ["task_steps_aggregate", "aggregate", "count"],
              key: "steps",
              defaultSortOrder: "asc",
              sortable: true,
              size: "icon",
              align: "center",
            },
            {
              title: "Hazards",
              key: "hazards",
              size: "icon",
              dataIndex: [""],
              render: (_, row) => {
                let hazLength = 0;
                row.task_steps.forEach(
                  (haz) =>
                    (hazLength = hazLength + haz.task_step_hazards.length),
                );
                return hazLength;
              },
              align: "center",
            },
            {
              title: "JHA Lead",
              dataIndex: ["reviewed_at"],
              key: "internalReview",
              defaultSortOrder: "desc",
              size: "md",
              sortable: true,
              render: (_, row) => {
                const taskAdmins = row.task_admins.map((admin) => ({
                  name: admin.general_contractor_employee.user.name,
                  title:
                    admin.general_contractor_employee.employee_title?.name.en,
                }));

                return taskAdmins.map((admin) => (
                  <div>
                    {`${admin.name} ${admin.title ? `(${admin.title})` : ``}`}
                  </div>
                ));
              },
            },
            {
              title: "Safety Review",
              dataIndex: [],
              key: "safety",
              defaultSortOrder: "desc",
              size: "md",
              render: (_, row) => {
                const safety =
                  row.task_signatures?.filter(
                    (review) =>
                      review.user?.employee?.employee_title
                        ?.employee_title_category?.name.en === "Safety",
                  ) ?? [];

                const emailRequest = row.email_requests
                  .filter((req) => req.type === "operations-review-request")
                  ?.at(0)?.created_at;

                return safety.length === 0 ? (
                  !!emailRequest ? (
                    <>
                      <div>Review Requested</div>
                      <div>{dayjs(emailRequest).format("MMM DD, YYYY")} </div>
                    </>
                  ) : (
                    <div>{0}</div>
                  )
                ) : safety.length > 2 ? (
                  <Popover
                    content={safety?.map((op, ind) => (
                      <div key={ind}>
                        <span>{op.user?.name} </span>
                        <span>
                          {dayjs(op.created_at).format("MMM DD, YYYY")}{" "}
                        </span>
                      </div>
                    ))}
                  >
                    {safety?.length}
                  </Popover>
                ) : (
                  <div>
                    {safety?.map((op, ind) => (
                      <div key={ind}>
                        {op.user?.name}{" "}
                        {dayjs(op.created_at).format("MMM DD, YYYY")}{" "}
                      </div>
                    ))}
                  </div>
                );
              },
            },
            {
              title: "Ops Review",
              dataIndex: [],
              key: "operations",
              defaultSortOrder: "desc",
              size: "md",
              render: (_, row) => {
                const operations =
                  row.task_signatures?.filter(
                    (review) =>
                      review.user?.employee?.employee_title
                        ?.employee_title_category?.name.en === "Operations",
                  ) ?? [];
                const emailRequest = row.email_requests
                  .filter((req) => req.type === "operations-review-request")
                  ?.at(0)?.created_at;

                return operations.length === 0 ? (
                  !!emailRequest ? (
                    <>
                      <div>Review Requested</div>
                      <div>{dayjs(emailRequest).format("MMM DD, YYYY")} </div>
                    </>
                  ) : (
                    <div>{0}</div>
                  )
                ) : operations.length > 2 ? (
                  <Popover
                    content={operations?.map((op, ind) => (
                      <div key={ind}>
                        <span>{op.user?.name} </span>
                        <span>
                          {dayjs(op.created_at).format("MMM DD, YYYY")}{" "}
                        </span>
                      </div>
                    ))}
                  >
                    {operations?.length}
                  </Popover>
                ) : (
                  <div>
                    {operations?.map((op, ind) => (
                      <div key={ind}>
                        {op.user?.name}{" "}
                        {dayjs(op.created_at).format("MMM DD, YYYY")}{" "}
                      </div>
                    ))}
                  </div>
                );
              },
            },
            {
              title: "Sub Approval",
              dataIndex: [""],
              key: "subcontractorApproval",
              defaultSortOrder: "asc",
              queryIncludeVarKey: "includeTaskEdit",
              size: "xsm",
              sortable: true,
              clientCompareFn: (a, b) => {
                return (
                  (a.task_signatures?.length ?? 0) -
                  (b.task_signatures?.length ?? 0)
                );
              },
              render: (_, row) => {
                const approvedByUsers = row.task_signatures
                  ?.filter(
                    (sign) =>
                      !sign.signature_image_id &&
                      !sign.sign_in_image_parent_id &&
                      sign.user?.role !== "employee",
                  )
                  .map(
                    (val) => (val.user?.name || val.email_request?.email) ?? "",
                  );

                const emailRequest = row.email_requests
                  .filter((req) => req.type === "approval-request")
                  ?.at(0)?.created_at;

                return approvedByUsers && approvedByUsers.length > 0 ? (
                  <Popover
                    content={approvedByUsers?.map((name, index) => (
                      <div key={index}>{name}</div>
                    ))}
                  >
                    {approvedByUsers?.length}
                  </Popover>
                ) : emailRequest ? (
                  `Requested  ${dayjs(emailRequest).format("MMM DD, YYYY")} `
                ) : (
                  0
                );
              },
            },
            {
              title: "PPE",
              key: "ppe",
              dataIndex: ["task_ppe_types_aggregate", "aggregate", "count"],
              queryIncludeVarKey: "includePpeCount",
              align: "right",
              size: "icon",
              sortable: true,
            },
            {
              title: "Permits",
              key: "permit",
              dataIndex: ["task_permit_types_aggregate", "aggregate", "count"],
              queryIncludeVarKey: "includePermitsCount",
              align: "right",
              size: "icon",
              sortable: true,
            },
            {
              title: "Signed",
              dataIndex: [""],
              key: "workersSigned",
              defaultSortOrder: "asc",
              size: "icon",
              queryIncludeVarKey: "includeTaskEdit",

              render: (_, row) => {
                const signedByUser = row.task_signatures
                  ?.filter(
                    (sign) =>
                      !!sign.signature_image_id ||
                      !!sign.sign_in_image_parent_id,
                  )
                  .map((val) => val.user?.name);

                return (
                  <Popover
                    content={signedByUser?.map((name, index) => (
                      <div key={index}>{name}</div>
                    ))}
                  >
                    {signedByUser?.length}
                  </Popover>
                );
              },
            },
            {
              title: "Last Used",
              key: "lastUsed",
              dataIndex: [],
              size: "sm",
              sortable: true,
              queryIncludeVarKey: "includeLastUsed",
              clientCompareFn: (a, b) => {
                const usedA = a.last_used
                  ? dayjs(a.last_used[0]?.created_at).valueOf()
                  : dayjs().valueOf();
                const usedB = b.last_used
                  ? dayjs(b.last_used[0]?.created_at).valueOf()
                  : dayjs().valueOf();
                return usedA - usedB;
              },
              render: (_, row) =>
                row.last_used?.length
                  ? dayjs(row.last_used[0].created_at).format("MMM DD, YYYY")
                  : "",
            },
            {
              title: "First Used",
              key: "firstUsed",
              dataIndex: [],
              size: "sm",
              queryIncludeVarKey: "includeFirstUsed",
              render: (_, row) =>
                row.first_used?.length
                  ? dayjs(
                      row.first_used[row.first_used.length - 1].created_at,
                    ).format("MMM DD, YYYY")
                  : "",
            },
            {
              title: "Last Edit",
              key: "lastEdit",
              size: "md",
              dataIndex: [""],
              queryIncludeVarKey: "includeTaskEdit",
              render: (_, row) => {
                if (!row.updated_at) return "";

                return (
                  <>
                    <span>{row.updated_by_user?.name}</span> <br />
                    <span>{dayjs(row.updated_at).format("MMM DD, YYYY")}</span>
                  </>
                );
              },
            },
            {
              title: "Created",
              dataIndex: ["created_at"],
              key: "created",
              defaultSortOrder: "asc",
              size: "md",
              sortable: true,
              searchDataIndex: ["created_at"],
              align: "right",
              render: (d: any, row) =>
                row.created_at ? (
                  <div>
                    <span>{row.created_by_user?.name} </span> <br />
                    <span>{dayjs(row.created_at).format("MMM DD, YYYY")}</span>
                  </div>
                ) : (
                  ""
                ),
            },

            {
              title: "Add to Projects",
              key: "addToMulipleProjects",
              dataIndex: [""],
              queryIncludeVarKey: "includeProjectTaskCount",
              align: "center",
              size: "xsm",
              render: (_, row) => {
                const projSet = new Set();
                row.project_tasks?.forEach((task) => {
                  projSet.add(task.project_id);
                });

                return (
                  <div
                    onClick={() => console.log("clicked")}
                    className="flex items-center"
                  >
                    <div className="flex items-center justify-center">
                      <span className="ml-1">{projSet.size}</span>

                      <div className="text-sm ml-1">
                        <CustomButton
                          green
                          icon={<MaterialSymbol icon="add" />}
                          small
                          onClick={async () => {
                            const jhaData = await getJHAData(environment, [
                              row.pk,
                            ]);
                            setIsMultiple(false);
                            setAddTaskToProjectsOpen(jhaData);
                          }}
                        ></CustomButton>
                      </div>
                    </div>
                  </div>
                );
              },
            },
            {
              title: "",
              dataIndex: [""],
              key: "actionButtons",
              size: "xsm",
              fixed: "right",

              render: (_: any, row, i: number) => {
                const status = row.archived_at
                  ? "Archived"
                  : row.flagged_at
                  ? "Flagged for Action"
                  : row.request_state === "requested"
                  ? "Requested"
                  : row.request_state === "processing"
                  ? "Processing"
                  : row.is_pending_acceptance
                  ? "Pending Acceptance"
                  : "Active";
                const subId = row.subcontractor_id;
                return (
                  <div
                    className="flex"
                    key={i}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <span>
                      <Popover
                        getPopupContainer={getPopupContainer}
                        placement={"topLeft"}
                        content={
                          <div>
                            {status === "Pending Acceptance" && (
                              <TaskViewFile key={1} row={row} />
                            )}

                            {props.actionsAllowed &&
                              props.actionsAllowed.includes(
                                "requestSubcontractorApproval",
                              ) &&
                              subId &&
                              projectId && (
                                <TaskRequestSubApproval
                                  key={2}
                                  title="Request Subcontractor Approval"
                                  onClick={async () => {
                                    const data = await getJHAApprovalData(
                                      environment,
                                      row.pk,
                                      subId,
                                      projectId,
                                    );
                                    setTaskForApprovalModal({
                                      type: "approval-request",
                                      modalData: data,
                                      taskId: row.pk,
                                    });
                                  }}
                                />
                              )}

                            {status === "Active" &&
                              projectId &&
                              props.actionsAllowed &&
                              props.actionsAllowed.includes(
                                "requestTeamReview",
                              ) && (
                                <TaskRequestSubApproval
                                  key={12}
                                  title="Request Team Member Review"
                                  onClick={async () => {
                                    const data = await getJHAApprovalData(
                                      environment,
                                      row.pk,
                                      row.subcontractor_id ?? "",
                                      projectId,
                                    );

                                    setTaskForApprovalModal({
                                      type: "review-request",
                                      modalData: data,
                                      taskId: row.pk,
                                    });
                                  }}
                                />
                              )}

                            {status === "Pending Acceptance" &&
                              props.actionsAllowed &&
                              props.actionsAllowed.includes(
                                "resendForAcceptance",
                              ) &&
                              row.creation_request_id && (
                                <TaskResendForAcceptance
                                  key={3}
                                  requestId={row.creation_request_id}
                                />
                              )}

                            {status === "Flagged for Action" &&
                              props.actionsAllowed.includes("unflagJHA") && (
                                <TaskFlagForAction
                                  key={11}
                                  status="flagged"
                                  taskId={row.pk}
                                  onClick={() => console.log("")}
                                />
                              )}

                            {props.actionsAllowed.includes(
                              "archiveOrActivate",
                            ) &&
                              (status === "Active" ||
                                status === "Archived") && (
                                <ArchiveOrActivateTask
                                  key={4}
                                  status={
                                    status == "Active" ? "active" : "archived"
                                  }
                                  taskId={row.pk}
                                />
                              )}
                            {status === "Pending Acceptance" &&
                              props.actionsAllowed.includes("acceptJHA") && (
                                <AcceptTask
                                  key={5}
                                  onRowItemClick={() =>
                                    props.onRowItemClick(row)
                                  }
                                />
                              )}
                            {status === "Active" &&
                              props.actionsAllowed.includes(
                                "addToCompanyJHALibrary",
                              ) &&
                              props.subcontractorId && (
                                <AddTaskToProjectOrCompany
                                  type="addToCompany"
                                  key={6}
                                  taskId={row.pk}
                                  subcontractorId={props.subcontractorId}
                                  projectId={null}
                                />
                              )}

                            {props.actionsAllowed.includes("addToProject") &&
                              props.subcontractorId &&
                              projectId && (
                                <AddTaskToProjectOrCompany
                                  type="addToProject"
                                  key={7}
                                  taskId={row.pk}
                                  subcontractorId={props.subcontractorId}
                                  projectId={projectId}
                                />
                              )}
                            {props.actionsAllowed.includes(
                              "addToMultipleProjects",
                            ) &&
                              status === "Active" && (
                                <div
                                  className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    const jhaData = await getJHAData(
                                      environment,
                                      [row.pk],
                                    );
                                    setIsMultiple(false);
                                    setAddTaskToProjectsOpen(jhaData);
                                  }}
                                >
                                  Add To Projects
                                </div>
                              )}

                            {
                              <TaskPdfDownload
                                key={8}
                                taskIds={[row.pk]}
                                type={
                                  props.isNotTurnersProject
                                    ? "siteform"
                                    : "turner"
                                }
                                jhaNum={[1]}
                              />
                            }
                            {
                              <TaskExcelDownload
                                key={9}
                                taskId={row.pk}
                                type={
                                  props.isNotTurnersProject
                                    ? "siteform"
                                    : "turner"
                                }
                              />
                            }

                            {props.actionsAllowed.includes("delete") && (
                              <DeleteTask
                                key={10}
                                taskIds={[row.pk]}
                                refresh={() => {
                                  if (ref && typeof ref !== "function")
                                    ref.current?.refetch();
                                }}
                              />
                            )}
                          </div>
                        }
                        trigger={["click"]}
                      >
                        <CustomButton
                          icon={<MaterialSymbol icon="more_vert" />}
                          secondary
                          onClick={() =>
                            isActionFieldsOpen
                              ? setIsActionFieldsOpen(false)
                              : setIsActionFieldsOpen(true)
                          }
                        />
                      </Popover>
                    </span>
                  </div>
                );
              },
            },
          ]}
          queryNode={graphql`
            query TasksTableQuery(
              $first: Int!
              $after: String
              $where: task_bool_exp!
              $order_by: [task_order_by!]!
              $includeTaskEdit: Boolean!
              $includeProjectTaskCount: Boolean!
              $includePpeCount: Boolean!
              $includePermitsCount: Boolean!
              $includeFirstUsed: Boolean!
              $includeLastUsed: Boolean!
            ) {
              ...TasksTable_task
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                  includeTaskEdit: $includeTaskEdit
                  includeProjectTaskCount: $includeProjectTaskCount
                  includePpeCount: $includePpeCount
                  includePermitsCount: $includePermitsCount
                  includeFirstUsed: $includeFirstUsed
                  includeLastUsed: $includeLastUsed
                )
              ...TasksTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment TasksTable_total on query_root
            @argumentDefinitions(where: { type: "task_bool_exp!" })
            @refetchable(queryName: "TasksTableTotalRefetchableQuery") {
              alltaskConnection: task_connection(where: $where) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment TasksTable_task on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "task_bool_exp!" }
              order_by: { type: "[task_order_by!]!" }
              includeTaskEdit: { type: "Boolean!" }
              includeProjectTaskCount: { type: "Boolean!" }
              includePpeCount: { type: "Boolean!" }
              includePermitsCount: { type: "Boolean!" }
              includeFirstUsed: { type: "Boolean!" }
              includeLastUsed: { type: "Boolean!" }
            )
            @refetchable(queryName: "TasksTableRefetchableQuery") {
              task_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "TasksTable_task_connection"
                  filters: ["where", "order_by"]
                ) {
                edges {
                  node {
                    email_requests {
                      type
                      created_at
                    }
                    creation_request_id
                    task_creation_request {
                      id
                      type
                      attached_files {
                        url
                      }
                      pk: id @__clientField(handle: "pk")
                      subcontractor_id
                      subcontractor {
                        name
                      }
                      tasks {
                        show_type
                      }
                    }
                    task_admins {
                      general_contractor_employee {
                        uid
                        employee_title {
                          name {
                            en
                          }
                        }
                        user {
                          name
                        }
                      }
                    }
                    updated_at
                    updated_by_user {
                      name
                    }
                    document {
                      url
                    }
                    subcontractor_id
                    subcontractor {
                      name
                    }
                    id
                    pk: id @__clientField(handle: "pk")
                    description {
                      en
                    }
                    task_steps_aggregate {
                      aggregate {
                        count
                      }
                    }
                    is_pending_acceptance
                    request_state
                    archived_at
                    archived_by_uid
                    flagged_at
                    created_at
                    created_by_user {
                      name
                    }
                    reviewed_at
                    reviewed_by_user {
                      name
                    }

                    task_steps {
                      id
                      task_step_hazards {
                        id
                      }
                    }
                    show_type
                    accepted_at_from_creation_request
                    accepted_by_uid_from_creation_request
                    task_edit(
                      order_by: { edited_at: desc }
                      where: { task_patch: { _neq: [] } }
                      limit: 1
                    ) @include(if: $includeTaskEdit) {
                      edited_at
                      edit_type
                      task_patch
                      edited_by_user {
                        name
                      }
                    }
                    task_signatures(
                      where: { is_active: { _eq: true } }
                      order_by: { created_at: desc }
                    ) @include(if: $includeTaskEdit) {
                      created_at
                      signature_image_id
                      sign_in_image_parent_id
                      email_request {
                        email
                      }
                      user {
                        role
                        name
                        employee {
                          employee_title {
                            employee_title_category {
                              name {
                                en
                              }
                            }
                          }
                        }
                      }
                    }
                    last_used: report_tasks(
                      order_by: { created_at: desc }
                      limit: 1
                    ) @include(if: $includeLastUsed) {
                      created_at
                    }
                    first_used: report_tasks(
                      order_by: { created_at: asc }
                      limit: 1
                    ) @include(if: $includeFirstUsed) {
                      created_at
                    }

                    project_tasks @include(if: $includeProjectTaskCount) {
                      project_id
                      id
                      pk: id @__clientField(handle: "pk")
                    }

                    task_ppe_types_aggregate @include(if: $includePpeCount) {
                      aggregate {
                        count
                      }
                    }
                    task_permit_types_aggregate
                      @include(if: $includePermitsCount) {
                      aggregate {
                        count
                      }
                    }
                  }
                }
              }
            }
          `}
        />
      </>
    );
  },
);

export default withCustomSuspense(TasksTable);
