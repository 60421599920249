import React from "react";
import { Avatar, Button, Dropdown, DropdownProps, Spin } from "antd";
import { MaterialSymbol } from "react-material-symbols";
import remToPx from "../../../../../../../common/functions/remToPx";
import useUploadInput from "../../../../../../../common/components/general/upload/useUploadInput";

export interface ProfileAvatarProps {
  src?: string;
  loading?: boolean;
  onFileUpload?: (imageUrl: string) => Promise<void>;
  onFileRemove?: () => Promise<void>;
}

const ProfilePicture: React.FC<ProfileAvatarProps> = (props) => {
  const { hiddenInputElement, uploading, openUploadWindow } = useUploadInput({
    accept: ".png,.jpg,.jpeg",
    onFileUpload: props.onFileUpload,
  });

  const dropdownMenu: DropdownProps["menu"] = {
    // onClick:(menuInfo) => {
    //   const key = menuInfo.key;
    //   switch (key){
    //     case "upload":
    //       props.onFileUpload && props.onFileUpload("");
    //       break;
    //     case "delete":
    //       props.onFileRemove && props.onFileRemove();
    //       break;
    //     default:
    //       break;
    //   }
    // },
    items: [
      {
        label: "Upload",
        icon: <MaterialSymbol icon={"upload"} />,
        onClick: () => {
          openUploadWindow();
        },
        key: "upload",
      },
      {
        label: "Delete",
        icon: <MaterialSymbol icon={"delete"} />,
        danger: true,
        onClick: () => {
          props.onFileRemove?.();
        },
        key: "delete",
        disabled: !props.src || !props.onFileRemove,
      },
    ],
  };

  return (
    <div>
      {hiddenInputElement}
      {props.src ? (
        <Spin wrapperClassName={"w-min"} spinning={uploading}>
          <Dropdown menu={dropdownMenu}>
            <Avatar src={props.src} size={remToPx(4)} />
          </Dropdown>
        </Spin>
      ) : (
        <Button
          onClick={openUploadWindow}
          loading={uploading}
          icon={<MaterialSymbol icon={"upload"} />}
        >
          Upload Profile Picture
        </Button>
      )}
    </div>
  );
};

export default ProfilePicture;
