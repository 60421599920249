import gcCompanySettingsRoutedMenu from "./gcCompanySettingsRoutedMenu";
import SettingsPageLayout from "../../../../../../../common/components/layouts/settings-page/SettingsPageLayout";
import getMenuItemsByRoutedMenu from "../../../../../../../utility-features/navigation/routed-menu/getMenuItemsByRoutedMenu";

const BASE_PATH = "/gce/hierarchy/corporate-office/settings";

const GCCompanySettings: React.FC = () => (
  <SettingsPageLayout
    basePath={BASE_PATH}
    menuItems={getMenuItemsByRoutedMenu(gcCompanySettingsRoutedMenu)}
  />
);

export default GCCompanySettings;
