import React, { useState } from "react";
import FModal from "src/common/components/dialogs/FModal";
import {
  InspectionInsertValues,
  OptionType,
} from "../utils/siteInspectionTypes";
import { FormInstance } from "antd/lib/form";
import { Button } from "antd";
import Icon from "src/common/components/general/Icon";
import clsx from "clsx";
import { MaterialSymbol } from "react-material-symbols";

const EditInspectionFields: React.FC<{
  initialValues: InspectionInsertValues;
  form: FormInstance<InspectionInsertValues> | undefined;
  inspectionQROptions?: Array<OptionType>;
  onAllowItemPhotoChange: (checked: boolean) => void;
  template?: boolean;
  labelOptions: Array<OptionType>;
  showAddToCompany?: boolean;
  showAddToCommunity?: boolean;
}> = ({ initialValues, inspectionQROptions, labelOptions, form, ...props }) => {
  const [showCreateQR, setShowCreateQR] = useState(false);
  const [modelOptionSelected, setModelOptionSelected] = useState<
    "required" | "default" | undefined
  >(
    initialValues.model_number
      ? "default"
      : initialValues.model_number_required
      ? "required"
      : undefined,
  );
  const [isMulti, setIsMulti] = useState(initialValues.is_multi_inspection);
  return (
    <>
      <div className="text-1.25">Details</div>
      <FModal.Text
        label="Inspection Name"
        required
        initialValue={initialValues.name}
        name={"name"}
        requiredMessage="Please enter a valid Inspection name"
      />
      {(props.showAddToCommunity || props.showAddToCompany) && (
        <>
          <div className={clsx("flex", props.showAddToCompany ? "" : "hidden")}>
            <FModal.Checkbox
              name="company"
              initialValue={initialValues.company}
              props={{ value: true }}
            >
              <span className="font-accent">Company Library</span>: add this
              inspection to your Company Library so it can be used on other
              company projects
            </FModal.Checkbox>
          </div>
          <div
            className={clsx("flex", props.showAddToCommunity ? "" : "hidden")}
          >
            <FModal.Checkbox
              name="community"
              props={{ value: true }}
              initialValue={initialValues.community}
            >
              {" "}
              <span className="font-accent">Community Library</span>: support
              the community, add this inspection to the Community Library
            </FModal.Checkbox>
          </div>
        </>
      )}
      <FModal.Checkbox
        initialValue={initialValues.is_multi_inspection}
        name="is_multi_inspection"
        valuePropName="checked"
        props={{
          value: true,
          onChange: (e) => {
            if (e.target.checked) {
              setIsMulti(true);
              form &&
                form.setFieldsValue({
                  model_number: undefined,
                  model_number_required: true,
                });
              setModelOptionSelected("required");
            } else setIsMulti(false);
          },
        }}
      >
        <span className="font-accent">Multi-Inspect</span>: performing multiple
        inspection at one time
        <br />
        (Inspectors will select/add the ID/Model # for each item)
      </FModal.Checkbox>
      <div className="font-accent mt-1 mb-0.25">Photos</div>
      <FModal.Checkbox
        initialValue={initialValues.image_required}
        name="image_required"
        valuePropName="checked"
        props={{ value: true }}
      >
        Inspector is required to add a photo to start the inspection
      </FModal.Checkbox>
      <FModal.Checkbox
        initialValue={initialValues.allow_photo_to_checklist_item}
        name="allow_photo_to_checklist_item"
        valuePropName="checked"
        props={{
          value: true,
          onChange: (e) => props.onAllowItemPhotoChange(e.target.checked),
        }}
      >
        Allow adding photos to checklist items
      </FModal.Checkbox>
      <FModal.Checkbox
        initialValue={initialValues.allow_inspection_in_app}
        name="allow_inspection_in_app"
        valuePropName="checked"
        props={{ value: true }}
      >
        Allow inspection to be performed in the mobile app
      </FModal.Checkbox>
      <div className={isMulti ? "hidden" : ""}>
        <div className="font-accent mt-1 mb-0.25">
          Location, Model, Serial, or ID #{" "}
          <span className="font-normal">(optional)</span>
        </div>
        <div className="ml-0.5">
          If there is a Location or Number associated with this inspection,
          choose one:{" "}
          <FModal.Checkbox
            initialValue={initialValues.model_number_required}
            name="model_number_required"
            valuePropName="checked"
            props={{
              disabled: isMulti || modelOptionSelected === "default",
              value: true,
              onChange: (e) => {
                if (e.target.checked) {
                  form && form.setFieldsValue({ model_number: undefined });
                  setModelOptionSelected("required");
                } else setModelOptionSelected(undefined);
              },
            }}
          >
            Inspector will provide or select the ID#/model/location each time
            they perform the inspection (the same inspection is used for
            multiple items with different ID #’s) <br />
            For example, a Fire Extinguisher inspection could have many ID #’s
            all on the same Inspection
          </FModal.Checkbox>
          <div>
            <div className="-mt-1 mb-0.125">- OR -</div>
            <FModal.Text
              initialValue={initialValues.model_number}
              label="A location, model, or ID# is assigned to the one item being inspected (one item per inspection)"
              name="model_number"
              props={{
                disabled: isMulti || modelOptionSelected === "required",
                placeholder:
                  "Enter the location, model or identification #, which is used to identify the item being inspected",
                onChange: (e) => {
                  if (e.target.value && e.target.value.trim()) {
                    form &&
                      form.setFieldsValue({ model_number_required: false });
                    setModelOptionSelected("default");
                  } else setModelOptionSelected(undefined);
                },
              }}
            />
          </div>
        </div>
      </div>
      <FModal.Select
        name={"labels"}
        initialValue={initialValues.labels}
        props={{
          options: labelOptions,
          mode: "multiple",
          placeholder:
            "Labels help when sorting and searching completed inspections (example labels: “Equipment” or “Scaffold”",
        }}
        label={<div className="font-accent">Label</div>}
      />
      {!props.template && (
        <>
          <div className="text-1.25">QR Code</div>
          {(inspectionQROptions || []).length > 0 && (
            <FModal.Select
              name={"inspection_qr_codes"}
              initialValue={initialValues.inspection_qr_codes}
              props={{ options: inspectionQROptions ?? [], mode: "multiple" }}
              label=" Add Inspection to an existing QR Code"
            />
          )}
          <div className={clsx("flex gap-1")}>
            <span className="mt-0.25">
              {(inspectionQROptions || []).length > 0 && <span>And/Or </span>}
              Create NEW QR Code
            </span>{" "}
            <Button
              type="primary"
              className="rounded-2 font-accent flex"
              onClick={() => {
                if (showCreateQR) {
                  form &&
                    form.setFieldsValue({
                      new_inspection_qr_code: undefined,
                    });
                  setShowCreateQR(false);
                } else {
                  setShowCreateQR(true);
                }
              }}
              icon={
                showCreateQR ? (
                  <Icon icon={<MaterialSymbol icon="close" />} color="white" />
                ) : (
                  <Icon icon={<MaterialSymbol icon="add" />} color="white" />
                )
              }
            >
              {showCreateQR ? "Cancel" : "Create"}
            </Button>
          </div>
          {showCreateQR ? (
            <FModal.Text
              name={"new_inspection_qr_code"}
              label="QR Code Name"
              required
              requiredMessage="Please enter a valid Name for QR"
              props={{
                placeholder:
                  "Name this QR code, for example “Fire Extinguishers” or you can be very specific, like “High Reach JLG 600AJ”",
              }}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default EditInspectionFields;
