import React, { useEffect } from "react";
import { Form, Modal, Select } from "antd";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

interface FormValues {
  usersToBeNotified: Array<string>;
}

interface User {
  name: string;
  email: string;
  id: string;
}

interface Props {
  projectId: string;
  visible: boolean;
  loading?: boolean;
  onDoneEditing: (values: FormValues) => void;
  onCancel: () => void;
  userOptions: Array<{ value: string; label: string }>;
  selectedUserIds: Array<string>;
}

const SelectEmailUsersForActiveUsersModal: React.FC<Props> = ({
  projectId,
  visible,
  onDoneEditing,
  onCancel,
  loading,
  selectedUserIds,
  userOptions,
}) => {
  // TODO: use typed hook

  //console.log(projectGcUsers.data?.project[0].general_contractor.employees);
  const [form] = Form.useForm<FormValues>();
  useEffect(() => {
    form.setFieldsValue({ usersToBeNotified: selectedUserIds });
  }, [selectedUserIds]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  return (
    <Modal
      open={visible}
      title="Edit Users To Be Notified"
      okText="Done"
      cancelText="Cancel"
      confirmLoading={loading}
      cancelButtonProps={{ style: { display: "inline" } }}
      onCancel={handleCancel}
      onOk={async () => {
        const values = await form.validateFields().catch((v) => null);
        if (!values) return;
        onDoneEditing(values);
        form.resetFields();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public", userIds: selectedUserIds }}
      >
        <Form.Item name="usersToBeNotified" label="Users">
          <Select
            style={{ width: "100%" }}
            mode="multiple"
            placeholder="Select users to be notified for this project"
            options={userOptions}
            showSearch
            filterOption={getNormalSelectOptionsFilter}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SelectEmailUsersForActiveUsersModal;
