import React, { useEffect, useMemo, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import {
  ChecklistItemAnswerType,
  ModelAnswersAndImagesType,
  SiteInspectionStepProps,
} from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import {
  CameraOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  GetInspectionDataOutput,
  InspectionChecklistItem,
} from "src/common/types/generated/apollo/graphQLTypes";
import { generalChecklistType } from "src/domain-features/siteinspection/utils/generalChecklistType";
import SiteInspectionWorkerStepChecklistItemListUI, {
  SiteInspectionWorkerStepChecklistItemListUIProps,
} from "../checklistItems/SiteInspectionWorkerStepChecklistitemListUI";
import { Button, Modal, Space } from "antd";
import SiteFeatureStepsUIImages, {
  SiteFeatureStepsUIImagesProps,
} from "src/common/components/general/images/SiteFeatureStepsUIImages";
import * as uuid from "uuid";
import clsx from "clsx";
import Icon from "src/common/components/general/Icon";
import EnterInputStringWithDoneAndCancel from "src/domain-features/siteinspection/components/EnterInputStringWithDoneAndCancel";
import getFullyCompletedChecklistitemAnswers from "src/domain-features/siteinspection/utils/getFullyCompletedChecklistitemAnswers";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";
import uploadImage from "src/common/functions/upload-utility/uploadImage";
// import Button from "src/common/components/general/Button";
import { MaterialSymbol } from "react-material-symbols";

export interface SiteInspectionWorkerStepsMultiModelCheckProps
  extends SiteInspectionStepProps {
  deficientTypeOptions: GetInspectionDataOutput["deficientAnswerSelectTypes"];
}

const SiteInspectionWorkerStepsMultiModelCheck: React.FC<
  SiteInspectionWorkerStepsMultiModelCheckProps
> = ({ deficientTypeOptions, onNext, onBack }) => {
  const workerContext = useSiteInspectionUser();
  const imagesRequired = workerContext.user?.inspection?.image_required;
  const langStrings = useCurrentLangStrings();
  const allowPhotoToChecklistItem =
    !!workerContext.user?.inspection?.allow_photo_to_checklist_item;
  const [modelOptions, setModelOptions] = useState<{
    [id: string]: ModelAnswersAndImagesType;
  }>(workerContext.user?.modelOptionsMap || {});
  useEffect(() => {
    const modelMap: {
      [id: string]: ModelAnswersAndImagesType;
    } = Object.fromEntries(
      [
        ...(workerContext.user?.inspection?.prevModelNumbers || []),
        ...Object.keys(
          workerContext.currentSessionInspectionsRequiredData[
            workerContext.user!.inspection!.id
          ]?.enteredModelNumbers ?? {},
        ),
      ].map((model) => [
        model,
        {
          result: undefined,
          images: [],
          modelNumber: model,
          checklistItemAnswers: {},
          id: uuid.v4(),
        },
      ]),
    );
    (workerContext.user?.modelAnswersAndImages || []).map((p) => {
      return (modelMap[p.modelNumber] = p);
    });

    const modelMapValues = Object.values(modelMap);
    if (
      workerContext.user?.modelOptionsMapBackOrNextPress === "back" ||
      !modelMapValues.length
    ) {
      setModelOptions(workerContext.user?.modelOptionsMap || {});
    } else
      setModelOptions(
        Object.fromEntries(
          modelMapValues.map((p) => {
            return [p.id, p];
          }),
        ),
      );
  }, []);
  const { checklistItemTypeMap, onlyOtherTypeChecklistItemTypeMap } =
    useMemo(() => {
      const checklistItemTypeMap: {
        [type: string]: InspectionChecklistItem[];
      } = {};
      const onlyOtherTypeChecklistItemTypeMap: typeof checklistItemTypeMap = {};
      (workerContext.user?.inspection?.checklistItems || []).forEach((item) => {
        const typeId = item.type?.name?.en ?? generalChecklistType;
        const list = [...(checklistItemTypeMap[typeId] ?? [])];
        list.push(item);
        checklistItemTypeMap[typeId] = list;

        if (item.item_type !== "checklist") {
          const otherTypeItems = [
            ...(onlyOtherTypeChecklistItemTypeMap[typeId] ?? []),
          ];
          otherTypeItems.push(item);
          onlyOtherTypeChecklistItemTypeMap[typeId] = otherTypeItems;
        }
      });
      return { checklistItemTypeMap, onlyOtherTypeChecklistItemTypeMap };
    }, [workerContext.user?.inspection?.checklistItems]);

  const onEnter = () => {
    workerContext.updateUser &&
      workerContext.updateUser((u) => {
        return {
          ...u,
          modelAnswersAndImages: Object.values(modelOptions).filter((val) => {
            return val.result;
          }),
          modelOptionsMap: modelOptions,
          modelOptionsMapBackOrNextPress: "next",
        };
      });
    onNext();
  };
  const otherTypeChecklistItemPresent = useMemo(
    () => !!Object.keys(onlyOtherTypeChecklistItemTypeMap).length,
    [onlyOtherTypeChecklistItemTypeMap],
  );
  const allChecklistItems = useMemo(
    () => workerContext.user?.inspection?.checklistItems || [],
    [workerContext.user?.inspection?.checklistItems || []],
  );
  const [loading, setLoading] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [showImageModalForId, setShowImageModalForId] = useState<string>();

  const onUploadImagesForSelectedModelNumber: SiteFeatureStepsUIImagesProps["onNext"] =
    async (images) => {
      if (!showImageModalForId) return;
      const uploadedImages = (
        modelOptions[showImageModalForId].images || []
      ).filter(
        (p) => images.findIndex((img) => img.url && img.id === p.id) !== -1,
      );
      const uploadPromises = (images || [])
        .filter(
          (img) => !uploadedImages.find((u) => u.id === img.id) || img.edited,
        )
        .map(async (image) => {
          try {
            if (image.url) {
              const uploadedImg = await uploadImage(image.url);
              if (uploadedImg) {
                const replacingIndex = uploadedImages.findIndex(
                  (img) => img.id === image.id,
                );
                if (replacingIndex === -1) {
                  uploadedImages.push({
                    ...uploadedImg,
                    id: image.id,
                    edited: false,
                  });
                } else {
                  uploadedImages[replacingIndex] = {
                    ...uploadedImg,
                    id: image.id,
                    edited: false,
                  };
                }
              }
            }
          } catch (err) {
            console.log(err);
          }
        });
      setLoading(true);
      await Promise.allSettled(uploadPromises)
        .then((results) => {
          results.map((result) => {
            console.log(result.status);
          });
        })
        .catch(console.error);
      setModelOptions((prev) => {
        return {
          ...prev,
          [showImageModalForId]: {
            ...prev[showImageModalForId],
            images: uploadedImages,
          },
        };
      });
      setLoading(false);
      setShowImageModalForId(undefined);
    };

  const onFailSelect = (id: string) => {
    setModelOptions((prev) => {
      const newRes: "fail" | undefined =
        prev[id].result === "fail" ? undefined : "fail";
      const prevOtherTypeAnswers = Object.fromEntries(
        Object.entries(prev[id].checklistItemAnswers).filter(
          ([_, itemAns]) => itemAns.item_type !== "checklist",
        ),
      );
      return {
        ...prev,
        [id]: {
          ...prev[id],
          result: newRes,
          done: false,
          checklistItemAnswers: prevOtherTypeAnswers,
        },
      };
    });
  };
  const onPassSelect = (id: string) => {
    setModelOptions((prev) => {
      const newRes: "pass" | undefined =
        prev[id].result === "pass" ? undefined : "pass";
      const checklistItemAnswers: {
        [key: string]: ChecklistItemAnswerType;
      } = {};
      (workerContext.user?.inspection?.checklistItems || []).forEach((p) => {
        if (p.item_type !== "checklist") return;
        const correct_answer = p.correct_answer === "no" ? "no" : "yes";
        checklistItemAnswers[p.id] = {
          item_type: p.item_type,
          option_response: correct_answer,
          id: p.id,
          correct_answer: correct_answer,
          require_photo_on_deficient:
            allowPhotoToChecklistItem && p.require_photo,
        };
      });
      return {
        ...prev,
        [id]: {
          ...prev[id],
          result: newRes,
          checklistItemAnswers: {
            ...prev[id].checklistItemAnswers,
            ...checklistItemAnswers,
          },
        },
      };
    });
  };
  const onBackClickInBetween = () => {
    const set = {
      modelOptionsMap: modelOptions,
      modelOptionsMapBackOrNextPress: "back" as const,
    };
    workerContext.updateUser((u) => (u ? { ...u, ...set } : set));
    onBack?.();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => onEnter()}
      onBackButtonClick={() => onBackClickInBetween()}
      nextButtonText={langStrings.strings.completeInspections}
      nextButtonDisabled={
        Object.values(modelOptions).filter((p) => p.result).length === 0 ||
        Object.values(modelOptions).filter((p) => {
          const completedAnswers = getFullyCompletedChecklistitemAnswers(
            p.checklistItemAnswers,
          );
          return (
            (p.images.length > 0 && !p.result) ||
            (p.result &&
              (completedAnswers.length !== allChecklistItems.length ||
                (p.result === "fail" &&
                  completedAnswers.findIndex(
                    (c) =>
                      c.correct_answer !== c.option_response &&
                      c.option_response !== "na",
                  ) === -1))) ||
            (p.result && imagesRequired && p.images.length === 0)
          );
        }).length > 0
      }
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.performMultipleInspections}
      >
        {showImageModalForId && (
          <Modal
            className="z-0"
            destroyOnClose
            open={!!showImageModalForId}
            confirmLoading={loading}
            onCancel={() => setShowImageModalForId(undefined)}
            footer={() => <></>}
            title={` Model/ID# ${modelOptions[showImageModalForId]?.modelNumber}: Add Image(s)`}
          >
            <div className="h-[70vh]">
              <SiteFeatureStepsUIImages
                nextButtonText="OK"
                initiallyCameraOpen={
                  (modelOptions[showImageModalForId]?.images || []).length === 0
                }
                initiallyDirectlyAdd={
                  (modelOptions[showImageModalForId]?.images || []).length === 0
                }
                images={modelOptions[showImageModalForId]?.images ?? []}
                loading={loading}
                required={
                  workerContext.user?.inspection?.image_required || false
                }
                onNext={async (images) =>
                  onUploadImagesForSelectedModelNumber(images)
                }
              />
            </div>
          </Modal>
        )}
        <Button
          onClick={() => setShowItems((i) => !i)}
          className="hover:text-interactive-primary"
        >
          {showItems ? (
            <div className="flex items-center justify-center gap-0.5">
              <EyeInvisibleOutlined /> {langStrings.strings.hideChecklistItems}{" "}
              <DownOutlined />
            </div>
          ) : (
            <div className="flex items-center justify-center gap-0.5">
              <EyeOutlined /> {langStrings.strings.seeChecklistItems}{" "}
              <RightOutlined />
            </div>
          )}
        </Button>
        {showItems && (
          <>
            <div className="font-accent">
              Inspect each item with the following checklist{" "}
            </div>
            <SiteInspectionWorkerStepChecklistItemListUI
              {...{
                checklistItemTypeMap,
                showEntryInputs: false,
                deficientTypeOptions,
                setChecklistItemAnswer: () => {},
              }}
            />
          </>
        )}
        {Object.values(modelOptions).map((modelVals, i) => {
          const {
            result,
            editable,
            images,
            id,
            checklistItemAnswers,
            modelNumber,
            done,
          } = modelVals;
          const completedAnswers =
            getFullyCompletedChecklistitemAnswers(checklistItemAnswers);
          const noFailedFound =
            Object.values(checklistItemAnswers).findIndex(
              (c) =>
                c.item_type === "checklist" &&
                c.option_response !== "na" &&
                c.correct_answer !== c.option_response,
            ) === -1;
          console.log(noFailedFound);
          const setChecklistItemAnswer: SiteInspectionWorkerStepChecklistItemListUIProps["setChecklistItemAnswer"] =
            (changes, { id: itemId, item_type }) =>
              setModelOptions((prev) => {
                const prevVal = prev[id];
                return {
                  ...prev,
                  [id]: {
                    ...prevVal,
                    checklistItemAnswers: {
                      ...prevVal.checklistItemAnswers,
                      [itemId]: prevVal.checklistItemAnswers[itemId]
                        ? {
                            ...prevVal.checklistItemAnswers[itemId],
                            item_type,
                            ...changes,
                          }
                        : { id: itemId, item_type, ...changes },
                    },
                  },
                };
              });
          return (
            <div key={id}>
              <div className="flex justify-between">
                <div className="flex">
                  ID # &nbsp;
                  {editable ? (
                    <EnterInputStringWithDoneAndCancel
                      placeholder={langStrings.strings.enterModelOrIdNumber}
                      savedVal={modelNumber}
                      openFailedInspect={result === "fail" && !done}
                      onCancel={() => {
                        setModelOptions((prev) => {
                          const newMap = { ...prev };
                          delete newMap[id];
                          return { ...newMap };
                        });
                      }}
                      onDone={(val) => {
                        setModelOptions((prev) => ({
                          ...prev,
                          [id]: { ...prev[id], modelNumber: val },
                        }));
                      }}
                    />
                  ) : (
                    <span
                      className={
                        result === "fail" && !done ? "font-accent" : ""
                      }
                    >
                      {modelNumber}
                    </span>
                  )}
                  {completedAnswers.length === allChecklistItems.length &&
                    ((result === "fail" && !noFailedFound) ||
                      (result === "pass" && otherTypeChecklistItemPresent)) && (
                      <div className="cursor-pointer mx-0.5">
                        <Icon
                          icon={
                            done ? (
                              <MaterialSymbol icon="visibility" />
                            ) : (
                              <MaterialSymbol icon="visibility_off" />
                            )
                          }
                          onClick={() => {
                            setModelOptions((prev) => ({
                              ...prev,
                              [id]: { ...prev[id], done: !done },
                            }));
                          }}
                        />
                      </div>
                    )}
                </div>
                {!!modelNumber && (
                  <div className="flex gap-0.125">
                    {(!imagesRequired || images.length > 0 || result) && (
                      <>
                        <button
                          className={clsx(
                            `px-1 py-0.5 border-0 rounded-l-0.25`,
                            !result && images.length > 0
                              ? "bg-white text-grey border-0.125 border-semantic-negative"
                              : result === "fail"
                              ? `bg-semantic-negative text-white border-0.125 border-semantic-negative`
                              : "bg-white text-grey border-0.125 border-suplementary-2",
                          )}
                          onClick={() => onFailSelect(id)}
                        >
                          {langStrings.strings.fail}
                        </button>
                        <button
                          className={clsx(
                            `px-1 py-0.5 border-0 rounded-r-0.25`,
                            !result && images.length > 0
                              ? "bg-white text-grey border-0.125 border-semantic-positive-green"
                              : result === "pass"
                              ? `bg-semantic-positive-green text-white border-0.125 border-semantic-positive-green`
                              : "bg-white text-grey border-0.125 border-suplementary-2",
                          )}
                          onClick={() => onPassSelect(id)}
                        >
                          {langStrings.strings.pass}
                        </button>
                      </>
                    )}
                    <Button
                      className={clsx(
                        "px-0.25 border-0.125 pb-0 text-center",
                        imagesRequired || images.length
                          ? "text-interactive-primary border-interactive-primary"
                          : "border-suplementary-2 text-suplementary-2",
                      )}
                      onClick={() => setShowImageModalForId(id)}
                      icon={<CameraOutlined />}
                    >
                      {images.length
                        ? `${images.length} `
                        : imagesRequired
                        ? langStrings.strings.inspect
                        : ""}
                    </Button>
                  </div>
                )}
              </div>
              {(result === "fail" ||
                (result === "pass" && otherTypeChecklistItemPresent)) &&
                !done && (
                  <div className="border-0.125 border-suplementary-1 m-0.125 p-0.5">
                    <SiteInspectionWorkerStepChecklistItemListUI
                      {...{
                        checklistItemTypeMap:
                          result === "pass"
                            ? onlyOtherTypeChecklistItemTypeMap
                            : checklistItemTypeMap,
                        showEntryInputs: true,
                        setChecklistItemAnswer,
                        deficientTypeOptions,
                        checklistItemAnswers,
                      }}
                    />
                    {completedAnswers.length === allChecklistItems.length &&
                      result === "fail" &&
                      noFailedFound && (
                        <div className="mb-0.5 text-semantic-negative">
                          {
                            langStrings.strings
                              .allMarkedGoodChangeResultToPassOrMarkDeficient
                          }
                        </div>
                      )}
                    <div className="flex justify-center items-center">
                      <Button
                        type="default"
                        disabled={
                          completedAnswers.length !==
                            allChecklistItems.length ||
                          (result === "fail" && noFailedFound)
                        }
                        onClick={() => {
                          setModelOptions((prev) => ({
                            ...prev,
                            [id]: { ...prev[id], done: true },
                          }));
                        }}
                      >
                        {langStrings.strings.done}
                      </Button>
                    </div>
                  </div>
                )}
            </div>
          );
        })}
        {Object.values(modelOptions).filter((p) => p.id && !p.modelNumber)
          .length === 0 && (
          <Button
            className="mb-1 text-interactive-primary border-0.125 border-interactive-primary"
            onClick={() => {
              setModelOptions((prev) => {
                const id = uuid.v4();
                return {
                  ...prev,
                  [id]: {
                    result: undefined,
                    modelNumber: "",
                    id,
                    editable: true,
                    images: [],
                    checklistItemAnswers: {},
                  },
                };
              });
            }}
          >
            {Object.values(modelOptions).length === 0
              ? langStrings.strings.addTheFirstIDModelNumberToStart
              : langStrings.strings.addAnotherIdModelNumber}{" "}
          </Button>
        )}
        <Space size={"middle"} />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};
export default SiteInspectionWorkerStepsMultiModelCheck;
