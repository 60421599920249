import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import withProjectIdUrlParam from "../../../../../../../../common/hoc/withProjectIdUrlParam";
import EmailProjectUserSelect from "src/common/components/EmailProjectUserSelect";
import { Card } from "antd";

const GCProjectSettingsIncidents: React.FC<ProjectIdProps> = ({
  projectId,
}) => {
  return (
    <div>
      <Card title="Incident Management">
        <EmailProjectUserSelect
          //key="incident_reporting"
          type="incident_reporting"
          // title="Incident Management"
          description="Select project team members who should be copied on all incident reports"
          extraProjectEmpFilter={{
            _or: [
              { direct_project_assign: { _eq: true } },
              { direct_project_assign: { _is_null: true } },
            ],
          }}
          projectId={projectId}
        />
      </Card>
    </div>
  );
};

export default withProjectIdUrlParam(GCProjectSettingsIncidents);
