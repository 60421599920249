import { TableProps } from "antd/es/table";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { GraphQLTaggedNode } from "react-relay";
import {
  fetchQuery,
  useLazyLoadQuery,
  usePaginationFragment,
  useRefetchableFragment,
  useRelayEnvironment,
} from "react-relay/hooks";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import getConnectionNodes, {
  Connection,
  GetConnectionNode,
} from "src/common/functions/getConnectionNodes";
import { Diff } from "utility-types";
import withCustomSuspense from "../../general/withCustomSuspense";
import ScrollTable, {
  FilterData,
  FilterSorterData,
  PkType,
  ScrollTableColumn,
  ScrollTableProps,
  SortOrder,
} from "./ScrollTable";

import { isDevelopmentBuild } from "../../../constants/environment";
import buildGraphQLFilter from "./utils/graphql-filter-functions/buildGraphqlFilter";
import buildGraphQLSorter from "./utils/graphql-filter-functions/buildGraphQLSorter";
import buildGraphQLSearchFilter from "./utils/graphql-filter-functions/buildGraphQLSearchFilter";
import { JSONObject } from "src/common/types/manual/JsonType";
import { DataRecord } from "./utils/dataScrollTableTypes";
import arrayFilterData from "./utils/client-filter-functions/arrayFilterData";
import arraySortData from "./utils/client-filter-functions/arraySortData";
import arrayFilterSearchData from "./utils/client-filter-functions/arrayFilterSearchData";
import LargeTableSkeleton from "./LargeTableSkeletion";

type AnyQuery<R = any, V = any> = {
  readonly response: R;
  readonly variables: V;
};

export type DataScrollTableRef = {
  refetch: (silent?: boolean) => void;
};

// GrapthQL filter state builders -----------------------

// GraphQL sort order builder  ---------------------------------

// ------------------------

//---  helper functions to apply filtering on array data (client side filtering)
//  consider moving it to separate file

// getFieldValue returns nested field value, for example
//    GetFieldValue({ report: { name: "SafityReport" }}, ["report", "name"])
// returns "SafityReport"

type LazyLoadState = {
  data: ReturnType<typeof useLazyLoadQuery>;
  loading: boolean;
  error?: any;
  variables?: string;
};

type RelayDataLoadHook = (...a: Parameters<typeof useLazyLoadQuery>) => {
  data: ReturnType<typeof useLazyLoadQuery>;
  loading: boolean;
  error?: any;
};

const useLazyLoadWithoutSuspense: RelayDataLoadHook = (
  query,
  variables,
  config,
) => {
  const stateRef = useRef<LazyLoadState>({ data: null, loading: false });
  const environment = useRelayEnvironment();
  const [_, forceUpdate] = useState(0);

  let vars = JSON.stringify(variables);
  const state = stateRef.current;
  if (state.variables != vars) {
    state.variables = vars;
    state.loading = true;
    fetchQuery(environment, query, variables).subscribe({
      error: (error: any) => {
        state.loading = false;
        state.error = error;
        forceUpdate((v) => v + 1);
      },
      next: (payload) => {
        state.data = payload;
        state.loading = false;
        state.error = undefined;
        forceUpdate((v) => v + 1);
      },
    });
  }
  return { data: state.data, loading: state.loading, error: state.error };
};
const useLazyLoadWithSuspense: RelayDataLoadHook = (
  query,
  variables,
  config,
) => ({
  data: useLazyLoadQuery<any>(query, variables, config),
  loading: false,
});

const getLoadHook = (disableSuspense: boolean) =>
  disableSuspense ? useLazyLoadWithoutSuspense : useLazyLoadWithSuspense;

interface BaseDataScrollTableProps<
  Conn extends Connection<N>,
  ColumnKeys extends string,
  Query extends AnyQuery,
  N = any,
> {
  ref: any;
  title?: string;
  explainerText?: string;
  queryNode: GraphQLTaggedNode;
  totalCountNode: GraphQLTaggedNode;
  countFromTotalCountNode?: boolean; // useful to show total Count node in a summary table, e.g. InspectionResultSummaryTable
  paginationNode: GraphQLTaggedNode;
  connectionName: string;
  // totalCountConnectionName: keyof Query['response'];
  customFilters?: CustomFilterType[];
  headerComponent?: React.ReactElement;
  totalCountConnectionName: string;
  //  setCurrentTypePermit?: any;
  //currentTypePermit?: string;
  where: Query["variables"]["where"];
  extraQueryVariables?: Partial<Query["variables"]>;
  excludedKeys?: Array<ColumnKeys>;
  loadAll?: boolean;
  defaultTableSort?: {
    key: ColumnKeys;
    order: SortOrder;
  };
  headerContol?: () => React.ReactNode;
  onRowClick?: (item: GetConnectionNode<Conn, N>) => void;
  columns: Array<
    ScrollTableColumn<GetConnectionNode<Conn, N>, ColumnKeys> & {
      queryIncludeVarKey?: keyof Query["variables"];
    }
  >;
  disableSuspense?: boolean;

  hideCount?: boolean;
}

export type DataScrollTableProps<
  Conn extends Connection<N>,
  ColumnKeys extends string,
  Query extends AnyQuery,
  N = any,
> = BaseDataScrollTableProps<Conn, ColumnKeys, Query, N> &
  Diff<TableProps<N>, BaseDataScrollTableProps<Conn, ColumnKeys, Query, N>> &
  Diff<
    Omit<ScrollTableProps<any>, "dataSource" | "onChange" | "totalCount">,
    BaseDataScrollTableProps<Conn, ColumnKeys, Query, N>
  > & {
    showRowBorderOnHover?: boolean;
    extraSearchDataIndexes?: Array<
      Array<{
        index: string;
        extraConstraint?: JSONObject; // remember extraConstraint is only for array relation indexes
      }>
    >;
    newCustomTableLook?: boolean; // TODO - ask Seva to remove this. This option is to control whether to display the new table design or now
  };

export interface DataScrollTableImplementorProps<
  C extends Connection<N>,
  K extends string,
  Q extends AnyQuery<N, V>,
  R extends any,
  N = any,
  V = any,
> extends Pick<
    DataScrollTableProps<C, K, Q, N>,
    | "excludedKeys"
    | "loadAll"
    | "customFilters"
    | "headerComponent"
    | "datePickerDataTitle"
    | "datePickerDataIndex"
    | "where"
    | "defaultTableSort"
    | "title"
    | "searchDataIndex"
    | "headerContol"
    | "headerComponent"
    | "disableSuspense"
    | "topBarButtons"
  > {
  onRowClick?: (args: R) => void;
}

type DataTableConnection<Node extends PkType> = Connection<Node>;

const DataScrollTable = <
  Conn extends Connection<N>,
  ColumnKeys extends string,
  Query extends AnyQuery,
  N = any,
>(
  {
    where,
    columns,
    title,
    explainerText,
    extraSearchDataIndexes,
    queryNode,
    paginationNode,
    connectionName,
    extraQueryVariables,
    totalCountNode,
    totalCountConnectionName,
    onRowClick,
    defaultTableSort,
    loadAll = false,
    disableSuspense = false,
    hideFilter = false,
    hideCount = false,
    ...props
  }: DataScrollTableProps<Conn, ColumnKeys, Query>,
  ref: any,
) => {
  const defaultSortCol = columns.find(
    (c) => !!c.defaultSortOrder && c.dataIndex.at(0),
  )!;
  const [filterSortersData, setFilterSorterData] = useState<FilterSorterData>({
    filterData: columns
      .filter(
        (column) =>
          column.filters?.defaultValues &&
          column.filters?.type === "checklist" &&
          column.filters.dataIndex,
      )
      .map((column) => {
        return {
          key: column.key as string,
          dataIndex: column.filters!.dataIndex,
          filter: { type: "in_list", values: column.filters!.defaultValues! },
        };
      }),
    sorterData: defaultSortCol
      ? {
          key: defaultSortCol.key,
          dataIndex: defaultSortCol.dataIndex,
          order: defaultSortCol.defaultSortOrder || null,
        }
      : undefined,
  });

  const firstDefaultSortColumn = columns.find((c) => !!c.defaultSortOrder);
  if (!firstDefaultSortColumn) {
    // why do we require this???
    throw new Error("Table must have at least 1 sortable column");
  }

  if (!firstDefaultSortColumn.sortable) {
    throw new Error("Default sort column must have sortable column");
  }
  const filters = [
    ...(filterSortersData?.filterData || []),
    ...columns
      .filter((c) => c.searchValue)
      .map((c) => ({
        key: "",
        dataIndex: c.dataIndex!,
        filter: {
          type: "search",
          text: c.searchValue!,
        },
      })),
  ] as FilterData;

  const [clientFiltering, setClientFiltering] = useState(loadAll);
  // if loadAll is true - fetch all without filter and perform client side filtering
  const whereStatement = clientFiltering
    ? where
    : {
        _and: [
          where,
          buildGraphQLFilter(filters),
          buildGraphQLSearchFilter(
            filterSortersData.searchData,
            extraSearchDataIndexes,
          ),
        ],
      };

  // and client side sorting
  const orderBy = buildGraphQLSorter(
    clientFiltering || !filterSortersData?.sorterData
      ? { key: "", dataIndex: firstDefaultSortColumn.dataIndex, order: "asc" }
      : filterSortersData?.sorterData,
  );
  const variables = {
    where: whereStatement,
    first: clientFiltering
      ? ScrollTable.LOAD_ALL_COUNT
      : ScrollTable.INITIAL_COUNT,
    order_by: orderBy,
    ...columns.reduce((a, c) => {
      return !!c.queryIncludeVarKey
        ? {
            ...a,
            [c.queryIncludeVarKey]:
              a[c.queryIncludeVarKey] || !props.excludedKeys?.includes(c.key),
          }
        : a;
    }, {} as any),
    ...(extraQueryVariables ? extraQueryVariables : {}),
  };

  // if we want change disableSuspense, we must remount component.
  //   otherwise we will have conditional hook problem. (react-hooks/rules-of-hooks)
  // so we copy disableSuspense to state on first render, and use it from state
  const [noSuspense] = useState(disableSuspense || false);
  if (isDevelopmentBuild && noSuspense !== disableSuspense) {
    throw new Error(
      "disableSuspense must remain unchanged during component lifecycle. Remount compoenent using key if you need to change it",
    );
  }
  const useLazyLoadHook = getLoadHook(noSuspense);
  const { data: query, loading } = useLazyLoadHook(queryNode, variables, {
    fetchPolicy: "network-only",
  });

  const [totalData, refetchTotal] = useRefetchableFragment<any, any>(
    totalCountNode,
    query,
  );
  const { data, loadNext, isLoadingNext, hasNext, refetch } =
    usePaginationFragment<any, any>(paginationNode, query);

  useImperativeHandle<DataScrollTableRef, DataScrollTableRef>(ref, () => ({
    refetch: () => {
      refetch({}, { fetchPolicy: "network-only" });
      refetchTotal({}, { fetchPolicy: "network-only" });
    },
  }));
  const nodes: N[] =
    (data &&
      data[connectionName] &&
      getConnectionNodes(data[connectionName])) ??
    [];
  const totalItems = totalData?.[totalCountConnectionName]?.edges.length;
  let items = nodes as unknown as Array<DataRecord>;

  // replace this with better code (automatically switch to client mode on small datasets)
  if (!clientFiltering && filters.length === 0 && totalItems < 200) {
    //    setClientFiltering(true);
  }
  ///   -----------

  if (clientFiltering) {
    // perform client side filtering
    if (filters.length > 0) {
      items = arrayFilterData(items, filters);
    }
    // search filter
    if (filterSortersData?.searchData) {
      items = arrayFilterSearchData(items, filterSortersData.searchData);
    }
    // and sorting
    if (filterSortersData?.sorterData) {
      items = arraySortData(items, filterSortersData.sorterData);
    }
  }
  const itemsCount = props.countFromTotalCountNode //????
    ? totalItems ?? items.length
    : clientFiltering
    ? items.length
    : totalItems ?? items.length;

  return (
    <ScrollTable
      hideFilter={hideFilter}
      {...props}
      dataSource={items}
      headerContol={props.headerContol}
      headerComponent={props.headerComponent}
      totalCount={hideCount ? undefined : itemsCount}
      columns={columns}
      title={title || ""}
      explainerText={explainerText}
      loading={loading}
      filterSorter={filterSortersData}
      onFilterSorterChange={setFilterSorterData}
      interactive={!!onRowClick || props.showRowBorderOnHover}
      onRow={(item: any) => ({
        onClick: () => {
          if (onRowClick) onRowClick(item);
        },
      })}
      pagination={{
        hasNext,
        isLoadingNext,
        onLoadMoreClick: () => {
          loadNext(ScrollTable.FETCH_MORE_COUNT);
        },
      }}
    />
  );
};
// @ts-ignore
export default withCustomSuspense(forwardRef(DataScrollTable), {
  fallback: <LargeTableSkeleton />,
}) as typeof DataScrollTable;
