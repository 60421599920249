import { Checkbox, Descriptions, message, notification, Table } from "antd";

import Item from "antd/lib/descriptions/Item";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import Button from "src/common/components/general/button/Button";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Image from "src/common/components/general/images/Image";
import Popover from "src/common/components/general/Popover";
import { PendingTaskViewQuery } from "src/common/types/generated/relay/PendingTaskViewQuery.graphql";
import { PendingTaskViewMutation } from "src/common/types/generated/relay/PendingTaskViewMutation.graphql";
import viewAndEditJhaQuery from "src/common/api/relay/queries/ViewAndEditJha";
import { ViewAndEditJhaQuery } from "src/common/types/generated/relay/ViewAndEditJhaQuery.graphql";
import { NULL_ID } from "src/common/functions/nullId";
import ViewAndEditTask from "./ViewAndEditTask";
import ViewAndEditTaskType from "src/common/types/manual/ViewAndEditTaskType";
import useAuthUser from "src/common/hooks/useAuthUser";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { MaterialSymbol } from "react-material-symbols";

interface CustomHeaderProps {
  header: string;
  size?: "large" | "small";
}

export const CustomHeader: FC<CustomHeaderProps> = ({ header, size }) => (
  <p
    className={`pl-1.5 pt-1.5 mb-1 ${
      size === "large" ? "text-1.5" : "text-1"
    } font-accent`}
  >
    {header}
  </p>
);
const query = graphql`
  query PendingTaskViewQuery(
    $taskId: uuid!
    $loggedInUserId: uuid!
    $previousImageTaskWhere: task_bool_exp!
  ) {
    user_connection(where: { id: { _eq: $loggedInUserId } }) {
      edges {
        node {
          role
        }
      }
    }
    previousImageTask: task_connection(where: $previousImageTaskWhere) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          created_at
          created_by_uid
        }
      }
    }
    task_connection(where: { id: { _eq: $taskId } }) {
      edges {
        node {
          ...TaskFrag @relay(mask: false)
        }
      }
    }
  }
`;

const updateTaskStatusMutation = graphql`
  mutation PendingTaskViewMutation(
    $taskId: uuid!
    $_set: task_set_input!
    $deleteTaskWhere: task_bool_exp!
    $updateTaskEditWhere: task_edit_bool_exp!
    $taskEditObjects: [task_edit_insert_input!]!
    $taskSignObjects: [task_signature_insert_input!]!
  ) {
    update_task_edit(where: $updateTaskEditWhere, _set: { task_id: $taskId }) {
      affected_rows
    }
    update_task_by_pk(pk_columns: { id: $taskId }, _set: $_set) {
      id
    }
    insert_task_edit(objects: $taskEditObjects) {
      affected_rows
    }
    delete_task(where: $deleteTaskWhere) {
      affected_rows
    }
    insert_task_signature(objects: $taskSignObjects) {
      affected_rows
    }
  }
`;

const PendingTaskView: FC<{
  taskId: string;
  type: "gc-proj" | "sub-proj" | "sub-lib";
  requestId?: string | null;
  projectId?: string;
  viewOnly?: boolean;
}> = ({ taskId, type, requestId, projectId, viewOnly }) => {
  const authUser = useAuthUser();

  const data = useLazyLoadQuery<PendingTaskViewQuery>(query, {
    taskId,
    loggedInUserId: authUser.uid,
    previousImageTaskWhere: requestId
      ? {
          report_id: { _is_null: true },
          creation_request_id: { _eq: requestId },
          ...(projectId ? { project_id: { _eq: projectId } } : {}),
          task_creation_request: { type: { _eq: "images_for_one" } },
          show_type: { _eq: "images" },
        }
      : { id: { _is_null: true } },
  });

  const queryData = useLazyLoadQuery<ViewAndEditJhaQuery>(viewAndEditJhaQuery, {
    currentUserId: authUser.uid,
    emailRequestWhere: {},
    projectId: NULL_ID,
    subId: NULL_ID,
    taskId: taskId,
  });

  const taskData = queryData.task_connection.edges[0].node;
  const taskStepsData = queryData.task_step_connection.edges;

  const viewAndEditTaskData: ViewAndEditTaskType = {
    description: taskData.description.en,
    descriptionId: taskData.description_id,
    ecms: queryData.ecm_type_connection.edges.map((ecm) => ({
      id: ecm.node.pk,
      description: ecm.node.name?.original ?? "",
    })),
    id: taskId,
    permits: queryData.permit_type_connection.edges.map((permit) => ({
      id: permit.node.pk,
      description: permit.node.name.en,
    })),
    ppeCategories: queryData.ppe_category_connection.edges.map(
      (ppeCategory) => ({
        id: ppeCategory.node.id,
        description: ppeCategory.node.name.en,
        ppes: ppeCategory.node.ppe_types.map((ppe) => ({
          id: ppe.pk,
          description: ppe.name.en,
        })),
      }),
    ),
    steps: taskStepsData.map((step) => ({
      description: step.node.description.en,
      id: step.node.pk,
      hazards: step.node.task_step_hazards.map((haz) => ({
        id: haz.pk,
        description: haz.description.en,
        control: haz.control.en,
        ecms: haz.task_hazard_ecm_types
          ? haz.task_hazard_ecm_types.map((ecm) => ({
              id: ecm.ecm_type_id,
              description: ecm.ecm_type?.name?.en ?? "",
            }))
          : [],
      })),
    })),

    taskPpes: queryData.task_ppe_type_connection.edges.map((ppe) => ({
      id: ppe.node.pk,
      description: ppe.node.ppe_type.name.en,
    })),

    taskPermits: queryData.task_permit_type_connection.edges.map((permit) => ({
      id: permit.node.pk,
      description: permit.node.permit_type.name.en,
    })),

    queryData: queryData,
  };

  const previousImageTypeTask = data.previousImageTask.edges[0]
    ? data.previousImageTask.edges[0].node
    : undefined;

  const userRole = data.user_connection.edges[0].node.role;
  const [updateTask] = useAsyncMutation<PendingTaskViewMutation>(
    updateTaskStatusMutation,
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const task = data.task_connection.edges[0].node;
  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const [isNotTurnersProject, setIsNotTurnersProject] = useState(
    task.task_creation_request?.project?.general_contractor_id !== turnerGCId,
  );
  let dataSource: any = [];
  const text = task.task_creation_request ? (
    <>
      <div className="w-full bg-white ml-1.5">
        <Descriptions size="middle" bordered>
          {(task.task_creation_request.project ?? task.project) && (
            <>
              <Item label="Project Name">
                {task.task_creation_request.project?.name ?? task.project?.name}
              </Item>
              <Item label="General Contractor">
                {task.task_creation_request.project?.general_contractor.name ??
                  task.project?.general_contractor.name}
              </Item>
            </>
          )}
          <Item label="Subcontractor">
            {task.task_creation_request.subcontractor.name}
          </Item>
          <Item label="Date Requested">
            {!!task.task_creation_request.requested_at
              ? dayjs(task.task_creation_request.requested_at).format(
                  "MMM DD,YYYY h:mm A",
                )
              : ""}
          </Item>
          <Item label="Requested By">
            {task.task_creation_request
              ? task.task_creation_request.requested_by_user.name
              : ""}
          </Item>
          <Item label="Date Sent For Approval">
            {task.document && task.document.jha_creation_request_verified_at
              ? dayjs(task.document.jha_creation_request_verified_at).format(
                  "MMM DD,YYYY h:mm A",
                )
              : task.task_creation_request.attached_files[0] &&
                task.task_creation_request.attached_files[0]
                  .jha_creation_request_verified_at
              ? dayjs(
                  task.task_creation_request.attached_files[0]
                    .jha_creation_request_verified_at,
                ).format("MMM DD,YYYY h:mm A")
              : ""}
          </Item>
          {task.task_creation_request.users_requested_for_task_approval
            .length !== 0 && (
            <Item label="Sent to For Approval">
              {task.task_creation_request.users_requested_for_task_approval.map(
                (p, i) =>
                  p.user.name +
                  (i + 1 !==
                  task.task_creation_request?.users_requested_for_task_approval
                    .length
                    ? ", "
                    : ""),
              )}
            </Item>
          )}
          {task.task_creation_request.type === "images_for_one" && (
            <Item label="Images">
              <div className="flex gap-2 text-interactive-primary">
                {task.task_creation_request.attached_files.map((p, i) => (
                  <Popover
                    showOnClick
                    content={
                      <div className="max-h-32 overflow-y-auto gap-0.5 w-24">
                        <Image src={p.url} />
                      </div>
                    }
                  >
                    <div>{`Image ${i + 1}`}</div>
                  </Popover>
                ))}
              </div>
            </Item>
          )}
        </Descriptions>
      </div>
    </>
  ) : (
    <></>
  );
  dataSource = [
    {
      id: task.pk,
      key: task.pk,
      taskName: task.description.en,
      task_steps: task.task_steps?.length,
      task_ppe_types: task.task_ppe_types?.length,
      task_permit_types: task.task_permit_types?.length,
      task_steps_hazards: task.task_steps.reduce(
        (sum, h) => sum + h.task_step_hazards.length,
        0,
      ),
    },
  ];
  const columns: any = [
    {
      title: "JHA (Job Hazard Analysis)",
      key: "taskName",
      dataIndex: "taskName",
      width: "35%",
    },
    {
      title: "Steps",
      key: "task_steps",
      dataIndex: "task_steps",
    },
    {
      title: "Hazards",
      key: "task_steps_hazards",
      dataIndex: "task_steps_hazards",
      width: "18%",
    },
    {
      title: "Permits",
      key: "task_permit_types",
      dataIndex: "task_permit_types",
      width: "17%",
    },
    {
      title: "PPE",
      key: "task_ppe_types",
      dataIndex: "task_ppe_types",
    },
  ];

  const onAccept = async () => {
    setLoading(true);
    const time = previousImageTypeTask
      ? previousImageTypeTask.created_at
      : dayjs().toISOString();

    await updateTask({
      variables: {
        ...(previousImageTypeTask
          ? {
              deleteTaskWhere: { id: { _eq: previousImageTypeTask.pk } },
              updateTaskEditWhere: {
                task_id: { _eq: previousImageTypeTask.pk },
              },
            }
          : {
              deleteTaskWhere: { id: { _is_null: true } },
              updateTaskEditWhere: {
                id: { _is_null: true },
              },
            }),
        taskId,
        _set: {
          accepted_at_from_creation_request: dayjs().toISOString(),
          accepted_by_uid_from_creation_request: authUser.uid,
          is_pending_acceptance: false,
          project_id: task.task_creation_request?.project_id ?? task.project_id,
          subcontractor_id: task.task_creation_request?.subcontractor_id,
          created_by_uid: previousImageTypeTask?.created_by_uid ?? authUser.uid,
          created_at: time,
        },
        taskEditObjects:
          type === "sub-lib"
            ? []
            : [
                {
                  edit_type:
                    type === "gc-proj" ? "Accepted" : "Accepted and Approved",
                  edited_at: dayjs().toISOString(),
                  edited_by_uid: authUser.uid,
                  task_id: taskId,
                },
              ],
        taskSignObjects:
          type === "sub-lib" || type === "gc-proj"
            ? []
            : [
                {
                  created_at: dayjs().format(),
                  signature_image_id: null,
                  user_id: authUser.uid,
                  is_active: true,
                  task_id: taskId,
                },
              ],
      },
    })
      .catch((e) =>
        notification.error({
          message: "Error: Couldn't accept the JHA",
          description: e.message,
        }),
      )
      .then(() => {
        message.success("Added JHA to the Library");
        navigate(-1);
      });

    setLoading(false);
  };

  return (
    <>
      <div className="flex flex-row gap-1">
        <Button
          loading={loading}
          icon={<MaterialSymbol icon="arrow_left" />}
          onClick={() => {
            navigate(-1);
          }}
          secondary
          label="Back"
        />

        {!viewOnly && (
          <BPopconfirm
            title="Are you sure you want to accept this JHA"
            onConfirm={() => onAccept()}
          >
            <Button
              loading={loading}
              icon={<MaterialSymbol icon="check" />}
              label="Accept JHA"
              onClick={() => {}}
            />
          </BPopconfirm>
        )}
      </div>
      {type === "sub-lib" && (
        <Checkbox
          className="mt-1"
          value={isNotTurnersProject}
          onChange={(value) => setIsNotTurnersProject(!value.target.checked)}
        >
          Use Turner's Format
        </Checkbox>
      )}
      <CustomHeader header={`JHA: ${task.description.en}`} size="large" />
      {text}
      <div className="ml-1.5">
        <Table
          columns={columns}
          dataSource={dataSource}
          className="pt-2"
          bordered
          scroll={{ x: 50 * 10 }}
          size={"large"}
        />
      </div>

      <ViewAndEditTask
        data={viewAndEditTaskData}
        isNotTurnersProject={isNotTurnersProject}
        taskId={taskId}
        viewOnly={viewOnly}
        type="pending_jha"
      />
    </>
  );
};

export default withCustomSuspense(PendingTaskView);
