import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  isDevelopmentBuild,
  isDevelopmentEnvironment,
} from "../../../common/constants/environment";

const sentryInit = () => {
  if (isDevelopmentEnvironment || isDevelopmentBuild) return;
  // Sentry initialization
  Sentry.init({
    dsn: "https://5717e5f78ad2844eaaad9e1ff8e4ef0f@o1028742.ingest.sentry.io/4506559174344704",
    // DSNs are safe to keep public because they only allow submission of new events and related event data;
    // they do not allow read access to any information.
    integrations: [
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of React Router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      // new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [window.location.origin],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
};

export default sentryInit;
