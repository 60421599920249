import { Modal, Select } from "antd";
import clsx from "clsx";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  GetInspectionDataOutput,
  InspectionChecklistItem,
} from "src/common/types/generated/apollo/graphQLTypes";
import {
  ChecklistItemAnswerType,
  OptionResponseType,
} from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import TextAreaAutoSize from "react-textarea-autosize";
import useLangStrings, {
  useCurrentLanguage,
  useDBLangSelector,
} from "../../../../../../utility-features/i18n/context/languageHooks";
import SiteFeatureStepsUIImages, {
  SiteFeatureStepsUIImagesProps,
} from "src/common/components/general/images/SiteFeatureStepsUIImages";
import uploadImage from "src/common/functions/upload-utility/uploadImage";

interface SiteInspectionWorkerChecklistItemUIProps
  extends InspectionChecklistItem {
  deficientTypeOptions: GetInspectionDataOutput["deficientAnswerSelectTypes"];
  onTextChange: (text: string) => void;
  showEntryInputs?: boolean;
  hideIncorrect?: boolean;
  allowPhotoToChecklistItem: boolean;
  initialValue?: ChecklistItemAnswerType;
  onDeficientTypeSelect: (
    deficientTypeId: string | undefined,
    immediateFix: boolean,
  ) => void;
  onSelectAnswerChange: (val: string) => void;
  onOptionResponse: (res: OptionResponseType) => void;
  onImagesChange: (
    newAllImages: NonNullable<ChecklistItemAnswerType["images"]>,
  ) => void;
}

const SiteInspectionWorkerChecklistItemUI: FC<
  SiteInspectionWorkerChecklistItemUIProps
> = (props) => {
  const language = useCurrentLanguage();
  const gs = useDBLangSelector(language);
  const langStrings = useLangStrings();
  const [selectQuestionAnswer, setSelectQuestionAnswer] = useState(
    props.initialValue?.select_answer,
  );
  const selectOptions = useMemo(
    () => props.select_options?.map((opt) => ({ label: opt, value: opt })),
    [props.select_options],
  );
  const [selectedResponse, setSelectedResponse] = useState<
    OptionResponseType | undefined
  >(props.initialValue?.option_response);
  const [selectedDeficientType, setSelectedDeficientType] = useState(
    props.initialValue?.deficient_type_id ||
      (props.initialValue?.text_response ? "other" : ""),
  );
  const [otherReasonSelected, setOtherReasonSelected] = useState(
    !props.initialValue?.deficient_type_id &&
      !!props.initialValue?.option_response,
  );
  const [immediateFixSelected, setImmediateFixSelected] = useState(
    props.initialValue?.deficient_type_id &&
      !!props.deficientTypeOptions.find(
        (def) =>
          def.immediateFix && def.id === props.initialValue?.deficient_type_id,
      ),
  );
  const [wrongSelected, setWrongSelected] = useState(false);
  const [text, setText] = useState(props.initialValue?.text_response);
  const imgReq =
    props.allowPhotoToChecklistItem && wrongSelected && props.require_photo;

  useEffect(() => {
    if (selectedResponse)
      setWrongSelected(
        selectedResponse !== "na" && selectedResponse !== props.correct_answer,
      );
    else setWrongSelected(false);
  }, [selectedResponse, imgReq]);
  const currImages = props.initialValue?.images || [];
  const noImages = !currImages.length;
  useEffect(() => {
    if (wrongSelected && imgReq && noImages) {
      setShowImageModal(true);
    }
  }, [imgReq, wrongSelected, noImages]);
  const options = [
    {
      title: "Yes",
      type: "yes",
      selectedColor:
        props.correct_answer === "yes"
          ? "semantic-positive-green"
          : "semantic-negative",
    },
    {
      title: "No",
      type: "no",
      selectedColor:
        props.correct_answer === "no"
          ? "semantic-positive-green"
          : "semantic-negative",
    },
  ].filter((option) =>
    props.hideIncorrect ? option.type === props.correct_answer : true,
  ) as {
    title: string;
    type: OptionResponseType;
    selectedColor: string;
  }[];
  if (props.show_na)
    options.push({
      title: "N/A",
      type: "na",
      selectedColor: "interactive-primary",
    });
  const [loading, setLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const onUploadImagesForItem: SiteFeatureStepsUIImagesProps["onNext"] = async (
    images,
  ) => {
    if (!props.initialValue) {
      console.log("Item isn't initialized yet");
      return;
    }
    if (!showImageModal) return;
    const uploadedImages = (props.initialValue.images || []).filter(
      (p) => images.findIndex((img) => img.url && img.id === p.id) !== -1,
    );
    const uploadPromises = (images || [])
      .filter(
        (img) => !uploadedImages.find((u) => u.id === img.id) || img.edited,
      )
      .map(async (image) => {
        try {
          if (image.url) {
            const uploadedImg = await uploadImage(image.url);
            if (uploadedImg) {
              const replacingIndex = uploadedImages.findIndex(
                (img) => img.id === image.id,
              );
              if (replacingIndex === -1) {
                uploadedImages.push({
                  ...uploadedImg,
                  id: image.id,
                  edited: false,
                });
              } else {
                uploadedImages[replacingIndex] = {
                  ...uploadedImg,
                  id: image.id,
                  edited: false,
                };
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
    setLoading(true);
    await Promise.allSettled(uploadPromises)
      .then((results) => {
        results.map((result) => {
          console.log(result.status);
        });
      })
      .catch(console.error);
    props.onImagesChange(uploadedImages);
    setLoading(false);
    setShowImageModal(false);
  };
  const ImageButton: React.FC = () =>
    props.allowPhotoToChecklistItem &&
    props.initialValue && (
      <button
        key={"camera"}
        className="ml-1 text-1.2"
        onClick={() => setShowImageModal(true)}
      >
        📷 {currImages.length ? `(${currImages.length})` : ""}
        {imgReq && <span className="text-semantic-negative">*</span>}
      </button>
    );
  return (
    <div className="mb-2 mx-0.5">
      {props.allowPhotoToChecklistItem && !!props.initialValue && (
        <Modal
          className="z-0"
          destroyOnClose
          open={!!showImageModal}
          confirmLoading={loading}
          onCancel={() => setShowImageModal(false)}
          footer={() => null}
          title={`${langStrings.strings.checklistItem}: ${gs(
            props.description,
          )}`}
        >
          <div className="h-[70vh]">
            <SiteFeatureStepsUIImages
              nextButtonText="OK"
              initiallyCameraOpen={noImages}
              initiallyDirectlyAdd={noImages}
              images={currImages}
              loading={loading}
              required={imgReq}
              onNext={onUploadImagesForItem}
              label={langStrings.strings.takePhotoOfSpecificItem}
            />
          </div>
        </Modal>
      )}
      <div className="mb-1 text-1">
        {gs(props.description)}
        {(wrongSelected || props.item_type !== "checklist") && (
          <span className="text-semantic-negative">*</span>
        )}
      </div>
      {!props.showEntryInputs ? null : props.item_type === "text" ? (
        <TextAreaAutoSize
          className={`w-full rounded-0.5 border-px border-suplementary-3 flex-1 resize-none outline-none p-0.75 whitespace-pre-wrap break-word`}
          value={text}
          placeholder={"Type your response here"}
          onChange={(e) => {
            setText(e.target.value);
            props.onTextChange(e.target.value);
          }}
        />
      ) : props.item_type === "select_one" ? (
        <Select
          className="w-full"
          options={selectOptions}
          placeholder="Select one of the options"
          value={selectQuestionAnswer}
          onChange={(val) => {
            setSelectQuestionAnswer(val);
            props.onSelectAnswerChange(val);
          }}
        />
      ) : (
        <>
          <div className="flex flex-row items-center">
            {options.map(({ title, selectedColor, type }, i) => (
              <button
                key={type}
                className={clsx(
                  `px-1 py-0.5 border-0 mr-px`,
                  selectedResponse === type
                    ? `bg-${selectedColor} text-white border-0.125 border-${selectedColor}`
                    : "bg-white text-grey border-0.125 border-suplementary-2",
                  i === 0 ? "rounded-l-0.25 " : "",
                  i + 1 === options.length ? "rounded-r-0.25" : "",
                )}
                onClick={() => {
                  setSelectedResponse(type);
                  props.onOptionResponse(type);
                }}
              >
                {title}
              </button>
            ))}
            <ImageButton />
          </div>
          {/* Text Input */}
          {wrongSelected ? (
            <div className="mt-1">
              {langStrings.strings.whatActionDidYouTake}
              <Select
                className="w-full"
                value={selectedDeficientType}
                onSelect={(deficientType) => {
                  setSelectedDeficientType(deficientType);
                  if (deficientType === "other") {
                    setOtherReasonSelected(true);
                    setImmediateFixSelected(false);
                    props.onDeficientTypeSelect(undefined, false);
                  } else {
                    const immediateFix =
                      props.deficientTypeOptions.findIndex(
                        (def) => def.immediateFix && def.id === deficientType,
                      ) > -1;
                    props.onDeficientTypeSelect(deficientType, immediateFix);
                    setImmediateFixSelected(immediateFix);
                    setOtherReasonSelected(false);
                  }
                }}
              >
                {props.deficientTypeOptions.map((deficientType) => (
                  <Select.Option
                    value={deficientType.id}
                    key={deficientType.id}
                  >
                    {gs(deficientType.name, deficientType.name.en)}
                  </Select.Option>
                ))}
                <Select.Option value={"other"} key={"other"}>
                  {langStrings.strings.other}
                </Select.Option>
              </Select>
              {selectedDeficientType && (
                <div className="pt-1 ml-1">
                  {otherReasonSelected || immediateFixSelected ? (
                    <div>
                      {langStrings.strings.whatActionDidYouTake}{" "}
                      <span className="text-semantic-negative">*</span>
                    </div>
                  ) : (
                    langStrings.strings.additionalInformation
                  )}

                  <div className="flex items-center">
                    <TextAreaAutoSize
                      className={`rounded-0.5 border-px border-suplementary-3 flex-1 resize-none outline-none p-0.75 whitespace-pre-wrap break-word`}
                      value={text}
                      placeholder={
                        otherReasonSelected || immediateFixSelected
                          ? langStrings.strings.whatDidYouDoToMakeSafe
                          : langStrings.strings.TypeAnyAdditionalInformationHere
                      }
                      onChange={(e) => {
                        setText(e.target.value);
                        props.onTextChange(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
export default SiteInspectionWorkerChecklistItemUI;
