import { MaterialSymbol } from "react-material-symbols";
import {
  Alert,
  Button as AntdButton,
  Input,
  Menu,
  MenuProps,
  message,
  notification,
  Popover,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, forwardRef, useEffect, useRef, useState } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { commitMutation } from "relay-runtime";
import OnSiteTag from "src/common/components/OnSiteTag";
import { getLatestDate } from "src/common/components/Project";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import Icon from "src/common/components/general/Icon";

import { OrientationProjectWorkerTable_project_worker$data } from "src/common/types/generated/relay/OrientationProjectWorkerTable_project_worker.graphql";
import OrientationDataScrollTable, {
  OrientationDataScrollTableImplementorProps,
  OrientationDataScrollTableProps,
  OrientationDataScrollTableRef,
} from "./OrientationDataScrollTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import capitalize from "src/common/functions/capitalize";
import { auth } from "src/common/functions/firebase";
import isNullOrUndefined from "src/common/functions/isNullOrUndefined";
import { useGcOrientationDataQuery$data } from "src/common/types/generated/relay/useGcOrientationDataQuery.graphql";
import { OrientationProjectWorkerTableQuery } from "src/common/types/generated/relay/OrientationProjectWorkerTableQuery.graphql";
import { OrientationProjectWorkerTable_ArchiveWorkerMutation } from "src/common/types/generated/relay/OrientationProjectWorkerTable_ArchiveWorkerMutation.graphql";
import {
  getCertificateExpiryValue,
  getCertificateExpiryValueFromDate,
} from "src/common/functions/getCertificateExpiryValue";
import PartialResultContent from "./PartialResultContent";
import { Certificate } from "src/root/routes/views/subcontractor/people/workers/SCWorkersUI";
import ProcessingCertCard from "src/common/components/workerCertificationCard/ProcessingCertCard";
import Tag from "src/common/components/general/Tag";
import SelectHowToSendOrientationInvite from "src/root/routes/views/subcontractor/people/workers/components/SelectHowToSendOrientationInvite";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import { CaretUpFilled, WarningFilled } from "@ant-design/icons";
import { useDeleteWorkerMutation } from "src/common/types/generated/apollo/graphQLTypes";
import getOrientationCompleted from "../../../utils/getOrientationCompleted";
import QuizResult, { QuizResultProps } from "./QuizResults";
import CertificationCard from "src/common/components/workerCertificationCard/CertificationCard";

export type ColumnKeys =
  | "name"
  | "verified"
  | "subcontractor"
  | "hardHatNumber"
  | "title"
  | "role"
  | "orientatedOn"
  | "orientated_at"
  | "project"
  | "registeredDate"
  | "inPersonOrientatedDate"
  | "extra"
  | "status"
  | "archivedAt"
  | "drugTest"
  | "drugtestDate"
  | "universal_orientated_at"
  | "invite"
  | "trainingAndCertifications";

const CONNECTION_NAME = "project_worker_connection";
export type DConnection =
  OrientationProjectWorkerTable_project_worker$data[typeof CONNECTION_NAME];

type Props = OrientationDataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  OrientationProjectWorkerTableQuery,
  { pk: string }
> & {
  projectId: string;
  requiredOrientationIds: Array<string>;
  searchString?: string;
  extraColumns?: OrientationDataScrollTableProps<
    DConnection,
    ColumnKeys,
    OrientationProjectWorkerTableQuery
  >["columns"];
  markDeleted?: boolean;
  customFilters?: CustomFilterType[];
  headerComponent?: React.ReactElement;
  datePickerDataTitle?: string;
  datePickerDataIndex?: string[];
  showOrientationTag?: boolean;
  showArchiveButton?: boolean;
  showOnsiteTag?: boolean;
  startTime?: string;
  hasUniversal: boolean;
  startDate?: string;
  onAddHH?: (user_id: string) => void;
  nextHH?: number;
  subs?: useGcOrientationDataQuery$data["project_connection"]["edges"][0]["node"]["project_subcontractors"];
  titles?: useGcOrientationDataQuery$data["worker_title_connection"]["edges"];
  roles?: useGcOrientationDataQuery$data["worker_role_connection"]["edges"];
  backgroundTracker?: boolean;
  showSubAdminTag?: boolean;
  hasInPerson: boolean;
  hasSlides: boolean;
  assignHardHat: boolean;
  allowEditHH: boolean;
  defaultSortColumnKey?: ColumnKeys;
};

const saveHHMutation2 = graphql`
  mutation OrientationProjectWorkerTable_SaveHHMutation(
    $projectUserId: uuid!
    $projectId: uuid!
    $userId: uuid!
    $hardHat: String!
  ) {
    update_project_worker_by_pk(
      pk_columns: { id: $projectUserId }
      _set: { hard_hat_number: $hardHat }
    ) {
      pk: id @__clientField(handle: "pk")
      hard_hat_number
    }
    update_project_worker(
      where: {
        project_id: { _eq: $projectId }
        worker_id: { _eq: $userId }
        id: { _neq: $projectUserId }
      }
      _set: { hard_hat_number: $hardHat }
    ) {
      affected_rows
    }
  }
`;
export const archiveWorkerMutation = graphql`
  mutation OrientationProjectWorkerTable_ArchiveWorkerMutation(
    $projectWorkerId: uuid!
    $_set: project_worker_set_input
  ) {
    update_project_worker_by_pk(
      pk_columns: { id: $projectWorkerId }
      _set: $_set
    ) {
      pk: id @__clientField(handle: "pk")
      archived_at
      archived_by_uid
      archived_by_user {
        name
        role
      }
    }
  }
`;

interface EditableHHCellProps {
  value?: string | null;
  record: DConnection["edges"][number]["node"];
  projectId: string;
  initiallyOpen: boolean;
}

const EditableHHCell: FC<EditableHHCellProps> = ({
  value,
  record,
  projectId,
  ...props
}) => {
  const [editing, setEditing] = useState(false);
  // const [val, setVal] = useState(value);

  const editRef = useRef<React.ElementRef<typeof Input>>(null);
  const environment = useRelayEnvironment();

  useEffect(() => {
    if (editing) {
      if (editRef?.current?.input) {
        editRef.current.input.value = value ?? "";
      }
    }
  }, [editing]);

  const onSave = () => {
    const pk = record["pk"];
    const value = editRef?.current?.input?.value;
    if (!value?.trim?.()) {
      return;
    }
    commitMutation(environment, {
      mutation: saveHHMutation2,
      variables: {
        projectId,
        userId: record.user?.pk,
        projectUserId: record.pk,
        hardHat: value,
      },
      onCompleted: () => {
        setEditing(false);
      },
    });
  };
  const onCancel = () => {
    setEditing(false);
  };

  if (editing || props.initiallyOpen) {
    return (
      <Input
        ref={editRef}
        size="large"
        onBlur={onSave}
        onPressEnter={onSave}
        onKeyDown={(e) => {
          if (e.key === "Esc" || e.key === "Escape") {
            e.stopPropagation();
            e.preventDefault();
            setEditing(false);
          }
        }}
      />
    );
  }
  return (
    <div className="editable-cell-value-wrap" onClick={() => setEditing(true)}>
      {value}
    </div>
  );
};

export const returnVal = (valueA: any, valueB: any) =>
  !valueA || !valueB
    ? !valueA < !valueB
      ? 1
      : !valueA > !valueB
      ? -1
      : 0
    : valueA < valueB
    ? -1
    : valueA > valueB
    ? 1
    : 0;

// declare getSuffix to avoid depricated substr
function getSuffix(s: string, len: number) {
  return s.substring(Math.max(s.length - len, 0));
}

const buttonstyle = {
  borderRadius: "20px",
  fontSize: "14px",
  height: "auto",
  color: `white`,
};
const OrientationProjectWorkerTable = forwardRef<
  OrientationDataScrollTableRef,
  Props
>(
  (
    {
      title = "Workers",
      onRowClick,
      markDeleted = false,
      showOrientationTag = false,
      showArchiveButton = false,
      showSubAdminTag = false,
      showOnsiteTag = false,
      assignHardHat,
      searchString,
      hasInPerson,
      hasSlides,
      hasUniversal,
      backgroundTracker,
      requiredOrientationIds,
      datePickerDataTitle,
      datePickerDataIndex,
      nextHH,
      headerComponent,
      customFilters,
      startDate = dayjs().format("YYYY-MM-DD"),
      startTime = dayjs().startOf("d").toISOString(),
      extraColumns,
      where,
      projectId,
      allowEditHH,
      defaultSortColumnKey,
      roles,
      ...props
    },
    ref,
  ) => {
    const [archiveWorker, isArchiving] =
      useAsyncMutation<OrientationProjectWorkerTable_ArchiveWorkerMutation>(
        archiveWorkerMutation,
      );
    const [deleteWorker, { loading: deleting }] = useDeleteWorkerMutation();
    // const { count, increment } = useCounter();
    // useEffectOnce(() => {
    //   const interval = setInterval(increment, 5000);
    //   return () => {
    //     clearInterval(interval);
    //   };
    // });
    console.log(requiredOrientationIds);
    const noInpersonOrSlides = !(hasInPerson || hasSlides);
    const [selectedWorker, setSelectedWorker] = useState<
      DConnection["edges"][number]["node"]["user"] | undefined
    >(undefined);
    const certsToVerifyWhere = {
      verified_at: { _is_null: true },
      document: { _eq: "certificate" },
      status: { _eq: "submitted" },
    };
    return (
      <>
        {/* Orientation{count} */}
        {selectedWorker && (
          <SelectHowToSendOrientationInvite
            visible={!!selectedWorker}
            onClose={() => setSelectedWorker(undefined)}
            worker={{ ...selectedWorker, id: selectedWorker.pk }}
            projectId={projectId}
            hasSlides={hasSlides}
          />
        )}
        <OrientationDataScrollTable<
          DConnection,
          ColumnKeys,
          OrientationProjectWorkerTableQuery
        >
          {...props}
          newCustomTableLook
          defaultCustomInitialSort={true}
          ref={ref}
          title={title}
          onRowClick={
            onRowClick
              ? ({ pk }) => {
                  onRowClick({ pk });
                }
              : undefined
          }
          where={{ ...where }}
          customFilters={customFilters}
          headerComponent={headerComponent}
          extraQueryVariables={{
            includeOnSite: showOnsiteTag,
            startDate,
            certsToVerifyWhere,
            startTime,
            projectId,
          }}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allProjectWorkersConnection"}
          datePickerDataIndex={datePickerDataIndex}
          datePickerDataTitle={datePickerDataTitle}
          columns={[
            {
              title: "",
              key: "invite",
              size: "icon",
              dataIndex: ["user", "name"],
              render: (r, a) => {
                let orientationCompleted: boolean | null =
                  getOrientationCompleted(
                    a.user_orientation,
                    hasSlides,
                    hasInPerson,
                  );
                const noPhoneOrEmail = !(a.user!.email || a.user!.phone_number);
                return !orientationCompleted ? (
                  !a.user_orientation?.orientated_at && (
                    <Icon
                      icon={<MaterialSymbol icon="send" />}
                      hoverContent={{
                        enableOnClick: true,
                        title: "Send Project Orientation Invite",
                        content: noPhoneOrEmail
                          ? "No phone or email on file"
                          : "Phone number or email on file",
                      }}
                      color={noPhoneOrEmail ? "high-severity" : "interactive"}
                      onClick={() => setSelectedWorker(a.user)}
                    />
                  )
                ) : (
                  <></>
                );
              },
            },
            {
              title: "Status",
              key: "status",
              size: "md",
              defaultSortOrder:
                defaultSortColumnKey === undefined ||
                defaultSortColumnKey === "status"
                  ? "asc"
                  : undefined,
              sortable: true,
              dataIndex: ["user", "name"],
              clientCompareFn: (a, b) => {
                let orientationCompletedA: boolean | null =
                  getOrientationCompleted(
                    a.user_orientation,
                    hasSlides,
                    hasInPerson,
                  );
                const universalPresentAndCompletedA = hasUniversal
                  ? a.user!.universal_orientations.length > 0
                  : true;
                if (noInpersonOrSlides) {
                  orientationCompletedA = universalPresentAndCompletedA;
                } else {
                  orientationCompletedA =
                    orientationCompletedA && universalPresentAndCompletedA;
                }

                let orientationCompletedB: boolean | null =
                  getOrientationCompleted(
                    b.user_orientation,
                    hasSlides,
                    hasInPerson,
                  );
                const universalPresentAndCompletedB = hasUniversal
                  ? b.user!.universal_orientations.length > 0
                  : true;
                if (noInpersonOrSlides) {
                  orientationCompletedB = universalPresentAndCompletedB;
                } else {
                  orientationCompletedB =
                    orientationCompletedB && universalPresentAndCompletedB;
                }
                const valueA = orientationCompletedA
                  ? assignHardHat && a.hard_hat_number === null
                    ? 2
                    : 3
                  : a.archived_at
                  ? 4
                  : 1;
                const valueB = orientationCompletedB
                  ? assignHardHat && b.hard_hat_number === null
                    ? 2
                    : 3
                  : b.archived_at
                  ? 4
                  : 1;
                const orientDateA = orientationCompletedA
                  ? a.user_orientation?.completed_at ??
                    getLatestDate(
                      a.user_orientation?.in_person_orientated_at,
                      a.user_orientation?.orientated_at,
                    ) ??
                    (hasUniversal
                      ? a.user!.universal_orientations[0]
                          ?.universal_orientated_at
                      : undefined)
                  : undefined;
                const orientDateB = orientationCompletedB
                  ? b.user_orientation?.completed_at ??
                    getLatestDate(
                      b.user_orientation?.in_person_orientated_at,
                      b.user_orientation?.orientated_at,
                    ) ??
                    (hasUniversal
                      ? b.user?.universal_orientations[0]
                          ?.universal_orientated_at
                      : undefined)
                  : undefined;
                return !valueA || !valueB
                  ? !valueA < !valueB
                    ? 1
                    : !valueA > !valueB
                    ? -1
                    : valueA === 3 ||
                      valueB === 3 ||
                      valueA === 2 ||
                      valueB === 2
                    ? -returnVal(orientDateA, orientDateB)
                    : -returnVal(a.created_at, b.created_at)
                  : valueA < valueB
                  ? -1
                  : valueA > valueB
                  ? 1
                  : valueA === 3 || valueA === 2
                  ? -returnVal(orientDateA, orientDateB)
                  : -returnVal(a.created_at, b.created_at);
              },
              render: (r, a) => {
                if (!a.user) return null;
                const workerVerified = !!a.worker.verified_at;
                let orientationCompleted: boolean | null =
                  getOrientationCompleted(
                    a.user_orientation,
                    hasSlides,
                    hasInPerson,
                  );
                const universalPresentAndCompleted = hasUniversal
                  ? a.user.universal_orientations.length > 0
                  : true;
                const universalCompletionDate =
                  a.user.universal_orientations[0]?.universal_orientated_at;
                if (noInpersonOrSlides) {
                  orientationCompleted = universalPresentAndCompleted;
                } else {
                  orientationCompleted =
                    orientationCompleted && universalPresentAndCompleted;
                }
                const completedModules: Set<string> = new Set();
                const results = a.user_orientation
                  ? a.user_orientation.all_user_orientation_results.filter(
                      (p) => {
                        if (
                          p.orientation.type === "corporate" ||
                          p.project_id === projectId ||
                          (p.project &&
                            p.project.linked_orientation_projects.find(
                              (p) => p.pk === projectId,
                            ))
                        ) {
                          if (p.status === "completed" || p.completed_at)
                            completedModules.add(p.orientation_id);
                          return (
                            p.status === "completed" ||
                            p.completed_at ||
                            requiredOrientationIds.includes(p.orientation_id!)
                          );
                        }
                        return false;
                      },
                    )
                  : [];

                return (
                  <>
                    {orientationCompleted ? (
                      assignHardHat && a.hard_hat_number === null ? (
                        <AntdButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            props.onAddHH && props.onAddHH(a.pk);
                          }}
                          className="text-0.75 bg-white text-semantic-pending border-0.125 border-semantic-pending hover:text-semantic-pending hover:border-semantic-pending rounded-2 mb-0.25"
                        >
                          Add HH #
                        </AntdButton>
                      ) : (
                        <>
                          {completedModules.size > 0 ? (
                            <Popover
                              placement="right"
                              trigger="hover"
                              title="ORIENTATION COMPLETE"
                              content={
                                <PartialResultContent
                                  workerId={a.worker_id}
                                  orientationIds={Array.from(
                                    new Set(completedModules),
                                  )}
                                />
                              }
                            >
                              <div className="text-0.75 p-0.25 bg-white text-semantic-positive-green border-0.125 border-semantic-positive-green rounded-2 mb-0.25">
                                Orientated
                              </div>
                            </Popover>
                          ) : (
                            <div className="text-0.75 p-0.25 bg-white text-semantic-positive-green border-0.125 border-semantic-positive-green rounded-2 mb-0.25">
                              Orientated
                            </div>
                          )}
                        </>
                      )
                    ) : (
                      <Popover
                        placement="right"
                        trigger={"hover"}
                        title="ORIENTATION INCOMPLETE"
                        content={
                          <>
                            {hasUniversal &&
                              (universalCompletionDate ? (
                                <div className="text-semantic-positive-green text-1">
                                  Universal Orientation completed:{" "}
                                  {dayjs(universalCompletionDate).format(
                                    "MM/DD/YYYY",
                                  )}
                                </div>
                              ) : (
                                <div className="text-semantic-negative text-1">
                                  Universal Orientation not completed
                                </div>
                              ))}
                            <PartialResultContent
                              notCompleted
                              workerId={a.worker_id}
                              orientationIds={[
                                ...Array.from(
                                  new Set(results.map((p) => p.orientation_id)),
                                ),
                                ...requiredOrientationIds,
                              ]}
                            />
                          </>
                        }
                      >
                        <div className="text-0.75 p-0.25 bg-white text-semantic-negative border-0.125 border-semantic-negative rounded-2 mb-0.25">
                          Not Orientated
                        </div>
                      </Popover>
                    )}
                    {!orientationCompleted && showOrientationTag && (
                      <div>
                        {/* {a.worker?.worker_projects[0].archived_at && (
                            <div className="text-0.75 p-0.25 bg-white text-warn border-0.125 border-warn rounded-2 mb-0.25">
                              Archived
                            </div>
                          )} */}
                        {a.user_orientation?.in_person_orientated_at && (
                          <Tag status="positive">✅ InPerson</Tag>
                        )}

                        {a.user_orientation &&
                        // (
                        a.user_orientation.orientated_at ? (
                          // ||
                          // element.user_orientation.site_specific_results.some(
                          //   (or) => or.status === "completed"
                          // ))

                          <Tag status="positive">✅ Online</Tag>
                        ) : (
                          results.length > 0 &&
                          !a.user_orientation?.in_person_orientated_at && (
                            <Tag status="incomplete">INCOMPLETE</Tag>
                          )
                        )}
                      </div>
                    )}

                    {workerVerified && (
                      <div>
                        <Tag status="positive">✅ Verified</Tag>
                      </div>
                    )}
                  </>
                );
              },
            },
            {
              title: "Verified",
              key: "verified",
              dataIndex: ["worker", "verified_at"],
              render: (value) => {
                const date = dayjs(value);
                return date.isValid() ? date.format("MM/DD/YYYY") : "";
              },
            },
            {
              title: "Name",
              key: "name",
              size: "md",
              sortable: true,
              contentType: {
                type: "row-title",
              },
              // clientCompareFn: (a, b) => returnVal(a.user!.name, b.user?.name),
              // filterCreater: (obj) => ({ user: { name: obj } }),
              defaultSortOrder:
                defaultSortColumnKey === undefined ||
                defaultSortColumnKey === "name"
                  ? "asc"
                  : undefined,
              dataIndex: ["user", "name"],
              searchDataIndex: ["user", "name"],

              render: (_, a) => {
                let orientationCompleted = getOrientationCompleted(
                  a.user_orientation,
                  hasSlides,
                  hasInPerson,
                );
                const universalPresentAndCompleted = hasUniversal
                  ? a.user!.universal_orientations.length > 0
                  : true;
                if (noInpersonOrSlides) {
                  orientationCompleted = universalPresentAndCompleted;
                } else {
                  orientationCompleted =
                    orientationCompleted && universalPresentAndCompleted;
                }
                if (!(hasSlides || hasInPerson) && !hasUniversal)
                  orientationCompleted = true;
                const onsite = !!a.project_workers_aggregate?.aggregate?.count;
                const failed =
                  a.user_orientation?.background_check_done !== true;
                if (!a.worker) return null;

                return (
                  <div className="flex">
                    {a.user!.name}
                    {showOnsiteTag && onsite && a.worker && (
                      <OnSiteTag
                        {...{
                          orientationCompleted: orientationCompleted ?? false,
                        }}
                      />
                    )}
                    {backgroundTracker && failed && (
                      <CaretUpFilled
                        style={{
                          color: "red",
                          marginLeft: "6px",
                          marginTop: "2px",
                        }}
                        className="custom-icon"
                      />
                    )}
                  </div>
                );
              },
            },
            // {
            //   title: "Project",
            //   key: "project",
            //   size: "md",
            //   dataIndex: ["worker", "worker_projects"],
            //   searchDataIndex: ["worker", "worker_projects", "project", "name"],
            //   includeInMainSearchBox: false,
            //   render: (a, r) => {
            //     if (r.worker) return r.worker.worker_projects[0].project.name;
            //   },
            // },
            {
              title: "Company",
              key: "subcontractor",
              size: "md",
              sortable: true,
              dataIndex: ["subcontractor", "name"],
              searchValue: searchString,
              filterCreater: (obj) => ({ subcontractor_id: obj }),
              includeInMainSearchBox: false,
              ...(props.subs
                ? {
                    filters: {
                      type: "checklist",
                      initialClose: true,
                      dataIndex: ["subcontractor_id"],
                      options: props.subs.map((r) => ({
                        text: capitalize(r.subcontractor.name),
                        value: r.subcontractor_id,
                      })),
                    },
                  }
                : {}),
              // contentType: {
              //   type: "with-icon",
              //   renderOptions: (value) => ({ icon: <MaterialSymbol icon={"work"}/>, label: value }),
              // },
              render: (val, a) => (
                <div className="flex flex-row gap-1">
                  <Icon icon={<MaterialSymbol icon={"work"} />} />

                  <div className="flex flex-row">
                    {a.subcontractor.name}
                    {showSubAdminTag &&
                    a.user!.role === "subcontractor_employee" ? (
                      <Icon
                        icon={<MaterialSymbol icon="badge" />}
                        hoverContent={{
                          content: (
                            <div className="flex justify-center items-center">
                              <Icon icon={<MaterialSymbol icon="badge" />} />
                              Indicates an Administrator for the subcontractor
                              or
                              <br />
                              trade partner. These users can manage their
                              company's
                              <br />
                              JHAs, workers, and more on the SiteForm website.
                              <br />
                              Invite these users by selecting "Invite Sub Admin"
                              on
                              <br />
                              the "Subcontractors" page.
                            </div>
                          ),
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ),
            },
            {
              title: "Hard Hat #",
              key: "hardHatNumber",
              dataIndex: ["hard_hat_number"],
              size: "sm",
              searchDataIndex: ["hard_hat_number"],
              sortable: true,
              clientCompareFn: (a, b) => {
                const sa = getSuffix(
                  "0000000000" + a?.hard_hat_number || "",
                  10,
                );
                const sb = getSuffix(
                  "0000000000" + b?.hard_hat_number || "",
                  10,
                );

                return sa === sb ? 0 : sa > sb ? 1 : -1;
              },
              // filterCreater: (obj) => ({ hard_hat_number: obj }),
              render: (_, a) => {
                let orientationCompleted = getOrientationCompleted(
                  a.user_orientation,
                  hasSlides,
                  hasInPerson,
                );
                const universalPresentAndCompleted = hasUniversal
                  ? a.user!.universal_orientations.length > 0
                  : true;
                if (noInpersonOrSlides) {
                  orientationCompleted = universalPresentAndCompleted;
                } else {
                  orientationCompleted =
                    orientationCompleted && universalPresentAndCompleted;
                }
                if (!(hasSlides || hasInPerson) && !hasUniversal)
                  orientationCompleted = true;
                const hh = a.hard_hat_number;

                return orientationCompleted && allowEditHH && projectId ? (
                  <EditableHHCell
                    value={hh}
                    record={a}
                    projectId={projectId}
                    initiallyOpen={!hh?.trim?.()}
                  />
                ) : (
                  hh
                );
              },
            },
            {
              title: "Title",
              key: "title",
              sortable: true,
              size: "sm",
              dataIndex: ["title", "translation", "en"],
              // clientCompareFn: (a, b) => {
              //   return returnVal(
              //     a.title?.translation?.en,
              //     b.title?.translation?.en
              //   );
              // },
              filterCreater: (obj) => ({ title_id: obj }),
              includeInMainSearchBox: false,
              ...(props.titles
                ? {
                    filters: {
                      type: "checklist",
                      initialClose: true,
                      dataIndex: ["title_id"],
                      options: props.titles.map((r) => ({
                        text: capitalize(r.node.translation.en),
                        value: r.node.pk,
                      })),
                    },
                  }
                : {}),
              render: (a, r) => a,
            },
            {
              width: 150,
              title: "Role",
              key: "role",
              sortable: true,
              dataIndex: ["worker_role"],
              size: "ml",
              filters: {
                dataIndex: ["worker_role"],
                options: ["apprentice", "foreman", "journeyman"].map((r) => ({
                  text: capitalize(r),
                  value: r,
                })),
              },
              // clientCompareFn: (a, b) => {
              //   return returnVal(a.worker_role, b.worker_role);
              // },
              // filterCreater: (obj) => ({ worker_role: obj }),
              render: (r, a) => (
                <Tag status="inactive">
                  {roles?.find((role) => role.node.value === a.worker_role)
                    ?.node.translation.en ?? ""}
                </Tag>
              ),
            },
            {
              sortable: true,
              title: "Orientated Date",
              key: "orientatedOn",
              size: "md",
              dataIndex: ["user_orientation", "orientated_at"],
              defaultSortOrder:
                defaultSortColumnKey === "orientatedOn" ? "desc" : undefined,
              clientCompareFn: (a, b) => {
                let valueA, valueB;
                if (a.user_orientation) {
                  valueA = getOrientationCompleted(
                    a.user_orientation,
                    hasSlides,
                    hasInPerson,
                  )
                    ? a.user_orientation.completed_at ??
                      getLatestDate(
                        a.user_orientation.in_person_orientated_at,
                        a.user_orientation.orientated_at,
                      )
                    : undefined;
                }
                if (b.user_orientation) {
                  valueB = getOrientationCompleted(
                    b.user_orientation,
                    hasSlides,
                    hasInPerson,
                  )
                    ? b.user_orientation.completed_at ??
                      getLatestDate(
                        b.user_orientation.in_person_orientated_at,
                        b.user_orientation.orientated_at,
                      )
                    : undefined;
                }

                return returnVal(valueA, valueB);
              },
              render: (_, a) => {
                const date =
                  getOrientationCompleted(
                    a.user_orientation,
                    hasSlides,
                    hasInPerson,
                  ) && a.user_orientation
                    ? a.user_orientation.completed_at ??
                      getLatestDate(
                        a.user_orientation.in_person_orientated_at,
                        a.user_orientation.orientated_at,
                      )
                    : undefined;
                let haveQuizAnswers = false;
                const orientationMap: {
                  [key: string]: NonNullable<
                    DConnection["edges"][number]["node"]["user_orientation"]
                  >["all_user_orientation_results"][number];
                } = {};
                if (a.user_orientation) {
                  haveQuizAnswers =
                    haveQuizAnswers ||
                    !!a.user_orientation.all_user_orientation_results
                      .filter(
                        (or) =>
                          or.status === "completed" &&
                          (or.orientation?.type === "corporate" ||
                            or.project_id === projectId ||
                            (or.project &&
                              or.project.linked_orientation_projects.find(
                                (p) => p.pk === projectId,
                              ))),
                      )
                      .some((or) => or.quiz_results.length > 0);
                }
                const results = (
                  a.user_orientation?.all_user_orientation_results || []
                ).filter(
                  (or) =>
                    or.status === "completed" &&
                    (or.orientation?.type === "corporate" ||
                      or.project_id === projectId ||
                      //here project will be available as we're not calling universal orientation results
                      or.project?.linked_orientation_projects.find(
                        (p) => p.pk === projectId,
                      )),
                );
                results.forEach(
                  (or) => (orientationMap[or.orientation_id ?? ""] = or),
                );

                return (
                  <div className="flex">
                    {date === undefined ? (
                      "not complete"
                    ) : date === null ? (
                      "not logged"
                    ) : (
                      <>
                        <Icon
                          icon={<MaterialSymbol icon={"calendar_today"} />}
                        />
                        {dayjs(date).format("M/DD/YYYY")}
                      </>
                    )}
                    {(haveQuizAnswers || !!a.user!.emergency_contact) && (
                      <Popover
                        content={
                          <QuizResult
                            emergencyContact={a.user!.emergency_contact}
                            orientationResults={Object.values(
                              orientationMap,
                            ).sort((a, b) => {
                              if (a.orientation.order === b.orientation.order)
                                return a.orientation.name.localeCompare(
                                  b.orientation.name,
                                );
                              else
                                return (
                                  a.orientation.order - b.orientation.order
                                );
                            })}
                          />
                        }
                        placement="left"
                        trigger={"hover"}
                      >
                        <div className="text-1 ml-1">📝</div>
                      </Popover>
                    )}
                  </div>
                );
              },
            },
            {
              sortable: true,
              title: "Universal Orientated Date",
              key: "universal_orientated_at",
              size: "md",
              dataIndex: ["universal_orientations"],
              clientCompareFn: (a, b) => {
                const valueA =
                  a.user!.universal_orientations[0]?.universal_orientated_at;
                const valueB =
                  b.user!.universal_orientations[0]?.universal_orientated_at;
                return returnVal(valueA, valueB);
              },
              render: (_, a) => {
                const date =
                  a.user!.universal_orientations[0]?.universal_orientated_at;
                const orientationMap: {
                  [key: string]: QuizResultProps["orientationResults"][number];
                } = {};
                if (a.user!.universal_orientations[0])
                  a.user!.universal_orientations[0].universal_orientation_results.forEach(
                    (or) => (orientationMap[or.orientation_id ?? ""] = or),
                  );
                return (
                  <div className="flex">
                    {date === undefined ? (
                      "not complete"
                    ) : date === null ? (
                      "not logged"
                    ) : (
                      <>
                        <Icon
                          icon={<MaterialSymbol icon={"calendar_today"} />}
                        />
                        {dayjs(date).format("M/DD/YYYY")}
                        {a.user!.universal_orientations[0] &&
                          a.user!.universal_orientations[0].universal_orientation_results.findIndex(
                            (p) => p.quiz_results.length > 0,
                          ) !== -1 && (
                            <Popover
                              content={
                                <QuizResult
                                  orientationResults={Object.values(
                                    orientationMap,
                                  )}
                                />
                              }
                              placement="left"
                              trigger={"hover"}
                            >
                              <div className="text-1 ml-1">📝</div>
                            </Popover>
                          )}
                      </>
                    )}
                  </div>
                );
              },
            },

            {
              sortable: true,
              title: "Corporate Video Date",
              key: "inPersonOrientatedDate",
              size: "sm",
              // dateRangeSearchIndex: [
              //   "worker",
              //   "worker_projects",
              //   "user_orientation",
              //   "in_person_orientated_at",
              // ],
              dataIndex: ["user_orientation", "in_person_orientated_at"],
              defaultSortOrder:
                defaultSortColumnKey === "inPersonOrientatedDate"
                  ? "desc"
                  : undefined,
              // filterCreater: (obj) => {
              //   return {
              //     _or: [
              //       {
              //         worker: {
              //           worker_projects: {
              //             project_id: { _eq: projectId },
              //             is_last: { _eq: true },
              //             user_orientation: { in_person_orientated_at: obj },
              //           },
              //         },
              //       },
              //       {
              //         employee: {
              //           employee_projects: {
              //             project_id: { _eq: projectId },
              //             user_orientation: { in_person_orientated_at: obj },
              //           },
              //         },
              //       },
              //     ],
              //   };
              // },
              clientCompareFn: (a, b) => {
                let valueA = undefined;

                if (a.user_orientation) {
                  let corporateCompletedA: boolean | undefined = undefined;
                  const corpOrient =
                    a.user_orientation.all_user_orientation_results.filter(
                      (or) => or.orientation.type === "corporate",
                    );
                  corpOrient.forEach((or) => {
                    corporateCompletedA =
                      corporateCompletedA !== undefined
                        ? corporateCompletedA &&
                          (!!or.completed_at || or.status === "completed")
                        : !!or.completed_at || or.status === "completed";
                  });
                  valueA = corporateCompletedA
                    ? a.user_orientation.orientated_at ??
                      corpOrient[0].completed_at
                    : undefined;
                }

                let valueB = undefined;

                if (b.user_orientation) {
                  let corporateCompletedB: boolean | undefined = undefined;
                  const corpOrient =
                    b.user_orientation.all_user_orientation_results.filter(
                      (or) => or.orientation.type === "corporate",
                    );
                  corpOrient.forEach((or) => {
                    corporateCompletedB =
                      corporateCompletedB !== undefined
                        ? corporateCompletedB &&
                          (!!or.completed_at || or.status === "completed")
                        : !!or.completed_at || or.status === "completed";
                  });
                  valueB = corporateCompletedB
                    ? b.user_orientation.orientated_at ??
                      corpOrient[0].completed_at
                    : undefined;
                }

                return returnVal(valueA, valueB);
              },
              render: (_, a) => {
                if (!a.user_orientation) return <>not complete</>;

                let corporateCompleted: boolean | undefined = undefined;
                const corpOrient =
                  a.user_orientation.all_user_orientation_results.filter(
                    (or) => or.orientation.type === "corporate",
                  );
                corpOrient.forEach((or) => {
                  corporateCompleted =
                    corporateCompleted !== undefined
                      ? corporateCompleted &&
                        (!!or.completed_at || or.status === "completed")
                      : !!or.completed_at || or.status === "completed";
                });
                const date = corporateCompleted
                  ? a.user_orientation.orientated_at ??
                    corpOrient[0].completed_at
                  : undefined;

                return (
                  <div className="flex">
                    {date === undefined ? (
                      "not complete"
                    ) : date === null ? (
                      "not logged"
                    ) : (
                      <>
                        <Icon
                          icon={<MaterialSymbol icon={"calendar_today"} />}
                        />
                        {dayjs(date).format("M/DD/YYYY")}
                      </>
                    )}
                  </div>
                );
              },
            },
            {
              sortable: true,
              title: "Registered Date",
              key: "registeredDate",
              size: "md",
              defaultSortOrder:
                defaultSortColumnKey === "registeredDate" ? "desc" : undefined,
              dataIndex: ["created_at"],

              render: (value, a) => {
                const date = a.created_at;
                return (
                  <div className="flex">
                    {date === undefined ? (
                      "not complete"
                    ) : date === null ? (
                      "not logged"
                    ) : (
                      <>
                        <Icon
                          icon={<MaterialSymbol icon={"calendar_today"} />}
                        />
                        {dayjs(date).format("M/DD/YYYY")}
                      </>
                    )}
                  </div>
                );
              },
            },
            {
              title: "Training & Certifications",
              key: "trainingAndCertifications",
              size: "ml",
              dataIndex: ["worker"],
              render: (_, row) => {
                const certificates: Certificate[] =
                  row.worker.worker_certifications.map((cert) => {
                    const certificate: Certificate = {
                      name: cert.certification.name,
                      expiryDate: cert.expires_on,
                    };
                    return certificate;
                  });
                const clockColor = getCertificateExpiryValue(certificates);
                const menu: MenuProps = {
                  items: row.worker.worker_certifications.map((cert) => {
                    let textColor = "";
                    if (cert && cert.expires_on) {
                      textColor = getCertificateExpiryValueFromDate(
                        new Date(cert.expires_on),
                      );
                    }
                    return {
                      key: cert.pk,
                      label: (
                        <Popover
                          placement="left"
                          title={
                            <div className="font-accent text-1 w-full">
                              {cert.certification.name}
                            </div>
                          }
                          content={
                            <CertificationCard worker_cert_id={cert.pk} />
                          }
                        >
                          <div className={textColor}>
                            {cert?.certification?.name}{" "}
                          </div>
                          {(
                            cert?.expires_on
                              ? dayjs(cert.expires_on).isAfter(dayjs())
                              : true
                          ) ? (
                            ""
                          ) : (
                            <p>
                              Expired on{" "}
                              {dayjs(cert?.expires_on).format("MMM DD, YYYY")}
                            </p>
                          )}
                        </Popover>
                      ),
                    };
                  }),
                };
                const certsToVerifyPresent =
                  row.worker.certificates_to_verify_aggregate.aggregate?.count;

                return (
                  <>
                    {certsToVerifyPresent && (
                      <Popover
                        trigger={"hover"}
                        placement="left"
                        content={
                          <ProcessingCertCard
                            certsToVerifyWhere={{
                              worker_id: { _eq: row.worker_id },
                              ...certsToVerifyWhere,
                            }}
                          />
                        }
                        title={
                          <div style={{ fontSize: "18px" }}>
                            SiteForm is verifying these Certificate Images
                          </div>
                        }
                      >
                        <div>
                          <Tag status="pending">
                            {`(${certsToVerifyPresent}) Processing`}
                          </Tag>
                        </div>
                      </Popover>
                    )}

                    {!isNullOrUndefined(row.worker.worker_certifications) &&
                      row.worker.worker_certifications.length !== 0 && (
                        <div className="flex flex-row gap-0.5">
                          <Popover
                            trigger={"hover"}
                            content={<Menu {...menu} />}
                            placement="bottom"
                          >
                            <div>
                              <Tag status={`positive`}>
                                {`✓ (${row.worker.worker_certifications.length}
                                ) Verified`}
                              </Tag>
                            </div>
                          </Popover>
                          {clockColor && (
                            <Icon
                              icon={<MaterialSymbol icon={"schedule"} />}
                              color={clockColor}
                            />
                          )}
                        </div>
                      )}
                  </>
                );
              },
            },
            {
              title: "Eligible to Work",
              sortable: true,
              key: "drugtestDate",
              defaultSortOrder:
                defaultSortColumnKey === undefined ||
                defaultSortColumnKey === "drugtestDate"
                  ? "desc"
                  : undefined,
              size: "md",
              dataIndex: ["user_orientation", "drug_test", "drug_test_date"],
              render: (value, a) => {
                const date = a.user_orientation?.drug_test?.drug_test_date;
                return (
                  <div className="flex">
                    {date === undefined ? (
                      "not complete"
                    ) : date === null ? (
                      "not logged"
                    ) : (
                      <>
                        <Icon
                          icon={<MaterialSymbol icon={"calendar_today"} />}
                        />
                        {dayjs(date).format("M/DD/YYYY")}
                      </>
                    )}
                  </div>
                );
              },
            },
            ...(extraColumns || []),
            {
              title: "Action",
              key: "archivedAt",
              size: "sm",
              dataIndex: ["archived_at"],
              defaultSortOrder:
                defaultSortColumnKey === "archivedAt" ? "asc" : undefined,
              render: (_, row) => {
                const d = row.archived_at;
                const ArchivedStatus: React.FC = () => (
                  <Popover
                    trigger={"click"}
                    title="Worker Archived By GC on this Project"
                    content={
                      <Alert
                        type="warning"
                        message={
                          <div>
                            An archived worker is a worker that was added <br />
                            to the project by a foreman, subcontractor,
                            <br />
                            or your team but never worked on the project. <br />
                            An archived worker has not completed orientation.
                            <br />
                            Archived By:{" "}
                            {row.archived_by_user?.name
                              ? `${row.archived_by_user.name}, ${
                                  row.archived_by_user.employee?.employee_title
                                    ?.name.en ?? ""
                                }`
                              : ""}
                            <br /> Archived Date: {dayjs(d).format("LL") ?? ""}
                          </div>
                        }
                      />
                    }
                  >
                    <AntdButton
                      size="small"
                      className="text-warn bg-white border-warn border-0.125 rounded-2 text-0.75 hover:text-warn hover:border-warn"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Archived
                    </AntdButton>
                  </Popover>
                );

                return (
                  <div>
                    {!(
                      row.user_orientation &&
                      row.user_orientation.orientated_at &&
                      row.user_orientation.in_person_orientated_at
                    ) && (
                      <BPopconfirm
                        cancelText={"No"}
                        icon={<WarningFilled color="red" />}
                        onConfirm={() => {
                          //delete worker
                          if (row.user) {
                            deleteWorker({
                              variables: { input: { workerId: row.user.pk } },
                            })
                              .then(() => {
                                message.success("Deleted Worker Successfully");
                                if (typeof ref !== `function`)
                                  ref?.current?.refetch();
                              })
                              .catch((e) =>
                                notification.error({
                                  message: "Couldn't delete " + row.user?.name,
                                  description: e.message,
                                }),
                              );
                          }
                        }}
                        title={
                          <div>
                            <div className="text-semantic-negative font-accent">
                              Delete Worker
                            </div>
                            <div>
                              Do you want to try and delete this worker? This
                              action cannot be undone. <br />
                              Are you sure?
                            </div>
                          </div>
                        }
                      >
                        <AntdButton
                          loading={isArchiving || deleting}
                          size="small"
                          className={`mb-0.75 border-semantic-negative hover:border-semantic-negative rounded-2 text-0.9 ${
                            d
                              ? "text-white hover:text-white bg-semborder-semantic-negative hover:bg-semborder-semantic-negative"
                              : "text-semborder-semantic-negative hover:text-semborder-semantic-negative bg-white hover:bg-white"
                          }`}
                        >
                          <Icon
                            icon={<MaterialSymbol icon="delete" />}
                            size="small"
                            color="negative"
                          />
                        </AntdButton>
                      </BPopconfirm>
                    )}
                    {d ? <ArchivedStatus /> : <></>}
                    {showArchiveButton && (
                      <AntdButton
                        loading={isArchiving || deleting}
                        type="primary"
                        size="small"
                        className={` border-warn hover:border-warn rounded-2 text-0.9 ${
                          d
                            ? "text-white hover:text-white bg-warn hover:bg-warn"
                            : "text-warn hover:text-warn bg-white hover:bg-white"
                        }`}
                        onClick={async () => {
                          if (d)
                            await archiveWorker({
                              variables: {
                                projectWorkerId: row.pk,
                                _set: {
                                  archived_at: null,
                                  archived_by_uid: null,
                                },
                              },
                            });
                          else
                            await archiveWorker({
                              variables: {
                                projectWorkerId: row.pk,
                                _set: {
                                  archived_at: dayjs().format(),
                                  archived_by_uid: auth.currentUser?.uid,
                                },
                              },
                            });
                          if (typeof ref !== `function`)
                            ref?.current?.refetch();
                        }}
                      >
                        {!d ? "Archive" : "+ Add Back"}
                      </AntdButton>
                    )}
                  </div>
                );
              },
            },
          ]}
          queryNode={graphql`
            query OrientationProjectWorkerTableQuery(
              $first: Int!
              $after: String
              $where: project_worker_bool_exp!
              $order_by: [project_worker_order_by!]!
              $startDate: date!
              $startTime: timestamptz!
              $projectId: uuid!
              $includeOnSite: Boolean!
              $certsToVerifyWhere: certificates_to_verify_bool_exp!
            ) {
              ...OrientationProjectWorkerTable_project_worker
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  startTime: $startTime
                  startDate: $startDate
                  projectId: $projectId
                  order_by: $order_by
                  includeOnSite: $includeOnSite
                  certsToVerifyWhere: $certsToVerifyWhere
                )
              ...OrientationProjectWorkerTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment OrientationProjectWorkerTable_total on query_root
            @argumentDefinitions(where: { type: "project_worker_bool_exp!" })
            @refetchable(
              queryName: "OrientationProjectWorkerTableTotalRefetchableQuery"
            ) {
              allProjectWorkersConnection: project_worker_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment OrientationProjectWorkerTable_project_worker on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              startTime: { type: "timestamptz!" }
              startDate: { type: "date!" }
              where: { type: "project_worker_bool_exp!" }
              projectId: { type: "uuid!" }
              order_by: { type: "[project_worker_order_by!]!" }
              includeOnSite: { type: "Boolean!" }
              certsToVerifyWhere: { type: "certificates_to_verify_bool_exp!" }
            )
            @refetchable(
              queryName: "OrientationProjectWorkerTableRefetchableQuery"
            ) {
              project_worker_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "OrientationProjectWorkerTable_project_worker_connection"
                  filters: ["where", "order_by"]
                ) {
                edges {
                  node {
                    pk: id @__clientField(handle: "pk")
                    worker_role
                    title_id
                    subcontractor_id
                    hard_hat_number
                    archived_at
                    project_id
                    deleted_at
                    created_at
                    worker_id
                    project {
                      name
                    }
                    archived_by_user {
                      name
                      employee {
                        general_contractor {
                          name
                        }
                        employee_title {
                          name {
                            en
                          }
                        }
                      }
                    }
                    subcontractor {
                      name
                    }
                    title {
                      translation {
                        en
                      }
                    }
                    user_orientation {
                      ...UserOrientationFrag @relay(mask: false)
                      all_user_orientation_results(
                        where: {
                          project: {
                            general_contractor: {
                              projects: { id: { _eq: $projectId } }
                            }
                          }
                        }

                        order_by: [
                          { completed_at: asc_nulls_last }
                          { created_at: asc }
                        ]
                      ) {
                        completed_at
                        status
                        orientation_id
                        orientation {
                          type
                          order
                          name
                        }
                        project_id
                        project {
                          linked_orientation_projects {
                            pk: id @__clientField(handle: "pk")
                          }
                        }
                        quiz_results(order_by: { slide: { order: asc } }) {
                          orientation_slide_id
                          answer
                          lang
                          slide {
                            title
                            question_ui_element
                            answer_items
                            answer_items_es
                            answer_items_pt
                            answer_items_it
                            answer_items_fr
                            answer_items_zh_hans
                            check_correct_answer
                            correct_answers
                          }
                        }
                      }
                    }
                    project_workers_aggregate(
                      where: {
                        _or: [
                          {
                            reports: {
                              report: { created_at: { _gte: $startTime } }
                            }
                          }
                          {
                            report_injuries: {
                              report: { created_at: { _gte: $startTime } }
                            }
                          }
                          {
                            daily_work_log_workers: {
                              daily_work_log: { date: { _gte: $startDate } }
                            }
                          }
                          {
                            toolbox_talks: {
                              toolbox_talk: { created_at: { _gte: $startTime } }
                            }
                          }
                          {
                            permits: {
                              permit: {
                                created_at: { _gte: $startTime }
                                current_status: {
                                  _in: ["submitted", "resubmitted"]
                                }
                                type: { _neq: "ladder" }
                                is_historical: { _eq: true }
                              }
                            }
                          }
                        ]
                      }
                    ) @include(if: $includeOnSite) {
                      aggregate {
                        count
                      }
                    }
                    user {
                      name
                      email
                      phone_number
                      role
                      emergency_contact
                      pk: id @__clientField(handle: "pk")
                      ...UserUoFrag @relay(mask: false)
                    }
                    worker {
                      subcontractor_id
                      verified_at
                      worker_certifications {
                        pk: id @__clientField(handle: "pk")
                        expires_on
                        certification {
                          name
                        }
                      }
                      certificates_to_verify_aggregate(
                        where: $certsToVerifyWhere
                      ) {
                        aggregate {
                          count
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
        />
      </>
    );
  },
);

export default OrientationProjectWorkerTable;
