/**
 * @generated SignedSource<<f7f1758a50ff6c75c369a8d8889772ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_orientation_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrientationModulesTable_UpsertProjectOrientationMutation$variables = {
  object: project_orientation_insert_input;
};
export type OrientationModulesTable_UpsertProjectOrientationMutation$data = {
  readonly insert_project_orientation_one: {
    readonly hide_but_give_credit: boolean;
    readonly id: string;
    readonly orientation_id: string;
    readonly pk: string;
    readonly play_during_in_person: boolean;
    readonly play_during_registration: boolean;
    readonly project_id: string | null | undefined;
    readonly required_by_all_workers: boolean;
  } | null | undefined;
};
export type OrientationModulesTable_UpsertProjectOrientationMutation = {
  response: OrientationModulesTable_UpsertProjectOrientationMutation$data;
  variables: OrientationModulesTable_UpsertProjectOrientationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "project_orientation_project_id_orientation_id_key",
      "update_columns": [
        "play_during_in_person",
        "play_during_registration",
        "required_by_all_workers",
        "hide_but_give_credit"
      ]
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "play_during_in_person",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "play_during_registration",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "required_by_all_workers",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hide_but_give_credit",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrientationModulesTable_UpsertProjectOrientationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_orientation",
        "kind": "LinkedField",
        "name": "insert_project_orientation_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrientationModulesTable_UpsertProjectOrientationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_orientation",
        "kind": "LinkedField",
        "name": "insert_project_orientation_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03d2d54ab19324bd331a094a2ed2bca5",
    "id": null,
    "metadata": {},
    "name": "OrientationModulesTable_UpsertProjectOrientationMutation",
    "operationKind": "mutation",
    "text": "mutation OrientationModulesTable_UpsertProjectOrientationMutation(\n  $object: project_orientation_insert_input!\n) {\n  insert_project_orientation_one(object: $object, on_conflict: {constraint: project_orientation_project_id_orientation_id_key, update_columns: [play_during_in_person, play_during_registration, required_by_all_workers, hide_but_give_credit]}) {\n    id\n    pk: id\n    project_id\n    orientation_id\n    play_during_in_person\n    play_during_registration\n    required_by_all_workers\n    hide_but_give_credit\n  }\n}\n"
  }
};
})();

(node as any).hash = "c33f8cae3a8ded0684adc189371a4be3";

export default node;
