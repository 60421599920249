import React from "react";
import AppNavigationLayout from "../../../../common/components/layouts/main/AppNavigationLayout";
import developmentPlaygroundNavigationItems from "./developmentPlaygroundNavigationItems";
import { Outlet } from "react-router-dom";
import { Menu } from "antd";
import useRoutedMenuProps from "../../../../utility-features/navigation/routed-menu/useRoutedMenuProps";

const DevelopmentPlayground: React.FC = () => {
  const menuItems: { label: string; path: string }[] = [];
  developmentPlaygroundNavigationItems.forEach((item) => {
    if (item.label && item.path) {
      menuItems.push({
        label: item.label,
        path: item.path,
      });
    }
  });

  const menuProps = useRoutedMenuProps(menuItems, "/development-playground");
  return (
    <AppNavigationLayout
      sideMenu={{
        body: <Menu {...menuProps} />,
      }}
    >
      <Outlet />
    </AppNavigationLayout>
  );
};

export default DevelopmentPlayground;
