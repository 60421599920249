import { message, notification, Space } from "antd";
import React, { FC, useRef, useState } from "react";
import { useLazyLoadQuery, useRefetchableFragment } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import DataDetailDescriptions, {
  DataDetailItems,
} from "src/common/components/layouts/DataDetailDescriptions";
import StyledContent from "src/common/components/layouts/StyledContent";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import { CrewProjectWorkerOptions_project_worker_connection_frag$key } from "src/common/types/generated/relay/CrewProjectWorkerOptions_project_worker_connection_frag.graphql";
import { CrewDetails_UpdateWorkerMutation } from "src/common/types/generated/relay/CrewDetails_UpdateWorkerMutation.graphql";
import { CrewDetailsQuery } from "src/common/types/generated/relay/CrewDetailsQuery.graphql";
import { CrewDetails_UpdateCrewLeadMutation } from "src/common/types/generated/relay/CrewDetails_UpdateCrewLeadMutation.graphql";
import ProjectWorkerTable from "src/common/components/tables/ProjectWorkerTable";
import { WarningFilled } from "@ant-design/icons";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Button from "src/common/components/general/button/Button";
import Popover from "src/common/components/general/Popover";
import CrewProjectWorkerOptionsFrag from "src/common/api/relay/fragments/CrewProjectWorkerOptions";
import EditCrewModal from "./modal/EditCrewModal";
import { MaterialSymbol } from "react-material-symbols";

interface CrewDetailsProps {
  projectId: string;
  subcontractorId: string;
  crewId: string;
  isGCAcc: boolean;
}

const updateSelectedCrewLead = graphql`
  mutation CrewDetails_UpdateCrewLeadMutation(
    $where: project_crew_bool_exp!
    $_setLeadCrew: project_crew_set_input
  ) {
    update_project_crew(where: $where, _set: $_setLeadCrew) {
      returning {
        ...CrewFrag @relay(mask: false)
      }
    }
  }
`;

const removeSelectedWorker = graphql`
  mutation CrewDetails_UpdateWorkerMutation(
    $removeWorkerWhere: project_worker_bool_exp!
    $_setRemoveWorkers: project_worker_set_input
    $where: project_crew_bool_exp!
    $_setLeadCrew: project_crew_set_input
  ) {
    update_project_worker(where: $removeWorkerWhere, _set: $_setRemoveWorkers) {
      affected_rows
    }
    update_project_crew(where: $where, _set: $_setLeadCrew) {
      returning {
        ...CrewFrag @relay(mask: false)
      }
    }
  }
`;

const getCrewDetailsQuery = graphql`
  query CrewDetailsQuery(
    $where: project_crew_bool_exp!
    $projectId: uuid!
    $projectWorkerWhere: project_worker_bool_exp!
  ) {
    ...CrewProjectWorkerOptions_project_worker_connection_frag
      @arguments(projectWorkerWhere: $projectWorkerWhere)
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        project_orientations: {
          required_by_all_workers: { _eq: true }
          project: { linked_orientation_projects: { id: { _eq: $projectId } } }
        }
        _or: [
          {
            project: {
              linked_orientation_projects: { id: { _eq: $projectId } }
            }
          }
          {
            project_id: { _is_null: true }
            general_contractor: { projects: { id: { _eq: $projectId } } }
          }
        ]
      }
      first: 1
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          id
        }
      }
    }
    universalRequired: orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        project_orientations: {
          required_by_all_workers: { _eq: true }
          project_id: { _is_null: true }
        }
        type: { _eq: "universal" }
      }
      first: 1
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          id
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          in_person_orientation
          agc_universal_orientation
        }
      }
    }
    project_crew_connection(where: $where, first: 10000)
      @connection(
        key: "CrewDetailsQuery_project_crew_connection"
        filters: []
      ) {
      edges {
        node {
          ...CrewFrag @relay(mask: false)
        }
      }
    }
  }
`;
const CrewDetails: FC<CrewDetailsProps> = ({
  projectId,
  subcontractorId,
  crewId,
  isGCAcc,
}) => {
  const crewData = useLazyLoadQuery<CrewDetailsQuery>(getCrewDetailsQuery, {
    where: {
      id: { _eq: crewId },
    },
    projectId,
    projectWorkerWhere: {
      subcontractor_id: { _eq: subcontractorId },
      project_id: { _eq: projectId },
      subcontractor_worker: {},
    },
  });
  const crewTableRef = useRef<DataScrollTableRef>(null);
  const [showEditCrewModal, setShowEditCrewModal] = useState(false);
  const navigate = useNavigate();
  const [removeWorker, removeLoading] =
    useAsyncMutation<CrewDetails_UpdateWorkerMutation>(removeSelectedWorker);
  const projectData = crewData.project_connection.edges[0].node;
  const hasInPerson = projectData.in_person_orientation;
  const hasSlides = crewData.orientation_connection.edges.length > 0;
  const [updateCrewLead, leadLoading] =
    useAsyncMutation<CrewDetails_UpdateCrewLeadMutation>(
      updateSelectedCrewLead,
    );

  const crew = crewData.project_crew_connection.edges[0].node;

  const [workersOnProject] = useRefetchableFragment<
    CrewDetailsQuery,
    CrewProjectWorkerOptions_project_worker_connection_frag$key
  >(CrewProjectWorkerOptionsFrag, crewData);

  const crewItems: DataDetailItems = [
    {
      label: "Crew Name",
      value: crew.name,
    },
    {
      label: "Crew Lead",
      value:
        crew.lead_foreman_project_worker &&
        crew.lead_foreman_project_worker.subcontractor_worker
          ? crew.lead_foreman_project_worker.user?.name
          : "No Crew Lead",
    },
  ];

  if (isGCAcc) {
    crewItems.push({
      label: "Subcontractor",
      value: crew.subcontractor.name,
    });
  }

  return (
    <BasicWrapper scrollable>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <StyledContent backgroundColor="white">
          <div className="flex justify-between">
            <DataDetailDescriptions title="Crew Info" items={crewItems} />
            <div>
              <Button
                onClick={() => setShowEditCrewModal(true)}
                label=" Edit Crew"
                icon={<MaterialSymbol icon={"edit"} />}
              />
            </div>
          </div>
          <EditCrewModal
            visible={showEditCrewModal}
            onCancel={() => setShowEditCrewModal(false)}
            workersOnProject={workersOnProject}
            crew={crewData.project_crew_connection.edges[0].node}
            onFinish={() => {
              crewTableRef.current?.refetch();
            }}
          />
        </StyledContent>
        <ProjectWorkerTable
          loadAll
          ref={crewTableRef}
          excludedKeys={[
            "archivedAt",
            "drugTest",
            "drugtestDate",
            "inPersonOrientatedDate",
            "project",
            "subcontractor",
            "universal_orientated_at",
            "trainingAndCertifications",
            "status",
            "orientated_at",
            "registeredDate",
            "dateLogged",
          ]}

          hasInPerson={hasInPerson}
          hasSlides={hasSlides}
          hasUniversal={
            projectData.agc_universal_orientation &&
            crewData.universalRequired.edges.length > 0
          }
          projectId={projectId}
          where={{
            project_crew_id: {
              _eq: crewId,
            },
            deleted_at: { _is_null: true },
            subcontractor_worker: {},
          }}
          onRowClick={({ pk }) => {
            if (isGCAcc)
              navigate(
                `/gce/hierarchy/project/${projectId}/workers/worker/${pk}`,
              );
          }}
          extraColumns={[
            {
              title: "Action",
              key: "extra",
              size: "icon",
              dataIndex: [],
              render: (_, row) => (
                <Popover
                  showOnClick
                  content={
                    <div className="flex flex-col gap-0.5">
                      <BPopconfirm
                        className="z-50"
                        cancelText={"Cancel"}
                        okText={"Yes"}
                        icon={<WarningFilled color="red" />}
                        onConfirm={async () => {
                          // remove worker
                          try {
                            await removeWorker({
                              variables: {
                                removeWorkerWhere: {
                                  id: { _eq: row.pk },
                                },
                                _setRemoveWorkers: {
                                  project_crew_id: null,
                                },
                                where:
                                  row.pk ===
                                  row.project_crew
                                    ?.lead_foreman_project_worker_id
                                    ? {
                                        id: { _eq: row.project_crew.pk },
                                      }
                                    : { id: { _is_null: true } },
                                _setLeadCrew: {
                                  lead_foreman_project_worker_id: null,
                                },
                              },
                            });
                            message.success("Removed Worker Successfully");
                          } catch (e) {
                            console.log(e);
                            notification.error({
                              message: "Couldn't remove " + row.user?.name,
                              duration: 5000,
                              description:
                                e instanceof Error
                                  ? e.message
                                  : JSON.stringify(e),
                            });
                          }
                        }}
                        placement="topRight"
                        title={
                          <div className="w-24">
                            <div className="text-semantic-negative font-accent">
                              Remove Worker from Crew
                            </div>
                            <div>
                              This will remove the worker from this Crew. <br />
                              Are you sure?
                            </div>
                          </div>
                        }
                      >
                        <Button
                          label="Remove from Crew"
                          delete
                          icon={<MaterialSymbol icon="delete" />}
                          onClick={() => {}}
                        />
                      </BPopconfirm>
                      <BPopconfirm
                        cancelText={"Cancel"}
                        className="z-auto"
                        okText={"Yes"}
                        icon={<WarningFilled color="green" />}
                        onConfirm={async () => {
                          // make crew lead
                          try {
                            await updateCrewLead({
                              variables: {
                                where: {
                                  id: { _eq: row.project_crew?.pk },
                                },
                                _setLeadCrew: {
                                  lead_foreman_project_worker_id: row.pk,
                                },
                              },
                            });
                            message.success("Crew Lead Changed Successfully");
                          } catch (e) {
                            console.log(e);
                            notification.error({
                              message: "Couldn't change crew lead. ",
                              duration: 5000,
                              description:
                                e instanceof Error
                                  ? e.message
                                  : JSON.stringify(e),
                            });
                          }
                        }}
                        placement="left"
                        title={
                          <div className="w-24">
                            <div className="text-semantic-positive-green font-accent">
                              Make this Worker the Crew Leader
                            </div>
                            <div>
                              This will make the worker the crew lead of this
                              Crew. <br />
                              Are you sure?
                            </div>
                          </div>
                        }
                      >
                        <Button
                          label="Make Crew Lead"
                          green
                          icon={<MaterialSymbol icon={"edit"} />}
                          onClick={() => {}}
                        />
                      </BPopconfirm>
                    </div>
                  }
                >
                  <Button
                    icon={<MaterialSymbol icon="more_vert" />}
                    fake
                    secondary
                    onClick={() => {}}
                  />
                </Popover>
              ),
            },
          ]}
        />
      </Space>
    </BasicWrapper>
  );
};

export default CrewDetails;
