import { MaterialSymbol } from "react-material-symbols";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PermitTable from "../../../../components/PermitTable";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { GCProjectReportsPermitsTableQuery } from "src/common/types/generated/relay/GCProjectReportsPermitsTableQuery.graphql";
import getGcProjectSettingsPath from "../../../../../../root/routes/views/general-contractor/hierarchy/projects/settings/getGcProjectSettingsPath";

const query = graphql`
  query GCProjectReportsPermitsTableQuery($projectId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          project_setting {
            worker_can_add_access_ladder_number
            worker_can_add_working_ladder_number
          }
          general_contractor {
            id
            hot_work_permit_name
          }
        }
      }
    }
  }
`;

interface GCProjectReportsPermitsTableProps {
  projectId: string;
  openPermit: (params: { permitId: string; permitType: string }) => void;
}

const GCProjectReportsPermitsTable: React.FunctionComponent<
  GCProjectReportsPermitsTableProps
> = ({ projectId, openPermit }) => {
  const [searchParams] = useSearchParams();
  const worker = searchParams.get("worker");
  const navigate = useNavigate();

  const handelSettingsButtonClick = () => {
    navigate(getGcProjectSettingsPath(projectId, `permits`));
  };

  const data = useLazyLoadQuery<GCProjectReportsPermitsTableQuery>(query, {
    projectId,
  });
  const project = data.project_connection.edges[0].node;
  const hotWorkPermitName = project.general_contractor.hot_work_permit_name;

  return (
    <PermitTable
      topBarButtons={[
        {
          secondary: true,
          onClick: handelSettingsButtonClick,
          icon: <MaterialSymbol icon={"settings"} />,
          hint: "Permit Settings",
        },
      ]}
      gcAddLadderIdentification={(isAccessLadder) =>
        !!project.project_setting &&
        !project.project_setting[
          isAccessLadder
            ? "worker_can_add_access_ladder_number"
            : "worker_can_add_working_ladder_number"
        ]
      }
      hotWorkPermitName={hotWorkPermitName}
      excludedKeys={["project"]}
      showDateRangeFilter={true}
      where={{
        ...(worker
          ? { permit_project_workers: { project_worker_id: { _eq: worker } } }
          : { project_id: { _eq: projectId }, is_historical: { _eq: false } }),
        current_status: { _neq: "pending" },
      }}
      onRowClick={(row: { type: string; pk: string }) => {
        openPermit({
          permitType: row.type,
          permitId: row.pk,
        });
      }}
    />
  );
};

export default GCProjectReportsPermitsTable;
