import React from "react";
import GCTeamListItem, { GCProjectTeamListItemProps } from "./GCTeamListItem";
import { List } from "antd";

export interface GCProjectTeamListProps {
  employees: Array<GCProjectTeamListItemProps>;
}

const GCTeamList: React.FC<GCProjectTeamListProps> = (props) => {
  return (
    <List
      bordered={true}
      itemLayout={"horizontal"}
      dataSource={props.employees}
      renderItem={(employee) => <GCTeamListItem {...employee} />}
    />
  );
};

export default GCTeamList;
