import { Card, Table } from "antd";
import dayjs from "dayjs";
import React, { FC } from "react";
import { ViewAndEditJhaQuery$data } from "src/common/types/generated/relay/ViewAndEditJhaQuery.graphql";
import Image from "src/common/components/general/images/Image";

type TaskSubAdminApprovalDataType =
  ViewAndEditJhaQuery$data["task_signature_connection"]["edges"];

interface Props {
  workerSignaturesData: TaskSubAdminApprovalDataType;
  projectId: string;
}

const TaskSignaturesTable: FC<Props> = ({
  workerSignaturesData,
  projectId,
}) => {
  let dataSource: any[];
  dataSource = workerSignaturesData
    .filter(
      (x) => !!x.node.signature_image_id || !!x.node.sign_in_image_parent_id,
    )
    .map((c) => {
      const user = c.node.user;

      return {
        key: c.node.pk,
        date: dayjs(c.node.created_at).format("MMM DD, YYYY h:mm A"),
        sign: <img src={c.node?.image?.url}></img>,
        photo: (
          <div>
            {c.node.sign_in_images.map((image) => (
              <Card key={image.url} className={"mb-2"}>
                <Image key={image.url} src={image.url} height={80} width={50} />
              </Card>
            ))}
          </div>
        ),
        name: [
          user?.name,
          user?.worker?.current_worker_role,
          user?.worker?.worker_title?.translation.en,
        ]
          .filter((v) => !!v)
          .join(", "),
      };
    });

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: "25%",
      defaultSordOrder: "ascend",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      sorter: (a: any, b: any) => (!!a.date ? a.date.localeCompare(b.date) : 1),
    },
    {
      title: "Signature",
      key: "sign",
      dataIndex: "sign",
      width: "25%",
    },
    {
      title: "Photo Documentation",
      key: "photo",
      dataIndex: "photo",
      width: "25%",
    },
  ];

  return (
    <>
      <Table columns={columns} dataSource={dataSource} bordered />
    </>
  );
};

export default TaskSignaturesTable;
