import withAppPageLayout from "../../../../../common/components/layouts/main/content/withAppPageLayout";
import { Card, Input, Space } from "antd";
import React, { useState } from "react";
import ProfileCard from "../../../../../common/components/ProfileCard";
import ProfilePicture from "../../subcontractor/people/workers/worker-profile/ProfilePicture";
import SwitchWithText from "../../../../../common/components/SwitchWithText";
import Image from "../../../../../common/components/general/images/Image";
import ShowMultipleImageLayout from "../../../../../common/components/layouts/ShowMultipleImageLayout";

const ImagesDemo: React.FC = () => {
  const [imageLoading, setImageLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>(
    `https://i.pinimg.com/736x/3e/ae/04/3eae04da2274a8cfe8d0bc1f5b2208de.jpg`,
  );
  const [imageSrcPassed, setImageSrcPassed] = useState<boolean>(true);
  const imageSrc = imageSrcPassed ? imageUrl : undefined;

  return (
    <div className={`flex flex-col gap-1`}>
      <Card>
        <Space direction={"vertical"}>
          <Input
            value={imageSrc}
            onChange={(e) => setImageUrl(e.target.value)}
            placeholder={"Image URL"}
          />
          <SwitchWithText
            checked={imageSrcPassed}
            text={"Pass image url"}
            onChange={setImageSrcPassed}
          />
        </Space>
      </Card>
      <Card>
        <ProfileCard
          fullName={"Full Name"}
          company={"Company"}
          avatar={{
            src: imageSrc,
            onFileRemove: async () => {},
            onFileUpload: async () => {},
            loading: imageLoading,
          }}
          bio={`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
          email={`email@via.placeholder.com`}
          phoneNumber={`+1 234 567 890`}
          position={"Position"}
          hardHatNumber={`123456`}
          userName={"userName"}
        />
      </Card>
      <Card>
        <Space direction={"vertical"}>
          <ProfilePicture
            onFileUpload={async () => {}}
            onFileRemove={async () => {}}
            src={imageSrc}
            loading={imageLoading}
          />
          <SwitchWithText
            checked={imageLoading}
            text={"Image loading"}
            onChange={setImageLoading}
          />
        </Space>
      </Card>
      <Card>
        <Image src={imageSrc} width={500} height={500} alt={"Image"} />
      </Card>
      <Card>
        {!!imageSrc && (
          <ShowMultipleImageLayout
            images={[
              {
                url: imageSrc,
                id: "0",
              },
              {
                url: imageSrc,
                id: "1",
              },
              {
                url: imageSrc,
                id: "2",
              },
              {
                url: imageSrc,
                id: "3",
              },
            ]}
          />
        )}
      </Card>
    </div>
  );
};

export default withAppPageLayout(ImagesDemo, {
  pageTitle: "Icons Demo",
});
