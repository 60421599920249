/**
 * @generated SignedSource<<21add5a25f1cc0dc0576b60ecc6c0538>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type OrientationCompletionMsgQuery$variables = {
  projectWhere: project_bool_exp;
  workerId: string;
};
export type OrientationCompletionMsgQuery$data = {
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly add_hard_hat_during_registration: boolean;
        readonly agc_universal_orientation: boolean;
        readonly assign_hard_hat: boolean;
        readonly general_contractor: {
          readonly name: string;
          readonly pk: string;
        };
        readonly in_person_orientation: boolean;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  };
  readonly secret_login_link_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly pk: string;
      };
    }>;
  };
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly reference_number: string | null | undefined;
        readonly role: string;
      };
    }>;
  };
};
export type OrientationCompletionMsgQuery = {
  response: OrientationCompletionMsgQuery$data;
  variables: OrientationCompletionMsgQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectWhere"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "workerId"
  }
],
v2 = [
  {
    "fields": [
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reference_number",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v6 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "project_id",
        "value": {
          "_is_null": true
        }
      },
      {
        "kind": "Literal",
        "name": "sign_in_role",
        "value": {
          "_eq": "pseudo_worker"
        }
      },
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "user_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v7 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "projectWhere"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agc_universal_orientation",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "in_person_orientation",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assign_hard_hat",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "add_hard_hat_during_registration",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrientationCompletionMsgQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "secret_login_linkConnection",
        "kind": "LinkedField",
        "name": "secret_login_link_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "secret_login_linkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "secret_login_link",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor",
                    "kind": "LinkedField",
                    "name": "general_contractor",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrientationCompletionMsgQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "secret_login_linkConnection",
        "kind": "LinkedField",
        "name": "secret_login_link_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "secret_login_linkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "secret_login_link",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v4/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor",
                    "kind": "LinkedField",
                    "name": "general_contractor",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v4/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d6e601fbbaa685317b3eb7b1a0b9eacc",
    "id": null,
    "metadata": {},
    "name": "OrientationCompletionMsgQuery",
    "operationKind": "query",
    "text": "query OrientationCompletionMsgQuery(\n  $projectWhere: project_bool_exp!\n  $workerId: uuid!\n) {\n  user_connection(where: {id: {_eq: $workerId}}) {\n    edges {\n      node {\n        reference_number\n        name\n        role\n        id\n      }\n    }\n  }\n  secret_login_link_connection(where: {user_id: {_eq: $workerId}, project_id: {_is_null: true}, sign_in_role: {_eq: \"pseudo_worker\"}}) {\n    edges {\n      node {\n        pk: id\n        id\n      }\n    }\n  }\n  project_connection(where: $projectWhere) {\n    edges {\n      node {\n        pk: id\n        name\n        agc_universal_orientation\n        in_person_orientation\n        assign_hard_hat\n        add_hard_hat_during_registration\n        general_contractor {\n          pk: id\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b4ee4e4019db6a6bc7c98709c8ca0447";

export default node;
