import React, { useEffect, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { OrientationTVWorkersListQuery } from "src/common/types/generated/relay/OrientationTVWorkersListQuery.graphql";
import { useLazyLoadQuery } from "react-relay/hooks";
import dayjs from "dayjs";
import { WorkerType } from "../manager/routes/finished/components/SiteOrientationInPersonManagerViewWorkersResult";

import clsx from "clsx";
import { MaterialSymbol } from "react-material-symbols";

const query = graphql`
  query OrientationTVWorkersListQuery(
    $projectId: uuid!
    $lastInpersonAcceptedAt: timestamptz!
    $universalOrientationValidDate: timestamptz!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
          agc_universal_orientation
          general_contractors {
            general_contractor {
              name
            }
          }
          address {
            city
            line_1
            line_2
            state_code
            zip_code
          }
        }
      }
    }
    project_employee_connection(
      where: {
        project: { orientation_project_id: { _eq: $projectId } }
        user_orientation: {
          in_person_orientated_at: { _gte: $lastInpersonAcceptedAt }
        }
      }
      order_by: [
        { user_orientation: { in_person_orientated_at: desc } }
        { employee: { user: { name: asc } } }
      ]
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          hard_hat_number
          user_orientation {
            orientated_at
            in_person_orientated_at
            orientation_provided_by_user_id
          }
          employee_id
          employee {
            user {
              name
              universal_orientations(
                where: {
                  universal_orientated_at: {
                    _gte: $universalOrientationValidDate
                  }
                }
                order_by: { universal_orientated_at: desc }
                limit: 1
              ) {
                id
                universal_orientated_at
              }
              profile_picture {
                url
              }
            }
            employee_title {
              name {
                en
              }
            }
            general_contractor_id
            general_contractor {
              name
            }
            oac_title {
              name
            }
            oac_company_id
            oac_company {
              name
            }
          }
        }
      }
    }
    corporateOrientation: orientation_connection(
      where: {
        type: { _eq: "corporate" }
        project_orientations: {
          required_by_all_workers: { _eq: true }
          project_id: { _eq: $projectId }
        }
        general_contractor: { projects: { id: { _eq: $projectId } } }
      }
      order_by: { created_at: desc }
    ) {
      edges {
        node {
          id
          created_at
          duration_valid
          project_orientations(where: { project_id: { _eq: $projectId } }) {
            hide_but_give_credit
            play_during_in_person
          }
          orientation_results(
            where: {
              user: {
                _or: [
                  {
                    worker: {
                      worker_projects: {
                        project_id: { _eq: $projectId }
                        user_orientation: { selected: { _eq: true } }
                      }
                    }
                  }
                  {
                    employee: {
                      employee_projects: {
                        project_id: { _eq: $projectId }
                        user_orientation: { selected: { _eq: true } }
                      }
                    }
                  }
                ]
              }
            }
          ) {
            completed_at
            user_id
            status
          }
        }
      }
    }
    universalOrientations: orientation_connection(
      where: {
        type: { _eq: "universal" }
        project_orientations: {
          required_by_all_workers: { _eq: true }
          project_id: { _is_null: true }
        }
        general_contractor_id: { _is_null: true }
      }
      order_by: { created_at: desc }
    ) {
      edges {
        node {
          id
          created_at
          duration_valid
          projectSpecificSetting: project_orientations(
            where: { project_id: { _eq: $projectId } }
          ) {
            hide_but_give_credit
            play_during_in_person
          }
        }
      }
    }
    project_worker_connection(
      where: {
        project: { orientation_project_id: { _eq: $projectId } }
        subcontractor_worker: {}
        user_orientation: {
          in_person_orientated_at: { _gte: $lastInpersonAcceptedAt }
        }
      }
      order_by: [
        { user_orientation: { in_person_orientated_at: desc } }
        { user: { name: asc } }
      ]
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          worker_id
          hard_hat_number
          user {
            lang
            name
            universal_orientations(
              where: {
                universal_orientated_at: {
                  _gte: $universalOrientationValidDate
                }
              }
              order_by: { universal_orientated_at: desc }
              limit: 1
            ) {
              id
              universal_orientated_at
            }
            profile_picture {
              url
              sm_url
            }
          }
          worker {
            subcontractor {
              name
            }
            subcontractor_id
            worker_certifications {
              id
            }
            certificates_to_verify(
              where: {
                document: { _eq: "profilePhoto" }
                status: { _eq: "submitted" }
                verified_at: { _is_null: true }
              }
              order_by: { created_at: desc }
              limit: 1
            ) {
              front_image {
                url
              }
            }
            current_worker_role
            worker_title {
              translation {
                en
              }
            }
          }
          user_orientation {
            pk: id @__clientField(handle: "pk")
            orientated_at
            in_person_orientated_at
            orientation_provided_by_user_id
            signature_id
            drug_test_id
            in_person_signature_id
          }
        }
      }
    }
  }
`;

type project_worker =
  OrientationTVWorkersListQuery["response"]["project_worker_connection"]["edges"][0]["node"];
type project_employee =
  OrientationTVWorkersListQuery["response"]["project_employee_connection"]["edges"][0]["node"];
type newWorkerType = WorkerType & { inpersonOrientatedAt: number };
const OrientationTVWorkersList: React.FC<{
  projectId: string;
  divideListIn2: boolean;
}> = ({ projectId, divideListIn2 }) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState({
    fetchKey: 0,
  });
  const data = useLazyLoadQuery<OrientationTVWorkersListQuery>(
    query,
    {
      projectId,
      lastInpersonAcceptedAt: dayjs()
        .subtract(3, "hour")
        .startOf("hour")
        .format(),
      universalOrientationValidDate: dayjs()
        .subtract(11, "months")
        .startOf("d")
        .toISOString(),
    },
    {
      fetchPolicy: "store-and-network",
      ...refreshedQueryOptions,
    },
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshedQueryOptions((prev) => ({
        fetchKey: (prev?.fetchKey ?? 0) + 1,
      }));
    }, 30000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    console.log("Data changed");
  }, [data]);
  const workers: { [userId: string]: project_worker } = {};
  (data.project_worker_connection.edges || []).forEach((v) => {
    if (!workers[v.node.worker_id]) {
      workers[v.node.worker_id] = v.node;
    }
  });
  const projectData = data.project_connection.edges[0].node;
  const projectHasUniversal =
    projectData.agc_universal_orientation &&
    data.universalOrientations.edges.length > 0;
  const gcUsers: { [userId: string]: project_employee } = {};
  data.project_employee_connection.edges.forEach((v) => {
    if (!gcUsers[v.node.employee_id]) {
      gcUsers[v.node.employee_id] = v.node;
    }
  });

  const listWorkers: newWorkerType[] = [];
  const allCorporateCompleted: { [key: string]: boolean } = {};
  data.corporateOrientation.edges.forEach((o) => {
    const showLate =
      o.node.project_orientations[0] &&
      o.node.project_orientations[0].hide_but_give_credit;
    o.node.orientation_results.forEach((or) => {
      if (or.user_id) {
        const moreThan1HourOldORShown = showLate
          ? dayjs(or.completed_at).isSameOrBefore(dayjs().subtract(1, "hour"))
          : true;
        //true as we the corporates palyed in siteform are still valid
        const notExpired = dayjs(or.completed_at)
          .add(o.node.duration_valid, "M")
          .isSameOrAfter(dayjs());
        //above we are using or.completed_at in dayjs so if it is undefined it will take current time but
        //doesn't matter as we'll check whether the or.completed_at is present before using these  values
        if (
          allCorporateCompleted[or.user_id] === null ||
          allCorporateCompleted[or.user_id] === undefined
        )
          allCorporateCompleted[or.user_id] = or.completed_at
            ? moreThan1HourOldORShown && notExpired
            : or.status === "completed";
        else
          allCorporateCompleted[or.user_id] =
            allCorporateCompleted[or.user_id] &&
            (or.completed_at
              ? moreThan1HourOldORShown && notExpired
              : or.status === "completed");
      }
    });
  });
  Object.values(workers).forEach((w) => {
    const obj: newWorkerType = {
      name: w.user!.name,
      inpersonOrientatedAt: dayjs(
        w.user_orientation!.in_person_orientated_at,
      ).valueOf(),
      company: {
        //these will be present as we're calling only the workers that are employed
        title: w.worker.subcontractor!.name,
        id: w.worker.subcontractor_id!,
      },
      hardHatNumber: w.hard_hat_number,
      id: w.worker_id,

      imgSrc:
        w.worker.certificates_to_verify[0]?.front_image?.url ??
        w.user?.profile_picture?.url,
      trade: w.worker.worker_title?.translation?.en,
      corporateOrientationPassed:
        !projectHasUniversal &&
        !!(data.corporateOrientation.edges.length > 0
          ? allCorporateCompleted[w.worker_id] ||
            (w.user_orientation?.orientation_provided_by_user_id &&
              w.user_orientation?.orientated_at)
          : false),
      universalCompleted: projectHasUniversal
        ? w.user!.universal_orientations.length > 0 &&
          dayjs(
            w.user!.universal_orientations[0].universal_orientated_at,
          ).isBefore(dayjs().subtract(1, "hour"))
        : false,
    };
    listWorkers.push(obj);
  });

  Object.values(gcUsers).forEach((w) => {
    const obj: newWorkerType = {
      name: w.employee.user!.name,
      inpersonOrientatedAt: dayjs(
        w.user_orientation?.in_person_orientated_at,
      ).valueOf(),
      company: {
        title:
          w.employee.oac_company?.name ?? w.employee.general_contractor.name,
        id: w.employee.oac_company_id ?? w.employee.general_contractor_id,
      },
      hardHatNumber: w.hard_hat_number,
      trade:
        w.employee.oac_title?.name ?? w.employee.employee_title?.name?.en ?? "",
      id: w.employee_id,
      imgSrc: w.employee.user?.profile_picture?.url,
      corporateOrientationPassed:
        !projectHasUniversal &&
        !!(data.corporateOrientation.edges.length > 0
          ? allCorporateCompleted[w.employee_id] ||
            (w.user_orientation?.orientation_provided_by_user_id &&
              w.user_orientation?.orientated_at)
          : false),
      universalCompleted: projectHasUniversal
        ? w.employee.user.universal_orientations.length > 0 &&
          dayjs(
            w.employee.user!.universal_orientations[0].universal_orientated_at,
          ).isBefore(dayjs().subtract(1, "hour"))
        : false,
    };
    listWorkers.push(obj);
  });
  const workerStatus = (worker: WorkerType) =>
    (
      projectHasUniversal
        ? worker.universalCompleted
        : worker.corporateOrientationPassed
    )
      ? "positive"
      : "negative";

  const workerStatusNumber = (worker: WorkerType) =>
    (
      projectHasUniversal
        ? worker.universalCompleted
        : worker.corporateOrientationPassed
    )
      ? 1
      : 0;

  listWorkers.sort((a, b) => {
    const statusA = workerStatusNumber(a);
    const statusB = workerStatusNumber(b);
    let toReturn = 0;
    if (statusA === statusB)
      toReturn = a.inpersonOrientatedAt - b.inpersonOrientatedAt;
    else if (typeof statusA === "number" && typeof statusB === "number")
      toReturn = statusA - statusB;
    return -toReturn;
  });

  return (
    <div className={divideListIn2 ? "w-42" : "w-20"}>
      {listWorkers.length > 0
        ? ` Welcome ${listWorkers.length ? `(${listWorkers.length})` : ""}`
        : ""}
      <div
        className="flex flex-col justify-between overflow-scroll"
        style={{ maxHeight: "70vh" }}
      >
        <div className={`flex flex-col gap-0.25`}>
          {listWorkers
            .filter((_, i) => (divideListIn2 ? i % 2 === 0 : true))
            .map((worker, i) => (
              <div className="flex gap-0.5 flex-row justify-between">
                <div
                  className={clsx(
                    `flex flex-col items-stretch rounded-0.25 p-0.5 w-20`,
                    (() => {
                      switch (workerStatus(worker)) {
                        case "negative":
                          return `bg-semantic-negative-light text-semantic-negative-dark`;
                        case "positive":
                          return `bg-semantic-positive-light text-semantic-positive-dark`;
                      }
                    })(),
                  )}
                  key={worker.id}
                >
                  <div
                    className={`flex flex-row items-center justify-between gap-0.5 h-full flex-1`}
                  >
                    <div>
                      <span className={`font-accent`}>{worker.name}</span>{" "}
                      {worker.hardHatNumber ? `#${worker.hardHatNumber}` : ""}
                    </div>
                    <div className="flex items-start justify-start">
                      {worker.corporateOrientationPassed && (
                        <>
                          <MaterialSymbol icon="check_box" />{" "}
                          <p className={`text-0.75 mt-0.25`}>
                            Company Orientation
                          </p>
                        </>
                      )}
                      {worker.universalCompleted && (
                        <>
                          <MaterialSymbol icon="check_box" />{" "}
                          <p className={`text-0.75 m-0.25`}>CARE</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div>{`${[worker.company.title, worker.trade].join(
                    ", ",
                  )}`}</div>
                </div>
                {listWorkers[2 * i + 1] && divideListIn2 && (
                  <div
                    className={clsx(
                      `flex flex-col items-stretch rounded-0.25 p-0.5 w-20`,
                      (() => {
                        switch (workerStatus(listWorkers[2 * i + 1])) {
                          case "negative":
                            return `bg-semantic-negative-light text-semantic-negative-dark`;
                          case "positive":
                            return `bg-semantic-positive-light text-semantic-positive-dark`;
                        }
                      })(),
                    )}
                    key={listWorkers[2 * i + 1].id}
                  >
                    <div
                      className={`flex flex-row items-center justify-between gap-0.5 h-full flex-1`}
                    >
                      <div>
                        <span className={`font-accent`}>
                          {listWorkers[2 * i + 1].name}
                        </span>{" "}
                        {listWorkers[2 * i + 1].hardHatNumber
                          ? `#${listWorkers[2 * i + 1].hardHatNumber}`
                          : ""}
                      </div>
                      <div className="flex items-start justify-start">
                        {listWorkers[2 * i + 1].corporateOrientationPassed && (
                          <>
                            <MaterialSymbol icon="check_box" />{" "}
                            <p className={`text-0.75 mt-0.25`}>
                              Company Orientation
                            </p>
                          </>
                        )}
                        {listWorkers[2 * i + 1].universalCompleted && (
                          <>
                            <MaterialSymbol icon="check_box" />{" "}
                            <p className={`text-0.75 m-0.25`}>CARE</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div>{`${[
                      listWorkers[2 * i + 1].company.title,
                      listWorkers[2 * i + 1].trade,
                    ].join(", ")}`}</div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default OrientationTVWorkersList;
