/**
 * @generated SignedSource<<1f484b5a77058b158bcaf50391e6ee9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { uploaded_toolbox_talk_project_bool_exp, uploaded_toolbox_talk_project_insert_input, uploaded_toolbox_talk_set_input, uploaded_toolbox_talk_subcontractor_bool_exp, uploaded_toolbox_talk_subcontractor_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadedToolboxTalkMutation$variables = {
  _set: uploaded_toolbox_talk_set_input;
  id: string;
  newProjectsRelationsToInsert: ReadonlyArray<uploaded_toolbox_talk_project_insert_input>;
  newSubcontractorsRelationsToInsert: ReadonlyArray<uploaded_toolbox_talk_subcontractor_insert_input>;
  projectsRelationsToDelete: uploaded_toolbox_talk_project_bool_exp;
  subcontractorsRelationsToDelete: uploaded_toolbox_talk_subcontractor_bool_exp;
};
export type UploadedToolboxTalkMutation$data = {
  readonly delete_uploaded_toolbox_talk_project: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly delete_uploaded_toolbox_talk_subcontractor: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_uploaded_toolbox_talk_project: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_uploaded_toolbox_talk_subcontractor: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_uploaded_toolbox_talk_by_pk: {
    readonly added_to_community: boolean;
    readonly assign_to: string | null | undefined;
    readonly content: {
      readonly en: string;
    } | null | undefined;
    readonly due_time: string | null | undefined;
    readonly id: string;
    readonly name: string;
    readonly subcontractor_id: string | null | undefined;
    readonly uploaded_toolbox_talk_projects: ReadonlyArray<{
      readonly project_id: string;
    }>;
  } | null | undefined;
};
export type UploadedToolboxTalkMutation = {
  response: UploadedToolboxTalkMutation$data;
  variables: UploadedToolboxTalkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newProjectsRelationsToInsert"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newSubcontractorsRelationsToInsert"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectsRelationsToDelete"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subcontractorsRelationsToDelete"
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "objects",
      "variableName": "newProjectsRelationsToInsert"
    }
  ],
  "concreteType": "uploaded_toolbox_talk_project_mutation_response",
  "kind": "LinkedField",
  "name": "insert_uploaded_toolbox_talk_project",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "projectsRelationsToDelete"
    }
  ],
  "concreteType": "uploaded_toolbox_talk_project_mutation_response",
  "kind": "LinkedField",
  "name": "delete_uploaded_toolbox_talk_project",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "objects",
      "variableName": "newSubcontractorsRelationsToInsert"
    }
  ],
  "concreteType": "uploaded_toolbox_talk_subcontractor_mutation_response",
  "kind": "LinkedField",
  "name": "insert_uploaded_toolbox_talk_subcontractor",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "subcontractorsRelationsToDelete"
    }
  ],
  "concreteType": "uploaded_toolbox_talk_subcontractor_mutation_response",
  "kind": "LinkedField",
  "name": "delete_uploaded_toolbox_talk_subcontractor",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v11 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "due_time",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added_to_community",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assign_to",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadedToolboxTalkMutation",
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "uploaded_toolbox_talk",
        "kind": "LinkedField",
        "name": "update_uploaded_toolbox_talk_by_pk",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "uploaded_toolbox_talk_project",
            "kind": "LinkedField",
            "name": "uploaded_toolbox_talk_projects",
            "plural": true,
            "selections": [
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "content",
            "plural": false,
            "selections": [
              (v19/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadedToolboxTalkMutation",
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "uploaded_toolbox_talk",
        "kind": "LinkedField",
        "name": "update_uploaded_toolbox_talk_by_pk",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "uploaded_toolbox_talk_project",
            "kind": "LinkedField",
            "name": "uploaded_toolbox_talk_projects",
            "plural": true,
            "selections": [
              (v18/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "content",
            "plural": false,
            "selections": [
              (v19/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d0d22228cf96cb1da2c7352d6862621",
    "id": null,
    "metadata": {},
    "name": "UploadedToolboxTalkMutation",
    "operationKind": "mutation",
    "text": "mutation UploadedToolboxTalkMutation(\n  $_set: uploaded_toolbox_talk_set_input!\n  $projectsRelationsToDelete: uploaded_toolbox_talk_project_bool_exp!\n  $newProjectsRelationsToInsert: [uploaded_toolbox_talk_project_insert_input!]!\n  $subcontractorsRelationsToDelete: uploaded_toolbox_talk_subcontractor_bool_exp!\n  $newSubcontractorsRelationsToInsert: [uploaded_toolbox_talk_subcontractor_insert_input!]!\n  $id: uuid!\n) {\n  insert_uploaded_toolbox_talk_project(objects: $newProjectsRelationsToInsert) {\n    affected_rows\n  }\n  delete_uploaded_toolbox_talk_project(where: $projectsRelationsToDelete) {\n    affected_rows\n  }\n  insert_uploaded_toolbox_talk_subcontractor(objects: $newSubcontractorsRelationsToInsert) {\n    affected_rows\n  }\n  delete_uploaded_toolbox_talk_subcontractor(where: $subcontractorsRelationsToDelete) {\n    affected_rows\n  }\n  update_uploaded_toolbox_talk_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n    name\n    due_time\n    added_to_community\n    subcontractor_id\n    assign_to\n    uploaded_toolbox_talk_projects {\n      project_id\n      id\n    }\n    content {\n      en\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ea010404d3c1c9a6abbf45935835b92";

export default node;
