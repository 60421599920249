import { InspectionChecklistItemProps } from "./siteInspectionTypes";
import * as uuid from "uuid";
export const getCommomChecklistItemObject = (
  checklistItem: InspectionChecklistItemProps,
  doNotInsertChecklistEmails?: boolean,
) => {
  return {
    description: {
      data: {
        original: checklistItem.description || "",
        en: checklistItem.description || "",
      },
    },
    ...(doNotInsertChecklistEmails
      ? {}
      : {
          inspection_checklist_emails: {
            data: (checklistItem.notifyees || []).map((u) => ({
              user_id: u,
            })),
          },
        }),
    show_na: checklistItem.show_na,
    correct_answer: checklistItem.correct_answer,
    require_photo: checklistItem.require_photo,
    item_type: checklistItem.item_type,
    select_options: checklistItem.select_options,
    item_id: checklistItem.item_id ?? uuid.v4(),
    sort_index: checklistItem.sort_index || 1,
    notify_on_deficient: checklistItem.notify_on_deficient,
  };
};
