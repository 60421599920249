import { Modal } from "antd";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import Button from "src/common/components/general/button/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import {
  useGenerateAllWorkersPdfMutation,
  useGenerateAllWorkersListExcelMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

interface GCDownloadCorporateWorkersModalProps {
  gcId: string;
  returnBase64?: boolean;
}

export interface GCDownloadCorporateWorkersModalRef {
  open: () => void;
}

const GCDownloadCorporateWorkersModal = forwardRef<
  GCDownloadCorporateWorkersModalRef,
  GCDownloadCorporateWorkersModalProps
>(({ gcId, returnBase64 = false }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fileExtension, setFileExtension] = useState<"pdf" | "csv">("pdf");
  const [loading, setLoading] = useState(false);
  const [generateAllWorkersPdf] = useGenerateAllWorkersPdfMutation();
  const [generateAllWorkersListExcel] =
    useGenerateAllWorkersListExcelMutation();

  useImperativeHandle<
    GCDownloadCorporateWorkersModalRef,
    GCDownloadCorporateWorkersModalRef
  >(ref, () => ({
    open: () => setIsOpen(true),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  const downloadAllWorkerDocument = async () => {
    setLoading(true);
    try {
      if (fileExtension === "pdf") {
        const { data } = await generateAllWorkersPdf({
          variables: {
            input: {
              gcId: gcId,
            },
          },
        });

        const documentUrl = data?.generateAllWorkersPdf;
        if (!documentUrl) {
          throw new Error("generateAllWorkersPdf returned null");
        }

        downloadFromUrl(documentUrl);
        closeModal();
      } else {
        const { data } = await generateAllWorkersListExcel({
          variables: {
            input: {
              gcId: gcId,
              returnBase64: returnBase64,
            },
          },
        });

        const documentUrl = data?.generateAllWorkersListExcel;
        if (!documentUrl) {
          throw new Error("generateAllWorkersListExcel returned null");
        }

        downloadFromUrl(documentUrl);
        closeModal();
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleFileExtension = () => {
    setFileExtension((prevState) => (prevState === "csv" ? "pdf" : "csv"));
  };

  return (
    <Modal
      open={isOpen}
      confirmLoading={loading}
      title={`Download Workers List ${fileExtension === "pdf" ? "PDF" : "CSV"}`}
      okText="Download"
      cancelText="Cancel"
      onCancel={closeModal}
      onOk={downloadAllWorkerDocument}
    >
      <div className="flex content-center justify-center items-center mb-2">
        <Button
          onClick={toggleFileExtension}
          label={`Change to .${fileExtension === "csv" ? "PDF" : "CSV"}`}
          secondary
        />
      </div>
    </Modal>
  );
});

export default withCustomSuspense(GCDownloadCorporateWorkersModal);
