/**
 * @generated SignedSource<<6159517ffa6b67eb6a34135beec4f2ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { checklist_item_config_type_enum, checklist_item_insert_input, checklist_item_type_enum } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SCReportsDailySettingsInsertItemMutation$variables = {
  object: checklist_item_insert_input;
};
export type SCReportsDailySettingsInsertItemMutation$data = {
  readonly insert_checklist_item_one: {
    readonly checklist_item_configs: ReadonlyArray<{
      readonly config: checklist_item_config_type_enum;
      readonly pk: string;
    }>;
    readonly checklist_item_notify_emails: ReadonlyArray<{
      readonly email: string;
      readonly pk: string;
    }>;
    readonly data_type: string;
    readonly description: {
      readonly clientText: string;
      readonly en: string;
      readonly es: string;
      readonly id: string;
      readonly original: string;
      readonly pk: string;
      readonly pt: string;
    };
    readonly did_notify: boolean;
    readonly id: string;
    readonly option_response: string | null | undefined;
    readonly parent_id: string | null | undefined;
    readonly pk: string;
    readonly project_id: string | null | undefined;
    readonly sort_index: number;
    readonly subcontractor_id: string | null | undefined;
    readonly text_response: {
      readonly clientText: string;
      readonly en: string;
      readonly es: string;
      readonly id: string;
      readonly original: string;
      readonly pk: string;
      readonly pt: string;
    };
    readonly type: checklist_item_type_enum;
  } | null | undefined;
};
export type SCReportsDailySettingsInsertItemMutation = {
  response: SCReportsDailySettingsInsertItemMutation$data;
  variables: SCReportsDailySettingsInsertItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "config",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data_type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parent_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "option_response",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "es",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pt",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v16 = [
  (v2/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v3/*: any*/),
  {
    "alias": "clientText",
    "args": null,
    "kind": "ScalarField",
    "name": "__original_clientText",
    "storageKey": null
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "did_notify",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sort_index",
  "storageKey": null
},
v19 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v21 = [
  (v2/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  {
    "alias": "clientText",
    "args": null,
    "kind": "ScalarField",
    "name": "original",
    "storageKey": null
  },
  {
    "alias": "clientText",
    "args": null,
    "filters": null,
    "handle": "clientText",
    "key": "",
    "kind": "ScalarHandle",
    "name": "original"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCReportsDailySettingsInsertItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "checklist_item",
        "kind": "LinkedField",
        "name": "insert_checklist_item_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "checklist_item_config",
            "kind": "LinkedField",
            "name": "checklist_item_configs",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "checklist_item_notify_email",
            "kind": "LinkedField",
            "name": "checklist_item_notify_emails",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "text_response",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          (v17/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCReportsDailySettingsInsertItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "checklist_item",
        "kind": "LinkedField",
        "name": "insert_checklist_item_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "checklist_item_config",
            "kind": "LinkedField",
            "name": "checklist_item_configs",
            "plural": true,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/),
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "checklist_item_notify_email",
            "kind": "LinkedField",
            "name": "checklist_item_notify_emails",
            "plural": true,
            "selections": [
              (v19/*: any*/),
              (v20/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "text_response",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          (v17/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "description",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "32e814ef32702f51054907def445217c",
    "id": null,
    "metadata": {},
    "name": "SCReportsDailySettingsInsertItemMutation",
    "operationKind": "mutation",
    "text": "mutation SCReportsDailySettingsInsertItemMutation(\n  $object: checklist_item_insert_input!\n) {\n  insert_checklist_item_one(object: $object) {\n    id\n    checklist_item_configs {\n      pk: id\n      config\n      id\n    }\n    checklist_item_notify_emails {\n      pk: id\n      email\n      id\n    }\n    type\n    data_type\n    project_id\n    subcontractor_id\n    parent_id\n    option_response\n    text_response {\n      id\n      en\n      es\n      pt\n      original\n      pk: id\n      clientText: original\n    }\n    did_notify\n    sort_index\n    description {\n      id\n      en\n      es\n      pt\n      original\n      pk: id\n      clientText: original\n    }\n    pk: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3133e0627c0187fb7161719ad0f49f92";

export default node;
