import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  Select,
  Table,
  TimePicker,
} from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import FModal from "src/common/components/dialogs/FModal";
import { dayOptions } from "src/common/constants/dayOptions";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import { GetReportAlertConfigQuery } from "src/common/types/generated/apollo/graphQLTypes";

type AlertReportType =
  | "safety_report"
  | "daily_report"
  | "toolbox_talk"
  | "permit_closure";

type SubsFormDataVal = {
  [key: `${string}_crewIds`]: string[];
  [key: `${string}_userIds`]: string[];
  [key: `${string}_alert_all_crews`]: boolean;
  subcontractorIds?: string[];
};
export interface ReportAlertConfigFormValues extends SubsFormDataVal {
  reminder_start_time?: dayjs.Dayjs | null;
  late_time?: dayjs.Dayjs | null;
  only_for_day?: number | null;
  recurring_count: number;
  report_type: AlertReportType;
  alert_all_subs: boolean;
  name: string;
  subcontractorIds: string[];
  employeeIds: string[];
}

type SubDataMapValue = {
  subId: string;
  label: string;
  projCrewOptions: { label: string; value: string }[];
  subUsers: { value: string; label: string }[];
};

export type SubReportAlertData = {
  subId: string;
  crewIds: string[];
  userIds: string[];
  alertAllCrews: boolean;
};

export type ReportAlertConfigDrawerProps = {
  open: boolean;
  loading: boolean;
  onSubAddPocClick: (subId: string) => void;
  handleClose: () => void;
  onSave: (
    vals: ReportAlertConfigFormValues,
    subsDataToUpdate: SubReportAlertData[],
  ) => Promise<unknown> | unknown;
  reportAlertConfig?: GetReportAlertConfigQuery["report_alert_config"][number] & {
    selectedEmployees: string[];

    configSubMappedData: SubReportAlertData[];
  };
  employeeOptions: { label: string; value: string }[];
  subsDataMap: Map<string, SubDataMapValue>;
};
const reportTypeOptions: { label: string; value: AlertReportType }[] = [
  { label: "Pre-Task Plan", value: "safety_report" },
  { label: "Daily Report", value: "daily_report" },
  { label: "Toolbox Talk", value: "toolbox_talk" },
  { label: "Permit", value: "permit_closure" },
];

const ReportAlertConfigDrawer: React.FC<ReportAlertConfigDrawerProps> = ({
  reportAlertConfig,
  subsDataMap,
  ...props
}) => {
  const subOptions = useMemo(() => {
    const subOptions: { label: string; value: string }[] = [];
    subsDataMap.forEach((val, key) =>
      subOptions.push({ value: key, label: val.label }),
    );
    return subOptions;
  }, [subsDataMap]);

  const [form] = Form.useForm<ReportAlertConfigFormValues>();
  const subTableColumns = useMemo(
    (): ColumnsType<SubDataMapValue> => [
      {
        title: "Subcontractor",
        key: "label",
        width: "20%",
        dataIndex: "label",
        render: (_, row) => row.label || "",
      },
      {
        title: "Select Crews",
        key: "crews",
        width: "37%",
        dataIndex: "crews",
        render: (_, row) =>
          (row.projCrewOptions?.length || 0) > 1 && (
            <Form.Item name={`${row.subId}_crewIds`}>
              <Select
                mode="multiple"
                showSearch
                filterOption={getNormalSelectOptionsFilter}
                className="min-w-8"
                options={row.projCrewOptions}
              />
            </Form.Item>
          ),
      },
      {
        title: "Include Future Crews",
        key: "alert_all_crews",

        width: "6%",
        dataIndex: "alert_all_crews",
        render: (_, row) => (
          <Form.Item<ReportAlertConfigFormValues>
            name={`${row.subId}_alert_all_crews`}
            valuePropName="checked"
          >
            <Checkbox
              onChange={(e) => {
                if (e.target.checked)
                  form.setFieldValue(
                    `${row.subId}_crewIds`,
                    row.projCrewOptions?.map((crew) => crew.value) || [],
                  );
              }}
            />
          </Form.Item>
        ),
      },
      {
        title: "Subcontractor POC to Notify",
        key: "users",
        width: "37%",
        dataIndex: "subAdmins",
        render: (_, row) =>
          row.subUsers?.length ? (
            <div className="flex-row flex gap-1">
              <Form.Item<ReportAlertConfigFormValues>
                name={`${row.subId}_userIds`}
              >
                <Select
                  mode="multiple"
                  showSearch
                  filterOption={getNormalSelectOptionsFilter}
                  className="min-w-16"
                  options={row.subUsers}
                />
              </Form.Item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => props.onSubAddPocClick(row.subId)}
                icon={<PlusOutlined />}
              ></Button>
            </div>
          ) : (
            <Button
              type="primary"
              onClick={() => props.onSubAddPocClick(row.subId)}
            >
              Add a subcontractor POC
            </Button>
          ),
      },
    ],
    [],
  );
  const initialValues = useMemo(() => {
    if (!reportAlertConfig) return {};
    const subData = reportAlertConfig.configSubMappedData;
    // make sure values contains only properies declared as form fields
    const values: ReportAlertConfigFormValues = {
      //...reportAlertConfig,
      subcontractorIds: subData.map((sub) => sub.subId),
      employeeIds: reportAlertConfig.selectedEmployees,
      reminder_start_time: reportAlertConfig.reminder_start_time
        ? dayjs(reportAlertConfig.reminder_start_time, "HH:mm")
        : null,
      late_time: reportAlertConfig.late_time
        ? dayjs(reportAlertConfig.late_time, "HH:mm")
        : null,
      recurring_count: reportAlertConfig.recurring_count,
      report_type: reportAlertConfig.report_type as AlertReportType, // TODO: add type assection function
      alert_all_subs: true,
      name: reportAlertConfig.name,
    };
    subData.map((sub) => {
      values[`${sub.subId}_userIds`] = sub.userIds;
      values[`${sub.subId}_alert_all_crews`] = sub.alertAllCrews;
      values[`${sub.subId}_crewIds`] = sub.crewIds;
    });
    return values;
  }, [reportAlertConfig]);

  return (
    <Drawer
      className="z-20"
      open={props.open}
      title="Report Reminder Configuration"
      width={"70%"}
      onClose={props.handleClose}
      destroyOnClose
      footer={
        <div className="flex flex-row gap-1">
          <Button
            type="primary"
            loading={props.loading}
            onClick={async () => {
              const vals = await form.validateFields().catch(() => null);
              if (!vals) return;
              const subIds = vals.subcontractorIds || [];
              const subsDataToUpdate = subIds.map((subId) => ({
                subId,
                crewIds: form.getFieldValue(`${subId}_crewIds`),
                userIds: form.getFieldValue(`${subId}_userIds`),
                alertAllCrews: form.getFieldValue(`${subId}_alert_all_crews`),
              }));
              await props.onSave(vals, subsDataToUpdate);
            }}
          >
            Save
          </Button>
          <Button loading={props.loading} onClick={props.handleClose}>
            Cancel
          </Button>
        </div>
      }
    >
      <Form form={form} initialValues={initialValues}>
        <FModal.Text
          name="name"
          label="Name this reminder"
          required
          requiredMessage="Enter valid name"
        />
        <FModal.Select
          name="report_type"
          label="Select report reminder"
          required
          requiredMessage="Select a report type"
          props={{ options: reportTypeOptions }}
        />

        <Form.Item<ReportAlertConfigFormValues> shouldUpdate>
          {(form) => {
            const reportType = form.getFieldValue("report_type");
            const subIds = new Set<string>(
              form.getFieldValue("subcontractorIds") || [],
            );
            console.log(subIds);
            const subsTableData: SubDataMapValue[] = [];
            subsDataMap.forEach((sub) => {
              if (subIds.has(sub.subId)) {
                subsTableData.push(sub);
              }
            });
            return reportType ? (
              <>
                {reportType !== "permit_closure" ? (
                  <>
                    <Form.Item
                      name="late_time"
                      required
                      label="Time report is late"
                    >
                      <TimePicker showSecond={false} minuteStep={15} />
                    </Form.Item>
                    <Form.Item
                      name="reminder_start_time"
                      required
                      label="Time to send 1st reminder"
                    >
                      <TimePicker showSecond={false} minuteStep={15} />
                    </Form.Item>
                    <Form.Item label="Recurring Count" name="recurring_count">
                      <Input
                        type="number"
                        min={0}
                        placeholder="How many reminders should be sent"
                      />
                    </Form.Item>

                    {reportType === "toolbox_talk" && (
                      <FModal.Select
                        name="only_for_day"
                        required
                        label="On the day"
                        requiredMessage="Select the Day For Reminder and late"
                        props={{ options: dayOptions }}
                      />
                    )}
                  </>
                ) : (
                  <Form.Item
                    label="How many reminders should be sent on the hour"
                    name="recurring_count"
                  >
                    <Input
                      type="number"
                      placeholder="How many reminders should be sent"
                    />
                  </Form.Item>
                )}
                {props.employeeOptions.length > 0 && (
                  <Form.Item name="employeeIds" label="Send reminders to">
                    <Select
                      options={props.employeeOptions}
                      mode="multiple"
                      filterOption={getNormalSelectOptionsFilter}
                      showSearch
                    />
                  </Form.Item>
                )}

                <FModal.Checkbox
                  name="alert_all_subs"
                  props={{
                    onChange: (e) => {
                      if (e.target.checked) {
                        const subIds: string[] = [];
                        const itemsToSet: SubsFormDataVal = {};
                        subsDataMap.forEach((sub) => {
                          subIds.push(sub.subId);
                          itemsToSet[`${sub.subId}_crewIds`] =
                            sub.projCrewOptions.length > 1
                              ? sub.projCrewOptions.map((c) => c.value)
                              : [];
                          itemsToSet[`${sub.subId}_alert_all_crews`] = true;
                        });
                        itemsToSet.subcontractorIds = subIds;
                        form.setFieldsValue(itemsToSet);
                      }
                    },
                  }}
                >
                  Send to ALL existing and any future subs
                </FModal.Checkbox>
                {subOptions.length > 0 && (
                  <Form.Item name="subcontractorIds" label="Subcontractor">
                    <Select
                      options={subOptions}
                      mode="multiple"
                      onSelect={(val) => {
                        //deselct not needed as we'll only look at selected subs
                        const subData = subsDataMap.get(val);
                        if (!subData) return;
                        const crewIds =
                          subData.projCrewOptions.length > 1
                            ? subData.projCrewOptions.map((v) => v.value)
                            : [];
                        const objToSet: SubsFormDataVal = {};
                        objToSet[`${val}_crewIds`] = crewIds;
                        objToSet[`${val}_alert_all_crews`] = true;

                        form.setFieldsValue(objToSet);
                      }}
                    />
                  </Form.Item>
                )}
                {subIds.size > 0 && (
                  <Table
                    dataSource={subsTableData}
                    rowKey={(row) => row.subId}
                    columns={subTableColumns}
                  />
                )}
              </>
            ) : null;
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default ReportAlertConfigDrawer;
