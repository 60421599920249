import React from "react";
import ProfilePicture, {
  ProfileAvatarProps,
} from "src/root/routes/views/subcontractor/people/workers/worker-profile/ProfilePicture";
import { Card, Descriptions } from "antd";
import { DescriptionsProps } from "antd/lib/descriptions";

interface BusinessCardProps {
  fullName: string;
  userName?: string;
  email?: string;
  hardHatNumber?: string | null;
  phoneNumber?: string;
  company?: string;
  position?: string;
  bio?: string;
  avatar?: ProfileAvatarProps;
}

const ProfileCard: React.FunctionComponent<BusinessCardProps> = (props) => {
  const descriptionItems: DescriptionsProps["items"] = [
    {
      key: "full-name",
      label: "Full name",
      children: props.fullName,
    },
    {
      key: "hard-hat-number",
      label: "Hard hat number",
      children: props.hardHatNumber,
    },
    {
      key: "user-name",
      label: "Username",
      children: props.userName,
    },
    {
      key: "company",
      label: "Company",
      children: props.company,
    },
    {
      key: "position",
      label: "Position",
      children: props.position,
    },
    {
      key: "bio",
      label: "Bio",
      children: props.bio,
    },
    {
      key: "email",
      label: "Email",
      children: props.email,
    },
    {
      key: "phone-number",
      label: "Phone number",
      children: props.phoneNumber,
    },
  ].filter((item) => !!item.children);

  return (
    <Card title={props.fullName} className={`max-w-32`}>
      <div className={`w-full flex flex-col gap-1`}>
        {!!props.avatar && <ProfilePicture {...props.avatar} />}
        <Descriptions
          bordered={true}
          size={"small"}
          column={1}
          // layout={"vertical"}
          items={descriptionItems}
        />
      </div>
    </Card>
  );
};

export default ProfileCard;
