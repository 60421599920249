/**
 * @generated SignedSource<<0932464ec2a7a8f3be3242061ad600f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InspectionTemplateTable_inspection_templates$data = {
  readonly inspection_template_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly allow_inspection_in_app: boolean;
        readonly allow_photo_to_checklist_item: boolean;
        readonly created_at: string;
        readonly created_by_uid: string;
        readonly created_by_user: {
          readonly name: string;
        };
        readonly general_contractor_id: string | null | undefined;
        readonly id: string;
        readonly image_required: boolean;
        readonly inspection_instances: ReadonlyArray<{
          readonly name_id: string;
          readonly pk: string;
        }>;
        readonly inspection_labels: ReadonlyArray<{
          readonly id: string;
          readonly label: {
            readonly label_name: string;
          };
          readonly label_id: string;
        }>;
        readonly inspection_template_checklist_items: ReadonlyArray<{
          readonly correct_answer: string;
          readonly description: {
            readonly en: string;
            readonly id: string;
          };
          readonly description_id: string;
          readonly id: string;
          readonly inspection_checklist_item_type: {
            readonly pk: string;
            readonly text: {
              readonly en: string;
            };
          } | null | undefined;
          readonly inspection_instance_checklist_items: ReadonlyArray<{
            readonly inspection_checklist_emails: ReadonlyArray<{
              readonly user_id: string;
            }>;
            readonly inspection_checklist_item_type: {
              readonly pk: string;
              readonly text: {
                readonly en: string;
              };
            } | null | undefined;
            readonly inspection_instance_id: string;
            readonly item_id: string;
            readonly pk: string;
          }>;
          readonly item_id: string;
          readonly item_type: string;
          readonly notify_on_deficient: boolean;
          readonly pk: string;
          readonly require_photo: boolean;
          readonly select_options: string | null | undefined;
          readonly show_na: boolean;
          readonly sort_index: number;
        }>;
        readonly is_multi_inspection: boolean;
        readonly model_number: string | null | undefined;
        readonly model_number_required: boolean;
        readonly name: {
          readonly en: string;
        };
        readonly name_id: string;
        readonly pk: string;
      };
    }>;
  };
  readonly " $fragmentType": "InspectionTemplateTable_inspection_templates";
};
export type InspectionTemplateTable_inspection_templates$key = {
  readonly " $data"?: InspectionTemplateTable_inspection_templates$data;
  readonly " $fragmentSpreads": FragmentRefs<"InspectionTemplateTable_inspection_templates">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "inspection_template_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "_is_null": true
},
v7 = {
  "deleted_at": (v6/*: any*/)
},
v8 = {
  "kind": "Literal",
  "name": "where",
  "value": (v7/*: any*/)
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "item_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "inspection_checklist_item_type",
  "kind": "LinkedField",
  "name": "inspection_checklist_item_type",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "text_translation",
      "kind": "LinkedField",
      "name": "text",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./InspectionTemplateTableRefetchableQuery.graphql')
    }
  },
  "name": "InspectionTemplateTable_inspection_templates",
  "selections": [
    {
      "alias": "inspection_template_connection",
      "args": null,
      "concreteType": "inspection_templateConnection",
      "kind": "LinkedField",
      "name": "__InspectionTemplateTable_inspection_template_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "inspection_templateEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "inspection_template",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "name",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model_number",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "is_multi_inspection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "general_contractor_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model_number_required",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allow_photo_to_checklist_item",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allow_inspection_in_app",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image_required",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_by_uid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "created_by_user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "label": {
                          "label_name": "asc"
                        }
                      }
                    }
                  ],
                  "concreteType": "inspection_label",
                  "kind": "LinkedField",
                  "name": "inspection_labels",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_label_type",
                      "kind": "LinkedField",
                      "name": "label",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "label_name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label_id",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": "inspection_labels(order_by:{\"label\":{\"label_name\":\"asc\"}})"
                },
                {
                  "alias": null,
                  "args": [
                    (v8/*: any*/)
                  ],
                  "concreteType": "inspection_instance",
                  "kind": "LinkedField",
                  "name": "inspection_instances",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": "inspection_instances(where:{\"deleted_at\":{\"_is_null\":true}})"
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "sort_index": "asc"
                      }
                    },
                    (v8/*: any*/)
                  ],
                  "concreteType": "inspection_template_checklist_item",
                  "kind": "LinkedField",
                  "name": "inspection_template_checklist_items",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notify_on_deficient",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sort_index",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "show_na",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "require_photo",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "item_type",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "select_options",
                      "storageKey": null
                    },
                    (v9/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description_id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "correct_answer",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "text_translation",
                      "kind": "LinkedField",
                      "name": "description",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "where",
                          "value": {
                            "deleted_at": (v6/*: any*/),
                            "inspection_instance": (v7/*: any*/)
                          }
                        }
                      ],
                      "concreteType": "inspection_instance_checklist_item",
                      "kind": "LinkedField",
                      "name": "inspection_instance_checklist_items",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "order_by",
                              "value": {
                                "user": {
                                  "name": "asc"
                                }
                              }
                            }
                          ],
                          "concreteType": "inspection_checklist_email",
                          "kind": "LinkedField",
                          "name": "inspection_checklist_emails",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "user_id",
                              "storageKey": null
                            }
                          ],
                          "storageKey": "inspection_checklist_emails(order_by:{\"user\":{\"name\":\"asc\"}})"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "inspection_instance_id",
                          "storageKey": null
                        },
                        (v10/*: any*/)
                      ],
                      "storageKey": "inspection_instance_checklist_items(where:{\"deleted_at\":{\"_is_null\":true},\"inspection_instance\":{\"deleted_at\":{\"_is_null\":true}}})"
                    },
                    (v10/*: any*/)
                  ],
                  "storageKey": "inspection_template_checklist_items(order_by:{\"sort_index\":\"asc\"},where:{\"deleted_at\":{\"_is_null\":true}})"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "aec9c63c67042b2b470c48e7b5aa0a9f";

export default node;
