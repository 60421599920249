import { message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import pluralize from "pluralize";
import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-relay/hooks";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import useCustomLazyLoadQuery from "src/common/hooks/useCustomLazyLoadQuery";
import ageByBirthDate from "src/common/functions/ageByBirthDate";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { SCWorkersMutation } from "src/common/types/generated/relay/SCWorkersMutation.graphql";
import {
  SCWorkersQuery,
  SCWorkersQuery$data,
} from "src/common/types/generated/relay/SCWorkersQuery.graphql";
import { SCWorkers_Update_Project_Worker_Mutation } from "src/common/types/generated/relay/SCWorkers_Update_Project_Worker_Mutation.graphql";
import SCWorkersUI, { Worker } from "./SCWorkersUI";
import { useSubcontractor } from "src/common/components/SubcontractorContext";
import useUpsertAndDeletePreviousSub from "src/common/api/relay/mutationHooks/useUpsertAndDeletePreviousSub";
import SCEmployeeVerificationModal from "src/common/components/dialogs/SCEmployeeVerificationModal";

const workersListQueryNode = graphql`
  query SCWorkersQuery($subcontractorId: uuid!, $order: order_by!) {
    project_subcontractor_connection(
      where: { subcontractor_id: { _eq: $subcontractorId } }
      order_by: { project: { name: asc } }
    ) {
      edges {
        node {
          project_id
          project {
            name
          }
        }
      }
    }
    worker_connection(
      where: { subcontractor_id: { _eq: $subcontractorId } }
      order_by: { user: { name: $order } }
      first: 100000
    ) @connection(key: "SCWorkersQuery_worker_connection", filters: []) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          worker_role {
            value
          }
          user {
            name
            birth_date
            email
            created_password
            phone_number
            profile_picture {
              sm_url
              url
              blurhash
            }
          }
          worker_title {
            translation {
              en
            }
          }
          worker_drug_tests(
            where: {
              status: { _is_null: false }
              subcontractor_id: { _eq: $subcontractorId }
              entered_through_user: {
                role: { _in: ["subcontractor_employee", "worker"] }
              }
            }
          ) {
            id
          }
          worker_projects(
            where: {
              subcontractor_id: { _eq: $subcontractorId }
              archived_at: { _is_null: true }
            }
          ) {
            id
            created_at
            archived_at
            is_last
            deleted_at
            project {
              name
              pk: id @__clientField(handle: "pk")
              general_contractor {
                name
                employee_verification
              }
            }
            subcontractor {
              name
            }
            worker {
              pk: id @__clientField(handle: "pk")
              user {
                name
              }
              status
            }
          }
          worker_certifications {
            id
            expires_on
            certification {
              name
            }
          }
        }
      }
    }
    prevWorkers: worker_previous_subcontractor_connection(
      where: { subcontractor_id: { _eq: $subcontractorId } }
      order_by: { worker: { user: { name: $order } } }
    ) {
      edges {
        node {
          worker {
            id
            pk: id @__clientField(handle: "pk")
            worker_role {
              value
            }
            subcontractor_id
            user {
              name
              phone_number
              birth_date
              profile_picture {
                sm_url
                blurhash
                url
              }
            }
            worker_title {
              translation {
                en
              }
            }
            worker_drug_tests {
              id
            }
            worker_projects(
              where: { subcontractor_id: { _eq: $subcontractorId } }
            ) {
              id
              project_id
              archived_at
            }
            worker_certifications {
              id
              expires_on
              certification {
                name
              }
            }
          }
        }
      }
    }
  }
`;

interface SCWorkersProps {
  showEmployeeVerificationModal?: boolean;
}

export const updateWorkerMutate = graphql`
  mutation SCWorkersMutation($_set: worker_set_input, $where: worker_bool_exp!)
  @raw_response_type {
    update_worker(_set: $_set, where: $where) {
      affected_rows
      returning {
        id
        current_worker_role
      }
    }
  }
`;
export const updateProjectWorkersForMultipleProjectAndMultipleWorkerMutation = graphql`
  mutation SCWorkers_Update_Project_Worker_Mutation(
    $projectIds: [uuid!]!
    $workerIds: [uuid!]!
    $subId: uuid!
  ) {
    u1: update_project_worker(
      where: {
        worker_id: { _in: $workerIds }
        project_id: { _in: $projectIds }
        subcontractor_id: { _neq: $subId }
      }
      _set: { is_last: false }
    ) {
      affected_rows
    }
    u2: update_project_worker(
      where: {
        project_id: { _in: $projectIds }
        worker_id: { _in: $workerIds }
        subcontractor_id: { _eq: $subId }
      }
      _set: { is_last: true }
    ) {
      affected_rows
    }
  }
`;
const SCWorkers: React.FC<SCWorkersProps> = ({
  showEmployeeVerificationModal,
}) => {
  const subcontractor = useSubcontractor();
  const [fetchKey, setFetchKey] = useState(0);

  const [
    employeeVerificationModalVisible,
    setEmployeeVerificationModalVisible,
  ] = useState(!!showEmployeeVerificationModal);

  useEffect(() => {
    setEmployeeVerificationModalVisible(!!showEmployeeVerificationModal);
  }, [showEmployeeVerificationModal]);

  const workersListData = useCustomLazyLoadQuery<SCWorkersQuery>(
    workersListQueryNode,
    {
      subcontractorId: subcontractor.id,
      order: Order_By.Asc,
    },
    { fetchPolicy: "network-only", fetchKey },
  );
  const [updateWorker, isUpdatingWorker] =
    useMutation<SCWorkersMutation>(updateWorkerMutate);
  const [updateProjectWorkers, isUpdatingProjectWorkers] =
    useMutation<SCWorkers_Update_Project_Worker_Mutation>(
      updateProjectWorkersForMultipleProjectAndMultipleWorkerMutation,
    );
  const [loading, setLoading] = useState(false);

  const upsertPreviousSubs = useUpsertAndDeletePreviousSub();
  const projects = workersListData.project_subcontractor_connection.edges.map(
    (p) => ({ id: p.node.project_id, name: p.node.project.name }),
  );
  const refetch = () => setFetchKey((i) => i + 1);

  const addBackTerminatedWorkers = async (input: {
    workersToAdd: Array<string>;
    workerProjectsUnderThisSub: Array<string>;
    diffSubWorkers: Array<{ id: string; prevSub: string }>;
  }) => {
    setLoading(true);
    try {
      await Promise.all([
        updateProjectWorkers({
          variables: {
            subId: subcontractor.id,
            workerIds: input.workersToAdd,
            projectIds: input.workerProjectsUnderThisSub,
          },
        }),
        updateWorker({
          variables: {
            where: { uid: { _in: input.workersToAdd } },
            _set: { subcontractor_id: subcontractor.id },
          },
        }),
        upsertPreviousSubs({
          variables: {
            deleteWhere: {
              worker_id: { _in: input.workersToAdd },
              subcontractor_id: { _eq: subcontractor.id },
            },
            objects: input.diffSubWorkers.map((x) => {
              let obj = {
                worker_id: x.id,
                subcontractor_id: x.prevSub,
              };
              return obj;
            }),
          },
        }),
      ])
        .then(() => {
          refetch();
          message.success(
            `Added Back ${pluralize(
              "worker",
              input.workersToAdd.length,
              true,
            )}`,
          );
        })
        .catch((e) => {
          message.error(`Error:${e.name} ${e.message}`);
        });
    } catch (error) {
      notification.error({
        message: "ERROR: Couldn't add back worker",
        description:
          error instanceof Error ? error.message : JSON.stringify(error),
      });
    }
    setLoading(false);
  };
  const terminateWorkers = async (workerIds: Array<string>) => {
    setLoading(true);
    try {
      await Promise.all([
        updateWorker({
          variables: {
            where: {
              uid: {
                _in: workerIds,
              },
            },
            _set: {
              subcontractor_id: null,
            },
          },
        }),
      ]).then(() =>
        upsertPreviousSubs({
          variables: {
            deleteWhere: { id: { _is_null: true } },
            objects: workerIds.map((workerId) => {
              let obj = {
                subcontractor_id: subcontractor.id,
                worker_id: workerId,
              };
              return obj;
            }),
          },
        }).then(() => {
          refetch();
          message.success(
            `Deleted ${pluralize("worker", workerIds.length, true)}`,
          );
        }),
      );
    } catch (error) {
      notification.error({
        message: "ERROR: Couldn't terminate worker",
        description:
          error instanceof Error ? error.message : JSON.stringify(error),
      });
    }
    setLoading(false);
  };
  const { workers, workerProjects } = useMemo(() => {
    const workers: Worker[] = [];
    const workerProjects: Array<
      SCWorkersQuery$data["worker_connection"]["edges"][0]["node"]["worker_projects"][0]
    > = [];
    workersListData.worker_connection.edges.forEach(({ node }) => {
      if (employeeVerificationModalVisible)
        workerProjects.push(...node.worker_projects);
      workers.push({
        imageUrl: node.user.profile_picture
          ? node.user.profile_picture.sm_url
            ? node.user.profile_picture?.sm_url
            : node.user.profile_picture.url
          : undefined,
        name: node.user.name,
        email: node.user.email,
        phoneNumber: node.user.phone_number || undefined,
        createdPassword: node.user.created_password,
        age:
          typeof node.user.birth_date === "string"
            ? ageByBirthDate(node.user.birth_date)
            : undefined,
        dataId: node.id,
        id: node.pk,
        role: node.worker_role.value,
        projectsAmount: node.worker_projects.filter((pw) => !pw.archived_at)
          .length,
        drugTestsAmount: node.worker_drug_tests.length ?? undefined,
        certificates: node.worker_certifications.map((cert) => {
          return {
            name: cert.certification.name,
            expiryDate: cert.expires_on,
          };
        }),
        trade: node.worker_title?.translation.en,
        blurhash:
          node.user.profile_picture?.blurhash ??
          node.user.profile_picture?.url ??
          undefined,
        terminated: {
          status: false,
          loading: loading || isUpdatingWorker,
          onChange: async () => {
            await terminateWorkers([node.pk]);
          },
        },
      });
    });

    // Process terminated workers
    workersListData.prevWorkers.edges.forEach((x) => {
      const node = x.node.worker;
      if (node) {
        workers.push({
          dataId: node.id,
          imageUrl: node.user.profile_picture
            ? node.user.profile_picture.sm_url
              ? node.user.profile_picture?.sm_url
              : node.user.profile_picture.url
            : undefined,
          name: node.user.name,
          phoneNumber: node.user.phone_number || undefined,
          age:
            typeof node.user.birth_date === "string"
              ? ageByBirthDate(node.user.birth_date)
              : undefined,
          id: node.pk,
          role: node.worker_role.value,
          projectsAmount: node.worker_projects.filter((pw) => !!pw.archived_at)
            .length,
          drugTestsAmount: node.worker_drug_tests.length ?? undefined,
          certificates: node.worker_certifications.map((cert) => {
            return {
              name: cert.certification.name,
              expiryDate: cert.expires_on,
            };
          }),
          trade: node.worker_title?.translation.en,
          underAnotherSub: node.subcontractor_id !== null, //it's already terminated so it cannot under the sub currently logged in
          currentWorkerSubId: node.subcontractor_id,
          //currentWorkerSubId is not to be used anywhere right now. it will be used when we implement multiple workers addBack button
          blurhash:
            node.user.profile_picture?.blurhash ??
            node.user.profile_picture?.url ??
            undefined,
          terminated: {
            status: true,
            loading: loading || isUpdatingWorker,
            onChange: async () => {
              await addBackTerminatedWorkers({
                workersToAdd: [node.pk],
                workerProjectsUnderThisSub: node.worker_projects.map(
                  (pw) => pw.project_id,
                ),
                diffSubWorkers: node.subcontractor_id
                  ? [{ id: node.pk, prevSub: node.subcontractor_id }]
                  : [],
              });
            },
          },
        });
      }
    });

    return { workers, workerProjects };
  }, [workersListData]);

  return (
    <>
      <SCEmployeeVerificationModal
        workerProjects={workerProjects}
        visible={employeeVerificationModalVisible}
        onClose={() => {
          setEmployeeVerificationModalVisible(false);
        }}
      />
      <SCWorkersUI
        {...{
          workers,
          projects,
          onWorkerAdded: refetch,
          onWorkerTerminate: refetch,
          loadingWorkers: loading || isUpdatingWorker,
        }}
      />
    </>
  );
};

interface SCWorkersWrapperProps {
  showEmployeeVerificationModal?: boolean;
}

const SCWorkersWrapper: React.FunctionComponent<SCWorkersWrapperProps> = ({
  showEmployeeVerificationModal,
}) => {
  return (
    <BasicWrapper scrollable>
      <SCWorkers
        showEmployeeVerificationModal={showEmployeeVerificationModal}
      />
    </BasicWrapper>
  );
};

export default SCWorkersWrapper;
