import { TableProps } from "antd/es/table";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import { Diff } from "utility-types";
import withCustomSuspense from "../../general/withCustomSuspense";
import ScrollTable, {
  FilterRecord,
  FilterSorterData,
  ScrollTableColumn,
  ScrollTableProps,
  SorterData,
  SortOrder,
} from "./ScrollTable";

import { DocumentNode } from "graphql";
import { isDevelopmentBuild } from "../../../constants/environment";
import buildGraphQLSearchFilter from "./utils/graphql-filter-functions/buildGraphQLSearchFilter";
import buildGraphQLSorter from "./utils/graphql-filter-functions/buildGraphQLSorter";
import buildGraphQLFilter from "./utils/graphql-filter-functions/buildGraphqlFilter";
import arrayFilterSearchData from "./utils/client-filter-functions/arrayFilterSearchData";
import arrayFilterData from "./utils/client-filter-functions/arrayFilterData";
import arraySortData from "./utils/client-filter-functions/arraySortData";
import { DataRecord } from "./utils/dataScrollTableTypes";
import { useQuery } from "@apollo/client";
import { JSONObject } from "src/common/types/manual/JsonType";
import LargeTableSkeleton from "./LargeTableSkeletion";
import {
  loadSortOrderRecord,
  removeSortOrderRecord,
  saveSortOrderRecord,
} from "./SortOrderStorage";

export type DataApolloScrollTableRef = {
  refetch: (silent?: boolean) => void;
};

interface BaseDataApolloScrollTableProps<
  VariablesType extends { where?: unknown },
  ColumnKeys,
  DataRecordType,
  Query extends { [key: string]: unknown },
> {
  ref: any;
  title?: string;
  queryNode: DocumentNode;
  countFromTotalCountNode?: boolean; // Alex: disscuss if we really need it. // useful to show total Count node in a summary table, e.g. InspectionResultSummaryTable
  queryDataIndex: Exclude<keyof Query, "__typename">;
  aggregateCountIndex: Exclude<keyof Query, "__typename">;
  customFilters?: CustomFilterType[];
  headerComponent?: React.ReactElement;
  //  setCurrentTypePermit?: any;
  //currentTypePermit?: string;
  where: VariablesType["where"];
  extraQueryVariables?: Partial<
    Omit<VariablesType, "where" | "order_by" | "offset" | "limit">
  >;
  excludedKeys?: Array<ColumnKeys>;
  loadAll?: boolean;
  defaultTableSort?: {
    key: ColumnKeys;
    order: SortOrder;
  };
  headerContol?: () => React.ReactNode;
  onRowClick?: (item: DataRecordType) => void;
  columns: Array<
    ScrollTableColumn<DataRecordType, ColumnKeys> & {
      queryIncludeVarKey?: keyof VariablesType;
    }
  >;
  disableSuspense?: boolean;
}

export type DataApolloScrollTableProps<
  VariablesType extends { where?: unknown },
  ColumnKeys,
  DataRecordType,
  Query extends { [key: string]: unknown },
> = BaseDataApolloScrollTableProps<
  VariablesType,
  ColumnKeys,
  DataRecordType,
  Query
> &
  Diff<
    TableProps<DataRecordType>,
    BaseDataApolloScrollTableProps<
      VariablesType,
      ColumnKeys,
      DataRecordType,
      Query
    >
  > &
  Diff<
    Omit<ScrollTableProps<any>, "dataSource" | "onChange" | "totalCount">,
    BaseDataApolloScrollTableProps<
      VariablesType,
      ColumnKeys,
      DataRecordType,
      Query
    >
  > & {
    showRowBorderOnHover?: boolean;
    sortOrderStorageKey?: string;
    extraSearchDataIndexes?: Array<
      Array<{
        index: string;
        extraConstraint?: JSONObject; // remember extraConstraint is only for array relation indexes
      }>
    >;
    newCustomTableLook?: boolean; // TODO - ask Seva to remove this. This option is to control whether to display the new table design or now
  };

export interface DataApolloScrollTableImplementorProps<
  VariablesType extends { where?: unknown },
  ColumnKeys,
  DataRecordType,
  Query extends { [key: string]: unknown },
> extends Pick<
    DataApolloScrollTableProps<
      VariablesType,
      ColumnKeys,
      DataRecordType,
      Query
    >,
    | "excludedKeys"
    | "loadAll"
    | "customFilters"
    | "headerComponent"
    | "datePickerDataTitle"
    | "datePickerDataIndex"
    | "where"
    | "defaultTableSort"
    | "title"
    | "searchDataIndex"
    | "headerContol"
    | "headerComponent"
    | "disableSuspense"
    | "topBarButtons"
  > {
  onRowClick?: (args: DataRecordType) => void;
}

const DataApolloScrollTable = <
  VariablesType extends { where?: unknown },
  ColumnKeys extends string,
  DataRecordType,
  Query extends { [key: string]: unknown },
>(
  {
    where,
    columns,
    title,
    extraSearchDataIndexes,
    queryNode,
    queryDataIndex,
    extraQueryVariables,
    aggregateCountIndex,
    onRowClick,
    defaultTableSort,
    loadAll = false,
    disableSuspense = false,
    sortOrderStorageKey,
    ...props
  }: DataApolloScrollTableProps<
    VariablesType,
    ColumnKeys,
    DataRecordType,
    Query
  >,
  ref: any,
) => {
  const [filterSorterData, setFilterSorterData] = useState<FilterSorterData>(
    () => {
      // do it once on first render only
      const storedSorter = sortOrderStorageKey
        ? loadSortOrderRecord(sortOrderStorageKey)
        : null;
      const sortColumn =
        storedSorter && columns.find((c) => c.key === storedSorter.key);
      const defaultSortCol =
        sortColumn || columns.find((c) => !!c.defaultSortOrder);
      return {
        filterData: [],
        sorterData: defaultSortCol
          ? {
              key: defaultSortCol.key,
              dataIndex: defaultSortCol.dataIndex,
              order:
                storedSorter?.order || defaultSortCol.defaultSortOrder || null,
            }
          : undefined,
      };
    },
  );

  const applyNewFilterSorterData = useCallback(
    (value: FilterSorterData) => {
      if (sortOrderStorageKey) {
        const { sorterData } = value;
        if (sorterData) {
          saveSortOrderRecord(sortOrderStorageKey, {
            key: sorterData.key,
            order: sorterData.order,
          });
        } else {
          removeSortOrderRecord(sortOrderStorageKey);
        }
      }
      setFilterSorterData(value);
    },
    [sortOrderStorageKey],
  );

  const filters = useMemo(
    () => [
      ...filterSorterData.filterData,
      ...columns
        .filter((c) => c.searchValue)
        .map(
          (c): FilterRecord => ({
            key: c.key,
            dataIndex: c.dataIndex,
            filter: {
              type: "search",
              text: c.searchValue!,
            },
          }),
        ),
    ],
    [filterSorterData.filterData, columns],
  );

  const [clientFiltering] = useState(loadAll);
  // if loadAll is true - fetch all without filter and perform cliend side filtering
  const whereStatement = useMemo(
    () =>
      clientFiltering
        ? where
        : {
            _and: [
              where,
              buildGraphQLFilter(filters),
              buildGraphQLSearchFilter(
                filterSorterData.searchData,
                extraSearchDataIndexes,
              ),
            ],
          },
    [clientFiltering, filters, extraSearchDataIndexes],
  );

  // and client side sorting
  const orderBy = useMemo(() => {
    const column = columns.find((c) => !!c.defaultSortOrder);
    if (!column) {
      // why do we require sort?
      throw new Error("Table must have at least 1 sortable column");
    }

    const sorterData: SorterData =
      clientFiltering || !filterSorterData.sorterData
        ? { key: column.key, dataIndex: column.dataIndex, order: "asc" }
        : filterSorterData.sorterData;

    return buildGraphQLSorter(sorterData);
  }, [clientFiltering, filterSorterData.sorterData]);
  console.log("order by", orderBy, clientFiltering);

  const variables = useMemo(
    () => ({
      where: whereStatement,
      limit: clientFiltering
        ? ScrollTable.LOAD_ALL_COUNT
        : ScrollTable.INITIAL_COUNT,
      order_by: orderBy,
      offset: 0,
      ...columns.reduce((a, c) => {
        return !!c.queryIncludeVarKey
          ? {
              ...a,
              [c.queryIncludeVarKey]:
                a[c.queryIncludeVarKey] || !props.excludedKeys?.includes(c.key),
            }
          : a;
      }, {} as any),
      ...(extraQueryVariables ? extraQueryVariables : {}),
    }),
    [whereStatement, orderBy, clientFiltering],
  );

  // if we want change disableSuspense, we must remount component.
  //   otherwise we will have conditional hook problem. (react-hooks/rules-of-hooks)
  // so we copy disableSuspense to state on first render, and use it from state
  const [noSuspense] = useState(disableSuspense || false);
  if (isDevelopmentBuild && noSuspense !== disableSuspense) {
    throw new Error(
      "disableSuspense must remain unchanged during component lifecycle. Remount compoenent using key if you need to change it",
    );
  }
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    data,
    refetch,
    loading: dataLoading,
    fetchMore,
    error,
  } = useQuery(queryNode, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  useImperativeHandle<DataApolloScrollTableRef, DataApolloScrollTableRef>(
    ref,
    () => ({ refetch: () => refetch() }),
  );
  let items = (data?.[queryDataIndex] as unknown as Array<DataRecord>) || [];
  const totalCount = data?.[aggregateCountIndex]?.aggregate?.count || 0;
  // replace this with better code (automatically switch to client mode on small datasets)
  ///   -----------
  if (error) {
    throw error;
  }

  if (clientFiltering) {
    // perform client side filtering
    if (filters.length > 0) {
      items = arrayFilterData(items, filters);
    }
    // search filter
    if (filterSorterData.searchData) {
      items = arrayFilterSearchData(items, filterSorterData.searchData);
    }
    // and sorting
    if (filterSorterData.sorterData) {
      items = arraySortData(items, filterSorterData.sorterData);
    }
  }

  const itemsCount = props.countFromTotalCountNode
    ? totalCount ?? items.length
    : clientFiltering
    ? items.length
    : totalCount ?? items.length;

  return (
    <ScrollTable
      {...props}
      dataSource={items}
      headerContol={props.headerContol}
      headerComponent={props.headerComponent}
      totalCount={itemsCount}
      columns={columns}
      title={title || ""}
      loading={dataLoading}
      filterSorter={filterSorterData}
      onFilterSorterChange={applyNewFilterSorterData}
      interactive={!!onRowClick || props.showRowBorderOnHover}
      onRow={(item: any) => ({
        onClick: (e) => {
          e.stopPropagation();
          if (onRowClick) onRowClick(item);
        },
      })}
      pagination={{
        hasNext: items.length < totalCount,
        isLoadingNext: loadingMore,
        onLoadMoreClick: async () => {
          setLoadingMore(true);
          try {
            await fetchMore({
              variables: {
                ...variables,
                offset: items.length,
              },
              query: queryNode,
              updateQuery: (prevResult, { fetchMoreResult }) => {
                // Merge new data with existing data
                return {
                  ...prevResult,
                  [queryDataIndex]: [
                    ...prevResult[queryDataIndex],
                    ...fetchMoreResult[queryDataIndex],
                  ],
                };
              },
            });
          } finally {
            setLoadingMore(false);
          }
        },
      }}
    />
  );
};
//@ts-ignore
export default withCustomSuspense(forwardRef(DataApolloScrollTable), {
  fallback: <LargeTableSkeleton />,
}) as typeof DataApolloScrollTable;
