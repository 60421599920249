import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import PreTaskPlansTable from "src/common/components/tables/PreTaskPlansTable";
import { RangePickerProps } from "antd/lib/date-picker";

const { RangePicker } = DatePicker;

interface SCReportsProps {
  subcontractorId: string;
  projectId?: string;
}

const SCReports: FC<SCReportsProps> = ({ subcontractorId, projectId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get("start");
  const endDate = searchParams.get("end");
  const [start, setStart] = useState(startDate ? dayjs(startDate) : null);
  const [end, setEnd] = useState(endDate ? dayjs(endDate) : null);

  const onRangeChange: RangePickerProps["onChange"] = (range) => {
    if (!range) {
      setStart(() => null);
      setEnd(() => null);
      return;
    }
    setStart(dayjs(range[0]));
    setEnd(dayjs(range[1]));
  };

  return (
    <BasicWrapper>
      <RangePicker
        onChange={onRangeChange}
        value={start && end ? [start, end] : undefined}
        allowClear
      />

      <PreTaskPlansTable
        title="Pre-Task Plans"
        excludedKeys={
          projectId ? ["subcontractor", "project"] : ["subcontractor"]
        }
        where={{
          subcontractor_id: {
            _eq: subcontractorId,
          },
          ...(projectId ? { project_id: { _eq: projectId } } : {}),
          created_at: { _lte: end?.format(), _gte: start?.format() },
        }}
        onRowClick={(pk) => {
          if (start && end)
            setSearchParams({
              reportId: pk,
              start: start.format("YYYY-MM-DD"),
              end: end.format("YYYY-MM-DD"),
            });
          else setSearchParams({ reportId: pk });
        }}
      />
    </BasicWrapper>
  );
};

export default SCReports;
