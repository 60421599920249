import { produce } from "immer";
import AddSubcontractorEmployeeModal from "src/common/components/dialogs/AddSubcontractorEmployeeModal";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useApolloClient, useSuspenseQuery } from "@apollo/client";
import { Button, Form, notification, Popconfirm, Select, Table } from "antd";
import { useCallback, useMemo, useState } from "react";
import withProjectIdUrlParam from "src/common/hoc/withProjectIdUrlParam";
import {
  GetReportAlertConfigDocument,
  GetReportAlertConfigQuery,
  GetReportAlertConfigQueryVariables,
  useUpdateReportAlertConfigMutation,
  useInsertReportAlertConfigMutation,
  useDeleteReportAlertConfigMutation,
  Report_Alert_Config_Subcontractor_Insert_Input,
  Report_Alert_Config_User_Insert_Input,
  Report_Alert_Config_Crew_Insert_Input,
  useCreateSubcontractorEmployeeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import ProjectIdProps from "src/common/types/manual/ProjectIdProps";
import ReportAlertConfigDrawer, {
  ReportAlertConfigDrawerProps,
  ReportAlertConfigFormValues,
  SubReportAlertData,
} from "./ReportAlertConfigDrawer";
import NotifyUserException from "src/utility-features/error-handling/NotifyUserException";
import dayjs from "dayjs";
import useAuthUser from "src/common/hooks/useAuthUser";
import { dayOptions } from "src/common/constants/dayOptions";
import AddSubcontractorProjectTeamModal from "../siteSafetyPlan/components/AddSubcontractorProjectTeamModal";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import { ProjectSubcontractorEmployeeFrag$data } from "src/common/types/generated/relay/ProjectSubcontractorEmployeeFrag.graphql";

const constructProjSubEmployeeFromInsertedProjSubEmployeeFrag = (
  newProjSubEmp: Omit<ProjectSubcontractorEmployeeFrag$data, " $fragmentType">,
): NonNullable<
  GetReportAlertConfigQuery["project_by_pk"]
>["project_subcontractors"][number]["project_subcontractor_employees"][number] => ({
  __typename: "project_subcontractor_employee",
  id: newProjSubEmp.pk,
  subcontractor_employee: {
    __typename: "subcontractor_employee",
    user_id: newProjSubEmp.subcontractor_employee_id,
    employee_title: newProjSubEmp.subcontractor_employee.employee_title
      ? {
          __typename: "subcontractor_employee_title",
          name: {
            __typename: "text_translation",
            en: newProjSubEmp.subcontractor_employee.employee_title.name_text,
          },
        }
      : null,
    user: {
      __typename: "user",
      name: newProjSubEmp.subcontractor_employee.user.name,
    },
  },
});
const GCProjectAlertConfig: React.FC<ProjectIdProps> = ({ projectId }) => {
  const { data, refetch } = useSuspenseQuery<
    GetReportAlertConfigQuery,
    GetReportAlertConfigQueryVariables
  >(GetReportAlertConfigDocument, { variables: { projectId } });
  const project = data.project_by_pk;
  if (!project) throw new Error("no project found for given projectId");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  console.log("Data", data.project_by_pk);
  const employeeOptions = useMemo(
    () =>
      project.project_employees.map((pe) => ({
        value: pe.employee.uid,
        label:
          pe.employee.user.name +
          (pe.employee.employee_title
            ? `, ${pe.employee.employee_title?.name.en}`
            : ""),
      })),
    [project.project_employees],
  );
  const subsDataMap = useMemo(
    () =>
      new Map(
        project.project_subcontractors.map((ps) => [
          ps.subcontractor.id,
          {
            subId: ps.subcontractor.id,
            label: ps.subcontractor.name,
            projCrewOptions: ps.project_crews.map((crew) => ({
              label: crew.name,
              value: crew.id,
            })),
            subUsers: ps.project_subcontractor_employees.map((pse) => ({
              value: pse.subcontractor_employee.user_id,
              label:
                pse.subcontractor_employee.user.name +
                (pse.subcontractor_employee.employee_title
                  ? `, ${pse.subcontractor_employee.employee_title?.name.en}`
                  : ""),
            })),
          },
        ]),
      ),
    [project.project_subcontractors],
  );

  const [deleteConfigMutate] = useDeleteReportAlertConfigMutation();
  const [insertConfig, { loading: inserting }] =
    useInsertReportAlertConfigMutation();
  const [form] = Form.useForm();
  const deleteConfig = useCallback(
    async (deleteId: string) =>
      await deleteConfigMutate({
        variables: { id: deleteId },
        optimisticResponse: {
          delete_report_alert_config_by_pk: {
            __typename: "report_alert_config",
            id: deleteId,
          },
        },
        update: (cache) => {
          cache.modify<GetReportAlertConfigQuery>({
            fields: {
              report_alert_config: (existing = [], { readField }) => {
                return existing.filter((item) => {
                  console.log(readField("id", item));
                  return readField("id", item) !== deleteId;
                });
              },
            },
          });
        },
      }),

    [deleteConfigMutate],
  );
  const reportConfigs = useMemo(
    () =>
      data.report_alert_config.map((config) => {
        const usersSet = new Set(config.config_users.map((u) => u.user_id));
        const crewsSet = new Set(
          config.config_crews.map((c) => c.project_crew_id),
        );
        const selectedEmployees = employeeOptions.reduce<string[]>((userIds, user) => {
          if (usersSet.has(user.value)) userIds.push(user.value);
          return userIds;
        }, []);
        const configSubMappedData = config.config_subcontractors.map((sub): SubReportAlertData => {
          const subId = sub.subcontractor_id;
          const subData = subsDataMap.get(subId);
          return {
            ...(subData || {}),
            subId,
            alertAllCrews: sub.alert_all_crews,
            crewIds: (subData?.projCrewOptions || []).reduce<string[]>(
              (crewIds, crew) => {
                if (crewsSet.has(crew.value)) crewIds.push(crew.value);
                return crewIds;
              },
              [],
            ),
            userIds: (subData?.subUsers || []).reduce<string[]>((userIds, user) => {
              if (usersSet.has(user.value)) userIds.push(user.value);
              return userIds;
            }, []),
          };
        });
        return { ...config, selectedEmployees, configSubMappedData };
      }),
    [data.report_alert_config, subsDataMap],
  );

  console.log(reportConfigs);
  const [addEmployeeOpenForSub, setAddEmployeeOpenForSub] = useState<string>();

  const [editingConfig, setEditingConfig] =
    useState<(typeof reportConfigs)[number]>();
  const [updateConfig, { loading: updating }] =
    useUpdateReportAlertConfigMutation();
  const authUser = useAuthUser();
  const getAlertConfigToSet = useCallback(
    (vals: ReportAlertConfigFormValues) => ({
      reminder_start_time: vals.reminder_start_time
        ? vals.reminder_start_time.format("HH:mm")
        : null,
      late_time: vals.late_time ? vals.late_time.format("HH:mm") : null,
      only_for_day: vals.only_for_day,
      alert_all_subs: vals.alert_all_subs,
      name: vals.name,
      recurring_count: vals.recurring_count,
      report_type: vals.report_type,
    }),
    [],
  );
  type GetConfigSubCrewAndUsersArgs = Parameters<
    ReportAlertConfigDrawerProps["onSave"]
  >;

  const getConfigSubCrewAndUsersToAdd = (
    vals: GetConfigSubCrewAndUsersArgs[0],
    configSubAndCrewsUsers: GetConfigSubCrewAndUsersArgs[1],
    editingConfigId?: string,
  ) => {
    const configSubObjs: Report_Alert_Config_Subcontractor_Insert_Input[] = [];
    const configCrewObjs: Report_Alert_Config_Crew_Insert_Input[] = [];
    const editingConfigToPut = editingConfigId
      ? { report_alert_config_id: editingConfigId }
      : {};

    const configUserObjs = vals.employeeIds.map((employeeId) => ({
      user_id: employeeId,
      ...editingConfigToPut,
    }));
    configSubAndCrewsUsers.forEach((s) => {
      configSubObjs.push({
        subcontractor_id: s.subId,
        alert_all_crews: !!s.alertAllCrews,
        project_id: projectId,
        ...editingConfigToPut,
      });
      (s.crewIds || []).forEach((crewId) =>
        configCrewObjs.push({ project_crew_id: crewId, ...editingConfigToPut }),
      );
      (s.userIds || []).forEach((userId) =>
        configUserObjs.push({ user_id: userId, ...editingConfigToPut }),
      );
    });
    return { configCrewObjs, configSubObjs, configUserObjs };
  };
  const client = useApolloClient();
  const onInsertNewConfig: ReportAlertConfigDrawerProps["onSave"] = async (
    vals,
    subsDataToUpdate,
  ) => {
    const { configCrewObjs, configSubObjs, configUserObjs } =
      getConfigSubCrewAndUsersToAdd(vals, subsDataToUpdate);
    await insertConfig({
      variables: {
        objects: [
          {
            ...getAlertConfigToSet(vals),
            project_id: projectId,
            created_by_uid: authUser.uid,
            config_subcontractors: { data: configSubObjs },
            config_crews: { data: configCrewObjs },
            config_users: { data: configUserObjs },
          },
        ],
      },
      update: (cache, { data }) => {
        const insertedEntry = data?.insert_report_alert_config?.returning[0];
        if (!insertedEntry) throw new Error("Server reutrned null response");

        cache.modify<GetReportAlertConfigQuery>({
          fields: {
            report_alert_config: (existing = [], { toReference }) => {
              const inserttedRef = toReference(insertedEntry);
              if (!inserttedRef) return existing;
              return [insertedEntry, ...existing];
            },
          },
        });
      },
    });
  };
  const onEditSave: ReportAlertConfigDrawerProps["onSave"] = async (
    vals,
    subsDataToUpdate,
  ) => {
    if (!editingConfig) throw new Error("editing Config not found");
    const { configCrewObjs, configSubObjs, configUserObjs } =
      getConfigSubCrewAndUsersToAdd(vals, subsDataToUpdate, editingConfig.id);
    const configToSet = getAlertConfigToSet(vals);
    await updateConfig({
      variables: {
        configId: editingConfig.id,
        configSet: configToSet,
        configCrewObjs,
        configSubObjs,
        configUserObjs,
      },
      optimisticResponse: {
        update_report_alert_config_by_pk: { ...editingConfig, ...configToSet },
      },
      update: (cache, { data: updatedData }) => {
        const returningConfigUsers =
          updatedData?.insert_report_alert_config_user?.returning || [];
        const returningConfigSubs =
          updatedData?.insert_report_alert_config_subcontractor?.returning ||
          [];
        const returningConfigCrews =
          updatedData?.insert_report_alert_config_crew?.returning || [];
        cache.modify<typeof editingConfig>({
          id: cache.identify(editingConfig),
          fields: {
            config_users: (_, { toReference }) =>
              returningConfigUsers.map((item) => toReference(item)!),
            config_subcontractors: (_, { toReference }) =>
              returningConfigSubs.map((item) => toReference(item)!),
            config_crews: (_, { toReference }) =>
              returningConfigCrews.map((item) => toReference(item)!),
          },
        });
      },
    });
  };

  return (
    <>
      <ReportAlertConfigDrawer
        onSubAddPocClick={(subId) => setAddEmployeeOpenForSub(subId)}
        open={openCreateModal || !!editingConfig}
        subsDataMap={subsDataMap}
        loading={inserting || updating}
        employeeOptions={employeeOptions}
        handleClose={() => {
          setEditingConfig(undefined);
          setOpenCreateModal(false);
        }}
        reportAlertConfig={editingConfig}
        onSave={async (vals, subsDataToUpdate) => {
          console.log(vals);
          if (openCreateModal && editingConfig) {
            throw new NotifyUserException(
              "Both create and edit are simultaneously open, please close one and try",
            );
          }
          if (openCreateModal) await onInsertNewConfig(vals, subsDataToUpdate);
          if (editingConfig) await onEditSave(vals, subsDataToUpdate);
          setEditingConfig(undefined);
          setOpenCreateModal(false);
        }}
      />
      <CustomSuspense>
        {addEmployeeOpenForSub && (
          <AddSubcontractorProjectTeamModal
            modalClose={() => {
              setAddEmployeeOpenForSub(undefined);
            }}
            refetchTables={() => {
              console.log("refetching");
              refetch();
            }}
            modalVisible={!!addEmployeeOpenForSub}
            subcontractorId={addEmployeeOpenForSub}
            projectId={projectId}
            onSubmit={(returnData) => {
              if (returnData) {
                const newProjSubEmps =
                  returnData.insert_project_subcontractor_employee?.returning ||
                  []; //it's relay Data
                client.cache.writeQuery<
                  GetReportAlertConfigQuery,
                  GetReportAlertConfigQueryVariables
                >({
                  query: GetReportAlertConfigDocument,
                  variables: { projectId },
                  data: produce(data, (draft) => {
                    const proj = draft.project_by_pk;
                    if (proj) {
                      const projSubIndex =
                        proj.project_subcontractors.findIndex(
                          (projSub) =>
                            projSub.subcontractor.id === addEmployeeOpenForSub,
                        );
                      if (projSubIndex !== -1)
                        proj.project_subcontractors[projSubIndex] = {
                          ...proj.project_subcontractors[projSubIndex],
                          project_subcontractor_employees: [
                            ...proj.project_subcontractors[projSubIndex]
                              .project_subcontractor_employees,
                            ...newProjSubEmps.map((newProjSubEmp) =>
                              constructProjSubEmployeeFromInsertedProjSubEmployeeFrag(
                                newProjSubEmp,
                              ),
                            ),
                          ],
                        };
                    }
                  }),
                });
              }
              setAddEmployeeOpenForSub(undefined);
              // projectSubcontractorEmployeeTableRef.current?.refetch();
            }}
          />
        )}
      </CustomSuspense>
      <Table
        className="z-10"
        bordered
        pagination={false}
        rowKey={(item) => item.id}
        title={() => (
          <div className="flex flex-row gap-1">
            <Button
              type="primary"
              onClick={() => {
                setEditingConfig(undefined);
                setOpenCreateModal(true);
              }}
            >
              Add New
            </Button>
          </div>
        )}
        dataSource={reportConfigs}
        columns={[
          {
            title: "Name",
            dataIndex: ["name"],
            key: "name",
          },
          {
            title: "Report Type",
            dataIndex: ["report_type"],
            key: "report_type",
            render: (reportType: string) =>
              reportType
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase()), // Capitalize each word
          },

          {
            title: "Include All Future Subs",
            dataIndex: ["alert_all_subs"],
            key: "alert_all_subs",
            render: (val) => (val ? "YES" : ""),
          },
          {
            title: "Send Reminder",
            dataIndex: ["reminder_start_time"],
            key: "reminder_start_time",
            render: (v) => (v ? dayjs(v, "HH:mm").format("hh:mm a") : null),
          },
          {
            title: "Report Late",
            dataIndex: ["late_time"],
            key: "late_time",
            render: (v) => (v ? dayjs(v, "HH:mm").format("hh:mm a") : null),
          },
          {
            title: "On the Day",
            dataIndex: ["only_for_day"],
            key: "only_for_day",
            render: (v, row) =>
              row.report_type !== "toolbox_talk"
                ? "NA"
                : v
                ? dayOptions[v].label
                : null,
          },

          {
            title: "Recurring Count",
            dataIndex: ["recurring_count"],
            key: "recurring_count",
          },
          {
            title: "Info",
            dataIndex: ["configSubMappedData"],
            key: "configSubMappedData",
            render: (_, row) => {
              const stringsList: string[] = [];
              if (row.configSubMappedData.length) {
                stringsList.push(
                  `${row.configSubMappedData.length} Subcontractors`,
                );

                if (row.config_crews.length) {
                  stringsList.push(`${row.config_crews.length} Crews`);
                }
              }
              if (row.config_users.length)
                stringsList.push(`${row.config_users.length} Users to notify`);

              return stringsList.join(", ");
            },
          },
          {
            title: "Action",
            key: "action",
            dataIndex: ["id"],
            render: (_, record) =>
              !editingConfig ? (
                <div className="flex gap-0.5 flex-row">
                  <Button
                    disabled={!!editingConfig}
                    onClick={() => {
                      setOpenCreateModal(false);
                      setEditingConfig(record);
                    }}
                    size={"small"}
                    type={"text"}
                    icon={<EditOutlined />}
                  />

                  <Popconfirm
                    title="Sure to Delete?"
                    onConfirm={async () => {
                      await deleteConfig(record.id);
                    }}
                  >
                    <Button
                      type={"text"}
                      size={"small"}
                      icon={<DeleteOutlined />}
                      danger
                    />
                  </Popconfirm>
                </div>
              ) : null,
          },
        ]}
      />
    </>
  );
};
export default withProjectIdUrlParam(GCProjectAlertConfig);
