import { MaterialSymbol } from "react-material-symbols";
import React from "react";
import Icon from "src/common/components/general/Icon";
import ProfilePicture, {
  ProfileAvatarProps,
} from "src/root/routes/views/subcontractor/people/workers/worker-profile/ProfilePicture";
import ContactLink from "src/common/components/general/ContactLink";

interface MobileBusinessCardProps {
  fullName: string;
  userName?: string;
  email?: string;
  hardHatNumber?: string | null;
  phoneNumber?: string;
  company?: string;
  position?: string;
  bio?: string;
  avatar?: ProfileAvatarProps;
}

const MobileBusinessCard: React.FunctionComponent<MobileBusinessCardProps> = (
  props,
) => {
  return (
    <div className="flex flex-col gap-2">
      {props.avatar && (
        <div className={`w-12 h-16 max-h-16 overflow-hidden rounded-2`}>
          <ProfilePicture {...props.avatar} />
        </div>
      )}
      <div className="flex flex-col gap-1">
        {props.userName && (
          <span className="inline-flex gap-0.5 items-center">
            <Icon
              icon={<MaterialSymbol icon="account_circle" />}
              color={`static`}
            />{" "}
            {props.userName}
          </span>
        )}
        <div className="flex items-end">
          <span className="text-2">
            {props.fullName}
            {props.hardHatNumber && (
              <span className="pb-3 text-2">
                &nbsp;&nbsp;&nbsp;#{props.hardHatNumber}
              </span>
            )}
          </span>
        </div>
        <div>
          {(props.company || props.position) && (
            <p className="inline-flex flex-col items-baseline gap-1">
              {props.position ? (
                <span className="text-1.25">{props.position}</span>
              ) : (
                "Working "
              )}
              {props.company && (
                <span className="text-1.25">{props.company}</span>
              )}
            </p>
          )}
        </div>
        {props.bio && (
          <div>
            <p>{props.bio}</p>
          </div>
        )}

        <div className="flex flex-col gap-1 text-1">
          {props.email && <ContactLink value={props.email} />}
          {props.phoneNumber && <ContactLink value={props.phoneNumber} />}
        </div>
      </div>
    </div>
  );
};

export default MobileBusinessCard;
