import React, { FC } from "react";
import insertTaskApprovalMutation from "../api/relay/mutations/InsertTaskApproval";
import {
  InsertTaskApprovalMutation,
  InsertTaskApprovalMutation$data,
} from "src/common/types/generated/relay/InsertTaskApprovalMutation.graphql";
import { ViewAndEditJhaQuery$data } from "../types/generated/relay/ViewAndEditJhaQuery.graphql";
import dayjs from "dayjs";
import { Checkbox, message } from "antd";
import CustomButton from "src/common/components/general/button/Button";

import { MaterialSymbol } from "react-material-symbols";
import { auth } from "src/common/functions/firebase";
import useAsyncMutation from "../hooks/useAsyncMutation";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import * as uuid from "uuid";

interface JhaSubAdminApprovalProps {
  currentPersonApproved: boolean;
  setApproved: () => void;
  taskId: string;
  lastApprovalRequestToSub: ViewAndEditJhaQuery$data["email_request_connection"]["edges"][number]["node"];
}

const JhaSubAdminApproval: FC<JhaSubAdminApprovalProps> = ({
  currentPersonApproved,
  setApproved,
  lastApprovalRequestToSub,
  taskId,
}) => {
  const [insertTaskApproval] = useAsyncMutation<InsertTaskApprovalMutation>(
    insertTaskApprovalMutation,
  );

  const handleInsertTaskApprovalUpdater = (
    store: RecordSourceSelectorProxy<InsertTaskApprovalMutation$data>,
  ) => {
    const insertTaskSign = store.getRootField("insert_task_signature");
    const insertTaskEdit = store.getRootField("insert_task_edit");
    const taskSignConn = ConnectionHandler.getConnection(
      store.getRoot(),
      "ViewAndEditJha_task_signature_connection",
    );
    const taskEditConn = ConnectionHandler.getConnection(
      store.getRoot(),
      "ViewAndEditJha_task_edit_connection",
    );

    if (taskSignConn) {
      insertTaskSign.getLinkedRecords("returning").forEach((p) => {
        const edge = store.create(uuid.v4(), "edge");
        edge.setLinkedRecord(p, "node");
        ConnectionHandler.insertEdgeAfter(taskSignConn, edge);
      });
    }

    if (taskEditConn) {
      insertTaskEdit.getLinkedRecords("returning").forEach((p) => {
        const edge = store.create(uuid.v4(), "edge");
        edge.setLinkedRecord(p, "node");
        ConnectionHandler.insertEdgeAfter(taskEditConn, edge);
      });
    }
  };

  return (
    <>
      <div className="flex items-center">
        {lastApprovalRequestToSub && !currentPersonApproved && (
          <span className="mr-1 text-accent text-1">
            {" "}
            [Approval Requested By {
              lastApprovalRequestToSub.sent_by_user?.name
            }{" "}
            from{" "}
            {
              lastApprovalRequestToSub.sent_by_user?.employee
                ?.general_contractor.name
            }{" "}
            on {dayjs(lastApprovalRequestToSub.created_at).format("LLL")}]
          </span>
        )}
        {currentPersonApproved && (
          <span className="mr-1 text-accent text-1"> Approved</span>
        )}
        <span className="inline-block float-right mr-1">
          {currentPersonApproved ? (
            <Checkbox
              disabled={currentPersonApproved}
              checked={currentPersonApproved}
            ></Checkbox>
          ) : (
            <CustomButton
              green
              label="Mark as Approved"
              icon={<MaterialSymbol icon="check" />}
              onClick={async () => {
                try {
                  await insertTaskApproval({
                    variables: {
                      taskEditObjects: [
                        {
                          edit_type: "Approved",
                          edited_at: dayjs().format(),
                          edited_by_uid: auth.currentUser?.uid,
                          task_id: taskId,
                        },
                      ],
                      taskSignObjects: [
                        {
                          user_id: auth.currentUser?.uid,
                          signature_image_id: null,
                          task_id: taskId,
                        },
                      ],
                    },
                    updater: handleInsertTaskApprovalUpdater,
                  });
                } catch (e) {
                  message.error("SubAdmin approval failed" + String(e));
                }
                setApproved();
              }}
            />
          )}
        </span>
      </div>
    </>
  );
};

export default JhaSubAdminApproval;
