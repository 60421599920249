import { RoutedMenuItem } from "../../../../../../../utility-features/navigation/routed-menu/routedMenuTypes";
import IncidentSeverityMapping from "../../components/incident/IncidentSeverityMapping";
import GCOrgUnitSettingsGeneral from "./general/GCOrgUnitSettingsGeneral";

const gcOrgUnitSettingsRoutedMenu: RoutedMenuItem[] = [
  {
    path: "general",
    label: "General",
    element: <GCOrgUnitSettingsGeneral />,
  },
  {
    path: "Incidents",
    label: "Incidents",
    element: <IncidentSeverityMapping />,
  },
];

export default gcOrgUnitSettingsRoutedMenu;
