/**
 * @generated SignedSource<<fa6a848077c13dc316d7e10634c59e42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InspectionResultSummaryTable_inspection_instances$data = {
  readonly inspection_instance_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly inspection_results: ReadonlyArray<{
          readonly company_name: string | null | undefined;
          readonly created_at: string;
          readonly general_contractor: {
            readonly name: string;
          } | null | undefined;
          readonly inspection_answers: ReadonlyArray<{
            readonly correction_inspection_answer: ReadonlyArray<{
              readonly corrected_inspection_answer: {
                readonly created_at: string;
                readonly inspection_result: {
                  readonly user: {
                    readonly name: string;
                  } | null | undefined;
                  readonly user_name: string | null | undefined;
                };
              } | null | undefined;
            }> | null | undefined;
            readonly created_at: string;
            readonly id: string;
            readonly images: ReadonlyArray<{
              readonly blurhash: string | null | undefined;
              readonly id: string;
              readonly url: string;
            }>;
            readonly inspection_checklist_item_deficient_type: {
              readonly is_immediate_fix: boolean;
              readonly text: {
                readonly en: string;
              };
            } | null | undefined;
            readonly inspection_instance_checklist_item: {
              readonly correct_answer: string;
              readonly description: {
                readonly en: string;
              };
              readonly inspection_checklist_item_type: {
                readonly text: {
                  readonly en: string;
                };
              } | null | undefined;
              readonly item_type: string;
            };
            readonly option_response: string | null | undefined;
            readonly select_response: string | null | undefined;
            readonly text_response: {
              readonly en: string;
              readonly id: string;
            } | null | undefined;
          }>;
          readonly inspection_qr_code: {
            readonly name: string;
          } | null | undefined;
          readonly project_location?: {
            readonly name: {
              readonly en: string;
            };
          } | null | undefined;
          readonly subcontractor: {
            readonly name: string;
          } | null | undefined;
          readonly user: {
            readonly name: string;
          } | null | undefined;
          readonly user_name: string | null | undefined;
        }>;
        readonly inspection_results_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly model_number: string | null | undefined;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  };
  readonly " $fragmentType": "InspectionResultSummaryTable_inspection_instances";
};
export type InspectionResultSummaryTable_inspection_instances$key = {
  readonly " $data"?: InspectionResultSummaryTable_inspection_instances$data;
  readonly " $fragmentSpreads": FragmentRefs<"InspectionResultSummaryTable_inspection_instances">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "inspection_instance_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_name",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "text",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeLocation"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./InspectionResultSummaryTableRefetchableQuery.graphql')
    }
  },
  "name": "InspectionResultSummaryTable_inspection_instances",
  "selections": [
    {
      "alias": "inspection_instance_connection",
      "args": [
        {
          "kind": "Variable",
          "name": "order_by",
          "variableName": "order_by"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "inspection_instanceConnection",
      "kind": "LinkedField",
      "name": "__InspectionResultSummaryTable_inspection_instance_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "inspection_instanceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "inspection_instance",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": "pk",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id_pk",
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model_number",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "inspection_result_aggregate",
                  "kind": "LinkedField",
                  "name": "inspection_results_aggregate",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_result_aggregate_fields",
                      "kind": "LinkedField",
                      "name": "aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "limit",
                      "value": 1
                    },
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "created_at": "desc"
                      }
                    }
                  ],
                  "concreteType": "inspection_result",
                  "kind": "LinkedField",
                  "name": "inspection_results",
                  "plural": true,
                  "selections": [
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v8/*: any*/),
                    {
                      "condition": "includeLocation",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "project_location",
                          "kind": "LinkedField",
                          "name": "project_location",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ]
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "subcontractor",
                      "kind": "LinkedField",
                      "name": "subcontractor",
                      "plural": false,
                      "selections": (v7/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "general_contractor",
                      "kind": "LinkedField",
                      "name": "general_contractor",
                      "plural": false,
                      "selections": (v7/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "company_name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_qr_code",
                      "kind": "LinkedField",
                      "name": "inspection_qr_code",
                      "plural": false,
                      "selections": (v7/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_answer",
                      "kind": "LinkedField",
                      "name": "inspection_answers",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "option_response",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "text_translation",
                          "kind": "LinkedField",
                          "name": "text_response",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "select_response",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "correction_inspection_answer",
                          "kind": "LinkedField",
                          "name": "correction_inspection_answer",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "inspection_answer",
                              "kind": "LinkedField",
                              "name": "corrected_inspection_answer",
                              "plural": false,
                              "selections": [
                                (v5/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "inspection_result",
                                  "kind": "LinkedField",
                                  "name": "inspection_result",
                                  "plural": false,
                                  "selections": [
                                    (v6/*: any*/),
                                    (v8/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "inspection_instance_checklist_item",
                          "kind": "LinkedField",
                          "name": "inspection_instance_checklist_item",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "correct_answer",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "item_type",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "inspection_checklist_item_type",
                              "kind": "LinkedField",
                              "name": "inspection_checklist_item_type",
                              "plural": false,
                              "selections": [
                                (v9/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "text_translation",
                              "kind": "LinkedField",
                              "name": "description",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "image",
                          "kind": "LinkedField",
                          "name": "images",
                          "plural": true,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "blurhash",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "inspection_checklist_item_deficient_type",
                          "kind": "LinkedField",
                          "name": "inspection_checklist_item_deficient_type",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "is_immediate_fix",
                              "storageKey": null
                            },
                            (v9/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "inspection_results(limit:1,order_by:{\"created_at\":\"desc\"})"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "047581ef7f854d9fcf81417f956a0901";

export default node;
