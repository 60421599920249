/**
 * @generated SignedSource<<d79d9fc49fcbe36e0af058f87c3b03ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, report_bool_exp, report_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PreTaskPlansTableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  includeProject: boolean;
  includeSub: boolean;
  order_by: ReadonlyArray<report_order_by>;
  where: report_bool_exp;
};
export type PreTaskPlansTableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PreTaskPlansTable_reports" | "PreTaskPlansTable_total">;
};
export type PreTaskPlansTableQuery = {
  response: PreTaskPlansTableQuery$data;
  variables: PreTaskPlansTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeProject"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeSub"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v6 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = {
  "kind": "Variable",
  "name": "order_by",
  "variableName": "order_by"
},
v9 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v10 = [
  (v9/*: any*/)
],
v11 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PreTaskPlansTableQuery",
    "selections": [
      {
        "args": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "Variable",
            "name": "includeProject",
            "variableName": "includeProject"
          },
          {
            "kind": "Variable",
            "name": "includeSub",
            "variableName": "includeSub"
          },
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "PreTaskPlansTable_reports"
      },
      {
        "args": (v10/*: any*/),
        "kind": "FragmentSpread",
        "name": "PreTaskPlansTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PreTaskPlansTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "reportConnection",
        "kind": "LinkedField",
        "name": "report_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "reportEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "report",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  {
                    "condition": "includeProject",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "condition": "includeSub",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "report_project_worker_aggregate",
                    "kind": "LinkedField",
                    "name": "report_project_workers_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "report_project_worker_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "report_injury_aggregate",
                    "kind": "LinkedField",
                    "name": "report_injuries_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "report_injury_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "task_aggregate",
                    "kind": "LinkedField",
                    "name": "tasks_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "task_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "PreTaskPlansTable_report_connection",
        "kind": "LinkedHandle",
        "name": "report_connection"
      },
      {
        "alias": "allReportsConnection",
        "args": (v10/*: any*/),
        "concreteType": "reportConnection",
        "kind": "LinkedField",
        "name": "report_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "reportEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "report",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22d0d2b0f16fb4c8d00d441f5c48b2d8",
    "id": null,
    "metadata": {},
    "name": "PreTaskPlansTableQuery",
    "operationKind": "query",
    "text": "query PreTaskPlansTableQuery(\n  $first: Int!\n  $after: String\n  $where: report_bool_exp!\n  $order_by: [report_order_by!]!\n  $includeSub: Boolean!\n  $includeProject: Boolean!\n) {\n  ...PreTaskPlansTable_reports_1lAmFF\n  ...PreTaskPlansTable_total_3FC4Qo\n}\n\nfragment PreTaskPlansTable_reports_1lAmFF on query_root {\n  report_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        pk: id\n        created_at\n        project @include(if: $includeProject) {\n          name\n          id\n        }\n        subcontractor @include(if: $includeSub) {\n          id\n          name\n        }\n        report_project_workers_aggregate {\n          aggregate {\n            count\n          }\n        }\n        report_injuries_aggregate {\n          aggregate {\n            count\n          }\n        }\n        tasks_aggregate {\n          aggregate {\n            count\n          }\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment PreTaskPlansTable_total_3FC4Qo on query_root {\n  allReportsConnection: report_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1bf11df0072b86ffddbcf181161217db";

export default node;
