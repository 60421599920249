import { Navigate, RouteObject } from "react-router-dom";
import { RoutedMenuItem } from "./routedMenuTypes";

const getRoutesByRoutedMenu: (
  navigationItems: RoutedMenuItem[],
) => RouteObject[] = (navigationItems) => {
  const routes: RouteObject[] = [];
  let redirectPath: null | string = null;

  navigationItems.forEach((item) => {
    const { label, icon, children, ...routeObjectWithoutChildren } = item;
    const routeObject: RouteObject = {
      ...routeObjectWithoutChildren,
      index: false,
      children: item.children
        ? getRoutesByRoutedMenu(item.children)
        : undefined,
    };
    routes.push({ ...routeObject });

    if (!redirectPath && !!item.path) {
      redirectPath = item.path;
    }
  });

  if (redirectPath) {
    routes.push({
      path: "",
      element: <Navigate to={redirectPath} replace />,
    });
  }
  return routes;
};

export default getRoutesByRoutedMenu;
