/**
 * @generated SignedSource<<4bf78de6a87c7d374ca93844dbe19a93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { uploaded_toolbox_talk_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ManageToolboxTalksMutation$variables = {
  object: uploaded_toolbox_talk_insert_input;
};
export type ManageToolboxTalksMutation$data = {
  readonly insert_uploaded_toolbox_talk_one: {
    readonly id: string;
  } | null | undefined;
};
export type ManageToolboxTalksMutation = {
  response: ManageToolboxTalksMutation$data;
  variables: ManageToolboxTalksMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      }
    ],
    "concreteType": "uploaded_toolbox_talk",
    "kind": "LinkedField",
    "name": "insert_uploaded_toolbox_talk_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageToolboxTalksMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageToolboxTalksMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0c5feeef9eadae2305216ab55f67c540",
    "id": null,
    "metadata": {},
    "name": "ManageToolboxTalksMutation",
    "operationKind": "mutation",
    "text": "mutation ManageToolboxTalksMutation(\n  $object: uploaded_toolbox_talk_insert_input!\n) {\n  insert_uploaded_toolbox_talk_one(object: $object) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "58a8ea20a24a88e01d37161cdb892839";

export default node;
