import { ButtonProps } from "src/common/components/general/button/Button";
import * as React from "react";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { MaterialSymbol } from "react-material-symbols";

// Args not Props because this is not a component
interface JhaTopBarButtonArgs {
  onAddJhaClick?: () => void;
  onDownloadJhaLogClick?: () => void;
  onDownloadJhasClick?: () => void;
  onArchiveJhasClick?: () => void;
  onDeleteJhasClick?: () => void;
  onAddJHAFromCompanyLibraryClick?: () => void;
  onBulkAddJHAsToProjectClick?: () => void;
  isSubAdminCompanyJHA?: boolean;
}

const getJhaTopBarButtons = ({
  onAddJhaClick,
  onDownloadJhaLogClick,
  onDownloadJhasClick,
  onArchiveJhasClick,
  onDeleteJhasClick,
  onAddJHAFromCompanyLibraryClick,
  onBulkAddJHAsToProjectClick,
}: JhaTopBarButtonArgs) => {
  const buttons: Array<ButtonProps> = [
    {
      fake: true,
      icon: <MaterialSymbol icon="more_vert" />,
      secondary: true,
      hint: (
        <div className={"flex flex-col gap-y-0.5 justify-start items-start"}>
          {onAddJhaClick && (
            <Button
              onClick={onAddJhaClick}
              icon={<UploadOutlined />}
              type="text"
            >
              Add
            </Button>
          )}

          {onDownloadJhaLogClick && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onDownloadJhaLogClick();
              }}
              icon={<DownloadOutlined />}
              type="text"
            >
              Download JHA Log
            </Button>
          )}
          {onAddJHAFromCompanyLibraryClick && (
            <Button
              icon={<UploadOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                onAddJHAFromCompanyLibraryClick();
              }}
              type="text"
            >
              Add JHA from Company Library
            </Button>
          )}

          {onBulkAddJHAsToProjectClick && (
            <Button
              icon={<UploadOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                onBulkAddJHAsToProjectClick();
              }}
              type="text"
            >
              Bulk Add JHAs to Project
            </Button>
          )}

          {onDownloadJhasClick && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onDownloadJhasClick();
              }}
              icon={<DownloadOutlined />}
              type="text"
            >
              Download JHA(s)
            </Button>
          )}

          {onArchiveJhasClick && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onArchiveJhasClick();
              }}
              type="text"
            >
              🗓️ Archive
            </Button>
          )}

          {onDeleteJhasClick && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onDeleteJhasClick();
              }}
              type="text"
            >
              🗑️ Delete
            </Button>
          )}
        </div>
      ),
    },
  ];

  return buttons;
};

export default getJhaTopBarButtons;
