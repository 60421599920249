import React, { useMemo } from "react";
import { InspectionResultType } from "../tables/InspectionResultTable";
import { Drawer, Button } from "antd";
import dayjs from "dayjs";
import KeyValuePairDisplayCard from "src/common/components/layouts/KeyValuePairDisplayCard";
import capitalize from "src/common/functions/capitalize";
import clsx from "clsx";
import ShowMultipleImageLayout from "src/common/components/layouts/ShowMultipleImageLayout";
import { generalChecklistType } from "../../utils/generalChecklistType";
import { useGetInspectionReportPdfMutation } from "src/common/types/generated/apollo/graphQLTypes";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import { DownloadOutlined } from "@ant-design/icons";

interface InspectionResultProps {
  data: InspectionResultType;
  visible: boolean;
  onClose: () => void;
}

const InspectionResult: React.FC<InspectionResultProps> = ({
  data,
  visible,
  onClose,
}) => {
  const { userData } = useUserData();
  const [generatePdf, { loading: generatingPdf }] =
    useGetInspectionReportPdfMutation();
  const objectToDisplay = useMemo(() => {
    const itemMap: { [key: string]: string | undefined } = {
      ["Submitted by"]: data.user_name ?? data.user?.name,
      ["Company"]:
        data.company_name ??
        (data.subcontractor?.name || data.general_contractor?.name),
      ["Submitted on"]: dayjs(data.created_at).format("LLL"),
      ["Inspection Name"]: data.inspection_name.en,
    };
    if (
      userData.employee?.general_contractor.show_locations_during_inspection &&
      data.project_location
    )
      itemMap["Job Location"] = data.project_location.name.en;
    if (data.model_number) itemMap["Model number"] = data.model_number;
    return itemMap;
  }, [data]);
  const typeMap = useMemo(() => {
    const typeMap = data.inspection_answers.reduce(
      (tempTypeMap, ans) => {
        const type =
          ans.inspection_instance_checklist_item.inspection_checklist_item_type
            ?.text?.en ?? capitalize(generalChecklistType);
        if (tempTypeMap[type]) {
          tempTypeMap[type].push(ans);
        } else tempTypeMap[type] = [ans];
        return tempTypeMap;
      },
      {} as {
        [type: string]: InspectionResultType["inspection_answers"][number][];
      },
    );
    return typeMap;
  }, [data]);
  return (
    <Drawer
      open={visible}
      onClose={onClose}
      width={"85%"}
      footer={
        <div className="flex gap-1">
          <Button onClick={() => onClose()}>Close</Button>
          <Button
            onClick={async () => {
              const { data: download } = await generatePdf({
                variables: { input: { inspectionId: data.pk } },
              });

              if (download?.generateInspectionReportPdf) {
                downloadFromUrl(download.generateInspectionReportPdf);
              }
            }}
            type={"primary"}
            loading={generatingPdf}
            icon={<DownloadOutlined />}
          >
            Download as PDF
          </Button>
        </div>
      }
    >
      <KeyValuePairDisplayCard objectToDisplay={objectToDisplay} />
      <div className="text-1.25 mt-0.5 flex justify-between">
        Checklist
        <div>Answers</div>
      </div>
      {Object.entries(typeMap).map(([type, inspection_answers]) => (
        <div>
          <div className="text-1.25">{type}</div>
          <div className="ml-0.5">
            {inspection_answers.map((ans, i) => {
              const item = ans.inspection_instance_checklist_item;
              const deficientType =
                ans.inspection_checklist_item_deficient_type;
              const descDiv = (
                <div>
                  <span className="font-accent">
                    {i + 1}
                    {".)"}{" "}
                  </span>
                  {item.description.en}:{" "}
                </div>
              );
              if (item.item_type === "text")
                return (
                  <div>
                    {descDiv}
                    <div>Response: {ans.text_response?.en}</div>
                  </div>
                );
              if (item.item_type === "select_one")
                return (
                  <div>
                    {descDiv}
                    <div>Selected Response: {ans.select_response}</div>
                  </div>
                );
              return (
                <div key={ans.id}>
                  <div className="flex gap-1 justify-between mt-0.25">
                    {descDiv}
                    <div
                      className={clsx(
                        "p-0.25 rounded-0.25 text-white",
                        ans.option_response === "na"
                          ? "bg-interactive-primary"
                          : ans.option_response === item.correct_answer
                          ? "bg-semantic-positive-green"
                          : "bg-semantic-negative",
                      )}
                    >
                      {ans.option_response
                        ? capitalize(ans.option_response)
                        : null}
                    </div>
                  </div>
                  {ans.option_response !== "na" &&
                    ans.option_response !== item.correct_answer && (
                      <div
                        className={
                          deficientType?.is_immediate_fix
                            ? "text-purple"
                            : "text-semantic-negative"
                        }
                      >
                        <span className="text-grey">
                          Make-safe action taken:{" "}
                        </span>
                        {deficientType ? (
                          <>
                            <span>{deficientType.text.en}</span>
                            {ans.text_response ? (
                              <div>
                                <span className="text-grey">
                                  Additional Information Provided:{" "}
                                </span>
                                {ans.text_response.en}
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ans.text_response && (
                            <span>{ans.text_response.en}</span>
                          )
                        )}
                      </div>
                    )}
                  <ShowMultipleImageLayout
                    showSmaller
                    images={ans.images.map((image) => image)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
      {data.inspection_images.length > 0 && (
        <>
          <div className="mt-1 text-1.25">Images</div>
          <ShowMultipleImageLayout
            images={data.inspection_images.map((image) => image)}
          />
        </>
      )}
    </Drawer>
  );
};
export default InspectionResult;
