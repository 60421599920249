import { Button } from "antd";
import { commitLocalUpdate, ConnectionHandler } from "relay-runtime";
import SelectEmailUsersForActiveUsersModal from "src/root/routes/views/general-contractor/hierarchy/projects/settings/components/SelectEmailUsersForActiveUsersModal";
import GetFullID from "../functions/GetFullId";
import {
  GetEmailProjectUserQuery,
  useInsertEmailProjectUserMutation,
} from "../types/generated/apollo/graphQLTypes";
import compareTwoLists from "./ComparingTwoLists";
import { useState } from "react";
import { useRelayEnvironment } from "react-relay";
import * as uuid from "uuid";
export interface EmailProjectUserSelectViewProps {
  selectedUsers: Array<{ id: string; name: string }>;
  userOptions: Array<{ label: string; value: string }>;
  connectionName?: string;
  projectId: string;
  type:
    | "quiz_failure"
    | "worker_with_drug_test_arrival"
    | "active_users"
    | "safety_directors"
    | "incorrect_workers"
    | "orientation_auto_email"
    | "incident_reporting";
  title?: string;
  description?: string;
  buttonTitle?: string;
  buttonSize?: "small" | "middle" | "large";
}

const EmailProjectUserSelectView: React.FC<EmailProjectUserSelectViewProps> = (
  props,
) => {
  const [showModal, setShowModal] = useState(false);
  const environment = useRelayEnvironment();
  const [insertEmailProjectUser, { loading: insertEmailProjectUserLoading }] =
    useInsertEmailProjectUserMutation();
  const relayConnectionName = props.connectionName;
  return (
    <div>
      {props.title && <p className="mb-2">{props.title}</p>}
      {props.description && <p className="mb-2">{props.description}</p>}
      <div className="-mx-0.25">
        {props.selectedUsers.map((user: { id: string; name: string }) => (
          <span
            className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
            key={user.id}
          >
            {user.name}
          </span>
        ))}
      </div>
      <div className="flex flex-row justify-start">
        <Button
          onClick={() => {
            setShowModal(true);
          }}
          loading={insertEmailProjectUserLoading}
          type="primary"
          size={props.buttonSize || "middle"}
        >
          {props.buttonTitle || "Select Team Member(s)"}
        </Button>
      </div>
      <SelectEmailUsersForActiveUsersModal
        projectId={props.projectId}
        userOptions={props.userOptions}
        loading={insertEmailProjectUserLoading}
        selectedUserIds={props.selectedUsers.map((user) => user.id)}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onDoneEditing={async (values) => {
          const [toBeInsertedUserIds, toBeDeletedUserIds] = compareTwoLists(
            values.usersToBeNotified,
            props.selectedUsers.map((user) => user.id),
          );
          const { data: mutatedData } = await insertEmailProjectUser({
            variables: {
              objects: toBeInsertedUserIds.map((userId) => ({
                id: uuid.v4(),
                project_id: props.projectId,
                user_id: userId,
                type: props.type,
              })),
              deleteWhere: {
                user_id: { _in: toBeDeletedUserIds },
                project_id: { _eq: props.projectId },
                type: { _eq: props.type },
              },
            },
            update: (cache, { data: returningData }) => {
              if (!returningData) return;
              const insertedData =
                returningData.insert_email_project_user?.returning || [];
              const deletedIds = new Set(
                (returningData.delete_email_project_user?.returning || []).map(
                  (d) => d.id,
                ),
              );

              cache.modify<GetEmailProjectUserQuery>({
                fields: {
                  email_project_user: (
                    existing = [],
                    { toReference, readField, storeFieldName },
                  ) =>
                    storeFieldName.includes(props.type)
                      ? [
                          ...existing.filter((item) => {
                            const id = readField("id", item);
                            return (
                              typeof id === "string" && !deletedIds.has(id)
                            );
                          }),
                          ...insertedData.map(
                            (inserted) => toReference(inserted)!,
                          ),
                        ]
                      : existing,
                },
              });
            },
          });
          if (!mutatedData) return;
          const insertedData =
            mutatedData.insert_email_project_user?.returning || [];
          const deletedIds = new Set(
            (mutatedData.delete_email_project_user?.returning || []).map(
              (d) => d.id,
            ),
          );
          if (relayConnectionName)
            commitLocalUpdate(environment, (store) => {
              const root = store.getRoot();
              const conn = ConnectionHandler.getConnection(
                root,
                relayConnectionName,
              );
              if (conn) {
                insertedData.forEach((newUser) => {
                  const fullId = GetFullID("email_project_user", newUser.id);
                  if (!store.get(fullId)) {
                    const newRecord = store.create(
                      fullId,
                      "email_project_user_connection",
                    );
                    const userFullId = GetFullID("user", newUser.user.id);
                    let userRecord = store.get(userFullId);
                    if (!userRecord) {
                      userRecord = store.create(userFullId, "user");
                      userRecord.setValue(newUser.user.id, "id");
                      userRecord.setValue(newUser.user.name, "name");
                    }
                    newRecord.setValue(newUser.id, "id");
                    newRecord.setValue(newUser.user_id, "user_id");
                    newRecord.setValue(newUser.type, "type");
                    newRecord.setLinkedRecord(userRecord, "user");
                    const edgeId = uuid.v4();
                    const edge = store.create(edgeId, "edge");
                    edge.setLinkedRecord(newRecord, "node");
                    ConnectionHandler.insertEdgeAfter(conn, edge);
                  }
                });
                deletedIds.forEach((deletedId) => {
                  const fullId = GetFullID("email_project_user", deletedId);
                  ConnectionHandler.deleteNode(conn, fullId);
                });
              }
            });
          setShowModal(false);
        }}
      />
    </div>
  );
};
export default EmailProjectUserSelectView;
