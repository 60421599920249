import { MaterialSymbol } from "react-material-symbols";
import React, { useState } from "react";
import CreateGCEmployeeModal, {
  CreateGCEmployeeFormValues,
} from "src/common/components/dialogs/CreateGCEmployeeModal";
import Button from "src/common/components/general/button/Button";
import SearchBar from "src/common/components/SearchBar";
import { SortOrderType } from "src/common/components/SortingTypePicker";
import capitalize from "src/common/functions/capitalize";
import AddGCProjectTeamModal from "./AddGCProjectTeamModal";
import GCTeamList from "./GCTeamList";
import PerformEmployeeChangeFunctionModal from "src/common/components/modals/PerformEmployeeChangeFunctionModal";
import { general_contractor_employee_bool_exp } from "src/common/types/generated/relay/AddGCProjectTeamModalQuery.graphql";
import { useParams, useSearchParams } from "react-router-dom";

import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import { GCProjectTeamListItemProps } from "./GCTeamListItem";

export interface GCProjectTeamUIProps {
  onCreateGCEmployee: (values: CreateGCEmployeeFormValues) => any;
  projectsToInvite: string[];
  directProjectTeam: boolean;
  excludeEmployeeWhere: general_contractor_employee_bool_exp;
  onInviteSuccess: (userId: string[]) => Promise<void> | void;
  employees: {
    name: string;
    userId: string;
    generalContractorId: string;
    isCorporateAdmin?: boolean;
    onRemoveFromTeam?: (userId: string) => Promise<void>;
    avatarUrl?: string;
    title?: string;
    email?: string;
    phoneNumber?: string;
    loading: boolean;
    invitationStatus: "pending" | "accepted";
    reSendInvite?: () => void;
    terminatedAt?: string;
    isLocalAdmin?: boolean; // if the user is an admin on the level of the hierarchy node
    activeOnProject?: boolean;
    onActiveOnProjectChange?: (newValue: boolean) => void;
    relayDataId?: string;
  }[];
}

const GCTeam: React.FC<GCProjectTeamUIProps> = (props) => {
  const [sortingOrder, setSortingOrder] = useState<SortOrderType>("ascending");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [fetchKey, setFetchKey] = useState(0);
  const [createEmployeeOpen, setCreateEmployeeOpen] = useState(false);
  const [handleChangeEmployee, setHandleChangeEmployee] = useState(false);
  const subview = searchParams.get("subview") || "active";
  const { section } = useParams();
  const handleReportsTabClick = (tabId: string) => {
    setSearchParams({ subview: tabId });
  };

  const [selectedSortingTypeId, setSelectedSortingType] = useState<
    `name` | "title"
  >("name");

  const searchQueryUpperCase = searchQuery.toUpperCase();

  const statusFilteredUsers = props.employees.reduce<
    GCProjectTeamListItemProps[]
  >((acc, user) => {
    if (subview === "active" && !user.terminatedAt) {
      acc.push(user);
    }
    if (subview === "terminated" && !!user.terminatedAt) {
      acc.push(user);
    }
    return acc;
  }, []);

  const filteredUsers = statusFilteredUsers
    .filter((user) => user.name.toUpperCase().includes(searchQueryUpperCase))
    .sort((user1, user2) => {
      let value1;
      let value2;
      switch (selectedSortingTypeId) {
        case "name":
          value1 = user1.name;
          value2 = user2.name;
          break;
        case "title":
          value1 = user1.title;
          value2 = user2.title;
          break;
      }
      if (!value1 && !value1) {
        return 0;
      }
      if (!value1) {
        return 1;
      }
      if (!value2) {
        return -1;
      }
      const returnValue = value1 === value2 ? 0 : value1 > value2 ? 1 : -1;
      return sortingOrder === "ascending" ? returnValue : -returnValue;
    });

  const handleAddUser = () => {
    setFetchKey((prev) => prev + 1);
    setVisible(true);
  };
  const handleEmployeeChange = () => {
    setHandleChangeEmployee((val) => !val);
  };

  const tabContent = (
    <>
      <div className={`flex flex-row items-center gap-1`}>
        <div className="flex-1 mb-0.25">
          <SearchBar
            onSearchSubmit={setSearchQuery}
            sorting={{
              onOrderClick: setSortingOrder,
              onSortingTypeClick: (newSortyingType) => {
                setSelectedSortingType((prevState) =>
                  newSortyingType === "name" || newSortyingType === "title"
                    ? newSortyingType
                    : prevState,
                );
              },
              order: sortingOrder,
              selectedSortingTypeId,
              sortingTypes: ["name", "title"].map((v) => ({
                title: capitalize(v),
                id: v,
              })),
            }}
          />
        </div>
        {/* <div className="flex-1"> */}
        {section !== "employees" && subview === "active" && (
          <Button
            onClick={handleAddUser}
            icon={<MaterialSymbol icon="person_add" />}
            secondary
          />
        )}

        {subview === "active" && (
          <Button
            onClick={handleEmployeeChange}
            icon={<MaterialSymbol icon="swap_horiz" />}
            secondary
          />
        )}
      </div>

      <GCTeamList employees={filteredUsers} />
    </>
  );

  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "active",
      label: "Active employees",
      content: tabContent,
    },
    {
      id: "terminated",
      label: "Deactivated",
      content: tabContent,
    },
  ];

  return (
    <>
      <CustomTabs
        onTabSelect={handleReportsTabClick}
        tabs={tabs}
        selectedTabId={subview ?? "active"}
      />

      <AddGCProjectTeamModal
        modalClose={() => {
          setVisible(false);
        }}
        directProjectTeam={props.directProjectTeam}
        onInviteSuccess={props.onInviteSuccess}
        excludeEmployeeWhere={props.excludeEmployeeWhere}
        projects={props.projectsToInvite}
        modalVisible={visible}
        onClickInvite={() => {
          setVisible(false);
          setCreateEmployeeOpen(true);
        }}
        fetchKey={fetchKey}
      />
      <CreateGCEmployeeModal
        visible={createEmployeeOpen}
        onCancel={() => {
          setCreateEmployeeOpen(false);
          handleAddUser();
        }}
        title={"Invite Office or Field Staff to SiteForm"}
        warning={"DO NOT invite Workers here (foremen or field labor)"}
        onCreate={async (values: CreateGCEmployeeFormValues) => {
          await props.onCreateGCEmployee(values);
          setCreateEmployeeOpen(false);
          // handleAddUser();
        }}
      />
      <PerformEmployeeChangeFunctionModal
        visible={handleChangeEmployee}
        onClose={() => setHandleChangeEmployee(false)}
        data={props.employees.map((employee) => ({
          name: employee.name,
          email: employee.email || undefined,
          uid: employee.userId,
          invitationAccepted: employee.invitationStatus === "accepted",
          relayDataIdrelayDataId: employee.relayDataId,
          phoneNumber: employee.phoneNumber,
        }))}
      />
    </>
  );
};

export default GCTeam;
