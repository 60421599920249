import React, { FC, useEffect, useMemo, useState } from "react";
import { Form, message, Modal, Radio, Select, Spin } from "antd";
import {
  Project_Worker_Bool_Exp,
  useGetWorkersToCombineLazyQuery,
  useInsertCsrEditMutation,
  useMergeDuplicateWorkersMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ConvertWorkerDataType } from "./performWorkerTypes";
import useAuthUser from "src/common/hooks/useAuthUser";

const { Option } = Select;

const CombineWorkerModal: FC<
  {
    visible: boolean;
    refetch: () => void;
    onClose: () => void;
    removeWorkerFromCache?: (workerIdToDelete: string) => void;
  } & (
    | {
        insertCsrEdit?: boolean;
        type: "noData";
        projWorkerWhere: Project_Worker_Bool_Exp;
      }
    | {
        type: "withData";
        data: ConvertWorkerDataType[];
        relayQueryConnName?: string;
      }
  )
> = ({ visible, onClose, refetch, ...props }) => {
  const [insertCsrEdit] = useInsertCsrEditMutation();
  const [
    getWorkersToCombine,
    { data: workersToCombine, loading: workersLoading },
  ] = useGetWorkersToCombineLazyQuery();
  useEffect(() => {
    if (props.type === "noData") {
      getWorkersToCombine({
        variables: { projWorkerWhere: props.projWorkerWhere },
      });
    }
  }, [props.type]);

  const workersToSelect = useMemo(
    () =>
      props.type === "noData"
        ? (workersToCombine?.project_worker || []).map(
            (pw): ConvertWorkerDataType => ({
              id: pw.user!.id,
              name: pw.user!.name,
              createdPassword: pw.user?.created_password,
              email: pw.user?.email,
              hh: pw.hard_hat_number,
              phoneNumber: pw.user?.phone_number || undefined,
              subcontractorId: pw.subcontractor.id,
              company: pw.subcontractor.name,
              trade: pw.title?.translation.en,
            }),
          )
        : props.data,
    [props, workersToCombine],
  );

  const authUser = useAuthUser();
  const [form] = Form.useForm();
  const [mergeWorkers, { loading }] = useMergeDuplicateWorkersMutation();
  const [toKeep, setToKeep] = useState<string | undefined>(undefined);
  return (
    <Modal
      onCancel={onClose}
      title="Combine Two Workers"
      width={950}
      okText="Combine"
      open={visible}
      confirmLoading={loading}
      onOk={async () => {
        const vals = await form.validateFields();

        if (!vals.accept) {
          message.error("Please accept to continue");
          return;
        }
        const input = { newWorkerId: vals.keep, oldWorkerId: vals.delete };
        await mergeWorkers({
          variables: { input },
        });
        props.removeWorkerFromCache?.(vals.delete);
        message.success("Merge finished succesfully");
        form.resetFields();

        onClose();
        setToKeep(undefined);
        refetch();
        if (props.type === "noData" && props.insertCsrEdit)
          await insertCsrEdit({
            variables: {
              csrEdits: [
                {
                  change_log: [input],
                  name: "mergeDuplicateWorkers",
                  csr_user_id: authUser.uid,
                  edit_text: "Merged two workers into one",
                  entity_id: vals.keep,
                  operation_name: "resolver_call",
                },
              ],
            },
          });
      }}
    >
      {workersLoading && props.type === "noData" ? (
        <Spin />
      ) : (
        <Form form={form} layout="horizontal">
          <div>
            Select the <span className="font-accent">Keep</span> Worker - the
            worker that will be kept in SiteForm &nbsp;&nbsp;
            <span className="text-2">🙂</span>
          </div>
          <Form.Item name="keep" label={"A"}>
            <Select
              onChange={(val) => setToKeep(val.toLocaleString())}
              showSearch
              filterOption={(input, option) => {
                if (!option || !option.props.children[0]) return false;
                return (
                  option.props.children[0]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {workersToSelect.map((u) => {
                const list = [u.name, u.hh, u.company, u.trade, u.email].filter(
                  (s) => s,
                );
                return (
                  <Option value={u.id} key={u.id}>
                    {list.join(", ")}&nbsp;&nbsp;
                    <span className="font-accent text-interactive-primary">
                      {u.createdPassword ? " PASSWORD SET" : ""}
                    </span>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <div>
            Select <span className="font-accent">Delete</span> Worker - the
            worker that will be deleted forever &nbsp;&nbsp;
            <span className="text-2">😢</span>
          </div>
          <Form.Item name="delete" label={"B"}>
            <Select
              disabled={!toKeep}
              showSearch
              filterOption={(input, option) => {
                if (!option || !option.props.children[0]) return false;
                return (
                  option.props.children[0]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              {workersToSelect
                .filter((u) => u.id !== toKeep)
                .map((u) => {
                  const list = [
                    u.name,
                    u.hh,
                    u.company,
                    u.trade,
                    u.email,
                  ].filter((s) => s);
                  return (
                    <Option value={u.id} key={u.id}>
                      {list.join(", ")}&nbsp;&nbsp;
                      <span className="font-accent text-interactive-primary">
                        {u.createdPassword ? " PASSWORD SET" : ""}
                      </span>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <div>
            By combining these workers, I confirm both worker profiles are for
            the same person and understand their information will be{" "}
            <span className="font-accent">combined into one (1) profile</span>.
            The profile selected in box A will be kept. The worker selected in
            box B will be deleted. This includes name, contact information,
            demographic questions, certifications, all previous projects,
            reports, orientations, etc.
          </div>
          <br />
          <br />
          <div>This cannot be undone</div>
          <Form.Item name="accept">
            <Radio.Group>
              <Radio value={true}>I understand</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
export default CombineWorkerModal;
