import { DownloadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Drawer, Form, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import Icon from "src/common/components/general/Icon";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { useGeneratePayrollComparisonReportMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { GCProjectReportsDownloadQuery$data } from "src/common/types/generated/relay/GCProjectReportsDownloadQuery.graphql";
import { MaterialSymbol } from "react-material-symbols";

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const WorkerHourCountReportCreator: React.FC<{
  visible: boolean;
  handleClose: () => void;
  projectId: string;
  data: GCProjectReportsDownloadQuery$data;
}> = ({ visible, handleClose, projectId, data }) => {
  const [form] = Form.useForm();
  const [dates, setDates] = useState<RangeValue>(null);
  const [hackValue, setHackValue] = useState<RangeValue>(null);
  const [start, setStart] = useState<dayjs.Dayjs | null>(
    dayjs().startOf("month"),
  );
  const [end, setEnd] = useState<dayjs.Dayjs | null>(dayjs());
  const today = dayjs();

  const [
    generatePayrollComparisonReport,
    { loading: generatingPayrollComparison },
  ] = useGeneratePayrollComparisonReportMutation();

  const subcontractorData = data.project_subcontractor_connection.edges;
  const subIds = new Array<string>();
  subcontractorData.map((t) => {
    subIds.push(t.node.subcontractor_id);
  });

  const disabledDate = (current: Dayjs) => {
    if (!dates) {
      return false;
    }

    if (current > today) {
      return true;
    }
    const tooLate = dates[0] && current.diff(dates[0], "months") >= 1;
    const tooEarly = dates[1] && dates[1].diff(current, "months") >= 1;
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setHackValue([null, null]);
      setDates([null, null]);
    } else {
      setHackValue(null);
    }
  };

  const handleDownload = async () => {
    const values = await form.validateFields();
    if (
      values.onsiteDateRange &&
      values.onsiteDateRange[0] &&
      values.onsiteDateRange[1]
    ) {
      const res = await generatePayrollComparisonReport({
        variables: {
          input: {
            projectId,
            onsiteStartDate: values.onsiteDateRange[0].format(),
            onsiteEndDate: values.onsiteDateRange[1].format(),
            subcontractorId:
              values.subcontractorId === "all"
                ? undefined
                : values.subcontractorId,
          },
        },
      });

      if (res.data?.generatePayrollComparisonReport)
        downloadFromUrl(res.data?.generatePayrollComparisonReport);

      handleClose();
    }
  };

  return (
    <Drawer
      title="Report Creator"
      width={"60%"}
      open={visible}
      onClose={handleClose}
      destroyOnClose
      footer={
        <div className="flex gap-1">
          <Button loading={generatingPayrollComparison} onClick={handleClose}>
            Close
          </Button>
          <Button
            type={"primary"}
            icon={<DownloadOutlined />}
            loading={generatingPayrollComparison}
            onClick={handleDownload}
          >
            Download
          </Button>
        </div>
      }
    >
      <div className="flex items-start mb-1.5">
        <Icon
          icon={<MaterialSymbol icon="info" />}
          hoverContent={{
            content: (
              <div>
                This report shows the subcontractors and their workers that were
                <br />
                onsite for a selected period of time. You can use this to
                compare
                <br />
                to certified payroll reports or see when a subcontractor did not
                <br />
                complete a daily report. The # shown on each day is the number
                of
                <br />
                hours. "Yes" indicates the worker was onsite but was not
                included
                <br />
                in a daily report or the daily report was not submitted.
              </div>
            ),
          }}
        />
      </div>
      <Form
        form={form}
        initialValues={{
          subcontractorId: "all",
          onsiteDateRange: [dayjs().startOf("month"), dayjs()],
        }}
      >
        <Form.Item
          name="onsiteDateRange"
          label={<div className="text-1">Select Onsite Date Range</div>}
          rules={[
            { required: true, message: "Select a date range for Onsite" },
          ]}
        >
          <DatePicker.RangePicker
            className="min-w-12"
            value={hackValue || [start, end]}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={(val) => {
              const start = val ? val[0] : null;
              const end = val ? val[1] : null;
              setStart(start);
              setEnd(end);
            }}
            presets={[
              {
                label: "This week",
                value: [dayjs().startOf("week"), dayjs().endOf("week")],
              },
              {
                label: "Last week",
                value: [
                  dayjs().subtract(1, "week").startOf("week"),
                  dayjs().subtract(1, "week").endOf("week"),
                ],
              },
              {
                label: "This month",
                value: [dayjs().startOf("month"), dayjs().endOf("month")],
              },
              {
                label: "Last month",
                value: [
                  dayjs().subtract(1, "month").startOf("month"),
                  dayjs().subtract(1, "month").endOf("month"),
                ],
              },
            ]}
            onOpenChange={onOpenChange}
          />
        </Form.Item>
        <Form.Item
          name="subcontractorId"
          label={
            <div className="text-1">Select Companies and Trade Partners</div>
          }
        >
          <Select className="w-full">
            <Select.Option key={0} value={"all"}>
              ALL Subs
            </Select.Option>
            {subcontractorData?.map((t) => (
              <Select.Option
                key={t.node.subcontractor_id}
                value={t.node.subcontractor_id}
              >
                {t.node.subcontractor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
export default WorkerHourCountReportCreator;
