import { FC, useState } from "react";
import ProjectJHALibrary from "src/common/components/ProjectJHALibrary";
import CustomButton from "src/common/components/general/button/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import StyledContent from "src/common/components/layouts/StyledContent";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import TasksTable from "src/domain-features/sitesafety/job-hazard-analysis/components/tables/TasksTable";
import { Drawer, message } from "antd";
import TaskInfo from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/TaskInfo";
import getJhaTopBarButtons from "src/domain-features/sitesafety/job-hazard-analysis/components/basic/topBarButtons";
import useAddTaskToProjectOrCompany from "src/domain-features/sitesafety/job-hazard-analysis/utils/useAddTaskToProjectOrCompany";
import { getJHAData } from "src/common/api/relay/queries/AddJHAToProjectOrCompany";
import { useRelayEnvironment } from "react-relay/hooks";

const GCSubcontractorJHAs: FC<{
  data: GCSubcontractorQuery$data;
  subcontractorId: string;
  projectId: string;
}> = ({ data, subcontractorId, projectId }) => {
  const environment = useRelayEnvironment();
  const [insertTasks] = useAddTaskToProjectOrCompany();
  const [order, setOrder] = useState(Order_By.Asc);
  const [areCompanyTasksVisible, setAreCompanyTasksVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState<{
    taskId: string;
    subcontractorId?: string;
    requestId?: string;
  }>();
  const user = data.user_connection.edges[0].node;
  if (!user) throw new Error("User not found");
  const emp = user.employee;
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const projectOfSub = (emp?.employee_projects || []).map((proj) => ({
    id: proj.project.pk,
    name: proj.project.name,
  }));
  const [bulkAddingJhas, setBulkAddingJhas] = useState(false);

  const handleBulkAddJHAsToProjectClick = async () => {
    message.loading({
      content: "Adding JHAs",
      duration: 0,
    });
    setBulkAddingJhas(true);
    try {
      const jhaData = await getJHAData(environment, selectedRows);
      await insertTasks(
        jhaData?.map((edge) => edge.node) ?? [],
        subcontractorId,
        projectId,
        "addToProject",
      );
    } finally {
      setBulkAddingJhas(false);
      message.destroy();
    }
  };

  return (
    <StyledContent padding backgroundColor="white">
      <Drawer
        width={`85%`}
        open={!!selectedTask}
        placement="right"
        onClose={() => setSelectedTask(undefined)}
      >
        {selectedTask && (
          <TaskInfo
            projectId={projectId}
            subcontractorId={selectedTask.subcontractorId}
            taskId={selectedTask.taskId}
            type="gce"
            key={selectedTask.taskId}
          />
        )}
      </Drawer>

      {areCompanyTasksVisible ? (
        <TasksTable
          onRowItemClick={(row) => {
            setSelectedTask({
              subcontractorId: row.subcontractor_id ?? undefined,
              taskId: row.pk,
              requestId: row.creation_request_id ?? undefined,
            });
          }}
          topBarButtons={
            selectedRows.length > 0 && !bulkAddingJhas
              ? getJhaTopBarButtons({
                  onBulkAddJHAsToProjectClick: handleBulkAddJHAsToProjectClick,
                })
              : undefined
          }
          setSelectedRows={setSelectedRows}
          actionsAllowed={[
            "acceptJHA",
            "addToProject",
            "excelDownload",
            "pdfDownload",
            "requestTeamReview",
            "viewFile",
            "resendForAcceptance",
            "acceptJHA",
          ]}
          title="Company JHA Library"
          isNotTurnersProject={false}
          projectId={projectId}
          subcontractorId={subcontractorId}
          headerComponent={
            <CustomButton
              secondary
              label="Go to Project JHA Library"
              onClick={() => setAreCompanyTasksVisible(false)}
            />
          }
          where={{
            project_id: { _is_null: true },
            subcontractor_id: { _eq: subcontractorId },
            is_pending_acceptance: { _eq: false },
            report_id: { _is_null: true },
            deleted_at: { _is_null: true },
          }}
          projectOfSub={projectOfSub}
          excludedKeys={[
            "subcontractor",
            "internalReview",
            "subcontractorApproval",
            "lastUsed",
            "firstUsed",
            "workersSigned",
            "addToMulipleProjects",
            "delete",
          ]}
        />
      ) : (
        <ProjectJHALibrary
          onClick={() => setAreCompanyTasksVisible(true)}
          projectId={projectId}
          subcontractorId={subcontractorId}
          order={order}
          setOrder={setOrder}
        />
      )}
    </StyledContent>
  );
};

export default withCustomSuspense(GCSubcontractorJHAs);
