import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useRelayEnvironment } from "react-relay/hooks";
import { fetchQuery } from "react-relay/hooks";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import * as uuid from "uuid";
import { useMoveInspectionQRToAnotherProjectMutation } from "src/common/types/generated/relay/useMoveInspectionQRToAnotherProjectMutation.graphql";

import type {
  inspection_instance_checklist_item_insert_input,
  inspection_instance_insert_input,
} from "src/common/types/generated/relay/types";

import { useMoveInspectionQRToAnotherProjectQuery } from "src/common/types/generated/relay/useMoveInspectionQRToAnotherProjectQuery.graphql";
import inspectionQROptionsDeleteUpdater from "./inspectionQROptionsDeleteUpdater";
import GetFullID from "src/common/functions/GetFullId";

const useMoveInspectionQRToAnotherProject = () => {
  const environment = useRelayEnvironment();
  const [move] = useAsyncMutation<useMoveInspectionQRToAnotherProjectMutation>(
    graphql`
      mutation useMoveInspectionQRToAnotherProjectMutation(
        $newObjects: [inspection_qr_code_insert_input!]!
        $prevQRCodeId: uuid!
        $now: timestamptz!
      ) {
        insert_inspection_qr_code(objects: $newObjects) {
          affected_rows
        }
        update_inspection_qr_code_by_pk(
          pk_columns: { id: $prevQRCodeId }
          _set: { deleted_at: $now }
        ) {
          id
        }
      }
    `,
  );

  const moveQRToAnotherProject = async (
    prevQRCodeId: string,
    projectId: string,
  ) => {
    const response = fetchQuery<useMoveInspectionQRToAnotherProjectQuery>(
      environment,
      graphql`
        query useMoveInspectionQRToAnotherProjectQuery($prevQRCodeId: uuid!) {
          inspection_qr_code_connection(where: { id: { _eq: $prevQRCodeId } }) {
            edges {
              node {
                name
                inspection_qr_code_instances(
                  where: {
                    inspection_instance: { deleted_at: { _is_null: true } }
                  }
                ) {
                  id
                  inspection_instance {
                    ...InspectionInstanceFrag @relay(mask: false)
                  }
                }
              }
            }
          }
        }
      `,
      { prevQRCodeId },
    );
    const data = await response.toPromise();
    if (!data)
      throw new Error("No QR found to be moved, please go back and try again");
    await move({
      variables: {
        now: dayjs().toISOString(),
        prevQRCodeId,
        newObjects: data?.inspection_qr_code_connection.edges.map((qr) => ({
          name: qr.node.name,
          created_by_uid: auth.currentUser?.uid,
          project_id: projectId,
          inspection_qr_code_instances: {
            data: qr.node.inspection_qr_code_instances.map((inst) => {
              const typeMap: { [type: string]: string } = {};
              const object: inspection_instance_insert_input = {
                name: {
                  data: {
                    original: inst.inspection_instance.name.en,
                    en: inst.inspection_instance.name.en,
                  },
                },
                created_by_uid: auth.currentUser?.uid,
                image_required: inst.inspection_instance.image_required,
                model_number:
                  inst.inspection_instance.model_number &&
                  inst.inspection_instance.model_number.trim()
                    ? inst.inspection_instance.model_number.trim()
                    : null,
                model_number_required:
                  inst.inspection_instance.model_number_required,
                project_id: projectId,
                inspection_template_id:
                  inst.inspection_instance.inspection_template_id,
                inspection_labels: {
                  data: inst.inspection_instance.inspection_labels.map(
                    (lb) => ({
                      label_id: lb.label_id,
                    }),
                  ),
                },

                inspection_instance_checklist_items: {
                  data: inst.inspection_instance.inspection_instance_checklist_items.map(
                    (item) => {
                      let typeToInpsert: inspection_instance_checklist_item_insert_input =
                        {};
                      if (item.inspection_checklist_item_type) {
                        if (
                          typeMap[item.inspection_checklist_item_type.text.en]
                        ) {
                          typeToInpsert = {
                            inspection_checklist_item_type_id:
                              typeMap[
                                item.inspection_checklist_item_type.text.en
                              ],
                          };
                        } else {
                          typeMap[item.inspection_checklist_item_type.text.en] =
                            uuid.v4();
                          typeToInpsert = {
                            inspection_checklist_item_type: {
                              data: {
                                id: typeMap[
                                  item.inspection_checklist_item_type.text.en
                                ],
                                text: {
                                  data: {
                                    original:
                                      item.inspection_checklist_item_type.text
                                        .en,
                                    en: item.inspection_checklist_item_type.text
                                      .en,
                                  },
                                },
                              },
                            },
                          };
                        }
                      }

                      return {
                        correct_answer: item.correct_answer,
                        require_photo: item.require_photo,
                        item_type: item.item_type,
                        select_options: item.select_options,
                        description: {
                          data: {
                            original: item.description.en,
                            en: item.description.en,
                          },
                        },
                        item_id: uuid.v4(),
                        inspection_checklist_emails: {
                          data: item.inspection_checklist_emails.map(
                            (itemEmails) => ({
                              user_id: itemEmails.user_id,
                            }),
                          ),
                        },
                        notify_on_deficient: item.notify_on_deficient,
                        sort_index: item.sort_index,
                        show_na: item.show_na,
                        inspection_template_checklist_item_id:
                          item.inspection_template_checklist_item_id,
                        ...typeToInpsert,
                      };
                    },
                  ),
                },
              };
              return { inspection_instance: { data: object } };
            }),
          },
        })),
      },
      updater: (store) => {
        inspectionQROptionsDeleteUpdater(
          GetFullID("inspection_qr_code", prevQRCodeId),
          store,
        );
      },
    });
  };
  return [moveQRToAnotherProject];
};
export default useMoveInspectionQRToAnotherProject;
