import React, { useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { auth } from "src/common/functions/firebase";
import { roleVar } from "src/common/api/apollo/cache";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import PhoneNumberInput from "src/common/components/phoneNumberInput/PhoneNumberInput";
import useLangStrings from "src/utility-features/i18n/context/languageHooks";
import Icon from "src/common/components/general/Icon";
import validatePhoneNumber from "src/common/functions/validatePhoneNumber";
import {
  useCreateWorkerMutation,
  usePtpQrCodeUserSignInThroughSecurityQuestionsMutation,
  usePtpQrUserSignInUsingEmailMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { isApolloError } from "apollo-boost";
import { signInWithCustomToken } from "firebase/auth";
import { Button, Popconfirm } from "antd";
import usePtpQr from "../../../utils/ptpQrHook";
import { PtpQrUserType } from "../../../types/PtpQrUserTypes";
import { MaterialSymbol } from "react-material-symbols";

export interface PtpQrUserPhoneNumberProps extends SiteOrientationStepProps {
  onLoginSuccess: (userId: string) => Promise<void>;
}

const PtpQrUserPhoneNumber: React.FC<PtpQrUserPhoneNumberProps> = (props) => {
  const { ptpQrUser, setPtpQrUser, projectId } = usePtpQr();
  const { userInfo } = ptpQrUser;
  const [ptpQrCodeSignIn] = usePtpQrUserSignInUsingEmailMutation();
  const [ptpQrCodeSignInThroughSecurityQuestions] =
    usePtpQrCodeUserSignInThroughSecurityQuestionsMutation();
  const langStrings = useLangStrings();
  const [createWorker] = useCreateWorkerMutation();
  const phoneNumberIsValid = validatePhoneNumber(userInfo?.phoneNumber ?? "");
  const [loading, setLoading] = useState(false);

  const validatePtpQrUser = (ptpQrUser: PtpQrUserType) => {
    if (!ptpQrUser.name) throw new Error("User name not found");
    if (!ptpQrUser.company) throw new Error("Subcontractor not found");
    if (!ptpQrUser.title) throw new Error("Worker title not found");
    if (!ptpQrUser.role) throw new Error("Worker role not found");
    if (!ptpQrUser.birthDate) throw new Error("Birthdate not found");

    return {
      name: ptpQrUser.name,
      company: ptpQrUser.company,
      role: ptpQrUser.role,
      title: ptpQrUser.title,
      birthDate: ptpQrUser.birthDate,
      email: ptpQrUser.email,
      phoneNumber: ptpQrUser.phoneNumber,
    };
  };

  const onEnter = async () => {
    try {
      if (!userInfo) throw new Error("userInfo not found for ptpsign in");
      if (!(userInfo.email || (userInfo.name && userInfo.birthDate))) {
        throw new Error("User info incomplete to sign in user");
      }

      setLoading(true);
      if (!userInfo.id) {
        //creating user only in case when worker is not present\
        const validatedUser = validatePtpQrUser(userInfo);

        await createWorker({
          variables: {
            email: validatedUser.email,
            name: validatedUser.name,
            phoneNumber: validatedUser.phoneNumber,
            workerRole: validatedUser.role,
            birthDate: validatedUser.birthDate,
            subcontractorId: validatedUser.company,
            title: validatedUser.title,
            projectId,
          },
        });
      }

      const signInResult = userInfo.email
        ? (
            await ptpQrCodeSignIn({
              variables: {
                input: {
                  email: userInfo.email,
                  projectId,
                },
              },
            })
          ).data?.ptpQrUserSignInUsingEmail
        : userInfo.name && userInfo.birthDate
        ? (
            await ptpQrCodeSignInThroughSecurityQuestions({
              variables: {
                input: {
                  name: userInfo.name,
                  workerDob: userInfo.birthDate,
                  projectId,
                },
              },
            })
          ).data?.ptpQrCodeUserSignInThroughSecurityQuestions
        : undefined;

      if (signInResult) {
        const { workerId, token, userRole } = signInResult;
        if (userRole === "employee")
          throw new Error("As an Gc user you cannot submit a ptp");
        if (token) {
          roleVar("none");
          await auth.signOut();
          await signInWithCustomToken(auth, token);
        }
        await props.onLoginSuccess(workerId);
        setLoading(false);
        props.onNext();
      } else throw new Error("Unexpected code reached");
    } catch (err) {
      if (err instanceof Error) {
        if (
          isApolloError(err) &&
          err.graphQLErrors.some(
            (e) => e.extensions?.code === "user-does-not-exist",
          )
        ) {
          props.onNext();
        } else {
          throw err;
        }
      }
    }
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!phoneNumberIsValid}
      loading={loading}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.whatIsYourPhoneNumber}
      >
        <p className="text-1">
          {langStrings.strings.enter10DigitPhoneNumberWithoutSpace}
        </p>
        <PhoneNumberInput
          value={userInfo?.phoneNumber ?? ""}
          onChange={(e) => {
            setPtpQrUser((prevState) => ({
              ...prevState,
              userInfo: {
                ...prevState.userInfo,
                phoneNumber: e,
              },
            }));
          }}
          onEnterPress={onEnter}
        />
        <div className="flex flex-row items-center gap-0.5 text-static-secondary text-1">
          <SafetyCertificateOutlined />
          <p>
            {
              langStrings.strings
                .siteFormIsCommittedToYourPrivacyAndWillNotSellYourData
            }
          </p>
        </div>
        <div className="flex text-static-secondary text-1">
          <Icon
            icon={<MaterialSymbol icon="info" />}
            color="static-secondary"
          />
          {langStrings.strings.yourPhoneNumberMayBeUsed}
        </div>
        <Popconfirm
          disabled={!!userInfo?.phoneNumber?.length || loading}
          title={langStrings.strings.areYouSureYouMayMissLifeSavingMsgs}
          onConfirm={onEnter}
        >
          <Button
            disabled={!!userInfo?.phoneNumber?.length || loading}
            type="text"
            className="self-end"
          >
            {langStrings.strings.continueWithoutPhoneNumber}
          </Button>
        </Popconfirm>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrUserPhoneNumber;
