import { FC, useMemo } from "react";
import {
  Project_Employee_Bool_Exp,
  useGetEmailProjectUserQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import EmailProjectUserSelectView, {
  EmailProjectUserSelectViewProps,
} from "./EmailProjectUserSelectView";

interface EmailProjectUserSelectProps
  extends Omit<
    EmailProjectUserSelectViewProps,
    "selectedUsers" | "userOptions" | "connectionName"
  > {
  extraProjectEmpFilter?: Project_Employee_Bool_Exp;
}

const EmailProjectUserSelect: FC<EmailProjectUserSelectProps> = (props) => {
  const { data: emailProjectUserData } = useGetEmailProjectUserQuery({
    variables: {
      projectId: props.projectId,
      projectEmployeeWhere: {
        project_id: { _eq: props.projectId },
        ...(props.extraProjectEmpFilter || {}),
      },
      type: props.type,
    },
  });

  const selectedUsers = useMemo(() => {
    return (
      emailProjectUserData?.email_project_user
        .filter((t) => t.type === props.type)
        .map((e) => ({
          id: e.user.id,
          name: e.user.name,
        })) ?? []
    );
  }, [props, emailProjectUserData?.email_project_user]);
  const userOptions = useMemo(() => {
    return (
      emailProjectUserData?.project_employee.map((e) => ({
        value: e.employee.user.id,
        label: e.employee.user.name,
      })) ?? []
    );
  }, [props, emailProjectUserData?.project_employee]);

  return (
    <EmailProjectUserSelectView {...{ ...props, userOptions, selectedUsers }} />
  );
};
export default withCustomSuspense(EmailProjectUserSelect);
