import { Form, Modal, Select } from "antd";
import React from "react";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";

interface Values {
  workerUids: Array<string>;
}

interface Props {
  visible: boolean;
  onCreate: (values: Values) => void;
  confirmLoading: boolean;
  onCancel: () => void;
  workers: SCProjectQuery$data["worker_connection"]["edges"];
}

const AddProjectWorkerModal: React.FC<Props> = ({
  visible,
  onCreate,
  onCancel,
  confirmLoading,
  workers,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={visible}
      title="Add Workers to Project"
      okText="Add"
      confirmLoading={confirmLoading}
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={async () => {
        const values = await form.validateFields().catch((v) => null);
        if (!values) return;
        onCreate(values);
        form.resetFields();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="workerUids"
          label="Workers"
          rules={[{ required: true, message: "Choose workers" }]}
        >
          <Select
            mode="multiple"
            optionFilterProp="key"
            style={{ width: "80%" }}
            options={workers.map((w, i) => ({
              value: w.node.pk,
              key: w.node.pk,
              label: w.node.user.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProjectWorkerModal;
