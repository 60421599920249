import { MaterialSymbol } from "react-material-symbols";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import Button from "src/common/components/general/button/Button";
import hexToRgba from "src/common/functions/hexToRgba";
import layoutBlocks, {
  SecPerDay,
} from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/utilities/layoutBlocks";
import {
  DeliveryType,
  SitedeliveryCalendarEventType,
  SitedeliveryCalendarType,
} from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/utilities/sitedeliveryTypes";
import SitedeliveryCalendarEvent from "./events/SitedeliveryCalendarEvent";
import LiveTimeline from "./SitedeliveryLiveTimeline";

type SitedeliveryCalendarDayColumnProps = SitedeliveryCalendarType & {
  onHide: () => void;
  day: dayjs.Dayjs;
};

const SitedeliveryCalendarDayColumn: React.FC<
  SitedeliveryCalendarDayColumnProps
> = (props) => {
  const isToday = dayjs().isSame(props.day, "day");

  const minutesToPercent = (minutes: number) => {
    return (100 / (24 * 60)) * minutes;
  };

  const deliveries: DeliveryType[] = [];
  const notDeliveryEvents: SitedeliveryCalendarEventType[] = [];
  props.events.forEach((event) => {
    if (event.type === "delivery") {
      deliveries.push(event);
    } else {
      notDeliveryEvents.push(event);
    }
  });

  return (
    <div className="relative flex flex-col w-12 h-full">
      <div className="sticky z-50 w-full" style={{ top: "2.25rem" }}>
        <div
          style={{ backgroundColor: hexToRgba(props.colorHex, 0.1) }}
          className="p-0.5 w-full flex flex-row items-center justify-center gap-0.5  rounded-t-1"
        >
          <span className="block text-center truncate">{props.title}</span>
          <Button
            icon={<MaterialSymbol icon="visibility_off" />}
            tiny
            onClick={props.onHide}
            secondary
          />
        </div>
      </div>
      <div className="relative flex flex-col px-0.25 w-full h-full bg-white">
        {isToday && <LiveTimeline />}
        {notDeliveryEvents.map((event) => {
          const fromPercentage = event.from.isBefore(props.day, "day")
            ? 0
            : minutesToPercent(event.from.hour() * 60 + event.from.minute());
          const toPercentage = event.to.isAfter(props.day, "day")
            ? 100
            : minutesToPercent(event.to.hour() * 60 + event.to.minute());

          return (
            <div
              className="absolute rounded-0.25 left-0 w-full"
              key={event.id}
              style={{
                top: `${fromPercentage}%`,
                height: `${toPercentage - fromPercentage}%`,
              }}
            >
              <SitedeliveryCalendarEvent
                {...{ ...event, colorHex: props.colorHex }}
              />
            </div>
          );
        })}
        {layoutBlocks(deliveries, 0, SecPerDay, props.day).map(
          ({ top, bottom, width, offset, delivery }) => (
            <div
              className={clsx("absolute rounded-0.25 left-0 w-full")}
              key={delivery.id}
              style={{
                top: `${top}%`,
                bottom: `${bottom}%`,
                left: `calc((100% - 1rem) * ${offset / 100} + 0.5rem)`,
                width: `calc((100% - 1rem) * ${width / 100} - 0.25rem)`,
              }}
            >
              <SitedeliveryCalendarEvent
                {...{
                  ...delivery,
                  colorHex: props.colorHex,
                }}
              />
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default SitedeliveryCalendarDayColumn;
