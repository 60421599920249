import React from "react";
import { Popover } from "antd";

export type IconType = React.ReactNode;

export interface IconProps {
  size?: "small" | "regular" | "large" | "xl" | "full";
  icon: IconType;
  secondaryIcon?: IconType;
  color?:
    | "interactive"
    | "static"
    | "white"
    | "static-secondary"
    | "warn"
    | "negative"
    | "positive"
    | "suplementary-3"
    | "low-severity"
    | "high-severity";
  hoverContent?: {
    content?: JSX.Element | string;
    title?: string | React.ReactElement;
    enableOnClick?: boolean;
  };
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Icon: React.FunctionComponent<IconProps> = (props) => {
  const className = (() => {
    switch (props.color) {
      case "interactive":
        return "text-interactive-primary";
      case "white":
        return "text-white";
      case "static":
        return "text-static";
      case "suplementary-3":
        return "text-suplementary-3";
      case "static-secondary":
        return "text-static-secondary";
      case "warn":
        return "text-warn";
      case "high-severity":
        return "text-orange-dark";
      case "low-severity":
        return "text-orange-light";
      case "negative":
        return "text-semantic-negative";
      case "positive":
        return "text-semantic-positive-green";
      default:
        return "text-static-primary";
    }
  })();

  // const InnerIcon = props.icon;
  if (props.secondaryIcon) {
    // const SecondaryIcon = props.secondaryIcon;
    return props.hoverContent ? (
      <Popover
        trigger={"hover"}
        content={props.hoverContent.content}
        title={props.hoverContent.title}
      >
        <div
          className={"flex"}
          onClick={props.hoverContent.enableOnClick ? props.onClick : undefined}
        >
          {props.icon}
          {props.secondaryIcon}
        </div>
      </Popover>
    ) : (
      <div className={" flex"} onClick={props.onClick}>
        {props.icon}
        {props.secondaryIcon}
      </div>
    );
  }

  // TODO: Should we have cursor pointer on all icons or only on the ones that are clickable (hoverContent.enableOnClick is true) ?

  return props.hoverContent ? (
    <Popover
      trigger={"hover"}
      content={props.hoverContent.content}
      title={props.hoverContent.title}
    >
      <div
        {...{
          className,
          onClick: props.hoverContent.enableOnClick ? props.onClick : undefined,
        }}
      >
        {props.icon}
      </div>
    </Popover>
  ) : (
    <div {...{ className, onClick: props.onClick }}>{props.icon}</div>
  );
};

export default Icon;
