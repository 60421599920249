import React, { FC } from "react";
import { useParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import CrewDetails from "../../components/CrewDetails";

interface GCProjectCrew {}

const GCProjectCrew: FC<GCProjectCrew> = () => {
  const { crewId, projectId, subcontractorId } = useParams();
  console.log("-----------------------------");

  return crewId && projectId && subcontractorId ? (
    <BasicWrapper scrollable>
      <CrewDetails
        subcontractorId={subcontractorId}
        crewId={crewId}
        projectId={projectId}
        isGCAcc={true}
      />
    </BasicWrapper>
  ) : (
    <div>Wrong link please go back and try again</div>
  );
};

export default GCProjectCrew;
