import { Form, Modal } from "antd";
import React from "react";
import { Project_Worker } from "src/common/types/generated/apollo/graphQLTypes";

interface AddProjectCrewValues {
  // name: string;
  leadForemanProjectWorkerId: string;
}

interface AddProjectCrewModalProps {
  visible: boolean;
  // defaultCrewName: string;
  projectForemen: Array<Project_Worker>;
  onCreate: (values: AddProjectCrewValues) => void;
  onCancel: () => void;
}

const AddProjectCrewModal: React.FC<AddProjectCrewModalProps> = ({
  visible,
  onCreate,
  onCancel,
  projectForemen,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={visible}
      title="Create a new crew"
      okText="Add"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={async () => {
        const values = await form.validateFields().catch((v) => null);
        if (!values) return;
        onCreate(values);
        form.resetFields();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        Creating New Crew
        {/* <Form.Item
          name="leadForemanProjectWorkerId"
          label="Lead Foreman"
          rules={[{ message: "Choose a lead foreman" }]}
        >
          <Select style={{ width: "100%" }}>
            {projectForemen.map((w) => (
              <Select.Option key={w.id} value={w.id}>
                {w.user?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default AddProjectCrewModal;
