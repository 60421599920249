import React from "react";
import { Typography } from "antd";

interface AgeProps {
  children?: React.ReactNode;
}

const Age: React.FunctionComponent<AgeProps> = (props) => {
  return props.children ? (
    <Typography.Text>
      <Typography.Text>{props.children}</Typography.Text>{" "}
      <Typography.Text type={"secondary"}>yo</Typography.Text>
    </Typography.Text>
  ) : null;
};

export default Age;
