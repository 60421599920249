import React, { useState } from "react";
import { Input } from "antd";
import {
  OacUsersObjType,
  SelectableUsersType,
  WorkersObjType,
} from "../../../../../../../utils/siteOrientationTypes";
import SiteOrientationInPersonManagerViewUsersSelectList from "./SiteOrientationInPersonManagerViewUsersSelectList";

export interface SiteOrientationInPersonManagerViewUsersSelectProps {
  activeWorkers: SelectableUsersType<WorkersObjType>;
  archivedWorkers: SelectableUsersType<WorkersObjType>;
  oacUsers: SelectableUsersType<OacUsersObjType>;
}

const SiteOrientationInPersonManagerViewUsersSelect: React.FC<
  SiteOrientationInPersonManagerViewUsersSelectProps
> = (props) => {
  const [searchPrompt, setSearchPrompt] = useState<string>("");

  return (
    <div className={`flex flex-col gap-0`}>
      <Input
        placeholder={`Search for users`}
        value={searchPrompt}
        onChange={(e) => {
          setSearchPrompt(e.target.value);
        }}
      />

      <SiteOrientationInPersonManagerViewUsersSelectList
        listTitle={`Owner, architect, engineer`}
        list={Object.entries(props.oacUsers.users).map(([id, oacUser]) => {
          return {
            selected: props.oacUsers.selectedUserIds[id],
            name: oacUser.name?.old ?? "",
            title: oacUser.title?.name,
            id,
            corporateValid: oacUser.corporateOrientationPassed,
            universalValid: oacUser.universalCompleted,
            companyName: oacUser.company?.title ?? "",
          };
        })}
        onSelectToggle={props.oacUsers.onToggleSelect}
        searchPrompt={searchPrompt}
      />
      <SiteOrientationInPersonManagerViewUsersSelectList
        listTitle={`Workers`}
        list={Object.entries(props.activeWorkers.users).map(([id, worker]) => {
          return {
            selected: props.activeWorkers.selectedUserIds[id],
            name: worker.name?.old ?? "",
            title: worker.title?.name,
            id,
            corporateValid: worker.corporateOrientationPassed,
            universalValid: worker.universalCompleted,
            companyName: worker.company?.title || "Company",
          };
        })}
        onSelectToggle={props.activeWorkers.onToggleSelect}
        searchPrompt={searchPrompt}
      />
      <SiteOrientationInPersonManagerViewUsersSelectList
        listTitle={`Archived workers`}
        hiddenByDefault={true}
        list={Object.entries(props.archivedWorkers.users).map(
          ([id, worker]) => {
            return {
              selected: props.archivedWorkers.selectedUserIds[id],
              name: worker.name?.old ?? "",
              title: worker.title?.name,
              id,
              corporateValid: worker.corporateOrientationPassed,
              universalValid: worker.universalCompleted,
              companyName: worker.company?.title ?? "",
            };
          },
        )}
        onSelectToggle={props.archivedWorkers.onToggleSelect}
        searchPrompt={searchPrompt}
      />
    </div>
  );
};

export default SiteOrientationInPersonManagerViewUsersSelect;
