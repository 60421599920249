import { MenuProps } from "antd";
import { RoutedMenuItem } from "./routedMenuTypes";

import useSelectedMenuKeys from "./useSelectedMenuKeys";
import getMenuItemsByRoutedMenu from "./getMenuItemsByRoutedMenu";
import { useMemo } from "react";

const useRoutedMenuProps: (
  menuItems: RoutedMenuItem[],
  basePath: string,
  title?: string,
) => MenuProps = (features, basePath, orgUnitTitle) => {

  const featuresMenuItems = useMemo(() => 
      getMenuItemsByRoutedMenu(features), 
    [features]);

  const mainMenuItems: MenuProps["items"] = orgUnitTitle
    ? [
        {
          key: "group",
          type: "group",
          label: orgUnitTitle,
          children: featuresMenuItems,
        },
      ]
    : featuresMenuItems;

  const { onSelect, selectedKeys } = useSelectedMenuKeys(
    featuresMenuItems,
    basePath,
  );

  return {
    items: mainMenuItems,
    onSelect,
    selectedKeys,
  };
};

export default useRoutedMenuProps;
