import React, { FC } from "react";

import BackButton from "src/common/components/general/button/BackButton";

import MobileViewWrapper from "src/common/components/layouts/MobileViewWrapper";
import ProjectReportsPermitsDetail from "src/domain-features/sitesafety/components/ProjectReportsPermitsDetail";

const QrProjectPermitReport: FC = () => {
  return (
    <MobileViewWrapper footer={<BackButton />}>
      <div className="w-full min-h-full overflow-x-auto bg-white">
        <ProjectReportsPermitsDetail />
      </div>
    </MobileViewWrapper>
  );
};

export default QrProjectPermitReport;
