import { FilterFilled, LineOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Menu, Space, Table } from "antd";
import CustomButton from "src/common/components/general/button/Button";
import { TableProps } from "antd/es/table";
import { RangePickerProps } from "antd/lib/date-picker";
import { ColumnType } from "antd/lib/table";
import * as Antd from "antd/lib/table/interface";
import dayjs, { Dayjs } from "dayjs";
import React, { FC, PropsWithChildren, useMemo, useRef, useState } from "react";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import {
  SortingTypeType,
  SortOrderType,
} from "src/common/components/SortingTypePicker";
import { Overwrite } from "utility-types";
import CustomTable, {
  CustomTableColumnType,
  CustomTableProps,
  FilterInputType,
} from "./CustomTable";
import { CustomTableCellContentType } from "./CustomTableCell";
import { IconProps } from "../../general/Icon";

export type PkType = { pk: string };

// Here we declare data structures to hold filtering state.

// ScrollTable can render UI to manipulate filtering state.
// but it is responsibility of parent component to apply filtering criteria on data.

// for example DataScrollTable  translates filtering state to "where" clause of GraphQL query.
// other data source  may imlement its own filtering logic using FilderState as input

export type PrimitiveArray = Array<string | number | boolean>;

// removes record if field value is not in values list.
export type InFilterState = {
  type: "in_list";
  values: PrimitiveArray;
};

// removes records outside of date range [from, to)
export type DateRangeFilterState = {
  type: "date_range";
  from?: dayjs.Dayjs | null; // range start including
  to?: dayjs.Dayjs | null; // range end excluding
};

// leaves records if field value contains text as substring
export type SearchFilterState = {
  type: "search";
  text: string;
};

export type FilterState =
  | InFilterState
  | DateRangeFilterState
  | SearchFilterState;
export type FilterTypeName = FilterState["type"];

export type FilterRecord = {
  key: string;
  dataIndex: ReadonlyArray<string>;
  filter: FilterState;
};

export type FilterData = Array<FilterRecord>;
// ------ Sorter state

export type SortOrder = "asc" | "desc" | null;

export type SorterData<RecordType = any> = {
  key: string;
  dataIndex: ReadonlyArray<string>;
  order: SortOrder;
  clientCompareFn?: Antd.CompareFn<RecordType>;
};

export type SearchData = {
  searchValue: string | null;
  fields: {
    key: string;
    dataIndex: ReadonlyArray<string>;
  }[];
};

export type FilterSorterData<RecordType = any> = {
  filterData: FilterData;
  sorterData?: SorterData<RecordType>;
  searchData?: SearchData;
};

export type DropDownFilterItem = {
  text: string;
  value?: string | number | null;
};

export type ScrollTableColumn<T, K = string> = Overwrite<
  ColumnType<T>,
  {
    defaultSortOrder?: SortOrder;
    filters?: {
      dataIndex: Array<string>;
      type?: string;
      initialClose?: boolean;
      options: ColumnType<T>["filters"];
      /**
       * Currently default values are only supported for 'checkbox' type filters
       *
       * TODO: Allow selecting default values for other types also
       */
      defaultValues?: Array<string>;
    };
  }
> & {
  key: K;
  dataIndex: ReadonlyArray<string>;
  searchDataIndex?: ReadonlyArray<string>;
  dateRangeSearchIndex?: ReadonlyArray<string>;
  includeInMainSearchBox?: boolean;
  searchValue?: string;
  dropDownFilterValues?: ReadonlyArray<DropDownFilterItem>;
  sortable?: boolean;
  clientCompareFn?: Antd.CompareFn<T>;
  contentType?: CustomTableCellContentType;
} & Pick<CustomTableColumnType<T>, "popoverWrap" | "size" | "cellPadding">;
export type ScrollTableColumns<T, K = string> = Array<ScrollTableColumn<T, K>>;
type TableOverwrite<T> = {
  title: string;
  columns: NonNullable<ScrollTableColumns<T>>;
  dataSource: NonNullable<TableProps<T>["dataSource"]>;
  pagination?: {
    hasNext: boolean;
    isLoadingNext: boolean;
    onLoadMoreClick: () => void;
  };
};

export interface ScrollTableProps<T extends PkType>
  extends Overwrite<TableProps<T>, TableOverwrite<T>>,
    Pick<CustomTableProps<T>, "narrow" | "topBarButtons" | "interactive"> {
  height?: number;
  totalCount?: number;
  explainerText?: string;
  defaultCustomInitialSort?: boolean;
  customFilters?: CustomFilterType[];
  headerComponent?: React.ReactElement;
  datePickerDataIndex?: Array<string>;
  datePickerDataTitle?: string;
  searchDataIndex?: Array<string>;
  filterSorter?: FilterSorterData<T>;
  onFilterSorterChange?: (data: FilterSorterData<T>) => void;
  excludedKeys?: Array<string>;
  titleIcon?: IconProps;
  disableRowNumber?: boolean;
  headerContol?: () => React.ReactNode;
  newCustomTableLook?: boolean;
  hideFiltersAndSort?: boolean;

  hideFilter?: boolean;
}

const DEFAULT_HEIGHT = 60 * 10;
const REFETCH_DELAY = 250;

//type Range = Parameters<NonNullable<RangePickerProps["onChange"]>>[0];
type Range = RangePickerProps["value"];

const SearchDropdown = ({ onSubmit }: { onSubmit: (str: string) => void }) => {
  const [inputValue, setInputValue] = useState("");

  const handleSearch = (str: string) => {
    onSubmit(str);
  };

  return (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        placeholder="Search..."
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onPressEnter={() => {
          handleSearch(inputValue);
        }}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            handleSearch(inputValue);
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setInputValue("");
            handleSearch("");
          }}
          size="small"
          style={{ width: 90 }}
        >
          Clear
        </Button>
      </Space>
    </div>
  );
};

interface FilterMenuProps {
  values: ReadonlyArray<DropDownFilterItem>;
  setFilter: (value?: string | number | null) => void;
}

const FilterMenu: FC<PropsWithChildren<FilterMenuProps>> = ({
  values,
  setFilter,
}) => {
  return (
    <Menu
      items={values.map((item, i) => ({
        key: item.value || i,
        onClick: () => {
          setFilter(item.value);
        },
        label: item.text,
      }))}
    />
  );
};

const ToAntdSortOrder = (order?: SortOrder): Antd.SortOrder => {
  if (!order) return null;
  return order === "asc" ? "ascend" : "descend";
};

const FromAntdSortOrder = (order?: Antd.SortOrder): SortOrder => {
  if (!order) return null;
  return order === "ascend" ? "asc" : "desc";
};
export const DEFAULT_TABLE_HEADER_HEIGHT = 122;
const BASE_CELL_HEIGHT = 60;

const sizeToWidth = {
  sm: 110,
  md: 160,
  ml: 180,
  lg: 210,
  xl: 250,
  check: 60,
  icon: 60,
  xsm: 80,
};

const ScrollTable = <T extends PkType>({
  title,
  totalCount,
  explainerText,
  columns,
  pagination,
  filterSorter,
  onFilterSorterChange,
  datePickerDataIndex,
  datePickerDataTitle,
  searchDataIndex,
  headerContol,
  excludedKeys = [],
  height = DEFAULT_HEIGHT,
  ...props
}: ScrollTableProps<T>) => {
  const datePicker = useRef<any>();
  const { hasNext, isLoadingNext, onLoadMoreClick } = pagination ?? {};
  const [sortingOrder, setSortingOrder] = useState<SortOrderType>("ascending");
  const [dataIndexFilters, setDataIndexFilters] = useState<{
    [key: string]: { val: string | boolean | string[]; onCancel: () => void };
  }>({});

  const defaultSortCol = columns.find((c) => !!c.defaultSortOrder)!;

  const findColumn = (key: string) => columns.find((c) => c.key === key);
  const getColumn = (key: string) => {
    const column = findColumn(key);
    if (!column) throw new Error(`Column with key ${key} is not found`);
    return column;
  };

  const defaultSorter = {
    key: defaultSortCol.key,
    order: defaultSortCol.defaultSortOrder!,
    dataIndex: defaultSortCol.dataIndex,
    clientCompareFn: defaultSortCol.clientCompareFn,
  };
  const sorterData = filterSorter?.sorterData;
  const filterData = filterSorter?.filterData || [];

  const tableColumns = columns
    .filter((c) => !excludedKeys.includes(c.key))
    .map(
      (c) =>
        ({
          ...c,
          title: typeof c.title === "string" ? c.title : "",
          sorter: c.sortable,
          filters: c.filters,
          filtered: true, ///filterData.some(v => v.key === c.key),
          filteredValue: (
            filterData?.find(
              (d) => d.key === c.key && d.filter.type === "in_list",
            ) as InFilterState | undefined
          )?.values as any,
          sortDirections: ["ascend", "descend", "ascend"],
          defaultSortOrder: ToAntdSortOrder(c.defaultSortOrder),
          sortOrder:
            sorterData?.key === c.key
              ? ToAntdSortOrder(sorterData?.order)
              : null,
          width: c.width ?? (c.size ? sizeToWidth[c.size] : undefined),
          // TODO add search bar?
          ...(c.searchDataIndex
            ? {
                filterMultiple: true,
                filterDropdown: ({ confirm }: any) => (
                  <SearchDropdown
                    onSubmit={(str) => {
                      confirm();

                      let newFilterDate = filterData.filter(
                        (item) =>
                          item.key !== c.key || item.filter.type !== "search",
                      );
                      if (str) {
                        newFilterDate.push({
                          key: c.key,
                          dataIndex: c.dataIndex,
                          filter: {
                            type: "search",
                            text: str,
                          },
                        });
                      }

                      onFilterSorterChange?.({
                        ...filterSorter,
                        filterData: newFilterDate,
                      });
                    }}
                  />
                ),
                filterIcon: (filtered: boolean) => (
                  <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                  />
                ),
              }
            : c.dropDownFilterValues
            ? {
                filterMultiple: true,
                filterDropdown: ({ confirm }: any) => (
                  <FilterMenu
                    values={c.dropDownFilterValues!}
                    setFilter={(v) => {
                      let newFilterDate = filterData.filter(
                        (item) =>
                          item.key !== c.key || item.filter.type !== "in_list",
                      );
                      if (v) {
                        newFilterDate.push({
                          key: c.key,
                          dataIndex: c.dataIndex,
                          filter: {
                            type: "in_list",
                            values: [`${v}`],
                          },
                        });
                      }
                      onFilterSorterChange?.({
                        ...filterSorter,
                        filterData: newFilterDate,
                      });
                    }}
                  />
                ),
                filterIcon: (filtered: boolean) => (
                  <SearchOutlined
                    style={{ color: filtered ? "#1890ff" : undefined }}
                  />
                ),
              }
            : {}),
        } as ColumnType<T> &
          CustomTableColumnType<T> &
          ScrollTableColumn<T, string>),
    );
  const showFiltered = filterData.length > 0;
  const rangeFilter = filterData.find(
    (rec) => rec.filter.type === "date_range",
  )?.filter;
  const rangeValue: [Dayjs | null, Dayjs | null] =
    rangeFilter?.type === "date_range"
      ? [rangeFilter.from ?? null, rangeFilter.to ?? null]
      : [null, null];

  const sortableColumns: SortingTypeType[] = [];
  const filterableColumns: FilterInputType[] = [];

  const submitFilterring = (
    valueId: string,
    columnKey: string,
    columnDataIndex: Readonly<string[]>,
    title?: string,
    textLabel?: string | null,
    cancelFunc?: boolean,
  ) => {
    const columnFilter = filterData.find(
      (column) => columnKey === column.key && column.filter.type === "in_list",
    );
    const newFilterData = filterData.filter((item) => item !== columnFilter);
    const values =
      columnFilter && columnFilter.filter.type === "in_list"
        ? columnFilter.filter.values
        : [];

    const hasValue = values.includes(valueId);
    const newValues = hasValue
      ? values.filter((value) => value !== valueId)
      : [...values, valueId];
    if (newValues.length > 0) {
      newFilterData.push({
        dataIndex: columnDataIndex,
        filter: { type: "in_list", values: newValues },
        key: columnKey,
      });
    }
    onFilterSorterChange?.({
      sorterData,
      filterData: newFilterData,
    });
  };

  if (!!datePickerDataIndex) {
    filterableColumns.push({
      controller: {
        type: "date-range",
        props: {
          onChange: (newRange) => {
            let newFilterData = filterData.filter(
              (r) => r.key !== ScrollTable.DATE_RANGE_PICKER_KEY,
            );
            if (newRange) {
              const [from, to] = newRange;
              if (from || to) {
                newFilterData.push({
                  dataIndex: datePickerDataIndex,
                  key: ScrollTable.DATE_RANGE_PICKER_KEY,
                  filter: {
                    type: "date_range",
                    from,
                    to,
                  },
                });
              }
            }

            onFilterSorterChange?.({
              sorterData,
              filterData: newFilterData,
            });
          },
          rangeValue: rangeValue || undefined,
        },
      },
      title: datePickerDataTitle ?? "Date range",
      id: datePickerDataIndex.join("."),
    });
  }
  tableColumns.forEach((column, id) => {
    if (column.sortable && column.title) {
      sortableColumns.push({
        title: column.title,
        id: column.key,
      });
    }
    if (
      column.searchDataIndex &&
      column.title &&
      column.includeInMainSearchBox === false
    ) {
      const dataIndex = column.searchDataIndex;
      filterableColumns.push({
        controller: {
          type: "search",
          props: {
            onChange: (newVal) => {
              let newFilterData = filterData.filter(
                (r) => r.key !== ScrollTable.SEARCH_INPUT_KEY,
              );
              if (newVal) {
                newFilterData.push({
                  dataIndex,
                  key: ScrollTable.SEARCH_INPUT_KEY,
                  filter: {
                    type: "search",
                    text: newVal,
                  },
                });
                setDataIndexFilters((prev) => ({
                  ...prev,
                  ["search"]: {
                    val: [column.title, newVal],
                    onCancel: () => {},
                  },
                }));
              } else {
                setDataIndexFilters((prev) => ({
                  ...prev,
                  ["search"]: {
                    val: [column.title, newVal],
                    onCancel: () => {
                      let newFilterData = filterData.filter(
                        (r) => r.key !== ScrollTable.SEARCH_INPUT_KEY,
                      );
                      newFilterData.push({
                        dataIndex,
                        key: ScrollTable.SEARCH_INPUT_KEY,
                        filter: {
                          type: "search",
                          text: "",
                        },
                      });
                      onFilterSorterChange?.({
                        sorterData,
                        filterData: newFilterData,
                      });
                      setDataIndexFilters((prev) => ({
                        ...prev,
                        ["search"]: {
                          val: [column.title, ""],
                          onCancel: () => {},
                        },
                      }));
                    },
                  },
                }));
              }
              onFilterSorterChange?.({
                sorterData,
                filterData: newFilterData,
              });
            },
          },
        },
        title: column.title,
        id: column.searchDataIndex.join("."),
      });
    }
    if (column.dateRangeSearchIndex && column.title) {
      const dataIndex = column.dateRangeSearchIndex;
      filterableColumns.push({
        controller: {
          type: "date-range",
          props: {
            onChange: (newRange) => {
              let newFilterData = filterData.filter(
                (r) => r.key !== column.key,
              );
              if (newRange) {
                const [from, to] = newRange;
                if (from && to) {
                  newFilterData.push({
                    dataIndex,
                    key: column.key,
                    filter: {
                      type: "date_range",
                      from,
                      to,
                    },
                  });
                  setDataIndexFilters((prev) => ({
                    ...prev,
                    [column.title]: {
                      val:
                        from && to
                          ? dayjs(from).format("MM/DD/YYYY") +
                            " - " +
                            dayjs(to).format("MM/DD/YYYY")
                          : from
                          ? "After " + dayjs(from).format("MM/DD/YYYY")
                          : "Before " + dayjs(to).format("MM/DD/YYYY"),
                      onCancel: () => {
                        let newFilterData = filterData.filter(
                          (r) => r.key !== column.key,
                        );

                        onFilterSorterChange?.({
                          sorterData,
                          filterData: newFilterData,
                        });
                        setDataIndexFilters((prev) => ({
                          ...prev,
                          [column.title]: {
                            val: false,
                            onCancel: () => {},
                          },
                        }));
                      },
                    },
                  }));
                } else {
                  setDataIndexFilters((prev) => ({
                    ...prev,
                    [column.title]: {
                      val: false,
                      onCancel: () => {},
                    },
                  }));
                }
              }
              onFilterSorterChange?.({
                sorterData,
                filterData: newFilterData,
              });
            },
            rangeValue: rangeValue || [null, null],
          },
        },
        title: column.title,
        id: column.dateRangeSearchIndex.join("."),
      });
    }

    if (column.filters && column.title) {
      filterableColumns.push(
        column.filters.type === "select"
          ? {
              controller: {
                type: "select",
                props: {
                  items: !column.filters.options
                    ? []
                    : column.filters.options.map(({ text, value }) => {
                        let newFilterData = filterData.filter(
                          (r) => r.key !== column.key,
                        );
                        return {
                          id: value + "",
                          label: text?.toString() ?? "",
                        };
                      }),
                  onChange: (valudIds) => {
                    let newFilterData = filterData.filter(
                      (r) => r.key !== column.key,
                    );
                    if (valudIds && column.filters?.dataIndex) {
                      newFilterData.push({
                        dataIndex: column.filters?.dataIndex,
                        key: column.key,
                        filter: {
                          type: "in_list",
                          values: valudIds,
                        },
                      });
                      onFilterSorterChange?.({
                        sorterData,
                        filterData: newFilterData,
                      });
                    }
                  },
                },
              },
              title: column.title ?? "",
              id: column.key,
            }
          : {
              controller: {
                type: "checklist",
                initialClose: column.filters.initialClose,
                props: {
                  items: !column.filters.options
                    ? []
                    : column.filters.options.map(({ text, value }) => {
                        const foundFilterData = filterData.find(
                          ({ key }) => key === column.key,
                        );
                        return {
                          id: value + "",
                          label: text?.toString() ?? "",
                          selected:
                            foundFilterData?.filter?.type === "in_list" &&
                            foundFilterData.filter.values.some(
                              (filterValue) => filterValue + "" === value + "",
                            ),
                        };
                      }),
                  onClick: (valudId) => {
                    const textLabel =
                      column.filters?.options &&
                      column.filters?.options?.find((p) => p.value === valudId);
                    column.filters?.dataIndex &&
                      submitFilterring(
                        valudId,
                        column.key,
                        column.filters.dataIndex,
                        column.title,
                        textLabel?.text?.toString(),
                      );
                    const foundFilterData = filterData.find(
                      ({ key }) => key === column.key,
                    );
                  },
                },
              },
              title: column.title ?? "",
              id: column.key,
            },
      );
    }
  });

  const searchableColumns = useMemo(
    () =>
      tableColumns.filter(
        ({ searchDataIndex, includeInMainSearchBox, title }) => {
          return !!searchDataIndex && includeInMainSearchBox !== false;
        },
      ),
    [tableColumns],
  );
  const submitSearch = (searchValue: string) => {
    const searchData: SearchData = {
      fields: searchableColumns.map(({ key, dataIndex }) => ({
        dataIndex,
        key,
      })),
      searchValue: searchValue,
    };
    console.log("submitSearch");
    onFilterSorterChange?.({
      ...filterSorter,
      filterData,
      searchData,
    });
  };

  const selectedSortingTypeId = sortableColumns.find(
    ({ id }) => id === filterSorter?.sorterData?.key,
  )?.id;

  const submitSorting = (sortOrder: SortOrderType, columnKey: string) => {
    console.log("submitSorting");
    const column = getColumn(columnKey);
    onFilterSorterChange?.({
      filterData,
      sorterData: {
        order: sortOrder === "ascending" ? "asc" : "desc",
        dataIndex: column.dataIndex,
        key: columnKey,
        clientCompareFn: column.clientCompareFn, //???  why we pass it here... consider to remove it
      },
    });
  };
  return !props.newCustomTableLook ? (
    <Table
      {...props}
      bordered
      columns={tableColumns}
      pagination={false}
      rowKey={(r) => r.pk}
      rowClassName={(_, index) =>
        index % 2 ? "table-row-dark" : "table-row-light"
      }
      rowSelection={
        props.dataSource.length > 0 ? props.rowSelection : undefined
      }
      title={
        !title
          ? () => ""
          : () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="text-1.25">
                  {title}{" "}
                  {totalCount !== null && totalCount !== undefined
                    ? `(${totalCount})`
                    : ""}
                  {showFiltered && (
                    <FilterFilled
                      style={{
                        color: "gray",
                        marginLeft: 16,
                      }}
                    />
                  )}
                </div>
                <div>
                  {props.topBarButtons && (
                    <div className={`flex flex-row items-center gap-0.5`}>
                      {props.topBarButtons.map((buttonProps, i) => (
                        <CustomButton {...buttonProps} key={i} />
                      ))}
                    </div>
                  )}
                  {searchDataIndex && (
                    <Input
                      type="primary"
                      style={{ width: "200px" }}
                      placeholder="Search"
                      inputMode="search"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          const text = (event.target as HTMLInputElement).value;
                          let newFilterData = filterData.filter(
                            (r) => r.key !== ScrollTable.SEARCH_INPUT_KEY,
                          );
                          if (text) {
                            newFilterData.push({
                              dataIndex: searchDataIndex,
                              key: ScrollTable.SEARCH_INPUT_KEY,
                              filter: {
                                type: "search",
                                text,
                              },
                            });
                            // secondarySearchDataIndexes.forEach((dataIndex) =>
                            //   newFilterData.push({
                            //     dataIndex,
                            //     key: ScrollTable.SEARCH_INPUT_KEY,
                            //     filter: {
                            //       type: "search",
                            //       text,
                            //     },
                            //   })
                            // );
                          }
                          onFilterSorterChange?.({
                            sorterData,
                            filterData: newFilterData,
                          });
                        }
                      }}
                    />
                  )}
                  {!!datePickerDataIndex && (
                    <DatePicker.RangePicker
                      ref={datePicker}
                      style={{ float: "right" }}
                      value={rangeValue || undefined}
                      allowEmpty={[true, true]}
                      presets={[
                        {
                          label: "Today",
                          value: [dayjs().startOf("day"), dayjs().endOf("day")],
                        },
                        {
                          label: "This week",
                          value: [
                            dayjs().startOf("week"),
                            dayjs().endOf("week"),
                          ],
                        },
                        {
                          label: "This month",
                          value: [
                            dayjs().startOf("month"),
                            dayjs().endOf("month"),
                          ],
                        },
                        {
                          label: "Last month",
                          value: [
                            dayjs().subtract(1, "month").startOf("month"),
                            dayjs().subtract(1, "month").endOf("month"),
                          ],
                        },
                      ]}
                      onChange={(newRange: Range) => {
                        let newFilterData = filterData.filter(
                          (r) => r.key !== ScrollTable.DATE_RANGE_PICKER_KEY,
                        );
                        if (newRange) {
                          const [from, to] = newRange;
                          if (from || to) {
                            newFilterData.push({
                              dataIndex: datePickerDataIndex,
                              key: ScrollTable.DATE_RANGE_PICKER_KEY,
                              filter: {
                                type: "date_range",
                                from,
                                to,
                              },
                            });
                          }
                        }
                        onFilterSorterChange?.({
                          sorterData,
                          filterData: newFilterData,
                        });
                        datePicker.current && datePicker.current.blur();
                      }}
                    />
                  )}
                  {headerContol?.()}
                </div>
              </div>
            )
      }
      onChange={(_, filters, sorter) => {
        const sorterData =
          "order" in sorter &&
          sorter.columnKey &&
          typeof sorter.columnKey === "string" &&
          sorter.order
            ? {
                key: sorter.columnKey,
                dataIndex: getColumn(sorter.columnKey).dataIndex,
                clientCompareFn: getColumn(sorter.columnKey).clientCompareFn, // TODO: consider toremove it
                // TODO distinguish between asc, asc_null_first, ...
                order: FromAntdSortOrder(sorter.order),
              }
            : defaultSorter;
        let newFilterData = filterData;
        if (filters) {
          Object.entries(filters).forEach(([key, values]) => {
            newFilterData = newFilterData.filter((v) => v.key !== key);
            if (values) {
              newFilterData.push({
                key,
                dataIndex: getColumn(key).dataIndex,
                filter: {
                  type: "in_list",
                  values: values as PrimitiveArray,
                },
              });
            }
          });
        }
        onFilterSorterChange?.({
          sorterData,
          filterData: newFilterData,
        });
      }}
      summary={() =>
        props.dataSource.length > 0 && (
          <Table.Summary.Row>
            <Table.Summary.Cell
              index={0}
              colSpan={
                tableColumns.length +
                (props.expandable ? 1 : 0) +
                (props.rowSelection ? 1 : 0)
              }
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  minHeight:
                    props.dataSource.length > ScrollTable.INITIAL_COUNT ||
                    !hasNext
                      ? undefined
                      : height -
                        (props.dataSource.length + 1) * BASE_CELL_HEIGHT +
                        12,
                }}
              >
                {hasNext ? (
                  <Button
                    type="primary"
                    loading={isLoadingNext}
                    onClick={onLoadMoreClick}
                  >
                    Load more
                  </Button>
                ) : (
                  <LineOutlined />
                )}
              </div>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )
      }
    />
  ) : (
    <CustomTable
      hideFilter={props.hideFilter}
      narrow={props.narrow}
      loading={typeof props.loading === "boolean" ? props.loading : undefined}
      customFilters={props.customFilters}
      headerComponent={props.headerComponent}
      dataIndexFilters={dataIndexFilters}
      columns={tableColumns.map((column, i) => {
        const dataIndex = Array.isArray(column.dataIndex)
          ? column.dataIndex.map((dataKey) => dataKey + "")
          : column.dataIndex + "";

        return {
          dataIndex,
          title: column.title,
          render: column.render,
          contentType: column.contentType,
          popoverWrap: column.popoverWrap,
          id: i + "",
          size: column.size,
          cellPadding: column.cellPadding,
        };
      })}
      hideFiltersAndSort={props.hideFiltersAndSort}
      topBarButtons={props.topBarButtons}
      interactive={props.interactive}
      dataSource={props.dataSource}
      title={
        title +
        (totalCount !== null && totalCount !== undefined
          ? ` (${totalCount})`
          : "")
      }
      explainerText={explainerText}
      titleIcon={props.titleIcon}
      totalCount={totalCount}
      onRowClick={props.onRow}
      search={
        !!searchableColumns.length
          ? {
              onSubmit: submitSearch,
            }
          : undefined
      }
      filter={
        !!filterableColumns.length
          ? {
              inputs: filterableColumns,
              onSubmit: (filterSearchQuery) => {
                const column = findColumn(filterSearchQuery.filterControllerId);
                if (column) {
                  submitFilterring(
                    filterSearchQuery.inputId,
                    filterSearchQuery.filterControllerId,
                    column.dataIndex,
                  );
                }
              },
            }
          : undefined
      }
      sorting={{
        sortingTypes: sortableColumns,
        onOrderClick: (newSortingOrder) => {
          setSortingOrder(newSortingOrder);
          !!selectedSortingTypeId &&
            submitSorting(newSortingOrder, selectedSortingTypeId);
        },
        order: sortingOrder,
        onSortingTypeClick: (newSortingTypeId) => {
          !!selectedSortingTypeId &&
            submitSorting(sortingOrder, newSortingTypeId);
        },
        selectedSortingTypeId,
      }}
      pagination={
        pagination
          ? {
              loading: pagination.isLoadingNext,
              hasMore: pagination.hasNext,
              loadMore: pagination.onLoadMoreClick,
            }
          : undefined
      }
    />
  );
};

// TODO increase if table has only little more (ex 8) than 7 so don't have to load more just for a few
ScrollTable.INITIAL_COUNT = 20;
ScrollTable.FETCH_MORE_COUNT = 45;
ScrollTable.LOAD_ALL_COUNT = 100000;
ScrollTable.DEFAULT_HEIGHT = DEFAULT_HEIGHT;
ScrollTable.DATE_RANGE_PICKER_KEY = "DATE_RANGE_PICKER_KEY";
ScrollTable.SEARCH_INPUT_KEY = "SEARCH_INPUT_KEY";

export default ScrollTable;
