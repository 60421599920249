import React, { FC, useState } from "react";
import { AGCEmployeeQuery$data } from "src/common/types/generated/relay/AGCEmployeeQuery.graphql";
import { useNavigate } from "react-router-dom";
import { Image } from "antd";
import CreateNewOrientationModal from "src/common/components/dialogs/CreateNewOrientationModal";
import OrientationModulesTable from "../../../../components/OrientationModulesTable";
import UploadButton from "src/common/components/general/upload/UploadButton";
import { graphql } from "babel-plugin-relay/macro";
import { AGCOrientations_UpdateMutation } from "src/common/types/generated/relay/AGCOrientations_UpdateMutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import OpenQrCode from "src/common/components/OpenQrCode";
import iconLogo from "src/common/assets/icon-logo.png";

interface AGCOrientationsProps {
  data: AGCEmployeeQuery$data["orientation_connection"]["edges"];
  posterUrl: string;
  agcCompanyId: string;
}

export const updateposterBackgroundMutation = graphql`
  mutation AGCOrientations_UpdateMutation(
    $id: uuid!
    $_set: general_contractor_set_input
  ) {
    update_general_contractor_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

const AGCOrientations: FC<AGCOrientationsProps> = ({
  data,
  posterUrl,
  agcCompanyId,
}) => {
  const [addToGC, isAdding] = useAsyncMutation<AGCOrientations_UpdateMutation>(
    updateposterBackgroundMutation,
  );
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [posterBackgroundImageUrl, setPosterBackgroundImageUrl] =
    useState(posterUrl);
  return (
    <>
      <CreateNewOrientationModal
        visible={showCreateModal}
        onSuccess={(oId) => {
          setShowCreateModal(false);
          navigate(
            `/agc/orientation/${oId}?universal-orientation-setting=true`,
          );
        }}
        type="universal"
        onCancel={() => setShowCreateModal(false)}
        gcProjectIds={[]}
      />
      <OrientationModulesTable
        hasInPerson={false}
        isHigherLevelLogin
        hasUniversal={true}
        onAddOrientation={() => {
          setShowCreateModal(true);
        }}
        excludeColumns={["registration", "inperson", "tv", "type"]}
        dataSource={data.map((v) => v.node)}
        onRow={({ pk }) => {
          navigate(
            "/agc/orientation/" + pk + "?universal-orientation-setting=true",
          );
        }}
      />
      <div className="flex ">
        <div className="w-1/2">
          <h3 className="text-1.25 mb-2 ml-1 w-fit  ">Orientation Poster</h3>
          <div className="flex flex-row items-end">
            <div className="w-1/3">
              <Image src={posterBackgroundImageUrl} />
            </div>
            <div className="ml-2">
              <UploadButton
                accept="image/*"
                children="Upload"
                onFileUpload={async (imageUrl) => {
                  setPosterBackgroundImageUrl(imageUrl);
                  // const user = await auth.currentUser?.uid();
                  await addToGC({
                    variables: {
                      id: agcCompanyId,
                      _set: {
                        orientation_qr_code_poster_background_url: imageUrl,
                      },
                    },
                  });
                }}
                // loading={props.loading}
              />
            </div>
          </div>
        </div>
        <div>
          <h3 className="text-1.25 mb-0 ml-1 ">
            CARE Non-Project Specific Orientation
          </h3>
          <OpenQrCode
            destinationUrl={`${document.location.origin}/universal/orientation/`}
            alwaysVisible={true}
            downloadableFileName="AGC_CARE_Orientation_QR"
            imageSettings={{
              src: iconLogo,
              excavate: true,
              height: 100,
              width: 100,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default AGCOrientations;
