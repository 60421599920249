import { MaterialSymbol } from "react-material-symbols";
import dayjs from "dayjs";
import React, { FC } from "react";
import Image, { ImageProps } from "src/common/components/general/images/Image";
import LabelIcon from "src/common/components/general/LabelIcon";
import Separator from "src/common/components/general/Separator";
import getDateStr from "src/common/functions/dates/getDateStr";

export type GCDailyReportImageType = {
  image: Pick<ImageProps, "src" | "alt">;
  author: string;
  time?: dayjs.Dayjs; //taken or added
  subcontractorTitle: string;
};

const GCProjectReportsDailySummaryPhotosItem: FC<GCDailyReportImageType> = (
  props,
) => {
  return (
    <>
      <div className="flex flex-col gap-0.5">
        <Image
          {...{
            ...props.image,
            preview: false,
          }}
        />
        <div className="flex my-auto flex-col gap-0.25">
          <LabelIcon
            icon={<MaterialSymbol icon={"work"} />}
            label={props.subcontractorTitle}
          />
          {!!props.author && (
            <LabelIcon
              icon={<MaterialSymbol icon={"edit"} />}
              label={props.author}
            />
          )}
          {!!props.time && (
            <LabelIcon
              icon={<MaterialSymbol icon={"calendar_today"} />}
              label={getDateStr(props.time, { withTime: true })}
            />
          )}
        </div>
      </div>
    </>
  );
};

interface GCProjectReportsDailySummaryPhotosProps {
  items: GCDailyReportImageType[];
}

const GCProjectReportsDailySummaryPhotos: React.FC<
  GCProjectReportsDailySummaryPhotosProps
> = (props) => {
  return !!props.items.length ? (
    <div className="flex w-full flex-col  gap-0.5">
      {props.items.reduce<React.ReactNode>(
        (acc, item, i) => (
          <>
            {acc}
            <GCProjectReportsDailySummaryPhotosItem {...item} />
            <Separator />
          </>
        ),
        <></>,
      )}
    </div>
  ) : null;
};

export default GCProjectReportsDailySummaryPhotos;
