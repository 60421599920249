import React, { forwardRef, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { Checkbox, message } from "antd";
import { UploadedToolboxTalkTable_uploaded_toolbox_talk$data } from "src/common/types/generated/relay/UploadedToolboxTalkTable_uploaded_toolbox_talk.graphql";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { UploadedToolboxTalkTableQuery } from "src/common/types/generated/relay/UploadedToolboxTalkTableQuery.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { UploadedToolboxTalkTableMutation } from "src/common/types/generated/relay/UploadedToolboxTalkTableMutation.graphql";
import { UploadedToolboxTalkTable_AddToAnotherProject_Mutation } from "src/common/types/generated/relay/UploadedToolboxTalkTable_AddToAnotherProject_Mutation.graphql";
import GetFullID from "src/common/functions/GetFullId";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Button from "src/common/components/general/button/Button";
import Icon from "src/common/components/general/Icon";
import CounterLabel from "src/common/components/general/CounterLabel";
import { insertUploadedTBTMutation } from "./ManageToolboxTalks";
import { ManageToolboxTalksMutation } from "src/common/types/generated/relay/ManageToolboxTalksMutation.graphql";
import { MaterialSymbol } from "react-material-symbols";

type ColumnKeys =
  | "check"
  | "date"
  | "project"
  | "createdBy"
  | "workers"
  | "subcontractor"
  | "general_contractor"
  | "topic"
  | "presented"
  | "due_date";
const CONNECTION_NAME = "uploaded_toolbox_talk_connection";
type DConnection =
  UploadedToolboxTalkTable_uploaded_toolbox_talk$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  UploadedToolboxTalkTableQuery,
  string
> & {
  userId: string;
  projectId?: string;
  gcId?: string;
  subId?: string;
  community?: boolean;
  hideFiltersAndSort?: boolean;
  headerComponent?: React.ReactElement;
  showDateRangeFilter?: boolean;
  onAddFromAnotherProjectToThisProjectFinish?: () => void;
};

const UploadedToolboxTalkTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Toolbox Talks",
      onRowClick,
      showDateRangeFilter,
      projectId,
      subId,
      gcId,
      userId,

      onAddFromAnotherProjectToThisProjectFinish,
      community,
      ...props
    },
    ref,
  ) => {
    const [insertUploadedTBT, inserting] =
      useAsyncMutation<ManageToolboxTalksMutation>(insertUploadedTBTMutation);
    const [toDelete, setToDelete] = useState<{ [key: string]: boolean }>({});
    const [archivedUploadedTBT, loading] =
      useAsyncMutation<UploadedToolboxTalkTableMutation>(graphql`
        mutation UploadedToolboxTalkTableMutation(
          $curTime: timestamptz!
          $ids: [uuid!]!
        ) {
          update_uploaded_toolbox_talk(
            where: { id: { _in: $ids } }
            _set: { archived_at: $curTime }
          ) {
            affected_rows
            returning {
              archived_at
              id
            }
          }
        }
      `);
    const [addProjectSpecificToAnotherProject, loading2] =
      useAsyncMutation<UploadedToolboxTalkTable_AddToAnotherProject_Mutation>(graphql`
        mutation UploadedToolboxTalkTable_AddToAnotherProject_Mutation(
          $objects: [uploaded_toolbox_talk_project_insert_input!]!
        ) {
          insert_uploaded_toolbox_talk_project(objects: $objects) {
            affected_rows
            returning {
              id
            }
          }
        }
      `);
    return (
      <DataScrollTable<DConnection, ColumnKeys, UploadedToolboxTalkTableQuery>
        {...props}
        newCustomTableLook
        ref={ref}
        title={title}
        datePickerDataIndex={showDateRangeFilter ? ["created_at"] : undefined}
        onRowClick={(tbt) => {
          onRowClick && onRowClick(tbt.pk);
        }}
        hideFiltersAndSort={props.hideFiltersAndSort}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allReportsConnection"}
        topBarButtons={
          !community && Object.values(toDelete).filter((val) => val).length > 0
            ? [
                ...(props.topBarButtons ?? []),
                onAddFromAnotherProjectToThisProjectFinish
                  ? {
                      label: "Add",
                      icon: <MaterialSymbol icon="check" />,
                      loading: loading || loading2,
                      onClick: async () => {
                        const tbtsSelected = Object.entries(toDelete).filter(
                          ([_, val]) => val,
                        );
                        await addProjectSpecificToAnotherProject({
                          variables: {
                            objects: tbtsSelected.map(([tbtId, _]) => ({
                              project_id: projectId,
                              uploaded_toolbox_talk_id: tbtId,
                            })),
                          },
                        });
                        setToDelete({});
                        onAddFromAnotherProjectToThisProjectFinish();
                      },
                    }
                  : {
                      label: "Delete",
                      icon: <MaterialSymbol icon="delete" />,
                      delete: true,
                      loading: loading,
                      onClick: async () => {
                        const objectsToDelete = Object.entries(toDelete).filter(
                          ([_, val]) => val,
                        );
                        await archivedUploadedTBT({
                          variables: {
                            ids: objectsToDelete.map(([pk, _]) => pk),
                            curTime: dayjs().toISOString(),
                          },
                          optimisticResponse: {
                            update_uploaded_toolbox_talk: {
                              affected_rows: objectsToDelete.length,
                              returning: objectsToDelete.map(([pk, _]) => ({
                                archived_at: dayjs().toISOString(),
                                id: GetFullID("uploaded_toolbox_talk", pk),
                              })),
                            },
                          },
                        });
                        setToDelete({});
                        if (ref && typeof ref !== "function")
                          ref.current?.refetch();
                      },
                    },
              ]
            : props.topBarButtons
        }
        columns={[
          {
            title: community ? "Add to Project" : "",
            key: "check",
            dataIndex: ["pk"],
            size: "icon",
            render: (pk, row) => {
              return community ? (
                <BPopconfirm
                  title={
                    <div>
                      Are you sure you want to add <br />
                      this TBT to your library?
                    </div>
                  }
                  onConfirm={async (e) => {
                    e?.stopPropagation();
                    try {
                      await insertUploadedTBT({
                        variables: {
                          object: {
                            added_to_community: false,
                            name: row.name,
                            created_by_uid: userId,
                            ...(row.content
                              ? {
                                  content: {
                                    data: { original: row.content.en },
                                  },
                                }
                              : {}),
                            images: {
                              data: row.images.map((p, i) => ({
                                url: p.url,
                                sort_index: i,
                                description: "Toolbox Talk",
                              })),
                            },
                            ...(gcId
                              ? {
                                  general_contractor_id: gcId,
                                  assign_to: "all-subcontractors",
                                  uploaded_toolbox_talk_projects: {
                                    data: [{ project_id: projectId }],
                                  },
                                }
                              : subId && projectId
                              ? {
                                  subcontractor_id: subId,
                                  uploaded_toolbox_talk_projects: {
                                    data: [{ project_id: projectId }],
                                  },
                                }
                              : subId
                              ? {
                                  subcontractor_id: subId,
                                  assign_to: "all-projects",
                                }
                              : {}),
                          },
                        },
                      });
                      message.success(
                        "Successfully added this TBT to your Library",
                      );
                    } catch (err) {
                      console.log(err);
                      message.error(
                        "Error: Couldn't add this TBT to your Library",
                      );
                    }
                  }}
                >
                  <Button
                    label=""
                    onClick={() => {}}
                    icon={<MaterialSymbol icon="add" />}
                    loading={inserting}
                  />
                </BPopconfirm>
              ) : (
                <div
                  className="flex justify-center"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Checkbox
                    checked={toDelete[pk]}
                    onChange={(e) => {
                      setToDelete((prev) => ({
                        ...prev,
                        [pk]: e.target.checked,
                      }));
                    }}
                  />
                </div>
              );
            },
          },
          {
            title: "Topic",
            key: "topic",
            sortable: true,
            size: "md",
            dataIndex: ["name"],
            searchDataIndex: ["name"],
            contentType: {
              type: "note",
            },
          },
          {
            title: "Due Date",
            key: "due_date",
            sortable: true,
            size: "md",
            dataIndex: ["due_time"],
            render: (a) =>
              a ? (
                <div className="flex flex-row gap-0.5 items-center ">
                  <Icon
                    {...{
                      icon: <MaterialSymbol icon={"calendar_today"} />,
                      color: "static",
                    }}
                  />
                  <span className="text-1">
                    {dayjs(a).format("MM/DD/YYYY")}
                  </span>{" "}
                  <Icon
                    icon={<MaterialSymbol icon="info" />}
                    hoverContent={{
                      content: (
                        <div className="w-24">
                          Select the date a TBT should be completed by.
                          <br /> By adding a date, SiteForm will mark a TBT due
                          and possibly show the TBT during a Pre-Task Plan
                          meeting. Not selecting a date adds the TBT to your
                          crews’ list for your foreman to select and deliver to
                          his crew.
                        </div>
                      ),
                    }}
                  />
                </div>
              ) : (
                <div className="flex">
                  <Icon
                    {...{
                      icon: <MaterialSymbol icon={"calendar_today"} />,
                      color: "static",
                    }}
                  />
                  none selected
                </div>
              ),
          },
          {
            title: "Added By",
            key: "createdBy",
            size: "md",
            sortable: true,
            dataIndex: ["created_by_user", "name"],
            searchDataIndex: ["created_by_user", "name"],
            render: (v, r) => {
              if (community) return "";
              return (
                <div className="flex">
                  <Icon icon={<MaterialSymbol icon={"work"} />} /> {v}
                  {", "}
                  {r.created_by_user.employee
                    ? r.created_by_user.employee.general_contractor.name
                    : r.created_by_user.subcontractor_employee
                    ? r.created_by_user.subcontractor_employee.subcontractor
                        .name
                    : r.subcontractor?.name ?? ""}
                </div>
              );
            },
          },

          {
            title: "Created on",
            key: "date",
            sortable: true,
            contentType: {
              type: "date",
              renderOptions: () => ({ format: "full", withTime: true }),
            },
            size: "md",
            defaultSortOrder: "desc",
            dataIndex: ["created_at"],
          },
          {
            title: "Assigned To",
            key: "subcontractor",
            size: "md",
            dataIndex: ["subcontractor", "name"],
            render: (a, row) =>
              row.assign_to === "all-subcontractors" ? (
                "All Subcontractors"
              ) : (
                <CounterLabel
                  label="Subcontractors"
                  count={row.uploaded_toolbox_talk_subcontractors.length}
                />
              ),
          },
          {
            title: "Projects Assigned",
            key: "project",
            size: "md",
            dataIndex: ["general_contractor", "name"],
            render: (a, row) => {
              return row.assign_to === "all-projects" ? (
                "All Projects"
              ) : (
                <CounterLabel
                  label="Projects"
                  count={row.uploaded_toolbox_talk_projects.length}
                />
              );
            },
          },
          {
            title: "Times Used",
            key: "presented",
            size: "md",
            dataIndex: [""],
            render: (a, row) => {
              return (
                <CounterLabel
                  label="Times Used"
                  count={row.toolbox_talks.length}
                />
              );
            },
          },
          {
            title: "Workers",
            key: "workers",
            size: "sm",
            // sortable: true,
            dataIndex: [
              "uploaded_toolbox_talk_project_workers_aggregate",
              "aggregate",
              "count",
            ],
            render: (a, row) => {
              const workers: { [key: string]: boolean } = {};
              row.toolbox_talks.forEach((p) =>
                p.toolbox_talk_project_workers.forEach(
                  (pw) => (workers[pw.project_worker.worker_id] = true),
                ),
              );
              return (
                <CounterLabel
                  label="Workers"
                  count={Object.entries(workers).length}
                />
              );
            },
          },
        ]}
        queryNode={graphql`
          query UploadedToolboxTalkTableQuery(
            $first: Int!
            $after: String
            $where: uploaded_toolbox_talk_bool_exp!
            $order_by: [uploaded_toolbox_talk_order_by!]!
          ) {
            ...UploadedToolboxTalkTable_uploaded_toolbox_talk
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...UploadedToolboxTalkTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment UploadedToolboxTalkTable_total on query_root
          @argumentDefinitions(
            where: { type: "uploaded_toolbox_talk_bool_exp!" }
          )
          @refetchable(
            queryName: "UploadedToolboxTalkTableTotalRefetchableQuery"
          ) {
            allReportsConnection: uploaded_toolbox_talk_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment UploadedToolboxTalkTable_uploaded_toolbox_talk on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "uploaded_toolbox_talk_bool_exp!" }
            order_by: { type: "[uploaded_toolbox_talk_order_by!]!" }
          )
          @refetchable(queryName: "UploadedToolboxTalkTableRefetchableQuery") {
            uploaded_toolbox_talk_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "UploadedToolboxTalkTable_uploaded_toolbox_talk_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  created_at
                  name
                  due_time
                  assign_to
                  added_to_community
                  subcontractor_id
                  uploaded_toolbox_talk_projects {
                    project_id
                  }
                  uploaded_toolbox_talk_subcontractors {
                    subcontractor_id
                  }
                  created_by_user {
                    name
                    employee {
                      general_contractor {
                        name
                      }
                    }
                    subcontractor_employee {
                      subcontractor {
                        name
                      }
                    }
                  }
                  general_contractor {
                    name
                  }
                  content {
                    en
                  }
                  images {
                    url
                  }
                  subcontractor {
                    name
                  }
                  toolbox_talks {
                    project_id
                    toolbox_talk_project_workers {
                      project_worker {
                        worker_id
                      }
                    }
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default UploadedToolboxTalkTable;
