import { Card, Select, Space, Typography } from "antd";
import GCTeamList, {
  GCProjectTeamListProps,
} from "../../general-contractor/hierarchy/projects/team/GCTeamList";
import SwitchWithText from "../../../../../common/components/SwitchWithText";
import React, { useState } from "react";

const PeopleListDemo: React.FC = () => {
  const people: GCProjectTeamListProps["employees"] = [];
  const [isCorporateAdmin, setIsCorporateAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invitationStatus, setInvitationStatus] = useState<
    "pending" | "accepted"
  >("pending");
  const [status, setStatus] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [isLocalAdmin, setIsLocalAdmin] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showTerminatedDate, setShowTerminatedDate] = useState(false);
  const [showAvatar, setShowAvatar] = useState(false);

  // fill 'people' with dummy data

  for (let i = 0; i < 10; i++) {
    people.push({
      onActiveOnProjectChange(newValue: boolean): void {},
      generalContractorId: "gcId",
      isCorporateAdmin,
      loading,
      reSendInvite(): void {},
      userId: i + "",
      name: `John Doe ${i}`,
      title: `Software Engineer ${i}`,
      invitationStatus,
      activeOnProject: true,
      onRemoveFromTeam: showRemove
        ? async () => {
            alert("remove from team");
          }
        : undefined,
      email: showEmail ? "random@em.ail" : undefined,
      phoneNumber: showPhone ? "1234567890" : undefined,
      isLocalAdmin: isLocalAdmin,
      avatarUrl: showAvatar
        ? "https://avatars2.githubusercontent.com/u/45?v=4"
        : undefined,
      terminatedAt: showTerminatedDate ? "2023-10-01T00:00:00Z" : undefined,
    });
  }

  return (
    <div className={`flex flex-col gap-1`}>
      <Card title={"inputs"}>
        <Space direction={"vertical"}>
          <SwitchWithText
            checked={isCorporateAdmin}
            text={
              <Typography.Text code={true}>isCorporateAdmin</Typography.Text>
            }
            onChange={setIsCorporateAdmin}
          />
          <SwitchWithText
            checked={loading}
            text={<Typography.Text code={true}>loading</Typography.Text>}
            onChange={setLoading}
          />
          <Select
            value={invitationStatus}
            options={[
              {
                value: "pending",
                label: "Pending",
              },
              {
                value: "accepted",
                label: "Accepted",
              },
            ]}
            onChange={setInvitationStatus}
          />
          <SwitchWithText
            checked={status}
            text={<Typography.Text code={true}>status</Typography.Text>}
            onChange={setStatus}
          />
          <SwitchWithText
            checked={showRemove}
            text={<Typography.Text code={true}>showRemove</Typography.Text>}
            onChange={setShowRemove}
          />
          <SwitchWithText
            checked={isLocalAdmin}
            text={<Typography.Text code={true}>isLocalAdmin</Typography.Text>}
            onChange={setIsLocalAdmin}
          />
          <SwitchWithText
            checked={showEmail}
            text={<Typography.Text code={true}>showEmail</Typography.Text>}
            onChange={setShowEmail}
          />
          <SwitchWithText
            checked={showPhone}
            text={<Typography.Text code={true}>showPhone</Typography.Text>}
            onChange={setShowPhone}
          />
          <SwitchWithText
            checked={showTerminatedDate}
            text={
              <Typography.Text code={true}>showTerminatedDate</Typography.Text>
            }
            onChange={setShowTerminatedDate}
          />
          <SwitchWithText
            checked={showAvatar}
            text={<Typography.Text code={true}>showAvatar</Typography.Text>}
            onChange={setShowAvatar}
          />
        </Space>
      </Card>
      <Card title={"List"}>
        <GCTeamList employees={people} />
      </Card>
    </div>
  );
};

export default PeopleListDemo;
