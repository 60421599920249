import React, { FC } from "react";
import { Tag } from "antd";
import { StateFunctions } from "./CustomFilterController";
import dayjs from "dayjs";

type CustomFilterTopSectionProps = {
  dataIndexFilters?: {
    [key: string]: { val: string | boolean | string[]; onCancel: () => void };
  };
  removalFilters: {
    [key: string]: (
      list?: Array<{ name: string; id: string }>,
      listItemToRemove?: string,
    ) => void;
  };
} & StateFunctions;
const CustomFilterTopSection: FC<CustomFilterTopSectionProps> = ({
  dataIndexFilters,
  removalFilters,
  setAppliedFilter,
  setRemovalFilters,
  appliedFiltersfilterMap,
}) => {
  return (
    <>
      {dataIndexFilters &&
        Object.entries({ ...dataIndexFilters }).map(
          ([key, { val, onCancel }], id) => {
            const array = key.split("%");
            if (val !== false) {
              if (key === "search") {
                return (
                  <div>
                    <Tag key={id} closable={true} onClose={onCancel}>
                      {Array.isArray(val) && val[1] ? `${val[1]}` : ""}
                    </Tag>
                  </div>
                );
              }
              if (array.length === 1) {
                // when radio
                return (
                  <div key={id}>
                    <Tag
                      onClick={onCancel}
                      key={id}
                      closable={true}
                      onClose={onCancel}
                    >{`${typeof val === "string" ? val : ""}`}</Tag>
                  </div>
                );
              }
              if (array.length === 3) {
                return (
                  <div key={id}>
                    <Tag
                      onClick={onCancel}
                      key={id}
                      onClose={onCancel}
                      closable={true}
                    >{`${array[1]}`}</Tag>
                  </div>
                );
              }
            }
          },
        )}
      {Object.entries({ ...appliedFiltersfilterMap }).map(([key, val], id) => {
        const array = key.split("%");
        if (val !== false) {
          if (array.length === 1) {
            // when radio
            return (
              <div key={id}>
                <Tag
                  onClose={() => {
                    removalFilters[key]();
                  }}
                  closable={true}
                  key={id}
                >{`${typeof val === "string" ? val : ""}`}</Tag>
              </div>
            );
          }
          if (array.length === 2 && array[1] === "select") {
            const options: { name: string; id: string }[] = [];
            if (Array.isArray(val))
              val.forEach((element) => {
                if (element && !dayjs.isDayjs(element)) {
                  options.push(element);
                }
              });
            return (
              <div key={id}>
                {options.length > 0 && (
                  <div>
                    {array[0]}:
                    <br />{" "}
                    {options.map((val, i: number) => (
                      <div key={val.id}>
                        <Tag
                          key={i + val.id}
                          closable={true}
                          onClose={() => {
                            removalFilters[key]?.(
                              options.filter((p) => p.id !== val.id),
                            );
                            setAppliedFilter((prev) => {
                              const opts: { name: string; id: string }[] = [];
                              const prevArr = prev[key];
                              if (Array.isArray(prevArr)) {
                                prevArr.forEach((element) => {
                                  if (element && !dayjs.isDayjs(element)) {
                                    opts.push(element);
                                  }
                                });
                              }
                              return {
                                ...prev,
                                [array[0] + "%select"]: opts.filter(
                                  (p) => p.id !== val.id,
                                ),
                              };
                            });
                          }}
                        >
                          {val.name}
                        </Tag>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          }
          if (array.length === 2 && array[1] === "dateRange") {
            return (
              <div key={id}>
                {Array.isArray(val) ? (
                  <Tag
                    onClose={() => {
                      removalFilters[key]();
                    }}
                    key={id}
                    closable={true}
                  >
                    {val.length === 2 &&
                    dayjs.isDayjs(val[0]) &&
                    dayjs.isDayjs(val[1])
                      ? `${array[0]}: ${val[0]?.format("MM/DD/YYYY")} - 
                      ${val[1]?.format("MM/DD/YYYY")}`
                      : ""}
                  </Tag>
                ) : null}
              </div>
            );
          }
          if (array.length === 3) {
            return (
              <div key={id}>
                <Tag
                  onClose={() => {
                    removalFilters[key]();
                  }}
                  key={id}
                  closable={true}
                >
                  {`${array[1]}`}
                </Tag>
              </div>
            );
          }
        }
      })}
    </>
  );
};
export default CustomFilterTopSection;
