import React from "react";
import TextInput from "src/common/components/general/TextInput";
import SortingTypePicker, {
  SortingTypePickerProps,
} from "src/common/components/SortingTypePicker";
import { Tooltip } from "antd";
import Button from "src/common/components/general/button/Button";
import { MaterialSymbol } from "react-material-symbols";
import {
  ButtonProps as CustomButtonProps,
  default as CustomButton,
} from "src/common/components/general/button/Button";

export interface CustomTableTopBarProps {
  sorting?: SortingTypePickerProps;
  filter?: {
    visible: boolean;
    toggle: () => void;
  };
  onSearchSubmit?: (searchValue: string) => void;
  hideFilter?: boolean;
  buttons?: CustomButtonProps[];
}

const CustomTableTopBar: React.FunctionComponent<CustomTableTopBarProps> = (
  props,
) => {
  const handleSearchSubmit = (searchQuery: string) => {
    props.onSearchSubmit?.(searchQuery);
    return false;
  };

  return (
    <div
      className={`flex flex-col desktop:flex-row justify-center desktop:items-center w-full gap-1 desktop-large:gap-2`}
    >
      {!!props.buttons && !!props.buttons.length && (
         <div className={`flex flex-row items-center gap-0.5`}>
           {props.buttons.map((buttonProps, i) => (
             <Button {...buttonProps} key={i} />
           ))}
         </div>
       )}
       
      {!!props.onSearchSubmit && (
        <div className={`flex-1`}>
          <TextInput
            onSubmit={handleSearchSubmit}
            placeholder={"Search"}
            allowClear
          />
        </div>
      )}
      {(!!props.sorting || !!props.filter) && (
        <div className="flex flex-row items-center gap-1 justify-center">
          {!!props.sorting && <SortingTypePicker {...props.sorting} />}
          {!!props.filter && (
            <Tooltip title={`Show filters`}>
              <CustomButton
                icon={<MaterialSymbol icon={"filter_alt"} />}
                onClick={props.filter.toggle}
              />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomTableTopBar;
