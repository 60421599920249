/**
 * @generated SignedSource<<8abdcc764215f651ed88a71c2565bb95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { image_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadedToolboxTalk_DeleteImage_Mutation$variables = {
  idsToDelete: ReadonlyArray<string>;
  objectsToInsert: ReadonlyArray<image_insert_input>;
};
export type UploadedToolboxTalk_DeleteImage_Mutation$data = {
  readonly delete_image: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_image: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type UploadedToolboxTalk_DeleteImage_Mutation = {
  response: UploadedToolboxTalk_DeleteImage_Mutation$data;
  variables: UploadedToolboxTalk_DeleteImage_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "idsToDelete"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objectsToInsert"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "idsToDelete"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "image_mutation_response",
    "kind": "LinkedField",
    "name": "delete_image",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objectsToInsert"
      }
    ],
    "concreteType": "image_mutation_response",
    "kind": "LinkedField",
    "name": "insert_image",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadedToolboxTalk_DeleteImage_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadedToolboxTalk_DeleteImage_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3f5fb039f16a4f7588d1b9637fae73a4",
    "id": null,
    "metadata": {},
    "name": "UploadedToolboxTalk_DeleteImage_Mutation",
    "operationKind": "mutation",
    "text": "mutation UploadedToolboxTalk_DeleteImage_Mutation(\n  $idsToDelete: [uuid!]!\n  $objectsToInsert: [image_insert_input!]!\n) {\n  delete_image(where: {id: {_in: $idsToDelete}}) {\n    affected_rows\n  }\n  insert_image(objects: $objectsToInsert) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc91c595ecf6e286564f50baca7fc911";

export default node;
