import { MaterialSymbol } from "react-material-symbols";
import React from "react";
import Icon from "./general/Icon";

interface CheckItemProps {
  label: string;
  onClick?: () => void;
}

const CheckItem: React.FunctionComponent<CheckItemProps> = ({
  label,
  onClick,
}) => {
  return (
    <div
      {...{ onClick }}
      className="inline-flex flex-row items-center cursor-pointer select-none group"
    >
      <Icon icon={<MaterialSymbol icon="add" />} color={"interactive"} />
      <span className="ml-0.5 group-hover:text-interactive-primary transition-all">
        {label}
      </span>
    </div>
  );
};

export default CheckItem;
