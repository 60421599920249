/**
 * @generated SignedSource<<a135bbeeaa7fa9ca4c8f29ae87248e87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { inspection_qr_code_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useMoveInspectionQRToAnotherProjectMutation$variables = {
  newObjects: ReadonlyArray<inspection_qr_code_insert_input>;
  now: string;
  prevQRCodeId: string;
};
export type useMoveInspectionQRToAnotherProjectMutation$data = {
  readonly insert_inspection_qr_code: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_inspection_qr_code_by_pk: {
    readonly id: string;
  } | null | undefined;
};
export type useMoveInspectionQRToAnotherProjectMutation = {
  response: useMoveInspectionQRToAnotherProjectMutation$data;
  variables: useMoveInspectionQRToAnotherProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newObjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "now"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "prevQRCodeId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "newObjects"
      }
    ],
    "concreteType": "inspection_qr_code_mutation_response",
    "kind": "LinkedField",
    "name": "insert_inspection_qr_code",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "deleted_at",
            "variableName": "now"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "prevQRCodeId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "inspection_qr_code",
    "kind": "LinkedField",
    "name": "update_inspection_qr_code_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useMoveInspectionQRToAnotherProjectMutation",
    "selections": (v3/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useMoveInspectionQRToAnotherProjectMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "b6d504317b74ad6e5433fe7921150ba0",
    "id": null,
    "metadata": {},
    "name": "useMoveInspectionQRToAnotherProjectMutation",
    "operationKind": "mutation",
    "text": "mutation useMoveInspectionQRToAnotherProjectMutation(\n  $newObjects: [inspection_qr_code_insert_input!]!\n  $prevQRCodeId: uuid!\n  $now: timestamptz!\n) {\n  insert_inspection_qr_code(objects: $newObjects) {\n    affected_rows\n  }\n  update_inspection_qr_code_by_pk(pk_columns: {id: $prevQRCodeId}, _set: {deleted_at: $now}) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c75ce399219f87c23b52aa773109b311";

export default node;
