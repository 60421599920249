import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Alert,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import React, { useState } from "react";
import Icon from "../../../../common/components/general/Icon";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import normalizeUploadFile from "src/common/functions/normalizeUploadFile";
import { MaterialSymbol } from "react-material-symbols";

interface Values {
  name: string;
  content?: string;
  dueDate?: string;
  addToCommunityLib?: boolean;
  toolboxTalkPdfObjects?: Array<{ originFileObj: File }>;
  images?: Array<{ originFileObj: File }>;
  subsSelected?: Array<string>;
  projectsSelected?: Array<string>;
}

interface Props {
  visible: boolean;
  loading: boolean;
  onCreate: (values: Values) => Promise<void>;
  onCancel: () => void;
  subs?: Array<{ id: string; name: string }>;
  projects?: Array<{ id: string; name: string }>;
  projectId?: string;
  subId?: string;
}

const websiteLinks = [
  {
    name: "CPWR",
    link: "https://www.cpwr.com/research/research-to-practice-r2p/r2p-library/toolbox-talks/",
  },
  {
    name: "OSHA Training",
    link: "https://www.oshatraining.com/Toolbox-Talks.php",
  },
  {
    name: "Harvard",
    link: "https://www.ehs.harvard.edu/programs/toolbox-talks",
  },
  {
    name: "Villanova",
    link: "https://www1.villanova.edu/villanova/fmo/ehs/training/Toolbox_Talks.html",
  },
  {
    name: "Washington State",
    link: "https://lni.wa.gov/safety-health/safety-training-materials/toolbox-talks",
  },
  {
    name: "Pennsylvania",
    link: "https://www.dli.pa.gov/Businesses/Compensation/WC/safety/paths/resources/Pages/Safety-Talk-Topics.aspx",
  },
  {
    name: "IHSA",
    link: "https://www.ihsa.ca/resources/safetytalks.aspx",
  },
];

const AddToolboxTalkPdfModal: React.FC<Props> = ({
  visible,
  onCreate,
  loading,
  onCancel,
  subs,
  subId,
  projects,
  projectId,
}) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  const [type, setType] = useState<"text" | "upload">("upload");
  return (
    <Modal
      open={visible}
      width={800}
      title="Add Toolbox Talk"
      okText="Add"
      confirmLoading={loading}
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            console.log(values);
            await onCreate(values as Values);
            form.resetFields();
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Alert
        message={
          <>
            <p>
              Need toolbox talks? These links provide lots of options. Download
              the talks from the websites and upload them here.
            </p>
            <p>
              {websiteLinks.map((l, i) => (
                <span key={i}>
                  <a href={l.link} target="_blank" className="underline">
                    {l.name}
                  </a>
                  {i !== websiteLinks.length - 1 && ", "}
                </span>
              ))}
            </p>
          </>
        }
        type="info"
      />
      <Radio.Group
        className="py-1"
        options={[
          { label: "Upload File", value: "upload" },
          { label: "Create with Text/Images", value: "text" },
        ]}
        optionType="button"
        value={type}
        onChange={(e) => {
          form.resetFields();
          setType(e.target.value);
        }}
      ></Radio.Group>
      <br />
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: "public",
          addToCommunityLib: false,
        }}
      >
        <div className="flex">
          <Form.Item
            name="addToCommunityLib"
            label={
              <div className="flex">
                Add Toolbox Talk to Community Library &nbsp;&nbsp;
                <Icon
                  icon={<MaterialSymbol icon="info" />}
                  hoverContent={{
                    content: (
                      <div className="w-24">
                        <div className="font-accent">
                          Toolbox Talk Community Library
                        </div>
                        <br />
                        <div>
                          When it comes to safety, everyone is on the same team.
                          Help build a community toolbox talk library.
                        </div>
                        <div>
                          By adding a TBT to the community library you are
                          sharing and helping workers across the country. And
                          other users are helping your workers.
                        </div>
                        <div>
                          We do not disclose who added the toolbox talk or from
                          what company. But we will show you how many workers
                          have used it.
                        </div>
                        <div>
                          You can remove a toolbox talk from the community at
                          any time.
                        </div>
                        <div>
                          Disclaimer: any community database within SiteForm is
                          provided for informational purposes only and is not
                          monitored for the accuracy or reliability of the
                          information contained within it. SiteForm users must
                          follow all copyright requirements when adding TBTs to
                          the Community Library. Email us at{" "}
                          <a
                            href="mailto:support@siteform.io"
                            className="text-interactive-primary underline"
                          >
                            support@siteform.io
                          </a>{" "}
                          about any issues with community content.
                        </div>
                      </div>
                    ),
                  }}
                />
              </div>
            }
          >
            <Switch />
          </Form.Item>
        </div>
        {type === "upload" && (
          <>
            <Form.Item
              label="Upload Toolbox Talks"
              name="toolboxTalkPdfObjects"
              valuePropName="fileList"
              className=""
              getValueFromEvent={normalizeUploadFile}
              rules={[{ required: true, message: "Choose files" }]}
            >
              <Upload.Dragger
                customRequest={() => true}
                accept=".png,.jpg,.jpeg,.pdf"
                maxCount={1}
                className="mb-0 pb-0"
                iconRender={() => <AntCloudOutlined />}
                name="files"
                onChange={(e) => {
                  console.log(e, form.getFieldValue("name"));
                  if (!form.getFieldValue("name") && e.file.name)
                    form.setFieldsValue({
                      name: e.file.name.replace(/\..*/, ""),
                    });
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p>Click or drag into this area to upload</p>
              </Upload.Dragger>
            </Form.Item>
            <div className="mb-1">Note: Only upload one (1) TBT at a time</div>
          </>
        )}

        <Form.Item
          name="name"
          label={"Name/Topic of Toolbox Talk"}
          rules={[{ required: true, message: "Enter a name" }]}
        >
          <Input />
        </Form.Item>
        {type === "text" && (
          <>
            <Form.Item
              name="content"
              label={"Toolbox Talk Content"}
              rules={[{ required: true, message: "Enter the content" }]}
            >
              <TextArea
                rows={8}
                style={{ width: 1000 }}
                placeholder="Add your toolbox talk text and information here"
              />
            </Form.Item>
            <Form.Item
              name="images"
              valuePropName="fileList"
              getValueFromEvent={normalizeUploadFile}
              label={
                "Toolbox Talk Images (add any images that will be shown with the TBT)"
              }
              // rules={[{ required: true, message: "Enter the content" }]}
            >
              <Upload.Dragger
                customRequest={() => true}
                accept=".png,.jpg,.jpeg"
                maxCount={4}
                onChange={(e) => console.log(e)}
                iconRender={() => <AntCloudOutlined />}
                name="files"
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p>Click or drag into this area to upload</p>
              </Upload.Dragger>
            </Form.Item>
          </>
        )}
        <div className="flex">
          <Form.Item
            label={
              <div className="flex">
                Due Date (optional) &nbsp;&nbsp;
                <Icon
                  icon={<MaterialSymbol icon="info" />}
                  hoverContent={{
                    content: (
                      <div className="w-24">
                        Select the date a TBT should be completed by.
                        <br /> By adding a date, SiteForm will mark a TBT due
                        and possibly show the TBT during a Pre-Task Plan
                        meeting. Not selecting a date adds the TBT to your
                        crews’ list for your foreman to select and deliver to
                        his crew.
                      </div>
                    ),
                  }}
                />
                :
              </div>
            }
            name="dueDate"
          >
            <DatePicker
              disabledDate={(date) =>
                date.isSameOrBefore(dayjs().subtract(1, "day"))
              }
            />
          </Form.Item>
        </div>
        <div className={subId ? "hidden" : ""}>
          {subs && (
            <Form.Item
              name="subsSelected"
              label={
                <div className="text-1">
                  Select Subscontractors to assign this TBT to
                </div>
              }
            >
              <Select
                placeholder="All Subcontractors are Selected by Default"
                mode="multiple"
              >
                {subs.map((s) => (
                  <Select.Option key={s.id} value={s.id}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>
        {projects && (
          <Form.Item
            name="projectsSelected"
            label={
              <div className="text-1">
                Select Projects to assign this TBT to
              </div>
            }
          >
            <Select
              placeholder="All Projects are Selected by Default"
              mode="multiple"
            >
              {projects.map((p) => (
                <Select.Option key={p.id} value={p.id}>
                  {p.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default AddToolboxTalkPdfModal;
