import { ChecklistItemAnswerType } from "./siteInspectionTypes";

const getFullyCompletedChecklistitemAnswers = (checklistItemAnswers: {
  [id: string]: ChecklistItemAnswerType;
}) => {
  return Object.values(checklistItemAnswers).filter((ch) =>
    ch.item_type === "text"
      ? !!ch.text_response?.trim()
      : ch.item_type === "select_one"
      ? ch.select_answer
      : ch.option_response === "na" ||
        ch.option_response === ch.correct_answer ||
        (ch.option_response &&
          (!ch.require_photo_on_deficient || !!ch.images?.length) &&
          (ch.text_response || (ch.deficient_type_id && !ch.immediate_fix))),
  );
};
export default getFullyCompletedChecklistitemAnswers;
