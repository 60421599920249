import { MaterialSymbol } from "react-material-symbols";
import { graphql } from "babel-plugin-relay/macro";
import React, { forwardRef } from "react";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { ToolboxTalkTableQuery } from "src/common/types/generated/relay/ToolboxTalkTableQuery.graphql";
import { ToolboxTalkTable_toolbox_talks$data } from "src/common/types/generated/relay/ToolboxTalkTable_toolbox_talks.graphql";

type ColumnKeys = "date" | "project" | "subcontractor" | "workers" | "topic";
const CONNECTION_NAME = "toolbox_talk_connection";
type DConnection = ToolboxTalkTable_toolbox_talks$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  ToolboxTalkTableQuery,
  string
> & {
  showDateRangeFilter?: boolean;
};

const ToolboxTalkTable = forwardRef<DataScrollTableRef, Props>(
  (
    { title = "Toolbox Talks", onRowClick, showDateRangeFilter, ...props },
    ref,
  ) => {
    return (
      <DataScrollTable<DConnection, ColumnKeys, ToolboxTalkTableQuery>
        {...props}
        newCustomTableLook
        ref={ref}
        title={title}
        datePickerDataIndex={showDateRangeFilter ? ["created_at"] : undefined}
        onRowClick={(tbt) => {
          onRowClick && onRowClick(tbt.pk);
        }}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allReportsConnection"}
        columns={[
          {
            title: "Date",
            key: "date",
            sortable: true,
            contentType: {
              type: "date",
              renderOptions: () => ({ format: "full", withTime: true }),
            },
            size: "md",
            defaultSortOrder: "desc",
            dataIndex: ["created_at"],
          },
          {
            title: "Project",
            key: "project",
            size: "md",
            queryIncludeVarKey: "includeProject",
            dataIndex: ["project", "name"],
            searchDataIndex: ["project", "name"],
          },
          {
            title: "Subcontractor",
            key: "subcontractor",
            size: "md",
            queryIncludeVarKey: "includeSub",
            dataIndex: ["subcontractor", "name"],
            searchDataIndex: ["subcontractor", "name"],
            contentType: {
              type: "with-icon",
              renderOptions: (value) => ({
                icon: <MaterialSymbol icon={"work"} />,
                label: value,
              }),
            },
          },
          {
            title: "Topic",
            key: "topic",
            size: "md",
            dataIndex: ["description", "en"],
            searchDataIndex: ["description", "en"],
            contentType: {
              type: "note",
            },
          },
          {
            title: "Workers",
            contentType: { type: "counter" },
            key: "workers",
            size: "sm",
            sortable: true,
            dataIndex: [
              "toolbox_talk_project_workers_aggregate",
              "aggregate",
              "count",
            ],
          },
        ]}
        queryNode={graphql`
          query ToolboxTalkTableQuery(
            $first: Int!
            $after: String
            $where: toolbox_talk_bool_exp!
            $order_by: [toolbox_talk_order_by!]!
            $includeSub: Boolean!
            $includeProject: Boolean!
          ) {
            ...ToolboxTalkTable_toolbox_talks
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
                includeSub: $includeSub
                includeProject: $includeProject
              )
            ...ToolboxTalkTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment ToolboxTalkTable_total on query_root
          @argumentDefinitions(where: { type: "toolbox_talk_bool_exp!" })
          @refetchable(queryName: "ToolboxTalkTableTotalRefetchableQuery") {
            allReportsConnection: toolbox_talk_connection(where: $where) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment ToolboxTalkTable_toolbox_talks on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "toolbox_talk_bool_exp!" }
            order_by: { type: "[toolbox_talk_order_by!]!" }
            includeSub: { type: "Boolean!" }
            includeProject: { type: "Boolean!" }
          )
          @refetchable(queryName: "ToolboxTalkTableRefetchableQuery") {
            toolbox_talk_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "ToolboxTalkTable_toolbox_talk_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  created_at
                  description {
                    ...TextTranslationFrag @relay(mask: false)
                  }
                  project @include(if: $includeProject) {
                    name
                  }
                  subcontractor @include(if: $includeSub) {
                    name
                  }
                  toolbox_talk_project_workers_aggregate {
                    aggregate {
                      count
                    }
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default ToolboxTalkTable;
