import getObsFindingsCount from "../utils/getObsFindingsCount";
import { useMemo } from "react";
import clsx from "clsx";
import { Table } from "antd";
import compareStringsIgnoreCase from "src/common/functions/compareStringsIgnoreCase";
import { LeaderboardDataProps } from "./LeaderboardCharts";
import compareStringsIgnoreCaseNullsLast from "src/common/functions/compareStringsIgnoreCaseNullsLast";

const FractionAndPercent = ({
  completed,
  required,
}: {
  completed: number;
  required: number;
}) => {
  const colorSuffix =
    completed < required
      ? "orange-dark"
      : completed === required
      ? "purple"
      : "semantic-positive-green";
  const percent = Math.round((completed / required) * 100);
  return (
    <div className="flex items-center gap-0.5">
      <div className={clsx("w-0.75 h-0.75 rounded-full", `bg-${colorSuffix}`)}>
        &nbsp;
      </div>
      {completed}/{required}{" "}
      <span className={`text-${colorSuffix}`}>&nbsp;&nbsp;{percent}%</span>
    </div>
  );
};
const sorter = (
  a: { completed: number; required?: number },
  b: { completed: number; required?: number },
) => {
  const ratioA = a.required ? a.completed / a.required : -1; // Calculate ratio, or assign -1 if completed is 0 or undefined
  const ratioB = b.required ? b.completed / b.required : -1;

  // If both have invalid completeds, maintain order
  if (ratioA === -1 && ratioB === -1) return a.completed - b.completed;

  // If only one has an invalid completed, place it at the bottom
  if (ratioA === -1) return 1;
  if (ratioB === -1) return -1;

  // Compare valid ratios
  return ratioA - ratioB; // Sort in descending order of the ratio
};

const LeaderboardTable: React.FC<LeaderboardDataProps> = ({
  users,
  titleReqMap,
  monthsCount,
  groupIdsFilter,
  titleIdsFilter,
  projectIdsFilter,
}) => {
  const tableData = useMemo(
    () =>
      users
        .reduce<
          Array<{
            name: string;
            id: string;
            title?: string;
            oneOffFindings: number;
            auditFindings: number;
            safeFindings: number;
            audits: number;
            group_name?: string;
            findings_per_audit?: number;
            observations_required?: number;
            audits_required?: number;
          }>
        >((list, user) => {
          const { oneOffFindings, auditFindings, safeFindings } =
            getObsFindingsCount(user.created_observations);
          const title = user.employee?.employee_title;
          const requirement = title ? titleReqMap.get(title.id) : undefined;
          if (
            (user.created_observation_inspections.length ||
              user.created_observations.length ||
              (title && titleReqMap.get(title.id))) &&
            (!projectIdsFilter.size ||
              user.employee?.directProjects.some((p) =>
                projectIdsFilter.has(p.project_id),
              )) &&
            (!titleIdsFilter.size || (title && titleIdsFilter.has(title.id))) &&
            (!groupIdsFilter.size ||
              (requirement && groupIdsFilter.has(requirement.id)))
          ) {
            list.push({
              name: user.name,
              id: user.id,
              title: title?.name.en,
              oneOffFindings,
              auditFindings,
              safeFindings,
              audits: user.created_observation_inspections.length,
              ...(requirement
                ? {
                    group_name: requirement.group_name,
                    findings_per_audit: requirement.findings_per_audit,
                    observations_required:
                      requirement.observations_required * monthsCount,
                    audits_required: requirement.audits_required * monthsCount,
                  }
                : {}),
            });
          }
          return list;
        }, [])
        .sort(
          (a, b) =>
            -sorter(
              {
                completed: a.audits,
                required: a.audits_required,
              },
              {
                completed: b.audits,
                required: b.audits_required,
              },
            ),
        ),
    [
      users,
      titleReqMap,
      monthsCount,
      projectIdsFilter,
      groupIdsFilter,
      titleIdsFilter,
    ],
  );
  return (
    <Table
      dataSource={tableData}
      pagination={false}
      rowKey={(row) => row.id}
      columns={[
        {
          title: "Name",
          dataIndex: ["name"],
          key: "name",
          sorter: (a, b) => compareStringsIgnoreCase(a.name, b.name),
        },
        {
          title: "Title",
          dataIndex: ["title"],
          key: "title",
          sorter: (a, b) => compareStringsIgnoreCaseNullsLast(a.title, b.title),
        },
        {
          title: "Group",
          dataIndex: ["group_name"],
          key: "group_name",
          sorter: (a, b) =>
            compareStringsIgnoreCaseNullsLast(a.group_name, b.group_name),
        },
        {
          title: "One-off Observations",
          key: "one-off-obs",
          sorter: (a, b) =>
            sorter(
              {
                completed: a.oneOffFindings,
                required: a.observations_required,
              },
              {
                completed: b.oneOffFindings,
                required: b.observations_required,
              },
            ),
          dataIndex: ["oneOffFindings"],
          render: (oneOffFindings, row) => {
            if (!row.observations_required) return oneOffFindings;
            return (
              <FractionAndPercent
                completed={oneOffFindings}
                required={row.observations_required}
              />
            );
          },
        },
        {
          title: "Audits",
          key: "audits",
          defaultSortOrder: "descend",
          sorter: (a, b) =>
            sorter(
              {
                completed: a.audits,
                required: a.audits_required,
              },
              {
                completed: b.audits,
                required: b.audits_required,
              },
            ),
          dataIndex: ["audits"],
          render: (audits, row) => {
            if (!row.audits_required) return audits;
            return (
              <FractionAndPercent
                completed={audits}
                required={row.audits_required}
              />
            );
          },
        },
        {
          title: "Total Audit Findings",
          key: "audit-findings",
          sorter: (a, b) => a.auditFindings - b.auditFindings,
          dataIndex: "auditFindings",
        },
        {
          title: "% Safe",
          key: "safe-percentage",
          sorter: (a, b) =>
            sorter(
              {
                completed: a.safeFindings,
                required: a.oneOffFindings + a.auditFindings,
              },
              {
                completed: b.safeFindings,
                required: b.oneOffFindings + b.auditFindings,
              },
            ),
          dataIndex: "safeFindings",
          render: (val, row) => {
            if (!val) return val;
            const total = row.auditFindings + row.oneOffFindings;
            const percent = Math.round((val / total) * 100);
            return (
              <div className="text-semantic-positive-green">{percent}%</div>
            );
          },
        },
        {
          title: "Findings per Audit",
          key: "findings-per-audit",
          dataIndex: ["auditFindings"],
          sorter: (a, b) =>
            sorter(
              {
                completed: a.audits
                  ? Math.round(a.auditFindings / a.audits)
                  : 0,
                required: a.findings_per_audit,
              },
              {
                completed: b.audits
                  ? Math.round(b.auditFindings / b.audits)
                  : 0,
                required: b.findings_per_audit,
              },
            ),
          render: (_, row) => {
            const findingPerAudit = row.audits
              ? Math.round(row.auditFindings / row.audits)
              : 0;

            if (!row.findings_per_audit) return findingPerAudit;
            return (
              <FractionAndPercent
                completed={findingPerAudit}
                required={row.findings_per_audit}
              />
            );
          },
        },
      ]}
    />
  );
};
export default LeaderboardTable;
