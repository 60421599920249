import clsx from "clsx";
import { useRef, useState } from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import Button from "src/common/components/general/button/Button";
import { useGetStatesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import AddressFormItems from "./AddressFormItems";
import uploadFiles from "src/common/functions/upload-utility/uploadFiles";
import { MaterialSymbol } from "react-material-symbols";

export type NewLevelFormDataType = {
  name: string;
  addressLine1: string;
  city: string;
  divisionId?: string;
  businessUnitId?: string;
  stateCode: string;
  zip: string;
  logoUrl?: Array<{ originFileObj: File }>;
  uploadedLogoUrl?: string | null;
};

export interface GCAddNewLevelButtonProps {
  inserting: boolean;
  buSelectOptions?: Array<{
    id: string;
    name: string;
    gc_division_id?: string | null;
  }>;
  buLevelName?: string | null;
  divisionLevelName?: string | null;
  divisionSelectOptions?: Array<{ id: string; name: string }>;
  levelName: string;
  divisionId?: string | null;
  businessUnitId?: string | null;
  addNewLevelInitialVals?: { businessUnitId?: string; divisionId?: string };
  onInsert: (vals: NewLevelFormDataType) => void | Promise<void>;
}

const GCAddNewLevelButton: React.FC<GCAddNewLevelButtonProps> = ({
  levelName,
  onInsert,
  inserting,
  buLevelName,
  divisionLevelName,
  buSelectOptions,
  divisionSelectOptions,
  addNewLevelInitialVals,
}) => {
  const [selectedDivision, setSelectedDivision] = useState<string | null>();
  const { data } = useGetStatesQuery({ variables: {} });
  const [uploading, setUploading] = useState(false);
  const fmodalRef = useRef<FModalRef<NewLevelFormDataType>>();
  return (
    <>
      <FModal
        ref={fmodalRef}
        onCancel={() => fmodalRef.current?.close()}
        title={"Add new " + levelName}
        confirmLoading={inserting || uploading}
        okText="Add +"
        form={{
          initialValues: addNewLevelInitialVals,
        }}
        onOk={async () => {
          const vals = await fmodalRef.current?.form.validateFields();
          if (!vals) return;
          if (vals.logoUrl) {
            try {
              setUploading(true);
              vals.uploadedLogoUrl = (
                await uploadFiles(vals.logoUrl.map((o) => o.originFileObj))
              )[0]?.[0]?.url;
            } finally {
              setUploading(false);
            }
          }
          vals.businessUnitId =
            addNewLevelInitialVals?.businessUnitId || vals.businessUnitId; // preferring the one passed
          vals.divisionId =
            addNewLevelInitialVals?.divisionId || vals.divisionId; // preffer the one passed
          if (vals.businessUnitId) {
            const foundBu = (buSelectOptions || []).find(
              (bu) => bu.id === vals.businessUnitId,
            );
            if (foundBu) {
              vals.divisionId = foundBu.gc_division_id || undefined; // if we found Bu then the divisionId should always be of that BU only nothing else
            }
          }

          if (vals) await onInsert(vals);
          fmodalRef.current?.close();
        }}
      >
        <FModal.Text
          name={"name"}
          required
          label="Name"
          requiredMessage="Name is required"
        />
        <FModal.Select
          className={clsx(
            divisionSelectOptions &&
              divisionLevelName &&
              !!divisionSelectOptions.length &&
              !addNewLevelInitialVals?.divisionId
              ? ""
              : "hidden", //WARNING: DO NOT CHANGE it: shubham
          )}
          name="divisionId"
          label={divisionLevelName}
          props={{
            allowClear: true,
            onChange: (v) =>
              typeof v === "string" || typeof v === "undefined"
                ? setSelectedDivision(v)
                : null,
            filterOption: (input, option) =>
              option?.label &&
              typeof option.label === "string" &&
              option.label.toLowerCase().includes(input.toLowerCase()),
            style: { width: "100%" },
            options: (divisionSelectOptions || []).map((s) => ({
              label: s.name,
              value: s.id,
            })),
          }}
        />
        <FModal.Select
          name="businessUnitId"
          className={clsx(
            buSelectOptions &&
              buLevelName &&
              !!buSelectOptions.length &&
              !addNewLevelInitialVals?.businessUnitId
              ? ""
              : "hidden", //WARNING: DO NOT CHANGE it: shubham
          )}
          label={
            <div>
              {buLevelName}
              {divisionLevelName && !addNewLevelInitialVals?.divisionId && (
                <div>
                  (You can select a {buLevelName} even without selecting a{" "}
                  {divisionLevelName})
                </div>
              )}
            </div>
          }
          props={{
            filterOption: (input, option) =>
              option?.label &&
              typeof option.label === "string" &&
              option.label.toLowerCase().includes(input.toLowerCase()),
            allowClear: true,
            onChange: (v) => {
              if (typeof v === "string") {
                const div = buSelectOptions?.find(
                  (b) => b.id === v,
                )?.gc_division_id;
                setSelectedDivision(div);
                fmodalRef.current?.form.setFieldValue("divisionId", div);
              }
            },
            style: { width: "100%" },
            options: (buSelectOptions || [])
              .filter(
                (bu) =>
                  !selectedDivision || bu.gc_division_id === selectedDivision,
              )
              .map((s) => ({
                label: s.name,
                value: s.id,
              })),
          }}
        />
        Address
        <AddressFormItems states={data?.state || []} onStateChange={() => {}} />
        <FModal.SingleImgDragUpload
          name={"logoUrl"}
          label={"Have a different logo? Upload it here."}
        />
      </FModal>

      <Button
        onClick={() => fmodalRef.current?.open()}
        icon={<MaterialSymbol icon={"add"} />}
        label={"Add New " + levelName}
      />
    </>
  );
};
export default GCAddNewLevelButton;
