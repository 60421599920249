import { FC, useCallback, useRef, useState } from "react";
import { message, Popover } from "antd";
import CustomButton from "src/common/components/general/button/Button";
import { auth } from "src/common/functions/firebase";
import { cloneDeep } from "@apollo/client/utilities";
import { getPreviousJHA } from "src/common/components/CompareTwoJHAs";
import dayjs from "dayjs";
import JhaGceReview from "src/common/components/JhaGceReview";
import JhaSubAdminApproval from "src/common/components/JhaSubAdminApproval";
import StyledContent from "src/common/components/layouts/StyledContent";
import TaskNotesTable from "src/common/components/tables/TaskNotesTable";
import TaskEditsTable from "src/common/components/tables/TaskEditsTable";
import TaskApprovalsTable from "src/common/components/tables/TaskSubAdminApprovalsTable";
import TaskSignaturesTable from "src/common/components/tables/TaskSignatureTable";
import TaskType from "src/common/types/manual/TaskType";
import ViewAndEditTaskType from "src/common/types/manual/ViewAndEditTaskType";
import TaskEditPage from "./TaskEditPage";
import TaskVersionsView from "./TaskVersionsView";
import useOnSaveTaskAfterEdit from "../../utils/useOnSaveTask";
import {
  useEmailFlaggedJhatoUnflagMutation,
  useUpdateFlaggedTaskMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import { MaterialSymbol } from "react-material-symbols";

interface ViewAndEditTaskProps {
  taskId?: string;
  projectId?: string;
  subcontractorId?: string;
  type:
    | "gce_project_jha"
    | "gce_company_jha"
    | "sub_project_jha"
    | "sub_company_jha"
    | "pending_jha"
    | "sfe_uploaded_jha"
    | "anonymous";
  isNotTurnersProject: boolean;
  data: ViewAndEditTaskType;
  onSfeTaskSave?: (task: TaskType) => void;
  requestId?: string;
  viewOnly?: boolean;
}

const ViewAndEditTask: FC<ViewAndEditTaskProps> = ({
  taskId,
  projectId,
  subcontractorId,
  type,
  isNotTurnersProject,
  data,
  requestId,
  onSfeTaskSave,
  viewOnly,
}) => {
  const {
    ppeCategories: dataForPpe,
    permits: dataForPermit,
    ecms: dataForEcm,
    queryData,
  } = data;

  const [editState, setEditState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editConfirmationOpen, setEditConfirmationOpen] = useState(false);
  const [currentJhaIndex, setCurrentJhaIndex] = useState(0);
  const [stepsOrderChanged, setStepsOrderChanged] = useState(false);

  const taskEditData = [...(queryData?.task_edit_connection.edges ?? [])].sort(
    (a, b) => dayjs(b.node.edited_at).diff(dayjs(a.node.edited_at)),
  );

  const taskSignaturesData = queryData?.task_signature_connection.edges;
  const subAdminApproval =
    queryData?.task_connection.edges[0].node.subAdminApprovals;
  const showEditWarning = Boolean(taskSignaturesData?.length);

  const currentTaskObject: TaskType = {
    id: data.id,
    description: data.description,
    descriptionId: data.descriptionId,
    permits: data.taskPermits,
    ppes: data.taskPpes,
    steps: data.steps,
  };

  const [currentTaskObjectTest, setCurrentTaskObjectTest] =
    useState(currentTaskObject);
  const currentTaskObjectTestCopy = useRef(currentTaskObjectTest);

  const buildJhaHistory = () => {
    const history: Array<{
      currentTask: TaskType;
      previousTask: TaskType;
      editedBy: string;
      editedAt: string;
      editType: string;
    }> = [];

    const editableTaskObject = cloneDeep(currentTaskObject);

    taskEditData.forEach((edit) => {
      if ((edit.node.task_patch as Array<string>).length > 0) {
        const tempTaskObject = cloneDeep(editableTaskObject);
        getPreviousJHA(editableTaskObject, edit.node.task_patch);

        history.push({
          currentTask: cloneDeep(tempTaskObject),
          previousTask: cloneDeep(editableTaskObject),
          editedBy: edit.node.edited_by_user?.name ?? "",
          editedAt: edit.node.edited_at,
          editType: edit.node.edit_type,
        });
      }
    });

    history.push({
      currentTask: editableTaskObject,
      previousTask: editableTaskObject,
      editedBy: "",
      editedAt: "",
      editType: "",
    });

    return history;
  };

  const [updateFlaggedTask] = useUpdateFlaggedTaskMutation();
  const [onSaveTaskAfterEdit] = useOnSaveTaskAfterEdit();
  const [emailToUnflag, { loading: emailingJHAForReview }] =
    useEmailFlaggedJhatoUnflagMutation();

  const lastApprovalRequestToSub =
    data.queryData?.email_request_connection.edges[0]?.node;
  const taskReviewedByLoggedInUser =
    data.queryData?.task_signature_connection.edges.some(
      (edge) => edge.node.user_id === auth.currentUser?.uid,
    );
  const [checked, setChecked] = useState(taskReviewedByLoggedInUser ?? false);
  const [currentPersonApproved, setCurrentPersonApproved] = useState(
    Boolean(
      subAdminApproval?.some((sub) => sub.user_id === auth.currentUser?.uid),
    ),
  );

  const jhaHistory = buildJhaHistory();
  const updateCurrentTaskObject = useCallback(
    (newTaskStepObject: typeof currentTaskObjectTest) => {
      currentTaskObjectTestCopy.current = newTaskStepObject;
    },
    [],
  );

  const jhaFormEdited =
    JSON.stringify(currentTaskObjectTest) !==
    JSON.stringify(currentTaskObjectTestCopy.current);

  const isTaskEditable = type !== "gce_company_jha" && type !== "pending_jha";

  const onEditTask = () => {
    if (editState) return;
    updateCurrentTaskObject(currentTaskObjectTest);
    setStepsOrderChanged(false);
    setLoading(false);
    setEditState(true);
  };

  const onSaveTask = async (removeSigns = false) => {
    setLoading(true);
    try {
      const hasTaskChanged =
        JSON.stringify(currentTaskObjectTest) !==
        JSON.stringify(currentTaskObjectTestCopy.current);

      if (!hasTaskChanged) {
        setEditState(false);
        return;
      }

      if (type === "anonymous" && requestId) {
        await updateFlaggedTask({
          variables: {
            input: {
              currentTaskObject: currentTaskObjectTest,
              previousTaskObject: currentTaskObjectTestCopy.current,
              requestId,
            },
          },
        });
      } else if (type === "sfe_uploaded_jha") {
        onSfeTaskSave?.(currentTaskObjectTest);
      } else {
        if (!taskId) throw new Error("TaskId is not specified");

        await onSaveTaskAfterEdit(
          currentTaskObjectTest,
          currentTaskObjectTestCopy.current,
          taskId,
          data.descriptionId,
          stepsOrderChanged,
          removeSigns,
        );
        setCurrentJhaIndex(0);
      }

      updateCurrentTaskObject(currentTaskObjectTest);
      setEditState(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BPopconfirm
        title={
          <div className={"w-24"}>
            This JHA was signed or Reviewed/Approved. Depending on your edits
            you can keep or clear the signatures, reviews and/or approvals.
          </div>
        }
        open={editConfirmationOpen}
        okText={"Save & Keep Signatures"}
        cancelText={"Save & Remove Signatures"}
        onConfirm={() => {
          setEditConfirmationOpen(false);
          onSaveTask(false);
        }}
        onCancel={() => {
          setEditConfirmationOpen(false);
          onSaveTask(true);
        }}
      />

      {isTaskEditable && (
        <div className="ml-3 mr-2 flex justify-between items-center">
          <div className="flex">
            {!viewOnly && (
              <span className="inline-block mr-1">
                <CustomButton
                  loading={editState ? loading : false}
                  label={editState ? "Save" : "Edit"}
                  icon={!editState ? <MaterialSymbol icon="edit" /> : undefined}
                  secondary={type === "anonymous"}
                  large
                  onClick={async () => {
                    if (editState && showEditWarning) {
                      setEditConfirmationOpen(true);
                      return;
                    } else if (editState) {
                      onSaveTask();
                    } else {
                      onEditTask();
                    }
                  }}
                />
              </span>
            )}

            <span>
              {editState && jhaFormEdited && (
                <CustomButton
                  small
                  secondary={type === "anonymous"}
                  label="Cancel"
                  onClick={async () => {
                    setCurrentTaskObjectTest(currentTaskObjectTestCopy.current);
                    setStepsOrderChanged(false);
                  }}
                />
              )}
            </span>
          </div>

          {type === "gce_project_jha" &&
            taskId &&
            data.queryData?.task_signature_connection &&
            projectId &&
            subcontractorId && (
              <JhaGceReview
                taskId={taskId}
                projectId={projectId}
                subcontractorId={subcontractorId}
                taskReviewedData={data.queryData.task_signature_connection.edges.filter(
                  (edge) => edge.node.user?.role === "employee",
                )}
                isNotTurnersProject={isNotTurnersProject}
                checked={checked}
                setChecked={setChecked}
              />
            )}

          {type === "sub_project_jha" && lastApprovalRequestToSub && taskId && (
            <JhaSubAdminApproval
              currentPersonApproved={currentPersonApproved}
              lastApprovalRequestToSub={lastApprovalRequestToSub}
              taskId={taskId}
              setApproved={() => setCurrentPersonApproved(true)}
            />
          )}
          {type === "anonymous" && requestId && (
            <div className="flex">
              <span className="inline-block mr-1">
                <CustomButton
                  icon={<MaterialSymbol icon="flag" />}
                  delete
                  onClick={() => console.log("")}
                />
              </span>

              <span className="inline-block mr-0.5">
                <CustomButton
                  label="Request to unflag"
                  secondary
                  onClick={async () => {
                    await emailToUnflag({
                      variables: { input: { requestId: requestId } },
                    });
                    message.success("Request sent to UNFLAG the JHA");
                  }}
                  large
                />
              </span>

              <span className="inline-block mr-0.5">
                <Popover
                  trigger={"hover"}
                  placement="topLeft"
                  content={
                    <div className="text-1.2">
                      Once the JHA has been edited, select{" "}
                      <b>Request to unflag </b>
                      to let the GC know it has been updated{" "}
                    </div>
                  }
                >
                  ℹ️
                </Popover>
              </span>
            </div>
          )}
        </div>
      )}
      <br />
      {!editState && (
        <TaskVersionsView
          currentTask={
            type === "sfe_uploaded_jha" || type === "anonymous"
              ? currentTaskObjectTest
              : jhaHistory[currentJhaIndex].currentTask
          }
          previousTask={
            type === "sfe_uploaded_jha" || type === "anonymous"
              ? currentTaskObjectTest
              : jhaHistory[currentJhaIndex].previousTask
          }
          editedBy={jhaHistory[currentJhaIndex].editedBy}
          editedAt={dayjs(jhaHistory[currentJhaIndex].editedAt).format("LLL")}
          editType={jhaHistory[currentJhaIndex].editType}
          previousJhaVersions={jhaHistory.length}
          currentJHAIndex={currentJhaIndex}
          isTurner={!isNotTurnersProject}
          handleClick={(type) => {
            if (type === "left") {
              setCurrentJhaIndex((i) => i + 1);
            } else if (type === "right") {
              setCurrentJhaIndex((i) => i - 1);
            } else if (type === "currentJha") {
              setCurrentJhaIndex(0);
            }
          }}
        />
      )}

      {editState && (
        <TaskEditPage
          currentTaskObject={currentTaskObjectTest}
          isNotTurnersProject={isNotTurnersProject}
          dataForPpe={dataForPpe}
          dataForPermit={dataForPermit}
          dataForEcm={dataForEcm}
          setCurrentTaskObject={(task) => setCurrentTaskObjectTest(task)}
          stepsOrderChanged={() => setStepsOrderChanged(true)}
          showCancel={jhaFormEdited}
          onCancel={() => {
            setCurrentTaskObjectTest(currentTaskObjectTestCopy.current);
            setStepsOrderChanged(false);
          }}
          onSave={async () => {
            if (
              JSON.stringify(currentTaskObjectTest) !==
              JSON.stringify(currentTaskObjectTestCopy.current)
            ) {
              message.loading({
                content: "Saving the JHA",
                duration: 0,
              });
              if (type === "sfe_uploaded_jha") {
                onSfeTaskSave && onSfeTaskSave(currentTaskObjectTest);
                message.destroy();
                setEditState(false);
                updateCurrentTaskObject(currentTaskObjectTest);
              } else if (type === "anonymous" && requestId) {
                await updateFlaggedTask({
                  variables: {
                    input: {
                      currentTaskObject: currentTaskObjectTest,
                      previousTaskObject: currentTaskObjectTestCopy.current,
                      requestId: requestId,
                    },
                  },
                });
                message.destroy();
                setEditState(false);
                updateCurrentTaskObject(currentTaskObjectTest);
              } else if (showEditWarning) {
                setEditConfirmationOpen(true);
              } else {
                if (!taskId) throw new Error("TaskId is not specified");
                await onSaveTaskAfterEdit(
                  currentTaskObjectTest,
                  currentTaskObjectTestCopy.current,
                  taskId,
                  data.descriptionId,
                  stepsOrderChanged,
                  false,
                );

                message.destroy();
                setCurrentJhaIndex(0);
                setEditState(false);
              }
            } else {
              updateCurrentTaskObject(currentTaskObjectTest);
              setStepsOrderChanged(false);
              setEditState(false);
            }
          }}
          isAnonymous={type === "anonymous"}
        />
      )}

      <br />

      {type === "gce_project_jha" && (
        <StyledContent padding backgroundColor="white">
          <h3 className="text-16 mb-1 font-bold">JHA notes</h3>
          {taskId && subcontractorId && (
            <TaskNotesTable subcontractorId={subcontractorId} taskId={taskId} />
          )}
        </StyledContent>
      )}

      {(type === "sub_project_jha" || type === "gce_project_jha") &&
        taskSignaturesData && (
          <StyledContent padding backgroundColor="white">
            <h3 className="text-16 mb-1 font-bold">
              SubAdmins who Approved this JHA
            </h3>
            <TaskApprovalsTable
              subApprovalData={taskSignaturesData.filter(
                (sign) =>
                  !sign.node.signature_image_id &&
                  !sign.node.sign_in_image_parent_id,
              )}
            />
          </StyledContent>
        )}

      {(type === "sub_project_jha" || type === "gce_project_jha") && (
        <StyledContent padding backgroundColor="white">
          <h3 className="text-16 mb-1 font-bold">JHA Activity</h3>
          <TaskEditsTable taskEditData={taskEditData} />
        </StyledContent>
      )}

      {(type === "gce_project_jha" || type === "sub_project_jha") &&
        taskSignaturesData &&
        projectId && (
          <StyledContent padding backgroundColor="white">
            <h1 className="text-16 mb-1 font-bold">Worker Signatures</h1>
            <TaskSignaturesTable
              workerSignaturesData={taskSignaturesData}
              projectId={projectId}
            />
          </StyledContent>
        )}
    </>
  );
};
export default ViewAndEditTask;
