import SiteformLogoCircle from "./SiteformLogoCircle";
import SiteformLogoFull from "./SiteformLogoFull";

interface SiteformBrandLogoProps {
  type?: "full" | "circle";
  className?: string;
}

const SiteformBrandLogo: React.FC<SiteformBrandLogoProps> = (props) => {
  const logoProps = {
    className: props.className,
  };

  switch (props.type) {
    case "circle":
      return <SiteformLogoCircle {...logoProps} />;
    case "full":
    default:
      return <SiteformLogoFull {...logoProps} />;
  }
};

export default SiteformBrandLogo;
