/**
 * @generated SignedSource<<3057eae04768a5bf1673b23dc2e95166>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_onsite_period_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentOnsiteSettingInsertSubOnsiteMutation$variables = {
  object: project_subcontractor_onsite_period_insert_input;
};
export type usePermanentOnsiteSettingInsertSubOnsiteMutation$data = {
  readonly insert_project_subcontractor_onsite_period_one: {
    readonly id: string;
  } | null | undefined;
};
export type usePermanentOnsiteSettingInsertSubOnsiteMutation = {
  response: usePermanentOnsiteSettingInsertSubOnsiteMutation$data;
  variables: usePermanentOnsiteSettingInsertSubOnsiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "project_subcontractor_onsite_period_project_id_subcontractor_id",
          "update_columns": [
            "close_date"
          ]
        }
      }
    ],
    "concreteType": "project_subcontractor_onsite_period",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_onsite_period_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentOnsiteSettingInsertSubOnsiteMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentOnsiteSettingInsertSubOnsiteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ea88c93945c083cd155373348001315",
    "id": null,
    "metadata": {},
    "name": "usePermanentOnsiteSettingInsertSubOnsiteMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentOnsiteSettingInsertSubOnsiteMutation(\n  $object: project_subcontractor_onsite_period_insert_input!\n) {\n  insert_project_subcontractor_onsite_period_one(object: $object, on_conflict: {constraint: project_subcontractor_onsite_period_project_id_subcontractor_id, update_columns: [close_date]}) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "13a15710d65a736cf34bc4e1e09fbdb1";

export default node;
