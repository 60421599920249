import React from "react";
import ContactLink from "src/common/components/general/ContactLink";
import {
  Avatar,
  Button,
  List,
  message,
  Popconfirm,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  GetUserHierarchyProjectsDocument,
  GetUserHierarchyProjectsQuery,
  GetUserHierarchyProjectsQueryVariables,
  useForgotPasswordMutation,
  useUpdateUserHierarchyTeamsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import useAuthUser from "src/common/hooks/useAuthUser";
import { MaterialSymbol } from "react-material-symbols";

const textData = {
  active: "Active",
  inactive: "Inactive",
};

export interface GCProjectTeamListItemProps {
  name: string;
  userId: string;
  generalContractorId: string;
  isCorporateAdmin?: boolean;
  onRemoveFromTeam?: (userId: string) => Promise<void>;
  avatarUrl?: string;
  title?: string;
  email?: string;
  phoneNumber?: string;
  loading?: boolean;
  invitationStatus: "pending" | "accepted";
  reSendInvite?: () => void;
  terminatedAt?: string;
  isLocalAdmin?: boolean; // if the user is an admin on the level of the hierarchy node
  activeOnProject?: boolean;
  onActiveOnProjectChange?: (newValue: boolean) => void;
}

const GCTeamListItem: React.FC<GCProjectTeamListItemProps> = (props) => {
  const client = useApolloClient();
  const [forgotPassword] = useForgotPasswordMutation();
  const authUser = useAuthUser();

  const [updateUserHierarchyTeams] = useUpdateUserHierarchyTeamsMutation();

  const sendResetPasswordLink = async () => {
    if (!props.email) {
      message.error("Email is missing");
      return;
    }

    await forgotPassword({
      variables: {
        input: {
          loginIdentifier: props.email,
        },
      },
    });
    message.success("Sent Successfully");
  };

  const activateOrDeactivateUser = async () => {
    const { data } = await client.query<
      GetUserHierarchyProjectsQuery,
      GetUserHierarchyProjectsQueryVariables
    >({
      query: GetUserHierarchyProjectsDocument,
      variables: {
        gcId: props.generalContractorId,
        userId: props.userId,
        includeAllGcProjects: !!props.isCorporateAdmin,
      },
    });

    const projectIds = props.isCorporateAdmin
      ? (data.general_contractor_project || []).map(
          (gcProject) => gcProject.project_id,
        )
      : (data.general_contractor_employee || []).flatMap((gcEmployee) => [
          ...(gcEmployee.gc_business_units || []).flatMap((gcBu) =>
            gcBu.gc_business_unit.projects.map((proj) => proj.id),
          ),
          ...(gcEmployee.gc_divisions || []).flatMap((gcBu) =>
            gcBu.gc_division.projects.map((proj) => proj.id),
          ),
          ...(gcEmployee.gc_offices || []).flatMap((gcBu) =>
            gcBu.gc_office.projects.map((proj) => proj.id),
          ),
        ]);

    await updateUserHierarchyTeams({
      variables: {
        userId: props.userId,
        isReactivation: !!props.terminatedAt,
        userUpdates: {
          terminated_at: !!props.terminatedAt ? null : dayjs().format(),
          terminated_by_uid: !!props.terminatedAt ? null : authUser.uid,
        },
        projectEmployeeUpdates: {
          status: !!props.terminatedAt ? "Active" : "Inactive",
        },
        projectEmployees: projectIds.map((projId) => ({
          project_id: projId,
          employee_id: props.userId,
          direct_project_assign: false,
        })),
      },
      optimisticResponse: {
        update_user: {
          affected_rows: 1,
          returning: [
            {
              id: props.userId,
              terminated_at: !!props.terminatedAt ? null : dayjs().format(),
              terminated_by_uid: !!props.terminatedAt ? null : authUser.uid,
            },
          ],
        },
        insert_project_employee: { affected_rows: 1 },
        update_project_employee: { affected_rows: 1 },
      },
    });
  };

  const isTerminated = !!props.terminatedAt;

  const onActiveOnProjectChange = props.onActiveOnProjectChange;

  return (
    <List.Item>
      <Spin spinning={!!props.loading}>
        <div className={`flex flex-row items-center justify-between`}>
          <List.Item.Meta
            avatar={<Avatar src={props.avatarUrl} />}
            title={
              <Space>
                <Typography.Text>{props.name}</Typography.Text>
                {props.isLocalAdmin && (
                  <Tooltip title={"Admin"}>
                    <Typography.Text type={"secondary"}>
                      <MaterialSymbol icon={"shield_person"} />
                    </Typography.Text>
                  </Tooltip>
                )}
              </Space>
            }
            description={props.title}
          />
          <Space>
            {/* Contacts */}
            {(!!props.phoneNumber || !!props.email) && (
              <Space>
                {!!props.email && <ContactLink value={props.email} />}
                {!!props.phoneNumber && (
                  <ContactLink value={props.phoneNumber} />
                )}
              </Space>
            )}
            {/* Termination Status */}
            {isTerminated && (
              <Tag>
                Deactivated on{" "}
                {dayjs(props.terminatedAt).format("MMM DD, YYYY")}
              </Tag>
            )}
            {/* Invitation Status */}
            {(() => {
              switch (props.invitationStatus) {
                case "pending":
                  return (
                    <>
                      <Tag>Invited</Tag>
                      {!!props.reSendInvite && (
                        <Tooltip title={"Resend Invite"}>
                          <Button
                            onClick={props.reSendInvite}
                            icon={<MaterialSymbol icon="send" />}
                          />
                        </Tooltip>
                      )}
                    </>
                  );
                case "accepted":
                  if (props.activeOnProject) {
                    return (
                      <Tag>
                        {props.activeOnProject
                          ? textData.active
                          : textData.inactive}
                      </Tag>
                    );
                  } else {
                    return (
                      <>
                        <Tag>Active</Tag>
                        <Popconfirm
                          title={`Send reset password link?`}
                          onConfirm={async () => {
                            await sendResetPasswordLink();
                          }}
                        >
                          <Tooltip title={`Send reset password link`}>
                            <Button
                              icon={<MaterialSymbol icon={"lock_open"} />}
                            />
                          </Tooltip>
                        </Popconfirm>
                      </>
                    );
                  }
              }
            })()}
            {/* Buttons */}
            <Space>
              {/* Project-based status */}
              {!!onActiveOnProjectChange &&
                props.invitationStatus === "accepted" &&
                (props.activeOnProject ? (
                  <Tooltip title={`Deactivate from the project`}>
                    <Button
                      onClick={() => {
                        onActiveOnProjectChange(false);
                      }}
                      icon={<MaterialSymbol icon={"person_remove"} />}
                    >
                      Make inactive
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title={`Activate on the project`}>
                    <Button
                      onClick={() => {
                        onActiveOnProjectChange(true);
                      }}
                      icon={"person_add"}
                    >
                      Activate
                    </Button>
                  </Tooltip>
                ))}
              {/* Remove from admins list */}
              {props.onRemoveFromTeam && (
                <Popconfirm
                  onConfirm={() => props.onRemoveFromTeam?.(props.userId)}
                  title={`Remove this User’s Admin Credentials?`}
                  description={`This will remove their access to this Admin Level of your organization and all projects`}
                >
                  <Tooltip title={`Remove admin access`}>
                    <Button
                      danger
                      icon={<MaterialSymbol icon="remove_moderator" />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
              {/* Termination */}
              {!!props.terminatedAt ? (
                <Popconfirm
                  description={
                    <>
                      Restore access for this previously deactivated user,
                      allowing access to SiteForm.
                    </>
                  }
                  onConfirm={activateOrDeactivateUser}
                  title={"Are you sure to reactivate this user?"}
                >
                  <Tooltip title={`Reactivate`}>
                    <Button
                      color={"green"}
                      icon={<MaterialSymbol icon={"undo"} />}
                    />
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Popconfirm
                  description={
                    <>
                      Remove access for current or terminated employees. This
                      will log them out of SiteForm, prevent future logins, and
                      cancel any pending invites if a password has not been set.
                    </>
                  }
                  onConfirm={activateOrDeactivateUser}
                  title={"Are you sure to deactivate this user?"}
                >
                  <Tooltip title={`Deactivate`}>
                    <Button
                      danger={true}
                      icon={<MaterialSymbol icon={"person_remove"} />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
            </Space>
          </Space>
        </div>
      </Spin>{" "}
    </List.Item>
  );
};

export default GCTeamListItem;
